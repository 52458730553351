import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../util/appStyle";

export const colors = {
  lightBlue: "#fff",
  darkBlue: "#012449",
  blue: "#004288",
  light: "#d7ecf1",
};
export const objStyle = makeStyle({
  main: {
    ".uicore_drawer": {
      minHeight: "100vh",
      zIndex: "11000",
      ".uicore_grid_item": {
        padding: "10px 5px",
        borderBottom: "1px solid",
        borderColor: colors.darkBlue,
      },
    },
  },
  mainContainer: {
    width: `${(11.5 / 12) * 100}%`,
    margin: "20px auto 0",
  },
  container: {
    width: `${(10 / 12) * 100}%`,
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
    paddingInlineEnd: "20px",
    xs: {
      width: `${(11 / 12) * 100}%`,
    },
  },

  subtitleLabel: {
    color: colors?.darkBlue,
    ...appStyle?.fontsChanga,
    fontWeight: "500",
    userSelect: "none",
  },
  subtitleLabelPrimary: {
    color: colors?.blue,
    ...appStyle?.fontsChanga,
    fontWeight: "600",
    userSelect: "none",
  },
  mainTitle: {
    color: colors?.blue,
    userSelect: "none",
    position: "relative",
    ...appStyle?.fontsChanga,
    fontWeight: "600",
    textAlign: "center !important",

  },
  herorTitleLight: {
    color: colors?.blue,
    userSelect: "none",
    position: "relative",
    ...appStyle?.fontsChanga,
    fontWeight: "600",
    textAlign: "center !important",
    lineHeight: "1",
    // textShadow: `0 0 1px #fff, 0 0 2px #fff, 0 0 3px ${colors?.blue}, 0 0 5px ${colors?.blue}, 0 0 6px ${colors?.blue}, 0 0 7px ${colors?.blue}, 0 0 10px ${colors?.blue}`,
    sm:{
      fontSize: "2.55rem"
    }
  },
  herorSubtitleLabel: {
    color: colors?.blue,
    userSelect: "none",
    position: "relative",
    ...appStyle?.fontsChanga,
    fontWeight: "600",
    textAlign: "center !important",
  },
  mainTitleLight: {
    color: colors?.blue,
    userSelect: "none",
    position: "relative",
    ...appStyle?.fontsChanga,
    fontWeight: "600",
    textAlign: "center !important",
    // textShadow: `0 0 1px #fff, 0 0 2px #fff, 0 0 3px ${colors?.blue}, 0 0 5px ${colors?.blue}, 0 0 6px ${colors?.blue}, 0 0 7px ${colors?.blue}, 0 0 10px ${colors?.blue}`,
  },

  hero: {
    position: "relative",
    background: `url(${require("../../../util/assets/images/kwcity_light.jpg")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "75vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    sm: {
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "80%",
        position: "absolute",
        background: "rgba(212, 213, 217, 0.35)",
        // backdropFilter: "blur(2px)",
      },
    },
    "&before": {
      content: "",
      display: "block",
      width: "80%",
      height: "60%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // top: "20%",
      // left: "10%",
      background: "rgba(212, 213, 217, 0.35)",
      // backdropFilter: "blur(2px)",
      borderRadius: "4px",
      boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",

    },
  },
  btnContainer:{
    sm:{
      position: "absolute",
      bottom: "0px"
    }
  },

  section: {
    position: "relative",
    height: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    margin: "0px auto",
    padding: "2.2rem 0px",
  },
  paperAbout: {
    background: `url(${require("../../../../old/assets/images/kw.png")})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    boxShadow: `0 0 8px ${colors?.blue}, inset 0 0 8px ${colors?.blue}`,
    border: `1px solid #fff`,
  },
  btnBox: {
    fontSize: "large",
    borderRadius: "8rem",
    border: "2px solid",
    borderColor: colors?.blue,
    span: {
      ...appStyle?.fontsMada,
    },
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
      color: appStyle.App_Light_Text_Color,
    },
    xs: {
      fontWeight: "bold",
      minWidth: "120px",
    },
  },

  objectives: {
    backgroundColor: colors?.lightBlue,
    backgroundImage: `url(${require("../../../util/assets/images/caerve.jpg")})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "51% 23%",
    backgroundRepeat: "no-repeat",
  },

  sections: {
    backgroundColor: "rgb(179, 210, 224)",
    backgroundImage: `url(${require("../../../util/assets/images/caerve3.jpg")})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "51% 23%",
    backgroundRepeat: "no-repeat",
  },

  about: {
    backgroundColor: colors?.blue,
    "&*":{
      color: `${colors?.lightBlue} !important`
    }
  },

  organizers: {
    backgroundColor: appStyle?.App_Light_Color,
    backgroundImage: `url(${require("../../../util/assets/images/caerve2.jpg")})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "51% 23%",
    backgroundRepeat: "no-repeat",
  },

  inner: {
    backgroundColor: "transparent",
    width: "50px",
    height: "50px",
    border: `.5px solid #fff`,
    boxShadow: `0 0 8px ${colors?.blue}, inset 0 0 8px ${colors?.blue}`,
  },
  accordionTab: {
    backgroundColor: "transparent",
    border: `.5px solid #fff`,
    boxShadow: `0 0 8px ${colors?.blue}, inset 0 0 8px ${colors?.blue}`,
  },
  sheikhImg: {
    width: "100px",
    borderRadius: "8px",
    border: `.5px solid #fff`,
    boxShadow: `0 0 8px ${colors?.blue}, inset 0 0 8px ${colors?.blue}`,
  },
  box: {
    height: "100%",
    background: "#fdfdfd",
    boxShadow: `0 0 8px ${colors?.blue}, inset 0 0 8px ${colors?.blue}`,
    border: `1px solid #fff`,
  },
  box2: {
    height: "90%",
    overflow: "hidden",
    border: `1px solid ${colors?.blue}`,
    background: `linear-gradient(-30deg, ${appStyle?.App_Light_Color} -30%, #B3D2E0 100%)`,
  },
  box3: {
    height: "100%",
    position: "absolute",
    zIndex: "1",
    overflow: "hidden",
    background: `linear-gradient(30deg, ${appStyle?.App_Light_Color} 30%, #B3D2E0 100%)`,
  },
  textShadow: {
    color: colors?.blue,
    // textShadow: `0 0 2px #fff, 0 0 5px #fff, 0 0 7px ${colors?.blue}, 0 0 10px ${colors?.blue}, 0 0 12px ${colors?.blue}, 0 0 15px ${colors?.blue}, 0 0 20px ${colors?.blue}`,
  },
  orderNum: {
    position: "absolute",
    top: "-90px",
    fontSize: "100px",
    zIndex: 2,
    sm: {
      fontSize: "80px",
      top: "-70px",
    },
  },
  smText: {
    sm: {
      fontSize: "1.2rem",
    },
  },
  copyright: {
    background: colors?.darkBlue,
    color: "#fff",
  },
  navbar: {
    color: `${colors?.blue} !important`,
    textDecoration: "none",
    "&active": {
      textDecoration: "underline",
    },
    "&visited": {
      textDecoration: "underline",
    },
  },
  navbarSS: {
    color: `${colors?.blue} !important`,
    width: "100%",
    textDecoration: "none",
    "&active": {
      textDecoration: "underline",
    },
    "&visited": {
      textDecoration: "underline",
    },
  },
  appbar: {
    position: "absolute",
    background: "transparent",
    // boxShadow: "none",
    minHeight: "67px",
    boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
    "&before": {
      content: "",
      position: "absolute",
      top: 0,
      left: 0,
      display: "block",
      width: "100%",
      height: "100%",
      background: "rgba(212, 213, 217, 0.35)",
      // backdropFilter: "blur(2px)",
      sm: {
        background: "transparent",
      },
    },
    ".uicore_bar_1": {
      background: colors?.light,
    },
    ".uicore_bar_2": {
      background: colors?.light,
    },
    ".uicore_bar_3": {
      background: colors?.light,
    },
    ".uicore_bar_burger_1qw5975": {
      "&before": {
        content: "",
        display: "block",
        width: "40px",
        height: "40px",
        background: colors?.blue,
        borderRadius: "50%",
        position:"absolute",
        top: "-9px", left: "-7px",
      },
    },
  },
});
