import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as XLSX from "xlsx";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { generateQueries, objTableAttributes } from "../../../../appHelper/appQuery/GenerateQuery";
import * as appVariables from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import appRouting from "../../../../../client/configuration/routing/appRouting";

const tblActivityGuestFetch = generateQueries("tblActivityGuest");
const strPageInfo = "@src/old/components/stakeholders/admin/AttendeeManagement/AttendeeManagement.controller.js";
const labels = dictionary?.components?.attendeeManagement;

export const strTransactionNeedToModify = "TransactionNeedToModify";
export default function attendeeManagementCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields, bigActivityType }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }

          state.blnIsFetchDone = true;
          setIsLoading(true);

          const result = await tblActivityGuestFetch(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes.tblActivityGuest.full,
            objectCondition: {
              blnIsActive: true,
              blnIsDeleted: false,
              ...(bigActivityType ? { bigActivityType: bigActivityType } : {}),
            },
          });

          if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.lstAttendee = (result.response || [])?.reverse();

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-initData");
          setIsLoading(false);
        }
      },
    restSearch:
      ({ fields }) =>
      async () => {
        try {
          fields?.strSearch?.setValue("");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-initData");
        }
      },

    onChangeActivety:
      ({ fields, state, setState }) =>
      (event) => {
        const optAllSubject = { key: "all", value: { eng: "All", arb: "الكل" }?.[lang] };
        const activityValue = event?.target?.id || String(fields?.lstActivityType?.getValue()?.key);

        if (String(activityValue) === "all") {
          fields.lstSubject.setOptions([optAllSubject]);
          state.lstActivitiesRead = state.lstActivities;
          setState({ ...state });

          return;
        }

        const objCat = appFunctions.getCategoryCache();
        let lstSubjectOption = [];
        let objSubjectOptionLang = {};

        const objActivityInfo = objCat?.Root?.activity?.[appFunctions?.appCategoryGetKey(objCat?.Root?.activity, activityValue)] || {};
        Object.entries(objActivityInfo?.subject || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstSubjectOption.push({ key: value?.id, value: value?.value?.[lang] });
            objSubjectOptionLang[value?.id] = {
              eng: value?.value?.eng,
              arb: value?.value?.arb,
            };
          }
        });

        fields.lstSubject.setOptions([optAllSubject, ...lstSubjectOption]);
        if (lstSubjectOption.length === 1) {
          fields.lstSubject.setValue(lstSubjectOption[0]);
        } else {
          fields.lstSubject.setValue(optAllSubject);
        }

        state.lstActivitiesRead = state.lstActivities?.filter((item) => String(item?.bigActivityType) === String(activityValue));
        setState({ ...state });
      },

    onChangeSubject:
      ({ fields, state, setState }) =>
      (event) => {
        const activityValue = String(fields?.lstActivityType?.getValue()?.key);
        const subjectValue = event?.target?.id; //fields?.lstSubject?.getValue();

        if (subjectValue === "all") {
          state.lstActivitiesRead = state.lstActivities?.filter((item) => String(item?.bigActivityType) === String(activityValue));
          setState({ ...state });
          return;
        }

        //state.lstActivitiesRead = state.lstActivitiesRead?.filter((item) => String(item?.jsnGuestInfo?.objTargeted?.id) === String(subjectValue?.key));
        state.lstActivitiesRead = state.lstActivities?.filter((item) => {
          let lstSubjectUser = Array.isArray(item?.jsnGuestInfo?.objSubject) ? item?.jsnGuestInfo?.objSubject : [];
          return (
            String(item?.bigActivityType) === String(activityValue) && (lstSubjectUser.includes(String(subjectValue)) || lstSubjectUser.includes(Number(subjectValue)))
          );
        });
        setState({ ...state });
      },

    exportExcelFile:
      ({ state }) =>
      () => {
        var workbook = XLSX.utils.book_new();
        var worksheet_data = [lang === "arb" ? ["الاسم"] : ["Name"]];

        (state?.lstAttendee || [])?.forEach((child) => {
          if (child?.blnIsConfirmed) {
            let strFullName = child?.jsnGuestInfo?.strFullName?.[lang] || child?.jsnGuestInfo?.strFullName || "";
            let lstAttendeeName = [];

            let lstSubjectUser = Array.isArray(child?.jsnGuestInfo?.objSubject) ? child?.jsnGuestInfo?.objSubject : [];
            lstSubjectUser.forEach((subject) => {
              lstAttendeeName.push([strFullName]);
            });

            worksheet_data?.push(...lstAttendeeName);
          }
        });

        var worksheet = XLSX.utils.aoa_to_sheet(worksheet_data);
        worksheet["!autofilter"] = { ref: "F1" };
        workbook.SheetNames.push("certificate_attendee_names");
        workbook.Sheets["certificate_attendee_names"] = worksheet;
        XLSX.writeFile(workbook, `certificate_attendee_names_${appFunctions.getDateUSFormat(appFunctions?.getCurrentDate())}.xlsx`);
      },
  };

  return handler;
}

/*
strTrainingType: String(trainingType).toLowerCase();
intTraineesCount : trainingNumber,
intTrainingProgPrice : trainingNumber * objTrainingFromCat?.price?.value?.[lang],
*/

///tblPartnershipTrack
