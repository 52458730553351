import React, { useEffect, useState } from "react";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { useApp } from "../../../../configuration/contextapi/context";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Avatar, Badge, Button, Container, Grid, Line, Loader, Paper, Typography } from "@cbmisorg/material";
import { App_info_Color, App_Primary_Color } from "../../../../util/appStyle";
import ShredHeader from "../../../../../old/components/sharedUi/header/ShredHeader";
import mngRegForumCtrl, { operationForumMode } from "./mngRegForum.controller";
import { Icon } from "@cbmisorg/icons";
import SaveForumModalView from "./components/saveForumModal/saveForumModal.view";
import NoResultImg from "../../../../../old/components/sharedUi/noResultImg/NoResultImg";
import * as appFunctions from "../../../../util/appFunctions";
import GenerateQRCodeView from "./components/generateQRCode/generateQRCode.view";
import AttendeeConfirmationView from "./components/attendeeConfirmation/attendeeConfirmation.view";
import SelectNameCoordinateView from "./components/selectNameCoordinate/selectNameCoordinate.view";
import AddGuestManual from "./components/addGuestManual/addGuestManual";
import { objUserRole } from "../../../../configuration/appVariables";
import excelIcon from "../../../../../old/assets/images/excel-icon.png";

export default function MngRegForumView() {
  const { appState, appDispatch } = useApp();

  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.stakeholders?.admin?.mngRegForum;

  const [state, setState] = useState({ blnIsInitDone: false, lstForum: [] });
  const [isLoading, setIsLoading] = useState(false);

  const handlers = mngRegForumCtrl({ appState, appDispatch });
  const selectNameCoordinateView = SelectNameCoordinateView();
  const saveForumModalView = SaveForumModalView({
    isLoading,
    setIsLoading,
    parentState: state,
    setParentState: setState,
    selectNameCoordinateOpenEle: selectNameCoordinateView.openEle,
  });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state });
  const exportAttendances = handlers.exportAttendances({ isLoading, setIsLoading });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <>
      {!isLoading ? null : <Loader backdrop={true} color={App_info_Color} />}

      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnHome={false} blnBack={true} blnLang={false} blnLogout={false} sx={{ position: "absolute" }} />

      <Container>
        {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole.Receptionist ? null : (
          <Grid container justifyContent="flex-end">
            <Grid item>{saveForumModalView.openEle(operationForumMode.add, null, null)}</Grid>
          </Grid>
        )}
        {state?.lstForum?.length ? null : <NoResultImg />}

        {state?.lstForum?.map((forum, indexI) => {
          return (
            <Paper sx={{ border: `1px solid ${App_Primary_Color}` }} key={forum?.bigForumID} my-4>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item alignItems="center">
                      <Typography as="subtitle2" sx={{ color: "info" }}>
                        {forum?.jsnForumInfo?.strForumName}
                      </Typography>
                    </Grid>
                    {!forum?.blnStrictRegForum ? null : (
                      <Grid item alignItems="center">
                        <Icon icon="howToReg" sx={{ color: "error", fontSize: "32px" }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {!forum?.blnStrictRegForum ? null : (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item alignItems="center">
                        <AttendeeConfirmationView bigForumID={forum?.bigForumID} intNum={forum?.intNumNeedToConfirmUser} lang={lang} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item alignItems="center">
                      <Typography as="body3" sx={{ color: "primary" }}>
                        {labels?.label?.numRegGuest?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item alignItems="center">
                      <Avatar sx={{ color: "#ffffffff", width: "24px", height: "24px", background: "primary" }}>{forum?.intNumRegUser}</Avatar>
                    </Grid>
                  </Grid>
                </Grid>

                <Line sx={{ width: "100%" }} my-4 />

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item alignItems="center">
                      <Typography as="body3" sx={{ color: "primary" }}>
                        {{ eng: "قائمة الحضور", arb: "قائمة الحضور" }?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item alignItems="center">
                      <span style={{ display: "flex" }} onClick={exportAttendances({ bigActivityType: forum?.bigForumID, blnStrictRegForum: forum?.blnStrictRegForum })}>
                        <Typography as="caption" px-2 py-0>
                          {labels?.certificateBtn?.[lang]}
                        </Typography>
                        <img src={excelIcon} alt="" width={"25px"} height={"25px"} style={{ borderRadius: "4px" }} />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
             

                {forum?.jsnForumInfo?.blnHaveSubForum ? (
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionTab>
                        <AccordionSummary>
                          <Badge content={forum?.jsnForumInfo?.lstSubForum?.length || "0"}>
                            <Typography p-2 as="subtitle2">
                              {labels?.label?.subForums?.[lang]}
                            </Typography>
                          </Badge>
                        </AccordionSummary>
                        <AccordionDetails>
                          {(Array.isArray(forum?.jsnForumInfo?.lstSubForum) ? forum?.jsnForumInfo?.lstSubForum : []).map((subForum, indexJ) => {
                            return (
                              <Grid container key={subForum?.bigSubForumID}>
                                <Grid item xs={12} my-2>
                                  <Typography as="subtitle2" sx={{ color: "info" }}>
                                    {subForum?.strSubForumName}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item alignItems="center">
                                      <Typography as="body3" sx={{ color: "primary" }}>
                                        {labels?.label?.forumDate?.[lang]}
                                      </Typography>
                                    </Grid>
                                    <Grid item alignItems="center">
                                      <Typography as="body3">{appFunctions.getDateUSFormat(subForum?.dtmSubForumData)}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item alignItems="center">
                                      <Typography as="body3" sx={{ color: "primary" }}>
                                        {labels?.label?.forumLocation?.[lang]}
                                      </Typography>
                                    </Grid>
                                    <Grid item alignItems="center">
                                      <Typography as="body3">{subForum?.strSubForumLocation}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item alignItems="center">
                                      <Typography as="body3" sx={{ color: "primary" }}>
                                        {labels?.label?.forumLink?.[lang]}
                                      </Typography>
                                    </Grid>
                                    <Grid item alignItems="center">
                                      <GenerateQRCodeView
                                        strForumPath={forum?.strForumPath}
                                        strSubForumName={subForum?.strSubForumName}
                                        strForumName={forum?.jsnForumInfo?.strForumName}
                                        strSubForumPath={subForum?.strSubForumPath}
                                        strForumLocation={subForum?.strSubForumLocation}
                                        dtmForumData={appFunctions.getDateUSFormat(subForum?.dtmSubForumData)}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </AccordionDetails>
                      </AccordionTab>
                    </Accordion>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item alignItems="center">
                          <Typography as="body3" sx={{ color: "primary" }}>
                            {labels?.label?.forumDate?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item alignItems="center">
                          <Typography as="body3">{appFunctions.getDateUSFormat(forum?.jsnForumInfo?.dtmForumDate)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item alignItems="center">
                          <Typography as="body3" sx={{ color: "primary" }}>
                            {labels?.label?.forumLocation?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item alignItems="center">
                          <Typography as="body3">{forum?.jsnForumInfo?.strForumLocation}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item alignItems="center">
                          <Typography as="body3" sx={{ color: "primary" }}>
                            {labels?.label?.forumLink?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item alignItems="center">
                          <GenerateQRCodeView
                            strForumPath={forum?.strForumPath}
                            strForumName={forum?.jsnForumInfo?.strForumName}
                            strForumLocation={forum?.jsnForumInfo?.strForumLocation}
                            dtmForumData={appFunctions.getDateUSFormat(forum?.jsnForumInfo?.dtmForumDate)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole.Receptionist ? null : (
                  <>
                    <Line sx={{ width: "100%" }} my-4 />

                    <Grid item xs={12} justifyContent="center">
                      <Grid item>{saveForumModalView.openEle(operationForumMode.edit, forum, indexI)}</Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          );
        })}
      </Container>

      {/* <AddGuestManual /> */}

      {saveForumModalView.modal}
      {selectNameCoordinateView.modal}
    </>
  );
}
