import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./Switch.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const defaultOptions = [
  { key: false, value: "off" },
  { key: true, value: "on" },
]
const Switch = forwardRef((props, ref) => {
    const {id,label,errorMessage,mode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,showValue=true,
          options=defaultOptions,
          sx,hidden,disabled,dir,className,...rest} = props

  const screenWidth = useCheckScreenSize();
  const styleRef = useRef({ strSxClassName: "" });
    const [inputState, setInputState] = useState({
      switchStyleCircle:"circle",
      switchMode:"cbmis-switch-circle-1",
      optionsState:options || [
        { key: false, value: "off" },
        { key: true, value: "on" },
      ],
      value:options?.[0]?.value || "off" 
    });

    const nameID = useRef(id || componentsFunction.randomName("Switch"));
    const getID = useRef(componentsFunction.randomName("Switch"));
    const inputRef = useRef({})


    //#region Effect

      useEffect(()=>{
        inputRef.current.listObjValues = value || defaultValue || ""
        const theValue = value || defaultValue
        if(theValue){
          if(typeof defaultValue === "boolean"){
            inputRef.current.value = defaultValue === true? options?.[1]?.value || true : options?.[0]?.value || false
            inputRef.current.key = defaultValue === true? options?.[1]?.key || true : options?.[0]?.key || false
            inputRef.current.objValue = {key: inputRef.current.key, value: inputRef.current.value};
            inputRef.current.checked = defaultValue

          }else if(typeof defaultValue === "object"){
            inputRef.current.value = defaultValue?.value === options?.[1]?.value? options?.[1]?.value || true : options?.[0]?.value || false
            inputRef.current.key = defaultValue?.key === options?.[1]?.key? options?.[1]?.key || true : options?.[0]?.key || false
            inputRef.current.objValue = {key: inputRef.current.key, value: inputRef.current.value};
            inputRef.current.checked = defaultValue?.key === options?.[1]?.key &&  defaultValue?.value === options?.[1]?.value
          }
          if(ref){
            ref.current.value =  inputRef.current.value
            ref.current.key = inputRef.current.key
            ref.current.objValue = inputRef.current.objValue;
            ref.current.checked = inputRef.current.checked
          }
          inputState.value = inputRef.current.value;
          setInputState({...inputState})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value, defaultValue, ref])

      useEffect(() => {},[
        id,label,errorMessage,mode,
        color,icon,iconColor,adornment,endIcon,endAdornment,
        value,defaultValue,onChange,options,
        sx,hidden,disabled,dir,className,rest,
        inputState
      ])

    //#endregion

     //#region style
    useEffect(() => {
      if (sx) {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-input");
      }
    }, [sx, screenWidth]);

    useEffect(() => {
        var root = document.querySelector(':root');
        root.style.setProperty('--cbmis-switch-active-color', componentsFunction.checkColorReturnValue(color || "primary"));
    }, [color]);

    useEffect(() => {
      if (mode) {
        const getStyle = String(mode).toLowerCase();
        inputState.switchMode =`cbmis-switch-${getStyle.slice(0, -1)}-${getStyle.slice(-1)}`;
        inputState.switchStyleCircle=getStyle.slice(0, -1) === "circle"?"circle":"";

        setInputState({...inputState})
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);


    const getRestProps = () => {
      const newRest = componentsFunction.filterPaddingAndMargin(rest);
      return { ...newRest };
    };
  //#endregion

  //#region change
  const handelChange = (event) => {
    var selectedValue = event.target.value;
    var selectedKey = event.target.key;
    if(event.target.checked){
      selectedValue = options?.[1]?.value || true;
      selectedKey = options?.[1]?.key || true;
    }else{
      selectedValue = options?.[0]?.value || false;
      selectedKey = options?.[0]?.key || false;
    }

    inputRef.current.value = selectedValue
    inputRef.current.key = selectedKey
    inputRef.current.objValue = {key: selectedKey, value: selectedValue};
    inputRef.current.checked = event.target.checked
    if(ref){
      ref.current.value = selectedValue
      ref.current.key = selectedKey
      ref.current.objValue = {key: selectedKey, value: selectedValue};
      ref.current.checked = event.target.checked
    }

    if(showValue){
      inputState.value = selectedValue
      setInputState({...inputState})
    }
    if (onChange) {
      onChange(event);
    }
  };

//#endregion

return (
      <React.Suspense fallback="">
        <div
          id={getID.current}
          key={getID.current}
          className={`cbmis-main-container  ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
            rest
          )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
          {...getRestProps()}
        >
          <div className={componentsFunction.CheckDirectionItem(dir)}>
            <div className={`cbmis-switch-container  ${errorMessage ? "cbmis-input-have-error" : ""}`}>
              <div className="cbmis-switch-button-cover">
                <div className={`cbmis-switch-button ${componentsFunction.CheckDisabledItem(disabled)} ${inputState.switchStyleCircle}`} id={inputState.switchMode}>
                  <input
                    type="checkbox"
                    id={getID.current}
                    name={nameID.current}
                    className={`cbmis-switch-checkbox`}
                    ref={inputRef || ref}
                    // defaultValue={checkedState ? optionsState[1].value : optionsState[0].value}
                    onChange={handelChange}
                    // checked={checkedState}
                    defaultChecked={(ref?.current?.key === options?.[1]?.key || ref?.current?.value === options?.[1]?.value)|| (inputRef?.current?.key === options?.[1]?.key || inputRef?.current?.value === options?.[1]?.value)}
                    key={defaultValue?.key || getID.current}
                  />
                  <div className="cbmis-switch-knobs" data-active={showValue ? inputState.value : ""}
                  ></div>
                  <div className="cbmis-switch-layer"></div>
                </div>
              </div>
              {!label?null:(
                <label className="cbmis-radio-checkbox-label" htmlFor={nameID.current}>
                  {label}
                </label>
              )}
            </div>
            <ErrorMessage errorMessage={errorMessage} />
          </div>
        </div>
      </React.Suspense>
    )
});

export default Switch