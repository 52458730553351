import React, { useEffect, useRef } from "react";
import "./Accordion.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const AccordionDetails = (props) => {
  const { children, hidden, sx, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  if(props?.[strTestProps]){
    if(children){
      console.log("AccordionDetails", "children");
    }
    if(props?.sx){
      console.log("AccordionDetails", "sx",);
    }
  }
  const getID = useRef(id || componentsFunction.randomName("AccordionDetails"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [children, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-accordion-tab-details");
    }
  }, [sx, screenWidth]);

  return (
    <div
      id={getID.current}
      className={`cbmis-accordion-tab-details ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {children}
    </div>
  );
};

export default AccordionDetails;
