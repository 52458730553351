import { Grid, Icon, Paper, Typography } from "@cbmisorg/client-app";
import React from "react";
import { useApp } from "../../../../client/configuration/contextapi/context";

function FontSizeSection() {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;

  const handelChange = (size) => () => {
    switch (size) {
      case "md":
        appState.clientInfo.appFontSize = "md";
        break;
      case "lg":
        appState.clientInfo.appFontSize = "lg";
        break;
      case "xl":
        appState.clientInfo.appFontSize = "xl";
        break;
      default:
        appState.clientInfo.appFontSize = "sm";
        break;
    }
    appDispatch();
    window.location.reload();
  };

  return (
    <Paper className="cardContainer">
      <Grid container justify={"center"}>
        <Grid item xs="12">
          <Typography>{{ arb: "حجم خط التطبيق", eng: "Application font size" }?.[lang]}</Typography>
        </Grid>
        <Grid container justify={"space-evenly"} spacing={3}>
          <Grid item className="fontXL">
            <Icon
              icon={"formatSize"}
              onClick={handelChange("xl")}
              color={appState.clientInfo.appFontSize === "xl" ? "primary" : ""}
              style={{ background: `${appState.clientInfo.appFontSize === "xl" ? "#eee" : ""}` }}
            />
          </Grid>
          <Grid item className="fontLg">
            <Icon
              icon={"formatSize"}
              onClick={handelChange("lg")}
              color={appState.clientInfo.appFontSize === "lg" ? "primary" : ""}
              style={{ background: `${appState.clientInfo.appFontSize === "lg" ? "#eee" : ""}` }}
            />
          </Grid>
          <Grid item className="fontMd">
            <Icon
              icon={"formatSize"}
              onClick={handelChange("md")}
              color={appState.clientInfo.appFontSize === "md" ? "primary" : ""}
              style={{ background: `${appState.clientInfo.appFontSize === "md" ? "#eee" : ""}` }}
            />
          </Grid>
          <Grid item>
            <Icon
              icon={"formatSize"}
              onClick={handelChange("sm")}
              color={appState.clientInfo.appFontSize === "sm" ? "primary" : ""}
              style={{ background: `${appState.clientInfo.appFontSize === "sm" ? "#eee" : ""}` }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default FontSizeSection;
