import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import * as appVariables from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../appHelper/appQuery/GenerateQuery";

const tblSystemFetch = generateQueries("tblSystem");

const strPageInfo = "@src/components/stakeholders/admin/mngAnnouncement/mngAnnouncement.controller.js";

export default function mngAnnouncementCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    //#region manage announcement
    getAnnouncementFromDB:
      ({ state, setState, isLoading, setIsLoading, fields }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }

          state.blnIsFetchDone = true;

          setIsLoading(true);

          state.bigSystemID = appState?.userInfo?.bigSystemID;
          const result = await tblSystemFetch(strPageInfo, appState, "itcAppFindOne", {
            objectCondition: { bigSystemID: appState?.userInfo?.bigSystemID },
            arrAttributes: objTableAttributes.tblSystem.full,
          });

          if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote?.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          state.lstAnnouncement = Array.isArray(result?.response?.jsnLstAnnouncement) ? result?.response?.jsnLstAnnouncement : [];

          const objCat = appFunctions.getCategoryCache();

          let lstCountryOption = [];
          let objCountryOptionLang = {};
          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.id });
              objCountryOptionLang[value?.id] = value?.value;
            }
          });

          let lsAcademicYearOption = [];
          let objAcademicYearOptionLang = {};
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lsAcademicYearOption.push({ key: value?.id, value: value?.id });
              objAcademicYearOptionLang[value?.id] = value?.value;
            }
          });

          fields.bigCountryID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.lstCountryID.setOptions(lstCountryOption, objCountryOptionLang);
          fields.intAcademicYear.setOptions(lsAcademicYearOption, objAcademicYearOptionLang);
          fields.lstUserRole.setOptions(
            [
              { key: "student", value: "student" },
              { key: "entrepreneur", value: "entrepreneur" },
              { key: "manager", value: "manager" },
              { key: "sponsor", value: "sponsor" },
              { key: "newUser", value: "newUser" },
            ],
            {
              student: { eng: "Student", arb: "طالب" },
              entrepreneur: { eng: "Entrepreneur", arb: "ريادي" },
              manager: { eng: "Manager", arb: "مدير" },
              sponsor: { eng: "Sponsors", arb: "داعمين" },
              newUser: { eng: "New User", arb: "مستخدم جدد" },
            }
          );

          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "getAnnouncementFromDB-ERROR");
          setIsLoading(false);
        }
      },

    addNewAnnouncement:
      ({ state, setState, isLoading, setIsLoading, fields, upAssets }) =>
      async () => {
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error");
          return;
        }
        let bigID = appFunctions.generateID(4);

        if (state.lstFile?.length) {
          const result = await upAssets();
          if (!result) {
            return;
          }
        }

        state.lstAnnouncement.push({
          bigID: bigID,
          strAnnouncementTitle: fields.strAnnouncementTitle.getValue(),
          strAnnouncementBody: fields.strAnnouncementBody.getValue(),
          blnIsMeeting: !fields.blnIsMeeting.getValue()?.length ? false : true,
          strMeetingLink: fields.strMeetingLink.getValue(),
          blnSpecificCountry: !fields.blnSpecificCountry.getValue()?.length ? false : true,
          lstCountryID: (Array.isArray(fields.lstCountryID.getValue()) ? fields.lstCountryID.getValue() : [])?.map((objValue) => String(objValue?.key)) || [],
          blnSpecificUniversity: !fields.blnSpecificUniversity.getValue()?.length ? false : true,
          bigCountryID: fields.bigCountryID.getValue()?.key,
          lstUniversityID: (Array.isArray(fields.lstUniversityID.getValue()) ? fields.lstUniversityID.getValue() : [])?.map((objValue) => String(objValue?.key)) || [],
          blnSpecificAcademicYear: !fields.blnSpecificAcademicYear.getValue()?.length ? false : true,
          intAcademicYear: fields.intAcademicYear.getValue()?.key,
          blnWithTimeLimit: !fields.blnWithTimeLimit.getValue()?.length ? false : true,
          dtmTo: fields.dtmTo.getValue(),
          dtmFrom: fields.dtmFrom.getValue(),
          blnSpecificRole: !fields.blnSpecificRole.getValue()?.length ? false : true,
          blnIsPublic: !fields.blnIsPublic.getValue()?.length ? false : true,

          strTime: typeof fields?.strTime.getValue() === "object" ? fields?.strTime.getValue() : { eng: fields?.strTime.getValue(), arb: fields?.strTime.getValue() },
          lstUserRole: (Array.isArray(fields.lstUserRole.getValue()) ? fields.lstUserRole.getValue() : [])?.map((objValue) => String(objValue?.key)) || [],
          lstFile: state.lstFile,

          dtmCreatedDate: new Date().toUTCString(),
          dtmUpdatedDate: new Date().toUTCString(),
        });

        await handler.saveAnnouncementInDB({ state, setState, isLoading, setIsLoading })();
      },

    editExistingAnnouncement:
      ({ state, setState, isLoading, setIsLoading, fields, upAssets }) =>
      async () => {
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error");
          return;
        }

        if (state.lstFile?.length) {
          const result = await upAssets();
          if (!result) {
            return;
          }
        }

        state.lstAnnouncement[state.intEditIndex].strAnnouncementTitle = fields.strAnnouncementTitle.getValue();
        state.lstAnnouncement[state.intEditIndex].strAnnouncementBody = fields.strAnnouncementBody.getValue();
        state.lstAnnouncement[state.intEditIndex].blnIsMeeting = !fields.blnIsMeeting.getValue()?.length ? false : true;
        state.lstAnnouncement[state.intEditIndex].strMeetingLink = fields.strMeetingLink.getValue();
        state.lstAnnouncement[state.intEditIndex].blnSpecificCountry = !fields.blnSpecificCountry.getValue()?.length ? false : true;
        state.lstAnnouncement[state.intEditIndex].lstCountryID =
          (Array.isArray(fields.lstCountryID.getValue()) ? fields.lstCountryID.getValue() : [])?.map((objValue) => String(objValue?.key)) || [];
        state.lstAnnouncement[state.intEditIndex].blnSpecificUniversity = !fields.blnSpecificUniversity.getValue()?.length ? false : true;
        state.lstAnnouncement[state.intEditIndex].bigCountryID = fields.bigCountryID.getValue()?.key;
        state.lstAnnouncement[state.intEditIndex].lstUniversityID =
          (Array.isArray(fields.lstUniversityID.getValue()) ? fields.lstUniversityID.getValue() : [])?.map((objValue) => String(objValue?.key)) || [];
        state.lstAnnouncement[state.intEditIndex].blnSpecificAcademicYear = !fields.blnSpecificAcademicYear.getValue()?.length ? false : true;
        state.lstAnnouncement[state.intEditIndex].intAcademicYear = fields.intAcademicYear.getValue()?.key;
        state.lstAnnouncement[state.intEditIndex].blnWithTimeLimit = !fields.blnWithTimeLimit.getValue()?.length ? false : true;
        state.lstAnnouncement[state.intEditIndex].dtmTo = fields.dtmTo.getValue();
        state.lstAnnouncement[state.intEditIndex].dtmFrom = fields.dtmFrom.getValue();
        state.lstAnnouncement[state.intEditIndex].strTime = fields.strTime.getValue();
        state.lstAnnouncement[state.intEditIndex].blnSpecificRole = !fields.blnSpecificRole.getValue()?.length ? false : true;
        state.lstAnnouncement[state.intEditIndex].lstUserRole =
          (Array.isArray(fields.lstUserRole.getValue()) ? fields.lstUserRole.getValue() : [])?.map((objValue) => String(objValue?.key)) || [];
        state.lstAnnouncement[state.intEditIndex].lstFile = state.lstFile;
        state.lstAnnouncement[state.intEditIndex].blnIsPublic = !fields.blnIsPublic.getValue()?.length ? false : true;

        state.lstAnnouncement[state.intEditIndex].dtmUpdatedDate = new Date().toUTCString();

        await handler.saveAnnouncementInDB({ state, setState, isLoading, setIsLoading })();
      },

    deleteAnnouncement:
      ({ state, setState, isLoading, setIsLoading }) =>
      (intIndex) =>
      () => {
        Confirmation.viewConfirmation(
          dictionary.components.mngAnnouncement.Announcement.confirmation.deleteAnnouncement[lang],
          () => {
            state.lstAnnouncement.splice(intIndex, 1);

            handler.saveAnnouncementInDB({ state, setState, isLoading, setIsLoading })();
          },
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    saveAnnouncementInDB:
      ({ state, setState, isLoading, setIsLoading }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          const result = await tblSystemFetch(strPageInfo, appState, "itcAppUpdate", {
            objectCondition: { bigSystemID: state.bigSystemID },
            objectInput: { jsnLstAnnouncement: state.lstAnnouncement, dtmUpdatedDate: new Date().toUTCString() },
          });

          if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          state.blnOpenDialog = false;

          setIsLoading(false);
          setState({ ...state });
          appDispatch();

          Alert.viewAlert(dictionary.shared.alertFetchNote.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "saveAnnouncementInDB-ERROR");
          setIsLoading(false);
        }
      },

    openModal:
      ({ state, setState, fields, blnIsViewLiveSession }) =>
      (blnIsAdd, intIndex, objInfo) =>
      () => {
        fields.controller.resetForm();

        state.blnOpenDialog = true;
        state.blnIsAdd = blnIsAdd;
        state.intEditIndex = intIndex;
        state.lstFile = [];
        fields.blnIsMeeting.setValue(blnIsViewLiveSession ? ["true"] : []);
        fields.blnWithTimeLimit.setValue(blnIsViewLiveSession ? ["true"] : []);

        if (!blnIsAdd) {
          fields.strAnnouncementTitle.setValue(objInfo?.strAnnouncementTitle);
          fields.strAnnouncementBody.setValue(objInfo?.strAnnouncementBody);
          //fields.blnIsMeeting.setValue(objInfo?.blnIsMeeting ? ["true"] : []);
          fields.strMeetingLink.setValue(objInfo?.strMeetingLink);
          fields.blnSpecificCountry.setValue(objInfo?.blnSpecificCountry ? ["true"] : []);
          fields.lstCountryID.setValue(Array.isArray(objInfo?.lstCountryID) ? objInfo?.lstCountryID : []);
          fields.blnSpecificUniversity.setValue(objInfo?.blnSpecificUniversity ? ["true"] : []);
          fields.bigCountryID.getValue(objInfo?.bigCountryID);
          handler.changeUniversityOption({ fields })({ target: { id: objInfo?.bigCountryID } });
          fields.lstUniversityID.setValue(Array.isArray(objInfo?.lstUniversityID) ? objInfo?.lstUniversityID : []);
          fields.blnSpecificAcademicYear.setValue(objInfo?.blnSpecificAcademicYear ? ["true"] : []);
          fields.intAcademicYear.setValue(objInfo?.intAcademicYear);
          fields.blnWithTimeLimit.setValue(blnIsViewLiveSession || objInfo?.blnWithTimeLimit ? ["true"] : []);
          fields.dtmTo.setValue(objInfo?.dtmTo);
          fields.dtmFrom.setValue(objInfo?.dtmFrom);
          fields?.strTime.setValue(objInfo?.strTime);
          fields.blnSpecificRole.setValue(objInfo?.blnSpecificRole ? ["true"] : []);
          fields.lstUserRole.setValue(objInfo?.lstUserRole || []);
          fields.blnIsPublic.setValue(objInfo?.blnIsPublic ? ["true"] : []);
          state.lstFile = objInfo?.lstFile;
        }

        setState({ ...state });
      },

    changeUniversityOption:
      ({ fields }) =>
      (event) => {
        let bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();
        const catName = appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID);

        let lstUniversityOption = [];
        let objUniversityOptionLang = {};
        Object.entries(objCat?.Root?.country?.[catName]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        fields.lstUniversityID.setOptions(lstUniversityOption);
      },

    closeModal:
      ({ state, setState }) =>
      () => {
        setState({ ...state, blnOpenDialog: false });
      },
    //#endregion

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //#region display counter
    getCounterAnnouncementToDisplay: async (jsnLstAnnouncement = null) => {
      try {
        let lstAnnouncement = [];
        if (!jsnLstAnnouncement) {
          const result = await tblSystemFetch(strPageInfo, appState, "itcAppFindOne", {
            objectCondition: { bigSystemID: appState?.userInfo?.bigSystemID },
            arrAttributes: ["bigSystemID", "jsnLstAnnouncement"],
          });

          if (!result.blnIsRequestSuccessful) {
            return;
          }
          lstAnnouncement = Array.isArray(result?.response?.jsnLstAnnouncement) ? result?.response?.jsnLstAnnouncement : [];
        } else {
          lstAnnouncement = Array.isArray(jsnLstAnnouncement) ? jsnLstAnnouncement : [];
        }

        let lstMeeting = [];
        let lstNotification = [];
        for (let i = 0; i < lstAnnouncement.length; i++) {
          if (lstAnnouncement[i]?.blnWithTimeLimit && !lstAnnouncement[i]?.blnIsMeeting) {
            if (
              appFunctions.getDifferenceBetweenTwoDate(new Date(lstAnnouncement[i]?.dtmTo).toUTCString(), new Date().toUTCString(), "hour") < 0 &&
              appFunctions.getDifferenceBetweenTwoDate(new Date().toUTCString(), new Date(lstAnnouncement[i]?.dtmFrom).toUTCString(), "hour") < 0
            ) {
              continue;
            }
          }

          if (lstAnnouncement[i]?.blnIsMeeting) {
            if (!handler.getIsMeetingAvailable(lstAnnouncement?.[i]) && !handler.getIsLiveMeetingLive(lstAnnouncement?.[i])) {
              continue;
            }
          }

          if (lstAnnouncement[i]?.blnIsMeeting) {
            lstMeeting.push(lstAnnouncement[i]);
          } else {
            lstNotification.push(lstAnnouncement[i]);
          }
        }

        return { lstMeeting, lstNotification };
      } catch (error) {
        appFunctions.logMessage(strPageInfo, error, "getCounterAnnouncementToDisplay-ERROR");
        return { lstMeeting: [], lstNotification: [] };
      }
    },
    //#endregion

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //#region display announcement
    getAnnouncementToDisplay:
      ({ state, setState }) =>
      async () => {
        try {
          state.blnIsFetchDone = true;

          const result = await tblSystemFetch(strPageInfo, appState, "itcAppFindOne", {
            objectCondition: { bigSystemID: appState?.userInfo?.bigSystemID },
            arrAttributes: ["bigSystemID", "jsnLstAnnouncement"],
          });

          if (!result.blnIsRequestSuccessful) {
            return;
          }
          const lstAnnouncement = Array.isArray(result?.response?.jsnLstAnnouncement) ? result?.response?.jsnLstAnnouncement : [];

          let lstMeeting = [];
          let lstNotification = [];
          let lstLastAnnouncement = [];
          for (let i = 0; i < lstAnnouncement.length; i++) {
            if (lstAnnouncement[i]?.blnWithTimeLimit && !lstAnnouncement[i]?.blnIsMeeting) {
              if (
                !(
                  new Date(new Date().toUTCString()).getTime() >= new Date(new Date(lstAnnouncement[i]?.dtmFrom).toUTCString()).getTime() &&
                  new Date(new Date().toUTCString()).getTime() <= new Date(new Date(lstAnnouncement[i]?.dtmTo).toUTCString()).getTime()
                )
              ) {
                continue;
              }
            }

            if (lstAnnouncement[i]?.blnSpecificRole) {
              /*  if (String(appVariables.objUserRole.Student) === String(appState?.userInfo?.jsnUserInfo?.intUserRoleFK)) {
              }

              if (String(appVariables.objUserRole.Sponsor) === String(appState?.userInfo?.jsnUserInfo?.intUserRoleFK)) {
                if (!(lstAnnouncement[i]?.lstUserRole || [])?.includes("sponsor")) {
                  continue;
                }
              } */
              let blnCanViewMsg = false;
              if (appState?.userInfo?.blnIsStudent && (lstAnnouncement[i]?.lstUserRole || [])?.includes("student")) {
                blnCanViewMsg ||= true;
              }
              if (appState?.userInfo?.blnIsManager && (lstAnnouncement[i]?.lstUserRole || [])?.includes("manager")) {
                blnCanViewMsg ||= true;
              }
              if (appState?.userInfo?.blnIsStartUp && (lstAnnouncement[i]?.lstUserRole || [])?.includes("entrepreneur")) {
                blnCanViewMsg ||= true;
              }
              if (appState?.userInfo?.blnIsSponsor && (lstAnnouncement[i]?.lstUserRole || [])?.includes("sponsor")) {
                blnCanViewMsg ||= true;
              }
              if (
                !appState?.userInfo?.blnIsStudent &&
                !appState?.userInfo?.blnIsManager &&
                !appState?.userInfo?.blnIsStartUp &&
                !appState?.userInfo?.blnIsSponsor &&
                (lstAnnouncement[i]?.lstUserRole || [])?.includes("newUser")
              ) {
                blnCanViewMsg ||= true;
              }

              if (!blnCanViewMsg) {
                continue;
              }
            }

            if (lstAnnouncement[i]?.blnSpecificAcademicYear) {
              if (String(appState?.userInfo?.jsnUserInfo?.strAcademicYear) !== String(lstAnnouncement?.[i]?.intAcademicYear)) {
                continue;
              }
            }

            if (lstAnnouncement[i]?.blnSpecificUniversity) {
              if (!lstAnnouncement?.[i]?.lstUniversityID?.includes(String(appState?.userInfo?.jsnUserInfo?.strUniversityName))) {
                continue;
              }
            }

            if (lstAnnouncement[i]?.blnSpecificCountry) {
              if (!lstAnnouncement?.[i]?.bigCountryID?.includes(String(appState?.userInfo?.jsnUserInfo?.bigCountryID))) {
                continue;
              }
            }

            if (lstAnnouncement[i]?.blnIsMeeting) {
              if (!handler.getIsMeetingAvailable(lstAnnouncement?.[i]) && !handler.getIsLiveMeetingLive(lstAnnouncement?.[i])) {
                continue;
              }
            }

            if (lstAnnouncement[i]?.blnIsMeeting) {
              lstMeeting.push(lstAnnouncement[i]);
            } else {
              lstNotification.push(lstAnnouncement[i]);
            }
            lstLastAnnouncement.push({
              ...lstAnnouncement[i],
              intHourLate: appFunctions.getDifferenceBetweenTwoDate(lstAnnouncement[i]?.dtmCreatedDate, new Date(), "hour"),
            });
          }

          lstLastAnnouncement.sort((a, b) => a?.intHourLate - b?.intHourLate);
          if (!appFunctions.checkIsValidDate(appState?.clientInfo?.dtmLastSendNotification)) {
            appState.clientInfo.dtmLastSendNotification = appFunctions.getSpecificDateFromNow("before", "day", 19);
          }
          if (lstLastAnnouncement?.length && appFunctions.getDifferenceBetweenTwoDate(appState?.clientInfo?.dtmLastSendNotification, new Date(), "hour") > 18) {
            handler.offlineNotification(
              lstLastAnnouncement?.[0]?.strAnnouncementTitle?.[lang],
              {
                body: lstLastAnnouncement?.[0]?.strAnnouncementBody?.[lang],
                dir: lang === "arb" ? "rtl" : "ltr",
              },
              false
            );
            appState.clientInfo.dtmLastSendNotification = new Date();
          }

          lstMeeting.sort((a, b) => new Date(b?.dtmFrom).getTime() - new Date(a?.dtmFrom).getTime());

          state.lstMeeting = lstMeeting;
          state.lstNotification = lstNotification;

          setState({ ...state });
          appDispatch();
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "getAnnouncementToDisplay-ERROR");
        }
      },

    offlineNotification: (title, option, isTest = true) => {
      try {
        if (typeof window === "undefined") {
          return;
        }
        if (!("Notification" in window && navigator.serviceWorker)) {
          if (isTest) {
            console.warn("This browser does not support desktop notification");
          }
        } else if (Notification.permission === "granted") {
          new Notification(title, option);
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
              new Notification(title, option);
            }
          });
        }
      } catch {}
    },
    getIsMeetingAvailable: (item) => {
      try {
        return new Date(new Date(item?.dtmFrom).toUTCString()).getTime() >= new Date(new Date().toUTCString()).getTime();
      } catch {
        return true;
      }
    },

    getIsLiveMeetingLive: (item) => {
      try {
        return (
          new Date(new Date().toUTCString()).getTime() >= new Date(new Date(item?.dtmFrom).toUTCString()).getTime() &&
          new Date(new Date().toUTCString()).getTime() <= new Date(new Date(item?.dtmTo).toUTCString()).getTime()
        );
      } catch {
        return false;
      }
    },

    goToExternalMeeting: (link) => () => {
      try {
        if (typeof window === "undefined") {
          return;
        }
        window.open(link, "_blank");
      } catch {}
    },
    //#endregion
  };

  return handler;
}
