import { useLocation, useNavigate } from "@cbmisorg/router";
import React, { useEffect, useState } from "react";
import { useApp } from "../../../configuration/contextapi/context";
import { useParams } from "@cbmisorg/client-app";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./surveymonkey.style";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { CtrlSurveymonkey } from "./surveymonkey.ctrl";
import ShredHeader from "../../shared/shredHeader";
import { Button, Container, Grid, Loader, Typography } from "@cbmisorg/material";
import { App_info_Color } from "../../../util/appStyle";
import { Icon } from "@cbmisorg/icons";
import { dictionary } from "../../../util/appLanguage/dictionary";
import { objUserRole } from "../../../configuration/appVariables";


const labels = dictionary?.components?.stakeholders?.admin?.mngSurvey;

function SurveymonkeyView() {
  const { appState, appDispatch } = useApp();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { surveyCode, bigAnswerID } = useParams();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setState] = useState({
    blnIsInitDone: false,
    objSurveyInfo: {},
  });

  const fields = FormData({
    blnIsConfirmed: {
      control: "checkbox",
      options: [{ key: "true", value: labels?.sentAaaiotEmp }],
    },
  });

  const handlers = CtrlSurveymonkey({ appState, appDispatch, isLoading, setIsLoading, surveyCode, bigAnswerID });
  const initData = handlers.initData({ setState, state: stateInfo, navigate, fields, objAnswer: state?.objAnswer });
  const handleSubmit = handlers?.handleSubmit({ state: stateInfo, navigate, fields });

  useEffect(() => {

    if (!stateInfo?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader lang={lang} title={labels?.survey?.[lang]} blnBack={false} sx={{ position: "sticky" }} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer} py-3>
          <Grid container justifyContent={"center"} alignSelf="center" mt-5>
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container}>
                <Grid container justifyContent="center" py-5>
                  <Grid item xs="12" justifyContent="center">
                    <Typography as="subtitle2" className={`${classes?.mainTitle}`}>
                      {stateInfo?.objSurveyInfo?.jsnSurveyInfo?.strTitle?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs="12" justifyContent="center">
                    <Typography as="body2" className={`${classes?.labelsLight}`}>
                      {stateInfo?.objSurveyInfo?.jsnSurveyInfo?.strSubtitle?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" pb-8>
                  <Grid item xs="12" justifyContent="center" className={`${classes?.descriptionLightText}`}>
                    <Typography as="body2" className={`${classes?.labelsLight}`}>
                      {stateInfo?.objSurveyInfo?.jsnSurveyInfo?.strDscrp?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto" md="0" className={`${classes?.arrowClose} arrowClose`}>
                    <Icon icon={"arrowcircleup"} color={App_info_Color} />
                  </Grid>
                </Grid>

                {stateInfo.objSurveyInfo?.lstSurveyQus?.map((qs) => {
              
                  if (qs?.questionNo && (!fields?.[qs?.questionNo]?.getValue() || fields?.[qs?.questionNo]?.getValue()?.key === "false" || fields?.[qs?.questionNo]?.getValue() === "false")) return null                  
                  return (
                    <Grid container spacing={3} key={qs?.bigQID} className={classes?.gridBorder}>
                      {!qs?.strQCaptionB ? null : (
                        <Grid item xs="12">
                          <Typography as="body3" className={classes?.labelsLight}>
                            {qs?.strQCaptionB?.[lang]}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs="12">
                        <Typography as="subtitle3" className={classes?.labelsInfo}>
                          {qs?.strQ?.[lang]}
                        </Typography>
                        <FormField objHandler={fields?.[qs?.bigQID]} color={App_info_Color} className={classes?.field}  />
                      </Grid>
                      {!qs?.strQCaptionA ? null : (
                        <Grid item xs="12">
                          <Typography as="body3" className={classes?.labelsLight}>
                            {qs?.strQCaptionA?.[lang]}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}

                {bigAnswerID && appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.RootAdmin ? (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item>
                        <FormField objHandler={fields?.blnIsConfirmed} color={App_info_Color} className={classes?.field}/>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}

                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      label={
                        bigAnswerID && appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.RootAdmin
                          ? dictionary?.shared?.buttons?.update?.[lang]
                          : dictionary?.shared?.buttons?.send?.[lang]
                      }
                      py-0
                      px-3
                      mode="outlined"
                      color={App_info_Color}
                      onClick={handleSubmit}
                      className={classes?.btn}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default SurveymonkeyView;
