import * as FormHelper from "./FormHelper"
import * as FormVariables from "./formVariables/FormVariables"
import { checkParseObject } from "../../../helper/CbmisFunction"

function inputHelper(controller,fieldName) {
  const { formState, setFormState, setValue, addCheckboxValue, deleteCheckboxValue}= controller

  //#region updateValue

   //#region for text and textarea
 const handleTextValueChange = (event) => {
  const fieldName = event.target.id
  const value = event.target.value
  setValue(fieldName, value)
};

// for text and textarea with languages
const handleTextLangValueChange = (event,language) => {
  const value = event.target.value
  const fieldName = event.target.id
  var fieldValue = formState?.fields?.[fieldName]?.value
  const appLanguage = formState.appLanguage
  if(typeof fieldValue === "string"){
    if(appLanguage){
      fieldValue = {
        [appLanguage]:fieldValue,
      }
    }else{
      fieldValue = {}
    }
  }
  fieldValue[language] = value
  setValue(fieldName, fieldValue)
};

const handleTextChange = (event,inputCurrentLang)=>{
  const fieldName = event.target.id
  const isMultiLanguage = formState?.fields?.[fieldName]?.objLanguage?.isMultiLanguage || false
  if(!isMultiLanguage){
    handleTextValueChange(event)
  }else{
    handleTextLangValueChange(event,inputCurrentLang)
  }

}
//#endregion

//#region number & rangeSlider
const handleNumberChange = (event)=>{
  const fieldName = event.target.id
    setValue(fieldName, parseInt(event?.target?.value))
}
//#endregion

//#region Ratting
const handleRattingChange = (event)=>{
  const id = String(event.target.id).split("-")
  const value = parseInt(id[0].charAt(id[0].length - 1))
  setValue(fieldName, value)
}
//#endregion

const handleColorChange = (event)=>{
  const value = event.target.value
  setValue(fieldName, value)

}

const handleSwitchChange = (event)=>{
  var options = formState?.fields?.[fieldName]?.options
  if(!Array.isArray(options) && typeof options === "object"){
    options= Object.keys(options).map(value => {
      return {key: options[value], value: value}
    })
  }
  var item = options?.[0] || { key: false, value: "off" }
  if(event.target.checked){
  item = options?.[1] || { key: true, value: "on" }
  }
  setValue(fieldName, item)
}
//#endregion



  //#region for radio and list
  const handleOptionInputChange = (event) => {
      const value = event.target.value
      var key = event.target.value
      if (event.target.id !== "") {
        key = event.target.id;
      }
      let item= {"key":key,"value":value}
      setValue(fieldName, item)
  };
  //#endregion

  //#region for checkbox and multipleList
  const handleMultipleOptionsInputChange = (event) => {
      const value = event.target.value
      var key = event.target.value
      if (event.target.id !== "") {
        key = event.target.id;
      }

      let item= {"key":key,"value":value}

      if(event.target.checked){
        addCheckboxValue(fieldName,item)
       }else{
        deleteCheckboxValue(fieldName,key)

       }
  };
  //#endregion

  const handelChange=(event,inputCurrentLang=null)=>{
    const control = String(formState?.fields?.[fieldName]?.control).toLowerCase()
    const fieldType = FormVariables.fieldType
    if(control === fieldType.number || control === fieldType.rangeSlider){
      handleNumberChange(event)
    }else if(control === fieldType.radio || control === fieldType.list){
      handleOptionInputChange(event)
    }else if(control === fieldType.checkbox || control === fieldType.multipleList){
      handleMultipleOptionsInputChange(event)
    }else if(control === fieldType.rating){
        handleRattingChange(event)
    }else if(control === fieldType.color){
      handleColorChange(event)
    }else if(control === fieldType.switch){
      handleSwitchChange(event)
    }else{
     
      handleTextChange(event,inputCurrentLang)

    }
  }

  const handelBlur=(event)=>{
    const errorMessage = FormHelper.checkFormValueByState(fieldName, formState)?.error
    const newFormState = FormHelper.updateError(errorMessage,fieldName, formState)
    setFormState(newFormState)
  }
  //#endregion

  //#region  label
  const getLanFrmObject = (label,lang,appDefaultLanguage,key="label")=>{
    if(label?.[lang] || label?.[appDefaultLanguage]){
      return label[lang] || label?.[appDefaultLanguage]
    }else if(label?.[key]?.[lang] || label?.[key]?.[appDefaultLanguage]){
      return label?.[key]?.[lang] || label?.[key]?.[appDefaultLanguage]
    }else{
      return ""
    }
  }

  const getLabel=(language)=>{
    const label = formState?.labels?.[fieldName] || ""
    const appLanguage = formState.appLanguage
    const appDefaultLanguage = formState.appDefaultLanguage
    const isMultiLanguage = formState?.fields?.[fieldName]?.objLanguage?.isMultiLanguage

    if(typeof label === "object"){
      if(isMultiLanguage && language){
        return getLanFrmObject(label,language,appDefaultLanguage)
      }else{
        return getLanFrmObject(label,appLanguage,appDefaultLanguage)

      }
    }else{
      return label
    }
  }

  const getPlaceholder=(language)=>{
    const label = formState?.labels?.[fieldName] || ""
    const appLanguage = formState.appLanguage
    const appDefaultLanguage = formState.appDefaultLanguage
    const isMultiLanguage = formState?.fields?.[fieldName]?.objLanguage?.isMultiLanguage

    if(typeof label === "object"){
      if(isMultiLanguage && language){
        return getLanFrmObject(label,language,appDefaultLanguage,"placeholder")
      }else{
        return getLanFrmObject(label,appLanguage,appDefaultLanguage,"placeholder")
      }
    }else{
      return label
    }
  }
  //#endregion

  //#region  lang
  const getObjLanguage=()=>{
    if(formState?.fields?.[fieldName]?.objLanguage?.isMultiLanguage){
      return formState?.fields?.[fieldName]?.objLanguage?.language || formState?.objLanguage
    }
    return null
  }
  //#endregion

  //#region  option
    const getOptions = (fieldLanguage=null)=>{
      const appLanguage = formState.appLanguage;
      const appDefaultLanguage = formState.appDefaultLanguage
      const objOptionLangLabel = formState?.labels?.[fieldName]?.options || {};

      let options= formState?.fields?.[fieldName]?.options

      if (!Array.isArray(options) && typeof options === "object"){
        const arrObjectOption = Object.entries(checkParseObject(options));
        options = arrObjectOption?.map(opt=>{
          return {key:objOptionLangLabel?.[opt?.value]?.[fieldLanguage] ||  opt?.[1]?.key || opt?.[1], 
                  value:objOptionLangLabel?.[opt[0]]?.[appLanguage] || objOptionLangLabel?.[opt[0]]?.[appDefaultLanguage] || opt[0],
                  jxs: opt?.[1]?.jxs || null,
                }
        })
        return options
      }else if(Array.isArray(options)){
          options = options?.map(opt=>{
            return {key:opt?.key, 
                    value:objOptionLangLabel?.[opt?.value]?.[fieldLanguage] || objOptionLangLabel?.[opt?.value]?.[appLanguage] || objOptionLangLabel?.[opt?.value]?.[appDefaultLanguage] || opt?.value,
                    jxs: opt?.jxs || null,
                  }
        })
        return options
      }

      return options
    }



  //#endregion

  //#region hidden && disabled
  const CheckCondition=(type)=>{
    let check = false
    const lstFieldValuesCondition = formState?.fields?.[fieldName]?.[type]?.lstFieldValuesCondition
    if(Array.isArray(lstFieldValuesCondition)){
     lstFieldValuesCondition.forEach(fieldName=>{
      if(!formState?.fields?.[fieldName]?.value){
        check = true
        return
      }
    })
    }
    if(!check){
      const condition = formState?.fields?.[fieldName]?.[type]?.condition
      if(typeof condition !== "undefined"){
        if(typeof condition === "boolean"){
          check =  condition
        }else{
          check = condition()
        }
      }
    }
    return check
  }

  const getHidden = ()=>{
    return CheckCondition("hidden")
  }

  const getDisabled = ()=>{
    return CheckCondition("disabled")
  }
  //#endregion

  return {
    getLabel,
    getPlaceholder,
    getObjLanguage,
    getOptions,
    getHidden,
    getDisabled,

    handelChange,
    handelBlur,
  }
}

export default inputHelper