import React, { useEffect, useRef } from "react";
import "./Tooltip.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Tooltip = (props) => {
  const { title, position, mode, color, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Tooltip"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-tooltip-text") });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [title, position, mode, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx || color) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, { ...(sx ? sx : {}), ...(color ? { background: `${color}` } : {}) }, "cbmis-tooltip-text");
    }
  }, [sx, screenWidth, color]);

  useEffect(() => {
    if (color && title) {
      document.documentElement.style.setProperty("--cbmis-tooltip-arrow-color", componentsFunction.checkColorReturnValue(color));
    }
  }, [color]);

  const getPositionTooltip = () => {
    const getPosition = String(position).toLowerCase();
    const getStyle = String(mode).toLowerCase();
    if (getStyle === "arrow") {
      switch (getPosition) {
        case "top":
          return "cbmis-tooltip-text-position-top-arrow";
        case "left":
          return "cbmis-tooltip-text-position-left-arrow";
        case "bottom":
          return "cbmis-tooltip-text-position-bottom-arrow";
        case "right":
          return "cbmis-tooltip-text-position-right-arrow";
        default:
          return "cbmis-tooltip-text-position-top-arrow";
      }
    } else {
      switch (getPosition) {
        case "top":
          return "cbmis-tooltip-text-position-top";
        case "left":
          return "cbmis-tooltip-text-position-left";
        case "bottom":
          return "cbmis-tooltip-text-position-bottom";
        case "right":
          return "cbmis-tooltip-text-position-right";
        default:
          return "cbmis-tooltip-text-position-top";
      }
    }
  };

  const positionTooltip = useRef(getPositionTooltip());

  return (
    <div className={`cbmis-tooltip`} id={getID.current}>
      {title ? (
        <div
          id={styleRef.current.strSXComponentID}
          className={`cbmis-tooltip-text  ${positionTooltip.current} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
            disabled
          )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${
            className || ""
          }`}
          {...getRestProps()}
        >
          <label className="cbmis-tooltip-label"> {title}</label>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Tooltip;
