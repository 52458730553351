import React, { forwardRef, useContext, useEffect, useId } from "react";
import { styleFunction, ContextThemeProvider } from "@cbmisorg/styles";
import RadioButtonsStyle from "./RadioButtonsStyle";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { checkParseObject } from "../../_helper/functions";
import ErrorBoundary from "../../_helper/errorHandeling/ErrorBoundary/ErrorBoundary";

const RadioButtons = forwardRef((/**@type {any}*/ props, ref) => {
  const {
    label,
    currentLang,
    options,
    children,
    display,
    color,
    sx,
    hidden,
    disabled,
    dir,
    value = null,
    defaultValue = null,
    className,
    id,
    filterValue,
    blnMenuInputList = false,
    errorMessage,
    helperMessage,
    ...rest
  } = props;
  const comID = id || String(useId()).slice(1, -1) + "radio";
  const theTheme = useContext(ContextThemeProvider);
  const { classesName, style, styleSx } = RadioButtonsStyle(theTheme);
  const classes = style();
  const classesSx = styleSx({ sx, color, display, blnMenuInputList });
  const itemClasses = styleFunction.getClassName(
    classes?.[classesName?.radio_container],
    classesSx?.[classesName?.radio_container],
    classesSx?.[classesName?.sx],
    styleFunction.CheckDirectionItem(dir),
    styleFunction.CheckDisabledItem(disabled),
    styleFunction.CheckHiddenItem(hidden),
    styleFunction.checkExistsMargin(rest),
    styleFunction.checkExistsPadding(rest),
    className
  );

  const RadioButtonsComp = () => {
    let lstOptions = [];
    if (children) {
      lstOptions = children;
    } else if (options) {
      lstOptions = options;
    }
    return (
      <div className={`${itemClasses} ${classes?.[classesName?.radio_label]}`} key={props?.key || comID}>
        {!label ? null : <label className={`${classes?.[classesName?.radio_label]} ${classesSx?.[classesName?.radio_label]}`}>{label}</label>}
        {lstOptions?.map((opt, index) => {
          const optValue = props?.blnMenuInputList
            ? value && typeof value === "object"
              ? checkParseObject(value)
              : defaultValue && typeof defaultValue === "object"
              ? checkParseObject(defaultValue)
              : value || defaultValue || {}
            : checkParseObject(value || defaultValue || {});

          return (
            <label key={opt?.key + index} className={`${classes?.[classesName?.radio_opt]} ${classesSx?.[classesName?.radio_opt]}`}>
              <input
                name={comID}
                type="radio"
                className={`${classes?.[classesName?.radio]} ${classesSx?.[classesName?.radio]}`}
                ref={ref}
                id={opt?.key}
                value={opt?.props?.value || opt?.value?.[currentLang] || opt?.value || opt?.key}
                checked={
                  typeof optValue === "object" ? String(typeof opt === "object" ? opt?.key : opt) === String(optValue?.key) : String(typeof opt === "object" ? opt?.key : opt) === String(optValue)
                }
                {...styleFunction?.getRestProps(rest)}
                key={opt?.key + "_input_" + index}
              />
              <div className={`${classes?.[classesName?.radio_opt_label]}`}>
                <ErrorBoundary>
                  {opt?.props?.children || opt?.props?.value || typeof opt?.jsx === "function" ? opt?.jsx() : typeof opt?.value !== "object" ? opt?.value : opt?.value?.[currentLang] || ""}
                </ErrorBoundary>
              </div>
            </label>
          );
        })}
        <ErrorMessage message={errorMessage || helperMessage || null} blnError={errorMessage? true: false} />
      </div>
    );
  };

  useEffect(() => {}, [
    label,
    currentLang,
    options,
    children,
    display,
    color,
    sx,
    hidden,
    disabled,
    dir,
    value,
    defaultValue,
    className,
    id,
    filterValue,
    blnMenuInputList,
    errorMessage,
    helperMessage,
  ]);

  return (
    <React.Fragment>
      <RadioButtonsComp />
      <span
        style={{ display: "none" }}
        hidden={true}
        className={`${classes?.[classesName?.radio_opt]} ${classes?.[classesName?.radio_opt_label]} ${classes?.[classesName?.radio]} ${classesSx?.[classesName?.radio]}`}
      />
    </React.Fragment>
  );
});

export default RadioButtons;
