import { Container, Grid, Typography } from "@cbmisorg/material";
import { ClassesBuilder } from "@cbmisorg/styles";
import React from "react";
import { objStyle } from "../registration/Registration.style";
import { useApp } from "../../../configuration/contextapi/context";
import { App_info_Color } from "../../../util/appStyle";
import ShredHeader from "../../shared/shredHeader";

const style = {
  h1: {
    fontFamily: `'Alfa Slab One', cursive`,
    margin: "0 auto",
    inline: "block",
    textAlign: "center",
    animationName: "darken",
    animationDuration: "5s",
    animationIterationCount: "infinite",
  },

  h3: {
    fontFamily: `'Josefin Slab', serif`,
    margin: "0 auto",
    inline: "block",
    textAlign: "center",
  },
};
function Page404View() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });

  return (
    <React.Fragment>
      <ShredHeader lang={lang} blnBack={true} back={-2} blnHome={false}/>
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"} alignSelf="center">
            <Grid item xs="12" md="9" lg="8" xl="7" className={classes?.bgBox}>
              <Container className={classes?.container} pt-10>
                <Grid container justifyContent={"center"}>
                  <Grid item xs="12" justifyContent={"center"}>
                    <Typography as="h1" color={App_info_Color} sx={style?.h1}>
                      404
                    </Typography>
                  </Grid>
                  <Grid item xs="12" justifyContent={"center"}>
                    <Typography as="h3" color={App_info_Color} sx={style?.h3}>
                      {{ arb: "الصفحة التي تبحث عنها غير موجودة", eng: "The page you are looking for cannot be found" }?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Page404View;
