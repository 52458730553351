import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../../util/appStyle";

export const objStyle = makeStyle({
  secDark: {
    position: "relative",
    display: "block",
    width: "100%",
    minHeight: "calc(100vh - 0px)",
    overflowY: "auto",
    padding: "0px",
    margin: "auto",
    paddingTop: "45px",
    backgroundColor: "rgb(0, 2, 36)",
    background: "linear-gradient(155deg, #000224 0%, #010219 50%, #0371a0 100%)",
    userSelect: "none",
    "&*":{
      userSelect: "none",
    }
  },
  mainContainer: {
    width: `${(11 / 12) * 100}%`,
    xl: {
      width: `${(9 / 12) * 100}%`,
    },
  },
  line: {
    width: "100%",
    // padding: "20px 0 10px",
    color: appStyle?.App_Dark_menu_Color,
  },
  anmition: {
    position: "absolute",
    bottom: "0",
    right: "0",
    overflow: "hidden",
    height: "200px",
    width: "100%",
    direction: "rtl",
  },
  anmitiongif: {
    position: "relative",
    display: "block",
    height: "350px",
    width: "350px",
    margin: "0",
    padding: "0",
    background: `url(${require("../../../../util/assets/images/gif2.gif")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    opacity: ".2",
    overflow: "hidden",
    backdropFilter: "blur(100px)",
  },
  field: {
    fieldset: {
      borderRadius: 0,
    },
    input: {
      color: appStyle.App_Input_Text_Color,
      ...appStyle?.fontsMada,
    },
    label: {
      ...appStyle?.fontsMada,
    },
    ".uicore_switch_label_1gtyshu": {
      color: appStyle?.App_Light_Text_Color,
    },
    ".uicore_menu": {
      background: appStyle?.App_Dark_Color,
      border: "1px solid",
      borderColor: appStyle?.App_info_Color,
      borderRadius: 0,
      color: appStyle?.App_Light_Text_Color,
      ...appStyle?.fontsMada,
      ".uicore_radio_opt_label": {
        "&hover": {
          background: appStyle?.App_Dark_menu_Color,
        },
      },
    },
  },
  bgBox: (props) => ({
    height: "100%",
    padding: "20px 30px",
    zIndex: "auto",
    boxShadow: "none",
    transition: "all 2s",
    userSelect: "none",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      background: `url(${require("../../../../util/assets/images/box1-h.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform: props?.lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
  }),
  bgBox2: (props) => ({
    height: "100%",
    padding: "20px",
    zIndex: "auto",
    boxShadow: "none",
    overflow: "hidden",

    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      background: `url(${require("../../../../util/assets/images/box3.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform: props?.lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
  }),
  btnBoxHref: {
    textDecoration: "none !important",
  },
  btnBox: {
    fontSize: "large",
    borderWidth: "2px",
    span: {
      ...appStyle?.fontsMada,
    },
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
      color: appStyle.App_Light_Text_Color,
    },
    sm: {
      fontSize: "medium",
    },
    xs: {
      fontSize: "small",
      fontWeight: "bold",
      minWidth: "120px",
      paddingInline: "4px"
    },
  },
  btnIcon: {
    fontSize: "large",
    borderWidth: "1px",
    borderRadius: "100%",
    borderStyle: "dashed",
    span: {
      ...appStyle?.fontsMada,
    },
  },
  btnIconHoverd: {
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
      color: appStyle.App_Light_Text_Color,
    },
  },
  btn: {
    span: {
      ...appStyle?.fontsMada,
    },
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
    },
  },
  subtitle: (props) => ({
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 5px 20px 0",
    padding: "25px",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      minWidth: "210px",
      // minHeight: "50px",
      position: "absolute",
      top: "0",
      background: `url(${require("../../../../util/assets/images/gif1.gif")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      margin: "10px",
      transform: props?.lang !== "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
  }),
  subtitleLabel: {
    color: appStyle.App_info_Color,
    position: "relative",
    right: "35px",
    left: "35px",
    padding: "5px 20px",
    ...appStyle?.fontsHandjet,
    fontWeight: "500",
    userSelect: "none",
  },
  mainTitle: {
    color: appStyle.App_info_Color,
    userSelect: "none",
    position: "relative",
    margin: "10px auto 0px",
    ...appStyle.fontsChanga,
    textAlign: "center !important",
    fontWeight: "600",
    xs: {
      padding: "22px 0 0",
    },
  },
  arrowClose: {
    display: "none",
    transition: "display 0.3s",
  },
  descriptionLightTextContainer: {
    height: "auto",
    transition: "height 1s",
    "&hover": {
      height: "100%",
    },
  },
  navBtton: {
    color: appStyle.App_info_Color,
    cursor: "pointer",
    borderRadius: "4px",
    width: "fit-content",
    display: "inline-block",
    border: "2px solid",
    borderColor: appStyle.App_info_Color,
    padding: 0,
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
      color: appStyle.App_Light_Text_Color,
    },
    ".uicore_nav_item":{
      padding: "0.2rem 4px",
      minHeight: "auto",
    },
    ".uicore_nav_item_item_content": {
      margin: "auto",
      borderRadius: "4px",
      padding: "0px",
    },
  },
  menuItem:{
    color: appStyle.App_info_Color,
    padding: "5px 0",
    borderBottom: "1px solid",
    borderColor: appStyle?.App_info_Opacity_Color,
    "&hover":{
      background: appStyle.App_info_Opacity_Color,
    }
  },
  descriptionLightText: {
    lineClamp: 3,
    WebkitLineClamp: 3,
    height: "calc(3 * 1.5em)",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    width: "100%",
    color: appStyle?.App_Light_Text_Color,
    // ...appStyle?.fontsMada,
    textAlign: "justify !important",
    cursor: "pointer",
    userSelect: "none",
  },
  descriptionLightText5: {
    lineClamp: 5,
    WebkitLineClamp: 5,
    height: "calc(5 * 1.5em)",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    width: "100%",
    color: appStyle?.App_Light_Text_Color,
    // ...appStyle?.fontsMada,
    textAlign: "justify !important",
    cursor: "pointer",
    userSelect: "none",
    sm: {
      lineClamp: 3,
      WebkitLineClamp: "3",
      height: "calc(3 * 1.5em)",
    },
  },
  descriptionLightTextActive: {
    overflow: "visible",
    lineClamp: "unset",
    WebkitLineClamp: "unset",
    boxOrient: "unset",
    WebkitBoxOrient: "unset",
    height: "auto",
    "&+": {
      ".arrowClose": {
        display: "block",
      },
    },
  },
  labelModal: {
    ...appStyle.fontsChanga,
    // textAlign: "justify !important",
    textAlign: "center !important",
  },
  modal: {
    background: appStyle?.App_Dark_menu_Color,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    minHeight: "25%",
    color: appStyle.App_Light_Text_Color,
    padding: "20px",
    sm: {
      width: "88%",
    },
  },
  modalHeader: {
    border: "none",
    color: appStyle.App_info_Color,
    margin: "auto",
    maxHeight: "10px",
    ".uicore_icon": {
      width: "25px",
      height: "25px",
    },
  },
  robotImg: ({ lang }) => ({
    height: "60%",
    position: "absolute",
    top: "5%",
    left: lang === "arb" ? "12px" : "unset",
    right: lang === "arb" ? "unset" : "12px",
    sm: {
      display: "none",
    },
  }),
  robotImg2: ({ lang }) => ({
    height: "80%",
    position: "absolute",
    top: "10%",
    left: lang === "arb" ? "12px" : "unset",
    right: lang === "arb" ? "unset" : "12px",
    sm: {
      display: "none",
    },
  }),
  imgLine: {
    display: "block",
    margin: "10px auto 0",
    width: "90%",
  },
  imgLine2: {
    display: "block",
    margin: "10px auto 0",
    width: "50%",
  },
  card: {
    position: "relative",
    display: "block",
    height: "100%",
    width: "100%",
    // minHeight: "260px",
    // maxHeight: "260px",
    // maxWidth: "260px",
    boxShadow: "none",
    cursor: "pointer",
    zIndex: "auto",
    borderRadius: "2px",
    overflow: "hidden",
    "&before": {
      content: "",
      display: "block",
      width: "96%",
      height: "96%",
      position: "absolute",
      top: "2%",
      left: "2%",
      background: appStyle?.App_Dark_menu_Color,
      borderRadius: "2px",
      border: "2px inset",
      borderColor: appStyle?.App_info_Color,
    },
  },

  cardContiner: {
    height: "100%",
    alignContent: "center",
  },
  cardTitle: {
    color: appStyle?.App_info_Color,
    ...appStyle?.fontsHandjet,
    textAlign: "center !important",
    // width: "98%",
  },
  cardDescription: {
    // padding: "0 2em 2em",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "center",
    ...appStyle?.fontsMada,
    textAlign: "justify !important",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: appStyle?.App_Dark_Opacity_Color,
    zIndex: 1,
    overflow: "hidden",
    height: "0%",
    width: "0%",
    padding: "0rem",
    transition: "width 1s",
    color: appStyle?.App_Dark_Text_Color,
    borderRadius: "2px",
  },
  cardDescriptionHoverd: {
    height: "68%",
    width: "84%",
    padding: "1rem",
    overflowY: "auto",
    transition: "width 1s",
  },
  cardDescriptionClose: {
    height: "70%",
    width: "0%",
    padding: "1rem 0",
    overflowY: "hidden",
    transition: "width 1s",
  },
  icon: {
    zIndex: 2,
    opacity: 0.8,
    borderRadius: "5px",
    color: appStyle?.App_info_Color,
    "&hover": {
      background: appStyle?.App_info_Color,
      color: appStyle?.App_Dark_Color,
      opacity: 1,
    },
  },
  iconcopyall: ({ lang }) => ({
    position: "absolute",
    left: lang !== "arb" ? "unset" : "7px",
    right: lang === "arb" ? "unset" : "7px",
    marginTop: "35px",
    zIndex: 2,
    opacity: 0.8,
    borderRadius: "5px",
    color: appStyle?.App_info_Color,
    "&hover": {
      background: appStyle?.App_info_Color,
      color: appStyle?.App_Dark_Color,
      opacity: 1,
    },
  }),
  textareBankInfo: {
    // borderColor: `${appStyle?.App_Light_Text_Color} !important`,
    // color: `${appStyle?.App_Light_Text_Color} !important`,
  },
  partnerCat: {
    border: "1px solid",
    borderColor: `${appStyle?.App_info_Color} !important`,
    color: `${appStyle?.App_info_Color} !important`,
    borderRadius: "1rem",
    paddingInline: "1rem",
  },
  tableRow: {
    backgroundColor: `${appStyle?.App_info_Opacity_Color} !important`,
    color: appStyle.App_info_Color,
  },
  tblBodyRow: {
    color: appStyle.App_Light_Text_Color,
    background: "transparent !important",
  },
  btnCard: {
    width: "0px",
    height: "0px",
    padding: "0px 15px",
    margin: "0px",
  },
  btnCardD: {
    width: "0px",
    height: "0px",
    padding: "0px 15px",
    margin: "0px",
    color: `${appStyle.App_info_Opacity_Color1} !important`,
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: "100%",
    border: "1px dashed",
    borderColor: appStyle?.App_info_Color,
    zIndex: 1,
    color: appStyle.App_info_Color,
    background: appStyle?.App_Dark_Color,
    "&hover": {
      background: appStyle.App_info_Opacity_Color,
    },
  },

  avatarStep: {
    width: "35px",
    height: "35px",
    color: appStyle?.App_info_Color,
    background: appStyle.App_Dark_Color,
    margin: "0px",
    border: "1px dashed",
    borderColor: appStyle?.App_info_Color,
  },
  note: {
    display: "inline-block",
    color: appStyle?.App_info_Color,
    padding: "0 3px",
    marginInlineEnd: "3px",
    borderRadius: "10px",
  },

  badgeFrame: {
    "&before": {
      content: "",
      display: "block",
      position: "absolute",
      left: "0",
      top: "-2%",
      width: "350px",
      height: "100%",
      background: `url(https://static-media-project.s3.eu-central-1.amazonaws.com/aaiot/certificate/fream.png)`,
      zIndex: 4,
      backgroundSize: "cover",
      //transform: "scale(1.05, 1.1)",
      //transformOrigin: "center",
      overflow: "hidden",
    },
  },
});
