import { Alert } from "@cbmisorg/material";
import { generateQueries } from "../../../../graphql/schema/GenerateQuery";
import { aaiot_System_ID, objQuestionType } from "../../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../../util/appLanguage/dictionary";
import { getRandomNumber, logMessage } from "../../../../util/appFunctions";
import { Confirmation } from "@cbmisorg/client-app";
import appRouting from "../../../../configuration/routing/appRouting";

const strPageInfo = "@src/client/component/stakeholders/admin/mngSurveySave/mngSurveySave.ctrl.js";

const tblSurvey = generateQueries("tblSurvey");

export const CtrlMngSurveySave = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    initData:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo, state, field }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }
          setIsLoading(true);
          if (state?.surveyInfo) {
            stateInfo.surveyInfo = state?.surveyInfo;
            //#region setValue

            if (stateInfo.surveyInfo?.strSurveyPath) {
              field?.strUrlCode?.setValue(stateInfo.surveyInfo?.strSurveyPath);
            }
            if (stateInfo.surveyInfo?.jsnSurveyInfo?.strTitle?.[lang]) {
              field?.strTitle?.setValue(stateInfo.surveyInfo?.jsnSurveyInfo?.strTitle);
            }
            if (stateInfo.surveyInfo?.jsnSurveyInfo?.strSubtitle?.[lang]) {
              field?.strSubtitle?.setValue(stateInfo.surveyInfo?.jsnSurveyInfo?.strSubtitle);
            }
            if (stateInfo.surveyInfo?.jsnSurveyInfo?.strDscrp?.[lang]) {
              field?.strDscrp?.setValue(stateInfo.surveyInfo?.jsnSurveyInfo?.strDscrp);
            }
            //#endregion
          } else {
            stateInfo.surveyInfo = {};
          }
          stateInfo.blnIsInitDone = true;
          setIsLoading(false);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },
    handleOpenModale:
      ({ stateInfo, setStateInfo, fieldQuestions }) =>
      (intSelectedQuestionsInd = -1, type = "") =>
      () => {
        if (!stateInfo?.surveyInfo?.lstSurveyQus) {
          stateInfo.surveyInfo.lstSurveyQus = [];
        }
        if (type === "save") {
          if (!fieldQuestions?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
            return;
          }
          if (
            fieldQuestions?.lstQuestionType?.getValue()?.key === objQuestionType?.multipleChoice &&
            [...(stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt || []), ...stateInfo?.lstTmpOption]?.length < 2
          ) {
            Alert.viewAlert(dictionary?.components?.stakeholders?.admin?.mngSurvey?.alertOpt?.[lang], "warning");
            return;
          }
          if (stateInfo?.intSelectedQuestionsInd === -1) {
            stateInfo?.surveyInfo?.lstSurveyQus?.push({
              bigQID: getRandomNumber(3),
              strQ: fieldQuestions?.strQuestion?.getValue(),
              strQGrp: fieldQuestions?.strQuestionGrp?.getValue(),
              strQCaptionB: fieldQuestions?.strQCaptionB?.getValue(),
              strQCaptionA: fieldQuestions?.strQCaptionA?.getValue(),
              intQType: fieldQuestions?.lstQuestionType?.getValue()?.key,
              questionNo: fieldQuestions?.questionNo?.getValue() ? stateInfo.surveyInfo.lstSurveyQus[Number(fieldQuestions?.questionNo?.getValue()) -1]?.bigQID || "" : "",
              required: fieldQuestions?.required?.getValue(),
              lstOpt: [...(stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt || []), ...stateInfo?.lstTmpOption],
            });
          } else {

            stateInfo.surveyInfo.lstSurveyQus[stateInfo?.intSelectedQuestionsInd] = {
              ...stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd],
              strQ: fieldQuestions?.strQuestion?.getValue(),
              strQGrp: fieldQuestions?.strQuestionGrp?.getValue(),
              strQCaptionB: fieldQuestions?.strQCaptionB?.getValue(),
              strQCaptionA: fieldQuestions?.strQCaptionA?.getValue(),
              intQType: fieldQuestions?.lstQuestionType?.getValue()?.key,
              questionNo: fieldQuestions?.questionNo?.getValue() ? stateInfo.surveyInfo.lstSurveyQus[Number(fieldQuestions?.questionNo?.getValue()) -1]?.bigQID || "" : "",
              required: fieldQuestions?.required?.getValue(),
              lstOpt: [...(stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt || []), ...stateInfo?.lstTmpOption],
            };
          }
          stateInfo.lstTmpOption = [];
          fieldQuestions?.controller?.resetForm();
        } else if (intSelectedQuestionsInd > -1) {
          const objQInfo = stateInfo?.surveyInfo?.lstSurveyQus?.[intSelectedQuestionsInd];
          // console.log(objQInfo);
          
          if (objQInfo?.strQ) {
            fieldQuestions?.strQuestion?.setValue(objQInfo?.strQ);
          }
          if (objQInfo?.strQGrp?.[lang]) {
            fieldQuestions?.strQuestionGrp?.setValue(objQInfo?.strQGrp);
          }
          if (objQInfo?.strQCaptionB?.[lang]) {
            fieldQuestions?.strQCaptionB?.setValue(objQInfo?.strQCaptionB);
          }
          if (objQInfo?.strQCaptionA?.[lang]) {
            fieldQuestions?.strQCaptionA?.setValue(objQInfo?.strQCaptionA);
          }
          if (objQInfo?.intQType) {
            fieldQuestions?.lstQuestionType?.setValue(objQInfo?.intQType);
          }
          if (objQInfo?.questionNo) {
            fieldQuestions?.questionNo?.setValue(objQInfo?.questionNo);
          }
          if (objQInfo?.required) {
            fieldQuestions?.required?.setValue(objQInfo?.required);
          }
          stateInfo.lstTmpOption = [];
        }
        setStateInfo({ ...stateInfo, blnOpenModal: !stateInfo?.blnOpenModal, intSelectedQuestionsInd: intSelectedQuestionsInd });
      },
    handleOpenOptModale:
      ({ stateInfo, setStateInfo, fieldOption }) =>
      (intSelectedOptInd = -1, type = "") =>
      () => {
        if (type === "save") {
          if (!fieldOption?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
            return;
          }
          if (stateInfo?.intSelectedOptInd === -1) {
            stateInfo?.lstTmpOption?.push({
              intOptID: getRandomNumber(3),
              strOpt: fieldOption?.strOption?.getValue(),
            });
          } else {
            stateInfo.surveyInfo.lstSurveyQus[stateInfo?.intSelectedQuestionsInd].lstOpt[stateInfo?.intSelectedOptInd] = {
              ...stateInfo.surveyInfo.lstSurveyQus[stateInfo?.intSelectedQuestionsInd].lstOpt[stateInfo?.intSelectedOptInd],
              strOpt: fieldOption?.strOption?.getValue(),
            };
          }
          fieldOption?.controller?.resetForm();
        } else if (intSelectedOptInd > -1) {
          const objOptInfo = stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt?.[intSelectedOptInd];
          fieldOption?.strOption?.setValue(objOptInfo?.strOpt);
        }
        setStateInfo({ ...stateInfo, blnOpenOptModal: !stateInfo?.blnOpenOptModal, intSelectedOptInd: intSelectedOptInd });
      },

    handleSubmit:
      ({ isLoading, setIsLoading, stateInfo, navigate, field, type }) =>
      async () => {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
          return;
        }
        if (!field?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
          return;
        }

        if (field?.strUrlCode?.getValue() !== stateInfo?.surveyInfo?.strUrlCode || type === "add") {
          const resultGetUrl = await tblSurvey(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: ["bigSurveyID"],
            objectCondition: {
              strSurveyPath: field?.strUrlCode?.getValue(),
              bigSystemID: bigSystemID,
            },
          });
          if (String(resultGetUrl?.response?.[0]?.bigSurveyID) !== String(stateInfo?.surveyInfo?.bigSurveyID) || resultGetUrl?.response?.length > 1) {
            logMessage(resultGetUrl?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.surveyURL?.[lang], "error", appLangDirection?.[lang]);
            setIsLoading(false);
            return;
          }
        }

        if (type === "add") {
          const result = await tblSurvey(strPageInfo, appState, "aaiotAppAdd", {
            objectInput: {
              ...(stateInfo?.surveyInfo || {}),
              bigSystemID: bigSystemID,
              bigSurveyID: getRandomNumber(10),
              jsnSurveyInfo: {
                ...(stateInfo?.surveyInfo?.jsnSurveyInfo || {}),
                strTitle: field?.strTitle?.getValue(),
                strSubtitle: field?.strSubtitle?.getValue(),
                strDscrp: field?.strDscrp?.getValue(),
              },
              strSurveyPath: field?.strUrlCode?.getValue(),

              dtmUpdatedDate: new Date()?.toISOString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "error", appLangDirection?.[lang]);

            setIsLoading(false);
            return;
          }
        } else {
          const result = await tblSurvey(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              ...(stateInfo?.surveyInfo || {}),
              strSurveyPath: field?.strUrlCode?.getValue(),
              jsnSurveyInfo: {
                ...(stateInfo?.surveyInfo?.jsnSurveyInfo || {}),
                strTitle: field?.strTitle?.getValue(),
                strSubtitle: field?.strSubtitle?.getValue(),
                strDscrp: field?.strDscrp?.getValue(),
              },
              dtmUpdatedDate: new Date(),
            },
            objectCondition: {
              bigSurveyID: stateInfo?.surveyInfo?.bigSurveyID,
              bigSystemID: bigSystemID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "error", appLangDirection?.[lang]);

            setIsLoading(false);
            return;
          }
        }

        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        navigate(appRouting?.MngSurvey?.url);
      },

    handelDeleteQuesConfirmation:
      ({ stateInfo, setStateInfo }) =>
      (qs) =>
      () => {
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.deleteItem?.[lang],
          () => {
            stateInfo.surveyInfo.lstSurveyQus = stateInfo?.surveyInfo?.lstSurveyQus?.filter((q) => q?.bigQID !== qs?.bigQID);
            setStateInfo({ ...stateInfo });
          },
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    handelDeleteQuesOpConfirmation:
      ({ stateInfo, setStateInfo }) =>
      (op) =>
      () => {
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.deleteItem?.[lang],
          () => {
            stateInfo.surveyInfo.lstSurveyQus[stateInfo?.intSelectedQuestionsInd].lstOpt = (
              stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt || []
            )?.filter((opt) => opt?.intOptID !== op?.intOptID);
            stateInfo.lstTmpOption = (stateInfo?.lstTmpOption || [])?.filter((opt) => opt?.intOptID !== op?.intOptID);

            setStateInfo({ ...stateInfo });
          },
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },
  };

  return handler;
};
