import { Alert } from "@cbmisorg/material";
import { generateQueries, objTableAttributes } from "../../../../graphql/schema/GenerateQuery";
import { aaiot_System_ID } from "../../../../configuration/appVariables";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { logMessage } from "../../../../util/appFunctions";
import { Confirmation } from "@cbmisorg/client-app";

const strPageInfo = "@src/client/component/stakeholders/admin/mngUsers/mngUsers.ctrl.js";

const tblUser = generateQueries("tblUser");

export const CtrlMngUser = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    getUser:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserAll", {
            arrAttributes: objTableAttributes?.tblUser?.full,
            objectCondition: {
              bigSystemID: bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");

            setIsLoading(false);
            return;
          }

          stateInfo.blnIsInitDone = true;
          let lstTmp = Array.isArray(result?.response) ? result?.response : [];

          stateInfo.lstUser = lstTmp;
          stateInfo.lstUserRead = (lstTmp || [])?.reverse();
          setIsLoading(false);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },

    onChangeFilterRole:
      ({ stateInfo, setStateInfo }) =>
      (selectedRole) =>
      () => {
        setStateInfo({ ...stateInfo, selectedRole: selectedRole });
      },

    openModalInfo:
      ({ stateInfo, setStateInfo }) =>
      (key = "", obj = {}) =>
      () => {
        setStateInfo({ ...stateInfo, blnOpenInfo: !stateInfo?.blnOpenInfo, strSelectedInterviewInfoKey: key, objSelectedInterviewInfo: obj });
      },

    openAnyUserModal:
      ({ stateInfo, setStateInfo }) =>
      (openkey = "", objUser = {}) =>
      () => {
        setStateInfo({ ...stateInfo, [openkey]: !stateInfo?.[openkey], objUserSelected: objUser });
      },
    handelChangeObjUser:
      ({ stateInfo, setStateInfo }) =>
      (objUserInfo = null) =>
      () => {
        if (objUserInfo) {
          const userInd = stateInfo?.lstUser?.findIndex((user) => user.bigUserID === objUserInfo.bigUserID);
          if (userInd > -1) {
            stateInfo.lstUser[userInd] = objUserInfo;
            setStateInfo({ ...stateInfo });
          }
        }
      },

    handelConfrmationEditByBlnKey:
      ({ stateInfo, setStateInfo, isLoading, setIsLoading }) =>
      (blnKey = "", objUser = {}, blnjsnInfo=false) => ()=>{
        let msg = dictionary?.shared?.confirmationMsg?.deleteUser;
        if (blnKey === "blnIsStudent") {
          msg = dictionary?.shared?.confirmationMsg?.addAsStudent;
        } else if (blnKey === "blnIsResearcher") {
          msg = dictionary?.shared?.confirmationMsg?.addAsResearcher;
        } else if (blnKey === "blnIsSponsor") {
          msg = dictionary?.shared?.confirmationMsg?.addAsSponser;
        } else if (blnKey === "blnIsVolunteer") {
          msg = dictionary?.shared?.confirmationMsg?.addAsVolunteer;
        } else if (blnKey === "blnIsMDEA") {
          msg = dictionary?.shared?.confirmationMsg?.addAsMDEA;
        } else if (blnKey === "blnIsInitialApproval") {
          msg = dictionary?.shared?.confirmationMsg?.addInitialApprovalTraining;
        } else if (blnKey === "blnIsTrainingApproval") {
          msg = dictionary?.shared?.confirmationMsg?.addApprovalTraining;
        } else if (blnKey === "blnIsReject") {
          msg = dictionary?.shared?.confirmationMsg?.addRejectTraining;
        }
        const handelEditByBlnKey = handler?.handelEditByBlnKey({ stateInfo, setStateInfo, isLoading, setIsLoading, blnKey, objUser, blnjsnInfo });
        Confirmation.viewConfirmation(
          msg?.[lang],
          handelEditByBlnKey,
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    handelEditByBlnKey:
      ({ stateInfo, setStateInfo, isLoading, setIsLoading, blnKey = "", objUser = {}, blnjsnInfo }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }
          const objectInput ={}
          if(!blnjsnInfo){
            objectInput[blnKey]= !(objUser?.[blnKey] || false);
          }else{
            objectInput.jsnUserInfo = objUser?.jsnUserInfo || {};
            if(blnKey === "blnIsReject"){
              objectInput.jsnUserInfo.blnIsInitialApproval = false
              objectInput.jsnUserInfo.blnIsTrainingApproval = false;
            }else{
              objectInput.jsnUserInfo[blnKey]= !(objUser?.jsnUserInfo?.[blnKey] || false);

            }
          }

          setIsLoading(true);
          const result = await tblUser(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              ...objectInput,
              dtmUpdatedDate: new Date().toISOString(),
            },
            objectCondition: {
              bigSystemID: bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
              bigUserID: objUser?.bigUserID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");

            setIsLoading(false);
            return;
          }

          const newUserObject = {...objUser, [blnKey]: !objUser?.[blnKey]};
          handler?.handelChangeObjUser({ stateInfo, setStateInfo })(newUserObject)();
          setIsLoading(false);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
};
