import React, { forwardRef, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import Text from "../Text/Text";


const Password = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,
          sx,hidden,disabled,dir,className,...rest} = props

    const [type, setType] = useState("password");

    const nameID = useRef(id || componentsFunction.randomName("Password"));

  return (
      <React.Suspense fallback="">
        <Text
          ref={ref}
          type={type}
          id={nameID.current}
          name={nameID.current}
          label={label}
          placeholder={placeholder}
          errorMessage={errorMessage}
          mode={mode}
          color={color}
          icon={icon}
          iconColor={iconColor}
          adornment={adornment}
          endIcon={type === "password" ? "visibilityoff" : "visibility"}
          endAdornment={endAdornment}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          endIconRest={{onMouseOver:() => setType("text"), onMouseLeave:() => setType("password")}}
          sx={sx}
          hidden={hidden}
          disabled={disabled}
          dir={dir}
          className={className}
          {...rest}
          />
      </React.Suspense>
    )
});

export default Password