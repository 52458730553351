// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useNavigate } from "@cbmisorg/router";
import { Button, Container, Grid, Typography, Loader } from "@cbmisorg/material";

import { useApp } from "../../../configuration/contextapi/context";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { dictionary } from "../../../util/appLanguage/dictionary";

import { CtrlSponserRegistration } from "./SponserRegistration.controller";
import { objStyle } from "./SponserRegistration.style";

import ShredHeader from "../../shared/shredHeader";
import { App_info_Color, appStyle } from "../../../util/appStyle";
import appRouting from "../../../configuration/routing/appRouting";
import { useSearchParams } from "react-router-dom";
import { useParams } from "@cbmisorg/client-app";
import useCheckSystemInfo from "../../../util/hooks/useCheckSystemInfo";
import useCheckCat from "../../../util/hooks/useCheckCat";
import { workSectorID } from "../../../configuration/appVariables";
import WorkSectorOption from "../../stakeholders/member/account/components/regActivityGuest/component/WorkSectorOption.view";

const labels = dictionary?.components?.public?.sponserRegistration;

function SponserRegistrationView() {
  useCheckHooksGlb();
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const { getNewCatInfo } = useCheckCat({ appState, blnIsNotHook: true });
  const { getNewSystemInfo } = useCheckSystemInfo({ appState });

  const { strTrainingType } = useParams();
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    countryCode: "",
    blnDefaultCountry: true,
  });

  const fields = FormData({
    strFullName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: () => fields?.blnRegister?.getValue() === true, email: true },
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: () => fields?.blnRegister?.getValue() === true,
        minLength: 4,
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: () => fields?.blnRegister?.getValue() === true,
        minLength: 4,
        equal: "strUserPassword",
      },
    },
    // bigCountryOfCitizenID: { control: "list", validations: { required: true }, blnLanguage: false },
    // bigCountryOfResidenceID: { control: "list", validations: {}, blnLanguage: false },
    bigAreaCountryID: { control: "list", validations: {}, blnLanguage: false },
    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: {required:true, onlyNumbers: true, minLength: 5, maxLength: 10 },
    },
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName2: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    blnRegister: { control: "switch", blnLanguage: false },
    blnAddNote: { control: "switch", blnLanguage: false },
    strNote: { control: "textArea", blnLanguage: false },
    strToSupport: { control: "list", blnLanguage: false, validations: { required: true } },
    strSponsorTrainingType: { control: "list", blnLanguage: false, validations: { required: true } },
  });

  const handlers = CtrlSponserRegistration({ appState, appDispatch, isLoading, setIsLoading, countryCode: null, strTrainingType });
  const handleSubmit = () => handlers.handleSubmit({ fields, navigate, state });
  const onChangeContry = handlers.onChangeContry({ fields, setState, state });
  const handleChangeToSupport = handlers.handleChangeToSupport({ fields });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields });

  useEffect(() => {
    if (appState?.clientInfo?.dtmLastLoggingIn) {
      navigate(appRouting?.Account?.url);
    }
    document.body.classList.add("paddingBottom");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.blnIsInitDone) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ShredHeader lang={lang} title={labels?.title?.[lang]} blnBack={true} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"} alignSelf="center">
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container} pt-10>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <React.Fragment>
                    <Grid container>
                      <Grid item className={classes?.subtitle} p-3>
                        <Typography as="subtitle2" className={classes?.subtitleLabel} style={{ padding: "5px" }}>
                          {labels?.sponsorInfo?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent={"center"} spacing={2}>
                      <Grid item xs={12} py-3>
                        <FormField objHandler={fields.strFullName} icon="person" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                      </Grid>
                      <Grid item xs={12} pt-3>
                        <FormField
                          objHandler={fields.strUserPhone}
                          icon="phone"
                          mode={"classic"}
                          color={App_info_Color}
                          inputDir="ltr"
                          className={classes?.field}
                          endAdornment={fields?.bigAreaCountryID?.getValue()?.value?.[lang]}
                        />
                      </Grid>
                      <Grid item xs={12} md="6">
                        <FormField objHandler={fields.bigWorkSector} icon="Work" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                      </Grid>
                      <Grid item xs={12} md="6">
                        <FormField objHandler={fields.strPlaceName2} icon="business" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid container>
                      <Grid item className={classes?.subtitle} p-3>
                        <Typography as="subtitle1" className={classes?.subtitleLabel}>
                          {labels?.supportInfo?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent={"center"} spacing={2}>
                      <Grid item xs={12} py-3>
                        <FormField
                          objHandler={fields.strToSupport}
                          icon="code"
                          mode={"classic"}
                          color={appStyle?.App_info_Color}
                          className={classes?.field}
                          onChange={handleChangeToSupport}
                        />
                      </Grid>
                      <Grid item xs={12} pt-3>
                        <FormField objHandler={fields.strSponsorTrainingType} icon="code" mode={"classic"} color={appStyle?.App_info_Color} className={classes?.field} />
                      </Grid>
                      <Grid item xs={12} pt-4 pb-3>
                        <FormField objHandler={fields.blnAddNote} color={appStyle.App_info_Color} className={classes?.field} />
                      </Grid>
                      {!fields?.blnAddNote?.getValue() ? null : (
                        <Grid item xs={12}>
                          <FormField objHandler={fields.strNote} color={appStyle.App_info_Color} className={classes?.field} rows={3} />
                        </Grid>
                      )}
                      <Grid item xs={12} pt-4 pb-3>
                        <FormField objHandler={fields.blnRegister} color={appStyle.App_info_Color} className={classes?.field} />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                  {!fields?.blnRegister?.getValue() ? null : (
                    <React.Fragment>
                      <Grid container>
                        <Grid item className={classes?.subtitle} p-3>
                          <Typography as="subtitle1" className={classes?.subtitleLabel} >
                            {labels?.registrationInfo?.[lang]}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" justifyContent={"center"} spacing={2}>
                        <Grid item xs={12}>
                          <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
                        </Grid>
                        <Grid item xs={12} md="6">
                          <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
                        </Grid>
                        <Grid item xs={12} md="6">
                          <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}

                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs="12" pt-5 pb-5>
                      <Button py-0 px-10 label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={handleSubmit} className={classes?.btn} />
                    </Grid>
                  </Grid>
                  <input type="submit" style={{ visibility: "hidden" }} onClick={handleSubmit} />
                </form>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default SponserRegistrationView;
