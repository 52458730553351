import React, {  useEffect, useState } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import {  Container, Grid, Icon, Typography, useNavigate, Box, Line } from "@cbmisorg/client-app";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { getNextStrURL } from "../../../../appHelper/appFunctions";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { useApp } from "../../../../../client/configuration/contextapi/context";

const lst2 = [
  { name: "intCountJoinUserAll", icon: "people" },
  { name: "intCountJoinSponsorAll", icon: "businessCenter" },
  { name: "intCountJoinResearcherAll", icon: "search" },
  // { name: "intCountJoinEntrepreneurAll", icon: "Work" },
  { name: "intCountJoinStudentAll", icon: "school" },
  { name: "intCountActivityGuestAll", icon: "AutoAwesome" },
  { name: "intCountActivityGuestUniFirstStudentAll", icon: "MilitaryTech" },
];

function Statistic() {
  useCheckCat();

  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const lang = appState.clientInfo.strLanguage;
  const labels = dictionary?.components?.manageStatistic;
  const [state] = useState({
    blnInitialData: false,
    intPartnerConfirmed: 0,
    intPartnerNotConfirmed: 0,
    intPartnerNew: 0,
    intResearcherCount: 0,
    intTrainingCount: 0,

    intNewTrainingManagerCount:0,
    intNewTrainingCount:0,

    intNotConfirmedTrainingManagerCount:0,
    intNotConfirmedTrainingCount:0,
  });

  // useCheckUserLoginDevice()
  const { getNewSystemInfo } = useCheckSystemInfo({ notHook: true, isGetCount: true });

  useEffect(() => {
    const accountUrl = getNextStrURL();
    if (accountUrl !== objAppRouting?.Account?.url) {
      navigate(accountUrl, { replace: true });
    }
    if (!state?.blnInitialData) {
      getNewSystemInfo(appDispatch);
    }
  }, []);



  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} />
      <Container py-10>
       {/* <Line label={labels?.pageVistor?.[lang]} />
        <Grid container justify={"center"} spacing={1}>
          {lst1?.map((item, index) => (
            <Grid item xs="6" sm="4" md="3" key={index} className="navAccount">
              <Box outline={1} sx={{minHeight:"82px", maxHeight:"100%"}}>
                <Grid container justify={"center"}>
                  <Grid item>
                    <Box py-0 px-2 m-0 color=" " sx={{ background: appState?.clientInfo?.objSystemInfo?.[item?.name] ? "green" : "var(--cbmis-error)" }}>
                      <Typography as="caption" color="light" p-0 m-0>
                        <b>{appState?.clientInfo?.objSystemInfo?.[item?.name] || 0}</b>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Icon icon={item?.icon} color="primary" />
                  </Grid>

                  <Grid item xs="12">
                    <Typography as="caption" className="textcenter">
                      {labels?.[item?.name]?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>*/}
        <Line label={labels?.regNumber?.[lang]} mt-10/>
        <Grid container justify={"center"} spacing={1}>
          {lst2?.map((item, index) => (
            <Grid item xs="6" sm="4" md="3" key={index} className="navAccount">
              <Box outline={1} sx={{minHeight:"82px", maxHeight:"100%"}}>
                <Grid container justify={"center"}>
                  <Grid item>
                    <Box py-0 px-2 m-0 color=" " sx={{ background: appState?.clientInfo?.objSystemInfo?.[item?.name] ? "green" : "var(--cbmis-error)" }}>
                      <Typography as="caption" color="light" p-0 m-0>
                        <b>{appState?.clientInfo?.objSystemInfo?.[item?.name] || 0}</b>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Icon icon={item?.icon} color="primary" />
                  </Grid>

                  <Grid item xs="12">
                    <Typography as="caption" className="textcenter">
                      {labels?.[item?.name]?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Statistic;
