import * as appVariables from "../../configuration/appVariables";

const appInitialState = {
  clientInfo: {
    dtmLastRequest: "",
    blnIsUserLoggedIn: false,
    blnIsAppStateLive: false,
    strLanguage: "arb",
    strDataLanguage: "arb",
    blnClientOnline: true,
    strAppName: appVariables.App_Name,
    strAppVersion: appVariables?.App_Version,
    objSystemInfo: {},

    appPermissionState: {
      cameraPermission: { user: false, system: false },
      locationPermission: { user: false, system: false },
      microphonePermission: { user: false, system: false },
      notificationsPermission: { user: false, system: false },
    },

    appFontSize: "lg",

    dtmLastLoggingIn: "",
    dtmLastCheckedSystemInfo: "",
  },
  userInfo: {
    bigUserID: 0,
    bigSystemID: appVariables?.aaiot_System_ID,
    strFullName: "",
    strUserEmail: "",
    strUserPhone: "",
    strUsername: "",
    bigUserRoleID: appVariables.Role_Public,
    bigCountryID: 0,
    lstUserFeature: [],
    txtLoginKeys: "",
    txtLoginIP: "",
    strLastLoginUserAgent: "",
    dtmLastLogin: "",
  },
  cacheInfo: {
    objSymposiumInfo: {},
    objActiivityReg: {}
  },
};

export default appInitialState;
