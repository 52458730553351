import React, { useEffect, useState } from 'react'
import './Pagination.css'
import CbmisIcon from '../../../icon/CbmisIcon'
import '../../../style/main.css'
import * as componentsFunction from '../../../helper/CbmisFunction'
import useCheckScreenSize from '../../../hook/useCheckScreenSize'
import { setSXStyle } from '../../../helper/convertJsCss'

const Pagination=(props) => {
    useCheckScreenSize()
    const {count,setStyle,color,textColor} =props
    const [arrPagination, setArrPagination] = useState([])
    const [paginationStyle, setpaginationStyle] = useState("cbmis-pagination-filled")

    useEffect(() => {
        const array = [];
        for (let index = 1; index <= parseInt(count); index++) {
            array.push(index)
            }
        setArrPagination(array)
    }, [count])

    useEffect(() => {
        if(setStyle){
            const paginationStyle = String(setStyle).toLowerCase()
            switch (paginationStyle) {
                case "outlined":
                    setpaginationStyle("cbmis-pagination-outlined")
                    break; 
                default:
                    setpaginationStyle("cbmis-pagination-filled")
                    break;
            }
        }
    }, [setStyle])

    useEffect(() => {
        if(paginationStyle === "cbmis-pagination-filled"){
            componentsFunction.getbgColorReturnClassName(".cbmis-pagination-filled",color)
        }else if(paginationStyle === "cbmis-pagination-outlined"){
            componentsFunction.getOutlinedStyleColorReturnClassName(".cbmis-pagination-outlined",color)
        }
    }, [paginationStyle,color])

    useEffect(() => {
        componentsFunction.changeItemColor(".cbmis-pagination",textColor || "var(--cbmis-body-color)")
    }, [textColor])

    return (
        <ul
         className="cbmis-pagination-container">
             <li className="cbmis-pagination">
                <button type="button" className={`cbmis-pagination-button ${paginationStyle}`} value=""><CbmisIcon icon="chevronLeft" /></button>
            </li>
            <li className="cbmis-pagination">
                <button type="button" className={`cbmis-pagination-button ${paginationStyle}`} value=""><CbmisIcon icon="chevronLeft" /></button>
            </li>
             {
               arrPagination.map((item,index)=>{
                   return <li key={index} className="cbmis-pagination">
                       <button type="button" className={`cbmis-pagination-button ${paginationStyle}`} value={item}>{item}</button>
                    </li>
               })
             }
             <li className="cbmis-pagination">
                <button type="button" className={`cbmis-pagination-button ${paginationStyle}`} value=""><CbmisIcon icon="chevronRight" /></button>
            </li>
            <li className="cbmis-pagination">
                <button type="button" className={`cbmis-pagination-button ${paginationStyle}`} value=""><CbmisIcon icon="chevronRight" /></button>
            </li>
        </ul>
    )
}

export default Pagination
