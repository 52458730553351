import React from 'react'
import AttendeeManagementView from './AttendeeManagement.view'

function AttendeeManagement() {
    
  return (
    <AttendeeManagementView />
  )
}

export default AttendeeManagement