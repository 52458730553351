/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Loader from "../../material/surfaces/Loader/Loader"

const CbmisInfiniteScroll = (props) => {
    const { data, Component,itemNumber =25} = props
    
    const records = data.length < itemNumber?data.length:itemNumber

    const [recordsNumber, setRecordsNumber] = useState(records)
    const [stateInfo, setStateInfo] = useState({
        items:[],
        loading:true,
        hasMore:true
    })

    const observer = useRef()


    useEffect(() => {
        let newItemsList = []
        let numOfItemsToShow = recordsNumber % records === 0 ? records : recordsNumber % records
        for (let i = recordsNumber - numOfItemsToShow; i < recordsNumber; i++) {
            newItemsList.push(data[i])
        }
        stateInfo.items = [...stateInfo.items, ...newItemsList]
        stateInfo.loading = false
        setStateInfo({...stateInfo})
    }, [recordsNumber])

    useEffect(() => {
    }, [ data, Component])
    
    const loadMore = () => {
        if (recordsNumber === data.length){
            stateInfo.hasMore = false
        }
        else {
            stateInfo.loading =true
            let remainingItems = data.length - recordsNumber
            let numOfItems = remainingItems < records ? remainingItems : records
            setTimeout(() => {
                setRecordsNumber(recordsNumber + numOfItems)
            }, 0);
        }
        setStateInfo({...stateInfo})
    }


    const lastItemRef = useCallback(node => {
        if (stateInfo.loading) return
        //disconnect our observer from the previous element,
        //so the new element will hooked up correctly
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            //isIntersecting let us now if our entries is visible in the page 
            if (entries[0].isIntersecting && stateInfo.hasMore) {
                loadMore()
            }
        })
        //if some node is our last element,we want to be sure that our observer observe on it
        if (node) observer.current.observe(node)
    }, [stateInfo])


    return (
        <React.Fragment>
            {stateInfo.items.map((item, index) => {
                if (stateInfo.items.length === index + 1) {
                    return (
                            <Component item={item} index={index} ref={lastItemRef} key={index}/>
                    )
                }
                else {
                    return (
                            <Component item={item} index={index}  />
                    )
                }
            })}
            <React.Fragment>
                {stateInfo.loading &&(
                    <Loader />
                )}
            </React.Fragment>
        </React.Fragment>
    )
}

export default CbmisInfiniteScroll
