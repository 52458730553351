import React, { useEffect, useRef } from "react";
import "./Line.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Line = (props) => {
  const { sx, hidden, disabled, dir, className, label, mode, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("Line"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [hidden, disabled, dir, className, label, mode, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-line-container");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <React.Suspense fallback="">
      <div
        id={getID.current}
        className={`cbmis-line-container ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
      >
        <div className={`cbmis-line ${mode === "fade" ? "cbmis-line-fade" : ""}`} />
        {label ? <label className="cbmis-line-Label">{label}</label> : ""}
      </div>
    </React.Suspense>
  );
};

export default Line;
