import { ClassesBuilder } from "@cbmisorg/styles";
import React, { useEffect, useState } from "react";
import { useApp } from "../../../configuration/contextapi/context";
import { objStyle, colors } from "./supportLight.style";
import { Accordion, AccordionSummary, AccordionDetails, AccordionTab, Avatar, Button, Container, Grid, Paper, Typography } from "@cbmisorg/material";
import { useNavigate } from "@cbmisorg/router";
import { labels } from "./support.dic";
import robot1 from "../../../util/assets/images/robot1.png";

import aaiotLogo from "../../../../old/assets/images/logo.png";
import caitLogo from "../../../util/assets/images/cait.png";

import { BtnGoToTop } from "@cbmisorg/client-app";
import appRouting from "../../../configuration/routing/appRouting";
import { generatePath } from "react-router-dom";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import { App_Server_Url_DownloadFiles, objVision2035 } from "../../../configuration/appVariables";
import Header from "./views/Header";
import { decompressText, getAppLocalStorage } from "../../../util/appFunctions";
import { Icon } from "@cbmisorg/icons";

const lstLogoImg = [caitLogo, aaiotLogo];

function SupportView() {
  const { appState, appDispatch } = useApp();
  useCheckHooksGlb();
  const aaiotLocalStorage = getAppLocalStorage();
  const objVision2035Config = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.objVision2035 || {};

  // useCheckSystemInfo(appState);
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({
    blnIsDone: false,
    lstPartner: [],
    objTrainingSponser: {},
  });

  const navigate = useNavigate();

  const classes = ClassesBuilder({ ...objStyle }, { lang });
  const goPath =
    (url, blank = false) =>
    () => {
      if (!blank) {
        navigate(url);
      } else {
        window.open(url, "_blank");
      }
    };

  useEffect(() => {
    if (!state?.blnIsDone) {
      if (aaiotLocalStorage?.clientInfo?.objSystemInfo) {
        state.lstPartner = (aaiotLocalStorage?.clientInfo?.objSystemInfo?.lstPartner || [])?.filter((item) => item?.strLogoImg?.[0]);
        state.blnIsDone = true;
        const objCat = decompressText(aaiotLocalStorage?.clientInfo?.objCatInfo);
        state.objTrainingSponser = objCat?.Root?.toSupport?.["fullstack"]?.sponser;
        setState({ ...state });
      }
    }
  }, [aaiotLocalStorage?.clientInfo?.objSystemInfo]);

  return (
    <React.Fragment>
      <section className={classes?.main}>
        <Header appState={appState} appDispatch={appDispatch} classes={classes} />
        <section className={classes?.hero}>
          <Container>
            <Grid container spacing={7} justifyContent="center">
              <Grid item xs={12} justifyContent="center">
                <Typography as="h1" className={classes?.herorTitleLight}>
                  {labels?.title?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs={12} justifyContent="center">
                <Typography as="h4" className={classes?.herorSubtitleLabel}>
                  {labels?.subtitle?.[lang]}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={"2"} justifyContent="center" className={classes?.btnContainer}>
              <Grid item>
                <Button
                  size="lg"
                  label={<Typography as="subtitle1">{labels?.btnSponser?.[lang]}</Typography>}
                  className={classes?.btnBox}
                  color={colors?.blue}
                  onClick={goPath(generatePath(appState?.clientInfo?.dtmLastLoggingIn ? appRouting?.Account?.url : appRouting?.sponsertRegistration?.url))}
                  py-5
                  px-10
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <div id="about" />
        <section className={`${classes?.section} ${classes?.about}`}>
          <Grid container spacing={"2"} justifyContent="center" py-5>
            <Grid item xs="12" md="11" lg="10">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} justifyContent="center">
                  <Typography as="h5" className={classes?.mainTitleLight}>
                    {labels?.about?.title?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Typography as="subtitle2" className={classes?.mainTitleLight}>
                    {labels?.about?.date?.[lang]}
                  </Typography>
                </Grid>

                <Grid item xs={12} justifyContent="center" py-3>
                  <Typography as="body2" className={classes?.subtitleLabel}>
                    {labels?.about?.content?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
        <div id="objectives" />
        <section className={`${classes?.section} ${classes?.objectives}`}>
          <Grid container spacing={"2"} justifyContent="center" py-3>
            <Grid item xs="12" md="11" lg="10">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} justifyContent="center">
                  <Typography as="h4" className={classes?.mainTitleLight}>
                    {labels?.objectives?.title?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Typography as="body2" className={classes?.subtitleLabel}>
                    {labels?.objectives?.content?.[lang]}
                  </Typography>
                </Grid>
                {labels?.objectives?.lst?.map((item, i) => (
                  <Grid item xs="12" key={i}>
                    <Grid container spacing={2}>
                      <Grid item xs="auto" sm="2" lg="1" alignItems="flex-start">
                        <Avatar className={classes?.inner}>
                          <Typography as="h6" className={classes?.textShadow}>{`0${i + 1}`}</Typography>
                        </Avatar>
                      </Grid>
                      <Grid item xs="9" sm="10" lg="11">
                        <Typography as="body2" className={classes?.subtitleLabel}>
                          <b style={{ color: colors?.blue }}>{item?.title?.[lang]}</b> : {item?.decr?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </section>

        <div id="features" />
        <section className={classes?.section}>
          <Container>
            <Grid container spacing={"2"} justifyContent="center">
              <Grid item xs="12" md="11" xl="10">
                <Grid container justifyContent="center" spacing={4} alignItems="stretch">
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="h4" className={classes?.mainTitleLight}>
                      {labels?.features?.title?.[lang]}
                    </Typography>
                  </Grid>

                  {labels?.features?.lst?.map((item, i) => (
                    <Grid item xs="12" md="6" lg="4" key={i} alignSelf="stretch">
                      <Paper className={classes?.box} py-2>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item>
                            <Avatar className={classes?.inner}>
                              <Icon icon={item?.icon} size="24px" color={colors.blue} />
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} justifyContent="center">
                            <Typography as="subtitle2" className={classes?.subtitleLabelPrimary} sx={{ textAlign: "center" }}>
                              {item?.title?.[lang]}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} justifyContent="center">
                            <Typography as="body3" className={classes?.subtitleLabel}>
                              {item?.decr?.[lang]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>

        <div id="sections" />
        <section className={`${classes?.section} ${classes?.sections}`}>
          <Grid container spacing={"2"} justifyContent="center" py-3>
            <Grid item xs="12" md="11" lg="10">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} justifyContent="center">
                  <Typography as="h4" className={classes?.mainTitleLight}>
                    {labels?.sections?.title?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  <Typography as="subtitle2" className={classes?.subtitleLabel}>
                    {labels?.sections?.content?.[lang]}
                  </Typography>
                </Grid>
                {labels?.sections?.lst?.map((item, i) => (
                  <Grid item xs="12" key={i}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs="auto" lg="1" alignItems="flex-start">
                        <Avatar className={classes?.inner}>
                          <Typography as="h6" className={classes?.textShadow}>{`0${i + 1}`}</Typography>
                        </Avatar>
                      </Grid>
                      <Grid item xs="9" md="auto" lg="11">
                        <Typography as="body2" className={classes?.subtitleLabel}>
                          <b style={{ color: colors?.blue }}>{item?.title?.[lang]}</b> : {item?.decr?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                      {labels?.sections?.lstTopic?.[item?.id]?.map((topics, ti) => (
                        <Grid item xs="12" key={ti}>
                          <Accordion dir={"ltr"}>
                            <AccordionTab className={classes?.accordionTab}>
                              <AccordionSummary sx={{ border: "none" }}>
                                <Typography as="subtitle3" color={colors?.blue} sx={{ fontWeight: "700" }}>
                                  {topics?.title}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className={classes?.paperAbout}>
                                <Grid item container alignSelf="flex-start" key={ti} spacing={3}>
                                  {topics?.lst?.map((topic, topicInd) => (
                                    <Grid item xs="12" md="6" xl="4" alignSelf="flex-start" key={topicInd}>
                                      <Grid container alignContent="center" spacing={0}>
                                        <Grid item xs="2">
                                          <span
                                            style={{
                                              fontFamily: "Satisfy, cursive",
                                              fontSize: "2em",
                                              color: "var(--cbmis-primary)",
                                              textShadow: `0.02em 0.02em 0 var(--cbmis-dark), 0 0 0.5em var(--cbmis-primary)`,
                                            }}
                                          >
                                            {topicInd < 9 ? `0${topicInd + 1}` : topicInd + 1}
                                          </span>
                                        </Grid>
                                        <Grid item xs={"10"} pt-2 container spacing={1}>
                                          <Grid item xs="12">
                                            <Typography as="body2" className={classes?.subtitleLabel} sx={{ textAlign: "start !important" }}>
                                              {topic?.[0]}
                                            </Typography>
                                          </Grid>
                                          {!topic?.[1] ? null : (
                                            <React.Fragment>
                                              <Grid item xs="12" px-4>
                                                <Typography as="caption" sx={{ textAlign: "start !important" }}>
                                                  <ul>
                                                    {topic?.[1]?.map((tp, ind3) => (
                                                      <li key={ind3}>{tp}</li>
                                                    ))}
                                                  </ul>
                                                </Typography>
                                              </Grid>
                                            </React.Fragment>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </AccordionDetails>
                            </AccordionTab>
                          </Accordion>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {!objVision2035Config?.blnBtnVision ? null : (
                <Grid item xs="12" justifyContent="center">
                  <Button
                    size="lg"
                    label={objVision2035Config?.strBtnTransition === objVision2035?.reg_std ? labels?.sections?.btnReg?.[lang] : labels?.sections?.btnDetails?.[lang]}
                    className={classes?.btnBox}
                    color={colors?.blue}
                    onClick={goPath(
                      objVision2035Config?.strBtnTransition === objVision2035?.tms
                        ? objVision2035Config?.strTmsLink
                        : generatePath(
                            objVision2035Config?.strBtnTransition === objVision2035?.reg_std ? appRouting?.TrainingRegistration?.url : appRouting?.Public_Initiative?.url,
                            {
                              strTrainingType: "kw",
                              countrycode: "kw",
                            }
                          ),
                      true
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </section>

        <div id="innovationWeek" />
        <section className={classes?.section}>
          <Container>
            <Grid container spacing={"2"} justifyContent="center">
              <Grid item xs="12" md="11" xl="10">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="h4" className={classes?.mainTitleLight}>
                      {labels?.innovationWeek?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="subtitle1" className={classes?.subtitleLabel}>
                      {labels?.innovationWeek?.subtitle?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="body2" className={classes?.subtitleLabel}>
                      {labels?.innovationWeek?.content?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={0} py-5 mt-10>
                  {labels?.innovationWeek?.lst?.map((item, i) => (
                    <Grid item xs="4" md="3" xl="2" key={i} justifyContent="center" sx={item?.num === 3 ? { left: "5px" } : item?.num === 2 ? { right: "5px" } : {}}>
                      <Typography className={`${classes?.textShadow} ${classes?.orderNum}`}>{item.num}</Typography>
                      <Paper className={i === 1 ? `${classes?.box3} ${classes?.box}` : classes?.box2} py-5>
                        <Grid container justifyContent="center">
                          <Grid item xs="12" justifyContent="center">
                            <Typography as="h6" className={`${classes?.mainTitle} ${classes?.smText}`}>
                              {item.title?.[lang]}
                            </Typography>
                          </Grid>
                          <Grid item xs="12" justifyContent="center">
                            <img src={robot1} alt="robot" width={"30px"} />
                          </Grid>
                          <Grid item xs="12" justifyContent="center">
                            <Typography as="subtitle1" className={classes?.subtitleLabel}>
                              {item.decr?.[lang]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>

        <div id="organizers" />
        <section className={`${classes?.section} ${classes?.organizers}`}>
          <Container>
            <Grid container spacing={"2"} justifyContent="center">
              <Grid item xs="12" md="11" xl="10">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="h4" className={classes?.mainTitleLight}>
                      {labels?.organizers?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="body2" className={classes?.subtitleLabel}>
                      {labels?.organizers?.content?.[lang]}
                    </Typography>
                  </Grid>
                  {labels?.organizers?.lst?.map((item, i) => (
                    <Grid item xs="6" md="6" key={i}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <img src={lstLogoImg?.[i]} alt="" width={i?"80px":"100x"}  />
                        </Grid>
                        {/* <Grid item xs={12} justifyContent="center">
                          <Typography as="subtitle2" className={classes?.mainTitle}>
                            {item?.name?.[lang]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} justifyContent="center">
                          <Typography as="body3" className={classes?.subtitleLabel}>
                            {item?.decr?.[lang]}
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>

        <div id="sponsors" />
        <section className={classes?.section}>
          <Container>
            <Grid container spacing={"2"} justifyContent="center">
              <Grid item xs="12" md="11" xl="10">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="h4" className={classes?.mainTitleLight}>
                      {labels?.sponsors?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Typography as="body2" className={classes?.subtitleLabel}>
                      {labels?.sponsors?.subtitle?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs="12" justifyContent="center">
                    <Button
                      size="lg"
                      label={labels?.btnSponser?.[lang]}
                      color={colors?.blue}
                      className={classes?.btnBox}
                      onClick={goPath(
                        generatePath(appState?.clientInfo?.dtmLastLoggingIn ? appRouting?.Account?.url : appRouting?.sponsertRegistration?.url, { strTrainingType: "fullstack" })
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {labels?.sponsors?.lst?.map((spon, i) => {
                let lst = [...state?.lstPartner]?.filter((item) => parseInt(state?.objTrainingSponser?.[spon?.key]?.studentNumber?.value?.eng) <= parseInt(item?.intTraineesCount));
                if (!lst.length) return null;
                return (
                  <Grid container justifyContent="center" spacing={2} key={i}>
                    <Grid item xs={12} justifyContent="center">
                      <Typography as="h4" className={classes?.mainTitle}>
                        {spon?.title?.[lang]}
                      </Typography>
                    </Grid>
                    {lst?.map((item, index) => {
                      return !item?.strLogoImg?.[0] ? null : (
                        <Grid item key={index}>
                          <Paper
                            elevation={0}
                            outline={0}
                            title={item?.jsnPartnerInfo?.strPlaceName}
                            onClick={() => {
                              if (item?.jsnPartnerInfo?.strURL) {
                                window.open(item?.jsnPartnerInfo.strURL, { traget: "_blank" });
                              }
                            }}
                            sx={item?.jsnPartnerInfo?.strURL ? { cursor: "pointer" } : {}}
                            p-1
                          >
                            <img
                              src={App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path}
                              alt={item?.jsnPartnerInfo?.title}
                              title={item?.jsnPartnerInfo?.title}
                              height={"100px"}
                            />
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </section>
      </section>
      <Grid container justifyContent="center" my-0 py-0 className={classes.copyright}>
        <Grid item>
          <Typography as="caption" color="#fff">
            {labels?.copyRight?.[lang]}
          </Typography>
        </Grid>
      </Grid>
      <BtnGoToTop className={lang === "arb" ? "arbLang" : ""} />
    </React.Fragment>
  );
}

export default SupportView;
