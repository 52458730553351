import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../util/appStyle";

export const objStyle = makeStyle({
  secDark: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    minHeight: "calc(100vh - 0px)",
    overflowY: "auto",
    margin: "40px auto 0px",
    padding: "0px",
    backgroundColor: "rgb(0, 2, 36)",
    background: "linear-gradient(155deg, #000224 0%, #010219 85%, #0371a0 100%)",
  },
  mainContainer: {
    width: `${(11.5 / 12) * 100}%`,
    margin: "20px auto 0",
  },
  container: {
    width: `${(10 / 12) * 100}%`,
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
    paddingInlineEnd: "20px",
    xs: {
      width: `${(11 / 12) * 100}%`,
    },
  },
  bgBox: (props) => ({
    height: "100%",
    padding: "20px",
    zIndex: 10,
    boxShadow: "none",
    transition: "all 2s",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      background: `url(${require("../../../util/assets/images/box1-h.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform: props?.lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
    
  }),
  bgBox2: (props) => ({
    // height: "100%",
    // padding: "20px",
    // zIndex: 10,
    // boxShadow: "none",
    // overflow: "hidden",
    // "&before": {
    //   content: "",
    //   display: "block",
    //   width: "100%",
    //   height: "100%",
    //   position: "absolute",
    //   top: "0",
    //   left: "0",
    //   background: `url(${require("../../../util/assets/images/box3.png")})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "center",
    //   backgroundSize: "100% 100%",
    //   transform: props?.lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    // },
    // "sm":{
    //   "&before":{
    //   }
    // }

    position: "relative",
    display: "block",
    height: "100%",
    width: "100%",
    // minHeight: "260px",
    // maxHeight: "260px",
    // maxWidth: "260px",
    boxShadow: "none",
    cursor: "pointer",
    zIndex: 10,
    borderRadius: "2px",
    overflow: "hidden",
    margin: "auto",
    "&before": {
      content: "",
      display: "block",
      width: "99%",
      height: "99%",
      position: "absolute", left: "0", top: "0",
      background: appStyle?.App_Dark_menu_Color,
      borderRadius: "8px",
      border: "2px dashed",
      borderColor: appStyle?.App_info_Color,
    },
  }),


  btnBox: {
    fontSize: "large",
    borderWidth: "2px",
    span: {
      ...appStyle?.fontsMada,
    },
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
      color: appStyle.App_Light_Text_Color,
    },
    sm: {
      // fontSize: "medium",
    },
    xs: {
      // fontSize: "small",
      fontWeight: "bold",
      minWidth: "120px",
    },
  },

  modalVideo: {
    background: appStyle?.App_Dark_menu_Color,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    minHeight: "25%",
    height: "98vh",
    width:"98vw",
    margin: "auto",
    color: appStyle.App_Light_Text_Color,
    padding: "20px",
    sm: {
      width: "88%",
    },
  },
  modal: {
    background: appStyle?.App_Dark_menu_Color,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    minHeight: "25%",
    margin: "auto",
    color: appStyle.App_Light_Text_Color,
    padding: "20px",
    sm: {
      width: "88%",
    },
  },
  modalHeader: {
    border: "none",
    color: appStyle.App_info_Color,
    margin: "auto",
    maxHeight: "10px",
    ".uicore_icon": {
      width: "25px",
      height: "25px",
    },
  },
  imgLine: {
    display: "block",
    margin: "20px auto",
    width: "90%",
    minHeight: "4px"
  },
  robotImg: ({ lang }) => ({
    height: "100px",
  }),
  descriptionLightText: {
    lineClamp: 5,
    WebkitLineClamp: 5,
    height: "calc(5 * 1.35em)",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    width: "100%",
    color: appStyle?.App_Light_Text_Color,
    // ...appStyle?.fontsMada,
    textAlign: "justify !important",
    cursor: "pointer",
    userSelect: "none",
  },
  descriptionLightText2: {
    lineClamp: 3,
    WebkitLineClamp: 3,
    height: "calc(3 * 1.35em)",
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    width: "100%",
    color: appStyle?.App_Light_Text_Color,
    // ...appStyle?.fontsMada,
    textAlign: "justify !important",
    cursor: "pointer",
    userSelect: "none",
  },
  descriptionLightTextActive: {
    overflow: "visible",
    lineClamp: "unset",
    WebkitLineClamp: "unset",
    boxOrient: "unset",
    WebkitBoxOrient: "unset",
    height: "auto",
    "&+": {
      ".arrowClose": {
        display: "block",
      },
    },
  },

});
