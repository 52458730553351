import React from 'react'
import Uploader from '../../Uploader'
import Icon from "../../../../icon/CbmisIcon"

function UploaderImageBox(props) {
    const {images,setImages,gallery,camera,handelOpenCamera,maxSizeKB,maxFilesNumber,maxWidth,maxHeight,quality} = props
  return (
    <React.Fragment>
         <div className="box">
            <div className="image-preview-container">
                {   images.length?
                        <img src={URL.createObjectURL(images[0])} alt="/" className="image-preview"/>
                    :
                        <Icon icon="image" className="iconImage"/>
                }
            </div>
            <div className="upload-options">
            {
              !gallery?null:(
                <label className="upload-options-btn uploader-options">
                    <Icon icon="uploadFile" />
                    <Uploader images={images} setImages={setImages}  maxSizeKB={maxSizeKB} maxFilesNumber={maxFilesNumber} maxWidth={maxWidth} maxHeight={maxHeight} quality={quality}/>
                </label>
              )
            }
            {
              !camera?null:(
                <label className=" upload-options-btn camera-options" onClick={handelOpenCamera}>
                  <Icon icon="cameraAlt" />
                </label>
              )
            }
            </div>
        </div>
    </React.Fragment>
  )
}

export default UploaderImageBox