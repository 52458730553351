import {Button, Container, Grid, Typography, useNavigate } from '@cbmisorg/client-app';
import React from 'react'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary';
import EventCard from '../../../../sharedUi/eventCard/EventCard';
import objAppRouting from '../../../../../appHelper/routing/appRouting';
import { App_orang_color } from '../../../../../appHelper/appColor';
import ParallaxScrollingApp from '../../../../sharedUi/ParallaxScrollingApp';

function Events({lang,appState}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.events
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Grid container py-10 px-0 mb-10 mt-8 spacing={0} id="events">
        <Grid item xs="0" md="12"  container  justify="center" >
          <Container>
            <Grid container justify="center" spacing={0} pb-sm-8 pb-md-8 pb-lg-8 pb-xl-8>
                <Grid item xs="12" pb-0>
                  <Typography as="h4" color="primary">{labels?.title?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12" pt-0>
                    <Typography as="subtitle1">{labels?.subtitle?.[lang]}</Typography>
                </Grid>
            </Grid>
              <Grid container spacing={3} justify="center">
                  {
                    appState?.clientInfo?.objSystemInfo?.lstActivities?.filter(item=>item?.jsnInfo?.blnHomePage)?.sort((a, b) => new Date(b.dtmCreatedDate) - new Date(a.dtmCreatedDate))?.map((item,index)=>{
                      return(
                        <Grid item xs="12" md="6" lg="4" key={index}>
                            <EventCard item={item} lang={lang} />
                        </Grid>
                        )
                      })
                  }
  
                  <Grid item xs="12">
                    <Button label={labels?.btn?.[lang]} color="primary" sx={{border:`2px solid ${App_orang_color} !important`}} onClick={()=>navigate(objAppRouting?.Public_Events?.url)}/>
                </Grid>
              </Grid>
          </Container>
        </Grid>
      </Grid>
      <ParallaxScrollingApp />

    </React.Fragment>
  )
}

export default Events
