import React, { useEffect } from 'react'


import * as  ClientFunction from "../../../system/cbmisFunction"
import useForm from '../../cbmisFormapi/useForm'
import Grid from '../../../material/layout/Grid/Grid'
import Typography from '../../../material/dataDisplay/Typography/Typography'
import FormField from '../../cbmisFormapi/FormField'
import Button from '../../../material/inputs/Button/Button'

function AddEditForm(props) {
  const {
    objFelidName,

    blnAddRoot,

    blnAdd,
    blnEdit,

    handleAdd,
    handleEdit,

    stateInfo,
    setStateInfo,

    objCategorySelectedInfo = null,
    bigParentID,
    handleCloseModal,

    color,
    objLabels,
    objLanguage,
    currentLanguage,
      } = props
  const initialState = {
    appLanguage:currentLanguage,
    objLanguage:objLanguage,
    labels:{
        strName:{
        label:{
            eng:objLabels?.strName
        },
        }
    },
    validationsMsg:{
        required:objLabels?.required,
    },
    fields:{
        strName:{
        control:"text",
        value:objCategorySelectedInfo?.strName || "",
        error:"",
        validations:{ 
            required:true,
        },
        },
    }
  }

  const {controller} = useForm(initialState)
  const newCategoryID = ClientFunction.getRandomNumber(10)


  useEffect(() => {
    if(!objCategorySelectedInfo){
      controller?.resetForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[objCategorySelectedInfo])


  const handleSubmit =()=>{
    if(controller?.isFormValid()){
      var newCategory = {}
      var newList = stateInfo?.treeList || []
      const nweValue = ClientFunction.checkParseObject(controller?.getCurrentFormValues());
        if(objCategorySelectedInfo && blnEdit){
          newCategory = objCategorySelectedInfo
          newCategory[objFelidName?.strName]=nweValue.strName
          const index= newList.findIndex(cat=>cat?.[objFelidName.intID] === newCategory?.[objFelidName.intID])
          newList[index] = newCategory
          if(handleEdit){
            handleEdit(newList,newCategory)
          }

        }else if(blnAddRoot || blnAdd){
          newCategory[objFelidName.intID]=newCategoryID
          newCategory[objFelidName.intParentID] = bigParentID
          newCategory[objFelidName.strName] = nweValue.strName
          newList.push(newCategory)
          if(handleAdd){
            handleAdd(newList,newCategory)
          }
        }
        if(stateInfo){
          stateInfo.treeList = newList
          setStateInfo({...stateInfo})
        }
        if(handleCloseModal){
          handleCloseModal()
        }
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing="3">
        <Grid item xs="12" container>
          <Grid item xs="6" justify="center">
            <Typography as="subtitle2">#ID:&nbsp;</Typography>
            <Typography>{objCategorySelectedInfo?objCategorySelectedInfo?.[objFelidName?.intID]:newCategoryID}</Typography>
          </Grid>
          <Grid item xs="6" justify="center">
            <Typography as="subtitle2">ParentID:&nbsp;</Typography>
            <Typography>{objCategorySelectedInfo?objCategorySelectedInfo?.[objFelidName?.intParentID]:bigParentID}</Typography>
          </Grid>
        </Grid>

        <Grid item xs="12">
          <FormField fieldName="strName" 
            controller={controller}
            color={color}
          />
        </Grid>
        <Grid item xs="12">
          <Button
              control="button"
              label={objLabels?.save}
              onClick={handleSubmit}
              color={color}
              size={"sm"}
              py-1 px-5

          />
      </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default AddEditForm