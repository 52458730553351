function buildSymmetricalValueObject(lstTargetCssProperties, _objInputStyle = {}, replaceFunction, _objOutputResult = {}) {
  let objOutput = _objOutputResult;

  if (typeof _objInputStyle !== "object") {
    return objOutput;
  }

  let lstSource = Object.entries(_objInputStyle || {});

  for (let i = 0; i < lstSource.length; i++) {
    const [key, value] = lstSource[i];

    if (typeof value !== "object" && lstTargetCssProperties?.includes(key)) {
      if (replaceFunction && typeof replaceFunction === "function") {
        objOutput = { ...objOutput, ...(replaceFunction(key, value) || {}) };
      } else {
        objOutput[key] = value;
      }
    } else if (typeof value === "object") {
      let result = buildSymmetricalValueObject(lstTargetCssProperties, value, replaceFunction);
      if (typeof result === "object" && Object.keys(result)?.length) {
        objOutput[key] = result;
      }
    }
  }

  return objOutput;
}

export const getSpecificCssRule = (lstTargetCssRules, objInputStyle, replaceFunction) => {
  const result = buildSymmetricalValueObject(lstTargetCssRules, objInputStyle, replaceFunction);
  return result;
};

export const sizeValuesSetter = (objInputStyle, setterFunction) => {
  const sizeSequence = ["default", "xs", "sm", "md", "lg", "xl"];
  let objResult = {};

  for (let i = 0; i < sizeSequence.length; i++) {
    let resultDefault = {};
    if (sizeSequence[i] === "default") {
      resultDefault = setterFunction(sizeSequence[i]);
      if (resultDefault && typeof resultDefault === "object" && Object.keys(resultDefault || {}).length) {
        objResult = { ...objResult, ...resultDefault };
      }
    } else {
      if (objInputStyle?.[sizeSequence[i]]) {
        let customSize = setterFunction(sizeSequence[i], objInputStyle?.[sizeSequence[i]]);
        if (customSize && typeof customSize === "object" && Object.keys(customSize || {}).length) {
          objResult = { ...objResult, ...customSize, [sizeSequence[i]]: customSize };
        }
      }
    }
  }

  return objResult;
};

export const sizeOneValueSetter = (cssProp, objInputStyle) => {
  let objResult = {};
  if (typeof objInputStyle === "string" || typeof objInputStyle === "number") {
    objResult[cssProp] = objInputStyle;
  } else if (typeof objInputStyle === "object") {
    const sizeSequence = ["xs", "sm", "md", "lg", "xl"];
    for (let i = 0; i < sizeSequence.length; i++) {
      if (objInputStyle?.[sizeSequence[i]] && (typeof objInputStyle?.[sizeSequence[i]] === "string" || typeof objInputStyle?.[sizeSequence[i]] === "number")) {
        let customSize = { [cssProp]: objInputStyle?.[sizeSequence[i]] };
        objResult = { ...objResult, ...customSize, [sizeSequence[i]]: customSize };
      }
    }
  }
  return objResult;
};

export const sizeValuesSelectDefault = (objInputStyle) => {
  const sizeSequence = ["xs", "sm", "md", "lg", "xl"];
  let defaultValue = "initial";

  for (let i = 0; i < sizeSequence.length; i++) {
    if (objInputStyle?.[sizeSequence[i]]) {
      defaultValue = objInputStyle?.[sizeSequence[i]];
    }
  }

  return defaultValue;
};

export const aggregationSizeStyle = (...lstSizeStyle) => {
  let objResult = {};
  if (lstSizeStyle?.length) {
    for (let index = 0; index < lstSizeStyle.length; index++) {
      const objInputStyle = lstSizeStyle[index];
      if (objInputStyle?.xs) {
        objResult.xs = {
          ...objResult.xs,
          ...(objInputStyle?.xs || {}),
        };
        delete objInputStyle?.xs;
      }
      if (objInputStyle?.sm) {
        objResult.sm = {
          ...objResult.sm,
          ...(objInputStyle?.sm || {}),
        };
        delete objInputStyle?.sm;
      }
      if (objInputStyle?.md) {
        objResult.md = {
          ...objResult.md,
          ...(objInputStyle?.md || {}),
        };
        delete objInputStyle?.md;
      }
      if (objInputStyle?.lg) {
        objResult.lg = {
          ...objResult.lg,
          ...(objInputStyle?.lg || {}),
        };
        delete objInputStyle?.lg;
      }
      if (objInputStyle?.xl) {
        objResult.xl = {
          ...objResult.xl,
          ...(objInputStyle?.xl || {}),
        };
        delete objInputStyle?.xl;
      }

      objResult = { ...objResult, ...(objInputStyle || {}) };
    }
  }
  return objResult;
};

// const result = sizeValuesSetter({ xs: "center", sm: "end", md: "start", lg: "flex-between" }, (size, styleValue) => {
//   return {
//     xl: { justifyContent: styleValue },
//     lg: { justifyContent: styleValue },
//     md: { justifyContent: styleValue },
//     sm: { justifyContent: styleValue },
//     xs: { justifyContent: styleValue },
//     default: { justifyContent: "flex-start" },
//   }?.[size];
// });

// const result1 = sizeValuesSelectDefault({ xs: "center", sm: "end", md: "start", lg: "flex-between" });
