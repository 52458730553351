import React, { forwardRef } from 'react'
import Icon from '../../../icon/CbmisIcon'

const RatingRadioInput = forwardRef((props, ref) => {
    const {
        name,id,value,
        icon, iconSize,readOnly,
        ...rest
    } = props

  return (
    <React.Fragment>
        <input
            className="cbmis-rating-input"
            type="radio"
            name={name}
            id={id}
            ref={ref}
            {...rest}
            readOnly={readOnly}
            disabled={readOnly}
        />
        <label htmlFor={id} className={`cbmis-rating-star-label ${!readOnly?"cbmis-rating-star-icon-hover":""}`}>
            <Icon icon={icon || "starRate"} size={iconSize} />
        </label>
    </React.Fragment>
  )
})

export default RatingRadioInput