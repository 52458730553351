export async function resizeImgKB(
    lstFileList,
    maxSizeKB,
    maxFilesNumber,
    fltQuality,
    blnDownload
  ) {
    try {
      let files = lstFileList;
      let lstResultFile = [];
  
      for (var i = 0; i < files.length; i++) {
        let strBase64ResizedImg = await processFileKB(files[i], i, maxSizeKB,fltQuality); // process each file at once
        let strImgType= "image/jpg"

        if (strBase64ResizedImg) {
          let fileNew = await fetch(strBase64ResizedImg);
          let blob = await fileNew.blob();
          let strFileName = files[i].name;
          let strNewFileType = files[i].type;
          if (strImgType) {
            strFileName = String(files[i].name).split(".")[0] + "." + String(strImgType).split("/")[1];
            strNewFileType = strImgType;
          }
  
          let newObjFile = new File([blob], strFileName, { type: strNewFileType });
          if(i < maxFilesNumber){
            lstResultFile.push(newObjFile);
          }else{
            lstResultFile[maxFilesNumber - 1] = newObjFile
          }

          if (blnDownload) {
            await downloadFile(newObjFile);
          }
        }
      }
      return lstResultFile;
    } catch (error) {
      console.error(error);
      return [];
    }
  }


  function processFileKB(objFile, intIndex,maxSizeKB,fltQuality=.95) {
    let strImgType=""
    return new Promise((resolve, reject) => {
      try {
        if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
          console.error("The File APIs are not fully supported in this browser.");
          resolve(false);
          return;
        }
  
        if (!/image/i.test(objFile.type)) {
          console.error("File " + objFile.name + " is not an image.");
          resolve(false);
          return;
        }
  
        // read the files
        let reader = new FileReader();
        reader.readAsArrayBuffer(objFile);
  
        reader.onload = (event) => {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          let blobURL = window.URL.createObjectURL(blob); // and get it's URL
  
          // helper Image object
          let objImg = new Image();
          objImg.src = blobURL;
  
          objImg.onload = () => {
            // have to wait till it's loaded
  
            if (!strImgType) {
              strImgType = objFile.type;
            }
  
            let canvas = document.createElement("canvas");
  
            let width = objImg.width;
            let height = objImg.height;

           

            if(maxSizeKB){
                const  accept= (maxSizeKB* 1024/objFile.size) * fltQuality
                let maxWidth = objImg.width * accept;
                let maxHeight = objImg.height * accept;

                // // maxHeight = height- maxHeight

                // Change the resizing logic
                  if (width > height) {
                    if (width > maxWidth) {
                        height = height * (maxWidth / width);
                        width = maxWidth;
                    }
                  } else {
                    if (height > maxHeight) {
                        width = width * (maxHeight / height);
                        height = maxHeight;
                    }
                  }
            }

  
            if (!canvas.getContext) {
              console.error("Canvas are not fully supported in this browser.");
              resolve(false);
              return;
            }
  
            // resize the canvas and draw the image data into it
            canvas.width = width;
            canvas.height = height;
  
            let ctx = canvas.getContext("2d");
            ctx.drawImage(objImg, 0, 0, width, height);
  
            let dataURL = canvas.toDataURL(strImgType, fltQuality); // get the data from canvas as 70% JPG (can be also PNG, etc.)
  
            resolve(dataURL);
  
            canvas.onerror = (error) => {
              console.error(`Error occurred while canvas initialized.\n${error.toString()}`);
              resolve(false);
            };
          };
  
          objImg.onerror = () => {
            console.error(`Error occurred when loading image: ${objFile.name}.`);
            resolve(false);
          };
        };
  
        reader.onerror = (error) => {
          console.error(`Error occurred reading file: ${objFile.name}.\n${error.target.error}`);
          resolve(false);
        };
      } catch (error) {
        console.error(error);
        resolve(false);
      }
    });
  }


  // export async function resizeImgCamera(
  //   lstFileList,
  //   maxSizeKB,
  //   blnDownload=true
  // ) {
  //   try {
  //     let files = lstFileList;
  //     let lstResultFile = [];
  
  //     for (var i = 0; i < files.length; i++) {
  //       let strBase64ResizedImg = await processFile(files[i], i, maxSizeKB); // process each file at once
  //       let strImgType= "image/jpg"

  //       if (strBase64ResizedImg) {
  //         let fileNew = await fetch(strBase64ResizedImg);
  //         let blob = await fileNew.blob();
  //         let strFileName = files[i].name;
  //         let strNewFileType = files[i].type;
  //         if (strImgType) {
  //           strFileName = String(files[i].name).split(".")[0] + "." + String(strImgType).split("/")[1];
  //           strNewFileType = strImgType;
  //         }
  
  //         let newObjFile = new File([blob], strFileName, { type: strNewFileType });
  //         lstResultFile.push(newObjFile);

  //         if (blnDownload) {
  //           await downloadFile(newObjFile);
  //         }
  //       }
  //     }
  
  //     return lstResultFile;
  //   } catch (error) {
  //     console.error(error);
  //     return [];
  //   }
  // }




  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region resize image
/**
 *
 * @param {FileList | any[]} lstFileList
 * @param {*} intMaxWidth
 * @param {*} intMaxHeight
 * @param {number} [fltQuality] quality of image max value 1
 * @param {boolean} [blnForceResize] force to resize image according to intMaxWidth and intMaxHeight
 * @param {"image/apng" | "image/avif" | "image/gif" | "image/jpeg" | "image/png" | "image/webp" | "image/bmp" | "image/x-icon" | "image/tiff"} [strImgType]
 * @param {boolean} [blnDownload] Download Each image after convert it.
 */
export async function resizeImgWH(
  lstFileList,
  intMaxWidth = 620,
  intMaxHeight = 620,
  fltQuality = 0.95,
  maxFilesNumber,
  blnDownload = false,
  blnForceResize = false,
  strImgType = null,
  
) {
  try {
    let files = lstFileList;
    let lstResultFile = [];

    for (var i = 0; i < files.length; i++) {
      let strBase64ResizedImg = await processFileWH(files[i], i, intMaxWidth, intMaxHeight, fltQuality, strImgType, blnForceResize); // process each file at once

      if (strBase64ResizedImg) {
        let fileNew = await fetch(strBase64ResizedImg);
        let blob = await fileNew.blob();
        let strFileName = files[i].name;
        let strNewFileType = files[i].type;
        if (strImgType) {
          strFileName = String(files[i].name).split(".")[0] + "." + String(strImgType).split("/")[1];
          strNewFileType = strImgType;
        }

        let newObjFile = new File([blob], strFileName, { type: strNewFileType });
        if(i < maxFilesNumber){
          lstResultFile.push(newObjFile);
        }else{
          lstResultFile[maxFilesNumber - 1] = newObjFile
        }

        if (blnDownload) {
          await downloadFile(newObjFile);
        }
      }
    }

    return lstResultFile;
  } catch (error) {
    console.error(error);
    return [];
  }
}



/**
 *
 * @param {File} objFile
 * @param {number} intIndex
 * @param {number} intMaxWidth
 * @param {number} intMaxHeight
 * @param {number} fltQuality
 * @param {string} strImgType
 * @param {boolean} blnForceResize
 * @param {boolean} blnShowPreview
 * @returns {Promise<string | false>}
 */
function processFileWH(objFile, intIndex, intMaxWidth, intMaxHeight, fltQuality, strImgType, blnForceResize) {
  return new Promise((resolve, reject) => {
    try {
      if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
        console.error("The File APIs are not fully supported in this browser.");
        resolve(false);
        return;
      }

      if (!/image/i.test(objFile.type)) {
        console.error("File " + objFile.name + " is not an image.");
        resolve(false);
        return;
      }

      // read the files
      let reader = new FileReader();
      reader.readAsArrayBuffer(objFile);

      reader.onload = (event) => {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;
        let blobURL = window.URL.createObjectURL(blob); // and get it's URL

        // helper Image object
        let objImg = new Image();
        objImg.src = blobURL;

        objImg.onload = () => {
          // have to wait till it's loaded

          if (!strImgType) {
            strImgType = objFile.type;
          }

          let canvas = document.createElement("canvas");

          let width = objImg.width;
          let height = objImg.height;

          // calculate the width and height, constraining the proportions
          if (blnForceResize) {
            width = intMaxWidth;
            height = intMaxHeight;
          } else {
            if (width > height) {
              if (width > intMaxWidth) {
                //height *= intMaxWidth / width;
                height = Math.round((height *= intMaxWidth / width));
                width = intMaxWidth;
              }
            } else {
              if (height > intMaxHeight) {
                //width *= intMaxHeight / height;
                width = Math.round((width *= intMaxHeight / height));
                height = intMaxHeight;
              }
            }
          }

          if (!canvas.getContext) {
            console.error("Canvas are not fully supported in this browser.");
            resolve(false);
            return;
          }

          // resize the canvas and draw the image data into it
          canvas.width = width;
          canvas.height = height;

          let ctx = canvas.getContext("2d");
          ctx.drawImage(objImg, 0, 0, width, height);

          let dataURL = canvas.toDataURL(strImgType, fltQuality); // get the data from canvas as 70% JPG (can be also PNG, etc.)

          resolve(dataURL);

          canvas.onerror = (error) => {
            console.error(`Error occurred while canvas initialized.\n${error.toString()}`);
            resolve(false);
          };
        };

        objImg.onerror = () => {
          console.error(`Error occurred when loading image: ${objFile.name}.`);
          resolve(false);
        };
      };

      reader.onerror = (error) => {
        console.error(`Error occurred reading file: ${objFile.name}.\n${error.target.error}`);
        resolve(false);
      };
    } catch (error) {
      console.error(error);
      resolve(false);
    }
  });
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function downloadFile(objFile) {
  try {
    const element = document.createElement("a");
    const file = objFile;
    element.href = URL.createObjectURL(file);
    element.download = file.name;
    document.body.appendChild(element);
    element.click();

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}