import { Icon } from "@cbmisorg/icons";
import { Button, Grid, Paper, Typography } from "@cbmisorg/material";
import React, { useState } from "react";
import { App_Dark_Color, App_Dark_Text_Color, App_info_Color, App_info_Opacity_Color, App_info_Opacity_Color1 } from "../../../../../../util/appStyle";
import { useNavigate } from "@cbmisorg/router";

function ServicesCard({ service, classes, lang }) {
  const [state, setstate] = useState({ blnOpenInfo: false });
  const navigate = useNavigate();
  const gorToUrl = (url) => () => {
    navigate(url);
  };

  const handelOpenInfo = () => {
    setstate({ blnOpenInfo: !state.blnOpenInfo });
  };
  return (
    <Paper className={classes?.card}>
      <Grid container justifyContent="center" className={classes?.cardContiner}>
        <Grid item xs="12">
          <Icon icon={service?.icon?.value?.[lang]} color={App_info_Color} size="32px" />
        </Grid>
        <Grid item>
          <Typography as="h6" className={classes?.cardTitle}>
            {service?.value?.[lang]}
          </Typography>
        </Grid>
        <Grid item xs="12" container justifyContent="space-between" py-0 spacing={0}>
          <Grid item px-1 >
            <Button
              icon="openInNew"
              iconSize="24px"
              className={`${!service?.url ? classes?.btnCardD : classes?.btnCard}`}
              color={!service?.url ? App_info_Opacity_Color1:App_info_Color}
              mode={"link"}
              onClick={gorToUrl(service?.url?.value?.[lang])}
              disabled={!service?.url}
            />
          </Grid>
          <Grid item px-1>
            <Button icon="infoOutlined" iconSize="24px" className={classes?.btnCard} color={App_info_Color} mode={"link"} onClick={handelOpenInfo} sx={{ borderRadius: "100%" }} />
          </Grid>
        </Grid>
      </Grid>
      <Typography
        as="body2"
        m-auto
        color={App_Dark_Text_Color}
        className={`${classes?.cardDescription} ${state?.blnOpenInfo ? classes?.cardDescriptionHoverd : classes?.cardDescriptionClose}`}
      >
        <Icon icon="close" className={classes?.closeIcon} onClick={handelOpenInfo} />
        {service?.description?.value?.[lang]}
      </Typography>
    </Paper>
  );
}

export default ServicesCard;
