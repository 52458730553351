import React, {  useEffect, useState } from "react";
import mngPotentialSponsorshipCtrl from "./mngPotentialSponsorship.controller";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Box, Button, Container, Grid, Icon, Line, Loader, Modal, ModalBody, ModalHeader, Paper, Tabs, Tooltip, Typography, useParams } from "@cbmisorg/client-app";
import { App_Dark_Color, App_Primary_Color, App_orang_color } from "../../../../appHelper/appColor";
import * as appVariables from "../../../../appHelper/appVariables";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import MngSuggestedSponsor from "./mngSuggestedSponsor/MngSuggestedSponsor";
import Followup from "./View/Followup";
import Notes from "./View/Notes";
import Info from "./View/Info";
import { useApp } from "../../../../../client/configuration/contextapi/context";

export default function MngPotentialSponsorshipView() {
  useCheckCat();

  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const labels = dictionary.components.mngPotentialSponsorship;
  const [isLoading, setIsLoading] = useState(false);
  const { strTrainingType } = useParams();
  const [state, setState] = useState({
    blnIsInitDataDone: false,
    lstTracking: [],

    intSubmissionNum: 0,
    intFollowUpNum: 0,
    // intFollowUpNum2: 0,
    // intFollowUpNum3: 0,
    intApprovedNum: 0,
    intRejectedNum: 0,
    intSuggestedNum: 0,
    blnClosedModal: false,

    lstTrainingSuggested: [],

    bigCurrentTab: appVariables.objPotentialSponsorshipStatus?.Suggested,

    intNumViewItem: 0,

    blnOpenTrackLogModal: false,
    intIndexTrackLog: null,
    objHisTrack: null,

    blnOpenEditModal: false,
    intIndexTrackEdit: null,
    blnIsEditTrackModal: null,

    blnOpenDetail: false,
    intIndexTrackDetail: null,
    objDetailTrack: null,
  });

  const fldSearch = FormData({
    strSearch: {
      strControl: "text",
      objLanguage: false,
    },
    bigSearchWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: "all", value: labels?.allWorkSector?.[lang] },
        { key: appVariables.workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
      value: { key: "all", value: labels?.allWorkSector?.[lang] },
    },
    intSearchByAddedDay: {
      strControl: "list",
      objLanguage: false,
      lstOption: [
        { key: "withoutSpecificTime", value: labels?.withoutSpecificTime?.[lang] },
        { key: "beforeWeek", value: labels?.beforeWeek?.[lang] },
        { key: "before2Week", value: labels?.before2Week?.[lang] },
        { key: "beforeMonth", value: labels?.beforeMonth?.[lang] },
      ],
      value: { key: "withoutSpecificTime", value: labels?.withoutSpecificTime?.[lang] },
    },
    bigSearchSponsorshipStatus: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: "all", value: labels?.allStatus?.[lang] },
        { key: appVariables.objPotentialSponsorshipStatus?.Approved, value: dictionary?.components?.mngPotentialSponsorship?.Approved?.[lang] },
        { key: appVariables.objPotentialSponsorshipStatus?.Rejected, value: dictionary?.components?.mngPotentialSponsorship?.Rejected?.[lang] },
      ],
      value: { key: "all", value: labels?.allStatus?.[lang] },
    },
  });

  const fields = FormData({
    strSponsorName: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strSponsorEmail: { strControl: "text", objLanguage: false, objValidations: { email: true } },
    strSponsorPhone: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } } },
    bigWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: appVariables.workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
    },
    strURL: { strControl: "text", objLanguage: false },
    strFullNameRecipient: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strUserEmailRecipient: { strControl: "text", objLanguage: false, objValidations: { email: true } },
    strUserPhoneRecipient: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } } },
    strNotes: { strControl: "textarea", objLanguage: false },
    dtmAlert: { strControl: "date", objLanguage: false },
    addNote: { strControl: "checkbox", objLanguage: false, lstOption: [{ key: "true", value: labels?.addNotes?.[lang] }] },
    addAlert: {
      strControl: "radio",
      objLanguage: false,
      lstOption: [
        { key: "false", value: labels?.addAlert2?.[lang] },
        { key: "true", value: labels?.addAlert1?.[lang] },
      ],
    },
  });

  const handlers = mngPotentialSponsorshipCtrl({ appState, appDispatch });
  const initData = handlers.initData({ state, setState, isLoading, setIsLoading, strTrainingType });
  const confirmAddToDB = handlers.confirmAddToDB({ fields, setState, state, setIsLoading, isLoading, strTrainingType });
  const updateToDB = handlers.updateToDB({ state, setState, isLoading, setIsLoading, fields, strTrainingType });
  const getAddedBeforeLabel = handlers.getAddedBeforeLabel({ state });
  const confirmUpdateToDB = handlers.confirmUpdateToDB({ state, setState, isLoading, setIsLoading, fields, strTrainingType });
  const setSuggestedAsPotential = handlers.setSuggestedAsPotential({ state, setState });

  useEffect(() => {
    if (!state.blnIsInitDataDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={strTrainingType === appVariables.objTrainingToSupport?.manager?.key ? labels?.titleManager?.[lang] : labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {!isLoading ? null : <Loader color={App_Dark_Color} backdrop={true} />}
        {
          //#region  alert
          <Notes appState={appState} setState={setState} state={state} fields={fields} handlers={handlers} blnWithDate={false} />

          //#endregion
        }
        <Grid container justify={"center"} sx={{ xs: { flexWrap: "nowrap" } }}>
          <Grid item xs={12} p-0 sx={{ ".cbmis-tabs": { width: "100% !important", maxWidth: "99% !important", alignItems: "center" } }}>
            <Tabs
              mode="box"
              activeColor="primary"
              p-0
              dir={lang === "arb" ? "rtl" : "ltr"}
              currentTab={1}
              tabsContent={[
                {
                  tabLabel: (
                    <Grid container>
                      <Grid item p-0 xs="9" sm="auto">
                        {labels?.Suggested?.[lang]}&nbsp;
                      </Grid>
                      <Grid item p-0 xs="2" sm="auto" px-1>
                        <Box py-0 px-2 m-0 color=" " sx={{ background: App_Primary_Color }}>
                          <Typography as="caption" color="light" p-0 m-0>
                            <b>{state?.intSuggestedNum || 0}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ),
                  onChange: () => setState({ ...state, bigCurrentTab: appVariables.objPotentialSponsorshipStatus.Suggested }),
                },
                {
                  tabLabel: (
                    <Grid container>
                      <Grid item p-0 xs="9" sm="auto">
                        {labels?.Submission?.[lang]}&nbsp;
                      </Grid>
                      <Grid item p-0 xs="2" sm="auto"  px-1>
                        <Box py-0 px-2 m-0 color=" " sx={{ background: App_Primary_Color }}>
                          <Typography as="caption" color="light" p-0 m-0>
                            <b>{state?.intFollowUpNum || 0}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ),
                  onChange: () => setState({ ...state, bigCurrentTab: appVariables.objPotentialSponsorshipStatus.Follow_Up }),
                },
                {
                  tabLabel: (
                    <Grid container>
                      <Grid item p-0 xs="9" sm="auto">
                        {labels?.closed?.[lang]}&nbsp;
                      </Grid>
                      <Grid item p-0 xs="2" sm="auto" px-1>
                        <Box py-0 px-2 m-0 color=" " sx={{ background: App_Primary_Color }}>
                          <Typography as="caption" color="light" p-0 m-0>
                            <b>{state?.intApprovedNum + state.intRejectedNum || 0}</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ),
                  onChange: () => setState({ ...state, bigCurrentTab: Number(appVariables.objPotentialSponsorshipStatus.Approved) }),
                },
              ]}
            />
          </Grid>
        </Grid>

        {state?.bigCurrentTab === appVariables.objPotentialSponsorshipStatus.Suggested ? (
          <MngSuggestedSponsor setSuggestedAsPotential={setSuggestedAsPotential} />
        ) : state?.bigCurrentTab === appVariables.objPotentialSponsorshipStatus.Submission || state?.bigCurrentTab === appVariables.objPotentialSponsorshipStatus.Follow_Up ? (
          <Followup blnFollowUp={true} appState={appState} fields={fields} fldSearch={fldSearch} setState={setState} state={state} handlers={handlers} />
        ) : (
          <Followup blnFollowUp={false} appState={appState} fields={fields} fldSearch={fldSearch} setState={setState} state={state} handlers={handlers} />
        )}
      </Container>

      {/*  Add/Edit Partner Modal */}

      <Modal size="xl" open={state.blnOpenEditModal} eventClose={() => setState({ ...state, blnOpenEditModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                {state.blnIsEditTrackModal
                  ? String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables.objPotentialSponsorshipStatus?.Approved) &&
                    String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables.objPotentialSponsorshipStatus?.Rejected)
                    ? labels?.Follow_UpAgain?.[lang]
                    : labels.updatePotentialPartnerInfo?.[lang]
                  : labels.addPotentialPartnerInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody>
          {!state.blnIsEditTrackModal ||
          (String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables?.objPotentialSponsorshipStatus.Follow_Up) &&
            String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables?.objPotentialSponsorshipStatus.Submission)) ? null : (
            <>
              <Grid container my-2 spacing="2" justify={"center"}>
                <Grid item xs="12">
                  <Typography color={App_Dark_Color} as="body2">
                    {labels.noteChangeStatus?.[lang]}
                  </Typography>
                </Grid>
              </Grid>

              {!state.blnIsEditTrackModal ||
              (String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables?.objPotentialSponsorshipStatus.Follow_Up) &&
                String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables?.objPotentialSponsorshipStatus.Submission)) ? null : (
                <Grid container  spacing="2">
                  <Grid item xs="auto">
                    <Typography as="body2" sx={{ color: "#707981" }}>
                      {labels.addAlertFollowUp?.[lang]}&nbsp;
                      
                    </Typography>
                  </Grid>
                  <Grid item xs="1">
                  <Tooltip
                   title={labels?.addAlertFollowUp1?.[lang]}  color="primary" >
                  </Tooltip>
                  <Icon
                   icon="info" color="primary" />
                </Grid>

                  <Grid item xs="12" p-0>
                    <FormField objHandler={fields.addAlert} />
                  </Grid>
                  {fields.addAlert?.getValue()?.key !== "true" ? null : (
                    <Grid item xs="12" pt-0 style={{ alignSelf: "flex-start" }}>
                      <FormField objHandler={fields.dtmAlert} dir={lang === "arb" ? "rtl" : "ltr"} />
                    </Grid>
                  )}
                </Grid>
              )}

              <Line />

              <Grid container justify={"center"}  spacing="3">
                <Grid
                  item
                  xs={
                    !state.blnIsEditTrackModal ||
                    (String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables?.objPotentialSponsorshipStatus.Follow_Up) &&
                      String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(appVariables?.objPotentialSponsorshipStatus.Submission))
                      ? "0"
                      : 12
                  }
                >
                <FormField objHandler={fields.addNote} />
                </Grid>
                {!fields.addNote?.getValue()?.length ? null : (
                  <Grid item xs="12" pt-0>
                    <FormField objHandler={fields.strNotes} />
                  </Grid>
                )}
              </Grid>

              <Grid container justify={"center"} my-3 >
                <Grid item xs={12} mt-4>
                  <Grid item>
                    <Button label={labels?.Follow_Confirm?.[lang]} color={App_Primary_Color} py-1 onClick={updateToDB(appVariables.objPotentialSponsorshipStatus.Follow_Up, false)} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {state.blnIsEditTrackModal ? null : (
            <>
              <Line my-3 />
              <Grid container >
                <Grid item xs={12}>
                  <Typography
                    as="subtitle2"
                    sx={{
                      borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                      paddingInlineStart: "8px !important",
                      color: App_Primary_Color,
                      fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                    }}
                  >
                    {dictionary?.components?.public?.join?.partnerInfo?.[lang]}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormField objHandler={fields.strSponsorName} icon="AccountCircle" />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormField objHandler={fields.strSponsorEmail} icon="email" dir={"ltr"} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormField objHandler={fields.strSponsorPhone} icon="phone" dir={"ltr"} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormField objHandler={fields.bigWorkSector} icon="Work" dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormField objHandler={fields.strURL} icon="link" dir={lang === "arb" ? "ltr" : "ltr"} />
                </Grid>

                <Line my-3 sx={{ width: "100%" }} />
                <Grid item xs={12}>
                  <Typography
                    as="subtitle2"
                    sx={{
                      borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                      paddingInlineStart: "8px !important",
                      color: App_Primary_Color,
                      fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                    }}
                  >
                    {labels?.recipientInfo?.[lang]}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormField objHandler={fields.strFullNameRecipient} icon="AccountCircle" />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormField objHandler={fields.strUserEmailRecipient} icon="email" className={`inputLeft inputLeft${lang}`} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormField
                    objHandler={fields.strUserPhoneRecipient}
                    icon={lang === "arb" ? "phone" : null}
                    endIcon={lang !== "arb" ? "phone" : null}
                    endAdornment={lang === "arb" ? appVariables.globalKey : null}
                    adornment={lang !== "arb" ? appVariables.globalKey : null}
                    className={`inputLeft`}
                  />
                </Grid>

                <Line my-3 sx={{ width: "100%" }} />
                <Grid item xs="12" pb-0>
                <FormField objHandler={fields.addNote} />
                </Grid>
                {!fields.addNote?.getValue()?.length ? null : (
                  <Grid item xs="12" pt-0>
                    <FormField objHandler={fields.strNotes} />
                  </Grid>
                )}
              </Grid>

              <Grid container justify={"center"} my-3>
                <Grid item>
                  <Button label={dictionary.shared.btn?.addBtn?.[lang]} onClick={confirmAddToDB} />
                </Grid>
              </Grid>
            </>
          )}
        </ModalBody>
      </Modal>

      {/*  Track Log Modal */}

      <Modal open={state.blnOpenTrackLogModal} eventClose={() => setState({ ...state, blnOpenTrackLogModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                {getAddedBeforeLabel({
                  dtmCreatedDate: state.objHisTrack?.dtmUpdatedDate,
                })}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Grid container justify={"center"}>
            <Grid item xs="12">
              <Paper elevation={0} outline={1}>
                {state.objHisTrack?.strNotes}
              </Paper>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      {/*  Track confirmed Close */}
      <Modal
        open={state.blnClosedModal}
        eventClose={() => {
          setState({ ...state, blnClosedModal: false });
        }}
      >
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                {labels?.close?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Grid container justify={"center"} spacing={2}>
            <Grid item xs="12">
              <Typography as="body2">{dictionary.components.mngPotentialSponsorship.confirmClose?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fields.addNote} />
            </Grid>
            {!fields.addNote?.getValue()?.length ? null : (
              <Grid item xs="12" pt-0>
                <FormField objHandler={fields.strNotes} />
              </Grid>
            )}
            <Grid item>
              <Button label={labels?.Approved?.[lang]} onClick={confirmUpdateToDB(appVariables.objPotentialSponsorshipStatus?.Approved, false, true)} />
            </Grid>
            <Grid item>
              <Button label={labels?.Rejected?.[lang]} color={App_orang_color} onClick={confirmUpdateToDB(appVariables.objPotentialSponsorshipStatus?.Rejected, false, true)} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      {/*  Sponsor Catalogue Detail Modal */}

      <Modal size="sm" open={state.blnOpenDetail && !state.blnOpenTrackLogModal && !state.blnClosedModal && !state.blnOpenEditModal} eventClose={() => setState({ ...state, blnOpenDetail: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="caption" sx={{ textAlign: "center !important", fontWeight: "bold !important" }}>
                {state.objDetailTrack?.jsnPartnershipTrackInfo?.strSponsorName?.[lang] || state.objDetailTrack?.jsnPartnershipTrackInfo?.strSponsorName}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody>
          <Info
            objTracking={state?.objDetailTrack}
            intIndex={state?.intIndexTrackDetail}
            blnFollowUp={true}
            appState={appState}
            fields={fields}
            setState={setState}
            state={state}
            handlers={handlers}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
