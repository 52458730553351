import { Grid, Icon,Typography, AccordionSummary, AccordionTab, Accordion, Line, AccordionDetails, Note } from '@cbmisorg/client-app'
import React from 'react'
import { requirementContent } from '../../../appHelper/appVariables'
import { dictionary } from '../../../appHelper/appLanguage/dictionary'

const labels = dictionary?.components?.manageSystem
function RequirementInfo({userInfo=null,objSystemInfo={},lang="arb",uploaderGovID,uploaderRest}) {
  const jsnSystemConfig = objSystemInfo?.jsnSystemConfig
  const blnSubscribeRequest = userInfo?.blnIsApproved && !userInfo?.blnIsPaySubscribed

  return (
    <React.Fragment>
      {
        jsnSystemConfig?.listContent?.includes(requirementContent?.note) || blnSubscribeRequest ||  userInfo?.jsnUserInfo?.strNote? (
          <Grid item xs="12">
            <Note>
              <Grid container p-0 justify={'start'}>
                <Grid item xs="1">
                  <Icon icon="info" />
                </Grid>
                <Grid item xs="11">
                  <Typography as="body2">{
                     userInfo?.jsnUserInfo?.strNote?userInfo?.jsnUserInfo?.strNote?.[lang] 
                      : !blnSubscribeRequest?jsnSystemConfig?.strNote?.[lang]:jsnSystemConfig?.strSubscriptionNote?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
            </Note>
          </Grid>
        ):null
      }

      {
        jsnSystemConfig?.listContent?.includes(requirementContent?.bankAccount) || blnSubscribeRequest ? (
          <Grid item xs="12" md="6" className="accordionTab_landingPage">
          <Accordion className="accordionTab_landingPage" dir={lang==="arb"?"rtl":"ltr"}>
            <AccordionTab>
              <AccordionSummary expandIcon={<Icon icon="AccountBalance" color="primary"/>}>
                  <Typography as="subtitle2" color="primary">{labels?.strAccountBank?.[lang]}</Typography>
              </AccordionSummary>
              <Line />
              <AccordionDetails>
              <Grid container>
                  <Grid item xs="12" container>
                    <Grid item>
                        <Typography as="subtitle2">{dictionary?.formapi?.strBankName?.label?.[lang]}:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography as="body2">{jsnSystemConfig?.strBankName?.[lang]}</Typography>
                      </Grid>
                  </Grid>

                  <Grid item xs="12" container>
                  <Grid item>
                      <Typography as="subtitle2">{dictionary?.formapi?.strAccountBankName?.label?.[lang]}:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography as="body2">{jsnSystemConfig?.strAccountName?.[lang]}</Typography>
                    </Grid>
                </Grid>

                <Grid item xs="12" container>
                <Grid item>
                    <Typography as="subtitle2">{dictionary?.formapi?.strAccountIBAN?.label?.[lang]}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography as="body2">{jsnSystemConfig?.strAccountIBAN}</Typography>
                  </Grid>
                </Grid>

                </Grid>
              </AccordionDetails>
            </AccordionTab>
          </Accordion>
          </Grid>
        ):null
      }
      {
        jsnSystemConfig?.listContent?.includes(requirementContent?.amount) || blnSubscribeRequest? (
        <Grid item xs="12" md="6" className="accordionTab_landingPage" >
          <Accordion className="accordionTab_landingPage" dir={lang==="arb"?"rtl":"ltr"}>
            <AccordionTab>
              <AccordionSummary arrowIcon="" expandIcon={<Icon icon="Paid" color="primary"/>}>
                  <Grid container justify={'space-between'}>
                    <Grid item >
                      <Typography as="subtitle2" color="primary">{labels?.strAmount?.[lang]}</Typography>
                    </Grid>
                    <Grid item >
                      <Typography as="body2" color="primary">{`${blnSubscribeRequest?jsnSystemConfig?.strSubscriptionAmount:jsnSystemConfig?.strAmount} ${labels?.currency?.[lang]}`}</Typography>
                    </Grid>
                  </Grid>
              </AccordionSummary>
              <Line />
            </AccordionTab>
          </Accordion>
          </Grid>
        ):null
      }
      {
        ((jsnSystemConfig?.listDoc?.includes(requirementContent?.govID) || jsnSystemConfig?.listDoc?.includes(String(requirementContent?.reset))) && !blnSubscribeRequest) 
        || blnSubscribeRequest ? (
          <Grid item className="file" xs="12" lg="auto">
            <Typography as="body2">{labels?.uploadedRequirementInfo?.[lang]}</Typography>
          </Grid>
        ):null
      }

      {
        ((jsnSystemConfig?.listDoc?.includes(String(requirementContent?.govID)) || jsnSystemConfig?.listDoc?.includes(requirementContent?.govID))  && !blnSubscribeRequest) || 
        ((jsnSystemConfig?.listSubscriptionRequiredDoc?.includes(String(requirementContent?.govID)) || jsnSystemConfig?.listSubscriptionRequiredDoc?.includes(requirementContent?.govID))  && blnSubscribeRequest) ? (
          <Grid item className="file">
            {uploaderGovID?.JSX}
          </Grid>
        ):null
      }
      {
        ((jsnSystemConfig?.listDoc?.includes(String(requirementContent?.reset)) || jsnSystemConfig?.listDoc?.includes(requirementContent?.reset))  && !blnSubscribeRequest) 
        || ((jsnSystemConfig?.listSubscriptionRequiredDoc?.includes(String(requirementContent?.reset)) || jsnSystemConfig?.listSubscriptionRequiredDoc?.includes(requirementContent?.reset))  && blnSubscribeRequest) ? (
          <Grid item className="file">
            {uploaderRest?.JSX}
          </Grid>
        ):null
      }

    </React.Fragment>
  )
}

export default RequirementInfo