import React, { useEffect, useRef, useState } from 'react'
import './Chatbot.css'
// import chat from "./data.json"

import Header from './components/hrader/Header'
import MessageBox from './components/messageBox/MessageBox'
import Message from './components/message/Message'

function ChatBot(props) {
    const {chat={},errorMsg="Please, try again later.",
        mode,positionHorizontal,positionVertical,blnAgentImg=true,icon,iconColor,iconSize,avatarColor,avatarSize
        } =props
    const [conversion, setConversion] = useState([])
    const lastRebotMsg = useRef("")


  useEffect(() => {
    const newConversion = []
    if(!chat?.["root"]){
        chat["root"] = {
            "message": [],
            "options":[],
            "keyWord":[],
            "nextIndex":3
        }
    }
    chat["root"].isPersonal=false
    lastRebotMsg.current=chat["root"]
    newConversion[0]=chat["root"]
    setConversion(newConversion)
  }, [chat])

  const getMode=()=>{
    switch (String(mode).toLowerCase()) {
        case "full":
            return "cbmis-messenger-container-full";
        default:
            return "";
    }
  }

 
  const getPositionHorizontal=()=>{
    switch (String(positionHorizontal).toLowerCase()) {
        case "right":
            return "cbmis-messenger-position-horizontal-right";
        default:
            return "cbmis-messenger-position-horizontal-left";
    }
  }
  const getPositionVertical=()=>{
    switch (String(positionVertical).toLowerCase()) {
        case "bottom":
            return "cbmis-messenger-position-vertical-bottom";
        default:
            return "cbmis-messenger-position-vertical-top";
    }
  }
  return (
    <div className={`cbmis-messenger-main-container ${String(mode).toLowerCase() !== "full"?'cbmis-messenger-chat': ""} ${getPositionHorizontal()} ${getPositionVertical()} cbmis-messenger-close`}>
        <section className={`cbmis-messenger-container ${getMode()}`}>
            <Header blnAgentImg={blnAgentImg} icon={icon} iconColor={iconColor} iconSize={iconSize} avatarColor={avatarColor} avatarSize={avatarSize}/>
            <div className="cbmis-chat">
                <div className="cbmis-chat-messages">
                    <div className="cbmis-chat-messages-content">
                        {
                            conversion.map((msg,index)=>{
                                return(
                                    <React.Fragment key={index}>
                                        <Message messageInfo={msg}/>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
                <MessageBox objChat={chat} conversion={conversion} setConversion={setConversion} errorMsg={errorMsg} lastRebotMsg={lastRebotMsg}/>
             </div>
        </section>
    </div>
  )
}

export default ChatBot