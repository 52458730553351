export const getDefaultValue = (defaultValue) => {
  if (defaultValue && Object.keys(defaultValue).length > 0 && defaultValue.length) {
    return defaultValue;
  }
  return;
};
export const isDate = (date) => {
  const isDate = Object.prototype.toString.call(date) === "[object Date]";
  const isValidDate = date && !Number.isNaN(date.valueOf());
  return isDate && isValidDate;
};

export const getDirectionReturnClassName = (dir) => {
  const getDirection = String(dir).toLowerCase();
  switch (getDirection) {
    case "rtl":
      return "cbmis-dir-rtl";
    case "ltr":
      return "cbmis-dir-ltr";
    default:
      return "cbmis-dir-ltr";
  }
};

export function blnIsDateBiggerThanDate2(date1, date) {
  const _theDate1 = new Date(date1);
  const _theDate2 = new Date(date);
  return _theDate1 < _theDate2;
}

export const checkColorReturnValue = (color) => {
  const getColor = String(color).toLowerCase();
  switch (getColor) {
    case "primary":
      return "var(--cbmis-primary)";
    case "secondary":
      return "var(--cbmis-secondary)";
    case "success":
      return "var(--cbmis-success)";
    case "info":
      return "var(--cbmis-info)";
    case "warning":
      return "var(--cbmis-warning)";
    case "error":
      return "var(--cbmis-error)";
    case "light":
      return "var(--cbmis-light)";
    case "dark":
      return "var(--cbmis-dark)";
    default:
      return getColor;
  }
};

export const getbgColorReturnClassName = (itemClassName, color) => {
  const getColor = checkColorReturnValue(String(color).toLowerCase());
  switch (getColor) {
    case "var(--cbmis-primary)":
      changeItemBgColor(itemClassName, "var(--cbmis-primary)");
      changeItemBorderColor(itemClassName, "var(--cbmis-primary)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    case "var(--cbmis-secondary)":
      changeItemBgColor(itemClassName, "var(--cbmis-secondary)");
      changeItemBorderColor(itemClassName, "var(--cbmis-secondary)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    case "var(--cbmis-success)":
      changeItemBgColor(itemClassName, "var(--cbmis-success)");
      changeItemBorderColor(itemClassName, "var(--cbmis-success)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    case "var(--cbmis-info)":
      changeItemBgColor(itemClassName, "var(--cbmis-info)");
      changeItemBorderColor(itemClassName, "var(--cbmis-info)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    case "var(--cbmis-warning)":
      changeItemBgColor(itemClassName, "var(--cbmis-warning)");
      changeItemBorderColor(itemClassName, "var(--cbmis-warning)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    case "var(--cbmis-error)":
      changeItemBgColor(itemClassName, "var(--cbmis-error)");
      changeItemBorderColor(itemClassName, "var(--cbmis-erroe)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    case "var(--cbmis-light)":
      changeItemBgColor(itemClassName, "var(--cbmis-light)");
      changeItemBorderColor(itemClassName, "var(--cbmis-light)");
      changeItemColor(itemClassName, "var(--cbmis-gray)");
      break;
    case "var(--cbmis-dark)":
      changeItemBgColor(itemClassName, "var(--cbmis-dark)");
      changeItemBorderColor(itemClassName, "var(--cbmis-dark)");
      changeItemColor(itemClassName, "var(--cbmis-white)");
      break;
    default:
      changeItemBgColor(itemClassName, getColor);
      break;
  }
};

export const getOutlinedStyleColorReturnClassName = (itemClassName, color) => {
  const getColor = checkColorReturnValue(String(color).toLowerCase());
  switch (getColor) {
    case "var(--cbmis-primary)":
      changeItemBorderColor(itemClassName, "var(--cbmis-primary)");
      changeItemColor(itemClassName, "var(--cbmis-primary)");
      break;
    case "var(--cbmis-secondary)":
      changeItemBorderColor(itemClassName, "var(--cbmis-secondary)");
      changeItemColor(itemClassName, "var(--cbmis-secondary)");
      break;
    case "var(--cbmis-success)":
      changeItemBorderColor(itemClassName, "var(--cbmis-success)");
      changeItemColor(itemClassName, "var(--cbmis-success)");
      break;
    case "var(--cbmis-info)":
      changeItemBorderColor(itemClassName, "var(--cbmis-info)");
      changeItemColor(itemClassName, "var(--cbmis-info)");
      break;
    case "var(--cbmis-warning)":
      changeItemBorderColor(itemClassName, "var(--cbmis-warning)");
      changeItemColor(itemClassName, "var(--cbmis-warning)");
      break;
    case "var(--cbmis-error)":
      changeItemBorderColor(itemClassName, "var(--cbmis-error)");
      changeItemColor(itemClassName, "var(--cbmis-error)");
      break;
    case "var(--cbmis-light)":
      changeItemBorderColor(itemClassName, "var(--cbmis-light)");
      changeItemColor(itemClassName, "var(--cbmis-light)");
      break;
    case "var(--cbmis-dark)":
      changeItemBorderColor(itemClassName, "var(--cbmis-dark)");
      changeItemColor(itemClassName, "var(--cbmis-dark)");
      break;
    default:
      changeItemBorderColor(itemClassName, getColor);
      changeItemColor(itemClassName, getColor);
      break;
  }
};

export const getItemSizeReturnValue = (itemSize) => {
  const getSize = String(itemSize).toLowerCase();
  switch (getSize) {
    case "xs":
      return "var(--cbmis-width-item-xs)";
    case "sm":
      return "var(--cbmis-width-item-sm)";
    case "md":
      return "var(--cbmis-width-item-md)";
    case "lg":
      return "var(--cbmis-width-item-lg)";
    case "xl":
      return "var(--cbmis-width-item-xl)";
    case "fluid":
      return "var(--cbmis-width-item-fluid)";
    case "half":
      return "var(--cbmis-width-item-half)";
    default:
      return "var(--cbmis-width-item-md)";
  }
};

export const getFontSizeReturnValue = (FontSize) => {
  const getSize = String(FontSize).toLowerCase();
  switch (getSize) {
    case "xs":
      return "var(--cbmis-font-size-xs)";
    case "sm":
      return "var(--cbmis-font-size-sm)";
    case "md":
      return "var(--cbmis-font-size-md)";
    case "lg":
      return "var(--cbmis-font-size-lg)";
    case "xl":
      return "var(--cbmis-font-size-xl)";
    default:
      return "var(--cbmis-width-item-sm)";
  }
};

export const getShapeReturnClassName = (shape) => {
  const theShape = String(shape).toLowerCase();

  switch (theShape) {
    case "circle":
      return "cbmis-shape-circle";
    case "oval":
      return "cbmis-shape-oval";
    case "square":
      return "cbmis-shape-square";
    case "rectangle":
      return "cbmis-shape-rectangle";
    case "pentagon":
      return "cbmis-shape-pentagon";
    case "hexagon":
      return "cbmis-shape-hexagon";
    case "heptagon":
      return "cbmis-shape-heptagon";
    case "octagon":
      return "cbmis-shape-octagon";
    case "star":
      return "cbmis-shape-star";
    case "topchevron":
      return "cbmis-shape-top-chevron";
    case "leftchevron":
      return "cbmis-shape-left-chevron";
    case "bottomchevron":
      return "cbmis-shape-bottom-chevron";
    case "rightchevron":
      return "cbmis-shape-right-chevron";
    default:
      return "cbmis-shape-circle";
  }
};

export const changeItemColor = (itemNameClass, color) => {
  try {
    if (color) {
      const getColor = checkColorReturnValue(String(color).toLowerCase());
      document.querySelector(itemNameClass).style.color = getColor;
    }
  } catch {}
};

export const changeCSSproperty = (itemNameClass, propertyName, propertyValue) => {
  if (propertyName) {
    // document.querySelector(itemNameClass).style?.[propertyName]= propertyValue
  }
};

export const changeItemBgColor = (itemNameClass, color) => {
  try {
    if (color) {
      const getColor = checkColorReturnValue(String(color).toLowerCase());
      document.querySelector(itemNameClass).style.backgroundColor = getColor;
    }
  } catch {}
};

export const changeItemBorderColor = (itemNameClass, color) => {
  try {
    if (color) {
      const getColor = checkColorReturnValue(String(color).toLowerCase());
      document.querySelector(itemNameClass).style.borderColor = getColor;
    }
  } catch {}
};

/*******************************************margin ***********************************************/
export const changeItemMarginClassName = (margin) => {
  const getArrMargin = String(margin).toLowerCase().split("-");
  if (getArrMargin.length === 2) {
    switch (getArrMargin[0]) {
      case "m":
        return `cbmis-margin-${getArrMargin[1]}`;
      case "mt":
        return `cbmis-margin-top-${getArrMargin[1]}`;
      case "ml":
        return `cbmis-margin-left-${getArrMargin[1]}`;
      case "mb":
        return `cbmis-margin-bottom-${getArrMargin[1]}`;
      case "mr":
        return `cbmis-margin-right-${getArrMargin[1]}`;
      case "my":
        return `cbmis-margin-y-${getArrMargin[1]}`;
      case "mx":
        return `cbmis-margin-x-${getArrMargin[1]}`;
      default:
        break;
    }
  } else if (getArrMargin.length === 3) {
    const screenWidth = window.innerWidth;
    var marginClassName = "";

    switch (getArrMargin[0]) {
      case "m":
        marginClassName = `cbmis-margin-${getArrMargin[2]}`;
        break;
      case "mt":
        marginClassName = `cbmis-margin-top-${getArrMargin[2]}`;
        break;
      case "ml":
        marginClassName = `cbmis-margin-left-${getArrMargin[2]}`;
        break;
      case "mb":
        marginClassName = `cbmis-margin-bottom-${getArrMargin[2]}`;
        break;
      case "mr":
        marginClassName = `cbmis-margin-right-${getArrMargin[2]}`;
        break;
      case "my":
        marginClassName = `cbmis-margin-y-${getArrMargin[2]}`;
        break;
      case "mx":
        marginClassName = `cbmis-margin-x-${getArrMargin[2]}`;
        break;
      default:
        break;
    }
    if (getArrMargin[1] === "xs" && screenWidth < 576) {
      return marginClassName;
    } else if (getArrMargin[1] === "sm" && screenWidth >= 576 && screenWidth < 768) {
      return marginClassName;
    } else if (getArrMargin[1] === "md" && screenWidth >= 768 && screenWidth < 992) {
      return marginClassName;
    } else if (getArrMargin[1] === "lg" && screenWidth >= 992 && screenWidth < 1200) {
      return marginClassName;
    } else if (getArrMargin[1] === "xl" && screenWidth >= 1200) {
      return marginClassName;
    }
  }
};

export const checkExistsMargin = (obj) => {
  var marginClassesName = "";
  const margin = Object.keys(obj).filter((item) => item.includes("m-"));
  const marginTop = Object.keys(obj).filter((item) => item.includes("mt-"));
  const marginLeft = Object.keys(obj).filter((item) => item.includes("ml-"));
  const marginBottom = Object.keys(obj).filter((item) => item.includes("mb-"));
  const marginRight = Object.keys(obj).filter((item) => item.includes("mr-"));
  const marginY = Object.keys(obj).filter((item) => item.includes("my-"));
  const marginX = Object.keys(obj).filter((item) => item.includes("mx-"));

  for (let index = 0; index < margin.length; index++) {
    marginClassesName += changeItemMarginClassName(margin[index]) + " ";
  }
  for (let index = 0; index < marginTop.length; index++) {
    marginClassesName += changeItemMarginClassName(marginTop[index]) + " ";
  }
  for (let index = 0; index < marginLeft.length; index++) {
    marginClassesName += changeItemMarginClassName(marginLeft[index]) + " ";
  }
  for (let index = 0; index < marginBottom.length; index++) {
    marginClassesName += changeItemMarginClassName(marginBottom[index]) + " ";
  }
  for (let index = 0; index < marginRight.length; index++) {
    marginClassesName += changeItemMarginClassName(marginRight[index]) + " ";
  }
  for (let index = 0; index < marginBottom.length; index++) {
    marginClassesName += changeItemMarginClassName(marginBottom[index]) + " ";
  }
  for (let index = 0; index < marginRight.length; index++) {
    marginClassesName += changeItemMarginClassName(marginRight[index]) + " ";
  }
  for (let index = 0; index < marginY.length; index++) {
    marginClassesName += changeItemMarginClassName(marginY[index]) + " ";
  }
  for (let index = 0; index < marginX.length; index++) {
    marginClassesName += changeItemMarginClassName(marginX[index]) + " ";
  }
  return marginClassesName;
};

/*******************************************padding ***********************************************/
export const changeItemPaddingClassName = (padding) => {
  const getArrPadding = String(padding).toLowerCase().split("-");
  if (getArrPadding.length === 2) {
    switch (getArrPadding[0]) {
      case "p":
        return `cbmis-padding-${getArrPadding[1]}`;
      case "pt":
        return `cbmis-padding-top-${getArrPadding[1]}`;
      case "pl":
        return `cbmis-padding-left-${getArrPadding[1]}`;
      case "pb":
        return `cbmis-padding-bottom-${getArrPadding[1]}`;
      case "pr":
        return `cbmis-padding-right-${getArrPadding[1]}`;
      case "py":
        return `cbmis-padding-y-${getArrPadding[1]}`;
      case "px":
        return `cbmis-padding-x-${getArrPadding[1]}`;
      default:
        break;
    }
  } else if (getArrPadding.length === 3) {
    const screenWidth = window.innerWidth;
    var paddingClassName = "";

    switch (getArrPadding[0]) {
      case "p":
        paddingClassName = `cbmis-padding-${getArrPadding[2]}`;
        break;
      case "pt":
        paddingClassName = `cbmis-padding-top-${getArrPadding[2]}`;
        break;
      case "pl":
        paddingClassName = `cbmis-padding-left-${getArrPadding[2]}`;
        break;
      case "pb":
        paddingClassName = `cbmis-padding-bottom-${getArrPadding[2]}`;
        break;
      case "pr":
        paddingClassName = `cbmis-padding-right-${getArrPadding[2]}`;
        break;
      case "py":
        paddingClassName = `cbmis-padding-y-${getArrPadding[2]}`;
        break;
      case "px":
        paddingClassName = `cbmis-padding-x-${getArrPadding[2]}`;
        break;
      default:
        break;
    }
    if (getArrPadding[1] === "xs" && screenWidth < 576) {
      return paddingClassName;
    } else if (getArrPadding[1] === "sm" && screenWidth >= 576 && screenWidth < 768) {
      return paddingClassName;
    } else if (getArrPadding[1] === "md" && screenWidth >= 768 && screenWidth < 992) {
      return paddingClassName;
    } else if (getArrPadding[1] === "lg" && screenWidth >= 992 && screenWidth < 1200) {
      return paddingClassName;
    } else if (getArrPadding[1] === "xl" && screenWidth >= 1200) {
      return paddingClassName;
    }
  }
};

export const checkExistsPadding = (obj) => {
  var paddingClassesName = "";
  const padding = Object.keys(obj).filter((item) => item.includes("p-"));
  const paddingTop = Object.keys(obj).filter((item) => item.includes("pt-"));
  const paddingLeft = Object.keys(obj).filter((item) => item.includes("pl-"));
  const paddingBottom = Object.keys(obj).filter((item) => item.includes("pb-"));
  const paddingRight = Object.keys(obj).filter((item) => item.includes("pr-"));
  const paddingY = Object.keys(obj).filter((item) => item.includes("py-"));
  const paddingX = Object.keys(obj).filter((item) => item.includes("px-"));

  for (let index = 0; index < padding.length; index++) {
    paddingClassesName += changeItemPaddingClassName(padding[index]) + " ";
  }
  for (let index = 0; index < paddingTop.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingTop[index]) + " ";
  }
  for (let index = 0; index < paddingLeft.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingLeft[index]) + " ";
  }
  for (let index = 0; index < paddingBottom.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingBottom[index]) + " ";
  }
  for (let index = 0; index < paddingRight.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingRight[index]) + " ";
  }
  for (let index = 0; index < paddingY.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingY[index]) + " ";
  }
  for (let index = 0; index < paddingX.length; index++) {
    paddingClassesName += changeItemPaddingClassName(paddingX[index]) + " ";
  }
  return paddingClassesName;
};

export const filterPaddingAndMargin = (obj) => {
  const rest = Object.entries(obj).filter(
    ([key, value]) =>
      !key.includes("p-") &&
      !key.includes("pt-") &&
      !key.includes("pl-") &&
      !key.includes("pb-") &&
      !key.includes("pr-") &&
      !key.includes("px-") &&
      !key.includes("py-") &&
      !key.includes("m-") &&
      !key.includes("mt-") &&
      !key.includes("ml-") &&
      !key.includes("mb-") &&
      !key.includes("mr-") &&
      !key.includes("mx-") &&
      !key.includes("my-")
  );
  return Object.fromEntries(rest);
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
export function hasClass(el, className) {
  try {
    if (el.classList) {
      return el.classList.contains(className);
    }
    return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
  } catch {
    return false;
  }
}

export function addClass(el, className) {
  try {
    if (!hasClass(el, className)) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += " " + className;
      }
    }
  } catch {}
}

export function removeClass(el, className) {
  try {
    if (el.classList) {
      el.classList.remove(className);
    } else if (hasClass(el, className)) {
      var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
      el.className = el.className.replace(reg, " ");
    }
  } catch {}
}

export const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getSXUniqueClassName = (classNameWithoutDot) => {
  const randomID = "_" + generateRandomString(6);
  return classNameWithoutDot + randomID;
};

export const getUniqueClassName = (classNameWithDot) => {
  const randomID = "_" + generateRandomString(6); //(Math.random() + 1).toString(36).substring(4); //Math.random().toString(36).substr(2, 9);
  // const element = document.querySelector(classNameWithDot)
  // const css = window.getComputedStyle(element)
  //var style = document.createElement("style");
  //style.type = "text/css";
  // style.innerHTML = `${classNameWithDot+randomID}{${css}}`
  return classNameWithDot + randomID;
};

export const getStyle = (sm_cssStyleObject, sx_cssStyleObject) => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 768 && sm_cssStyleObject) {
    return sm_cssStyleObject;
  } else if (sx_cssStyleObject) {
    return sx_cssStyleObject;
  }
  return {};
};

/*********************************************************************************************/
export const CheckDisabledItem = (disabled) => {
  if (disabled) {
    return "cbmis-disabled";
  } else {
    return "";
  }
};

export const CheckHiddenItem = (hidden) => {
  if (hidden) {
    return "cbmis-invisible-hidden";
  } else {
    return "";
  }
};

export const CheckDirectionItem = (dir) => {
  if (dir) {
    return `cbmis-dir-${dir}`;
  } else {
    return "";
  }
};

/*************************** formapi****************************** */

export function checkFileName(objFile, alternativeName) {
  let fileName = String(objFile.name);
  const isContainNoneLatinsChar = /[^\u0020-\u007E]/.test(fileName);
  if (isContainNoneLatinsChar === true) {
    let lstSplitString = fileName.split(".");
    let strFileExtension = lstSplitString[lstSplitString.length - 1];
    let newFileName = alternativeName + "." + strFileExtension;
    return new File([objFile], newFileName, objFile);
  } else {
    return objFile;
  }
}

export const filterFormStateFromProps = (obj) => {
  const rest = Object.entries(obj).filter(([key, value]) => !key.toLowerCase().includes("formState") && !key.toLowerCase().includes("setFormState"));
  return Object.fromEntries(rest);
};

export const randomName = (componentName) => {
  return `${componentName}_${generateRandomString(10)}`;
};

export const scrollToElement = async (path) => {
  var offSet = 0;
  var scrollType = "smooth";
  const tesNode = document.querySelector(path);
  const scrollOptions = {
    left: 0,
    top: tesNode.offsetTop + offSet,
    behavior: scrollType,
  };
  if (true) {
    window.scrollTo(scrollOptions);
  }
};

export function checkParseObject(object) {
  try {
    if (object === undefined || object === null) {
      throw new Error("Unsupported data-type");
    }
    if (typeof object === "string") {
      return JSON.parse(object);
    } else if (typeof object === "object") {
      return object;
    } else {
      throw new Error("Unsupported data-type");
    }
  } catch (error) {
    return {};
  }
}

export function WordCount(str) {
  return str.split(" ").length;
}


export function replaceClass(el, oldClassName, newClassName) {
  try {
    if (el.classList) {
      el.classList.replace(oldClassName, newClassName);
    } else if (hasClass(el, oldClassName)) {
      let oldReg = new RegExp("(\\s|^)" + oldClassName + "(\\s|$)");
      el.className = el.className.replace(oldReg, newClassName + " ");
    }
  } catch {}
}


export const getFormOptionFromObject = (obj, objLang, lang) => {
  let options = [];
  if (!Array.isArray(obj)) {
    const arrObjec = Object.entries(checkParseObject(obj));
    if (Array.isArray(arrObjec)) {
      options = arrObjec.map((item) => {
        return { key: item[1], value: objLang?.[item[0]]?.[lang] || item[0] };
      });
    }
  } else {
    options = obj.map((item) => {
      return { key: item?.key, value: objLang?.[item?.value]?.[lang] || item?.value };
    });
  }
  return options;
};

export const getFormDefaultValue = (list, key, value) => {
  if (key && value) {
    return list.filter((item) => String(item.key) === String(key) && String(item.value) === String(value));
  } else if (Array.isArray(key)) {
    return list.filter((item) => key.includes(String(item.key)));
  } else if (key) {
    return list.filter((item) => String(item.key) === String(key));
  } else if (value) {
    return list.filter((item) => String(item.value) === String(value));
  } else {
    return "";
  }
};


