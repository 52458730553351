import { Grid, Paper, Typography } from "@cbmisorg/client-app";
import React from "react";
import { appStyle } from "../../../../../appHelper/appStyle";
import { FormField } from "../../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { objVision2035 } from "../../../../../../client/configuration/appVariables";

const labels = dictionary?.components?.manageSystem;

function Vision2035Config({ fields, appState }) {
  const lang = appState?.clientInfo?.strLanguage;

  return (
    <React.Fragment>
      <Grid container justify={"center"} spacing={3}>
        <Grid item xs="12">
          <Paper className="cardContainer">
            <Grid container spacing={2} py-0>
              <Grid item xs="12">
                <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                  {labels?.Vision2035Config?.[lang]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs="12">
                <FormField objHandler={fields?.blnBtnVision} />
              </Grid>
            </Grid>
            {!fields?.blnBtnVision?.getValue()?.length ? null : (
              <React.Fragment>
                <Grid container>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strBtnTransition} />
                  </Grid>
                  {
                    fields?.strBtnTransition?.getValue()?.key !== objVision2035?.tms?null:(
                      <Grid item xs="12">
                      <FormField objHandler={fields?.strTmsLink} />
                    </Grid>
                    )
                  }
                </Grid>
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Vision2035Config;
