import { Button, Container, Grid, Loader, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import React, { useEffect, useState } from "react";
import { useCheckUserLoginDevice } from "../../../appHelper/appHook/useCheckUserLoginDevice ";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { clearAppLocalStorage, getNextStrURL } from "../../../appHelper/appFunctions";
import objAppRouting from "../../../appHelper/routing/appRouting";
import RequirementInfo from "../../sharedUi/requirementInfo/RequirementInfo";
import Uploader from "../../sharedUi/uploader/Uploader";
import { objUploadType } from "../../../appHelper/appVariables";
import useCheckSystemInfo from "../../../appHelper/appHook/useCheckSystemInfo";
import { CtrlRequirementInfo } from "./controller/CtrlRequirementInfo";
import { App_Primary_Color } from "../../../appHelper/appColor";
import { useApp } from "../../../../client/configuration/contextapi/context";

function RouteRequirementInfo() {
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  useCheckSystemInfo();
  useCheckUserLoginDevice();
  const lang = appState?.clientInfo?.strLanguage;
  const objSystemInfo = appState?.clientInfo?.objSystemInfo;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    listFileGovID: [],
    listFileRestID: [],
  });
  const uploaderGovID = Uploader(
    state,
    setState,
    "listFileGovID",
    dictionary?.components?.manageSystem?.govID,
    { blnIsMultiple: false, blnForceNewName: true, fileType: objUploadType?.aaiot?.fileType },
    "Badge"
  );
  const uploaderRest = Uploader(
    state,
    setState,
    "listFileRestID",
    dictionary?.components?.manageSystem?.rest,
    { blnIsMultiple: false, blnForceNewName: true, fileType: objUploadType?.aaiot?.fileType },
    "Description"
  );

  const handlers = CtrlRequirementInfo(appState, appDispatch, isLoading, setIsLoading, navigate, state, setState);

  function handleSubmit() {
    handlers.updateRequested(uploaderGovID, uploaderRest);
  }
  useEffect(() => {
    const accountUrl = getNextStrURL();
    if (accountUrl !== objAppRouting?.RequirementInfo?.url) {
      navigate(accountUrl, { replace: true });
    }
  }, []);

  const funLogout = () => {
    clearAppLocalStorage(appState);
    navigate(objAppRouting?.Public_LandingPage?.url, { replace: true });
  };
  return (
    <React.Fragment>
      <ShredHeader title={dictionary?.components?.manageSystem?.requirementTitle?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-10 sx={{ overflowY: "none" }}>
        {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
        <Grid container justify={"center"} spacing={2}>
          <Grid item xs="12" md="8">
            <Paper className="cardContainer" pb-1 px-2>
              <Grid container spacing={3}>
                <RequirementInfo userInfo={appState?.userInfo} objSystemInfo={objSystemInfo} lang={lang} uploaderGovID={uploaderGovID} uploaderRest={uploaderRest} />
              </Grid>
              <Grid container justify={"center"}>
                <Grid item xs="12" pt-10 pb-0>
                  <Button label={<Typography as="subtitle2">{dictionary?.shared?.btn?.save?.[lang]}</Typography>} py-0 style={{ minWidth: "100%" }} onClick={handleSubmit} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container justify={"center"} py-0>
          <Grid item xs="12" md="8">
            <Grid container justify={"end"} py-0>
              <Grid item>
                <Button size="sm" mode="link" color="error" label={dictionary?.components?.account?.logout?.[lang]} icon="logout" onClick={funLogout} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default RouteRequirementInfo;
