import React, { useEffect, useRef } from "react";
import "./Container.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const Container = (props) => {
  const { width, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("Container"));
  const styleRef = useRef({ strSxClassName: "" });

  if(props?.[strTestProps]){
    if(props?.width){
      console.log("container", "width");
    }
    if(props?.sx){
      console.log("container", "sx",);
    }
  }
  useEffect(() => {}, [width, sx, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-container");
    }
  }, [sx, screenWidth]);

  const getWidth = () => {
    switch (String(width).toLowerCase()) {
      case "xl":
        return "cbmis-width-xl";
      case "lg":
        return "cbmis-width-lg";
      case "md":
        return "cbmis-width-md";
      case "sm":
        return "cbmis-width-sm";
      case "fluid":
        return "cbmis-width-fluid";
      case "half":
        return "cbmis-width-half";
      default:
        return "";
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <React.Suspense fallback="">
      <div
        id={getID.current}
        className={`cbmis-container ${getWidth()} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
      >
        {props.children}
      </div>
    </React.Suspense>
  );
};

export default Container;
