import React, { useEffect, useRef } from "react";
import "./ParallaxScrolling.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function ParallaxScrolling(props) {
  const { src, id, sx, hidden, disabled, dir, className, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Link"));
  const styleRefContainer = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-ParallaxScrolling-container") });
  const styleRefImg = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [src, id, hidden, disabled, dir, className]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRefContainer, styleRefContainer.current.strSXComponentID, sx, "cbmis-ParallaxScrolling-container");
      setSxClassName(styleRefImg, getID.current, sx, "cbmis-ParallaxScrolling-image");
    }
  }, [sx, screenWidth]);

  return (
    <div id={styleRefContainer.current.strSXComponentID} className={`cbmis-ParallaxScrolling-container`} {...getRestProps()}>
      <div className={`cbmis-ParallaxScrolling-image ${className}`} id={getID.current} style={{ ...componentsFunction.getStyle(sx), backgroundImage: `url(${src})` }}>
        <div className="cbmis-parallax-inner">{props.children}</div>
      </div>
    </div>
  );
}

export default ParallaxScrolling;
