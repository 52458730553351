import { useEffect, useState } from "react";
import usePermissions from "../CbmisPermissions/usePermissions"
import * as Alert from "../../material/feedback/Alert/Alert"
// import * as Confirmation from "../../material/feedback/Confirmation/Confirmation"


const useRecorder = (appPermissionState) => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const permissions = usePermissions(appPermissionState)

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
      if (recorder === null) {
        if (isRecording) {
          requestRecorder().then(setRecorder, console.error);
        }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = e => {
      setAudioURL(URL.createObjectURL(e.data));
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {
    if(appPermissionState){
      permissions.checkPermission("microphonePermission")
      if(appPermissionState.microphonePermission.user && appPermissionState.microphonePermission.user){
        setIsRecording(true);
      }else{
        Alert.viewAlert("Please go to the permission page and turn on the microphone")
      }
    }else{
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const deleteRecording = () => {
    // if(audioURL){
      // Confirmation("Are you sure to delete this record?",()=>{
      setAudioURL("")
      // })
    // }

    setAudioURL("")

  };

  
  return {audioURL, isRecording, startRecording, stopRecording, deleteRecording};
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}


export default useRecorder;
