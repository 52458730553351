import React from 'react'
import MngSupportTransView from './mngSupportTrans.view'

export default function mngSupportTrans() {
  return (
    <>
    <MngSupportTransView/>
    </>
  )
}
