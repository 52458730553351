const objAppRouting = {
  Public_LandingPage: { url: "/", featureID: 8786507229 },
  Public_Team: { url: "/team", featureID: 8786507229 },

  Public_certificate: { url: "/certificate", featureID: 8786507229 },


  Public_Event: { url: "/events/:id", featureID: 8786507229 },

  Public_Events: { url: "/events", featureID: 8786507229 },
  Public_Initiative: { url: "/training/tech/:strTrainingType", featureID: 8786507229 },
  public_Training_Vedio: { url: "/video/:strTrainingType", featureID:8786507229,},
  public_Vedioes: { url: "/videoes/:strUrlVedio", featureID:8786507229,},

  Public_Support: { url: "/support/:trainingType", featureID: 8886372647 },

  Public_Settings: { url: "/settings", featureID: 8786507229 },


  Public_Login: { url: "/login", featureID: 2580267594 },
  Public_RestPassword: { url: "/restpassword", featureID: 1446339286 },

  Public_SheikMessage: { url: "/sheikmessage", featureID: 0 },

  // Public_Activity: { url: "/:activityCode", featureID: 8786507229, featureAuth: false },


  Account: { url: "/account", featureID: 6759118668 , featureAuth: true},
  profile: { url: "/profile", featureID: 3195608470 , featureAuth: false},
  SponsorProfile: { url: "/supportinfo", featureID: 6759118668 , featureAuth: false},

  ManageActivates: { url: "/mngactivates", featureID: 3578590965 , featureAuth: true},
  ManageActivates_Archaic: { url: "/archaic", featureID: 3578590965 , featureAuth: true},
  SaveActivates: { url: "/saveactivates", featureID: 3578590965 , featureAuth: true},

  Contact: { url: "/contact", featureID: 8465294220 , featureAuth: true},
  viewEmail: { url: "/contact/viewemail", featureID: 8465294220 , featureAuth: true},
  Subscription: { url: "/subscription", featureID: 5638147424 , featureAuth: true},
  Partner: { url: "/partners", featureID: 0 , featureAuth: true},
  Manage_RequestActive: { url: "/mngrequestactive", featureID: 7004479076 , featureAuth: true},
  manageMembers: { url: "/managemembers", featureID: 7250067630 , featureAuth: true},
  viewMember: { url: "/viewmember", featureID: 7250067630 , featureAuth: true},
  View_RequestJoin: { url: "/viewrequestedjoin", featureID: 7004479076 , featureAuth: true},
  View_RequestActive: { url: "/viewrequestedaactive", featureID: 7004479076 , featureAuth: true},



  ManageSystem: { url: "/mngsystem", featureID: 5053121509 , featureAuth: true},
  AdvertisingVideoManagement: { url: "/advertisingVideoManagement", featureID: 5053121509 , featureAuth: true},
  ManageCategories: { url: "/mngcategories", featureID: 9119639410 , featureAuth: true},
  AttendeeManagement: { url: "/attendeeManagement", featureID: 6300172818 , featureAuth: true},


  ManageStatistic: { url: "/statistic", featureID: 7005632818 , featureAuth: true},

  Waiting: { url: "/waiting", featureID: 6759118668 , featureAuth: true},
  WaitingTraining: { url: "/waitingsymposium"},
  symposiumCard: { url: "/waitingSymposium"},
  RequirementInfo: { url: "/requirementinfo", featureID: 6769221882 , featureAuth: true},

  CardMember: { url: "/cardMember", featureID:4904282639},

  Join_Symposium: { url: "/symposium/ai", featureID:9788338218},
  Join_Trainee: { url: "/student", featureID:5094561645},
  Join_Startup: { url: "/startup", featureID:5094561645},
  Join_Manager: { url: "/managers", featureID:1453065237},
  Public_Join_Partners: { url: "/sponsor/:trainingType", featureID: 8414014146 },
  Public_JoinRequest: { url: "/membership", featureID: 6769221882 },
  Public_reg: { url: "/registration"},

  Mng_Join_Symposium: { url: "/mngreqtraining", featureID:5087107817},
  View_Join_Symposium: { url: "/viewreqtraining", featureID:5087107817},

  MngAnnouncement: { url: "/mngannouncement", featureID: 1870026289, featureAuth: true },
  MngMeeting: { url: "/mngmeeting", featureID: 1870026289, featureAuth: true },

  Manage_Training: { url: "/mngtraining/:strTrainingType", featureID: 6759118668 , featureAuth: true},
  manage_Taining_info: { url: "/mangtraiee/:strTrainingType", featureID: 7004479076 , featureAuth: true},
  Manage_Partner: { url: "/mngPartner", featureID: 7005632818 , featureAuth: true},
  Manage_Partner_Support_Trans: { url: "/mngsupporttransaction/:strTrainingType", featureID: 7005632818, featureAuth: true },
  Mng_Potential_Partner: { url: "/mngpotentialpartner/:strTrainingType", featureID: 2878712119, featureAuth: true },

  Mng_Suggested_Sponsor: { url: "/mngsuggestedsponsor", featureID:2878712119, featureAuth: true},
  Mng_Suggested_SponsorwithParm: { url: "/mngsuggestedsponsor/:strTrainingType", featureID:2878712119, featureAuth: true},

  Mng_Registered_Activities: { url: "/registered_in_activities", featureID:0, featureAuth: false},

};

export default objAppRouting;
