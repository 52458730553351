import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../../../../util/appFunctions";
import * as appVariables from "../../../../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import appRouting from "../../../../../../configuration/routing/appRouting";
import { alertStyle } from "../../../../../../util/appStyle";

const strPageInfo = "@src/client/component/public/trainingRegistration/trainingRegistration.ctrl.js";
const tblUser = generateQueries("tblUser");

export const CtrlTraineeSetterModal = ({ appState, appDispatch }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, setState, state, fields, objUser }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);


          //#endregion set options
          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
          let lstCountryOption = [];
          // let lstUniversityOption = [];
          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGenderOption = [];
          let lstTypeGovSector = [];

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.nationality?.value });
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value], index) => {
            if (key !== "id" && key !== "value" && index < 6) {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.typeGovSector || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstTypeGovSector.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigCountryOfCitizenID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigAcademicYearID.setOptions([...lstAcademicYearOption]);
          fields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);
          //#endregion


          

          //#region setValues
          if (objUser?.jsnUserInfo?.strFullName) {
            if (typeof objUser?.jsnUserInfo?.strFullName === "object" && objUser?.jsnUserInfo?.strFullName?.[lang]) {
              fields.strFullName.setValue(objUser?.jsnUserInfo?.strFullName?.[lang]);
            } else if(typeof objUser?.jsnUserInfo?.strFullName === "string"){
              fields.strFullName.setValue(objUser?.jsnUserInfo?.strFullName);
            }
          }
          if (objUser?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(objUser?.jsnUserInfo?.strMajor);
          }
          if (objUser?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(objUser?.jsnUserInfo?.bigGender);
          }
          if (objUser?.jsnUserInfo?.bigWorkSector) {
            fields.bigWorkSector2.setValue(objUser?.jsnUserInfo?.bigWorkSector);
          }
          if (objUser?.strUserEmail) {
            fields.strUserEmail.setValue(objUser?.strUserEmail);
          }
          if (objUser?.strUserPhone) {
            fields.strUserPhone.setValue(objUser?.strUserPhone);
          }
          if (objUser?.jsnUserInfo?.bigWorkGovSectorType) {
            fields.bigWorkGovSectorType.setValue(objUser?.jsnUserInfo?.bigWorkGovSectorType);
            handler.onChangeWorkGovSectorType(fields)();
          }
          if (objUser?.jsnUserInfo?.bigGovPlaceName) {
            fields.bigGovPlaceName.setValue(objUser?.jsnUserInfo?.bigGovPlaceName);
          }
          if (objUser?.jsnUserInfo?.bigCountryOfCitizenID) {
            if (String(lstCountryOption?.[0]?.key) === String(objUser?.jsnUserInfo?.bigCountryOfCitizenID)) {
              fields.bigCountryOfCitizenID.setValue(objUser?.jsnUserInfo?.bigCountryOfCitizenID);
            } else {
              fields.bigCountryOfCitizenID.setValue("other");
            }
          }
          if (objUser?.jsnUserInfo?.strPositionTitle) {
            fields.strPositionTitle.setValue(objUser?.jsnUserInfo?.strPositionTitle);
          }
          if (objUser?.jsnUserInfo?.bigMajorID) {
            fields.bigMajorID.setValue(objUser?.jsnUserInfo?.bigMajorID);
          }
          // if (objUser?.jsnUserInfo?.bigUniversityName) {
          //   fields.bigUniversityName.setValue(objUser?.jsnUserInfo?.bigUniversityName);
          // }
          if (objUser?.jsnUserInfo?.strUniversityName) {
            fields.strUniversityName.setValue(objUser?.jsnUserInfo?.strUniversityName);
          }
          if (objUser?.jsnUserInfo?.bigAcademicYearID) {
            fields.bigAcademicYearID.setValue(objUser?.jsnUserInfo?.bigAcademicYearID);
          }
          if (objUser?.jsnUserInfo?.strYearGraduation) {
            fields.strYearGraduation.setValue(objUser?.jsnUserInfo?.strYearGraduation);
          }
          if (objUser?.jsnUserInfo?.strPlaceName) {
            fields.strPlaceName.setValue(objUser?.jsnUserInfo?.strPlaceName);
          }
          if (objUser?.jsnUserInfo?.intExperienceYear) {
            fields.intExperienceYear.setValue(objUser?.jsnUserInfo?.intExperienceYear);
          }
          //#endregion

          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    // getUserObj: ({ fields, interviewfields, state }) => {
    //   const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
    //   let bigID = appFunctions.generateID(10);
    //   let countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[countryCode]];
    //   const countryInfoObj = {};
    //   if (fields?.bigCountryOfCitizenID?.getValue()?.key) {
    //     const countryID = fields?.bigCountryOfCitizenID?.getValue()?.key;
    //     const getCountryKey = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
    //     state.countryCode = objCat?.Root?.country?.[getCountryKey]?.code?.value?.["eng"];
    //     countryInfoObj.bigCountryOfResidenceID = countryID;
    //     countryInfoObj.bigCountryOfCitizenID = countryID;
    //   } else if (countryCode && countryObj) {
    //     countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
    //     countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
    //   } else if (!countryCode) {
    //     countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.strDefaultContryRegisterCode || "kw"]];
    //     countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
    //     countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
    //   }
    //   const objectInput = {
    //     ...(objUser ? objUser : {}),
    //     bigUserID: objUser?.bigUserID ? objUser?.bigUserID : bigID,
    //     bigAppID: appVariables.App_ID,
    //     bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,

    //     strUserEmail: fields?.strUserEmail?.getValue(),
    //     strUserPhone: fields?.strUserPhone?.getValue(),
    //     strTrainingTypeCode: state?.countryCode,

    //     jsnUserInfo: {
    //       ...(objUser?.jsnUserInfo ? objUser?.jsnUserInfo : {}),
    //       strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
    //       intUserRoleFK: objUser?.jsnUserInfo?.intUserRoleFK ? appState?.userInfo?.jsnUserInfo?.intUserRoleFK : appVariables?.objUserRole?.Member,
    //       bigWorkGovSectorType: fields?.bigWorkGovSectorType?.getValue()?.key || "",
    //       bigGovPlaceName: fields?.bigGovPlaceName?.getValue()?.key || "",
    //       bigWorkSector: fields?.bigWorkSector2?.getValue()?.key,
    //       strMajor: fields?.bigMajorID?.getValue()?.key,
    //       strPositionTitle: fields?.strPositionTitle?.getValue(),
    //       bigGender: fields?.bigGender?.getValue()?.key,
    //       bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
    //       strYearGraduation: fields?.strYearGraduation?.getValue(),
    //       strPlaceName: fields?.strPlaceName?.getValue(),
    //       intExperienceYear: fields?.intExperienceYear?.getValue(),
    //       bigUniversityName: fields?.bigUniversityName?.getValue()?.key,
    //       strUniversityName: fields?.strUniversityName?.getValue(),
    //       ...countryInfoObj,
    //       jsnInterviewInfo: {
    //         blnAppsNeedDev: interviewfields?.blnAppsNeedDev?.getValue() ? interviewfields?.blnAppsNeedDev?.getValue()?.key === "true" : "",
    //         strExplainProposal: interviewfields?.strExplainProposal?.getValue(),
    //         blnlikeWorkinGivSec: interviewfields?.blnlikeWorkinGivSec?.getValue() ? interviewfields?.blnlikeWorkinGivSec?.getValue()?.key === "true" : "",
    //         bigWorkGovSectorType: interviewfields?.bigWorkGovSectorType?.getValue()?.key,
    //         bigGovPlaceName: interviewfields?.bigGovPlaceName?.getValue()?.key,
    //         blnGraduationProject: interviewfields?.blnGraduationProject?.getValue() ? interviewfields?.blnGraduationProject?.getValue()?.key === "true" : "",
    //         blnHaveGoveIdea: interviewfields?.blnHaveGoveIdea?.getValue() ? interviewfields?.blnHaveGoveIdea?.getValue()?.key === "true" : "",
    //         strExplainIdea: interviewfields?.strExplainIdea?.getValue() ? interviewfields?.strExplainIdea?.getValue()?.key === "true" : "",
    //         blnExpJs: interviewfields?.blnExpJs?.getValue() ? interviewfields?.blnExpJs?.getValue()?.key === "true" : "",
    //         intYearExpJs: interviewfields?.intYearExpJs?.getValue(),
    //         intNumJsProject: interviewfields?.intNumJsProject?.getValue(),
    //         strExpJsProject: interviewfields?.strExpJsProject?.getValue(),
    //         blnExFe: interviewfields?.blnExFe?.getValue() ? interviewfields?.blnExFe?.getValue()?.key === "true" : "",
    //         intYearExpFn: interviewfields?.intYearExpFn?.getValue(),
    //         strExpFeProject: interviewfields?.strExpFeProject?.getValue(),
    //         blnExpNode: interviewfields?.blnExpNode?.getValue() ? interviewfields?.blnExpNode?.getValue()?.key === "true" : "",
    //         intYearExpNode: interviewfields?.intYearExpNode?.getValue(),
    //         intNumNodeProject: interviewfields?.intNumNodeProject?.getValue(),
    //         strExpNodeProject: interviewfields?.strExpNodeProject?.getValue(),
    //         blnExAi: interviewfields?.blnExAi?.getValue() ? interviewfields?.blnExAi?.getValue()?.key === "true" : "",
    //         intYearExpAi: interviewfields?.intYearExpAi?.getValue(),
    //         strExpAiProject: interviewfields?.strExpAiProject?.getValue(),
    //         blnExCybS: interviewfields?.blnExCybS?.getValue() ? interviewfields?.blnExCybS?.getValue()?.key === "true" : "",
    //         intYearExpCybS: interviewfields?.intYearExpCybS?.getValue(),
    //         strExpCybSProject: interviewfields?.strExpCybSProject?.getValue(),
    //         // blnExMobile: interviewfields?.blnExMobile?.getValue() ? interviewfields?.blnExMobile?.getValue()?.key === "true" : "",
    //         // intYearExpMobile: interviewfields?.intYearExpMobile?.getValue(),
    //         // strExpMobileProject: interviewfields?.strExpMobileProject?.getValue(),
    //       },
    //       dtmRegisterStudent: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
    //     },
    //   };

    //   return objectInput;
    // },
    // gOToStep2:
    //   ({ state, setState, fields, interviewfields }) =>
    //   () => {
    //     const bigWorkSectorId = fields?.bigWorkSector2?.getValue()?.key || 0;
    //     if (bigWorkSectorId) {
    //       fields?.strPositionTitle?.setValidation("required", false, false);
    //       fields?.bigWorkGovSectorType?.setValidation("required", false, false);
    //       fields?.bigGovPlaceName?.setValidation("required", false, false);
    //       fields?.intExperienceYear?.setValidation("required", false, false);
    //       fields?.bigCountryOfCitizenID?.setValidation("required", false, false);
    //       fields?.bigUniversityName?.setValidation("required", false, false);
    //       fields?.strUniversityName?.setValidation("required", false, false);
    //       fields?.bigMajorID?.setValidation("required", false, false);
    //       fields?.bigAcademicYearID?.setValidation("required", false, false);
    //       fields?.strYearGraduation?.setValidation("required", false, false);
    //       fields?.strPlaceName?.setValidation("required", false, false);

    //       fields?.strPositionTitle?.setError("");
    //       fields?.bigWorkGovSectorType?.setError("");
    //       fields?.bigGovPlaceName?.setError("");
    //       fields?.intExperienceYear?.setError("");
    //       fields?.bigCountryOfCitizenID?.setError("");
    //       fields?.bigUniversityName?.setError("");
    //       fields?.strUniversityName?.setError("");
    //       fields?.bigMajorID?.setError("");
    //       fields?.bigAcademicYearID?.setError("");
    //       fields?.strYearGraduation?.setError("");
    //       fields?.strPlaceName?.setError("");
    //       switch (bigWorkSectorId) {
    //         case appVariables?.workSectorID?.gov:
    //           fields?.strPositionTitle?.setValidation("required", true, false);
    //           fields?.bigWorkGovSectorType?.setValidation("required", true, false);
    //           fields?.bigGovPlaceName?.setValidation("required", true, false);
    //           fields?.intExperienceYear?.setValidation("required", true, false);
    //           break;
    //         case appVariables?.workSectorID?.student:
    //           fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
    //           fields?.bigUniversityName?.setValidation("required", true, false);
    //           fields?.bigMajorID?.setValidation("required", true, false);
    //           fields?.bigAcademicYearID?.setValidation("required", true, false);
    //           break;
    //         case appVariables?.workSectorID?.unEmployee:
    //           fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
    //           fields?.bigUniversityName?.setValidation("required", true, false);
    //           fields?.bigMajorID?.setValidation("required", true, false);
    //           fields?.strYearGraduation?.setValidation("required", true, false);
    //           break;
    //         case appVariables?.workSectorID?.prvt:
    //           fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
    //           fields?.strPositionTitle?.setValidation("required", true, false);
    //           fields?.strPlaceName?.setValidation("required", true, false);
    //           fields?.intExperienceYear?.setValidation("required", true, false);
    //           break;
    //         default:
    //           break;
    //       }
    //       setState({ ...state, intStep: 2 });
    //     } else {
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
    //       return;
    //     }
    //     appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
    //     appDispatch();
    //   },

    // gOToStep3:
    //   ({ state, setState, fields, interviewfields }) =>
    //   () => {
    //     if (fields?.bigUniversityName?.getValue()?.key === "other") {
    //       fields?.strUniversityName?.setValidation("required", true, false);
    //     } else {
    //       fields?.strUniversityName?.setValidation("required", false, false);
    //       fields?.strUniversityName?.setError("");
    //     }

    //     if (!fields?.controller?.isFormValid()) {
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
    //       return;
    //     }
    //     setState({ ...state, intStep: 3 });
    //     appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
    //     appDispatch();
    //   },
    // gOToStepPre:
    //   ({ fields, interviewfields, state, setState }) =>
    //   () => {
    //     setState({ ...state, intStep: state.intStep - 1 });
    //     appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
    //     appDispatch();
    //   },

    onChangeWorkGovSectorType: (fields) => () => {
      try {
        //#endregion set options
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        const workGovSectorTypeid = fields.bigWorkGovSectorType.getValue()?.key || "";
        if (!workGovSectorTypeid) return;

        const workGovSectorTypeKey = appFunctions?.appCategoryGetKey(objCat?.Root?.typeGovSector, workGovSectorTypeid);
        let lstGovPlaceName = [];
        Object.entries(objCat?.Root?.typeGovSector?.[workGovSectorTypeKey] || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstGovPlaceName.push({ key: value?.id, value: value?.value });
          }
        });

        fields.bigGovPlaceName.setOptions([...lstGovPlaceName, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }], true);

        //#endregion

        //#region setValues
        if (appState?.userInfo?.jsnUserInfo?.strFullName) {
          fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName);
        }
        if (appState?.userInfo?.jsnUserInfo?.strMajor) {
          fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
        }
        if (appState?.userInfo?.jsnUserInfo?.bigGender) {
          fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
        }
        if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
          fields.bigWorkSector2.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
        }
        //#endregion

        // setIsLoading(false);
        appDispatch();
      } catch (error) {
        // setIsLoading(false);
        appFunctions.logError(strPageInfo, "initData-ERROR", error);
      }
    },

    // onChangesetValidation:
    //   ({ fields, interviewfields, state }) =>
    //   (mainfieldName, lstfieldsName = [], lstNotfieldsName = []) =>
    //   () => {
    //     const blnValue = interviewfields?.[mainfieldName]?.getValue()?.key === "true";

    //     for (let index = 0; index < lstfieldsName.length; index++) {
    //       if (blnValue) {
    //         interviewfields?.[lstfieldsName[index]]?.setValidation("required", true, false);
    //       } else {
    //         interviewfields?.[lstfieldsName[index]]?.setValidation("required", false, false);
    //         interviewfields?.[lstfieldsName[index]]?.setError("");
    //       }
    //     }
    //     for (let index = 0; index < lstNotfieldsName.length; index++) {
    //       if (!blnValue) {
    //         interviewfields?.[lstNotfieldsName[index]]?.setValidation("required", true, false);
    //       } else {
    //         interviewfields?.[lstNotfieldsName[index]]?.setValidation("required", false, false);
    //         interviewfields?.[lstNotfieldsName[index]]?.setError("");
    //       }
    //     }

    //     appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
    //     appDispatch();
    //   },

    // handleSubmit: async ({ fields, interviewfields, navigate, state }) => {
    //   try {
    //     console.log(fields, interviewfields);

    //     if (!fields?.controller?.isFormValid() || !interviewfields?.controller?.isFormValid()) {
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
    //       return;
    //     }
    //     if (isLoading) {
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //       return;
    //     }
    //     setIsLoading(true);
    //     const objectInput = handler?.getUserObj({ fields, interviewfields, state });
    //     if (!appState?.clientInfo?.blnIsUserLoggedIn) {
    //       objectInput.strUserPassword = fields?.strUserPassword?.getValue();
    //       objectInput.lstStatisticFelid = ["intCountJoinUser", "intCountJoinUserAll", "intCountJoinStudentAll", "intCountJoinStudent"];
    //       objectInput.blnIsStudent = true;
    //       const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
    //         objectInput: objectInput,
    //       });
    //       if (!result?.blnIsRequestSuccessful) {
    //         appFunctions.logMessage(result?.response, "");
    //         if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
    //           Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //         } else {
    //           Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //         }
    //         setIsLoading(false);
    //         return;
    //       }
    //       appState.userInfo = result.response;
    //       appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
    //     } else {
    //       const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
    //         objectInput: { ...objectInput, dtmUpdatedDate: new Date()?.toUTCString() },
    //         objectCondition: {
    //           bigAppID: appVariables.App_ID,
    //           bigSystemID: appState?.userInfo?.bigSystemID,
    //           bigUserID: appState?.userInfo?.bigUserID,
    //         },
    //       });
    //       if (!result?.blnIsRequestSuccessful) {
    //         appFunctions.logMessage(result?.response, "");
    //         if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
    //           Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //         } else {
    //           Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
    //         }
    //         setIsLoading(false);
    //         return;
    //       }
    //     }

    //     setIsLoading(false);
    //     if (!appState.clientInfo.blnIsUserLoggedIn) {
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote?.submit_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
    //     } else {
    //       Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
    //     }

    //     appState.clientInfo.blnIsUserLoggedIn = true;
    //     appDispatch();
    //     fields?.controller?.resetForm();
    //     navigate(appRouting?.Account?.url, { replace: true });
    //   } catch (error) {
    //     appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
    //     setIsLoading(false);
    //   }
    // },
  };

  return handler;
};
