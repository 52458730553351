import { Alert, Confirmation, Grid, Icon, Typography } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import { aaiot_System_ID } from "../../../../../appHelper/appVariables";
import { App_Gray_Color, App_Primary_Color } from "../../../../../appHelper/appColor";

const strPageInfo = "@src/component/public/manageSystrm/view/contact/controler/CtrlMngSystem.js";

const tblSystem = generateQueries("tblSystem");

export const CtrlAdvertisingVideoManagement = (appState, appDispatch, isLoading, setIsLoading, stateInfo, setStateInfo, navigate = null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    getSystemInfo: async ({ getNewSystemInfo }) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        setIsLoading(true);
        const result = await getNewSystemInfo();

        stateInfo.blnIsInitDone = true;
        stateInfo.systemInfo = result;
        setStateInfo({ ...stateInfo });
        setIsLoading(false);
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getNewSystemInfo-ERROR", error);
      }
    },

    closeModale: () => {
      setStateInfo({ ...stateInfo, blnOpenModal: false, selectedVideoInfo: null });
    },
    openModale:
      ({ fieldVedio }) =>
      (selectedVideoInfo) =>
      () => {
        fieldVedio.resetField();
        stateInfo.lstVideos=[]
        if (selectedVideoInfo) {
          stateInfo.selectedVideoInfo = selectedVideoInfo;
          stateInfo.lstVideos = selectedVideoInfo?.lstVideos;

          fieldVedio.strVedioUrl.setValue(selectedVideoInfo?.strVedioUrl);
          fieldVedio.strVedioTitle.setValue(selectedVideoInfo?.strVedioTitle);
        }

        setStateInfo({ ...stateInfo, blnOpenModal: true });
      },

    handleSubmitModal:
      ({ fieldVedio, uploaderVideo }) =>
      async () => {
        try {
          if (!fieldVedio.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          if (!stateInfo?.systemInfo?.jsnSystemConfig) {
            stateInfo.systemInfo.jsnSystemConfig = { ...stateInfo?.systemInfo?.jsnSystemConfig };
          }
          if (!stateInfo?.systemInfo?.jsnSystemConfig?.lstAdvVideos) {
            stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos = [];
          }
          const filteredUrl = stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos?.findIndex((video) => String(video.strVedioUrl).trim() === String(fieldVedio?.strVedioUrl?.getValue()).trim());
          if (filteredUrl !== -1 && String(fieldVedio?.strVedioUrl?.getValue()).trim() !== stateInfo?.selectedVideoInfo?.strVedioUrl) {
            fieldVedio?.strVedioUrl?.controller?.setError("strVedioUrl", dictionary?.formapi?.beSelected?.[lang]);
            setIsLoading(false);
            return;
          }

          if (stateInfo.lstVideos?.length && !stateInfo.lstVideos[0]?.path) {
            const resultUploadVideo = await uploaderVideo?.uploadHandler("trainingsVideos");
            if (!resultUploadVideo) {
              setIsLoading(false);
              return;
            }
          }else if (!stateInfo.lstVideos?.length){
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.uploadedVideo?.[lang], "warning");
            setIsLoading(false);
            return
          }

          if (!stateInfo?.selectedVideoInfo) {
            stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos.push({
              id: appFunctions?.generateID(10),
              strVedioUrl: String(fieldVedio?.strVedioUrl?.getValue()).trim(),
              strVedioTitle: fieldVedio?.strVedioTitle?.getValue(),
              lstVideos: stateInfo.lstVideos,
            });
          } else {
            const vedioIndex = stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos?.findIndex((video) => video.id === stateInfo.selectedVideoInfo?.id);
            if (vedioIndex > -1) {
              stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos[vedioIndex] = {
                ...stateInfo.selectedVideoInfo,
                strVedioUrl: fieldVedio?.strVedioUrl?.getValue(),
                strVedioTitle: fieldVedio?.strVedioTitle?.getValue(),
                lstVideos: stateInfo.lstVideos,
              };
            }
          }
          const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: { jsnSystemConfig: stateInfo.systemInfo.jsnSystemConfig, dtmUpdatedDate: new Date().toUTCString() },
            objectCondition: {
              bigSystemID: bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          fieldVedio.resetField();
          setStateInfo({
            ...stateInfo,
            lstVideos: [],
            selectedVideoInfo: null,
            blnOpenModal: false,
          });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "handleSubmitSponserModal-ERROR");
          setIsLoading(false);
        }
      },

    delateVideoConfirmation: (selectedVideo) => () => {
      const delateVideo = (selectedVideo) => async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);
          stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos = stateInfo.systemInfo.jsnSystemConfig.lstAdvVideos?.filter((video) => video.id !== selectedVideo?.id);

          const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: { jsnSystemConfig: stateInfo.systemInfo.jsnSystemConfig, dtmUpdatedDate: new Date().toUTCString() },
            objectCondition: {
              bigSystemID: bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          setIsLoading(false);
          stateInfo.lstMail = stateInfo.lstMail.filter((el) => !stateInfo.lstMailChecked.includes(el.bigMailID));
          stateInfo.lstMailChecked = [];
          setStateInfo({ ...stateInfo });

          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      };

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deleteItem?.[lang],
        delateVideo(selectedVideo),
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
  };
  return handler;
};
