import { Button, Grid, Typography } from "@cbmisorg/material";
import React from "react";
import { FormField } from "../../../../util/formApi/FormAPI";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { App_info_Color, App_Light_Text_Color } from "../../../../util/appStyle";

const labels = dictionary?.components?.public?.researcherRegistration;

function Step2({  onChangeAreaCode, fields, classes, lang,  appState, uploaderVideo, handleSubmit }) {
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container spacing={2}>
          <Grid item className={classes?.subtitle}>
            <Typography as={lang === "arb" ? "subtitle1" : "subtitle1"} className={classes?.subtitleLabel}>
              {labels?.internInfo?.[lang]}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormField objHandler={fields.strFullName} icon="person" mode={"classic"} color={App_info_Color} className={classes?.field} />
          </Grid>
          <Grid item xs={12}>
            <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"} color={App_info_Color} className={classes?.field} />
          </Grid>
          <Grid item xs={appState?.clientInfo?.blnIsUserLoggedIn ? "12" : "0"}>
            <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
          </Grid>
          {!jsnSystemConfig?.blnAreaCode ? null : (
            <React.Fragment>
              {lang === "arb" ? null : (
                <Grid item xs={4} md="3" lg="2" dir="ltr">
                  <FormField
                    objHandler={fields.bigAreaCountryID}
                    mode={"classic"}
                    color={App_info_Color}
                    inputDir="ltr"
                    dir="ltr"
                    className={classes?.field}
                    onChange={onChangeAreaCode}
                  />
                </Grid>
              )}
              <Grid item xs={8} md="9" lg="10">
                <FormField objHandler={fields.strUserPhone} mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
              </Grid>
              {lang !== "arb" ? null : (
                <Grid item xs={4} md="3" lg="2" dir="ltr">
                  <FormField
                    objHandler={fields.bigAreaCountryID}
                    mode={"classic"}
                    color={App_info_Color}
                    inputDir="ltr"
                    dir="ltr"
                    className={classes?.field}
                    onChange={onChangeAreaCode}
                  />
                </Grid>
              )}
            </React.Fragment>
          )}

          <Grid item xs={12} md="12">
            <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} />
          </Grid>
          <React.Fragment>
            {!jsnSystemConfig?.blnContryName ? null : (
              <Grid item xs={12} md="12">
                <FormField objHandler={fields.bigCountryOfResidenceID} icon="public" mode={"classic"} color={App_info_Color} className={classes?.field}  onChange={onChangeAreaCode}/>
              </Grid>
            )}
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.strPositionTitle} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.intExperienceYear} icon="AutoAwesome" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid item xs="12" container justifyContent="center" py-3>
              <Grid item>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.uploadVideo?.[lang]}
                </Typography>
              </Grid>
              <Grid item alignSelf="baseline" alignContent="baseline" py-0>
                {uploaderVideo?.JSX}
              </Grid>
            </Grid>
          </React.Fragment>

          {appState?.clientInfo?.blnIsUserLoggedIn ? null : (
            <React.Fragment>
              <Grid item className={classes?.subtitle}>
                <Typography as={lang === "arb" ? "subtitle1" : "subtitle1"} className={classes?.subtitleLabel}>
                  {labels?.registrationInfo?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12">
                <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs="12" pt-5 pb-10>
            <Button py-0 px-10 label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={handleSubmit} className={classes?.btn} />
          </Grid>
        </Grid>
        <input type="submit" style={{ visibility: "hidden" }} onClick={handleSubmit} />
      </form>
    </React.Fragment>
  );
}

export default Step2;
