import React, { useEffect, useRef } from "react";
import "./Button.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import Icon from "../../../icon/CbmisIcon";

const Button = (props) => {
  const { label, color, size, textColor, icon, iconSize, endIcon, buttonAdornment, endAdornment, iconColor, mode, sx, hidden, disabled, dir, className, id, ...rest } =
    props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(componentsFunction.randomName("Button"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [label, size, textColor, icon, iconSize, endIcon, buttonAdornment, endAdornment, iconColor, hidden, disabled, dir, className, id]);

  useEffect(() => {
    const getStyle = String(mode).toLowerCase();
    switch (getStyle) {
      case "outlined":
        setSxClassName(
          styleRef,
          getID.current,
          {
            background: "transparent !important",
            color: `${componentsFunction.checkColorReturnValue(color)} !important`,
            border: `1px solid ${componentsFunction.checkColorReturnValue(color)} !important`,
            ...(sx ? { ...sx } : {}),
          },
          "cbmis-main-button"
        );

        break;
      case "contained":
        setSxClassName(
          styleRef,
          getID.current,
          {
            background: `${componentsFunction.checkColorReturnValue(color)} !important`,
            color: `#fff`,
            border: `1px solid transparent !important`,
            ...(sx ? { ...sx } : {}),
          },
          "cbmis-main-button"
        );

        break;
      case "link":
        setSxClassName(
          styleRef,
          getID.current,
          {
            background: "transparent !important",
            color: `${componentsFunction.checkColorReturnValue(color)} !important`,
            border: `1px solid transparent !important`,
            ...(sx ? { ...sx } : {}),
          },
          "cbmis-main-button"
        );

        break;
      default:
        setSxClassName(
          styleRef,
          getID.current,
          {
            background: `${componentsFunction.checkColorReturnValue(color)} !important`,
            color: `#fff`,
            border: `1px solid transparent !important`,
            ...(sx ? { ...sx } : {}),
          },
          "cbmis-main-button"
        );
        // setBtnStyle("cbmis-btn-contained")
        componentsFunction.getbgColorReturnClassName("." + styleRef.current.strSxClassName, color ? color : "var(--cbmis-primary)");
        break;
    }
  }, [sx, screenWidth, color, mode]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const btnStyleClass = () => {
    const getStyle = String(mode).toLowerCase();
    switch (getStyle) {
      case "outlined":
        return "cbmis-btn-outlined";
      case "contained":
        return "cbmis-btn-contained";
      case "link":
        return "cbmis-btn-link";
      default:
        return "cbmis-btn-contained";
    }
  };

  const btnStyle = useRef(btnStyleClass());

  return (
    <React.Suspense fallback="">
      <button
        type="submit"
        className={`cbmis-main-button ${btnStyle.current} cbmis-btn-size-${size} ${!label ? "cbmis-icon-btn" : ""} 
                        ${typeof icon !== "undefined" ? "cbmis-btn-label-haveIcon" : ""}}  
                        ${!label && !icon && !endIcon && !endAdornment && !buttonAdornment ? "cbmis-no-button" : null}
                        ${componentsFunction.checkExistsPadding(rest)} 
                        ${componentsFunction.CheckDisabledItem(disabled)} 
                        ${componentsFunction.CheckDirectionItem(dir)}  
                        ${componentsFunction.CheckHiddenItem(hidden)} 
                        ${componentsFunction.checkExistsMargin(rest)}
                        ${className || ""}`}
        {...getRestProps()}
        id={getID.current}
      >
        {icon ? (
          <div className="cbmis-icon-container-in-btn">
            <Icon
              icon={icon}
              size={iconSize ? iconSize : ""}
              color={iconColor ? componentsFunction.checkColorReturnValue(iconColor) : componentsFunction.checkColorReturnValue(textColor)}
            />
          </div>
        ) : buttonAdornment ? (
          <div className="cbmis-adornment-container-in-btn">{buttonAdornment}</div>
        ) : (
          ""
        )}
        {label ? (
          <label className="cbmis-btn-label" style={{ color: componentsFunction.checkColorReturnValue(textColor) }}>
            {label}
          </label>
        ) : (
          ""
        )}

        {props.children}

        {endIcon ? (
          <div className="cbmis-icon-container-in-btn">
            <Icon icon={endIcon} size={iconSize ? iconSize : ""} color={iconColor ? iconColor : textColor} />
          </div>
        ) : endAdornment ? (
          <div className="cbmis-adornment-container-in-btn">{endAdornment}</div>
        ) : (
          ""
        )}
      </button>
    </React.Suspense>
  );
};

export default Button;
