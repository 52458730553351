"use client";

function getPkgConfigStyle() {
  const initConfig = {
    blnIsTestingMode: true,
    enableCleanUpStyles: true,
    enableCleanChangedStyles: true,
  };
  return initConfig;
}

export var pkgConfigStyle = getPkgConfigStyle();

export function setPkgConfigStyle(config) {
  let newConfig = config;
  if (!newConfig) {
    newConfig = {};
  }
  if (typeof newConfig?.blnIsTestingMode !== "boolean") {
    newConfig.blnIsTestingMode = true;
  }
  if (typeof newConfig?.enableCleanUpStyles !== "boolean") {
    newConfig.enableCleanUpStyles = true;
  }
  if (typeof newConfig?.enableCleanChangedStyles !== "boolean") {
    newConfig.enableCleanChangedStyles = true;
  }
  pkgConfigStyle.blnIsTestingMode = newConfig.blnIsTestingMode;
  pkgConfigStyle.enableCleanUpStyles = newConfig.enableCleanUpStyles;
  pkgConfigStyle.enableCleanChangedStyles = newConfig.enableCleanChangedStyles;
}

import "./style/main.css";
import "./style/themes.css";
import "./style/dataCbmisDefault.css";
import "./style/dataCbmisSx.css";
import "./style/dataClientStyle.css";

import MainCache from "./cache/MainCache";
const cache = MainCache();

import { StyleBuilder as StyleBuilderFun } from "./styleBuilder/StyleBuilder";

export const StyleBuilder = StyleBuilderFun(cache);

export { default as ClassesBuilder, makeStyle } from "./styleBuilder/ClassesBuilder";

export { default as ThemeProvider } from "./themeProvider/ThemeProvider";

export { default as ChangeThemes } from "./themeProvider/ChangeThemes";

export { default as StyledComponentsRegistry } from "./styledComponentsRegistry/StyledComponentsRegistry";

export * as styleFunction from "./helper/styleFunction";
export * as styleVariables from "./helper/styleVariables";
export { getSpecificCssRule, sizeValuesSetter, sizeValuesSelectDefault , sizeOneValueSetter, aggregationSizeStyle} from "./helper/cssRulesUtils";

export { objCss, cssKeys, globalValues, mainTheme } from "./themeProvider/cssVariables";

export { ContextThemeProvider } from "./themeProvider/ThemeProvider";
