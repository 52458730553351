export const FileExtensions1ToIcon = {
  accdb: "file_type_access.svg",
  accdt: "file_type_access.svg",
  mdb: "file_type_access.svg",
  accda: "file_type_access.svg",
  accdc: "file_type_access.svg",
  accde: "file_type_access.svg",
  accdp: "file_type_access.svg",
  accdr: "file_type_access.svg",
  accdu: "file_type_access.svg",
  ade: "file_type_access.svg",
  adp: "file_type_access.svg",
  laccdb: "file_type_access.svg",
  ldb: "file_type_access.svg",
  mam: "file_type_access.svg",
  maq: "file_type_access.svg",
  mdw: "file_type_access.svg",
  afdesign: "file_type_affinitydesigner.svg",
  affinitydesigner: "file_type_affinitydesigner.svg",
  afphoto: "file_type_affinityphoto.svg",
  affinityphoto: "file_type_affinityphoto.svg",
  afpub: "file_type_affinitypublisher.svg",
  affinitypublisher: "file_type_affinitypublisher.svg",
  ai: "file_type_ai.svg",
  ino: "file_type_arduino.svg",
  pde: "file_type_arduino.svg",
  aspx: "file_type_aspx.svg",
  ascx: "file_type_aspx.svg",
  astro: "file_type_astro.svg",
  aac: "file_type_audio.svg",
  act: "file_type_audio.svg",
  aiff: "file_type_audio.svg",
  amr: "file_type_audio.svg",
  ape: "file_type_audio.svg",
  au: "file_type_audio.svg",
  dct: "file_type_audio.svg",
  dss: "file_type_audio.svg",
  dvf: "file_type_audio.svg",
  flac: "file_type_audio.svg",
  gsm: "file_type_audio.svg",
  iklax: "file_type_audio.svg",
  ivs: "file_type_audio.svg",
  m4a: "file_type_audio.svg",
  m4b: "file_type_audio.svg",
  m4p: "file_type_audio.svg",
  mmf: "file_type_audio.svg",
  mogg: "file_type_audio.svg",
  mp3: "file_type_audio.svg",
  mpc: "file_type_audio.svg",
  msv: "file_type_audio.svg",
  oga: "file_type_audio.svg",
  ogg: "file_type_audio.svg",
  opus: "file_type_audio.svg",
  ra: "file_type_audio.svg",
  raw: "file_type_audio.svg",
  tta: "file_type_audio.svg",
  vox: "file_type_audio.svg",
  wav: "file_type_audio.svg",
  wma: "file_type_audio.svg",
  avif: "file_type_avif.svg",
  awk: "file_type_awk.svg",
  a: "file_type_binary.svg",
  app: "file_type_binary.svg",
  bin: "file_type_binary.svg",
  cmo: "file_type_binary.svg",
  cmx: "file_type_binary.svg",
  cma: "file_type_binary.svg",
  cmxa: "file_type_binary.svg",
  cmi: "file_type_binary.svg",
  dll: "file_type_binary.svg",
  exe: "file_type_binary.svg",
  hl: "file_type_binary.svg",
  ilk: "file_type_binary.svg",
  lib: "file_type_binary.svg",
  n: "file_type_binary.svg",
  ndll: "file_type_binary.svg",
  o: "file_type_binary.svg",
  obj: "file_type_binary.svg",
  pyc: "file_type_binary.svg",
  pyd: "file_type_binary.svg",
  pyo: "file_type_binary.svg",
  pdb: "file_type_binary.svg",
  scpt: "file_type_binary.svg",
  scptd: "file_type_binary.svg",
  so: "file_type_binary.svg",
  bb: "file_type_blitzbasic.svg",
  gemfile: "file_type_bundler.svg",
  cake: "file_type_cake.svg",
  csr: "file_type_cert.svg",
  crt: "file_type_cert.svg",
  cer: "file_type_cert.svg",
  der: "file_type_cert.svg",
  pfx: "file_type_cert.svg",
  p12: "file_type_cert.svg",
  p7b: "file_type_cert.svg",
  p7r: "file_type_cert.svg",
  src: "file_type_cert.svg",
  crl: "file_type_cert.svg",
  sst: "file_type_cert.svg",
  stl: "file_type_cert.svg",
  lucee: "file_type_cf.svg",
  h: "file_type_cheader.svg",
  class: "file_type_class.svg",
  cjm: "file_type_clojure.svg",
  cljc: "file_type_clojure.svg",
  cljs: "file_type_clojurescript.svg",
  kit: "file_type_codekit.svg",
  plist: "file_type_light_config.svg",
  hpp: "file_type_cppheader.svg",
  hh: "file_type_cppheader.svg",
  hxx: "file_type_cppheader.svg",
  "h++": "file_type_cppheader.svg",
  csx: "file_type_csharp.svg",
  csproj: "file_type_csproj.svg",
  db: "file_type_light_db.svg",
  djt: "file_type_django.svg",
  eco: "file_type_light_docpad.svg",
  drawio: "file_type_drawio.svg",
  dio: "file_type_drawio.svg",
  ejs: "file_type_ejs.svg",
  el: "file_type_emacs.svg",
  elc: "file_type_emacs.svg",
  ensime: "file_type_ensime.svg",
  eps: "file_type_eps.svg",
  xls: "file_type_excel.svg",
  xlsx: "file_type_excel.svg",
  xlsm: "file_type_excel.svg",
  ods: "file_type_excel.svg",
  fods: "file_type_excel.svg",
  xlsb: "file_type_excel.svg",
  fbx: "file_type_fbx.svg",
  fla: "file_type_light_fla.svg",
  swf: "file_type_flash.svg",
  swc: "file_type_flash.svg",
  woff: "file_type_light_font.svg",
  woff2: "file_type_light_font.svg",
  ttf: "file_type_light_font.svg",
  otf: "file_type_light_font.svg",
  eot: "file_type_light_font.svg",
  pfa: "file_type_light_font.svg",
  pfb: "file_type_light_font.svg",
  sfd: "file_type_light_font.svg",
  fsproj: "file_type_fsproj.svg",
  gmx: "file_type_gamemaker.svg",
  yy: "file_type_light_gamemaker2.svg",
  yyp: "file_type_light_gamemaker2.svg",
  gradle: "file_type_light_gradle.svg",
  hxproj: "file_type_haxedevelop.svg",
  idr: "file_type_idris.svg",
  lidr: "file_type_idris.svg",
  ibc: "file_type_idrisbin.svg",
  ipkg: "file_type_idrispkg.svg",
  jpeg: "file_type_image.svg",
  jpg: "file_type_image.svg",
  gif: "file_type_image.svg",
  png: "file_type_image.svg",
  bmp: "file_type_image.svg",
  tiff: "file_type_image.svg",
  ico: "file_type_image.svg",
  imba: "file_type_imba.svg",
  imba2: "file_type_imba.svg",
  inc: "file_type_inc.svg",
  include: "file_type_inc.svg",
  infopathxml: "file_type_infopath.svg",
  xsn: "file_type_infopath.svg",
  xsf: "file_type_infopath.svg",
  xtp2: "file_type_infopath.svg",
  jar: "file_type_jar.svg",
  jbuilder: "file_type_jbuilder.svg",
  json5: "file_type_light_json5.svg",
  jsonld: "file_type_light_jsonld.svg",
  "json-ld": "file_type_light_jsonld.svg",
  jsp: "file_type_jsp.svg",
  jss: "file_type_jss.svg",
  ipynb: "file_type_jupyter.svg",
  key: "file_type_key.svg",
  pem: "file_type_key.svg",
  master: "file_type_layout.svg",
  enc: "file_type_license.svg",
  hxp: "file_type_lime.svg",
  liquid: "file_type_liquid.svg",
  ls: "file_type_livescript.svg",
  lnk: "file_type_lnk.svg",
  log: "file_type_log.svg",
  tlg: "file_type_log.svg",
  crec: "file_type_lync.svg",
  ocrec: "file_type_lync.svg",
  makefile: "file_type_makefile.svg",
  map: "file_type_map.svg",
  mdown: "file_type_markdown.svg",
  markdown: "file_type_markdown.svg",
  fig: "file_type_matlab.svg",
  mex: "file_type_matlab.svg",
  mexn: "file_type_matlab.svg",
  mexrs6: "file_type_matlab.svg",
  mn: "file_type_matlab.svg",
  mum: "file_type_matlab.svg",
  mx: "file_type_matlab.svg",
  mx3: "file_type_matlab.svg",
  rwd: "file_type_matlab.svg",
  slx: "file_type_matlab.svg",
  slddc: "file_type_matlab.svg",
  smv: "file_type_matlab.svg",
  xvc: "file_type_matlab.svg",
  mustache: "file_type_light_mustache.svg",
  mst: "file_type_light_mustache.svg",
  njsproj: "file_type_njsproj.svg",
  nupkg: "file_type_nuget.svg",
  snupkg: "file_type_nuget.svg",
  nuspec: "file_type_nuget.svg",
  psmdcp: "file_type_nuget.svg",
  npy: "file_type_numpy.svg",
  npz: "file_type_numpy.svg",
  nunj: "file_type_nunjucks.svg",
  njs: "file_type_nunjucks.svg",
  one: "file_type_onenote.svg",
  onepkg: "file_type_onenote.svg",
  onetoc: "file_type_onenote.svg",
  onetoc2: "file_type_onenote.svg",
  sig: "file_type_onenote.svg",
  cl: "file_type_opencl.svg",
  opencl: "file_type_opencl.svg",
  org: "file_type_org.svg",
  pst: "file_type_outlook.svg",
  bcmx: "file_type_outlook.svg",
  otm: "file_type_outlook.svg",
  msg: "file_type_outlook.svg",
  oft: "file_type_outlook.svg",
  ovpn: "file_type_ovpn.svg",
  pkg: "file_type_package.svg",
  patch: "file_type_patch.svg",
  pcd: "file_type_light_pcl.svg",
  pdf: "file_type_pdf.svg",
  psd: "file_type_photoshop.svg",
  php1: "file_type_php.svg",
  php2: "file_type_php.svg",
  php3: "file_type_php.svg",
  php4: "file_type_php.svg",
  php5: "file_type_php.svg",
  php6: "file_type_php.svg",
  phps: "file_type_php.svg",
  phpsa: "file_type_php.svg",
  phpt: "file_type_php.svg",
  phtml: "file_type_php.svg",
  phar: "file_type_php.svg",
  pipeline: "file_type_pipeline.svg",
  pu: "file_type_plantuml.svg",
  plantuml: "file_type_plantuml.svg",
  iuml: "file_type_plantuml.svg",
  puml: "file_type_plantuml.svg",
  pck: "file_type_plsql_package.svg",
  pkb: "file_type_plsql_package_body.svg",
  pkh: "file_type_plsql_package_header.svg",
  pks: "file_type_plsql_package_spec.svg",
  po: "file_type_poedit.svg",
  mo: "file_type_poedit.svg",
  pot: "file_type_powerpoint.svg",
  potx: "file_type_powerpoint.svg",
  potm: "file_type_powerpoint.svg",
  pps: "file_type_powerpoint.svg",
  ppsx: "file_type_powerpoint.svg",
  ppsm: "file_type_powerpoint.svg",
  ppt: "file_type_powerpoint.svg",
  pptx: "file_type_powerpoint.svg",
  pptm: "file_type_powerpoint.svg",
  pa: "file_type_powerpoint.svg",
  ppa: "file_type_powerpoint.svg",
  ppam: "file_type_powerpoint.svg",
  sldm: "file_type_powerpoint.svg",
  sldx: "file_type_powerpoint.svg",
  psd1: "file_type_powershell_psd.svg",
  psm1: "file_type_powershell_psm.svg",
  pro: "file_type_prolog.svg",
  P: "file_type_prolog.svg",
  pub: "file_type_publisher.svg",
  puz: "file_type_publisher.svg",
  q: "file_type_q.svg",
  qbs: "file_type_qbs.svg",
  qvd: "file_type_qlikview.svg",
  qvw: "file_type_qlikview.svg",
  rake: "file_type_rake.svg",
  rt: "file_type_reacttemplate.svg",
  reg: "file_type_registry.svg",
  rego: "file_type_rego.svg",
  rproj: "file_type_rproj.svg",
  sls: "file_type_saltstack.svg",
  sass: "file_type_sass.svg",
  scssm: "file_type_scss.svg",
  unity: "file_type_light_shaderlab.svg",
  fish: "file_type_shell.svg",
  sketch: "file_type_sketch.svg",
  eskip: "file_type_skipper.svg",
  sln: "file_type_sln.svg",
  spe: "file_type_spacengine.svg",
  sqlite: "file_type_sqlite.svg",
  sqlite3: "file_type_sqlite.svg",
  db3: "file_type_sqlite.svg",
  sss: "file_type_sss.svg",
  dta: "file_type_stata.svg",
  storyboard: "file_type_storyboard.svg",
  svg: "file_type_svg.svg",
  tcl: "file_type_tcl.svg",
  exp: "file_type_tcl.svg",
  tfstate: "file_type_terraform.svg",
  tst: "file_type_test.svg",
  texi: "file_type_light_tex.svg",
  tikz: "file_type_light_tex.svg",
  csv: "file_type_text.svg",
  tsv: "file_type_text.svg",
  todo: "file_type_light_todo.svg",
  tt2: "file_type_tt.svg",
  vala: "file_type_vala.svg",
  vapi: "file_type_vapi.svg",
  vash: "file_type_light_vash.svg",
  vbhtml: "file_type_vbhtml.svg",
  vbproj: "file_type_vbproj.svg",
  vcxproj: "file_type_vcxproj.svg",
  "3g2": "file_type_video.svg",
  "3gp": "file_type_video.svg",
  asf: "file_type_video.svg",
  amv: "file_type_video.svg",
  avi: "file_type_video.svg",
  divx: "file_type_video.svg",
  qt: "file_type_video.svg",
  f4a: "file_type_video.svg",
  f4b: "file_type_video.svg",
  f4p: "file_type_video.svg",
  f4v: "file_type_video.svg",
  flv: "file_type_video.svg",
  m2v: "file_type_video.svg",
  m4v: "file_type_video.svg",
  mkv: "file_type_video.svg",
  mk3d: "file_type_video.svg",
  mov: "file_type_video.svg",
  mp2: "file_type_video.svg",
  mp4: "file_type_video.svg",
  mpe: "file_type_video.svg",
  mpeg: "file_type_video.svg",
  mpeg2: "file_type_video.svg",
  mpg: "file_type_video.svg",
  mpv: "file_type_video.svg",
  nsv: "file_type_video.svg",
  ogv: "file_type_video.svg",
  rm: "file_type_video.svg",
  rmvb: "file_type_video.svg",
  svi: "file_type_video.svg",
  vob: "file_type_video.svg",
  webm: "file_type_video.svg",
  wmv: "file_type_video.svg",
  vsix: "file_type_light_vsix.svg",
  vsixmanifest: "file_type_light_vsixmanifest.svg",
  wasm: "file_type_wasm.svg",
  webp: "file_type_webp.svg",
  doc: "file_type_word.svg",
  docx: "file_type_word.svg",
  docm: "file_type_word.svg",
  dot: "file_type_word.svg",
  dotx: "file_type_word.svg",
  dotm: "file_type_word.svg",
  wll: "file_type_word.svg",
  wxml: "file_type_wxml.svg",
  wxss: "file_type_wxss.svg",
  xcodeproj: "file_type_xcode.svg",
  xfl: "file_type_light_xfl.svg",
  xib: "file_type_xib.svg",
  xliff: "file_type_xliff.svg",
  xlf: "file_type_xliff.svg",
  pex: "file_type_xml.svg",
  tmlanguage: "file_type_xml.svg",
  zip: "file_type_zip.svg",
  rar: "file_type_zip.svg",
  "7z": "file_type_zip.svg",
  tar: "file_type_zip.svg",
  tgz: "file_type_zip.svg",
  bz: "file_type_zip.svg",
  gz: "file_type_zip.svg",
  bzip2: "file_type_zip.svg",
  xz: "file_type_zip.svg",
  bz2: "file_type_zip.svg",
  zipx: "file_type_zip.svg",
};
