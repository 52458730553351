import React, {  useEffect, useRef } from "react";
import "./Grid.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";


const Grid = React.forwardRef((props,ref) => {
  const { container, item, xs, sm, md, lg, xl, spacing, alignItems, alignSelf, justify, direction, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("Grid"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [container, item, xs, sm, md, lg, xl, spacing, alignItems, alignSelf, justify, direction, hidden, disabled, dir, className, id]);

  if(props?.[strTestProps]){
    if(props?.container && props?.item){
      console.log("grid","container", "item");
    }else if(props?.item){
      console.log("grid","item");
    }else if(props?.container){
      console.log("grid","container");
    }
    if(props?.sx){
      console.log("grid", "sx",);
    }
  }
  
  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-grid");
    }
  }, [sx, screenWidth]);

  const getGridScreen = () => {
    switch (screenWidth) {
      case "xs":
        if (xs) return xs;
        else return -1;
      case "sm":
        if (sm) return sm;
        else if (xs) return xs;
        else return -1;
      case "md":
        if (md) return md;
        else if (sm) return sm;
        else if (xs) return xs;
        else return -1;
      case "lg":
        if (lg) return lg;
        else if (md) return md;
        else if (sm) return sm;
        else if (xs) return xs;
        else return -1;
      case "xl":
        if (xl) return xl;
        else if (lg) return lg;
        else if (md) return md;
        else if (sm) return sm;
        else if (xs) return xs;
        else return -1;
      default:
        return "auto";
    }
  };

  const getGridCol = () => {
    const gridScreen = parseInt(getGridScreen());
    switch (gridScreen) {
      case 0:
        return "cbmis-grid-col-0";
      case 1:
        return "cbmis-grid-col-1";
      case 2:
        return "cbmis-grid-col-2";
      case 3:
        return "cbmis-grid-col-3";
      case 4:
        return "cbmis-grid-col-4";
      case 5:
        return "cbmis-grid-col-5";
      case 6:
        return "cbmis-grid-col-6";
      case 7:
        return "cbmis-grid-col-7";
      case 8:
        return "cbmis-grid-col-8";
      case 9:
        return "cbmis-grid-col-9";
      case 10:
        return "cbmis-grid-col-10";
      case 11:
        return "cbmis-grid-col-11";
      case 12:
        return "cbmis-grid-col-12";
      default:
        return "cbmis-grid-col-default";
    }
  };

  const getGapSpacing = () => {
    switch (parseInt(spacing)) {
      case 0:
        return "cbmis-grid-spacing-0";
      case 1:
        return "cbmis-grid-spacing-1";
      case 2:
        return "cbmis-grid-spacing-2";
      case 3:
        return "cbmis-grid-spacing-3";
      case 4:
        return "cbmis-grid-spacing-4";
      case 5:
        return "cbmis-grid-spacing-5";
      case 6:
        return "cbmis-grid-spacing-6";
      case 7:
        return "cbmis-grid-spacing-7";
      case 8:
        return "cbmis-grid-spacing-8";
      case 9:
        return "cbmis-grid-spacing-9";
      case 10:
        return "cbmis-grid-spacing-10";
      default:
        return "cbmis-grid-spacing-1";
    }
  };

  const getGridAlignItems = () => {
    switch (String(alignItems).toLowerCase()) {
      case "flex-start":
        return "cbmis-grid-align-items-flex-start";
      case "center":
        return "cbmis-grid-align-items-center";
      case "flex-end":
        return "cbmis-grid-align-items-flex-end";
      case "stretch":
        return "cbmis-grid-align-items-stretch";
      case "baseline":
        return "cbmis-grid-align-items-baseline";
      default:
        return "cbmis-grid-align-items-stretch";
    }
  };


  const getGridAlignSelf = () => {
    switch (String(alignSelf).toLowerCase()) {
      case "flex-start":
        return "cbmis-grid-align-self-flex-start";
      case "center":
        return "cbmis-grid-align-self-center";
      case "flex-end":
        return "cbmis-grid-align-self-flex-end";
      case "stretch":
        return "cbmis-grid-align-self-stretch";
      case "baseline":
        return "cbmis-grid-align-self-baseline";
      default:
        return "cbmis-grid-align-self-center";
    }
  };


  const getGridJustifyContent = () => {
    switch (String(justify).toLowerCase()) {
      case "start":
        return "cbmis-grid-justify-content-flex-start";
      case "center":
        return "cbmis-grid-justify-content-center";
      case "end":
        return "cbmis-grid-justify-content-flex-end";
      case "space-between":
        return "cbmis-grid-justify-content-space-between";
      case "space-around":
        return "cbmis-grid-justify-content-space-around";
      case "space-evenly":
        return "cbmis-grid-justify-content-space-evenly";
      default:
        return "cbmis-grid-justify-content-flex-start";
    }
  };

  const getGridDirection = () => {
    switch (String(direction).toLowerCase()) {
      case "row":
        return "cbmis-grid-flex-direction-row";
      case "row-reverse":
        return "cbmis-grid-flex-direction-row-reverse";
      case "column":
        return "cbmis-grid-flex-direction-column";
      case "column-reverse":
        return "cbmis-grid-flex-direction-column-reverse";
      default:
        return "cbmis-grid-flex-direction-row";
    }
  };

  const getGridClassName = () => {
    const isContainer = container ? `cbmis-grid-container ${getGapSpacing()}` : "";
    const isItem = item ? `cbmis-grid-item ${getGridCol()}` : "";
    return `${isContainer} ${isItem}  ${getGridAlignSelf()} ${getGridAlignItems()} ${getGridDirection()} ${getGridJustifyContent()}`;
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin({ ...rest });
    return { ...newRest };
  };

  return (
    <React.Fragment>
      <div
        id={getID.current}
        className={`${getGridClassName()} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
          disabled
        )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
        ref={ref}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
});

export default Grid;

// direction:row ,row-reverse,column,column-reverse
// justify:flex-start,center,flex-end,space-between,space-around,space-evenly
// alignItems:flex-start,center,flex-end,stretch,baseline
// alignSelf:flex-start,center,flex-end,stretch,baseline
// spacing:0,1,2,3,4,5,6,7,8,9,10
// mt,ml,mb,mr
