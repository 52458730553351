/* eslint-disable no-fallthrough */
import React, { useEffect, useRef } from "react";
import "./Paper.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const Paper = (props) => {
  const { elevation, width, outline, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  if(props?.[strTestProps]){
    if(props?.elevation && props?.width && props?.outline){
      console.log("Paper", "elevation", "width", "outline");
    }else if(props?.elevation){
      console.log("Paper", "elevation");
    }else if(props?.width){
      console.log("Paper", "width",);
    }else if(props?.outline){
      console.log("Paper", "outline");
    }
    if(props?.sx){
      console.log("Paper", "sx",);
    }
  }

  const getID = useRef(id || componentsFunction.randomName("Paper"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [elevation, width, outline, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-paper");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getShadow = () => {
    switch (parseInt(elevation)) {
      case 0:
        return "cbmis-paper-elevation-0";
      case 1:
        return "cbmis-paper-elevation-1";
      case 2:
        return "cbmis-paper-elevation-2";
      case 3:
        return "cbmis-paper-elevation-3";
      case 4:
        return "cbmis-paper-elevation-4";
      case 5:
        return "cbmis-paper-elevation-5";
      case 6:
        return "cbmis-paper-elevation-6";
      case 7:
        return "cbmis-paper-elevation-7";
      case 8:
        return "cbmis-paper-elevation-8";
      case 9:
        return "cbmis-paper-elevation-9";
      default:
        return "cbmis-paper-elevation-2";
    }
  };

  const getWidthClass = () => {
    switch (width) {
      case "xl":
        return "cbmis-width-xl";
      case "lg":
        return "cbmis-width-lg";
      case "md":
        return "cbmis-width-md";
      case "sm":
        return "cbmis-width-sm";
      case "fluid":
        return "cbmis-width-fluid";
      case "half":
        return "cbmis-width-half";
      default:
        return "cbmis-width-fluid";
    }
  };

  const getBorderClass = () => {
    switch (parseInt(outline)) {
      case 1:
        return "cbmis-border-width-1";
      case 2:
        return "cbmis-border-width-2";
      case 3:
        return "cbmis-border-width-3";
      case 4:
        return "cbmis-border-width-4";
      case 5:
        return "cbmis-border-width-5";
      default:
        return "cbmis-border-width-0";
    }
  };

  const shadow = useRef(getShadow());
  const widthClass = useRef(getWidthClass());
  const borderClass = useRef(getBorderClass());

  return (
    <div className="cbmis-paper-container" {...getRestProps()}>
      <div
        id={getID.current}
        className={`cbmis-paper ${shadow.current} ${widthClass.current} ${borderClass.current} ${componentsFunction.CheckDirectionItem(
          dir
        )} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
          rest
        )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Paper;
