import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import * as ClientFunction from "../system/cbmisFunction";

// /**
//  * @param {any} appInitialState
//  * @param {React.Context<any>} AppContext
//  * @param {string} strAppLocalStorageName
//  * @param {boolean} blnIsEncrypt
//  * @param {string} strEncryptKey
//  * @param {boolean} blnIsTestingMode
//  * @returns {(props: any) => JSX.Element}
//  */
export const capitalize = (str) => {
  return String(str).charAt(0).toUpperCase() + String(str).slice(1);
};
export const funAppContext = () => {
  return createContext({ state: {}, dispatch: (newState) => null });
};

export const ContextLayerProvider = React.createContext({});

export function funAppProvider(appInitialState, AppContext, contextName, strAppVersion, strAppLocalStorageName = undefined, blnIsEncrypt = false, strEncryptKey = "secret", blnIsTestingMode = true) {
  const UPDATE_APP_STATE = "UPDATE_APP_STATE";
  const UPDATE_All_APP_STATE = "UPDATE_All_APP_STATE";

  function AppReducer(state, action) {
    switch (action?.type) {
      case UPDATE_APP_STATE:
        if (strAppLocalStorageName) {
          ClientFunction.setAppLocalStorage({ ...state, ...action.newAppState }, strAppLocalStorageName, blnIsEncrypt, strEncryptKey);
        }
        return { ...state, ...action.newAppState };
      case UPDATE_All_APP_STATE:
        if (strAppLocalStorageName) {
          ClientFunction.setAppLocalStorage({ ...action.newAppState }, strAppLocalStorageName, blnIsEncrypt, strEncryptKey);
        }
        return { ...action.newAppState };
      default:
        if (strAppLocalStorageName) {
          ClientFunction.setAppLocalStorage({ ...state }, strAppLocalStorageName, blnIsEncrypt, strEncryptKey);
        }
        return { ...state };
    }
  }

  function AppProviderImpl(props) {
    const [appState, appDispatch] = useReducer(AppReducer, appInitialState);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (strAppLocalStorageName) {
        try {
          if (localStorage.hasOwnProperty(strAppLocalStorageName) && localStorage.getItem(strAppLocalStorageName) !== null) {
            const localState = ClientFunction.getAppLocalStorage(strAppLocalStorageName, blnIsEncrypt, strEncryptKey);
            if (localState?.clientInfo?.strAppVersion !== strAppVersion) {
              appDispatch({ type: UPDATE_APP_STATE, newAppState: appState });
            } else {
              appDispatch({ type: UPDATE_APP_STATE, newAppState: localState });
            }
            if (blnIsTestingMode) {
              console.log(`[${new Date().toLocaleTimeString("sv-SE")}] appState-localStorage updated ✅`);
            }
          }
          setIsLoading(false);
        } catch (error) {
          if (blnIsTestingMode) {
            console.log(`[${new Date().toLocaleTimeString("sv-SE")}] appState-localStorage can't update 💥`, error);
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }, []);

    return (
      <AppContext.Provider
        value={{
          state: appState,
          dispatch: appDispatch,
        }}
      >
        {isLoading ? null : props.children}
      </AppContext.Provider>
    );
  }

  function AppContextImpl() {
    const { state, dispatch } = useContext(AppContext);

    const appContext = (newState = null) => {
      try {
        if (newState) {
          dispatch({ type: UPDATE_All_APP_STATE, newAppState: newState });
        } else {
          dispatch();
        }
      } catch (error) {
        console.error(error);
        return state;
      }
    };
    return {
      [`${String(contextName).toLowerCase()}State`]: state,
      [`${String(contextName).toLowerCase()}Dispatch`]: appContext,
    };
  }

  return {
    AppProvider: AppProviderImpl,
    appContext: AppContextImpl,
  };
}
