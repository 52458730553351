
import * as valueFunction from "./ValuesFunctions";
import * as errorsFunctions from "./ErrorsFunctions";

//#region  check all form values

  export function checkFormValues(formState){
  const fields = Object.entries(formState.fields);
  let newFormState = formState
  const validationFields = fields.filter(field=>field[1].validations)
    validationFields.forEach(field=>{
      newFormState = performValidation(field[0],newFormState)
    });

  return {...newFormState}
  }

export function isFormValid(newFormStates){
    const fields = Object.values(newFormStates.fields)
    return(fields.every(field => {return field.error === ""})) 
  }

  export function performValidation(fieldName, formState){
    const checkHidden = getHidden(fieldName, formState)
    const checkDisabled =getDisabled(fieldName, formState)
    if(checkHidden || checkDisabled){
      return formState
    }else{
      let newFiled= checkFormValueByState(fieldName,formState)
      const newFormState = formState
      newFormState.fields[fieldName]= newFiled
      return {...newFormState}
    }
    
  }
//#endregion


//#region check  values

  //#region hidden && disabled
  const CheckConditionHiddenDisabled=(type,fieldName,formState)=>{
    const condition = formState?.fields?.[fieldName]?.[type]?.condition
    if(typeof condition !== "undefined"){
      if(typeof condition === "boolean"){
        return condition
      }else{
        return condition()
      }
    }
    return false
  }

  const getHidden = (fieldName,formState)=>{
    return CheckConditionHiddenDisabled("hidden",fieldName,formState)
  }

  const getDisabled = (fieldName,formState)=>{
    return CheckConditionHiddenDisabled("disabled",fieldName,formState)
  }
  //#endregion

  const CheckOtherFieldsValue=(rule,fieldName,formState)=>{
    const lstFieldValuesCondition = formState?.fields?.[fieldName].validations?.[rule]?.lstFieldValuesCondition
    let status = true
    if(Array.isArray(lstFieldValuesCondition) && lstFieldValuesCondition.length){
      lstFieldValuesCondition.forEach(element => {
        const fieldValue = valueFunction.getValueByFieldName(fieldName,formState)
        if(!fieldValue){
          status = false
          return status
        }
      });
    }
    return status
  }

  const CheckCondition=(rule,fieldName,formState)=>{
    const condition = formState?.fields?.[fieldName].validations?.[rule]?.condition
    if(condition){
      if(typeof condition === "function"){
        return condition()
      }else {
        return condition
      }
    }
    return true
  }

  const langValue = (fieldName,formState)=>{
    const isMultiLanguage = formState?.fields?.[fieldName]?.objLanguage?.isMultiLanguage
    const objLanguage = !isMultiLanguage?null
                        :formState?.fields?.[fieldName]?.objLanguage?.language ||  formState?.objLanguage
    const fieldValue = formState?.fields?.[fieldName]?.value
      if(!isMultiLanguage || typeof fieldValue === "string" || !(String(formState?.fields?.[fieldName]?.control).toLowerCase() === "text" || String(formState?.fields?.[fieldName]?.control).toLowerCase() === "textarea")){
        return fieldValue? [fieldValue] : ""
      }
      if(isMultiLanguage && (String(formState?.fields?.[fieldName]?.control).toLowerCase() === "text" || String(formState?.fields?.[fieldName]?.control).toLowerCase() === "textarea")){
        const lstValue=[]
        Object.keys(objLanguage).forEach(lang=>{
          if(!fieldValue?.[lang] && objLanguage?.[lang]?.blnIsMandatory){
            lstValue.push("")
          }else  if(fieldValue?.[lang]){
            lstValue.push(fieldValue?.[lang])
          }
        })
        return lstValue
      }

  }


   export function checkFormValueByState(fieldName,formState){
    try {
      const newFiled = formState?.fields?.[fieldName];
      Object.entries(newFiled?.validations).every(([key,value]) =>{
        newFiled.error= ""
        const arrValues = langValue(fieldName,formState)
        const rule = key

        switch(String(key).toLowerCase()){
          case'required':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              if(Required(arrValues) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
            return true
          case 'email':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              if(Email(arrValues) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'onlynumbers':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              if(OnlyNumbers(arrValues) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'onlyletters':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              if(OnlyLetters(arrValues) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'password':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              if(password(arrValues) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'maxword':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.[rule]?.condition
              if(maxWord(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'minword':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.[rule]?.condition
              if(minWord(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'maxlength':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.[rule]?.condition
              if(maxLength(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'minlength':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.[rule]?.condition
              if(minLength(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'included':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.included?.condition
              if(included(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'started':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.started?.condition
              if(started(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'ended':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const condition = formState?.fields?.[fieldName]?.validations?.ended?.condition
              if(ended(arrValues,condition) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'equal':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
              const fieldName2 = formState?.fields?.[fieldName]?.validations?.[rule]?.condition
              const value2 = formState?.fields?.[fieldName2]?.value
              if(equal(arrValues,value2) === false){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
              }
            }
          return true
          case 'custom':
            if(CheckOtherFieldsValue(rule,fieldName,formState) && CheckCondition(rule,fieldName,formState)){
                newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState)
                return false
            }
          return true

          default: return true
        }
      })
      return newFiled
    } catch (error) {
      return formState?.fields?.[fieldName]
    }
  }


//#endregion

/******************* validation Action functions*******************/
//#region
// to check require value
export function Required(arrValues) {
  if(!arrValues || !arrValues){ return false }
    var status = true
    arrValues.forEach(value=>{
      if(!value || (Array.isArray(value) && !value.length)){
        status = false
        return false
      }
    })
    return status 

}

export function Email(arrEmail) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var status = true

    if(Array.isArray(arrEmail) && arrEmail.length > 0){
      arrEmail.forEach(email=>{
        if(!re.test(String(email).toLowerCase())){
          status = false
          return status
        }
      })
    }else if(arrEmail !==""){
      status = re.test(String(arrEmail).toLowerCase())
    }
    return status
}

// to check value is number
export function OnlyNumbers(arrValue){
  const re = /^[0-9,.,٠-٩]+$/;
  var status = true

  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!re.test(String(value).toLowerCase())){
        status = re.test(String(value).toLowerCase())
        return re.test(String(value).toLowerCase())
      }
    })
  }else if(arrValue !==""){
    status = re.test(String(arrValue).toLowerCase())
  }
  return status
}

// to check value is letters
export function OnlyLetters(arrValue){
  const re = /^[A-Za-z\-\u0600-\u06FF, ]+$/;
  var status = true

  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!re.test(String(value).toLowerCase())){
        status = re.test(String(value).toLowerCase())
        return re.test(String(value).toLowerCase())
      }
    })
  }else if(arrValue !==""){
    status = re.test(String(arrValue).toLowerCase())
  }
  return status
}

// to check value1 = value2
export function equal(value1,value2){
  var status = true
  if(Array.isArray(value1) && value1.length > 0){
    value1.forEach(value=>{
      if(value !== value2){
        status = false
        return status
      }
    })
  }else if(value1 !==""){
    status = value1 === value2
  }
  return status
}

// to check length of the value is less than or equal to maxLength
export function maxLength(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.length > parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.length > parseInt(length)
  }
  return status
}

// to check length of the value is more than or equal to maxLength
export function minLength(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.length < parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.length < parseInt(length)
  }
  return status
}

export function started(arrValue,strStarted){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!String(value).toLowerCase().startsWith(String(strStarted).toLowerCase())){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = String(arrValue).toLowerCase().startsWith(String(strStarted).toLowerCase())
  }
  return status
}

export function ended(arrValue,strEnded){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!String(value).toLowerCase().endsWith(String(strEnded).toLowerCase())){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = String(arrValue).toLowerCase().endsWith(String(strEnded).toLowerCase())
  }
  return status
}

export function included(arrValue,strIncluded){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!String(value).toLowerCase().includes(String(strIncluded).toLowerCase())){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = String(arrValue).toLowerCase().includes(String(strIncluded).toLowerCase())
  }
  return status
}


// to check length of the value is more than or equal to maxWord
export function maxWord(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.split(" ").length > parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.split(" ").length <= parseInt(length)
  }
  return status
}

// to check length of the value is more than or equal to minWord
export function minWord(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.split(" ").length < parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.split(" ").length <= parseInt(length)
  }
  return status
}

// to check passwords according to a specific pattern
export function password(arrValue){
  var status = true
  const re = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*.]{8,96}$/;

  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!re.test(String(value))){
        status = re.test(String(value))
        return re.test(String(value))
      }
    })
  }else if(arrValue !==""){
    status = re.test(String(arrValue))
  }
  return status
}
//#endregion
