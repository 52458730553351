import React, { useEffect } from 'react'
import "./CbmisVoiceRecorder.css"
import useRecorder from './useRecorder';
import Grid from '../../material/layout/Grid/Grid';
import Icon from '../../icon/CbmisIcon';

function CbmisVoiceRecorder({appPermissionState,getAudioURL}) {
  let {audioURL, isRecording, startRecording, stopRecording,deleteRecording}= useRecorder(appPermissionState);

  const handelClicked=()=>{
    if(!isRecording){
      startRecording()
    }else{
      stopRecording()
      deleteRecording()
    }
  }

  useEffect(() => {
    if(audioURL && getAudioURL){
      getAudioURL(audioURL)
    }
}, [audioURL,getAudioURL])



  return (
    <React.Fragment>
     <div className='cbmis-record-container'>
        {audioURL?(
          <Grid container>
            <audio id="source" src={audioURL} controls className='cbmis-record-audio'/>
            <Icon icon="delete" color={"red"} eventClicked={deleteRecording} className="cbmis-delete-record-btn-icon" />
          </Grid>
        ):(
          <button className={`cbmis-record-btn ${isRecording?"cbmis-record-btn-active":""}`} onClick={handelClicked}>
            <Icon icon={!isRecording?"mic":"stop"} className="cbmis-record-btn-icon"/>
          </button>
        )}
        
     </div>
    </React.Fragment>
  )
}

export default CbmisVoiceRecorder