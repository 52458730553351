import { Button, Grid, Typography } from "@cbmisorg/material";
import React, { useEffect } from "react";
import { FormField } from "../../../../util/formApi/FormAPI";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { App_info_Color, App_Light_Color, App_Light_Text_Color } from "../../../../util/appStyle";
import { workSectorID } from "../../../../configuration/appVariables";

const labels = dictionary?.components?.public?.trainingRegistration;

function Step3({
  state,
  uploaderVideo,
  uploaderTranscript,
  fields,
  interviewfields,
  classes,
  lang,
  handleSubmit,
  gOToStepPre,
  onChangeWorkGovSectorType,
  onChangesetValidation,
  appState,
}) {
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;


  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container spacing={2}>
          <Grid item className={classes?.subtitle} sx={{ sm: { "&before": { minWidth: "175px !important" } } }}>
            <Typography as={lang === "arb" ? "subtitle1" : "subtitle1"} className={classes?.subtitleLabel}>
              {labels?.interviewQuestions?.[lang]}
            </Typography>
          </Grid>

          {fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov && state?.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode ? (
            <React.Fragment>
              <Grid item xs={12}>
                <FormField objHandler={interviewfields?.blnAppsNeedDev} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  objHandler={interviewfields?.blnGraduationProject}
                  label={labels?.blnGraduationProject?.[lang]}
                  className={classes?.field}
                  onChange={onChangesetValidation("blnGraduationProject",["strExplainProposal"])}
                />
              </Grid>
              {interviewfields?.blnGraduationProject?.getValue()?.key === "true" ? (
                <Grid item xs={12}>
                  <Typography as="body3" color={App_Light_Text_Color}>
                    {labels?.strExplainProposal?.[lang]}
                  </Typography>
                  <FormField objHandler={interviewfields?.strExplainProposal} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
                </Grid>
              ) : null}
            </React.Fragment>
          ) : state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode ? (
            <React.Fragment>
              <Grid item xs={12}>
                <FormField
                  objHandler={interviewfields?.blnlikeWorkinGivSec}
                  className={classes?.field}
                  onChange={onChangesetValidation("blnlikeWorkinGivSec", ["bigWorkGovSectorType", "bigGovPlaceName", "blnGraduationProject", "blnHaveGoveIdea"])}
                />
              </Grid>
              {interviewfields?.blnlikeWorkinGivSec?.getValue()?.key === "true" ? (
                <React.Fragment>
                  <Grid item xs={12} md="6">
                    <FormField
                      objHandler={interviewfields.bigWorkGovSectorType}
                      icon="businesscenter"
                      mode={"placeholder"}
                      color={App_info_Color}
                      className={classes?.field}
                      onChange={onChangeWorkGovSectorType(interviewfields)}
                    />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={interviewfields.bigGovPlaceName} icon="business" mode={"placeholder"} color={App_info_Color} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={interviewfields?.blnGraduationProject} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={interviewfields?.blnHaveGoveIdea} className={classes?.field} onChange={onChangesetValidation("blnHaveGoveIdea", ["strExplainIdea"])} />
                  </Grid>
                  {interviewfields?.blnHaveGoveIdea?.getValue()?.key === "true" ? (
                    <Grid item xs={12}>
                      <Typography as="body3" color={App_Light_Text_Color}>
                        {labels?.strExplainIdea?.[lang]}
                      </Typography>
                      <FormField objHandler={interviewfields?.strExplainIdea} className={classes?.field} />
                    </Grid>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}

          <Grid item xs={12}>
            <FormField
              objHandler={interviewfields.blnExpJs}
              className={classes?.field}
              onChange={onChangesetValidation("blnExpJs", ["intYearExpJs", "intNumJsProject", "strExpJsProject"], ["blnExFe", "intYearExpFn", "strExpFeProject"])}
            />
          </Grid>
          {interviewfields.blnExpJs?.getValue()?.key === "true" ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.intYearExpJs?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.intYearExpJs} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.intNumJsProject?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.intNumJsProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.strExpJsProject?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.strExpJsProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : interviewfields.blnExpJs?.getValue()?.key === "false" ? (
            <React.Fragment>
              <Grid item xs={12}>
                <FormField objHandler={interviewfields.blnExFe} className={classes?.field} onChange={onChangesetValidation("blnExFe", ["intYearExpFn", "strExpFeProject"])} />
              </Grid>
              {interviewfields.blnExFe?.getValue()?.key === "true" ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography as="body3" color={App_Light_Text_Color}>
                      {labels?.intYearExpFn?.[lang]}
                    </Typography>
                    <FormField objHandler={interviewfields?.intYearExpFn} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography as="body3" color={App_Light_Text_Color}>
                      {labels?.strExpFeProject?.[lang]}
                    </Typography>
                    <FormField objHandler={interviewfields?.strExpFeProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
                  </Grid>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}

          <Grid item xs={12}>
            <FormField
              objHandler={interviewfields.blnExpNode}
              className={classes?.field}
              onChange={onChangesetValidation("blnExpNode", ["intYearExpNode","intNumNodeProject", "strExpNodeProject"])}
            />
          </Grid>
          {interviewfields.blnExpNode?.getValue()?.key === "true" ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.intYearExpNode?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.intYearExpNode} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.intNumNodeProject?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.intNumNodeProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.strExpNodeProject?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.strExpNodeProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : null}

          <Grid item xs={12}>
            <FormField objHandler={interviewfields.blnExAi} className={classes?.field} onChange={onChangesetValidation("blnExAi", ["intYearExpAi", "strExpAiProject"])} />
          </Grid>
          {interviewfields.blnExAi?.getValue()?.key === "true" ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.intYearExpAi?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.intYearExpAi} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.strExpAiProject?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.strExpAiProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : null}

          <Grid item xs={12}>
            <FormField objHandler={interviewfields.blnExCybS} className={classes?.field} onChange={onChangesetValidation("blnExCybS", ["intYearExpCybS", "strExpCybSProject"])} />
          </Grid>
          {interviewfields.blnExCybS?.getValue()?.key === "true" ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.intYearExpCybS?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.intYearExpCybS} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12}>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.strExpCybSProject?.[lang]}
                </Typography>
                <FormField objHandler={interviewfields?.strExpCybSProject} mode={"placeholder"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>
        {fields?.bigWorkSector2?.getValue()?.key !== workSectorID?.student ||
        !jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqTranscriptCode?.includes(
          state?.countryCode || appState?.clientInfo?.clientCountryCode
        ) ? null : (
          <React.Fragment>
            <Grid item xs="12" container justifyContent="center" py-3>
              <Grid item>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.uploadTranscript?.[lang]}
                </Typography>
              </Grid>
              <Grid item alignSelf="baseline" alignContent="baseline" py-0>
                {uploaderTranscript?.JSX}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {!jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqVideoCode?.includes(state?.countryCode || appState?.clientInfo?.clientCountryCode) ? null : (
          <React.Fragment>
            <Grid item xs="12" container justifyContent="center" py-3>
              <Grid item>
                <Typography as="body3" color={App_Light_Text_Color}>
                  {labels?.uploadVideo?.[lang]}
                </Typography>
              </Grid>
              <Grid item alignSelf="baseline" alignContent="baseline" py-0>
                {uploaderVideo?.JSX}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item py-5>
            <Button py-0 px-3 label={dictionary?.shared?.buttons?.previous?.[lang]} mode="outlined" color={"#ddd"} onClick={gOToStepPre} className={classes?.btn} />
          </Grid>
          <Grid item py-5>
            <Button
              py-0
              px-3
              label={appState.clientInfo.blnIsUserLoggedIn ? dictionary?.shared?.buttons?.save?.[lang] : dictionary?.shared?.buttons?.registration?.[lang]}
              mode="outlined"
              color={App_info_Color}
              onClick={handleSubmit}
              className={classes?.btn}
            />
          </Grid>
        </Grid>
        <input type="submit" style={{ visibility: "hidden" }} onClick={handleSubmit} />
      </form>
    </React.Fragment>
  );
}

export default Step3;
