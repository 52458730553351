import { checkParseObject } from "../../../../helper/CbmisFunction"

//#region update Values

const getOptions = (fieldName, formState=null)=>{
  const appLanguage = formState.appLanguage;
  const appDefaultLanguage = formState.appDefaultLanguage
  const objOptionLangLabel = formState?.labels?.[fieldName]?.options || {};

  let options= formState?.fields?.[fieldName]?.options

  if (!Array.isArray(options) && typeof options === "object"){
    const arrObjectOption = Object.entries(checkParseObject(options));
    options = arrObjectOption?.map(opt=>{
      return {key:opt?.[1], value:objOptionLangLabel?.[opt[0]]?.[appLanguage] || objOptionLangLabel?.[opt[0]]?.[appDefaultLanguage] || opt[0]}
    })
    return options
  }else if(Array.isArray(options)){
      options = options?.map(opt=>{
        return {key:opt?.key, 
                value: objOptionLangLabel?.[opt?.value]?.[appLanguage] || objOptionLangLabel?.[opt?.value]?.[appDefaultLanguage] || opt?.value
              }
    })
    return options
  }

  return options
}


export function updateValue(newValue, fieldName, formState=null){
    // const appLanguage = formState.appLanguage;
    // const appDefaultLanguage = formState.appDefaultLanguage
    // const objOptionLangLabel = formState?.labels?.[fieldName]?.options || {};

    const theNewValue = checkParseObject(newValue) || ""

    if(formState){
      const field = formState?.fields?.[fieldName];
      let lstOptionTmp = getOptions(fieldName, formState) || [];
      const strControl = String(field?.control).toLowerCase()

      if ((strControl === "list" || strControl === "radio" || strControl === "switch")) {
        let objValue = {};
        for (let i = 0; i < lstOptionTmp.length; i++) {
          if (typeof theNewValue !== "object" && String(lstOptionTmp[i]?.key) === String(theNewValue)) {
            objValue = lstOptionTmp[i];
            break;
          }else if(typeof theNewValue === "object" && String(lstOptionTmp[i]?.key) === String(theNewValue.key)){
            objValue = lstOptionTmp[i];
            break;
          }
        }
        formState.fields[fieldName].value = objValue

      }else if (strControl === "checkbox" || strControl === "multiplelist") {
        let valueTmp = Array.isArray(theNewValue) ? theNewValue : [];

          let letValue = [];
          let keysTmp = valueTmp.map(vl=>{return vl?.key || ""});

          for (let i = 0; i < lstOptionTmp.length; i++) {
            if ((typeof lstOptionTmp[i] === "object"  || typeof lstOptionTmp[i] !== "object" )&& (valueTmp.includes(String(lstOptionTmp[i]?.key)) || valueTmp.includes(Number(lstOptionTmp[i]?.key)))) {
              letValue.push(lstOptionTmp[i]);
            }else if (typeof lstOptionTmp[i] === "object" && (keysTmp.includes(String(lstOptionTmp[i]?.key)) || keysTmp.includes(Number(lstOptionTmp[i]?.key)))) {
              letValue.push(lstOptionTmp[i]);
            }
          }

          formState.fields[fieldName].value = letValue
      }else{
        formState.fields[fieldName].value = theNewValue
      }
      return {...formState}
    }
    return theNewValue
}
/******************* checkbox add to array Of Values *******************/

export function addToCheckboxArray(newPairValue, fieldName,formState = null){
  var currentArrayOfValues = formState?.fields?.[fieldName]?.value
    if(!Array.isArray(currentArrayOfValues)){
      currentArrayOfValues = []
    }
    currentArrayOfValues.push(newPairValue)
    if(formState){
      formState.fields[fieldName].value = currentArrayOfValues
    return {...formState}
    }
    return currentArrayOfValues
}


/******************* checkbox delete from array Of Values *******************/

  export function deleteFromCheckboxArray(key, fieldName,formState = null){
    var arrayOfValues = formState?.fields?.[fieldName]?.value || []
    var filtered = arrayOfValues?.filter(el=>el.key != key); 
    if(filtered.length===0){filtered =[]}
    formState.fields[fieldName].value = filtered
    return {...formState}
  }

//#endregion

//#region get values formApp
export function getFormValues(formState){
  const fields = Object.entries(formState.fields)
   var valuesObject = {}
  fields.map(
    item =>  valuesObject[item[0]] = item[1]?.value 
  );
  return valuesObject
}

/******************* get value by fieldName *******************/
export function getValueByFieldName(fieldName, formState){
  return formState.fields?.[fieldName].value
}
//#endregion


export const getDefaultValue = (list, key, value) => {
  if (key && value) {
    return list.filter((item) => String(item.key) === String(key) && String(item.value) === String(value));
  } else if (Array.isArray(key)) {
    return list.filter((item) => key.includes(String(item.key)));
  } else if (key) {
    return list.filter((item) => String(item.key) === String(key));
  } else if (value) {
    return list.filter((item) => String(item.value) === String(value));
  } else {
    return "";
  }
};

export function updateOptions(newOptions,objOptionLang={}, fieldName, formState=null){
  if(formState){
    formState.fields[fieldName].options = newOptions
    formState.labels[fieldName].options = {
      ...formState?.labels?.[fieldName]?.options,
      ...objOptionLang,
    }
    return {...formState}
  }
  return newOptions
}