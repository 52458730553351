
import * as valueFunction from "./ValuesFunctions";
import * as errorsFunctions from "./ErrorsFunctions";

//#region  check all form values

  export function checkFormValues(formState, objDefLabel){
  const fields = Object.entries(formState);
  let newFormState = formState
  const validationFields = fields.filter(field=>field[1].validations)
    validationFields.forEach(field=>{
      newFormState = performValidation(field[0],newFormState, objDefLabel)
    });

  return {...newFormState}
  }

export function isFormValid(newFormStates){
    const fields = Object.values(newFormStates)
    return(fields.every(field => {return field.error === ""})) 
  }

  export function performValidation(fieldName, formState, objDefLabel){
    const field = formState?.[fieldName]
    if(field?.hidden || field?.disabled){
      return formState
    }else{
      let newFiled= checkFormValueByState(fieldName,formState, objDefLabel)
      const newFormState = formState
      newFormState[fieldName]= newFiled
      return {...newFormState}
    }
    
  }
//#endregion


//#region check  values

  const langValue = (fieldName,formState, objDefLabel)=>{
      const isMultiLanguage = formState?.[fieldName]?.blnLanguage || false
      const fieldValue= formState?.[fieldName]?.getValue()
      if(isMultiLanguage && (String(formState?.[fieldName]?.control).toLowerCase() === "text" || String(formState?.[fieldName]?.control).toLowerCase() === "textarea")){
        const lstValue=[]
        Object.keys(objDefLabel?.objLanguage).forEach(lang=>{
          if(!fieldValue?.[lang] && objDefLabel?.objLanguage?.[lang]?.blnIsMandatory){
            lstValue.push("")
          }else  if(fieldValue?.[lang]){
            lstValue.push(fieldValue?.[lang])
          }
        })
        return lstValue
      }
      return fieldValue
  }


  export function checkFormValueByState(fieldName,formState, objDefLabel){
    try {
      const newFiled = formState?.[fieldName];
      newFiled.error= ""
      const filedValue= langValue(fieldName,formState, objDefLabel)
      const emailValid = Email(filedValue)

      const objFunValidation={
        required:(validValue)=>Required(filedValue),
        email:(validValue)=> emailValid,
        onlynumbers:(validValue)=>OnlyNumbers(filedValue),
        onlyletters:(validValue)=>OnlyLetters(filedValue),
        maxlength:(validValue)=>maxLength(filedValue,validValue),
        minlength:(validValue)=>minLength(filedValue,validValue),
        maxword:(validValue)=>maxWord(filedValue,validValue),
        minword:(validValue)=>minWord(filedValue,validValue),
        maxnumber:(validValue)=>maxNumber(filedValue,validValue),
        minnumber:(validValue)=>minNumber(filedValue,validValue),

        password:(validValue)=>password(filedValue),
        included:(validValue)=>included(filedValue,validValue),
        started:(validValue)=>started(filedValue,validValue),
        ended:(validValue)=>ended(filedValue,validValue),

        equal:(validValue)=>{
          const fieldValue2 = formState?.[validValue]?.getValue()     
         return equal(filedValue,fieldValue2)
        },

        rule:(validValue)=>customRule(validValue)
      }

      Object.entries(newFiled?.validations || {}).every(([key,value]) =>{
        const rule = key
        const ruleLowerCase = String(rule)?.toLowerCase()
        let userRule = value;
        if (value && typeof value === "function") {
          userRule = value(filedValue);
        }
        let blnIsValid = true;
        if (objFunValidation?.[ruleLowerCase] && (userRule || userRule === 0)) {
          blnIsValid = objFunValidation?.[ruleLowerCase](userRule);        
        }

        if (value && !blnIsValid) {
          newFiled.error = errorsFunctions.getErrorLabel(rule, fieldName, formState, objDefLabel);
          return false;
        }

        // if(value && objFunValidation?.[ruleLowerCase](value)===false){
        //   newFiled.error = errorsFunctions.getErrorLabel(rule,fieldName,formState, objDefLabel);
        //   return false
        // }
        return blnIsValid
      })
      return newFiled
    } catch (error) {
      return formState?.[fieldName]
    }
  }


//#endregion

/******************* validation Action functions*******************/
//#region
// to check require value
export function Required(filedValue) {
  if(!filedValue){
      return false
    }else if(Array.isArray(filedValue) && !filedValue?.length){
      return false
    }
    var status = true
    if(Array.isArray(filedValue)){
      for (let index = 0; index < filedValue.length; index++) {
        if(!filedValue[index] || (Array.isArray(filedValue[index]) && !filedValue[index].length)){
          status = false
          break;
        }
      }
    }
    return status 
}

export function Email(arrEmail) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var status = true

    if(Array.isArray(arrEmail) && arrEmail.length > 0){
      arrEmail.forEach(email=>{
        if(!re.test(String(email).toLowerCase())){
          status = false
          return status
        }
      })
    }else if(arrEmail !==""){
      status = re.test(String(arrEmail).toLowerCase())
    }
    return status
}

// to check value is number
export function OnlyNumbers(arrValue){
  const re = /^[0-9,.,٠-٩]+$/;
  var status = true

  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!re.test(String(value).toLowerCase())){
        status = re.test(String(value).toLowerCase())
        return re.test(String(value).toLowerCase())
      }
    })
  }else if(arrValue !==""){
    status = re.test(String(arrValue).toLowerCase())
  }
  return status
}

// to check value is letters
export function OnlyLetters(arrValue){
  const re = /^[A-Za-z\-\u0600-\u06FF, ]+$/;
  var status = true

  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!re.test(String(value).toLowerCase())){
        status = re.test(String(value).toLowerCase())
        return re.test(String(value).toLowerCase())
      }
    })
  }else if(arrValue !==""){
    status = re.test(String(arrValue).toLowerCase())
  }
  return status
}

// to check value1 = value2
export function equal(value1,value2){  
  var status = true  
  if(Array.isArray(value1) && value1.length > 0){
    value1.forEach(value=>{
      if(value !== value2){
        status = false
        return status
      }
    })
  }else if(value1 !==""){
    status = value1 === value2    
  }
  return status
}

// to check length of the value is less than or equal to maxLength
export function maxLength(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.length > parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.length <= parseInt(length)
  }
  return status
}

// to check length of the value is more than or equal to maxLength
export function minLength(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.length < parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.length >= parseInt(length) 
  }
  return status
}

export function started(arrValue,strStarted){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!String(value).toLowerCase().startsWith(String(strStarted).toLowerCase())){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = String(arrValue).toLowerCase().startsWith(String(strStarted).toLowerCase())
  }
  return status
}

export function ended(arrValue,strEnded){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!String(value).toLowerCase().endsWith(String(strEnded).toLowerCase())){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = String(arrValue).toLowerCase().endsWith(String(strEnded).toLowerCase())
  }
  return status
}

export function included(arrValue,strIncluded){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!String(value).toLowerCase().includes(String(strIncluded).toLowerCase())){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = String(arrValue).toLowerCase().includes(String(strIncluded).toLowerCase())
  }
  return status
}


// to check length of the value is more than or equal to maxWord
export function maxWord(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.split(" ").length > parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.split(" ").length <= parseInt(length)
  }
  return status
}


// to check length of the value is more than or equal to minWord
export function minWord(arrValue,length){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(value.split(" ").length < parseInt(length)){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = arrValue.split(" ").length >= parseInt(length)
  }
  return status
}

// to check length of the value is more than or equal to maxNumber
export function maxNumber(arrValue,maxValue){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(Number(value) > maxValue){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = Number(arrValue) < maxValue
  }
  return status
}


// to check length of the value is more than or equal to minNumber
export function minNumber(arrValue,maxValue){
  var status = true
  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(Number(value) < maxValue){
        status = false
        return status
      }
    })
  }else if(arrValue !==""){
    status = Number(arrValue) < maxValue
  }
  return status
}

// to check passwords according to a specific pattern
export function password(arrValue){
  var status = true
  const re = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*.]{8,96}$/;

  if(Array.isArray(arrValue) && arrValue.length > 0){
    arrValue.forEach(value=>{
      if(!re.test(String(value))){
        status = re.test(String(value))
        return re.test(String(value))
      }
    })
  }else if(arrValue !==""){
    status = re.test(String(arrValue))
  }
  return status
}

export function customRule(validValue){
  if(typeof validValue ==="function"){
    return validValue()
  }else if(typeof validValue ==="boolean"){
    return validValue
  }else{
    return true
  }
}

//#endregion
