import React, { useEffect, useRef, useState } from "react";
import "./Card.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const Card = (props) => {
  const { sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  if(props?.[strTestProps]){
    if(props?.sx){
      console.log("Card", "sx",);
    }
  }
  const [haveBack, setHaveBack] = useState(true);
  const getID = useRef(id || componentsFunction.randomName("Card"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-card") });

  useEffect(() => {}, [props.children, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (props.children) {
      setHaveBack(props.children[1] ? true : false);
    }
  }, []);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-card");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <div
      id={getID.current}
      className={`cbmis-card-container ${componentsFunction.CheckDirectionItem(dir)} ${haveBack ? "cbmis-card-have-back" : ""}`}
      {...getRestProps()}
    >
      <div
        id={styleRef.current.strSXComponentID}
        className={`cbmis-card ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
          rest
        )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Card;
