import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";

const strPageInfo = "@src/client/component/public/surveymonkey/surveymonkey.ctrl.js";
const tblSurvey = generateQueries("tblSurvey");
const tblSurveyAnswers = generateQueries("tblSurveyAnswers");

export const CtrlSurveymonkey = ({ appState, appDispatch, isLoading, setIsLoading, surveyCode, bigAnswerID = null }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  // const bigSystemID = appState?.userInfo?.bigSystemID || appVariables.aaiot_System_ID;
  // localStorage.setItem("FID", appRouting?.surveymonkey?.featureID);

  const handler = {
    initData:
      ({ setState, state, navigate, fields, objAnswer }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          setIsLoading(true);
          //#region get initData
          const result = await tblSurvey(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: objTableAttributes?.tblSurvey?.full,
            objectCondition: {
              // bigSystemID: bigSystemID,
              strSurveyPath: surveyCode,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang], alertStyle);

            setIsLoading(false);
            // navigate(appRouting.page404?.url);
            return;
          }

          state.blnIsInitDone = true;
          state.objSurveyInfo = result?.response;

          //#endregion

          //#region set fields
          const newObjfields = {};
          for (let index = 0; index < (state.objSurveyInfo?.lstSurveyQus?.length || 0); index++) {
            const qus = state.objSurveyInfo?.lstSurveyQus[index];
            newObjfields[qus?.bigQID] = {
              name: qus?.bigQID,
              control: qus?.intQType === appVariables.objQuestionType?.writing ? "textarea" : "radio",
              value:
                typeof objAnswer?.lstSurveyQusAns?.[qus?.bigQID] === "boolean"
                  ? String(objAnswer?.lstSurveyQusAns?.[qus?.bigQID])
                  : String(objAnswer?.lstSurveyQusAns?.[qus?.bigQID] || ""),
              blnLanguage: false,
              validations: qus?.questionNo ? null : { required: qus?.required },
              options:
                qus?.intQType === appVariables.objQuestionType?.trueFalse
                  ? [
                      { key: "true", value: { eng: "Yes", arb: "نعم" } },
                      { key: "false", value: { eng: "No", arb: "لا" } },
                    ]
                  : qus?.lstOpt?.map((opt) => ({ key: opt?.intOptID, value: opt?.strOpt })),
            };
          }
          fields?.controller?.addListFields(newObjfields);
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    handleSubmit:
      ({ state, navigate, fields }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          for (let index = 0; index < state.objSurveyInfo?.lstSurveyQus.length; index++) {
            const qs = state.objSurveyInfo?.lstSurveyQus[index];
            if (!fields?.[qs?.bigQID]?.getValue() && qs?.questionNo && (fields?.[qs?.questionNo]?.getValue() && fields?.[qs?.questionNo]?.getValue()?.key !== "false" && fields?.[qs?.questionNo]?.getValue() !== "false")) {
              if (qs?.required) {
                fields?.[qs?.bigQID]?.setError(dictionary?.formValidationsMsg?.required);
              } else {
                fields?.[qs?.bigQID]?.setError("");
              }
            }else if(!fields?.[qs?.bigQID]?.getValue() && qs?.questionNo && (!fields?.[qs?.questionNo]?.getValue() || fields?.[qs?.questionNo]?.getValue()?.key === "false" || fields?.[qs?.questionNo]?.getValue() === "false")){
                fields?.[qs?.bigQID]?.setError("");
            }
          }
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);
          const objectInput = {
            bigAnswerID: bigAnswerID || appFunctions.getRandomNumber(10),
            bigSurveyID: state?.objSurveyInfo?.bigSurveyID,
            lstSurveyQusAns: {},
            blnIsConfirmed: fields?.blnIsConfirmed?.getValue()?.length ? true : false,
            dtmUpdatedDate: new Date()?.toISOString(),
          };

          for (let index = 0; index < state.objSurveyInfo?.lstSurveyQus.length; index++) {
            const qs = state.objSurveyInfo?.lstSurveyQus[index];
            if (qs?.intQType === appVariables?.objQuestionType?.writing) {
              objectInput.lstSurveyQusAns[qs?.bigQID] = fields?.[qs?.bigQID]?.getValue();
            } else if (qs?.intQType === appVariables?.objQuestionType?.trueFalse) {
              objectInput.lstSurveyQusAns[qs?.bigQID] = String(fields?.[qs?.bigQID]?.getValue()?.key) === "true" || String(fields?.[qs?.bigQID]?.getValue()) === "true";
            } else if (qs?.intQType === appVariables?.objQuestionType?.multipleChoice) {
              objectInput.lstSurveyQusAns[qs?.bigQID] = String(fields?.[qs?.bigQID]?.getValue()?.key || fields?.[qs?.bigQID]?.getValue());
            }
          }
          if (!bigAnswerID) {
            const result = await tblSurveyAnswers(strPageInfo, appState, "aaiotAppAdd", {
              objectInput: objectInput,
            });
            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");

              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              setIsLoading(false);
              return;
            }
          } else {
            const result = await tblSurveyAnswers(strPageInfo, appState, "aaiotAppUpdate", {
              objectInput: { ...objectInput, dtmUpdatedDate: new Date()?.toUTCString() },
              objectCondition: {
                bigAnswerID: objectInput?.bigAnswerID,
                bigSurveyID: objectInput?.bigSurveyID,
              },
            });
            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              setIsLoading(false);
              return;
            }
          }

          setIsLoading(false);

          fields?.controller?.resetForm();
          if (String(appState?.userInfo?.jsnUserInfo?.intUserRoleFK) === String(appVariables.objUserRole?.RootAdmin)) {
            navigate(appRouting?.MngSurvey?.url, { replace: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
          } else {
            navigate(appRouting?.surveyThank?.url, { replace: true });
            // Alert.viewAlert(dictionary?.shared?.alertFetchNote?.alertsurvey?.[lang], "success", appLangDirection?.[lang], alertStyle);
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
          setIsLoading(false);
        }
      },

    handelChange:
      ({ fields }) =>
      (qs) => {
        if (!fields?.[qs?.bigQID]?.getValue() && qs?.questionNo && (!fields?.[qs?.questionNo]?.getValue() || fields?.[qs?.questionNo]?.getValue()?.key !== "false")) {
          // if (qs?.required) {
          fields?.[qs?.bigQID]?.setValidation("required", true);
          // }
          // } else {
          // fields?.[qs?.bigQID]?.setValidation("required", false);
        }
      },
  };

  return handler;
};
