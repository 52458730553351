import { Alert } from "@cbmisorg/material";
import { aaiot_System_ID } from "../../../../configuration/appVariables";
import { generateQueries, objTableAttributes } from "../../../../graphql/schema/GenerateQuery";
import { appLangDirection, dictionary } from "../../../../util/appLanguage/dictionary";
import { alertStyle } from "../../../../util/appStyle";
import * as appFunctions from "../../../../util/appFunctions";

const strPageInfo = "@src/client/component/stakeholders/admin/manageMedia/ManageMedia.controller.js";

const tblActivity = generateQueries("tblActivity");

export const CtrlManageMedia = (appState, appDispatch, navigate = null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    getMedia:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "error", appLangDirection?.[lang], alertStyle);

            return;
          }
          setIsLoading(true);
          const result = await tblActivity(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes?.tblActivity?.full,
            objectCondition: { bigSystemID: bigSystemID, blnIsActive: true, blnIsDeleted: false, blnIsArchive: false, blnIsMedia: true },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "error", appLangDirection?.[lang], alertStyle);

            setIsLoading(false);
            return;
          }

          stateInfo.blnIsInitDone = true;
          let lstTmp = Array.isArray(result?.response) ? result?.response : [];

          stateInfo.listMedia = [...(lstTmp || [])]?.reverse();
          stateInfo.selectedMediaInfo = stateInfo?.listMedia?.[0];
          appDispatch();

          setIsLoading(false);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },

    addMedia:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo, uploaderVideo, uploaderVideosPoster, fieldVedio, fieldVedioBefor, fieldVedioAfter }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);
          const resultGetUrl = await tblActivity(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: ["bigActivityID"],
            objectCondition: {
              strUrlCode: fieldVedio?.strUrlCode?.getValue(),
              bigSystemID: bigSystemID,
            },
          });
          if (!resultGetUrl?.blnIsRequestSuccessful || resultGetUrl?.response?.length > 1) {
            appFunctions.logMessage(resultGetUrl?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.videoUploadFileURL?.[lang], "error", appLangDirection?.[lang], alertStyle);

            setIsLoading(false);
            return;
          }

          if (stateInfo.lstVideos?.length) {
            const resultUploadVideo = await uploaderVideo?.uploadHandler("trainingsVideos");
            if (!resultUploadVideo) {
              setIsLoading(false);
              return;
            }
          } else if (!stateInfo.lstVideos?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.uploadedVideo?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          if(stateInfo.lstVideosPoster?.length){
            const resultUploadVideoPoster = await uploaderVideosPoster?.uploadHandler("trainingsVideosPoster");
            if (!resultUploadVideoPoster) {
              setIsLoading(false);
              return;
            }
          }
          const result = await tblActivity(strPageInfo, appState, "aaiotAppAdd", {
            objectInput: {
              bigActivityID: appFunctions?.getRandomNumber(10),
              bigSystemID: bigSystemID,
              blnIsMedia: true,
              strUrlCode: fieldVedio?.strUrlCode?.getValue(),
              jsnInfo: {
                title: fieldVedio?.strTitle?.getValue(),
                objCardBeforMedia: {
                  blnIsViw: fieldVedioBefor?.blnIsViwBefor?.getValue(),
                  title: fieldVedioBefor?.strTitle?.getValue(),
                  subtitle: fieldVedioBefor?.strSubtitle?.getValue(),
                  strDscrp: fieldVedioBefor?.strDscrp?.getValue(),
                  strDscrp2: fieldVedioBefor?.strDscrp2?.getValue(),
                  lstPoints: stateInfo?.objPointSelectedBeforPoint,
                  caption: fieldVedioBefor?.caption?.getValue(),
                  mediaBtn: {
                    blnIsViw: fieldVedioBefor?.isViewMediaBtn?.getValue(),
                    label: fieldVedioBefor?.mediaBtn?.getValue(),
                  },
                  forumBtn: {
                    blnIsViw: fieldVedioBefor?.isForumBtn?.getValue(),
                    label: fieldVedioBefor?.strForumBtn?.getValue(),
                    strForumKey: fieldVedioBefor?.strForumKey?.getValue(),
                  },
                },
                lstVideos: stateInfo?.lstVideos || [],
                lstVideosPoster:stateInfo?.lstVideosPoster || [],
                objCardAfterMedia: {
                  blnIsViw: fieldVedioAfter?.blnIsViwAfter?.getValue(),
                  title: fieldVedioAfter?.strTitle?.getValue(),
                  subtitle: fieldVedioAfter?.strSubtitle?.getValue(),
                  strDscrp: fieldVedioAfter?.strDscrp?.getValue(),
                  strDscrp2: fieldVedioAfter?.strDscrp2?.getValue(),
                  lstPoints: stateInfo?.objPointSelectedAfterPoint,
                  caption: fieldVedioAfter?.caption?.getValue(),
                  mediaBtn: {
                    blnIsViw: fieldVedioAfter?.isViewMediaBtn?.getValue(),
                    label: fieldVedioAfter?.mediaBtn?.getValue(),
                  },
                  forumBtn: {
                    blnIsViw: fieldVedioAfter?.isForumBtn?.getValue(),
                    label: fieldVedioAfter?.strForumBtn?.getValue(),
                    strForumKey: fieldVedioAfter?.strForumKey?.getValue(),
                  },
                },
                lstWhoRegister: (fieldVedio?.lstReg?.getValue() || [])?.map(item=>item?.key),
              },
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "error", appLangDirection?.[lang], alertStyle);

            setIsLoading(false);
            return;
          }

          stateInfo.listMedia = [result?.response, ...stateInfo?.listMedia];
          appDispatch();

          setIsLoading(false);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },
    updateMedia:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo, uploaderVideo, uploaderVideosPoster, fieldVedio, fieldVedioBefor, fieldVedioAfter }) =>
      async () => {
        try {
          if (!fieldVedio?.controller?.isFormValid() || !fieldVedioBefor?.controller?.isFormValid() || !fieldVedioAfter?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);

          if (fieldVedio?.strUrlCode?.getValue() !== stateInfo?.selectedMediaInfo?.strUrlCode) {
            const resultGetUrl = await tblActivity(strPageInfo, appState, "aaiotAppFindAll", {
              arrAttributes: ["bigActivityID"],
              objectCondition: {
                strUrlCode: fieldVedio?.strUrlCode?.getValue(),
                bigSystemID: bigSystemID,
              },
            });
            if (!resultGetUrl?.blnIsRequestSuccessful || resultGetUrl?.response?.length > 1) {
              appFunctions.logMessage(resultGetUrl?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.videoUploadFileURL?.[lang], "error", appLangDirection?.[lang], alertStyle);

              setIsLoading(false);
              return;
            }
          }

          if (stateInfo.lstVideos?.length) {
            const resultUploadVideo = await uploaderVideo?.uploadHandler("trainingsVideos");
            if (!resultUploadVideo) {
              setIsLoading(false);
              return;
            }
          } else if (!stateInfo.lstVideos?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.uploadedVideo?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          if(stateInfo.lstVideosPoster?.length){
            const resultUploadVideoPoster = await uploaderVideosPoster?.uploadHandler("trainingsVideosPoster");
            if (!resultUploadVideoPoster) {
              setIsLoading(false);
              return;
            }
          }
          const result = await tblActivity(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              jsnInfo: {
                title: fieldVedio?.strTitle?.getValue(),
                objCardBeforMedia: {
                  blnIsViw: fieldVedioBefor?.blnIsViwBefor?.getValue(),
                  title: fieldVedioBefor?.strTitle?.getValue(),
                  subtitle: fieldVedioBefor?.strSubtitle?.getValue(),
                  strDscrp: fieldVedioBefor?.strDscrp?.getValue(),
                  strDscrp2: fieldVedioBefor?.strDscrp2?.getValue(),
                  lstPoints: stateInfo?.objPointSelectedBeforPoint,
                  caption: fieldVedioBefor?.caption?.getValue(),
                  mediaBtn: {
                    blnIsViw: fieldVedioBefor?.isViewMediaBtn?.getValue(),
                    label: fieldVedioBefor?.mediaBtn?.getValue(),
                  },
                  forumBtn: {
                    blnIsViw: fieldVedioBefor?.isForumBtn?.getValue(),
                    label: fieldVedioBefor?.strForumBtn?.getValue(),
                    strForumKey: fieldVedioBefor?.strForumKey?.getValue(),
                  },
                },
                lstVideos: stateInfo.lstVideos,
                lstVideosPoster:stateInfo?.lstVideosPoster || [],
                // lstVideos: [...stateInfo.lstVideos, { path: "https://cbmiswebsite-public.s3.eu-central-1.amazonaws.com/aaiot/Kuwait.mp4" }],
                objCardAfterMedia: {
                  blnIsViw: fieldVedioAfter?.blnIsViwAfter?.getValue(),
                  title: fieldVedioAfter?.strTitle?.getValue(),
                  subtitle: fieldVedioAfter?.strSubtitle?.getValue(),
                  strDscrp: fieldVedioAfter?.strDscrp?.getValue(),
                  strDscrp2: fieldVedioAfter?.strDscrp2?.getValue(),
                  lstPoints: stateInfo?.objPointSelectedAfterPoint,
                  caption: fieldVedioAfter?.caption?.getValue(),
                  mediaBtn: {
                    blnIsViw: fieldVedioAfter?.isViewMediaBtn?.getValue(),
                    label: fieldVedioAfter?.mediaBtn?.getValue(),
                  },
                  forumBtn: {
                    blnIsViw: fieldVedioAfter?.isForumBtn?.getValue(),
                    label: fieldVedioAfter?.strForumBtn?.getValue(),
                    strForumKey: fieldVedioAfter?.strForumKey?.getValue(),
                  },
                },
                lstWhoRegister: (fieldVedio?.lstReg?.getValue() || [])?.map(item=>item?.key),
              },
              dtmUpdatedDate: new Date(),
            },
            objectCondition: {
              bigActivityID: stateInfo?.selectedMediaInfo?.bigActivityID,
              bigSystemID: bigSystemID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "error", appLangDirection?.[lang], alertStyle);

            setIsLoading(false);
            return;
          }

          appDispatch();
          setIsLoading(false);
          setStateInfo({ ...stateInfo });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },
    openModale:
      ({ stateInfo, setStateInfo, fieldVedio, fieldVedioBefor, fieldVedioAfter }) =>
      (selectedMediaInfo = null) =>
      () => {
        if (selectedMediaInfo) {
          fieldVedio?.strUrlCode?.setValue(selectedMediaInfo?.strUrlCode);
          fieldVedio?.strTitle?.setValue(selectedMediaInfo?.jsnInfo?.title);
          fieldVedio?.lstReg?.setValue(selectedMediaInfo?.jsnInfo?.lstWhoRegister)

          fieldVedioBefor?.blnIsViwBefor?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.blnIsViw);
          fieldVedioBefor?.strTitle?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.title);
          fieldVedioBefor?.strSubtitle?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.subtitle);
          fieldVedioBefor?.strDscrp?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.strDscrp);
          fieldVedioBefor?.strDscrp2?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.strDscrp2);
          fieldVedioBefor?.caption?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.caption);
          fieldVedioBefor?.isViewMediaBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.mediaBtn?.blnIsViw);
          fieldVedioBefor?.mediaBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.mediaBtn?.label);
          fieldVedioBefor?.isForumBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.forumBtn?.blnIsViw);
          fieldVedioBefor?.strForumBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.forumBtn?.label);
          fieldVedioBefor?.strForumKey?.setValue(selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.forumBtn?.strForumKey);

          fieldVedioAfter?.blnIsViwAfter?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.blnIsViw);
          fieldVedioAfter?.strTitle?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.title);
          fieldVedioAfter?.strSubtitle?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.subtitle);
          fieldVedioAfter?.strDscrp?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.strDscrp);
          fieldVedioAfter?.strDscrp2?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.strDscrp2);
          fieldVedioAfter?.caption?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.caption);
          fieldVedioAfter?.isViewMediaBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.mediaBtn?.blnIsViw);
          fieldVedioAfter?.mediaBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.mediaBtn?.label);
          fieldVedioAfter?.isForumBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.forumBtn?.blnIsViw);
          fieldVedioAfter?.strForumBtn?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.forumBtn?.label);
          fieldVedioAfter?.strForumKey?.setValue(selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.forumBtn?.strForumKey);

          stateInfo.objPointSelectedBeforPoint = selectedMediaInfo?.jsnInfo?.objCardBeforMedia?.lstPoints;
          stateInfo.objPointSelectedAfterPoint = selectedMediaInfo?.jsnInfo?.objCardAfterMedia?.lstPoints;
          stateInfo.lstVideos = selectedMediaInfo?.jsnInfo?.lstVideos;
          stateInfo.lstVideosPoster = selectedMediaInfo?.jsnInfo?.lstVideosPoster;

        } else {
          fieldVedio?.controller?.resetForm();
          fieldVedioBefor?.controller?.resetForm();
          fieldVedioAfter?.controller?.resetForm();
          stateInfo.objPointSelectedBeforPoint = [];
          stateInfo.objPointSelectedAfterPoint = [];
        }
        setStateInfo({ ...stateInfo, blnOpenModal: !stateInfo?.blnOpenModal, selectedMediaInfo: selectedMediaInfo });
      },
    openPointModal:
      ({ stateInfo, setStateInfo, fieldPoints }) =>
      (type, objPoint = null, intPointSelectedIndex = -1) =>
      () => {
        if (objPoint) {
          let newObjValue = { eng: "", arb: "" };
          objPoint?.lstPoint.forEach((element, index) => {
            if (index === 0) {
              newObjValue.eng = element.eng;
              newObjValue.arb = element.arb;
            } else {
              newObjValue.eng = newObjValue?.eng + "/n" + element.eng;
              newObjValue.arb = newObjValue?.arb + "/n" + element.arb;
            }
          });
          fieldPoints?.strTitle?.setValue(objPoint?.title);
          fieldPoints?.lstPoints?.setValue(newObjValue);
        } else {
          fieldPoints?.controller?.resetForm();
        }
        setStateInfo({
          ...stateInfo,
          blnOpenPointModal: !stateInfo?.blnOpenPointModal,
          strPointTypeSelected: type,
          objPointSelected: objPoint,
          intPointSelectedIndex: intPointSelectedIndex,
        });
      },

    openSavePointModal:
      ({ stateInfo, setStateInfo, fieldPoints }) =>
      () => {
        const lstArbPoint = fieldPoints?.lstPoints?.getValue()?.arb?.split("\n");
        const lstEngPoint = fieldPoints?.lstPoints?.getValue()?.eng?.split("\n");

        const lstPoint = lstArbPoint.map((point, index) => {
          return { arb: point, eng: lstEngPoint[index] };
        });

        const objPoint = {
          title: fieldPoints?.strTitle?.getValue(),
          lstPoint: lstPoint,
        };
        if (stateInfo?.strPointTypeSelected === "before") {
          if(!stateInfo?.objPointSelectedBeforPoint){
            stateInfo.objPointSelectedBeforPoint =[]
          }
          if (stateInfo?.intPointSelectedIndex === -1) {
            stateInfo?.objPointSelectedBeforPoint?.push(objPoint);
          } else {
            stateInfo.objPointSelectedBeforPoint[stateInfo?.intPointSelectedIndex] = objPoint;
          }
        } else if (stateInfo?.strPointTypeSelected === "after") {
          
          if(!stateInfo?.objPointSelectedAfterPoint){
            stateInfo.objPointSelectedAfterPoint =[]
          }
          if (stateInfo?.intPointSelectedIndex === -1) {
            stateInfo?.objPointSelectedAfterPoint?.push(objPoint);
          } else {
            stateInfo.objPointSelectedAfterPoint[stateInfo?.intPointSelectedIndex] = objPoint;
          }
        }        
        setStateInfo({ ...stateInfo, blnOpenPointModal: !stateInfo?.blnOpenPointModal, strPointTypeSelected: "", objPointSelected: null, intPointSelectedIndex: -1 });
      },
    delateVideoConfirmation:
      ({}) =>
      () => {},
  };

  return handler;
};


// jsnInfo: {
//   title: { eng: "Digital Empowerment in Kuwait", arb: "التمكين الرقمي في الكويت" },
//   objCardBeforMedia: {
//     blnIsViw: true,
//     title: { eng: "Digital Empowerment and Artificial Intelligence Forum", arb: "منتدى التمكين الرقمي والذكاء الإصطناعي" },
//     subtitle: { eng: "Previous Forum", arb: "المنتدى السابق" },
//     strDscrp: {
//       eng: "This forum, sponsored by the Central Agency for Information Technology, is dedicated to decision-makers, officials, and administrators in the government sector of Kuwait. Dr. Amjad Al-Kilani provided comprehensive insights to empower attendees to lead and manage a comprehensive national strategy for digital empowerment, without the need for technical specialization. The presentations covered the following topics:",
//       arb: "تم تخصيص هذا المنتدى الذي تم برعاية من الجهاز المركزي لتكنولوجيا المعلومات لصناع القرار والمسؤولين والإداريين في القطاع الحكومي بدولة الكويت، حيث قدم الدكتور أمجد الكيلاني رؤى شاملة لتمكين الحضور من قيادة وإدارة استراتيجية وطنية متكاملة للتمكين الرقمي، دون الحاجة إلى التخصص التقني. تضمنت العروض التقديمية المحاور التالية:",
//     },
//     strDscrp2: "",
//     lstPoints: [
//       {
//         title: {
//           eng: "Clarifying the relationship between various technical disciplines and highlighting the essential skills needed by decision-makers, officials, and administrators, with the aim of providing a comprehensive understanding that enables them to lead and implement a national strategy for digital empowerment",
//           arb: "توضيح العلاقة بين التخصصات التقنية المختلفة وتسليط الضوء على المهارات الأساسية التي يحتاجها صناع القرار والمسؤولون والإداريون، بهدف تقديم فهم شامل يمكنهم من قيادة وتنفيذ استراتيجية وطنية شاملة للتمكين الرقمي.",
//         },
//         lstPoint: [],
//       },
//       {
//         title: {
//           eng: "How to leverage technology and digital transformation to train local competencies, improve electronic services, support the state budget, and enhance Kuwait's technological standing as a leading country in the region.",
//           arb: "كيفية توظيف التكنولوجيا والتحول الرقمي لتدريب الكفاءات المحليه، وتحسين الخدمات الالكترونية، ودعم ميزانية الدولة، وتعزيز مكانة الكويت التقنية كدولة رائدة في المنطقة.",
//         },
//         lstPoint: [],
//       },
//     ],
//     caption: { eng: "Kuwait – The St. Regis Hotel – October 21, 2024", arb: "دولة الكويت – فندق سانت ريجيس – 21 اكتوبر 2024" },
//     mediaBtn: {
//       blnIsViw: true,
//       label: { eng: "View the presentation", arb: "مشاهدة العرض التقديمي" },
//     },
//     forumBtn: {
//       blnIsViw: false,
//       label: { eng: "View the presentation", arb: "مشاهدة العرض التقديمي" },
//       strForumKey: "",
//     },
//   },
//   // lstMedia: [
//   //   {
//   //     cat: "",
//   //     dtm: "Oct 7, 24, 01:50:34",
//   //     lbl: "",
//   //     name: "opRiTOzHN2.mp4",
//   //     path: "aaiot/trainingsVideos/opRiTOzHN2.mp4",
//   //     bSize: 10406177,
//   //   },
//   // ],
//   lstVideos: [{ path: "https://cbmiswebsite-public.s3.eu-central-1.amazonaws.com/aaiot/Kuwait.mp4" }],
//   objCardAfterMedia: {
//     blnIsViw: true,
//     title: { eng: "Digital Empowerment Forum – Smart and Secure Application Development", arb: "منتدى التمكين الرقمي – صناعة التطبيقات الذكية والاَمنة" },
//     subtitle: { eng: "Next Forum", arb: "المنتدى القادم" },
//     caption: { eng: "Kuwait – The location and date will be announced soon", arb: "دولة الكويت – سيتم الإعلان عن الموقع والتاريخ قريبا" },
//     strDscrp: {
//       eng: "Target Audience: Project managers in technology, software engineers and developers, university professors and researchers in the fields of artificial intelligence and secure programming, students in technology programs, and entrepreneurs",
//       arb: "الفئات المستهدفة: مديري المشاريع التقنية، مهندسي ومطوري البرمجيات، دكاترة الجامعات والباحثين في مجال الذكاء الإصطناعي والبرمجة الاَمنة، طلبة المشاريع التقنية و رواد الأعمال",
//     },
//     strDscrp2: {
//       eng: "As part of the activities of the Digital Empowerment and Secure Application Development Forum, Dr. Amjad Kallani will cover the following topics through a series of seminars and workshops:",
//       arb: "من ضمن فعاليات منتدى التمكين الرقمي وصناعة التطبيقات الاَمنة سيغطي الدكتور أمجد الكيلاني ومن خلال سلسلة من الندوات وورش العمل المحاور التالية:",
//     },
//     lstPoints: [
//       {
//         title: {
//           eng: "Workshop on the Latest Technologies for Efficient and Effective Development of Smart and Secure Government Applications",
//           arb: "ورشة عمل حول أحدث التقنيات لتطوير تطبيقات حكومية ذكية واَمنة بكفاءة وفاعلية",
//         },
//         lstPoint: [
//           {
//             eng: "Highlighting the management and technical skills, as well as the latest global technologies that contribute to enhancing sustainable digital empowerment",
//             arb: "تسليط الضوء على المهارات الإدارية والتقنية وأحدث التقنيات العالمية التي تساهم في تعزيز التمكين الرقمي المستدام",
//           },
//           {
//             eng: "An introduction to a range of platforms and software tools that should be adopted for developing large-scale applications with minimal time and cost, along with successful examples of applying these tools to accelerate digital transformation",
//             arb: "التعريف في مجموعة من المنصات والأدوات البرمجية التي يجب اعتمادها لتطوير التطبيقات الضخمة بأقل وقت وتكلفة، مع استعراض أمثلة ناجحة لتطبيق هذه الأدوات من أجل تسريع التحول الرقمي",
//           },
//           {
//             eng: "Clarifying the mechanisms for adopting secure programming as a fundamental pillar for data protection and addressing most security threats",
//             arb: "وضيح آليات تبني البرمجة الآمنة كركيزة أساسية لحماية البيانات والتصدي لأغلب التهديدات الأمنية",
//           },
//         ],
//       },
//       {
//         title: {
//           eng: "Workshop on the Importance of Artificial Intelligence and How to Invest It in Advancing All Areas of Scientific Research",
//           arb: "ورشة عمل حول أهمية الذكاء الإصطناعي وكيفية إستثماره في تطوير كافة مجالات البحث العلمي",
//         },
//         lstPoint: [
//           {
//             eng: "Artificial Intelligence Applications: Fundamentals, Implementation Mechanisms, and Challenges",
//             arb: "تطبيقات الذكاء الإصطناعي: الأساسيات, اَليات التطبيق والتحديات",
//           },
//           {
//             eng: "Presenting a comprehensive framework for successfully adopting artificial intelligence and ensuring maximum benefits across various fields",
//             arb: "تقديم إطار عمل متكامل لتبني الذكاء الاصطناعي بنجاح وضمان تحقيق أقصى استفادة منه في مختلف المجالات",
//           },
//         ],
//       },
//       {
//         title: {
//           eng: "Workshop on Entrepreneurship and How to Transform Innovative Ideas into Profitable Applications",
//           arb: "ورشة عمل حول ريادة الأعمال وكيفية تحويل الأفكار الإبتكارية الى تطبيقات ربحية",
//         },
//         lstPoint: [
//           {
//             eng: "Discussing the challenges faced by software developers, how to overcome them, and how to transform entrepreneurial ideas into profitable applications",
//             arb: "مناقشة التحديات التي يواجها مطوري البرمجيات وكيفية التغلب عليها وكيفية تحويل الأفكار الرياديه الى تطبيقات ربحية",
//           },
//         ],
//       },
//     ],
//     mediaBtn: {
//       blnIsViw: false,
//       label: { eng: "View the presentation", arb: "مشاهدة العرض التقديمي" },
//     },
//     forumBtn: {
//       blnIsViw: true,
//       label: { eng: "Register for the forum", arb: "سجل في المنتدى" },
//       strForumKey: "aiForum",
//     },
//   },
// },