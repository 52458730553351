import React, { useEffect, useState, Fragment, useRef } from "react";
import "./Stepper.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import StepperHead from "./StepperHead";
import { setSxClassName } from "../../../helper/convertJsCss";

function Stepper(props) {
  const { mode, stepperContent, currentTab, completedColor, activeColor, stepClicked, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Stepper"));
  const styleRef = useRef({ strSxClassName: "" });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [stepperContentArr, setStepperContentArr] = useState([]);

  const navigateToStepHandler = (index) => {
    if (index !== currentTabIndex) {
      setCurrentTabIndex(index);
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [mode, stepperContent, currentTab, completedColor, stepClicked, activeColor, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (currentTab && parseInt(currentTab) > 1) {
      setCurrentTabIndex(parseInt(currentTab) - 1);
    } else {
      setCurrentTabIndex(0);
    }
  }, [currentTab]);

  useEffect(() => {
    if (stepperContent) {
      setStepperContentArr(stepperContent || []);
    }
  }, [stepperContent]);

  useEffect(() => {
    document.documentElement.style.setProperty("--cbmis-active-color", componentsFunction.checkColorReturnValue(activeColor || "var(--cbmis-gary)"));
  }, [styleRef.current.strSxClassName]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-stepper-wrapper");
    }
  }, [sx, screenWidth]);

  return (
    <div
      id={getID.current}
      className={`cbmis-stepper-wrapper ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)}`}
      {...getRestProps()}
    >
      <div style={{ display: String(mode).toLowerCase() === "isvertical" ? "flex" : "block" }}>
        <StepperHead
          stepperContent={stepperContentArr}
          navigateToStepHandler={navigateToStepHandler}
          mode={mode}
          currentTabIndex={currentTabIndex}
          completedColor={completedColor || "var(--cbmis-primary)"}
          sx={sx}
          className={className}
          stepClicked={stepClicked}
        />
        <div className="cbmis-stepper-body">
          {stepperContentArr.map((el, i) => (
            <Fragment key={i}>{i === currentTabIndex && el.content}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Stepper;
