import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { generateQueries, objTableAttributes } from "../../../../appHelper/appQuery/GenerateQuery";
import * as appVariables from "../../../../appHelper/appVariables";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";

const tblPartnershipTrack = generateQueries("tblPartnershipTrack");
const tblSystem = generateQueries("tblSystem");

const strPageInfo = "@src/components/stakeholders/shared/mngPotentialSponsorship/mngPotentialSponsorship.controller.js";

export default function mngPotentialSponsorshipCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ state, setState, isLoading, setIsLoading, strTrainingType }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDataDone = true;
          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region get data partnership track from DB

          const result = await tblPartnershipTrack(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes.tblPartnershipTrack.full,
            objectCondition: {},
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          const lstTracking = Array.isArray(result?.response) ? result?.response : [];
          const lstTrackingTmp = [];

          let intFollowUpNum = 0;
          let intApprovedNum = 0;
          let intRejectedNum = 0;
          for (let i = 0; i < lstTracking.length; i++) {
            if (
              strTrainingType === appVariables?.objTrainingToSupport?.manager?.key &&
              lstTracking?.[i]?.jsnPartnershipTrackInfo?.strTrainingType === appVariables?.objTrainingToSupport?.manager?.key
            ) {
              lstTrackingTmp.push(lstTracking?.[i]);

              if (
                String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Submission) ||
                String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Follow_Up)
              ) {
                ++intFollowUpNum;
              }
              if (String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Approved)) {
                ++intApprovedNum;
              }
              if (String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Rejected)) {
                ++intRejectedNum;
              }
            } else if (
              strTrainingType === appVariables?.objTrainingToSupport?.fullstack?.key &&
              lstTracking?.[i]?.jsnPartnershipTrackInfo?.strTrainingType === appVariables?.objTrainingToSupport?.fullstack?.key
            ) {
              lstTrackingTmp.push(lstTracking?.[i]);
              if (
                String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Submission) ||
                String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Follow_Up)
              ) {
                ++intFollowUpNum;
              }

              if (String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Approved)) {
                ++intApprovedNum;
              }
              if (String(lstTracking?.[i]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Rejected)) {
                ++intRejectedNum;
              }
            }
          }

          state.lstTracking = lstTrackingTmp?.reverse();

          state.intFollowUpNum = intFollowUpNum;
          state.intApprovedNum = intApprovedNum;
          state.intRejectedNum = intRejectedNum;
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region get data system from DB
          const resultSystem = await tblSystem(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: ["jsnSponsorCatalogue"],
            objectCondition: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
            },
          });
          if (!resultSystem?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          const lstSuggested = Array.isArray(resultSystem?.response?.jsnSponsorCatalogue?.list) ? resultSystem?.response?.jsnSponsorCatalogue?.list : [];
          let intSuggestedNum = 0;
          let lstTrainingSuggested = [];
          for (let i = 0; i < lstSuggested.length; i++) {
            if (!(lstSuggested?.[i]?.lstTrainingType && Array.isArray(lstSuggested?.[i]?.lstTrainingType) && lstSuggested?.[i]?.lstTrainingType?.includes(strTrainingType))) {
              ++intSuggestedNum;
            }
          }
          state.intSuggestedNum = intSuggestedNum;
          state.lstTrainingSuggested = lstTrainingSuggested;
          //#endregion

          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-initData");
          setIsLoading(false);
        }
      },

    sortListResult:
      ({ state, fldSearch }) =>
      (objTracking) => {
        try {
          if (
            String(state.bigCurrentTab) === String(appVariables?.objPotentialSponsorshipStatus?.Follow_Up) &&
            String(appVariables?.objPotentialSponsorshipStatus?.Follow_Up) !== String(objTracking?.bigTrackStatusID) &&
            String(appVariables?.objPotentialSponsorshipStatus?.Submission) !== String(objTracking?.bigTrackStatusID)
          ) {
            return false;
          }
          if (
            String(state.bigCurrentTab) === String(appVariables?.objPotentialSponsorshipStatus?.Approved) &&
            String(appVariables?.objPotentialSponsorshipStatus?.Approved) !== String(objTracking?.bigTrackStatusID) &&
            String(appVariables?.objPotentialSponsorshipStatus?.Rejected) !== String(objTracking?.bigTrackStatusID)
          ) {
            return false;
          }

          let blnIsMatch = true;

          const bigSearchSponsorshipStatus = fldSearch?.bigSearchSponsorshipStatus?.getValue()?.key;
          if (bigSearchSponsorshipStatus && bigSearchSponsorshipStatus !== "all") {
            if (String(objTracking?.bigTrackStatusID) === String(bigSearchSponsorshipStatus)) {
              blnIsMatch &&= true;
            } else {
              blnIsMatch &&= false;
            }
          }

          const jsnPartnershipTrackInfo = objTracking?.jsnPartnershipTrackInfo;

          const bigSearchWorkSector = fldSearch?.bigSearchWorkSector?.getValue()?.key;
          if (bigSearchWorkSector && bigSearchWorkSector !== "all") {
            if (String(jsnPartnershipTrackInfo?.bigWorkSector) === String(bigSearchWorkSector)) {
              blnIsMatch &&= true;
            } else {
              blnIsMatch &&= false;
            }
          }

          const intSearchByAddedDay = fldSearch?.intSearchByAddedDay?.getValue()?.key;
          if (intSearchByAddedDay && intSearchByAddedDay !== "withoutSpecificTime") {
            let intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(objTracking?.dtmCreatedDate, new Date().toUTCString(), "day"));
            if (intSearchByAddedDay === "beforeWeek" && intAddedBefore <= 7) {
              blnIsMatch &&= true;
            } else if (intSearchByAddedDay === "before2Week" && intAddedBefore <= 14) {
              blnIsMatch &&= true;
            } else if (intSearchByAddedDay === "beforeMonth" && intAddedBefore <= 30) {
              blnIsMatch &&= true;
            } else {
              blnIsMatch &&= false;
            }
          }

          const strSearch = fldSearch?.strSearch?.getValue();
          if (strSearch) {
            if (String(jsnPartnershipTrackInfo?.strSponsorName)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatch &&= true;
            } else if (String(jsnPartnershipTrackInfo?.strSponsorEmail)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatch &&= true;
            } else if (String(jsnPartnershipTrackInfo?.strSponsorPhone)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatch &&= true;
            } else if (String(jsnPartnershipTrackInfo?.strFullName)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatch &&= true;
            } else if (String(jsnPartnershipTrackInfo?.strUserEmail)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatch &&= true;
            } else if (String(jsnPartnershipTrackInfo?.strUserPhone)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatch &&= true;
            } else {
              blnIsMatch &&= false;
            }
          }

          return blnIsMatch;
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-sortListResult");
          return true;
        }
      },

    //For now disabled
    openLogDialog:
      ({ state, setState }) =>
      (objHisTrack) =>
      () => {
        try {
          state.blnOpenTrackLogModal = true;
          state.objHisTrack = objHisTrack;

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openLogDialog");
        }
      },

    openEditDialog:
      ({ state, setState, fields }) =>
      (objTracking, intIndex, blnIsEdit, blnRestNote) =>
      () => {
        try {
          state.blnOpenEditModal = true;
          state.intIndexTrackEdit = intIndex;
          state.blnIsEditTrackModal = blnIsEdit;

          fields.controller.resetForm();
          fields.addNote.setValue(["true"]);

          if (blnIsEdit) {
            fields.strSponsorName.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorName);
            fields.strSponsorEmail.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorEmail);
            fields.strSponsorPhone.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone);
            fields.strFullNameRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strFullName);
            fields.strUserEmailRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strUserEmail);
            fields.strUserPhoneRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strUserPhone);
            fields.bigWorkSector.setValue(objTracking?.jsnPartnershipTrackInfo?.bigWorkSector);
            fields.strURL.setValue(objTracking?.jsnPartnershipTrackInfo?.strURL);
            fields.strNotes.setValue(objTracking?.jsnPartnershipTrackInfo?.strNotes);
            fields.dtmAlert.setValue(objTableAttributes?.jsnPartnershipTrackInfo?.dtmAlert);
          }
          if (blnRestNote) {
            fields.strNotes.setValue("");
            fields.dtmAlert.setValue("");
          }

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openEditDialog");
        }
      },

    openDetailDialog:
      ({ state, setState, fields }) =>
      (objTracking, intIndex) =>
      () => {
        try {
          if (objTracking?.bigTrackStatusID === appVariables?.objPotentialSponsorshipStatus?.Approved || objTracking?.bigTrackStatusID === appVariables?.objPotentialSponsorshipStatus?.Rejected) {
            state.blnClosedModal = true;
            state.intIndexTrackEdit = intIndex;
          } else {
            state.blnOpenDetail = true;
            state.objDetailTrack = objTracking;
            state.intIndexTrackDetail = intIndex;
          }

          fields.controller.resetForm();
          fields.addNote.setValue(["true"]);

          fields.strSponsorName.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorName);
          fields.strSponsorEmail.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorEmail);
          fields.strSponsorPhone.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone);
          fields.strFullNameRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strFullName);
          fields.strUserEmailRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strUserEmail);
          fields.strUserPhoneRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strUserPhone);
          fields.bigWorkSector.setValue(objTracking?.jsnPartnershipTrackInfo?.bigWorkSector);
          fields.strURL.setValue(objTracking?.jsnPartnershipTrackInfo?.strURL);
          fields.strNotes.setValue(objTracking?.jsnPartnershipTrackInfo?.strNotes);
          fields.dtmAlert.setValue(objTableAttributes?.jsnPartnershipTrackInfo?.dtmAlert);

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openEditDialog");
        }
      },

    addToDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType }) =>
      async () => {
        try {
          if (fields?.strURL?.getValue() && !appFunctions.isValidUrl(fields?.strURL?.getValue())) {
            fields?.strURL?.controller?.setError("strURL", dictionary?.shared?.formValidationsMsg?.url);
            return;
          }
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add data DB

          const bigTrackID = appFunctions.generateID(10);

          const result = await tblPartnershipTrack(strPageInfo, appState, "aaiotAppAdd", {
            objectInput: {
              bigAppID: appState?.clientInfo?.bigAppID,
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
              bigTrackID: bigTrackID,
              bigTrackStatusID: appVariables.objPotentialSponsorshipStatus?.Submission,
              blnAAIOTContact: true,
              intUpdateStatusNumber: 1,
              jsnPartnershipTrackInfo: {
                strSponsorName: fields.strSponsorName.getValue(),
                strSponsorEmail: fields.strSponsorEmail.getValue(),
                strSponsorPhone: fields.strSponsorPhone.getValue(),
                strFullName: fields.strFullNameRecipient.getValue(),
                strUserEmail: fields.strUserEmailRecipient.getValue(),
                strUserPhone: fields.strUserPhoneRecipient.getValue(),
                bigWorkSector: fields.bigWorkSector.getValue()?.key,
                strURL: fields.strURL.getValue(),
                strTrainingType: strTrainingType,
                blnHasAlert: fields.dtmAlert.getValue() ? true : false,
              },
              jsnTrackingHistory: [
                {
                  bigTrackStatusID: appVariables.objPotentialSponsorshipStatus?.Submission,
                  intUpdateStatusNumber: 1,
                  strNotes: fields.strNotes.getValue(),
                  dtmAlert: fields.dtmAlert.getValue() ? new Date(fields.dtmAlert.getValue()).toUTCString() : "",
                  dtmCreatedDate: new Date().toUTCString(),
                  dtmUpdatedDate: new Date().toUTCString(),
                },
              ],
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update cache
          state.lstTracking?.push({ ...result?.response });
          ++state.intFollowUpNum;
          state.blnOpenEditModal = false;

          setState({ ...state });
          setIsLoading(false);
          Alert.viewAlert(dictionary.shared.alertFetchNote.added_Successfully?.[lang], "success");
          //#endregion
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-addToDB");
        }
      },

    confirmAddToDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType }) =>
      () => {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }

        Confirmation.viewConfirmation(
          dictionary.components.mngPotentialSponsorship.confirmAddNew?.[lang],
          handler.addToDB({ state, setState, isLoading, setIsLoading, fields, strTrainingType }),
          () => null,
          dictionary.shared.confirmationMsg.yesSure?.[lang],
          dictionary.shared.confirmationMsg.no?.[lang]
        );
      },

    updateToDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType }) =>
      (bigPotentialPartnerState, blnIsUpdateOnly = false) =>
      async () => {
        try {
          if(fields?.strURL?.getValue() && !appFunctions.isValidUrl(fields?.strURL?.getValue())){
            fields?.strURL?.controller?.setError("strURL", dictionary?.shared?.formValidationsMsg?.url)
            return;
          }
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region change counters
          let intFollowUpNum = state.intFollowUpNum;
          let intApprovedNum = state.intApprovedNum;
          let intRejectedNum = state.intRejectedNum;

          if (
            String(state?.lstTracking?.[state?.intIndexTrackEdit]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Submission) ||
            String(state?.lstTracking?.[state?.intIndexTrackEdit]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Follow_Up)
          ) {
            --intFollowUpNum;
          }
          if (String(state?.lstTracking?.[state?.intIndexTrackEdit]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Approved)) {
            --intApprovedNum;
          }
          if (String(state?.lstTracking?.[state?.intIndexTrackEdit]?.bigTrackStatusID) === String(appVariables.objPotentialSponsorshipStatus?.Rejected)) {
            --intRejectedNum;
          }
          if (
            String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus?.Submission) ||
            String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus?.Follow_Up)
          ) {
            ++intFollowUpNum;
          }

          if (String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus?.Approved)) {
            ++intApprovedNum;
          }
          if (String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus?.Rejected)) {
            ++intRejectedNum;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update data DB

          if (!blnIsUpdateOnly) {
            if (String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) === String(bigPotentialPartnerState)) {
              ++state.lstTracking[state.intIndexTrackEdit].intUpdateStatusNumber;
            } else if (String(state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackStatusID) !== String(bigPotentialPartnerState)) {
              state.lstTracking[state.intIndexTrackEdit].intUpdateStatusNumber = 1;
            }
            state.lstTracking[state.intIndexTrackEdit].bigTrackStatusID = bigPotentialPartnerState;
          }

          let jsnTrackingHistory = Array.isArray(state?.lstTracking?.[state?.intIndexTrackEdit]?.jsnTrackingHistory) ? state?.lstTracking?.[state?.intIndexTrackEdit]?.jsnTrackingHistory : [];

          jsnTrackingHistory.push({
            bigTrackStatusID: state.lstTracking[state.intIndexTrackEdit].bigTrackStatusID,
            intUpdateStatusNumber: state.lstTracking[state.intIndexTrackEdit].intUpdateStatusNumber,
            strNotes: fields.strNotes.getValue(),
            dtmAlert: fields.dtmAlert.getValue() ? new Date(fields.dtmAlert.getValue()).toUTCString() : "",
            dtmCreatedDate: new Date().toUTCString(),
            dtmUpdatedDate: new Date().toUTCString(),
          });

          const result = await tblPartnershipTrack(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigTrackID: state.lstTracking?.[state.intIndexTrackEdit]?.bigTrackID,
            },
            objectInput: {
              bigTrackStatusID: state.lstTracking[state.intIndexTrackEdit].bigTrackStatusID,
              intUpdateStatusNumber: state.lstTracking[state.intIndexTrackEdit].intUpdateStatusNumber,
              jsnTrackingHistory: jsnTrackingHistory,
              jsnPartnershipTrackInfo: {
                ...state?.lstTracking?.[state?.intIndexTrackEdit]?.jsnPartnershipTrackInfo,
                blnHasAlert:
                  String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus.Approved) ||
                  String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus.Rejected)
                    ? false
                    : fields.dtmAlert.getValue()
                    ? true
                    : false,
              },
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update cache
          state.lstTracking[state.intIndexTrackEdit].jsnPartnershipTrackInfo.strNotes = fields.strNotes.getValue();
          state.lstTracking[state.intIndexTrackEdit].jsnPartnershipTrackInfo.dtmAlert = new Date(fields.dtmAlert.getValue()).toUTCString();
          state.lstTracking[state.intIndexTrackEdit].jsnTrackingHistory = jsnTrackingHistory;
          state.lstTracking[state?.intIndexTrackEdit].jsnPartnershipTrackInfo = {
            ...state?.lstTracking?.[state?.intIndexTrackEdit]?.jsnPartnershipTrackInfo,
            blnHasAlert:
              String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus.Approved) ||
              String(bigPotentialPartnerState) === String(appVariables.objPotentialSponsorshipStatus.Rejected)
                ? false
                : fields.dtmAlert.getValue()
                ? true
                : false,
          };
          state.intFollowUpNum = intFollowUpNum;
          state.intApprovedNum = intApprovedNum;
          state.intRejectedNum = intRejectedNum;
          state.blnClosedModal = false;
          state.blnOpenEditModal = false;
          state.blnOpenDetail = false;

          setState({ ...state });
          setIsLoading(false);
          Alert.viewAlert(dictionary.shared.alertFetchNote.updated_Successfully2?.[lang], "success");
          //#endregion
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-updateToDB");
        }
      },

    confirmUpdateToDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType }) =>
      (bigPotentialPartnerState, blnIsUpdateOnly, blnIsConfirmClosed = false) =>
      () => {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }

        Confirmation.viewConfirmation(
          blnIsConfirmClosed
            ? dictionary.components.mngPotentialSponsorship.confirmClosed?.[lang]
            : blnIsUpdateOnly
            ? dictionary.components.mngPotentialSponsorship.confirmUpdate?.[lang]
            : dictionary.components.mngPotentialSponsorship.confirmChangeStatus?.[lang],
          handler.updateToDB({ state, setState, isLoading, setIsLoading, fields, strTrainingType })(bigPotentialPartnerState, blnIsUpdateOnly),
          () => null,
          dictionary.shared.confirmationMsg.yesSure?.[lang],
          dictionary.shared.confirmationMsg.no?.[lang]
        );
      },

    getAddedBeforeLabel:
      ({ state }) =>
      (objTracking) => {
        try {
          const dtmCreatedDate = objTracking?.dtmCreatedDate;
          let intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "day"));

          let strLabel = "";
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.days?.[lang];
            if (intAddedBefore === 2) {
              strLabel = dictionary.components.mngPotentialSponsorship.twoDay?.[lang];
            } else if (intAddedBefore < 2) {
              strLabel = dictionary.components.mngPotentialSponsorship.day?.[lang];
            }
          }

          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "hour"));
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.hour?.[lang];
          }

          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          intAddedBefore = Math.floor(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "minute"));
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.minute?.[lang];
          }
          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          return `( ${dictionary.components.mngPotentialSponsorship?.now?.[lang]} )`;
        } catch {
          return "-";
        }
      },

    confirmClose:
      ({ state, setState, fields }) =>
      (intIndexTrackEdit) =>
      () => {
        const objTracking = state?.lstTracking?.[intIndexTrackEdit];
        // fields.controller.resetForm();
        fields.addNote.setValue(["true"]);

        fields.strSponsorName.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorName);
        fields.strSponsorEmail.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorEmail);
        fields.strSponsorPhone.setValue(objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone);
        fields.strFullNameRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strFullName);
        fields.strUserEmailRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strUserEmail);
        fields.strUserPhoneRecipient.setValue(objTracking?.jsnPartnershipTrackInfo?.strUserPhone);
        fields.bigWorkSector.setValue(objTracking?.jsnPartnershipTrackInfo?.bigWorkSector);
        fields.strURL.setValue(objTracking?.jsnPartnershipTrackInfo?.strURL);
        fields.strNotes.setValue(objTracking?.jsnPartnershipTrackInfo?.strNotes);
        fields.dtmAlert.setValue(objTableAttributes?.jsnPartnershipTrackInfo?.dtmAlert);

        setState({ ...state, blnClosedModal: true, intIndexTrackEdit: intIndexTrackEdit });
        fields.addNote.setValue(["true"]);
        fields?.strNotes?.setValue("");
      },
    setSuggestedAsPotential:
      ({ state, setState }) =>
      (sponsorInfo) => {
        try {
          state.lstTracking.unshift(sponsorInfo);
          ++state.intFollowUpNum;
          --state.intSuggestedNum;
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-setSuggestedAsPotential");
        }
      },
  };

  return handler;
}
