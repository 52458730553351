import React from 'react'
import Typography from '../../../../material/dataDisplay/Typography/Typography'
import Button from '../../../../material/inputs/Button/Button'
import Grid from '../../../../material/layout/Grid/Grid'
import Icon from "../../../../icon/CbmisIcon"


function UploaderImageButton(props) {
  const {images,handelUploadOpen,label,icon,mode,handlePreview} = props
  return (
    <React.Fragment>
            <Grid container spacing="0">
              <Grid item>
                <Button 
                  label={label} 
                  icon={icon || "image"} 
                  iconColor ={"#fff"} 
                  mode={mode}
                  textColor={"#fff"}
                  onClick={handelUploadOpen}
                />
              </Grid>
              <Grid item>
                {
                  images.length===1?(
                    <Button
                      label={<Typography as="caption" textColor="#333">{images[0].name}</Typography>}
                      mode="link"
                      onClick={handlePreview(images[0],0)}
                     />

                  ):images.length>1?
                  (
                    <Button
                      label={<Typography as="caption" textColor="#333">{`${images.length} File Uploaded`}</Typography>}
                      mode="link"
                      onClick={handlePreview(images[0],0)}
                    />
                  ):null
                }
              </Grid>
            </Grid>
    </React.Fragment>
  )
}

export default UploaderImageButton