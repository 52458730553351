import { ContextThemeProvider, styleFunction } from '@cbmisorg/styles';
import React, { forwardRef, useContext, useId } from 'react'
import SwitchStyle from './SwitchStyle';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const Switch = forwardRef((/**@type {any}*/props, ref) => {
  const {id,label,errorMessage,helperMessage,
    color,currentLang,
    value,defaultValue=false,
    sx,hidden,disabled,dir,className,...rest} = props
    const comID = String(useId()).slice(1,-1) + "checkbox" || id;
    const theTheme = useContext(ContextThemeProvider);
    const {classesName, style, styleSx} = SwitchStyle(theTheme);
    const classes = style();
    const classesSx = styleSx({sx, color, dir});
    const itemClasses = styleFunction.getClassName(classes?.[classesName?.switch_container], classesSx?.[classesName?.sx], className);
    const itemClasses2 = styleFunction.getClassName(classes?.[classesName?.switch], styleFunction.CheckDirectionItem(dir),styleFunction.CheckDisabledItem(disabled), styleFunction.CheckHiddenItem(hidden),styleFunction.checkExistsMargin(rest),styleFunction.checkExistsPadding(rest));

  return (
    <div className={classes?.[classesName?.container]} key={comID+"_container"}>
      <div className={itemClasses}>
      <span className={itemClasses2}>
        <input 
            type="checkbox"
            id={comID}
            ref={ref}
            defaultValue={value || defaultValue}
            defaultChecked={defaultValue || value}
            className={`${classes?.[classesName?.switch_input]} ${classesSx?.[classesName?.switch_input]}`}
            {...styleFunction?.getRestProps({...rest})} key={comID+"_input"}
          />
          <label
            className={`${classes?.[classesName?.switch_slider]} ${classesSx?.[classesName?.switch_slider]}`}
            htmlFor={comID}
            >
            <span className={`${classes?.[classesName?.switch_slider_toggle]} ${classesSx?.[classesName?.switch_slider_toggle]}`} />
          </label>
      </span>
      <label
        className={`${classes?.[classesName?.switch_label]} ${classesSx?.[classesName?.switch_label]}`}
        htmlFor={comID}
        >
        {label?.[currentLang] || label}
      </label>

      </div>
      <ErrorMessage message={errorMessage || helperMessage || null} blnError={errorMessage? true: false}/>
    </div>

  )
});

export default Switch