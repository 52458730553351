import React from 'react'
import  Icon  from '../../../icon/CbmisIcon'

const CamFeatureBtn = (props) => {
    const { top, right, bottom, left, icon, func, extraClass,color } = props
    return (
        <button
            className={extraClass ? `cam-feature-btn ${extraClass}` : 'cam-feature-btn'}
            style={{ top, right, bottom, left }}
            onClick={func}
        >
            <Icon icon={icon} size="28px" color={color || "#eee"} />
        </button>
    )
}

export default CamFeatureBtn
