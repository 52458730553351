export const viewAlert = (
  strText,
  mode = "info", //error | info | warning | success | custom
  direction,
  style = {
    backDropColor:null,
    title: null,
    withoutTitle: false,
    containerBk: null,
    borderColor: null,
    iconColorFill: null,
    iconWidth: null,
    titleColor: null,
    textColor: null,
    textAlign: null,
    fontSizeTitle: null,
    fontSizeTitle_xs: null,
    fontSize: null,
    fontSize_xs: null,
    iconEle: null,
    withoutIcon: false,

    closeIconColor: null,
    closeIconBk: null,
    closeIconBorder: null,


    closeIconColor_hover: null,
    closeIconBk_hover: null,

    headerIconSameLvl_xs: false,

  }
) => {
  try {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return;
    }
    //////////////////////////////////////////// constant (FOR INTERNAL COMPARE ) ///////////////////////////////////////////////////
    //#region
    const xmlns = "http://www.w3.org/2000/svg";
    const MODE_INFO = "info";
    const MODE_ERROR = "error";
    const MODE_WARNING = "warning";
    const MODE_SUCCESS = "success";
    const MODE_HELP = "help";
    const MODE_CUSTOM = "custom";
    //#endregion
    //////////////////////////////////////////// init style ///////////////////////////////////////////////////
    //#region

    //info #047dbc  #04a8f9 #efeefe  #5458f7
    //error #d60000  #880300 #feeeef #eb5756
    //warning #fec107  #bc910e #fef8e8 #f2c94d
    //success #4cb050  #3a833c #e5faf5 #00cc99
    if (!style) {
      style = {};
    }
    if(!style?.backDropColor){
      style.backDropColor = "rgba(0, 0, 0, 0.5)";
    }
    if (!style?.title) {
      if (mode === MODE_INFO) {
        style.title = direction === "rtl" ? "معلومة" : "Information";
      } else if (mode === MODE_ERROR) {
        style.title = direction === "rtl" ? "خطأ" : "ERROR";
      } else if (mode === MODE_WARNING) {
        style.title = direction === "rtl" ? "تحذير" : "Warning";
      } else if (mode === MODE_SUCCESS) {
        style.title = direction === "rtl" ? "نجاح" : "Success";
      } else if (mode === MODE_HELP) {
        style.title = direction === "rtl" ? "مساعدة" : "Help";
      } else {
        style.title = "";
      }
    }
    if (style?.withoutTitle) {
      style.title = "";
    }
    if (!style?.containerBk) {
      if (mode === MODE_INFO) {
        style.containerBk = "#f1f1fe";
      } else if (mode === MODE_ERROR) {
        style.containerBk = "#feeeef";
      } else if (mode === MODE_WARNING) {
        style.containerBk = "#fefaf3";
      } else if (mode === MODE_SUCCESS) {
        style.containerBk = "#f0faf6";
      } else if (mode === MODE_HELP) {
        style.containerBk = "#fff5ef";
      } else {
        style.containerBk = "#ffffff";
      }
    }
    if (!style?.borderColor) {
      if (mode === MODE_INFO) {
        style.borderColor = "#5458f7";
      } else if (mode === MODE_ERROR) {
        style.borderColor = "#eb5756";
      } else if (mode === MODE_WARNING) {
        style.borderColor = "#ecc243";
      } else if (mode === MODE_SUCCESS) {
        style.borderColor = "#00cc99";
      } else if (mode === MODE_HELP) {
        style.borderColor = "#f48a5c";
      } else {
        style.borderColor = "#666";
      }
    }

    if (!style?.iconColorFill) {
      if (mode === MODE_INFO) {
        style.iconColorFill = "#5458f7";
      } else if (mode === MODE_ERROR) {
        style.iconColorFill = "#eb5756";
      } else if (mode === MODE_WARNING) {
        style.iconColorFill = "#ecc243";
      } else if (mode === MODE_SUCCESS) {
        style.iconColorFill = "#00cc99";
      } else if (mode === MODE_HELP) {
        style.iconColorFill = "#f48a5c";
      } else {
        style.iconColorFill = "#666";
      }
    }
    if (!style?.iconWidth) {
      style.iconWidth = "100%";
    }
    if (!style?.titleColor) {
      if (mode === MODE_INFO) {
        style.titleColor = "#5458f7";
      } else if (mode === MODE_ERROR) {
        style.titleColor = "#eb5756";
      } else if (mode === MODE_WARNING) {
        style.titleColor = "#ecc243";
      } else if (mode === MODE_SUCCESS) {
        style.titleColor = "#00cc99";
      } else if (mode === MODE_HELP) {
        style.titleColor = "#f48a5c";
      } else {
        style.titleColor = "#111";
      }
    }
    if (!style?.textColor) {
      style.textColor = "#55585c";
    }
    if (!style?.textAlign) {
      style.textAlign = "justify";
    }
    if (!style?.fontSizeTitle_xs) {
      style.fontSizeTitle_xs = style?.fontSizeTitle ? style?.fontSizeTitle : "20px";
    }
    if (!style?.fontSizeTitle) {
      style.fontSizeTitle = "24px";
    }
    if (!style?.fontSize_xs) {
      style.fontSize_xs = style?.fontSize ? style?.fontSize : "13px";
    }
    if (!style?.fontSize) {
      style.fontSize = "16px";
    }
    if(!style?.closeIconColor){
      style.closeIconColor = "#cd3a37";
    }
    if(!style?.closeIconBk){
      style.closeIconBk = "#ddd";
    }
    if(!style?.closeIconColor_hover){
      style.closeIconColor_hover = "#ddd";
    }
    if(!style?.closeIconBk_hover){
      style.closeIconBk_hover = "#cd3a37";
    }
    
    //#endregion
    //////////////////////////////////////////// create header icon ///////////////////////////////////////////////////
    //#region
    let headerIcon = document.createElementNS(xmlns, "svg");
    headerIcon.setAttributeNS(null, "viewBox", "1 2 22 20");

    if (!style?.iconEle) {
      const path1 = document.createElementNS(xmlns, "path");
      path1.setAttributeNS(null, "d", "M0 0h24v24H0z");
      path1.setAttributeNS(null, "fill", "none");
      headerIcon.appendChild(path1);

      if (mode === MODE_INFO) {
        const path2 = document.createElementNS(xmlns, "path");
        path2.setAttributeNS(null, "d", "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z");
        headerIcon.appendChild(path2);
      } else if (mode === MODE_ERROR) {
        const path2 = document.createElementNS(xmlns, "path");
        path2.setAttributeNS(null, "d", "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z");
        headerIcon.appendChild(path2);
      } else if (mode === MODE_WARNING) {
        const path2 = document.createElementNS(xmlns, "path");
        path2.setAttributeNS(null, "d", "M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z");
        headerIcon.appendChild(path2);
      } else if (mode === MODE_SUCCESS) {
        const path2 = document.createElementNS(xmlns, "path");
        path2.setAttributeNS(null, "d", "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z");
        headerIcon.appendChild(path2);
      } else {
        const path2 = document.createElementNS(xmlns, "path");
        path2.setAttributeNS(
          null,
          "d",
          "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-6.99 15c-.7 0-1.26-.56-1.26-1.26 0-.71.56-1.25 1.26-1.25.71 0 1.25.54 1.25 1.25-.01.69-.54 1.26-1.25 1.26zm3.01-7.4c-.76 1.11-1.48 1.46-1.87 2.17-.16.29-.22.48-.22 1.41h-1.82c0-.49-.08-1.29.31-1.98.49-.87 1.42-1.39 1.96-2.16.57-.81.25-2.33-1.37-2.33-1.06 0-1.58.8-1.8 1.48l-1.65-.7C9.01 7.15 10.22 6 11.99 6c1.48 0 2.49.67 3.01 1.52.44.72.7 2.07.02 3.08z"
        );
        headerIcon.appendChild(path2);
      }

      headerIcon.style.fill = style.iconColorFill;
      headerIcon.style.width = style.iconWidth;
    } else {
      headerIcon = style?.iconEle;
    }
    if (style?.withoutIcon) {
      headerIcon = null;
    }
    //#endregion
    //////////////////////////////////////////// create close icon ///////////////////////////////////////////////////
    //#region
    const closeIcon = document.createElementNS(xmlns, "svg");
    closeIcon.setAttributeNS(null, "viewBox", "0 0 24 24");
    const path1 = document.createElementNS(xmlns, "path");
    path1.setAttributeNS(null, "d", "M0 0h24v24H0z");
    path1.setAttributeNS(null, "fill", "none");
    const path2 = document.createElementNS(xmlns, "path");
    path2.setAttributeNS(null, "d", "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z");
    closeIcon.appendChild(path1);
    closeIcon.appendChild(path2);
    closeIcon.style.cursor = "pointer";
    closeIcon.style.fill = style.closeIconColor;
    closeIcon.style.width = "1.5rem";
    closeIcon.style.borderRadius = `50%`;
    closeIcon.style.background = style?.closeIconBk;
    if(style?.closeIconBorder){
      closeIcon.style.border = style?.closeIconBorder;
    }
    //#endregion
    //////////////////////////////////////// root of modal //////////////////////////////////////////////
    //#region
    const modal = document.createElement("div");

    modal.style.position = "fixed";
    modal.style.left = "0px";
    modal.style.top = "0px";
    modal.style.bottom = "0px";
    modal.style.right = "0px";
    modal.style.width = "100%";
    modal.style.height = "100%";
    modal.style.margin = "0px";
    modal.style.padding = "0px";
    modal.style.backgroundColor = style?.backDropColor;
    modal.style.opacity = "1";
    modal.style.transform = "scale(1)";
    modal.style.transition = "visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s";
    modal.style.display = "flex";
    modal.style.flexDirection = "row";
    modal.style.justifyContent = "center";
    modal.style.alignSelf = "center";
    modal.style.alignItems = "center";
    modal.style.alignContent = "center";
    modal.style.flexWrap = "wrap";
    modal.style.boxSizing = "border-box";
    modal.style.zIndex = 1301;
    modal.style.direction = direction === "rtl" ? direction : "ltr";
    //#endregion
    ////////////////////////////////////// container ///////////////////////////////////////////////////
    //#region
    const modalContainer = document.createElement("div");
    modalContainer.animate(
      [
        {
          transform: "scale(0)",
          opacity: "0",
        },
        {
          transform: "scale(1)",
          opacity: "1",
        },
      ],
      {
        duration: 200,
        iterations: 1,
      }
    );
    modalContainer.style.position = "relative";
    modalContainer.style.margin = "0px";
    modalContainer.style.display = "flex";
    modalContainer.style.flexGrow = "0";
    modalContainer.style.flexBasis = `${(100 / 12) * (window.innerWidth < 600  ? 10 : 8)}%`;
    modalContainer.style.width = `${(100 / 12) * (window.innerWidth < 600 ? 10 : 8)}%`;
    modalContainer.style.flexWrap = "wrap";
    modalContainer.style.justifyContent = "space-between";
    modalContainer.style.boxSizing = "border-box";
    modalContainer.style.background = "#ffffff";
    if (direction === "rtl") {
      modalContainer.style.borderRight = `5px solid ${style.borderColor}`;
      modalContainer.style.borderLeft = `1px solid ${style.borderColor}`;
    } else {
      modalContainer.style.borderLeft = `5px solid ${style.borderColor}`;
      modalContainer.style.borderRight = `1px solid ${style.borderColor}`;
    }
    modalContainer.style.borderTop = `1px solid ${style.borderColor}`;
    modalContainer.style.borderBottom = `1px solid ${style.borderColor}`;
    modalContainer.style.padding = "1.25em 1.25em";
    modalContainer.style.borderRadius = "0.3em";
    modalContainer.style.boxShadow = `1px 1px 5px ${style.borderColor}`;
    modalContainer.style.backgroundColor = style.containerBk;

    modal.appendChild(modalContainer);
    //#endregion
    ////////////////////////////////////// close icon item ///////////////////////////////////////////////////
    //#region
    const modalCloseIcon = document.createElement("div");
    modalCloseIcon.style.position = "absolute";
    modalCloseIcon.style.top = "-0.75em";
    if (direction === "rtl") {
      modalCloseIcon.style.left = "-0.75em";
    } else {
      modalCloseIcon.style.right = "-0.75em";
    }
    modalCloseIcon.style.margin = "0px";
    modalCloseIcon.style.cursor = "pointer";
    modalCloseIcon.appendChild(closeIcon);

    modalCloseIcon.onmouseenter = function () {
      closeIcon.style.fill = style?.closeIconColor_hover;
      closeIcon.style.background = style?.closeIconBk_hover;
    };

    modalCloseIcon.onmouseleave = function () {
      closeIcon.style.fill = style?.closeIconColor;
      closeIcon.style.background = style?.closeIconBk;
    };

    modalContainer.appendChild(modalCloseIcon);
    modalCloseIcon.onclick = function (event) {
      while (modal?.firstChild) {
        modal.removeChild(modal.lastChild);
      }
      modal.remove();
    };
    //#endregion
    ////////////////////////////////////// header container for mobile ///////////////////////////////////////////////////
    //#region
    let modalHeaderContainer = null;

    if (window?.innerWidth < 600 && !style?.headerIconSameLvl_xs) {
      modalHeaderContainer = document.createElement("div");
      modalHeaderContainer.style.position = "relative";
      modalHeaderContainer.style.margin = "0px";
      modalHeaderContainer.style.display = "flex";
      modalHeaderContainer.style.flexGrow = "0";
      modalHeaderContainer.style.flexBasis = `${(100 / 12) * 12}%`;
      modalHeaderContainer.style.width = `${(100 / 12) * 12}%`;
      modalHeaderContainer.style.flexWrap = "wrap";
      modalHeaderContainer.style.alignSelf = "self-start";
      modalHeaderContainer.style.alignItems = "center";
      modalHeaderContainer.style.justifyContent = "flex-start";

      modalContainer.appendChild(modalHeaderContainer);
    }

    //#endregion
    ////////////////////////////////////// icon header item ///////////////////////////////////////////////////
    //#region
    if (headerIcon) {
      const modalIcon = document.createElement("div");
      modalIcon.style.position = "relative";
      modalIcon.style.margin = "0px";
      modalIcon.style.padding = "0px";
      modalIcon.style.display = "flex";
      modalIcon.style.flexGrow = "0";
      modalIcon.style.flexBasis = `${(100 / 12) * (window.innerWidth < 600 ? 1 : 1)}%`;
      modalIcon.style.width = `${(100 / 12) * (window.innerWidth < 600 ? 1 : 1)}%`;
      modalIcon.style.flexWrap = "wrap";
      modalIcon.style.alignSelf = "stretch";
      modalIcon.style.alignItems = "center";

      modalIcon.appendChild(headerIcon);

      if (window?.innerWidth < 600 && modalHeaderContainer) {
        modalHeaderContainer.appendChild(modalIcon);
      } else {
        modalContainer.appendChild(modalIcon);
      }
    }

    //#endregion

    ////////////////////////////////////// container text child item ///////////////////////////////////////////////////
    //#region
    const modalContainerChild = document.createElement("div");
    modalContainerChild.style.position = "relative";
    modalContainerChild.style.margin = "0px";

    if (direction === "rtl") {
      modalContainerChild.style.paddingRight = "6px";
    } else {
      modalContainerChild.style.paddingLeft = "6px";
    }

    modalContainerChild.style.display = "flex";
    modalContainerChild.style.flexGrow = "0";
    modalContainerChild.style.flexBasis = `calc(${(100 / 12) * (window.innerWidth < 600 && !style?.headerIconSameLvl_xs? 12 : 11)}%${window.innerWidth < 600 && !style?.headerIconSameLvl_xs? "" : " - 10px"})`;
    modalContainerChild.style.width = `calc(${(100 / 12) * (window.innerWidth < 600  && !style?.headerIconSameLvl_xs? 12 : 11)}%${window.innerWidth < 600 && !style?.headerIconSameLvl_xs? "" : " - 10px"})`;
    modalContainerChild.style.flexWrap = "wrap";
    modalContainerChild.style.alignSelf = "center";
    modalContainerChild.style.alignItems = "center";

    modalContainer.appendChild(modalContainerChild);
    //#endregion
    ////////////////////////////////////// title item ///////////////////////////////////////////////////
    //#region
    const modalTitle = document.createElement("div");
    modalTitle.style.position = "relative";
    modalTitle.style.margin = "auto";
    modalTitle.style.display = "flex";
    modalTitle.style.flexGrow = "0";
    modalTitle.style.flexBasis = `calc(${(100 / 12) * (window.innerWidth < 600 && !style?.headerIconSameLvl_xs ? 11 : 12)}%${window.innerWidth < 600 && !style?.headerIconSameLvl_xs? " - 10px" : ""})`;
    modalTitle.style.width = `calc(${(100 / 12) * (window.innerWidth < 600 && !style?.headerIconSameLvl_xs? 11 : 12)}%${window.innerWidth < 600 && !style?.headerIconSameLvl_xs? " - 10px" : ""})`;
    modalTitle.style.flexWrap = "wrap";
    modalTitle.style.alignSelf = "center";
    modalTitle.style.alignItems = "center";
    modalTitle.style.whiteSpace = "nowrap";
    modalTitle.style.fontWeight = "bold";
    modalTitle.style.fontSize = window.innerWidth < 600 ? style.fontSizeTitle_xs : style.fontSizeTitle;
    modalTitle.style.fontFamily = "system-ui";
    modalTitle.style.textAlign = "start";
    modalTitle.style.lineHeight = "1.5em";
    modalTitle.style.color = style.titleColor;
    modalTitle.innerHTML = style.title;
    if (window?.innerWidth < 600 && modalHeaderContainer) {
      modalHeaderContainer.appendChild(modalTitle);
    } else {
      modalContainerChild.appendChild(modalTitle);
    }

    //#endregion
    ////////////////////////////////////// text item ///////////////////////////////////////////////////
    //#region
    const modalText = document.createElement("div");
    modalText.style.position = "relative";
    modalText.style.margin = "auto";
    modalText.style.display = "flex";
    modalText.style.flexGrow = "0";
    modalText.style.flexBasis = `${(100 / 12) * 12}%`;
    modalText.style.width = `${(100 / 12) * 12}%`;
    modalText.style.flexWrap = "wrap";
    modalText.style.alignSelf = "flex-start";
    modalText.style.alignItems = "flex-start";
    modalText.style.whiteSpace = "pre-line";
    modalText.style.overflow = "auto";
    modalText.style.maxHeight = `calc(100vh - 2.5em - 6em - 2em)`;
    modalText.style.fontSize = window.innerWidth < 600 ? style.fontSize_xs : style.fontSize;
    modalText.style.fontFamily = "system-ui";
    modalText.style.textAlign = style.textAlign;
    modalText.style.color = style.textColor;
    modalText.innerHTML = strText;

    modalContainerChild.appendChild(modalText);
    //#endregion
    ////////////////////////////////////// add to body /////////////////////////////////////////////
    //#region
    document.body.appendChild(modal);

    modal.onclick = function (event) {
      if (event?.target === modal) {
        while (event?.target?.firstChild) {
          event.target.removeChild(event.target.lastChild);
        }
        event.target.remove();
      }
    };

    return {
      eventClose: () => {
        while (modal?.firstChild) {
          modal.removeChild(modal.lastChild);
        }
        modal.remove();
      },
    };
    //#endregion
  } catch (error) {
    //console.log(error)
  }
};
