import { ModalBody } from "@cbmisorg/client-app";
import { Button, Grid, Modal, Typography } from "@cbmisorg/material";
import React, { useEffect, useId, useState } from "react";
import { useRef } from "react";
import selectNameCoordinateCtrl from "./selectNameCoordinate.controller";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";

export default function SelectNameCoordinateView() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const labels = dictionary?.components?.stakeholders?.admin?.mngRegForum;

  const [state, setState] = useState({
    blnOpenCanvasModal: false,

    mouseX: null,
    mouseY: null,
    startX: null,
    startY: null,
    ctxWidth: null,
    ctxHeight: null,
    isDown: false,

    funSelectDone: null,
    blnIsBadge: false,
  });
  const canvasID = "canvas-" + useId().replaceAll(":", "");
  const imageID = "imageplot-" + useId().replaceAll(":", "");
  const scrollRef = useRef({ scrollTop: 0, scrollLeft: 0 });
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const fields = FormData({
    strTestLabel: {
      control: "text",
      blnLanguage: false,
      value: "محمد أحمد عبدالله محمود",
      label: { arb: "الاسم (للفحص)", eng: "Name (for examination)" },
    },
    strFontSize: {
      control: "text",
      blnLanguage: false,
      value: "72",
      label: { arb: "حجم الخط", eng: "Font Size" },
      validations: {
        onlyNumbers: true,
      },
    },
    strFontColor: {
      control: "text",
      blnLanguage: false,
      value: "#324476",
      label: { arb: "لون الخط", eng: "Font Color" },
    },
  });

  const handlers = selectNameCoordinateCtrl({ appState });
  const handleMouseDown = handlers.handleMouseDown({ canvasRef, scrollRef, state });
  const handleMouseUp = handlers.handleMouseUp({ state });
  const handleMouseOut = handlers.handleMouseOut({ state });
  const handleMouseMove = handlers.handleMouseMove({ canvasRef, scrollRef, setState, state, fields });
  const handleScrollRegion = handlers.handleScrollRegion({ scrollRef });
  const previewPhoto = handlers.previewPhoto({ imageRef, canvasID, canvasRef, imageID, scrollRef, setState, state, fields });
  // const downloadImg = handlers.downloadImg({ state, setState, canvasRef });

  useEffect(() => {
    let viewport = document.querySelector('meta[name="viewport"]');
    return () => {
      if (viewport && viewport.content !== "width=device-width, initial-scale=1") {
        viewport.content = "width=device-width, initial-scale=1";
      }
    };
  }, []);

  return {
    openEle: (fileCertificate, funSelectDone = null, defaultInfo, blnIsBadge) => (
      <>
        <Button
          py-0
          size="sm"
          icon="edit"
          label={labels?.label?.selectNameAreaBtn?.[lang]}
          onClick={previewPhoto(fileCertificate, funSelectDone, defaultInfo, blnIsBadge)}
          mode="outlined"
        />
        {/* <Grid item xs={12}>
          <Button label={"Download Certificates"} onClick={downloadImg} />
        </Grid> */}
      </>
    ),
    modal: (
      <Modal size="xl" sx={{ width: "100% !important" }} p-0 open={state.blnOpenCanvasModal} eventClose={() => setState({ ...state, blnOpenCanvasModal: false })}>
        <ModalBody p-0>
          <Grid container justifyContent="space-between">
            <Grid item alignItems="center">
              <Button
                icon="save"
                label={labels?.label?.doneBtn?.[lang]}
                disabled={state.startX === null}
                onClick={() => {
                  let viewport = document.querySelector('meta[name="viewport"]');
                  if (viewport && viewport?.content !== "width=device-width, initial-scale=1") {
                    viewport.content = "width=device-width, initial-scale=1";
                  }
                  setState({ ...state, blnOpenCanvasModal: false });
                  if (typeof state?.funSelectDone === "function") {
                    state.funSelectDone({
                      imageWidth: canvasRef?.current?.imageBoundary?.width,
                      imageHeight: canvasRef?.current?.imageBoundary?.height,
                      imageTop: canvasRef?.current?.imageBoundary?.top,
                      imageLeft: canvasRef?.current?.imageBoundary?.left,
                      imageRight: canvasRef?.current?.imageBoundary?.right,
                      imageBottom: canvasRef?.current?.imageBoundary?.bottom,
                      startX: state?.startX,
                      startY: state?.startY,
                      mouseX: state?.mouseX,
                      mouseY: state?.mouseY,
                      ctxWidth: state?.ctxWidth,
                      ctxHeight: state?.ctxHeight,
                      fontSize: Number(fields.strFontSize.getValue()) || 72,
                      fontColor: fields.strFontColor.getValue() || "#324476",
                    });
                  }
                }}
                mode="outlined"
                sx={{
                  fontSize: "large",
                  borderWidth: "2px",
                  sm: {
                    fontSize: "medium",
                  },
                }}
                p-0
              />
            </Grid>
            <Grid item alignItems="center" justifyContent="center">
              <Typography as="subtitle2">{labels?.label?.selectModalTitle?.[lang]}</Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid item alignItems="center" xs={6}>
              <FormField objHandler={fields.strTestLabel} />
            </Grid>
            <Grid item alignItems="center" xs={3}>
              <FormField objHandler={fields.strFontSize} dir="ltr" />
            </Grid>
            <Grid item alignItems="center" xs={3}>
              <FormField objHandler={fields.strFontColor} dir="ltr" />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item justifyContent="flex-start" p-0 sx={{ overflow: "auto", maxWidth: "100vw", maxHeight: "82vh" }} onScroll={handleScrollRegion}>
              <img alt="" id={imageID} style={{ zIndex: 5, maxWidth: state?.blnIsBadge ? "380px" : "100%", width: "auto", height: "auto" }} />
              <canvas
                id={canvasID}
                style={{ border: "1px solid red", position: "absolute", zIndex: 10, overflow: "auto" }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                // onTouchEnd={handleMouseUp}
                onTouchCancel={handleMouseUp}
              ></canvas>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
