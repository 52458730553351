import React from "react";
import { FormField } from "../../../../../util/formApi/FormAPI";
import { App_info_Color } from "../../../../../util/appStyle";
import { Button, Grid } from "@cbmisorg/material";
import { dictionary } from "../../../../../util/appLanguage/dictionary";
import { useNavigate } from "@cbmisorg/router";


function Info({ appState, fields, handlers, classes, lang, uploaderFirstProofSection, isLoading, setIsLoading }) {
  const navigate = useNavigate();
  // const onChangeCountry = handlers?.onChangeCountry({ fields });
  const handelChangeEmployment = handlers?.handelChangeEmployment({ fields });
  const handelSubmit = handlers?.handelSubmit({ fields, isLoading, setIsLoading, navigate });

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"center"} py-10>
        <Grid item xs={12}>
          <FormField objHandler={fields.strFullName} icon="person" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField objHandler={fields.bigDegreeID} icon="documentScannerRounded" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField
            objHandler={fields.bigWorkSector}
            icon="work"
            mode={"classic"}
            
            color={App_info_Color}
            className={classes?.field}
            onChange={handelChangeEmployment}
          />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>

      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Button py-0 px-10 label={dictionary?.shared?.buttons?.save?.[lang]} onClick={handelSubmit} mode="outlined" color={App_info_Color} className={classes?.btnBox} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Info;
