import { Alert } from "@cbmisorg/material";
import { aaiot_System_ID } from "../../../configuration/appVariables";
import { generateQueries, objTableAttributes } from "../../../graphql/schema/GenerateQuery";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { alertStyle } from "../../../util/appStyle";
import * as appFunctions from "../../../util/appFunctions";

const strPageInfo = "@src/client/component/public/media/Media.controller.js";

const tblActivity = generateQueries("tblActivity");

export const CtrlMedia = (appState, appDispatch, navigate = null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    getMedia:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo, strUrlVedio, objForumInfo }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "error", appLangDirection?.[lang], alertStyle);

            return;
          }
          setIsLoading(true);
          const result = await tblActivity(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: objTableAttributes?.tblActivity?.full,
            objectCondition: { bigSystemID: bigSystemID, blnIsActive: true, blnIsDeleted: false, blnIsArchive: false, blnIsMedia: true, strUrlCode: strUrlVedio },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.cantFindRecords?.[lang], "error", appLangDirection?.[lang], alertStyle);

            setIsLoading(false);
            return;
          }
          if (objForumInfo) {
            stateInfo.lstCertificateFile = Array.isArray(objForumInfo?.jsnForumInfo?.lstForumCertificateFile) ? objForumInfo?.jsnForumInfo?.lstForumCertificateFile : [];
          }

          stateInfo.blnIsInitDone = true;
          stateInfo.objInfo = result?.response || {};

          if (!appState?.clientInfo?.mediapage) {
            appState.clientInfo.mediapage = {};
          }
          if (!appState?.clientInfo?.mediapage?.strUrlCode) {
            const resultCount = await tblActivity(strPageInfo, appState, "aaiotAppIncDecInt", {
              arrAttributes: ["intCountView"],
              objectCondition: {
                bigSystemID: bigSystemID,
                bigActivityID: stateInfo?.objInfo?.bigActivityID,
                strUrlCode: stateInfo?.objInfo?.strUrlCode,
                blnIsActive: true,
                blnIsDeleted: false,
              },
            });
            if (!resultCount?.blnIsRequestSuccessful) {
              appFunctions.logMessage(resultCount?.response, "");
              return;
            }
          }
          appState.clientInfo.mediapage.strUrlCode = true;
          setIsLoading(false);
          setStateInfo({ ...stateInfo });
          appDispatch();
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },
    goPath: (url) => () => {
      window.open(url, "_blank");
    },
    openVedioModal:
      ({ stateInfo, setStateInfo }) =>
      async () => {
        setStateInfo({
          ...stateInfo,
          openModalVideo: !stateInfo.openModalVideo,
        });
        if (!stateInfo.openModalVideo) {
          const resultCount = await tblActivity(strPageInfo, appState, "aaiotAppIncDecInt", {
            arrAttributes: ["intCountWatchMedia"],
            objectCondition: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID,
              bigActivityID: stateInfo?.objInfo?.bigActivityID,
              strUrlCode: stateInfo?.objInfo?.strUrlCode,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!resultCount?.blnIsRequestSuccessful) {
            appFunctions.logMessage(resultCount?.response, "");
            return;
          }
        }
      },
  };

  return handler;
};
