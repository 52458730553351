import { ThemeProvider } from "@cbmisorg/client-app";
import "./App.css";
import "./aaiotStyle.css";
import Router from "../old/configuration/Router";
import { App_Dark_Color, App_Primary_Color, App_Second_Color } from "../old/appHelper/appColor";
import React from "react";

import { AppProvider } from "../client/configuration/contextapi/context";
// import NewRouter from "../client/configuration/routing/Router";

function App() {
  const theme = {
    primary: App_Primary_Color,
    secondary: App_Second_Color,
    dark: App_Dark_Color,
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Router />
        </AppProvider>
      </ThemeProvider>

      {/* <NewAppProvider>
        <NewRouter />
      </NewAppProvider> */}
    </div>
  );
}

export default App;
