import React, { useEffect, useRef } from "react";
import "./VideoPlayer.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function VideoPlayer(props) {
  const { src, imageSrc, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("VideoPlayer"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName(".cbmis-video-container") });

  useEffect(() => {}, [src, imageSrc, id, sx, hidden, disabled, dir, className]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-video-container");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <React.Fragment>
      <div
        id={styleRef.current.strSXComponentID}
        className={`cbmis-video-container ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)}`}
        {...getRestProps()}
      >
        <video id={id || getID} width="100%" poster={imageSrc} controlsList="nodownload" className={`cbmis-video-player ${className || ""}`} {...getRestProps()}>
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </React.Fragment>
  );
}

export default VideoPlayer;
