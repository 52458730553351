import React from 'react'
import MediaView from './Media.view'

function Media() {
  return (
    <React.Fragment>
        <MediaView />
    </React.Fragment>
  )
}

export default Media