import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Button, Grid, Typography } from "@cbmisorg/client-app";
import { App_Primary_Color, App_Text_Color } from "../../../../../appHelper/appColor";
import NoResultImg from "../../../../sharedUi/noResultImg/NoResultImg";
import { FormField } from "../../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as appVariables from "../../../../../appHelper/appVariables";
import Info from "./Info";

const labels = dictionary.components.mngPotentialSponsorship;

function Followup({ appState, fldSearch, fields, handlers, state, setState, blnFollowUp }) {
  const lang = appState?.clientInfo?.strLanguage;
  const sortListResult = handlers.sortListResult({ state, fldSearch });
  const openEditDialog = handlers.openEditDialog({ fields, setState, state });
  const getAddedBeforeLabel = handlers.getAddedBeforeLabel({ state });

  return (
    <React.Fragment>
      <Grid container justify="space-between">
        <Grid item md={blnFollowUp?5:6} xs={blnFollowUp?5:6} lg="6" sx={{ background: "#ffffff" }} p-0 px-1>
          <FormField objHandler={fldSearch?.bigSearchWorkSector} placeholder={labels?.searchByWorkSector?.[lang]} dir={lang === "arb" ? "rtl" : "ltr"} />
        </Grid>

        <Grid item xs={blnFollowUp?5:6} md={blnFollowUp?5:6} sx={{ background: "#ffffff" }} p-0 px-1>
          <FormField
            objHandler={blnFollowUp ? fldSearch?.intSearchByAddedDay : fldSearch?.bigSearchSponsorshipStatus}
            placeholder={labels?.searchByAddedDate?.[lang]}
            endAdornment={labels?.days?.[lang]}
            dir={lang === "arb" ? "rtl" : "ltr"}
          />
        </Grid>

        {!blnFollowUp ? null : (
          <Grid item p-0 sx={{ alignItems: "center !important" }}>
            <Grid item p-0>
              <Button sx={{ xs: { padding: "0px", margin: "10px" } }} size="sm" icon={"add"} onClick={openEditDialog({}, null, false)} />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid container justify="space-between">
        <Grid item xs={12} sx={{ background: "#ffffff" }} p-0>
          <FormField objHandler={fldSearch?.strSearch} placeholder={labels?.searchBySponserName?.[lang]} endIcon={"search"} dir={lang === "arb" ? "rtl" : "ltr"} />
        </Grid>
      </Grid>

      <Grid container justify={"center"}>
        {(state.lstTracking || [])?.map((objTracking, intIndex) => {
          if (intIndex === 0) {
            state.intNumViewItem = 0;
          }

          if (!sortListResult(objTracking)) {
            if (!state.intNumViewItem && intIndex >= state.lstTracking?.length - 1) {
              return <NoResultImg key={objTracking?.bigTrackID} xs={6} />;
            }
            return null;
          }
          ++state.intNumViewItem;

          return (
            <Grid item xs="12" key={objTracking?.bigTrackID}>
              <Accordion ariaControl="" dir={lang === "arb" ? "rtl" : "ltr"}>
                <AccordionTab sx={{ width: "100%", border: `1px solid ${App_Text_Color}`, background: "#ffffff", color: App_Primary_Color }}>
                  <AccordionSummary py-1>
                    <Grid container>
                      <Grid item md={11} xs={8}>
                        <Grid container>
                          <Grid item md={"auto"} xs={12} py-0>
                            <Typography as="caption" pb-0 color="primary">
                              {appVariables.workSectorIDLabel?.[objTracking?.jsnPartnershipTrackInfo?.bigWorkSector]?.[lang] || "-"}
                            </Typography>
                          </Grid>
                          <Grid p-0 item md={"auto"} xs={"0"}>
                            /
                          </Grid>
                          <Grid item md={"auto"} xs={12} py-0>
                            <Typography as="caption" pb-0 color="primary" sx={{ fontWeight: "bold" }}>
                              {objTracking?.jsnPartnershipTrackInfo?.strSponsorName?.[lang] || objTracking?.jsnPartnershipTrackInfo?.strSponsorName || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs="4" md="1" justify={"end"} alignSelf={"center"}>
                        <Typography as="caption" pb-0 color="primary">
                          {objTracking?.bigTrackStatusID === appVariables.objPotentialSponsorshipStatus?.Approved ||
                          objTracking?.bigTrackStatusID === appVariables.objPotentialSponsorshipStatus?.Rejected ? (
                            objTracking?.bigTrackStatusID === appVariables.objPotentialSponsorshipStatus?.Approved ? (
                              <span style={{ color: "var(--cbmis-success)" }}>{labels?.Approved?.[lang]}</span>
                            ) : (
                              <span style={{ color: "var(--cbmis-error)" }}>{labels?.Rejected?.[lang]}</span>
                            )
                          ) : (
                            getAddedBeforeLabel({
                              dtmCreatedDate: objTracking?.jsnTrackingHistory?.[objTracking?.jsnTrackingHistory?.length - 1]?.dtmUpdatedDate,
                            })
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Info objTracking={objTracking} intIndex={intIndex} blnFollowUp={blnFollowUp} appState={appState} fields={fields} setState={setState} state={state} handlers={handlers} />
                  </AccordionDetails>
                </AccordionTab>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default Followup;
