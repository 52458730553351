import React, { useEffect, useRef } from "react";
import "./SpeedDial.css";
import "../../../style/main.css";
import CbmisIcon from "../../../icon/CbmisIcon";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const SpeedDial = (props) => {
  const { icon, openIcon, direction, sx, hidden, disabled, dir, className, onClick, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("SpeedDial"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [icon, openIcon, direction, hidden, disabled, dir, className, onClick, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-speed-dial");
    }
  }, [sx, screenWidth]);

  const handleClicked = () => {
    if (onClick) {
      onClick();
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getSpeedDialDirection = () => {
    const getDirection = String(direction).toLowerCase();
    switch (getDirection) {
      case "up":
        return "cbmis-speed-dial-direction-up";
      case "left":
        return "cbmis-speed-dial-direction-left";
      case "bottom":
        return "cbmis-speed-dial-direction-bottom";
      case "right":
        return "cbmis-speed-dial-direction-right";
      default:
        return "cbmis-speed-dial-direction-up";
    }
  };

  return (
    <div
      id={getID.current}
      className={`cbmis-speed-dial ${getSpeedDialDirection()} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
      onClick={handleClicked}
    >
      <div className="cbmis-speed-dial-icon">{icon ? icon : <CbmisIcon icon="add" />}</div>
      <div className="cbmis-speed-dial-open-icon">{openIcon ? openIcon : <CbmisIcon icon="clear" />}</div>
      <ul className="cbmis-speed-dial-action-list">{props.children}</ul>
    </div>
  );
};

export default SpeedDial;
