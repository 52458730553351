import { Avatar, Box, Container, Grid, Icon, Typography } from '@cbmisorg/client-app';
import React from 'react'
import * as appColor from '../../../../../appHelper/appColor'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary';
import { appStyle } from '../../../../../appHelper/appStyle';
import ParallaxScrollingApp from '../../../../sharedUi/ParallaxScrollingApp';
import aiImg from "../../../../../assets/images/ai.jpg";

const lstIcon=["Lightbulb","BusinessCenter","ManageAccounts","CastForEducation","Radar","Female","Stream","flag"]

export default function OurGoal({lang,appState}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.ourGoal

  return (
    <React.Fragment>
      <Grid container py-10 id="ourGoal" px-0 spacing={0}>
        <Grid item xs="12"  container  justify="center" >
          <ParallaxScrollingApp img={aiImg}>
            <Container py-10>
                <Grid container spacing={2}>
                    <Grid item xs="12" pb-10 mb-5>
                        <Typography as="h4" sx={appStyle?.title2} >{labels?.title?.[lang]}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {
                      labels?.lst?.map((slide,index)=>{
                        return(
                          <Grid item xs="12" sm="6" lg={appState?.clientInfo?.appFontSize==="xl" ?"6":"4"} xl="4"  key={index}>
                          <Box p-2 className={`${lang==="arb"?"boxViewArb":"boxView"} ${appState?.clientInfo?.appFontSize==="xl" || appState?.clientInfo?.appFontSize==="lg"?"hight200":""}`}>
                                <Grid container spacing={2} justify="start" sx={{borderBottom:`1px solid ${appColor?.App_Primary_Color}`,}}>
                                  <Grid item xs="2" p-0>
                                    <Avatar className='boxViewAvatar'>
                                      <Icon icon={lstIcon?.[index]} color={appColor.App_Primary_Color} />
                                    </Avatar>
                                  </Grid>
                                  <Grid item xs="10">
                                      <Typography as="subtitle1" color="primary" sx={{textAlign:"start !important"}}>{slide?.title?.[lang]}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs="12">
                                    <Typography as="body2" sx={{textAlign:"justify !important"}}>{slide?.point?.[lang]}</Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                          </Grid>
                          )
                        })
                    }
                </Grid>
            </Container>
          </ParallaxScrollingApp>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
