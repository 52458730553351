import {  Box, Button, Grid, Icon, Line, Typography, useNavigate } from '@cbmisorg/client-app'
import React from 'react'
import img from '../../../assets/images/ai.jpg'
import { App_orang_color } from '../../../appHelper/appColor'
import objAppRouting from '../../../appHelper/routing/appRouting'
import { App_Server_Url_DownloadFiles } from '../../../appHelper/appVariables'

//                             <Avatar src={item?.lstImg?.[0] || img} alt={item.title?.[lang]} mode="square" sx={{width:"100%", minHeight:"280px"}}/>


function EventCard(props) {
    const navigate= useNavigate()
    const {item={},lang="arb",} = props
    const content = item?.jsnInfo
  return (
    <React.Fragment>
        <Box outline={2} p-0 sx={{minHeight:"555px", sm:{minHeight:"555px !important"}, xs:{minHeight:"auto !important"}}}>
            <Grid container>
                <React.Fragment>
                    <Grid item xs="12" container spacing={2}>
                        <Grid item xs="12" sx={{maxHeight:"55px"}}>
                            <Typography as="subtitle2" color="primary" className='block-ellipsis_title'>{content?.title?.[lang]}</Typography>
                        </Grid>
                        <Grid item xs="12">
                            <img src={App_Server_Url_DownloadFiles + content?.lstMainImg?.[0]?.path || img} alt={content.title?.[lang]} width={"100%"} height={window.innerWidth < 600?"auto":"300px"} />
                        </Grid>
                        <Grid item  xs="0" sm="12" sx={{display:"block !important"}}>
                            <Line />
                        </Grid>
                        <Grid item xs="12">
                            <Typography as="body2" className="block-ellipsis">
                                {`${content?.listPoint?.[0]?.[lang] || ""} ${content?.listPoint?.[1]?.[lang]  || ""} ${content?.listPoint?.[2]?.[lang]  || ""} ${content?.listPoint?.[3]?.[lang]  || ""} ${content?.listPoint?.[4]?.[lang]  || ""}`}
                            </Typography>
                        </Grid>
                        <Grid item  xs="12" sm="12" sx={{display:"block !important"}}>
                            <Line />
                        </Grid>
                        <Grid item xs="12" container justify={'space-between'}>
                            <Grid item xs="10" md="auto" container spacing={0}>
                                <Grid item>
                                    <Icon icon="business"  color="primary"/>
                                </Grid>
                                <Grid item xs="10" md="auto">
                                    <Typography as="caption" color="primary">{content?.source?.[lang]}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button icon="openInNew" color={App_orang_color} p-0 onClick={()=>{navigate(objAppRouting?.Public_Events?.url + "/" + item?.bigActivityID)}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </Grid>
        </Box>
    </React.Fragment>
  )
}

export default EventCard
