import React from 'react'
import Uploader from '../../Uploader'
import Icon from "../../../../icon/CbmisIcon"

function UploaderImageMultipleBox(props) {
    const {images,setImages,gallery,camera,handelOpenCamera,handleDeleteFile,handlePreview,maxSizeKB,maxFilesNumber,maxWidth,maxHeight,quality} = props

  return (
    <React.Fragment>
         <div className="box-multiple-image-container" id="img-preview">
            <div className="upload-options upload-options-multiple-box">
            {
              !gallery?null:(
                <label className="upload-options-btn uploader-options">
                    <Icon icon="uploadFile" />
                    <Uploader images={images} setImages={setImages}  maxSizeKB={maxSizeKB} maxFilesNumber={maxFilesNumber} maxWidth={maxWidth} maxHeight={maxHeight} quality={quality}/>
                </label>
              )
            }
            {
              !camera?null:(
                <label className=" upload-options-btn camera-options" onClick={handelOpenCamera}>
                  <Icon icon="cameraAlt" />
                </label>
              )
            }
            </div>
            <div className='box-multiple-image-preview-container'>
                {images.length?
                    Object.values(images).map((imgFile,index)=>{
                        return(
                            <div className="image-preview-container-box-multiple" key={index}>
                              <img src={URL.createObjectURL(imgFile)} alt="/" className="image-preview"/>
                                <div className='image-preview-container-button'>
                                    <Icon icon="delete" size={"22px"} onClick={handleDeleteFile(index)}  />
                                    <Icon icon="designServices" size={"22px"} onClick={handlePreview(imgFile,index)}  />
                                </div>
                            </div>
                        )
                    })
                    :(<Icon icon="image" className="iconImage"/>)
                }
            </div>
         </div>
    </React.Fragment>
  )
}

export default UploaderImageMultipleBox