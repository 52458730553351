import React, { useEffect, useState } from 'react'
import { Container, Grid, Icon, MenuItem, Typography,Loader, useNavigate, Button, Text, } from '@cbmisorg/client-app';
import { App_Primary_Color, App_Text_Color } from '../../../../appHelper/appColor';
import { CtrlReqTraining } from './controler/CtrlReqTraining';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import objAppRouting from '../../../../appHelper/routing/appRouting';
import NoResultImg from '../../../sharedUi/noResultImg/NoResultImg';
import { useApp } from '../../../../../client/configuration/contextapi/context';

const labels = dictionary?.components?.manageMembers

function RouteaMnageReqTraining() {
  const navigate = useNavigate()
  const { appState } = useApp();
  const lang = appState.clientInfo.strLanguage
  const [isLoading, setIsLoading] = useState(false)
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone:false,
    listMembers:[],
    listReadMembers:[],
  })

  const handlers = CtrlReqTraining(appState,isLoading, setIsLoading,stateInfo, setStateInfo);

  useEffect(() => {
    if(!stateInfo?.blnIsInitDone){
      handlers?.getMembers()
    }
  }, [])


  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false}/>
      <Container  py-3>
      {
        isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
      }
        <Grid container justify={'center'}>
          <Grid item xs="12">
            <Text placeholder={labels?.search?.[lang]} icon="search" dir={lang==="arb"?"rtl":"ltr"} onChange={handlers?.searchMember}/>
          </Grid>
        </Grid>
      {
          !stateInfo?.listReadMembers?.length?(
            <NoResultImg />
          ):(
            <React.Fragment>
              <Grid container justify={'center'} spacing={0}>
                {
                  stateInfo?.listMembers?.map((item,index)=>{
                    return(
                      <Grid item xs="12" py-2 key={index}>
                         <MenuItem sx={{width:"100%"}} 
                          headItem={<Icon icon={"AccountBox"} pt-1 color="#aaa"/>}
                            outlined
                          >
                            <Grid container justify={'space-between'} px-0 px-xs-3 >
                                <Grid item pb-0 xs="11" sm="auto">
                                  <Typography as="caption" pb-0 color={App_Text_Color}>{item?.jsnUserInfo?.strFullName?.[lang] || item?.jsnUserInfo?.strFullName}</Typography>
                                </Grid>
                              <Grid item container spacing={0} xs="1" sm="auto">
                                <Grid item py-1 px-0>
                                  <Button icon="edit" p-0 onClick={()=>navigate(objAppRouting?.viewMember?.url,{state:{selected:item}})}/>
                                </Grid>
                                <Grid item py-1 px-0>
                                <Button icon="delete" p-0 color="error" onClick={()=>handlers?.funDelete(item)}/>
                              </Grid>
                            </Grid>
                            </Grid>
                          </MenuItem>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </React.Fragment>
          )
      }

      </Container>

    </React.Fragment>
  )
}

export default RouteaMnageReqTraining