import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Icon from "../../../icon/CbmisIcon";

import Typography from '../../dataDisplay/Typography/Typography'
import { langRtL } from '../../../helper/CbmisVariables'
import Menu from '../../navigation/Menu/Menu'
import MenuItem from '../../navigation/Menu/MenuItem'

const TextArea = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,line,
          color="primary",icon,iconColor,adornment,endIcon,endAdornment,
          objLang,currentLang,setCurrentLang, getCurrentValue,
          value,defaultValue,onChange,
          sx,hidden,disabled,dir,className,...rest} = props

    const inputRef = useRef({})

    const screenWidth = useCheckScreenSize();
    const nameID = useRef(id || componentsFunction.randomName("TextArea"));
    const getID = useRef(componentsFunction.randomName("TextArea"));
    const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-input")});

    const [initialState, setInitialState] = useState({
      currentLangSelected:currentLang,
      objLang:objLang || {},

      lstPosition:{x:0,y:0,width:0},
      openMenu:false,
      blnIsFull:false
    })

  //#region Effect
    useEffect(()=>{
      if(typeof(value)==="object" || typeof(defaultValue)==="object"){
        inputRef.current.value = value?.[initialState.currentLangSelected] || defaultValue?.[initialState.currentLangSelected] || ""
      }else{
        inputRef.current.value = value || defaultValue || ""
      }
      if(Object.keys(initialState.objLang).length>1){
        var blnIsFull = true
        Object.keys(initialState.objLang).forEach(item=>{
          if(!props?.defaultValue?.[item] && initialState.objLang[item]?.blnIsMandatory){
            blnIsFull = false
              return
          }
        })
        setInitialState(prevState => {
          return {...prevState, blnIsFull:blnIsFull}
        });
    }
    },[value, defaultValue, initialState.currentLangSelected])

    useEffect(()=>{
    },[id,label,placeholder,errorMessage,mode,
        color,icon,iconColor,adornment,endIcon,endAdornment,
        objLang,currentLang,setCurrentLang, getCurrentValue,
        value,defaultValue,
        sx,hidden,disabled,dir,className,rest,
        initialState])

//#endregion

  //#region style
    const getInputStyle = () => {
      const getStyle = String(mode).toLowerCase();
      switch (getStyle) {
        case "outlined":
          return "cbmis-input-outlined";
        case "placeholder":
          return "cbmis-input-placeholder";
        case "classic":
          return "cbmis-input-classic";
        default:
          return "cbmis-input-placeholder";
      }
    };

    useEffect(() => {
      if (sx) {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-input-container");
      }
    }, [sx, screenWidth]);

    useEffect(() => {
      var root = document.querySelector(':root');
      root.style.setProperty('--cbmis-input-color-focus', componentsFunction.checkColorReturnValue(color || "primary"));
    }, [color]);

    const getRestProps = () => {
      const newRest = componentsFunction.filterPaddingAndMargin(rest);
      return { ...newRest };
    };
  //#endregion

  //#region change
    const handelChange =(event)=>{
      inputRef.current.value=event.target.value;
      if(onChange){
        onChange(event)
      }
    }
  //#endregion

  //#region LangList
   const getDir = langRtL.includes(currentLang)?"rtl":dir
    function openList() {
    initialState.openMenu = true;

    const input = document.querySelector(`#${nameID.current}`);
    const endIcon = document.querySelector(`#${getID.current} .cbmis-input-icon-end-container`);

    document.body.style.overflowY = 'hidden';

    const left = endIcon?.getBoundingClientRect().left;
    const top = endIcon?.getBoundingClientRect().top;
    const width = input?.offsetWidth;
    const height = input?.offsetHeight;
    const lstPosition = { x: `${left - (width / 3) + 75}px`, y: `${top + height}px`, width: `${width / 3}px` };
    inputRef.current.value = value?.[currentLang] || defaultValue?.[currentLang] || ""
    setInitialState({ ...initialState, lstPosition: lstPosition });
    }

    const closeList = ()=>{
      document.body.style.overflowY = '';
      initialState.openMenu = false
      setInitialState({...initialState})
    }

    const getOptionsList=()=>{
      const Options = []
       Object.keys(initialState.objLang).forEach((item,index)=>{
            Options.push(
              <MenuItem key={index} 
                        selected={initialState.currentLangSelected === item}  p-1 
                        headItem={<Icon icon= {
                                              props?.defaultValue?.[item]? "check":
                                              initialState.objLang[item]?.blnIsMandatory === false? "NotInterested"
                                              :"close"
                                              }
                        color={props?.defaultValue?.[item]?
                                  componentsFunction.checkColorReturnValue("success"): 
                                  initialState.objLang[item]?.blnIsMandatory === false?""
                                  :componentsFunction.checkColorReturnValue("error")}/>
                                }
                        onClick={handelListChange(item)}>
                        <span className="cbmis-option-input-label cbmis-list-radio-label">{initialState.objLang[item]?.label || label?.[currentLang] || String(label)}</span>  
              </MenuItem>
            )
      })
      return Options
    }

    const handelListChange = (item)=>()=>{
      document.body.style.overflowY = '';
      initialState.currentLangSelected = item;
      initialState.openMenu = false
      if(setCurrentLang){
          setCurrentLang(item)
      }
      setInitialState({...initialState})
      
    }

  //#endregion

  return (
      <React.Suspense fallback="">
        <div className={initialState.openMenu ? "lstContainerOpen" : "lstContainerClose"} onClick={closeList} />
        <div id={getID.current}
              className={`cbmis-main-container
                          ${icon || adornment?"cbmis-input-haveIcon":""} 
                          ${componentsFunction.CheckHiddenItem(hidden) || ""}`}

              {...getRestProps()}
        >
          <div className={componentsFunction.CheckDirectionItem(getDir)}>
            <div className={`cbmis-input-container 
                            ${componentsFunction.CheckDisabledItem(disabled)} 
                            ${errorMessage?"cbmis-input-have-error":""}
                            cbmis-textArea
                          `}
                           >
              {icon?
                <div className="cbmis-input-icon-container" >
                <Icon icon= {icon} color={errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}/>
                </div>
                :adornment?
                <div className="cbmis-input-adornment-container" style={{color:errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}} >
                    <label>{adornment}</label>
                </div>
                :""
                }
                <textarea
                  id={nameID.current} name={nameID.current}
                  className={`${getInputStyle()} cbmis-textArea-resize ${styleRef?.current?.strSxClassName} ${placeholder?"cbmis-input-have-placeholder":""}${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""} `}
                  placeholder ={placeholder || " "}
                  ref={ref || inputRef}
                  onChange={handelChange}
                  rows={line || 4}
                  {...getRestProps()}
              />
              {label?.[initialState.currentLangSelected] || label?.default || label?
                <label className="cbmis-input-label" htmlFor={`${nameID.current}`} data-placeholder={label?.[initialState.currentLangSelected] || label?.default || label} />
              :""}

              {initialState?.objLang && Object.keys(initialState?.objLang).length >1?
                <div className="cbmis-input-icon-end-container cbmis-input-icon-end-lang-list" onClick={openList}>
                    {
                    initialState.currentLangSelected?
                    <Typography as="caption" 
                                // color={initialState.blnIsFull?"success":"error"}
                        >
                        {initialState.objLang?.[initialState.currentLangSelected].label || initialState.objLang?.[initialState.currentLangSelected].label}
                    </Typography>
                    :<Icon icon="language" 
                                // color={initialState.blnIsFull?iconColor || "success":"error"}
                    />
                    }
                    <Icon icon= {!initialState.blnIsFull?"arrowDropDownCircleOutlined":"checkCircleOutlined"}
                          // color={initialState.blnIsFull?iconColor || "success":"error"}
                    />
                </div>
              :endIcon?
                  <div className="cbmis-input-icon-end-container" >
                  <Icon icon= {endIcon} color={errorMessage?componentsFunction.checkColorReturnValue("error"):iconColor?componentsFunction.checkColorReturnValue(iconColor):""}/>
                  </div>
              :endAdornment?
                  <div className="cbmis-input-endAdornment-container" style={{color:errorMessage?componentsFunction.checkColorReturnValue("error"):iconColor?componentsFunction.checkColorReturnValue(iconColor):""}} >
                      <label>{endAdornment}</label>
                  </div>
              :""}
            </div>
            {initialState?.objLang && Object.keys(initialState?.objLang).length>1?
              <Menu  open={initialState.openMenu} className="cbmis-lang-list-options"  sx={{top:`${initialState?.lstPosition?.y}`,left:`${initialState?.lstPosition?.x} !important`,width:`${initialState?.lstPosition?.width}`}} >
                      {getOptionsList()}
              </Menu> 
            :""} 
            <ErrorMessage errorMessage={errorMessage} />
          </div>
        </div>
      </React.Suspense>
    )
});

export default TextArea