import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import Text from "../Text/Text";
import Icon from "../../../icon/CbmisIcon";


const Number = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,
          max=100,min=0,step=1,setValue,
          sx,hidden,disabled,dir,className,...rest} = props

    const [theValue, setTheValue] = useState(defaultValue || "");

    const nameID = useRef(id || componentsFunction.randomName("Number"));

    const handelAdd = (event) => {
      let newValue = theValue
      if(!newValue){
        newValue = parseInt(max) || 0;
      }
      const theStep =parseInt(step);
      if(newValue < max){
          newValue = parseInt(newValue) + theStep;
      }else if(!max && parseInt(min) !== 0){
        newValue = parseInt(newValue) + theStep;
      }
      if(newValue > max){
        newValue = max
      }
        setTheValue(newValue);
    };

    const handelMinus = (event) => {
      let newValue = theValue

      if(!newValue){
        newValue = parseInt(min) || 0;
      }
      const theStep =parseInt(step);
      if(newValue > min){
          newValue = parseInt(newValue) - theStep;
      }else if(!min && parseInt(min) !== 0){
        newValue = parseInt(newValue) - theStep;
      }
      if(newValue < min){
        newValue = min
      }
        setTheValue(newValue);
    };

    useEffect(()=>{
      if(setValue && theValue){
        setValue(theValue);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[theValue])


  return (
      <React.Suspense fallback="">
        <Text
          ref={ref}
          type="number"
          id={nameID.current}
          name={nameID.current}
          label={label}
          placeholder={placeholder}
          errorMessage={errorMessage}
          mode={mode}
          color={color}
          icon={icon}
          iconColor={iconColor}
          adornment={adornment}
          endComponent={
            <div className="cbmis-input-icon-end-container cbmis-input-number-buttons-container">
                  <Icon icon="add" onClick={handelAdd} p-0 m-0  />
                  <Icon icon="minimize" onClick={handelMinus} p-0 m-0/>
              </div>
          }
          endIcon={endIcon}
          endAdornment={endAdornment}
          value={value}
          defaultValue={theValue}
          onChange={onChange}
          endIconRest={""}
          sx={sx}
          hidden={hidden}
          disabled={disabled}
          dir={dir}
          className={className}
          min={min}
          max={max}
          step={step}
          {...rest}
          />
      </React.Suspense>
    )
});

export default Number