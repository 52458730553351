import React, { useEffect, useRef } from "react";
import "./Badge.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Badge = (props) => {
  const { content, mode, vertical, horizontal, color, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("Badge"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-badge-content") });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };
  const getStyleMode = () => {
    const getStyle = String(mode).toLowerCase();
    switch (getStyle) {
      case "dot":
        return "cbmis-mdl-badge-dot";
      default:
        return "";
    }
  };

  const getHorizontal = () => {
    const getHorizontal = String(horizontal).toLowerCase();
    switch (getHorizontal) {
      case "left":
        return "cbmis-badge-badge-horizontal-left";
      default:
        return "cbmis-badge-badge-horizontal-right";
    }
  };

  const getVertical = () => {
    const getVertical = String(vertical).toLowerCase();
    switch (getVertical) {
      case "bottom":
        return "cbmis-badge-badge-vertical-bottom";
      default:
        return "cbmis-badge-badge-vertical-top";
    }
  };

  useEffect(() => {}, [content, mode, vertical, horizontal, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx || color) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, { ...(sx ? sx : {}), ...(color ? { background: `${color}` } : {}) }, "cbmis-badge-content");
    }
  }, [sx, screenWidth, color]);

  return (
    <div id={getID.current} className={`cbmis-badge-container ${componentsFunction.CheckDirectionItem(dir)}`} {...getRestProps()}>
      <div className={`cbmis-badge-label cbmis-mdl-badge ${getStyleMode()} ${getVertical()} ${getHorizontal()}`}>
        {props.children}
        <div
          id={styleRef.current.strSXComponentID}
          className={`cbmis-badge-content ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
            hidden
          )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default Badge;
