import { generateQueries } from "../../../../graphql/schema/GenerateQuery";
import { appLangDirection, dictionary } from "../../../../util/appLanguage/dictionary";
import * as appFunctions from "../../../../util/appFunctions";
import { App_ID } from "../../../../configuration/appVariables";
import { alertStyle } from "../../../../util/appStyle";
import { Alert } from "@cbmisorg/material";

const strPageInfo = "@src/components/stakeholders/shared/manageMembers/controler/CtrlProfile.js";

const tblUser = generateQueries("tblUser");

export const CtrlProfile = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  let handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields, fieldEmail, fieldPhone }) =>
      async () => {
        try {
          fields.controller.resetForm();

          // const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          // let lstCountryOption = [];
          let lstDegreeOption = [];
          // let lstEmploymentOption = [];
          let lstMajorOption = [];
          // let lstAcademicYearOption = [];
          // let lstGPAOption = [];
          let lstGenderOption = [];

          // Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          //   if (key !== "id" && key !== "value") {
          //     lstCountryOption.push({ key: value?.id, value: value?.value });
          //   }
          // });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.value });
            }
          });
          // Object.entries(objCat?.Root?.employment || {}).forEach(([key, value]) => {
          //   if (key !== "id" && key !== "value") {
          //     lstEmploymentOption.push({ key: value?.id, value: value?.value });
          //   }
          // });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          // Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value]) => {
          //   if (key !== "id" && key !== "value") {
          //     lstAcademicYearOption.push({ key: value?.id, value: value?.value });
          //   }
          // });
          // Object.entries(objCat?.Root?.gpa || {}).forEach(([key, value]) => {
          //   if (key !== "id" && key !== "value") {
          //     lstGPAOption.push({ key: value?.id, value: value?.value });
          //   }
          // });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions(lstMajorOption);
          fields.bigDegreeID.setOptions(lstDegreeOption);
          fields.bigGender.setOptions(lstGenderOption);
          // fields.bigEmploymentID.setOptions(lstEmploymentOption);

          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
          }
          // if (appState?.userInfo?.jsnUserInfo?.bigEmploymentID) {
          //   fields.bigEmploymentID.setValue(appState?.userInfo?.jsnUserInfo?.bigEmploymentID);
          // }
          if (appState?.userInfo?.jsnUserInfo?.bigDegreeID) {
            fields.bigDegreeID.setValue(appState?.userInfo?.jsnUserInfo?.bigDegreeID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
            fields.bigWorkSector.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
          }

          fieldEmail.strRestEmail.setValue(appState?.userInfo?.strUserEmail);
          if (appState?.userInfo?.strUserPhone) {
            fieldPhone.strUserPhone.setValue(appState?.userInfo?.strUserPhone);
          }

          state.blnOpenSetterModal = true;
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },
    onChangeCountry:
      ({ fields }) =>
      (event) => {
        const bigCountryID = event?.target?.id;

        const objCat = appFunctions.getCategoryCache();

        let lstUniversityOption = [];
        let objUniversityOptionLang = {};

        Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstUniversityOption.push({ key: value?.id, value: value?.id });
            objUniversityOptionLang[value?.id] = value?.value;
          }
        });

        lstUniversityOption.push({ key: "other", value: "other" });
        objUniversityOptionLang["other"] = dictionary.shared.btn.otherBtn;

        fields.strUniversityName.setOptions(lstUniversityOption, objUniversityOptionLang);
      },

    handelSubmit:
      ({ fields, isLoading, setIsLoading, navigate }) =>
      async () => {
        try {
          if (!fields.controller.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);

          const newObj = {
            jsnUserInfo: {
              ...appState?.userInfo?.jsnUserInfo,
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
              strMajor: fields?.bigMajorID?.getValue()?.key,
              bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
              bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
              bigGender: fields?.bigGender?.getValue()?.key,
            },
            dtmUpdatedDate: new Date().toUTCString(),
          };
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: newObj,
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(false);
          appState.userInfo.jsnUserInfo = newObj.jsnUserInfo;
          appDispatch();
          Alert.viewAlert(dictionary.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handelSubmit-ERROR", error);
          setIsLoading(false);
        }
      },

    funRestPassword:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        try {
          if (!fields.controller.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);

          const result = await tblUser(strPageInfo, appState, "aaiotAppUserResetPassword", {
            objectInput: {
              strUserPassword: fields?.strRestPassword?.getValue(),
            },
            objectCondition: {
              bigAppID: App_ID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              bigUserID: appState.userInfo?.bigUserID,
              strUserEmail: appState?.userInfo?.strUserEmail,
              strUserPassword: fields?.strOldUserPassword?.getValue(),
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          state.modalPasswordOpen = false;
          setState({ ...state, blnIsInitDone: true });
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
          fields?.controller?.resetForm();
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "funRestPassword-ERROR", error);
          setIsLoading(false);
        }
      },
    funRestEmail:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        try {
          if (!fields.controller.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (String(appState?.userInfo?.strUserEmail).toLowerCase().trim() === String(fields?.strRestEmail?.getValue()).toLowerCase().trim()) {
            state.modalEmailOpen = false;
            setState({ ...state, blnIsInitDone: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
            return;
          }

          setIsLoading(true);
          const resultRead = await tblUser(strPageInfo, appState, "aaiotAppUserOne", {
            arrAttributes: ["bigUserID"],
            objectCondition: {
              bigAppID: App_ID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              strUserEmail: fields?.strRestEmail?.getValue(),
            },
          });

          if (resultRead.blnIsRequestSuccessful && resultRead?.response?.bigUserID && !Number.isNaN(Number(resultRead?.response?.bigUserID))) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.email_Already_Used?.[lang], "info", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }

          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: {
              strUserEmail: fields?.strRestEmail?.getValue(),
            },
            objectCondition: {
              bigAppID: App_ID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              bigUserID: appState?.userInfo?.bigUserID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.email?.[lang] || dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          appState.userInfo.strUserEmail = fields?.strRestEmail?.getValue();
          state.modalEmailOpen = false;
          setState({ ...state, blnIsInitDone: true });
          appDispatch();
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "funRestEmail-ERROR", error);
          setIsLoading(false);
        }
      },

    funRestPhone:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        try {
          if (!fields.controller.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (
            String(appState?.userInfo?.strUserPhone).toLowerCase().trim() ===
            String(fields?.strUserPhone?.getValue())
              .toLowerCase()
              .trim()
          ) {
            state.modalPhoneOpen = false;
            setState({ ...state, blnIsInitDone: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
            return;
          }

          setIsLoading(true);
          const resultRead = await tblUser(strPageInfo, appState, "aaiotAppUserOne", {
            arrAttributes: ["bigUserID"],
            objectCondition: {
              bigSystemID: appState?.userInfo?.bigSystemID,
              strUserPhone: String(fields?.strUserPhone?.getValue()).trim(),
            },
          });

          if (resultRead?.blnIsRequestSuccessful && resultRead?.response?.bigUserID && !Number.isNaN(Number(resultRead?.response?.bigUserID))) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.phone_Already_Used?.[lang], "info", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }

          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: {
              strUserPhone: fields?.strUserPhone?.getValue(),
            },
            objectCondition: {
              bigSystemID: appState?.userInfo?.bigSystemID,
              bigUserID: appState?.userInfo?.bigUserID,
            },
          });
          
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.phoneNumber?.[lang] || dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }

          setIsLoading(false);
          appState.userInfo.strUserPhone =  String(fields?.strUserPhone?.getValue()).trim();
          state.modalPhoneOpen = false;
          setState({ ...state, blnIsInitDone: true });
          appDispatch();
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "funRestPhone-ERROR", error);
          setIsLoading(false);
        }
      },

    funCloseModal:
      ({ state, setState }) =>
      () => {
        setState({ ...state, modalPasswordOpen: false, modalEmailOpen: false, modalPhoneOpen: false });
      },
    getPhoneGlobalKey: (bigCountryID) => {
      try {
        const objCat = appFunctions.getCategoryCache();
        return appFunctions.appCategoryGetValue(objCat?.Root?.country, bigCountryID, "phoneCode", true);
      } catch {
        return "";
      }
    },
    handelChangeEmployment:
      ({ fields }) =>
      () => {
        try {
          const employmentValue = fields.bigEmploymentID?.getValue()?.key;

          if (objCat?.Root?.employment?.student?.id === employmentValue) {
            fields?.strUniversityName?.setValidation("required", true);
            fields?.bigAcademicYearID?.setValidation("required", true);
            fields?.bigGpaID?.setValidation("required", true);
          }
          if (objCat?.Root?.employment?.unemployed?.id === employmentValue) {
            fields?.strUniversityName?.setValidation("required", true);
            fields?.bigGpaID?.setValidation("required", true);
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "handelChangeEmployment-ERROR", error);
        }
      },

    objCat: objCat,
  };

  return handler;
};
