import React, { useEffect, useRef } from 'react'

export default function MessageBox(props) {
  const {conversion, setConversion,objChat,errorMsg,lastRebotMsg} =props
  const inputMsg = useRef("")
  const inputMsgTemp = useRef("")


  const handelSubmit=()=>{
    const inputMsgValue = inputMsg.current.value
    if(inputMsgValue){
    inputMsgTemp.current=inputMsgValue
    conversion.push({message:[{text:inputMsgValue}],isPersonal:true})
    inputMsg.current.value=""
    conversion.push({message:[{text:""}],isPersonal:false,isLoading:true}) //loading msg
    setConversion([...conversion])
    botMsg()

  
    }
}

const handelPressEnter=(event)=>{
  if (event.key === "Enter") {
    event.preventDefault();
    handelSubmit()
  }
}

const botMsg = ()=>{
  
  let newMessage= { "message": [{text:errorMsg}]}
  let newSelectedMsgInfo =null
  if(lastRebotMsg.current.blnNoSearch){
    newSelectedMsgInfo = objChat?.[lastRebotMsg.current?.nextIndex]

  }else if(lastRebotMsg.current.options){
    const nextIndex = lastRebotMsg.current.options.filter(item=>(item.keyWord).includes(inputMsgTemp.current))?.[0]?.["nextIndex"]
    newSelectedMsgInfo = objChat?.[nextIndex]
  }else{
    const arrObjChat = Object.values(objChat)
    newSelectedMsgInfo = arrObjChat?.filter(item=>(item.keyWord).includes(inputMsgTemp.current))?.[0]
  }

  if(newSelectedMsgInfo){
    newMessage= newSelectedMsgInfo
  }

  conversion.pop()
  conversion.push({...newMessage,isPersonal:false,isLoading:false})
  lastRebotMsg.current={...newMessage,isPersonal:false,isLoading:false}
  setConversion([...conversion])
}

  const handelOptionSelect=(option)=>()=>{
    conversion.push({message:[{text:option.label}],options:[],isPersonal:true,isLoading:false})
    const newMessage= {...objChat[option.nextIndex],isPersonal:false,isLoading:false}
    conversion.push(newMessage)
    lastRebotMsg.current=newMessage
    setConversion([...conversion])

  }

  useEffect(() => {
    var chatMessages = document.querySelector(".cbmis-chat-messages .cbmis-chat-messages-content");
    chatMessages.scrollTop = chatMessages.scrollHeight;
  }, [conversion])

  return (
    <React.Fragment>
          <div className='cbmis-chat-message-lst-box'>
            {
              conversion[conversion.length-1]?.options?.map((option,index)=>{
                return(
                  <label className='cbmis-chat-message-option' key={index} onClick={handelOptionSelect(option)}>
                    {option.label}
                  </label>
                )
              })
            }
          </div>

          <div className="cbmis-chat-message-box">
              <input type="text" 
                      className="cbmis-chat-message-input" 
                      placeholder="Type message..."
                      ref={inputMsg}
                      onKeyUp={handelPressEnter}
                      />
              <button type="submit" 
                      className="cbmis-chat-message-submit"
                      onClick={handelSubmit}
                    >Send</button>
          </div>
    </React.Fragment>
  )
}
