import React from 'react'
import Avatar from "../../../../material/dataDisplay/Avatar/Avatar"
import Icon from "../../../../icon/CbmisIcon"

export default function AgentHeader({handelChatOpen,blnAgentImg=true,icon,iconColor,iconSize,avatarColor,avatarSize}) {
  return (
    <React.Fragment>
         <div className="cbmis-agent-face-img-container" onClick={handelChatOpen}>
            <div className="">
                <Avatar className="agent cbmis-agent-face-circle-avatar" 
                        color={avatarColor}
                        src={blnAgentImg?"https://image.shutterstock.com/image-vector/chatbot-robo-advisor-adviser-chat-260nw-1222464061.jpg":null}
                        sx={{width:avatarSize,height:avatarSize}}
                        >
                          <Icon icon={icon} size={iconSize || "25px"} color={iconColor || "dark"}/>
                        </Avatar>
            </div>
        </div>
        <div className="cbmis-chat-title">
            {/* <h1>ChatBot</h1> */}
        </div>
    </React.Fragment>
  )
}
