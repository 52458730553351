import { Grid, Paper, Typography } from "@cbmisorg/client-app";
import React from "react";
import { appStyle } from "../../../../../appHelper/appStyle";
import { FormField } from "../../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";

const labels = dictionary?.components?.manageSystem;

function SponerConfig({ fields, stateInfo, appState,}) {
    const lang = appState?.clientInfo?.strLanguage;

  return (
    <React.Fragment>
      <Grid container justify={"center"} spacing={3}>
        <Grid item xs="12">
          <Paper className="cardContainer">
            <Grid container spacing={2} py-0>
              <Grid item xs="12">
                <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                  {labels?.SponercConfig?.[lang]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs="12">
                <FormField objHandler={fields.blnIsConfiremSponer} />
              </Grid>
              <Grid item xs="12">
                <FormField objHandler={fields?.blnIsPaid} py-1 value={stateInfo?.systemInfo?.jsnSystemConfig?.blnIsPaid ? "true" : "false"} />
              </Grid>
              {fields?.blnIsPaid?.getValue()?.key !== "paid" ? null : (
                <Grid item xs="12">
                  <FormField objHandler={fields?.strBankInfo} py-1 line={10} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              )}
              {fields?.blnIsPaid?.getValue()?.key === "hideCard" ? null : (
                <Grid item xs="12" pt-5>
                  <FormField objHandler={fields?.lstContrySponser} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SponerConfig;
