import React, { useEffect, useState } from "react";
import {
  Avatar,
  ClientDirection,
  Confirmation,
  Container,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  Paper,
  PermissionControl,
  Typography,
  useNavigate,
} from "@cbmisorg/client-app";
import Header from "../../sharedUi/header/ShredHeader";
import { App_Primary_Color } from "../../../appHelper/appColor";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";

import imgAddToHomeChroum1 from "../../../assets/images/addToHomeChroum1.jpg";
import imgAddToHomeChroum2 from "../../../assets/images/addToHomeChroum2.jpg";
import imgAddToHomeChroum3 from "../../../assets/images/addToHomeChroum3.jpg";
import imgAddToHomeChroum4 from "../../../assets/images/addToHomeChroum4.jpg";

import imgAddToHomeEdge1 from "../../../assets/images/addToHomeEdge1.jpg";
import imgAddToHomeEdge2 from "../../../assets/images/addToHomeEdge2.jpg";
import imgAddToHomeEdge3 from "../../../assets/images/addToHomeEdge3.jpg";
import imgAddToHomeEdge4 from "../../../assets/images/addToHomeChroum4.jpg";

import imgAddToHomeSafari1 from "../../../assets/images/addToHomeSafari1.jpg";
import imgAddToHomeSafari2 from "../../../assets/images/addToHomeSafari2.jpg";
import imgAddToHomeSafari3 from "../../../assets/images/addToHomeSafari3.jpg";
import imgAddToHomeSafari4 from "../../../assets/images/addToHomeSafari4.jpg";
import { clearAppLocalStorage, detectBrowser } from "../../../appHelper/appFunctions";
import FontSizeSection from "./FontSizeSection";
import objAppRouting from "../../../appHelper/routing/appRouting";
import { useApp } from "../../../../client/configuration/contextapi/context";

// const localStorage = get
function RouteSettings() {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [openModal, setopenModal] = useState({ open: false, image: "", caption: "" });
  const navigate = useNavigate();

  const appPermissionState = appState?.clientInfo?.appPermissionState;

  const handelAppPermissionState = (newAppPermissionState) => {
    appState.clientInfo.appPermissionState = { ...newAppPermissionState };
    appDispatch();
  };

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  const funLogout = () => {
    Confirmation.viewConfirmation(
      dictionary?.shared?.confirmationMsg?.logout?.[lang],
      () => {
        clearAppLocalStorage(appState);
        navigate(objAppRouting?.Public_LandingPage?.url, { replace: true });
      },
      () => {},
      dictionary?.shared?.confirmationMsg?.yesSure?.[lang],
      dictionary?.shared?.confirmationMsg?.no?.[lang]
    );
  };
  function fnBrowserDetect() {
    const setImage = (ahImag1, ahImag12, ahImag3, ahImag4) => {
      return (
        <Grid item xs="12" container justify="center" dir="rtl" spacing="2">
          <Grid item xs="5" lg="3" container justify="center">
            <Grid item xs="12">
              <img
                src={ahImag1}
                alt=""
                width="75%"
                style={{ maxWidth: "200px" }}
                onClick={() => setopenModal({ open: true, image: ahImag1, caption: "النقر على الثلاث نقاط في الاعلى" })}
              />
            </Grid>
            <Grid item xs="12">
              <Typography sx={{ textAlign: "center" }}>
                <Avatar sx={{ width: "25px", height: "25px" }} color={App_Primary_Color}>
                  1
                </Avatar>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs="5" lg="3" container justify="center">
            <Grid item xs="12">
              <img
                src={ahImag12}
                alt=""
                width="75%"
                style={{ maxWidth: "200px" }}
                onClick={() => setopenModal({ open: true, image: ahImag12, caption: "اختر الاضافة الى الشاشة الرئيسية" })}
              />
            </Grid>
            <Grid item xs="12">
              <Typography sx={{ textAlign: "center" }}>
                <Avatar sx={{ width: "25px", height: "25px" }} color={App_Primary_Color}>
                  2
                </Avatar>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs="5" lg="3" container justify="center">
            <Grid item xs="12">
              <img src={ahImag3} alt="" width="75%" style={{ maxWidth: "200px" }} onClick={() => setopenModal({ open: true, image: ahImag3, caption: "انقر على الاضافة" })} />
            </Grid>
            <Grid item xs="12">
              <Typography sx={{ textAlign: "center" }}>
                <Avatar sx={{ width: "25px", height: "25px" }} color={App_Primary_Color}>
                  3
                </Avatar>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs="5" lg="3" container justify="center">
            <Grid item xs="12">
              <img
                src={ahImag4}
                alt=""
                width="75%"
                style={{ maxWidth: "200px" }}
                onClick={() => setopenModal({ open: true, image: ahImag4, caption: "تم تثبيت ايقونة منصة ماس على الصفحة الرئيسية لجهازك" })}
              />
            </Grid>
            <Grid item xs="12">
              <Typography sx={{ textAlign: "center" }}>
                <Avatar sx={{ width: "25px", height: "25px" }} color={App_Primary_Color}>
                  4
                </Avatar>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    };

    let browser = detectBrowser();
    switch (browser) {
      case "edge":
        return setImage(imgAddToHomeEdge1, imgAddToHomeEdge2, imgAddToHomeEdge3, imgAddToHomeEdge4);
      case "safari":
        return setImage(imgAddToHomeSafari1, imgAddToHomeSafari2, imgAddToHomeSafari3, imgAddToHomeSafari4);

      default:
        return setImage(imgAddToHomeChroum1, imgAddToHomeChroum2, imgAddToHomeChroum3, imgAddToHomeChroum4);
    }
  }
  const handelChangeLang = () => {
    if (lang === "arb") {
      ClientDirection.setDirection("ltr");
      appState.clientInfo.strLanguage = "eng";
      appState.clientInfo.strDataLanguage = "eng";
      appDispatch();
    } else {
      ClientDirection.setDirection("rtl");
      appState.clientInfo.strLanguage = "arb";
      appState.clientInfo.strDataLanguage = "arb";
      appDispatch();
    }
  };

  return (
    <React.Fragment>
      <Header title={dictionary?.components?.public?.footer?.settings?.[lang]} lang={lang} blnHome={false} blnLang={false} blnLangStart={false} />

      <Container pb-10 className="appPermission">
        <Grid container p-3>
          <Grid item xs="12">
            <Paper className="cardContainer">
              <Grid container justify="space-between">
                <Grid item>
                  <Typography as="caption">
                    {{ eng: `Change language to Arabic`, arb: `تغيير اللغة الى الإنجليزية` }?.[lang]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Icon icon="translate" onClick={handelChangeLang} color={"primary"} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container p-3>
          <Grid item xs="12">
            <FontSizeSection lang={lang} />
          </Grid>
        </Grid>

        <Grid container justify="center" p-3>
          <Grid item xs="12">
            <Paper className="cardContainer">
              <PermissionControl appPermissionState={appPermissionState} changeAppPermissionState={handelAppPermissionState} color={App_Primary_Color} lang={lang} />
            </Paper>
          </Grid>
        </Grid>

        <Grid container p-3>
          <Grid item xs="12">
            <Paper className="cardContainer">
              <Grid container justify="center">
                <Grid item xs="12">
                  <Typography className="textcenter">{{ eng: "To add the application to the home screen", arb: "لإضافة التطبيق إلى الصفحة الرئيسية" }?.[lang]}</Typography>
                </Grid>

                {fnBrowserDetect()}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {!appState?.clientInfo?.blnIsUserLoggedIn ? null : (
          <Grid container p-3>
            <Grid item xs="12">
              <Paper className="cardContainer">
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography as="caption">{{ eng: "Logout", arb: "تسجيل الخروج" }?.[lang]}</Typography>
                  </Grid>
                  <Grid item>
                    <Icon icon="logout" onClick={funLogout} color={"error"} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
        <Grid container pb-10 />
      </Container>

      <Modal open={openModal.open} eventClose={() => setopenModal({ open: false, image: "", caption: "" })}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Grid container justify="center">
            <Grid item xs="12">
              <img src={openModal.image} alt="" width="95%" style={{ minWidth: "250px", maxWidth: "250px" }} />
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle2" color={App_Primary_Color}>
                {
                  // openModal.caption
                }
              </Typography>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RouteSettings;
