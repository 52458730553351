import React from "react";
import { Button, Grid, Icon, Typography } from "@cbmisorg/client-app";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import PaperSection from "../../../../sharedUi/paperSection/PaperSection";
import { objPotentialSponsorshipStatus } from "../../../../../appHelper/appVariables";

const labels = dictionary.components.mngPotentialSponsorship;

function Notes({ appState, handlers, fields, state, setState, blnWithDate = true }) {
  const lang = appState?.clientInfo?.strLanguage;
  const openDetailDialog = handlers.openDetailDialog({ state, setState , fields});

  return (
    <React.Fragment>
      <Grid container spacing={2} justify={"center"} py-0>
        {(state.lstTracking || [])?.map((note, index) =>
          note?.jsnPartnershipTrackInfo?.blnHasAlert && note?.bigTrackStatusID !== objPotentialSponsorshipStatus?.Approved && note?.bigTrackStatusID !== objPotentialSponsorshipStatus?.Rejected ? (
            <Grid item xs="12">
              <PaperSection>
                <Grid container>
                  <Grid item xs="10" md="11">
                    <Grid item xs="1" md="auto">
                      <Icon icon={"NotificationsActive"} color="primary" />
                    </Grid>
                    <Grid item xs="11" md="11">
                      <Typography as="body2" color="primary" px-2>
                        {blnWithDate
                          ? labels?.noteContact(
                              note?.jsnPartnershipTrackInfo?.strFullName,
                              note?.jsnPartnershipTrackInfo?.strSponsorName,
                              appFunctions?.getDateUSFormat(note?.jsnTrackingHistory?.[note?.jsnTrackingHistory?.length - 1]?.dtmAlert)
                            )?.[lang]
                          : labels?.noteContactNoDate(
                              note?.jsnPartnershipTrackInfo?.strFullName,
                              note?.jsnPartnershipTrackInfo?.strSponsorName,
                              appFunctions?.getDateUSFormat(note?.jsnTrackingHistory?.[note?.jsnTrackingHistory?.length - 1]?.dtmAlert)
                            )?.[lang]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs="2" md="1" sx={{ justifyContent: "end" }}>
                    <Button icon="info" color="primary" onClick={openDetailDialog(note, index)} />
                  </Grid>
                </Grid>
              </PaperSection>
            </Grid>
          ) : null
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Notes;
