import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { generateQueries } from "../../../../../appHelper/appQuery/GenerateQuery";
import * as appVariables from "../../../../../appHelper/appVariables";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as XLSX from "xlsx";

const tblPartnershipTrack = generateQueries("tblPartnershipTrack");
const tblSystem = generateQueries("tblSystem");

const strPageInfo = "@src/components/stakeholders/shared/suggestedSponsor/SuggestedSponsor.controller.js";

export default function suggestedSponsorCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ state, setState, isLoading, setIsLoading, strTrainingType }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDataDone = true;
          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region get data system from DB

          state.objSponsorCatalogue = JSON.parse(JSON.stringify(appState?.clientInfo?.objSystemInfo?.jsnSponsorCatalogue));
          if (state?.objSponsorCatalogue?.list && Array.isArray(state?.objSponsorCatalogue?.list)) {
            state.objSponsorCatalogue.list = state?.objSponsorCatalogue?.list?.reverse();
          } else {
            const resultSystem = await tblSystem(strPageInfo, appState, "aaiotAppFindOne", {
              arrAttributes: ["jsnSponsorCatalogue"],
              objectCondition: {
                bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
              },
            });
            if (!resultSystem?.blnIsRequestSuccessful) {
              Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning");
              setIsLoading(false);
              return;
            }
            state.objSponsorCatalogue = resultSystem?.response?.jsnSponsorCatalogue;
            if (!appState?.clientInfo?.objSystemInfo?.jsnSponsorCatalogue) {
              appState.clientInfo.objSystemInfo = { ...appState.clientInfo.objSystemInfo };
            }
            appState.clientInfo.objSystemInfo.jsnSponsorCatalogue = resultSystem?.response?.jsnSponsorCatalogue;
          }
          //#endregion

          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-initData");
          setIsLoading(false);
        }
      },
    sortSponsorCatalogue:
      ({ fldSearch, strTrainingType }) =>
      (objCatalogue) => {
        try {
          const jsnSponsor = objCatalogue;
          if (objCatalogue?.lstTrainingType && Array.isArray(objCatalogue?.lstTrainingType)) {
            if (strTrainingType && objCatalogue.lstTrainingType?.includes(strTrainingType)) {
              return false;
            }
          }

          let blnIsMatched = true;
          const bigWorkSectorFilter = fldSearch?.bigSearchWorkSector?.getValue()?.key;
          if (bigWorkSectorFilter && bigWorkSectorFilter !== "all") {
            if (String(jsnSponsor?.bigWorkSector) === String(bigWorkSectorFilter)) {
              blnIsMatched &&= true;
            } else {
              blnIsMatched &&= false;
            }
          }

          const strSearch = fldSearch?.strSearchCatalogue?.getValue();
          if (strSearch) {
            if (String(jsnSponsor?.strSponsorName)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatched &&= true;
            } else if (String(jsnSponsor?.strSponsorEmail)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatched &&= true;
            } else if (String(jsnSponsor?.strSponsorPhone)?.toLowerCase().trim().includes(String(strSearch)?.toLowerCase().trim())) {
              blnIsMatched &&= true;
            } else {
              blnIsMatched &&= false;
            }
          }

          return blnIsMatched;
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-sortSponsorCatalogue");
          return true;
        }
      },

    openViewDialog:
      ({ state, setState }) =>
      (intIndex) =>
      () => {
        try {
          state.blnOpenViewModal = true;
          state.intIndexSelected = intIndex;

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openLogDialog");
        }
      },

    openSponsorCatalogueAdd:
      ({ state, setState, fldCatalogue }) =>
      () => {
        try {
          fldCatalogue.controller.resetForm();
          state.blnOpenAddSponsorCatalogueModal = true;
          fldCatalogue?.addNote?.setValue(["true"]);
          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openSponsorCatalogueAdd");
        }
      },
    deleteSponsorCatalogueFromDB:
      ({ state, setState, isLoading, setIsLoading, intIndex }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update data DB

          if (!state.objSponsorCatalogue?.list || !Array.isArray(state.objSponsorCatalogue?.list)) {
            if (!state.objSponsorCatalogue) {
              state.objSponsorCatalogue = {};
            }
            state.objSponsorCatalogue.list = { ...state.objSponsorCatalogue?.list };
            state.objSponsorCatalogue.list = [];
          }
          if (state?.objSponsorCatalogue?.list?.length - 1 >= intIndex) {
            state.objSponsorCatalogue.list.splice(intIndex, 1);
          }

          appState.clientInfo.objSystemInfo.jsnSponsorCatalogue = JSON.parse(JSON.stringify(state.objSponsorCatalogue));
          appState.clientInfo.objSystemInfo.jsnSponsorCatalogue.list.reverse();

          const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables?.aaiot_System_ID,
            },
            objectInput: {
              jsnSponsorCatalogue: appState.clientInfo.objSystemInfo.jsnSponsorCatalogue,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update cache

          state.blnOpenAddSponsorCatalogueModal = false;

          setState({ ...state });
          setIsLoading(false);
          appDispatch();
          Alert.viewAlert(dictionary.shared.alertFetchNote.deleteded_Successfully?.[lang], "success");
          //#endregion
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-deleteSponsorCatalogueFromDB");
        }
      },
    confirmDeleteSponsorCatalogue:
      ({ state, setState, isLoading, setIsLoading }) =>
      (intIndex) =>
      () => {
        Confirmation.viewConfirmation(
          dictionary.components.suggestedSponsor.confirmDeleteSponsorCatalogue?.[lang],
          handler.deleteSponsorCatalogueFromDB({ state, setState, isLoading, setIsLoading, intIndex }),
          () => null,
          dictionary.shared.confirmationMsg.yesSure?.[lang],
          dictionary.shared.confirmationMsg.no?.[lang]
        );
      },

    openEditDialogFromCatalogue:
      ({ state, setState, fields }) =>
      () => {
        try {
          state.blnOpenSponsorCatalogueModal = false;
          state.blnIsAddFromCatalogue = true;
          const objSponsor = state.objSponsorCatalogue.list?.[state.intIndexSelected];

          fields.controller.resetForm();

          fields.strSponsorName.setValue(objSponsor?.strSponsorName);
          fields.strSponsorEmail.setValue(objSponsor?.strSponsorEmail==="-"?"":objSponsor?.strSponsorEmail);
          fields.strSponsorPhone.setValue(objSponsor?.strSponsorPhone==="-"?"":objSponsor?.strSponsorPhone);
          fields.bigWorkSector.setValue(objSponsor?.bigWorkSector);
          fields.strURL.setValue(objSponsor?.strURL==="-"?"":objSponsor?.strURL);

          setState({ ...state });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "ERROR-openEditDialogFromCatalogue");
        }
      },

    addNewSponsorCatalogueToDB:
      ({ state, setState, isLoading, setIsLoading, fldCatalogue, strTrainingType }) =>
      async () => {
        try {
          if (!fldCatalogue?.strURL?.getValue() && !appFunctions.isValidUrl(fldCatalogue?.strURL?.getValue())) {
            fldCatalogue?.strURL?.controller?.setError("strURL", dictionary?.shared?.formValidationsMsg?.url);
            return;
          }
          if (!fldCatalogue.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(false);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add data DB
          const bigTrackID = appFunctions.generateID(10);

          if (!state?.objSponsorCatalogue?.list || !Array.isArray(state?.objSponsorCatalogue?.list)) {
            if (!state?.objSponsorCatalogue) {
              state.objSponsorCatalogue = {};
            }
            state.objSponsorCatalogue.list = { ...state.objSponsorCatalogue.list };
            state.objSponsorCatalogue.list = [];
          }

          state.objSponsorCatalogue.list.splice(0, 0, {
            id: bigTrackID,
            strSponsorName: fldCatalogue.strSponsorName.getValue(),
            strSponsorEmail: fldCatalogue.strSponsorEmail.getValue(),
            strSponsorPhone: fldCatalogue.strSponsorPhone.getValue(),
            bigWorkSector: fldCatalogue.bigWorkSector.getValue()?.key,
            strURL: fldCatalogue.strURL.getValue(),
            strTrainingType: strTrainingType,
            dtmCreatedDate: new Date().toUTCString(),
            bigUserID: appState?.userInfo?.bigUserID,
          });

          appState.clientInfo.objSystemInfo.jsnSponsorCatalogue = JSON.parse(JSON.stringify(state.objSponsorCatalogue));
          appState.clientInfo.objSystemInfo.jsnSponsorCatalogue.list.reverse();

          const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
            },
            objectInput: {
              jsnSponsorCatalogue: appState.clientInfo.objSystemInfo.jsnSponsorCatalogue,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update cache

          state.blnOpenAddSponsorCatalogueModal = false;
          state.blnOpenViewModal = false;

          setState({ ...state });
          setIsLoading(false);
          appDispatch();
          Alert.viewAlert(dictionary.shared.alertFetchNote.added_Successfully?.[lang], "success");
          //#endregion
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-addNewSponsorCatalogueToDB");
        }
      },

    addToDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType, setSuggestedAsPotential }) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add data DB

          const bigTrackID = appFunctions.generateID(10);

          const result = await tblPartnershipTrack(strPageInfo, appState, "aaiotAppAdd", {
            objectInput: {
              bigAppID: appState?.clientInfo?.bigAppID,
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
              bigTrackID: bigTrackID,
              bigTrackStatusID: appVariables.objPotentialSponsorshipStatus?.Submission,
              blnAAIOTContact: true,
              intUpdateStatusNumber: 1,
              jsnPartnershipTrackInfo: {
                strSponsorName: fields.strSponsorName.getValue(),
                strSponsorEmail: fields.strSponsorEmail.getValue(),
                strSponsorPhone: fields.strSponsorPhone.getValue(),
                strFullName: fields.strFullNameRecipient.getValue(),
                strUserEmail: fields.strUserEmailRecipient.getValue(),
                strUserPhone: fields.strUserPhoneRecipient.getValue(),
                bigWorkSector: fields.bigWorkSector.getValue()?.key,
                strURL: fields.strURL.getValue(),
                strTrainingType: strTrainingType,
              },
              jsnTrackingHistory: [
                {
                  bigTrackStatusID: appVariables.objPotentialSponsorshipStatus?.Submission,
                  intUpdateStatusNumber: 1,
                  strNotes: fields.strNotes.getValue(),
                  dtmCreatedDate: new Date().toUTCString(),
                  dtmUpdatedDate: new Date().toUTCString(),
                },
              ],
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          if (setSuggestedAsPotential && typeof setSuggestedAsPotential === "function") {
            setSuggestedAsPotential(result.response);
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update System

          if (!state?.objSponsorCatalogue?.list) {
            if (!state?.objSponsorCatalogue) {
              state.objSponsorCatalogue = {};
            }
            state.objSponsorCatalogue.list = { ...state?.objSponsorCatalogue?.list };
          }
          if (!Array.isArray(state.objSponsorCatalogue.list)) {
            state.objSponsorCatalogue.list = [];
          }

          if (Array.isArray(state?.objSponsorCatalogue?.list?.[state?.intIndexSelected].lstTrainingType) && state?.objSponsorCatalogue?.list?.[state?.intIndexSelected].lstTrainingType?.length) {
            state?.objSponsorCatalogue?.list?.[state?.intIndexSelected].lstTrainingType.push(strTrainingType);
          } else {
            state.objSponsorCatalogue.list[state?.intIndexSelected].lstTrainingType = [strTrainingType];
          }

          appState.clientInfo.objSystemInfo.jsnSponsorCatalogue = JSON.parse(JSON.stringify(state.objSponsorCatalogue));
          appState.clientInfo.objSystemInfo.jsnSponsorCatalogue.list.reverse();

          const resultSystem = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
            },
            objectInput: {
              jsnSponsorCatalogue: appState.clientInfo.objSystemInfo.jsnSponsorCatalogue,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });
          if (!resultSystem?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update cache
          state.blnIsAddFromCatalogue = false;
          state.blnOpenViewModal = false;

          setState({ ...state });
          setIsLoading(false);
          appDispatch();
          Alert.viewAlert(dictionary.shared.alertFetchNote.added_Sponser_Successfully?.[lang], "success");
          //#endregion
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-addToDB");
        }
      },

    confirmAddToDB:
      ({ state, setState, isLoading, setIsLoading, fields, strTrainingType, setSuggestedAsPotential }) =>
      () => {
        fields.strSponsorPhone.setValue(String(fields.strSponsorPhone?.getValue("")).replace(" ", ""))
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }
        if (!fields.strUserEmailRecipient.getValue() && !fields.strUserPhoneRecipient.getValue()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field_Email_phone?.[lang], "");
          fields.strUserEmailRecipient.controller.setError("strUserEmailRecipient", "*");
          fields.strUserPhoneRecipient.controller.setError("strUserPhoneRecipient", "*");
          return;
        }
        Confirmation.viewConfirmation(
          dictionary.components.mngPotentialSponsorship.sponserBook?.[lang],
          handler.addToDB({ state, setState, isLoading, setIsLoading, fields, strTrainingType, setSuggestedAsPotential }),
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

    getAddedBeforeLabel:
      ({ state }) =>
      () => {
        try {
          const dtmCreatedDate = state?.objSponsorCatalogue?.list?.[state?.intIndexSelected]?.dtmCreatedDate || new Date();
          let intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "day"));

          let strLabel = "";
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.days?.[lang];
            if (intAddedBefore === 2) {
              strLabel = dictionary.components.mngPotentialSponsorship.twoDay?.[lang];
            } else if (intAddedBefore < 2) {
              strLabel = dictionary.components.mngPotentialSponsorship.day?.[lang];
            }
          }

          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          intAddedBefore = Math.round(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "hour"));
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.hour?.[lang];
          }

          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          intAddedBefore = Math.floor(appFunctions.getDifferenceBetweenTwoDate(dtmCreatedDate, new Date().toUTCString(), "minute"));
          if (intAddedBefore > 0) {
            strLabel = dictionary.components.mngPotentialSponsorship.minute?.[lang];
          }
          if (strLabel && intAddedBefore) {
            return `( ${dictionary.components.mngPotentialSponsorship?.before?.[lang]} ${(intAddedBefore < 3 ? "" : intAddedBefore) + " " + strLabel} )`;
          }

          return `( ${dictionary.components.mngPotentialSponsorship?.now?.[lang]} )`;
        } catch {
          return "-";
        }
      },

    onchangeEmailOrPhone:
      ({ fields }) =>
      () => {
        if (fields?.strUserEmailRecipient?.getValue() && !fields?.strUserPhoneRecipient?.getValue()) {
          fields?.strUserEmailRecipient?.controller?.setError("strUserPhoneRecipient", "");
        } else if (fields?.strUserPhoneRecipient?.getValue() && !fields?.strUserEmailRecipient?.getValue()) {
          fields?.strUserEmailRecipient?.controller?.setError("strUserEmailRecipient", "");
        } else if (!fields?.strUserPhoneRecipient?.getValue() && !fields?.strUserEmailRecipient?.getValue()) {
          fields?.strUserEmailRecipient?.controller?.setError("strUserPhoneRecipient", "*");
          fields?.strUserEmailRecipient?.controller?.setError("strUserEmailRecipient", "*");
        }
      },

    exportDataFormXSLX:
      ({ isLoading, setIsLoading, setState, state, strTrainingType }) =>
      async (e) => {
        try {
          e.preventDefault();

          var files = e.target.files,
            f = files[0];
          var reader = new FileReader();
          reader.onload = async function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: "binary" });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            if(!state?.objSponsorCatalogue?.list){
              state.objSponsorCatalogue.list = [];
            }
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            for (let index = 0; index < dataParse.length; index++) {
              const element = dataParse[index];
              const bigTrackID = appFunctions.generateID(10);
              if (!state?.objSponsorCatalogue?.list) {
                state.objSponsorCatalogue.list = [
                  {
                    id: bigTrackID,
                    strSponsorName: element?.[0],
                    strSponsorEmail: element?.[3] === "-" ? "" : element?.[3],
                    strSponsorPhone: element?.[2] === "-" ? "" : element?.[2],
                    bigWorkSector: element?.[4],
                    strURL: element?.[1] === "-" ? "" : element?.[1],
                    strTrainingType: strTrainingType,
                    dtmCreatedDate: new Date().toUTCString(),
                    bigUserID: appState?.userInfo?.bigUserID,
                  },
                ];
              } else {
                state.objSponsorCatalogue.list.splice(0, 0, {
                  id: bigTrackID,
                  strSponsorName: element?.[0],
                  strSponsorEmail: element?.[3] === "-" ? "" : element?.[3],
                  strSponsorPhone: element?.[2] === "-" ? "" : element?.[2],
                  bigWorkSector: element?.[4],
                  strURL: element?.[1] === "-" ? "" : element?.[1],
                  strTrainingType: strTrainingType,
                  dtmCreatedDate: new Date().toUTCString(),
                  bigUserID: appState?.userInfo?.bigUserID,
                });
              }
            }
            if (isLoading) {
              Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning");
              return;
            }

            setIsLoading(true);
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //#region add data DB
            appState.clientInfo.objSystemInfo.jsnSponsorCatalogue = JSON.parse(JSON.stringify(state.objSponsorCatalogue));
            appState.clientInfo.objSystemInfo.jsnSponsorCatalogue.list.reverse();

            const result = await tblSystem(strPageInfo, appState, "aaiotAppUpdate", {
              objectCondition: {
                bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
              },
              objectInput: {
                jsnSponsorCatalogue: appState.clientInfo.objSystemInfo.jsnSponsorCatalogue,
                dtmUpdatedDate: new Date().toUTCString(),
              },
            });
            if (!result?.blnIsRequestSuccessful) {
              Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Save_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }
            //#endregion
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //#region update cache

            state.blnOpenAddSponsorCatalogueModal = false;
            state.blnOpenViewModal = false;

            setState({ ...state });
            setIsLoading(false);
            appDispatch();
            Alert.viewAlert(dictionary.shared.alertFetchNote.added_Successfully?.[lang], "success");
            //#endregion
          };
          reader.readAsBinaryString(f);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "ERROR-addNewSponsorCatalogueToDB");
        }
      },
    fetchMoreDataScroll:
      ({ setState, state }) =>
      () => {},
  };

  return handler;
}
