import { useEffect, useState } from "react";
import mngAnnouncementCtrl from "./mngAnnouncement.controller";
import { useApp } from "../../../../../client/configuration/contextapi/context";

export default function AnnouncementDisplay() {
  const { appState, appDispatch } = useApp();
  const [state, setState] = useState({
    blnIsFetchDone: false,
    lstMeeting: [],
    lstNotification: [],
  });

  const handlers = mngAnnouncementCtrl({ appState, appDispatch });

  const getAnnouncementToDisplay = handlers.getAnnouncementToDisplay({ state, setState });
  const getIsMeetingAvailable = handlers.getIsMeetingAvailable;
  const getIsLiveMeetingLive = handlers.getIsLiveMeetingLive;
  const goToExternalMeeting = handlers.goToExternalMeeting;

  useEffect(() => {
    if (!state.blnIsFetchDone) {
      getAnnouncementToDisplay();
    }
  }, []);

  return {
    objAnnouncement: state,
    getIsMeetingAvailable,
    getIsLiveMeetingLive,
    goToExternalMeeting,
  };
  // <>
  //   <Container sx={{ direction: appLangDirection?.[lang], width: "100%" }}>
  //     {!state.lstMeeting?.length ? null : (
  //       <Grid container spacing="0" sx={{ direction: appLangDirection?.[lang] }} my-4>
  //         <Grid item p-0 m-0 justifyContent="center" alignItems="center" alignSelf="stretch" sx={classes.meetingIconContainer}>
  //           <Icon icon="liveTv" sx={classes.meetingIcon} />
  //         </Grid>
  //         <Grid item p-0 m-0 sx={classes.meetingAccordionContainer}>
  //           <Accordion>
  //             <AccordionTab sx={classes.meetingAccordionTap}>
  //               <AccordionSummary sx={classes.meetingAccordionSummery} p-0>
  //                 <Grid container justifyContent="space-between" alignItems="center">
  //                   <Grid xs={8} md={9} lg={10} item p-0>
  //                     <Typography sx={classes.meetingAccordionTitleText} as="subtitle2">
  //                       {labels.label?.newLiveSessionAvailable?.[lang]}
  //                     </Typography>
  //                   </Grid>

  //                   <Grid item p-0 alignItems="center">
  //                     <Avatar color={classes.meetingAccordionTitleCounterColor} sx={classes.meetingAccordionTitleCounter} p-0 m-0>
  //                       {state.lstMeeting?.length || "0"}
  //                     </Avatar>
  //                   </Grid>
  //                 </Grid>
  //               </AccordionSummary>

  //               <AccordionDetails p-0>
  //                 {(state.lstMeeting || []).map((liveSession, intIndex) => {
  // let blnIsAvailable = getIsMeetingAvailable(liveSession);
  // let blnIsLive = getIsLiveMeetingLive(liveSession);

  // if (!blnIsAvailable && !blnIsLive) {
  //   return null;
  // }

  //                   return (
  //                     <React.Fragment key={liveSession?.bigID}>
  //                       <Grid container sx={classes.meetingDetailContainer}>
  //                         <Grid item xs={12} md={10} alignItems="center">
  //                           <Grid item xs={12} px-2>
  //                             <Typography as="subtitle3" sx={classes.meetingDetailTitleText}>
  //                               {liveSession?.strAnnouncementTitle?.[lang]}
  //                             </Typography>
  //                           </Grid>

  //                           <Grid item xs={12} px-2>
  //                             <Typography as="body2">
  //                               {blnIsLive ? (
  //                                 <>
  //                                   {`${labels.label.liveSessionCurrentLive?.[lang]} ${labels.label.from?.[lang]} `}
  //                                   <span style={classes.meetingSpanDate}>
  //                                     {appFunctions.getDateUSFormat(appFunctions.getCurrentDate(liveSession?.dtmFrom).toLocaleString(), true)}
  //                                   </span>
  //                                   {` ${labels.label.to?.[lang]} `}
  //                                   <span style={classes.meetingSpanDate}>
  //                                     {appFunctions.getDateUSFormat(appFunctions.getCurrentDate(liveSession?.dtmTo).toLocaleString(), true)}
  //                                   </span>
  //                                 </>
  //                               ) : blnIsAvailable ? (
  //                                 <>
  //                                   {`${labels.label.liveSessionAvailable?.[lang]} ${labels.label.from?.[lang]} `}
  //                                   <span style={classes.meetingSpanDate}>
  //                                     {appFunctions.getDateUSFormat(appFunctions.getCurrentDate(liveSession?.dtmFrom).toLocaleString(), true)}
  //                                   </span>
  //                                   {` ${labels.label.to?.[lang]} `}
  //                                   <span style={classes.meetingSpanDate}>
  //                                     {appFunctions.getDateUSFormat(appFunctions.getCurrentDate(liveSession?.dtmTo).toLocaleString(), true)}
  //                                   </span>
  //                                 </>
  //                               ) : null}
  //                             </Typography>
  //                           </Grid>

  //                           {!liveSession?.lstFile?.length ? null : (
  //                             <Grid item xs={12} px-2>
  //                               <UploaderReader lstFiles={liveSession?.lstFile} />
  //                             </Grid>
  //                           )}
  //                         </Grid>

  //                         <Grid item xs={12} md={2} justifyContent="flex-end" alignItems="center">
  //                           {!blnIsLive ? null : (
  //                             <Button
  //                               size="sm"
  //                               label={dictionary.shared.btn.openBtn?.[lang]}
  //                               p-0
  //                               color={classes.meetingOpenLinkColor}
  //                               onClick={goToExternalMeeting(liveSession?.strMeetingLink)}
  //                             />
  //                           )}
  //                         </Grid>

  //                         {intIndex >= state.lstMeeting?.length - 1 ? null : <Line sx={classes.meetingLineSeptate} my-1 p-0 />}
  //                       </Grid>
  //                     </React.Fragment>
  //                   );
  //                 })}
  //               </AccordionDetails>
  //             </AccordionTab>
  //           </Accordion>
  //         </Grid>
  //       </Grid>
  //     )}

  //     {!state.lstNotification.length ? null : (
  //       <Grid container spacing="0" sx={{ direction: appLangDirection?.[lang] }} my-4>
  //         <Grid item p-0 m-0 justifyContent="center" alignItems="center" alignSelf="stretch" sx={classes.notificationIconContainer}>
  //           <Icon icon="info" sx={classes.notificationIcon} />
  //         </Grid>
  //         <Grid item p-0 m-0 sx={classes.notificationAccordionContainer}>
  //           <Accordion>
  //             <AccordionTab sx={classes.notificationAccordionTap}>
  //               <AccordionSummary sx={classes.notificationAccordionSummery} p-0>
  //                 <Grid container justifyContent="space-between" alignItems="center">
  //                   <Grid xs={8} md={9} lg={10} item p-0>
  //                     <Typography sx={classes.notificationAccordionTitleText} as="subtitle2">
  //                       {labels.label?.newAnnouncement?.[lang]}
  //                     </Typography>
  //                   </Grid>

  //                   <Grid item p-0 alignItems="center">
  //                     <Avatar color={classes.notificationAccordionTitleCounterColor} sx={classes.notificationAccordionTitleCounter} p-0 m-0>
  //                       {state.lstNotification?.length || "0"}
  //                     </Avatar>
  //                   </Grid>
  //                 </Grid>
  //               </AccordionSummary>

  //               <AccordionDetails p-0>
  //                 {(state.lstNotification || []).map((msg, intIndex) => {
  //                   return (
  //                     <React.Fragment key={msg?.bigID}>
  //                       <Grid container sx={classes.notificationDetailContainer}>
  //                         <Grid xs={12} item px-2>
  //                           <Typography as="subtitle3" sx={classes.notificationDetailTitleText}>
  //                             {msg?.strAnnouncementTitle?.[lang]}
  //                           </Typography>
  //                         </Grid>
  //                         <Grid item xs={12} px-2>
  //                           <Typography as="body2" sx={classes.notificationDetailBodyText}>
  //                             {msg?.strAnnouncementBody?.[lang]}
  //                           </Typography>
  //                         </Grid>
  //                         <Grid item xs={12} p-2>
  //                           <Grid container justifyContent="space-between">
  //                             <Grid item alignItems="center">
  //                               <Typography sx={classes.notificationCreateDate} as="caption">
  //                                 {appFunctions.getDateUSFormat(msg?.dtmCreatedDate, true)}
  //                               </Typography>
  //                             </Grid>
  //                             <Grid item alignItems="center">
  //                               {!msg?.lstFile?.length ? null : <UploaderReader lstFiles={msg?.lstFile} justifyContent="flex-end" />}
  //                             </Grid>
  //                           </Grid>
  //                         </Grid>
  //                         {intIndex >= state.lstNotification?.length - 1 ? null : <Line sx={classes.notificationLineSeptate} my-1 p-0 />}
  //                       </Grid>
  //                     </React.Fragment>
  //                   );
  //                 })}
  //               </AccordionDetails>
  //             </AccordionTab>
  //           </Accordion>
  //         </Grid>
  //       </Grid>
  //     )}
  //   </Container>
  // </>
}
