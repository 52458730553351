import React, { Children, useEffect } from "react";

function ThemeProvider(props) {
  const { theme } = props;

  let cssVar = {
    gray: "--cbmis-gray",
    dark: "--cbmis-gray-dark",
    primary: "--cbmis-primary",
    secondary: "--cbmis-secondary",
    success: "--cbmis-success",
    info: "--cbmis-info",
    warning: "--cbmis-warning",
    error: "--cbmis-error",
    light: "--cbmis-light",

    fontSizeXS:"--cbmis-font-size-xs",
    fontSizeSM:"--cbmis-font-size-sm",
    fontSizeMD:"--cbmis-font-size-md",
    fontSizeLG:"--cbmis-font-size-lg",
    fontSizeXL:"--cbmis-font-size-xl",
    fontSizeIcon:"--cbmis-font-size-icon",

    bodyFontSize:"--cbmis-font-size", // default
    bodyFontFamily:"--cbmis-body-font-family",
    bodyFontWeight:"--cbmis-body-font-weight",
    bodyLineHeight:"--cbmis-body-line-height",
    bodyColor:"--cbmis-body-color",
    bodyBackgroundColor:"--cbmis-body-bg",
    bodyTextAlign:"--cbmis-body-text-align",
  };

  const changeObject = () => {
    Object.entries(theme).forEach((item) => {
      const thrVar = cssVar?.[item[0]];
      const theValue = item[1];
      if (thrVar) {
        changeVar(thrVar, theValue);
      }
    });
  };
  const changeVar = (variable, value) => {
    if (variable && value) {
      const root = document.querySelector(":root");
      root.style.setProperty(variable, value);
    }
  };

  useEffect(() => {
    changeObject();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return <React.Fragment>{props.children}</React.Fragment>;
}

export default ThemeProvider;
