import React, {  useEffect, useState } from "react";
import suggestedSponsorCtrl from "./mngSuggestedSponsor.controller";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionTab,
  Button,
  Grid,
  Icon,
  Line,
  Loader,
  MenuItem,
  Modal,
  ModalBody,
  ModalHeader,
  Typography,
  useParams,
} from "@cbmisorg/client-app";
import { App_Dark_Color, App_Primary_Color, App_Text_Color, App_orang_color } from "../../../../../appHelper/appColor";
import NoResultImg from "../../../../sharedUi/noResultImg/NoResultImg";
import * as appVariables from "../../../../../appHelper/appVariables";
import { FormData, FormField } from "../../../../../appHelper/formApi/FormAPI";
import { useApp } from "../../../../../../client/configuration/contextapi/context";

function MngSuggestedSponsor({ setSuggestedAsPotential = null }) {
  const { strTrainingType } = useParams();
  const labels = dictionary.components.mngPotentialSponsorship;

  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDataDone: false,
    objSponsorCatalogue: {},
    intNumViewCatalogue: 0,
    blnOpenViewModal: false,
    intIndexSelected: -1,
    blnOpenSponsorCatalogueModal: false,
    blnOpenAddSponsorCatalogueModal: false,
    blnIsAddFromCatalogue: false,
    inCurentScrollIndex: 0,
    inNumberItemScroll: 50,
  });

  const fldSearch = FormData({
    strSearchCatalogue: {
      strControl: "text",
      objLanguage: false,
    },
    bigSearchWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: "all", value: labels?.allWorkSector?.[lang] },
        { key: appVariables.workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
      value: { key: "all", value: labels?.allWorkSector?.[lang] },
    },
  });

  const fields = FormData({
    strSponsorName: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strSponsorEmail: { strControl: "text", objLanguage: false, objValidations: { email: true } },
    strSponsorPhone: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } } },
    bigWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: appVariables.workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
    },
    strURL: { strControl: "text", objLanguage: false },
    strFullNameRecipient: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strUserEmailRecipient: { strControl: "text", objLanguage: false, objValidations: { email: true } },
    strUserPhoneRecipient: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } } },
    addNote: { strControl: "checkbox", objLanguage: false, lstOption: [{ key: "true", value: labels?.addNotes?.[lang] }] },
    strNotes: { strControl: "textarea", objLanguage: false },
  });

  const fldCatalogue = FormData({
    strSponsorName: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strSponsorEmail: { strControl: "text", objLanguage: false, objValidations: { email: true } },
    strSponsorPhone: { strControl: "text", objLanguage: false, objValidations: { onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } } },
    bigWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: appVariables.workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: appVariables.workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
    },
    strURL: { strControl: "text", objLanguage: false },
  });

  const handlers = suggestedSponsorCtrl({ appState, appDispatch });
  const initData = handlers.initData({ state, setState, isLoading, setIsLoading, strTrainingType });
  const confirmAddToDB = handlers.confirmAddToDB({ fields, setState, state, setIsLoading, isLoading, strTrainingType, setSuggestedAsPotential });

  const openSponsorCatalogueAdd = handlers.openSponsorCatalogueAdd({ setState, state, fldCatalogue });
  const sortSponsorCatalogue = handlers.sortSponsorCatalogue({ fldSearch, strTrainingType });
  const openViewDialog = handlers.openViewDialog({ setState, state });
  const addNewSponsorCatalogueToDB = handlers.addNewSponsorCatalogueToDB({ setState, state, fldCatalogue, isLoading, setIsLoading, strTrainingType });
  const openEditDialogFromCatalogue = handlers.openEditDialogFromCatalogue({ setState, state, fields });
  const confirmDeleteSponsorCatalogue = handlers.confirmDeleteSponsorCatalogue({ setState, state, setIsLoading, isLoading });
  const onchangeEmailOrPhone = handlers.onchangeEmailOrPhone({ fields });
  const exportDataFormXSLX = handlers.exportDataFormXSLX({ setState, state, setIsLoading, isLoading, strTrainingType });

  useEffect(() => {
    if (!state.blnIsInitDataDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      {!isLoading ? null : <Loader color={App_Dark_Color} backdrop={true} />}
      <Grid container justify={"space-between"}>
        <Grid item xs={5} sx={{ background: "#ffffff" }} p-0>
          <FormField objHandler={fldSearch?.bigSearchWorkSector} placeholder={labels?.searchByWorkSector?.[lang]} dir={lang === "arb" ? "rtl" : "ltr"} />
        </Grid>
        <Grid item xs="5" md={6} sx={{ background: "#ffffff" }} p-0>
          <FormField objHandler={fldSearch?.strSearchCatalogue} placeholder={labels?.searchBySponserName?.[lang]} icon={"search"} dir={lang === "arb" ? "rtl" : "ltr"} />
        </Grid>
        <Grid item>
          <Button size="sm" icon="add" onClick={openSponsorCatalogueAdd} />
        </Grid>
      </Grid>

      <Grid container justify={"center"}>
        <Grid item xs={12}>
          {state?.objSponsorCatalogue?.list?.length ? null : (
            <Grid container justify={"center"}>
              <NoResultImg xs={8} md={6} lg={6} />
            </Grid>
          )}

          {!state?.objSponsorCatalogue?.list?.length ? null : (
            <Grid container>
              {(Array.isArray(state?.objSponsorCatalogue?.list) ? state?.objSponsorCatalogue?.list : []).map((objSponsor, intIndex) => {
                if (intIndex > state?.inNumberItemScroll) {
                  return null;
                }

                if (!sortSponsorCatalogue(objSponsor)) {
                  if (!state.intNumViewCatalogue && intIndex >= state?.objSponsorCatalogue?.list?.length - 1) {
                    return <NoResultImg key={objSponsor?.id} xs={6} />;
                  }
                  return null;
                }

                ++state.intNumViewCatalogue;

                return (
                  <Grid item xs="12" key={objSponsor?.id}>
                    <MenuItem sx={{ width: "100%", border: `1px solid ${App_Text_Color}`, background: "#ffffff", color: App_Primary_Color }} outlined>
                      <Grid container>
                        <Grid item xs="8" md="10">
                          <Grid container>
                            <Grid item md={"auto"} xs={12} py-0>
                              <Typography as="caption" pb-0 color="primary">
                                {appVariables.workSectorIDLabel?.[objSponsor?.bigWorkSector]?.[lang] || "-"}
                              </Typography>
                            </Grid>
                            <Grid p-0 item md={"auto"} xs={"0"}>
                              /
                            </Grid>
                            <Grid item md={"auto"} xs={12} py-0>
                              <Typography as="caption" pb-0 color="primary" sx={{ fontWeight: "bold" }}>
                                {objSponsor?.strSponsorName?.[lang] || objSponsor?.strSponsorName || "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs="4" md="2" justify="end">
                          {objSponsor?.bigUserID !== appState?.userInfo?.bigUserID &&
                          appState.userInfo.jsnUserInfo?.intUserRoleFK !== appVariables.objUserRole.RootAdmin ? null : (
                            <Button size="sm" icon="delete" color="error" mode="link" onClick={confirmDeleteSponsorCatalogue(intIndex)} mx-2 />
                          )}

                          <Button size="sm" icon="visibility" mode="link" onClick={openViewDialog(intIndex)} />
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      {state?.inNumberItemScroll >= state?.objSponsorCatalogue?.list?.length - 1 || !state?.objSponsorCatalogue?.list?.length ? null : (
        <Grid container justify={"center"}>
          <Grid item xs="12">
            <Button
              label={dictionary?.shared?.btn?.readMore?.[lang]}
              onClick={() => setState({ ...state, inNumberItemScroll: state?.objSponsorCatalogue?.list?.length - 1 })}
            />
          </Grid>
        </Grid>
      )}

      {/*  Sponsor Catalogue Detail Modal */}

      <Modal size="sm" open={state.blnOpenViewModal} eventClose={() => setState({ ...state, blnOpenViewModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="caption" sx={{ textAlign: "center !important", fontWeight: "bold !important" }}>
                {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorName?.[lang] ||
                  state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorName ||
                  "-"}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody>
          <Grid container>
            <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
              <Typography as="caption">
                <b>{dictionary.formapi.bigWorkSector?.label?.[lang]}</b> :{" "}
              </Typography>
              <Typography as="caption">
                <span style={{ color: App_orang_color }}>
                  {appVariables.workSectorIDLabel?.[state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.bigWorkSector]?.[lang] || "-"}
                </span>
              </Typography>
            </Grid>

            {!state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorEmail ? null : (
              <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                <Typography as="caption">
                  <b>{dictionary.formapi.strSponsorEmail?.label?.[lang]}</b> :{" "}
                </Typography>
                <Typography as="caption">
                  <a href={`mailto:${state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorEmail}`} target="_blank">
                    {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorEmail}
                  </a>
                </Typography>
              </Grid>
            )}

            {!state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorPhone ? null : (
              <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                <Typography as="caption">
                  <b>{dictionary.formapi.strSponsorPhone?.label?.[lang]}</b> :
                </Typography>
                <Typography as="caption">
                  <a href={`tel:${state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorPhone}`} target="_blank">
                    {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorPhone}
                  </a>
                </Typography>
              </Grid>
            )}

            {!state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strURL ? null : (
              <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                <Typography as="caption">
                  <b>{dictionary.formapi.strURL?.label?.[lang]}</b> :{" "}
                </Typography>
                <Typography as="caption" sx={{ overflow: "hidden", whiteSpace: "nowrap !important", textOverflow: "ellipsis", maxWidth: "99%", direction: "ltr" }}>
                  <a href={state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strURL} target="_blank">{state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strURL}</a>
                </Typography>
              </Grid>
            )}

            {!strTrainingType ? null : (
              <Grid item xs={12} justify="center" mt-3>
                <Button py-0 size="sm" sx={{ height: "28px" }} icon={"fileUpload"} label={labels?.sendBook?.[lang]} onClick={openEditDialogFromCatalogue} />
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>

      {/*  Add From Sponsor Catalogue To Potential Sponsor Modal */}

      <Modal size="xl" open={state.blnIsAddFromCatalogue} eventClose={() => setState({ ...state, blnIsAddFromCatalogue: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                {labels.addPotentialPartnerInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody>
          <Grid container justify={"center"}>
            <Grid item md={12} xs={12} pb-3>
              <Accordion dir={lang === "arb" ? "rtl" : "ltr"}>
                <AccordionTab>
                  <AccordionSummary
                    py-1
                    expandIcon={<Icon icon="business" color={App_Primary_Color} />}
                    sx={{ border: `1px solid ${App_Primary_Color}`, borderRadius: "0.2em" }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography as={"subtitle2"}>{fields.strSponsorName?.getValue()?.[lang] || fields.strSponsorName?.getValue()}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs="12">
                        <Typography as="caption" pb-0>
                          <b>{dictionary.formapi.strSponsorName?.label?.[lang]}</b> :{" "}
                          <span style={{ color: App_orang_color }}>
                            {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorName?.[lang] ||
                              state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorName ||
                              "-"}
                          </span>
                        </Typography>
                      </Grid>

                      <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                        <Typography as="caption">
                          <b>{dictionary.formapi.bigWorkSector?.label?.[lang]}</b> :{" "}
                          {appVariables.workSectorIDLabel?.[state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.bigWorkSector]?.[lang] || "-"}
                        </Typography>
                      </Grid>

                      {!state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorEmail ? null : (
                        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                          <Typography as="caption">
                            <b>{dictionary.formapi.strSponsorEmail?.label?.[lang]}</b> :{" "}
                            <a href={`mailto:${state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorEmail}`}>
                              {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorEmail}
                            </a>
                          </Typography>
                        </Grid>
                      )}

                      {!state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorPhone ? null : (
                        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                          <Typography as="caption">
                            <b>{dictionary.formapi.strSponsorPhone?.label?.[lang]}</b> :
                            <a href={`tel:${state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorPhone}`}>
                              {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strSponsorPhone}
                            </a>
                          </Typography>
                        </Grid>
                      )}

                      {!state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strURL ? null : (
                        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
                          <Typography as="caption">
                            <b>{dictionary.formapi.strURL?.label?.[lang]}</b> :{" "}
                            <a href={state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strURL}>
                              {state.objSponsorCatalogue?.list?.[state.intIndexSelected]?.strURL}
                            </a>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </AccordionTab>
              </Accordion>
            </Grid>
          </Grid>
          <Line my-3 sx={{ width: "100%" }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                as="caption"
                sx={{
                  borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                  paddingInlineStart: "8px !important",
                  color: App_Primary_Color,
                  fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                }}
              >
                {labels?.recipientInfo?.[lang]}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormField objHandler={fields.strFullNameRecipient} icon="AccountCircle" />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormField objHandler={fields.strUserEmailRecipient} icon="email" className={`inputLeft inputLeft${lang}`} onChange={onchangeEmailOrPhone} />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormField
                objHandler={fields.strUserPhoneRecipient}
                icon={lang === "arb" ? "phone" : null}
                endIcon={lang !== "arb" ? "phone" : null}
                endAdornment={lang === "arb" ? appVariables.globalKey : null}
                adornment={lang !== "arb" ? appVariables.globalKey : null}
                className={`inputLeft`}
                onChange={onchangeEmailOrPhone}
              />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fields.addNote} />
            </Grid>
            {!fields.addNote?.getValue()?.length ? null : (
              <Grid item xs="12" pt-0>
                <FormField objHandler={fields.strNotes} />
              </Grid>
            )}
          </Grid>
          <Grid container justify={"center"} my-3>
            <Grid item>
              <Button label={labels?.addSendBook?.[lang]} onClick={confirmAddToDB} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      {/*  Add To Sponsor Catalogue Modal */}

      <Modal size="xl" open={state.blnOpenAddSponsorCatalogueModal} eventClose={() => setState({ ...state, blnOpenAddSponsorCatalogueModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                {labels.addSuggestedSponserInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Grid container justify={"center"}>
            <Grid item md={12} xs={12}>
              <FormField objHandler={fldCatalogue.strSponsorName} icon="AccountCircle" />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormField objHandler={fldCatalogue.strSponsorEmail} icon="email" dir={"ltr"} />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormField objHandler={fldCatalogue.strSponsorPhone} icon="phone" dir={"ltr"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormField objHandler={fldCatalogue.bigWorkSector} icon="Work" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormField objHandler={fldCatalogue.strURL} icon="link" dir={lang === "arb" ? "ltr" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container justify={"center"} my-3 pt-3>
            <Grid item>
              <Button label={dictionary.shared.btn?.addBtn?.[lang]} onClick={addNewSponsorCatalogueToDB} />
            </Grid>
          </Grid>
          {appState?.userInfo?.jsnUserInfo?.intUserRoleFK !== appVariables.objUserRole?.RootAdmin ? null : <input type="file" onChange={exportDataFormXSLX} />}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default MngSuggestedSponsor;
