import { Alert } from "@cbmisorg/material";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import * as appFunctions from "../../../../../../util/appFunctions";
import { operationForumMode } from "../../mngRegForum.controller";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
const forumApi = generateQueries("tblForum");

const strPageInfo = "@src/client/component/stakeholders/admin/mngRegForum/components/saveForumModal/saveForumModal.controller.js";
export default function saveForumModalCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage || "arb";

  const handler = {
    openSaveForum:
      ({ state, setState, fields }) =>
      (strOperation, objForumInfo, intIndexForum) =>
      () => {
        try {
          fields.controller.resetForm();

          state.lstSubForum = [];
          state.lstForumBrochureFile = [];
          state.lstForumBadgeFile = [];
          state.lstForumCertificateFile = [];
          state.objForumBadgeSelectInfo = {};
          state.objForumCertificateSelectInfo = {};
          state.objSubForumBadgeSelectInfo = {};
          state.objSubForumCertificateSelectInfo = {};
          if (strOperation === operationForumMode.edit) {
            fields.strForumName.setValue(objForumInfo?.jsnForumInfo?.strForumName);
            fields.dtmForumDate.setValue(objForumInfo?.jsnForumInfo?.dtmForumDate);
            fields.strForumLocation.setValue(objForumInfo?.jsnForumInfo?.strForumLocation);
            fields.blnStrictRegForum.setValue(objForumInfo?.blnStrictRegForum ? ["true"] : []);
            fields.blnHaveSubForum.setValue(objForumInfo?.jsnForumInfo?.blnHaveSubForum ? ["true"] : []);
            state.lstSubForum = Array.isArray(objForumInfo?.jsnForumInfo?.lstSubForum) ? objForumInfo?.jsnForumInfo?.lstSubForum : [];
            state.lstForumBrochureFile = Array.isArray(objForumInfo?.jsnForumInfo?.lstForumBrochureFile) ? objForumInfo?.jsnForumInfo?.lstForumBrochureFile : [];
            state.lstForumBadgeFile = Array.isArray(objForumInfo?.jsnForumInfo?.lstForumBadgeFile) ? objForumInfo?.jsnForumInfo?.lstForumBadgeFile : [];
            state.lstForumCertificateFile = Array.isArray(objForumInfo?.jsnForumInfo?.lstForumCertificateFile) ? objForumInfo?.jsnForumInfo?.lstForumCertificateFile : [];
            state.objForumBadgeSelectInfo = objForumInfo?.jsnForumInfo?.objForumBadgeSelectInfo || {};
            state.objForumCertificateSelectInfo = objForumInfo?.jsnForumInfo?.objForumCertificateSelectInfo || {};
          }

          state.strOperationForum = strOperation;
          state.objForumInfo = objForumInfo;
          state.intIndexForum = intIndexForum;
          state.blnOpenSaveModal = true;
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openSaveForum-ERROR", error);
        }
      },

    openSaveSubForum:
      ({ state, setState, fldSubForum }) =>
      (strOperation, objSubForumInfo, index) =>
      () => {
        try {
          fldSubForum.controller.resetForm();

          state.lstSubForumBadgeFile = [];
          state.lstSubForumCertificateFile = [];
          state.objSubForumBadgeSelectInfo = {};
          state.objSubForumCertificateSelectInfo = {};
          if (strOperation === operationForumMode.edit) {
            fldSubForum.strSubForumName.setValue(objSubForumInfo?.strSubForumName);
            fldSubForum.dtmSubForumData.setValue(objSubForumInfo?.dtmSubForumData);
            fldSubForum.strSubForumLocation.setValue(objSubForumInfo?.strSubForumLocation);
            state.lstSubForumBadgeFile = Array.isArray(objSubForumInfo.lstSubForumBadgeFile) ? objSubForumInfo.lstSubForumBadgeFile : [];
            state.lstSubForumCertificateFile = Array.isArray(objSubForumInfo.lstSubForumCertificateFile) ? objSubForumInfo.lstSubForumCertificateFile : [];
            state.objSubForumBadgeSelectInfo = objSubForumInfo?.objSubForumBadgeSelectInfo;
            state.objSubForumCertificateSelectInfo = objSubForumInfo?.objSubForumCertificateSelectInfo;
          }

          state.strOperationSubForum = strOperation;
          state.intIndexSubForum = index;
          state.blnOpenSubForumSaveModal = true;
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openSaveSubForum-ERROR", error);
        }
      },

    saveSubForumLocally:
      ({ isLoading, setIsLoading, state, setState, fldSubForum, uploaderBadgeSubForum, uploaderCertificateSubForum }) =>
      (strOperationSubForum, index) =>
      async () => {
        if (!fldSubForum.controller.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
          return;
        }

        if (state?.lstSubForumBadgeFile?.length && !Object.keys(state?.objSubForumBadgeSelectInfo || {})?.length) {
          Alert.viewAlert({ arb: "الرجاء تحديد موقع الاسم على الباجة", eng: "Please locate the name on the badge." }?.[lang], "warning", appLangDirection?.[lang]);
          return;
        }

        if (state?.lstSubForumCertificateFile?.length && !Object.keys(state?.objSubForumCertificateSelectInfo || {})?.length) {
          Alert.viewAlert({ arb: "الرجاء تحديد موقع الاسم على الشهادة", eng: "Please locate the name on the certificate." }?.[lang], "warning", appLangDirection?.[lang]);
          return;
        }

        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
          return;
        }
        setIsLoading(true);

        if (state?.lstSubForumBadgeFile?.length) {
          const result = await uploaderBadgeSubForum?.uploadHandler("forum/badge");
          if (!result) {
            setIsLoading(false);
            return;
          }
        }
        if (state?.lstSubForumCertificateFile?.length) {
          const result = await uploaderCertificateSubForum?.uploadHandler("forum/certificate");
          if (!result) {
            setIsLoading(false);
            return;
          }
        }

        try {
          switch (strOperationSubForum || state.strOperationSubForum) {
            case operationForumMode.add:
              state.lstSubForum.push({
                bigSubForumID: appFunctions.generateID(10),
                strSubForumPath: appFunctions.generateRandomStringSecure(4),
                strSubForumName: fldSubForum.strSubForumName.getValue(),
                dtmSubForumData: new Date(fldSubForum.dtmSubForumData.getValue()),
                strSubForumLocation: fldSubForum.strSubForumLocation.getValue(),
                lstSubForumBadgeFile: state.lstSubForumBadgeFile,
                lstSubForumCertificateFile: state.lstSubForumCertificateFile,
                objSubForumBadgeSelectInfo: state?.objSubForumBadgeSelectInfo,
                objSubForumCertificateSelectInfo: state?.objSubForumCertificateSelectInfo,
                dtmCreatedDate: new Date(),
              });
              break;
            case operationForumMode.edit:
              state.lstSubForum[state.intIndexSubForum] = {
                ...state.lstSubForum[state.intIndexSubForum],
                strSubForumName: fldSubForum.strSubForumName.getValue(),
                dtmSubForumData: new Date(fldSubForum.dtmSubForumData.getValue()),
                strSubForumLocation: fldSubForum.strSubForumLocation.getValue(),
                lstSubForumBadgeFile: state.lstSubForumBadgeFile,
                lstSubForumCertificateFile: state.lstSubForumCertificateFile,
                objSubForumBadgeSelectInfo: state?.objSubForumBadgeSelectInfo,
                objSubForumCertificateSelectInfo: state?.objSubForumCertificateSelectInfo,
              };
              break;
            case operationForumMode.delete:
              state.lstSubForum.splice(index, 1);
              break;
            default:
              break;
          }

          state.blnOpenSubForumSaveModal = false;
          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "saveSubForumLocally-ERROR", error);
        }
      },

    saveForumInToDB:
      ({ isLoading, setIsLoading, state, setState, parentState, setParentState, fields, uploaderBrochure, uploaderBadge, uploaderCertificate }) =>
      async () => {
        try {
          if (!fields.controller.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang]);
            return;
          }

          if (state?.lstForumBadgeFile?.length && !Object.keys(state?.objForumBadgeSelectInfo || {})?.length) {
            Alert.viewAlert({ arb: "الرجاء تحديد موقع الاسم على الباجة", eng: "Please locate the name on the badge." }?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          if (state?.lstForumCertificateFile?.length && !Object.keys(state?.objForumCertificateSelectInfo || {})?.length) {
            Alert.viewAlert(
              { arb: "الرجاء تحديد موقع الاسم على الشهادة", eng: "Please locate the name on the certificate." }?.[lang],
              "warning",
              appLangDirection?.[lang]
            );
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }
          setIsLoading(true);

          if (state?.lstForumBrochureFile?.length) {
            const result = await uploaderBrochure?.uploadHandler("forum/brochure");
            if (!result) {
              setIsLoading(false);
              return;
            }
          }
          if (state?.lstForumBadgeFile?.length) {
            const result = await uploaderBadge?.uploadHandler("forum/badge");
            if (!result) {
              setIsLoading(false);
              return;
            }
          }
          if (state?.lstForumCertificateFile?.length) {
            const result = await uploaderCertificate?.uploadHandler("forum/certificate");
            if (!result) {
              setIsLoading(false);
              return;
            }
          }

          state.lstSubForum.sort((a, b) => new Date(a?.dtmSubForumData).getTime() - new Date(b?.dtmSubForumData).getTime());

          switch (state.strOperationForum) {
            case operationForumMode.add:
              const resultAdd = await forumApi(strPageInfo, appState, "aaiotAppAdd", {
                objectInput: {
                  bigForumID: appFunctions.generateID(10),
                  blnStrictRegForum: !fields?.blnStrictRegForum?.getValue()?.length ? false : true,
                  strForumPath: appFunctions.generateRandomStringSecure(4),
                  textSubForumPath: JSON.stringify(state.lstSubForum.map((subForum) => subForum?.strSubForumPath)),
                  jsnForumInfo: {
                    strForumName: fields?.strForumName?.getValue(),
                    dtmForumDate: fields?.dtmForumDate?.getValue(),
                    strForumLocation: fields?.strForumLocation?.getValue(),
                    lstForumBadgeFile: state.lstForumBadgeFile,
                    lstForumCertificateFile: state.lstForumCertificateFile,
                    lstForumBrochureFile: state.lstForumBrochureFile,
                    objForumBadgeSelectInfo: state.objForumBadgeSelectInfo,
                    objForumCertificateSelectInfo: state.objForumCertificateSelectInfo,
                    blnHaveSubForum: !fields?.blnHaveSubForum?.getValue()?.length ? false : true,
                    lstSubForum: state.lstSubForum,
                  },
                },
              });

              if (!resultAdd?.blnIsRequestSuccessful) {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang]);
                setIsLoading(false);
                return;
              }

              state.blnOpenSaveModal = false;
              parentState.lstForum.push(resultAdd.response);
              break;
            case operationForumMode.edit:
              const resultEdit = await forumApi(strPageInfo, appState, "aaiotAppUpdate", {
                objectCondition: {
                  bigForumID: state?.objForumInfo?.bigForumID,
                },
                objectInput: {
                  blnStrictRegForum: !fields?.blnStrictRegForum?.getValue()?.length ? false : true,
                  textSubForumPath: JSON.stringify(state.lstSubForum.map((subForum) => subForum?.strSubForumPath)),
                  jsnForumInfo: {
                    strForumName: fields?.strForumName?.getValue(),
                    dtmForumDate: fields?.dtmForumDate?.getValue(),
                    strForumLocation: fields?.strForumLocation?.getValue(),
                    lstForumBadgeFile: state.lstForumBadgeFile,
                    lstForumCertificateFile: state.lstForumCertificateFile,
                    lstForumBrochureFile: state.lstForumBrochureFile,
                    objForumBadgeSelectInfo: state.objForumBadgeSelectInfo,
                    objForumCertificateSelectInfo: state.objForumCertificateSelectInfo,
                    blnHaveSubForum: !fields?.blnHaveSubForum?.getValue()?.length ? false : true,
                    lstSubForum: state.lstSubForum,
                  },
                  dtmUpdatedDate: new Date(),
                },
              });

              if (!resultEdit?.blnIsRequestSuccessful) {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang]);
                setIsLoading(false);
                return;
              }

              state.blnOpenSaveModal = false;
              parentState.lstForum[state.intIndexForum] = {
                ...parentState.lstForum[state.intIndexForum],
                blnStrictRegForum: !fields?.blnStrictRegForum?.getValue()?.length ? false : true,
                textSubForumPath: JSON.stringify(state.lstSubForum.map((subForum) => subForum?.strSubForumPath)),
                jsnForumInfo: {
                  strForumName: fields?.strForumName?.getValue(),
                  dtmForumDate: fields?.dtmForumDate?.getValue(),
                  strForumLocation: fields?.strForumLocation?.getValue(),
                  lstForumBadgeFile: state.lstForumBadgeFile,
                  lstForumCertificateFile: state.lstForumCertificateFile,
                  lstForumBrochureFile: state.lstForumBrochureFile,
                  objForumBadgeSelectInfo: state.objForumBadgeSelectInfo,
                  objForumCertificateSelectInfo: state.objForumCertificateSelectInfo,
                  blnHaveSubForum: !fields?.blnHaveSubForum?.getValue()?.length ? false : true,
                  lstSubForum: state.lstSubForum,
                },
              };
              break;
            default:
              break;
          }

          setParentState({ ...parentState });
          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "saveForumInToDB-ERROR", error);
        }
      },
  };

  return handler;
}
