import React, { useEffect, useRef } from "react";
import "./Modal.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import Icon from "../../../icon/CbmisIcon";

const ModalHeader = (props) => {
  const { sx, hidden, disabled, dir, className, id, closeIcon, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("ModalHeader"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [hidden, disabled, dir, className, id, closeIcon]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-modal-header");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <div
      id={getID.current}
      className={`cbmis-modal-header ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
        hidden
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      <button className="cbmis-modal-close-btn">
        <Icon icon={closeIcon || "close"} />
      </button>
      {props.children}
    </div>
  );
};

export default ModalHeader;
