import { useState, useEffect } from "react";

export default function useCheckScreenSize() {
  let xsScrSize = window.matchMedia("(max-width: 600px)");
  let smScrSize = window.matchMedia("screen and (min-width: 601px) and (max-width: 900px)");
  let mdScrSize = window.matchMedia("screen and (min-width: 901px) and (max-width: 1200px)");
  let lgScrSize = window.matchMedia("screen and (min-width: 1201px) and (max-width: 1536px)");
  let xlScrSize = window.matchMedia("(min-width: 1537px)");

  const [sizeScreen, setSizeScreen] = useState(
    xsScrSize?.matches ? "xs" : smScrSize?.matches ? "sm" : mdScrSize?.matches ? "md" : lgScrSize?.matches ? "lg" : xlScrSize?.matches ? "xl" : "md"
  );

  useEffect(() => {
    function onChangeEvent(event) {
      let targetMedia = event?.media;
      let strScrMedia =
        targetMedia === xsScrSize?.media
          ? "xs"
          : targetMedia === smScrSize?.media
          ? "sm"
          : targetMedia === mdScrSize?.media
          ? "md"
          : targetMedia === lgScrSize?.media
          ? "lg"
          : targetMedia === xlScrSize?.media
          ? "xl"
          : "md";

      if (event?.matches) {
        if (strScrMedia !== sizeScreen) {
          setSizeScreen(strScrMedia);
        }
      }
    }

    if (xsScrSize?.addEventListener) {
      xsScrSize.addEventListener("change", onChangeEvent);
    } else {
      xsScrSize.addListener(onChangeEvent);
    }

    if (smScrSize?.addEventListener) {
      smScrSize.addEventListener("change", onChangeEvent);
    } else {
      smScrSize.addListener(onChangeEvent);
    }

    if (mdScrSize?.addEventListener) {
      mdScrSize.addEventListener("change", onChangeEvent);
    } else {
      mdScrSize.addListener(onChangeEvent);
    }

    if (lgScrSize?.addEventListener) {
      lgScrSize.addEventListener("change", onChangeEvent);
    } else {
      lgScrSize.addListener(onChangeEvent);
    }

    if (xlScrSize?.addEventListener) {
      xlScrSize.addEventListener("change", onChangeEvent);
    } else {
      xlScrSize.addListener(onChangeEvent);
    }

    return () => {
      if (xsScrSize?.addEventListener) {
        xsScrSize.removeEventListener("change", onChangeEvent);
      } else {
        xsScrSize.removeListener(onChangeEvent);
      }

      if (smScrSize?.addEventListener) {
        smScrSize.removeEventListener("change", onChangeEvent);
      } else {
        smScrSize.removeListener(onChangeEvent);
      }

      if (mdScrSize?.addEventListener) {
        mdScrSize.removeEventListener("change", onChangeEvent);
      } else {
        mdScrSize.removeListener(onChangeEvent);
      }

      if (lgScrSize?.addEventListener) {
        lgScrSize.removeEventListener("change", onChangeEvent);
      } else {
        lgScrSize.removeListener(onChangeEvent);
      }

      if (xlScrSize?.addEventListener) {
        xlScrSize.removeEventListener("change", onChangeEvent);
      } else {
        xlScrSize.removeListener(onChangeEvent);
      }
    };
  }, [window.innerWidth]);

  return sizeScreen;
}
