import React, { useEffect, useState } from "react";
import { labels } from "../support.dic";
import { AppBar, Button, Grid, GroupBar, Typography } from "@cbmisorg/material";
import { ClientDirection } from "@cbmisorg/helper";
import trainingLog from "../../../../util/assets/images/trainingLogo.png";
import { colors } from "../supportLight.style";
import { Icon } from "@cbmisorg/icons";

function Header({ appState, appDispatch, classes }) {
  const lang = appState?.clientInfo?.strLanguage;
  const [openSide, setopenSide] = useState(false);

  const handelChangeLang = (language) => {
    if (language !== lang) {
      ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
      appState.clientInfo.strLanguage = language;
      appState.clientInfo.strDataLanguage = language;
      appDispatch();
    }
  };

  const JXSButton = (
    <Button
      label={<Typography as="caption">{lang === "arb" ? "English" : "العربية"}</Typography>}
      size="sm"
      onClick={() => handelChangeLang(lang === "arb" ? "eng" : "arb")}
      mode="link"
      icon="translate"
      p-1
      color={colors?.blue}
      sx={{ "&hover": { color: colors?.darkBlue } }}
    />
  );

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
    appDispatch();
  }, [lang]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setopenSide(false);
    });
  }, []);

  useEffect(() => {
    if (!openSide) {
      const ele = document.querySelector(".uicore_drawer");
      const button = document.querySelector(".uicore_bar_burger_1711de4");

      if (ele) {
        ele.style.display = "none";
        // setTimeout(function () {
          button.click();
        // }, 1);
      }
      setopenSide(!openSide);
    }
  }, [openSide]);

  return (
    <React.Fragment>
      <AppBar sx={{ display: "flex", sm: { display: "none" } }} className={classes?.appbar} dir="rtl">
        <GroupBar position={{ xs: "navbar" }} sx={{ height: "100%" }}>
          <img src={trainingLog} alt="training_Logo" height={"50px"} style={{ zIndex: "1", maxHeight: "-webkit-fill-available" }} />
        </GroupBar>
        <GroupBar position={{ xs: "navbar" }}>
          <Grid container spacing={2}>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#about">
                  {labels?.about?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#objectives">
                  {labels?.objectives?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#features">
                  {labels?.features?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#sections">
                  {labels?.sections?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#innovationWeek">
                  {labels?.innovationWeek?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#organizers">
                  {labels?.organizers?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="">
              <Typography as="subtitle3">
                <a className={classes?.navbar} href="#sponsors">
                  {labels?.sponsors?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </GroupBar>
        <GroupBar position={{ xs: "navbar" }}>{JXSButton}</GroupBar>
      </AppBar>
      <AppBar sx={{ display: "none", sm: { display: "flex" } }} className={classes?.appbar} dir="ltr">
        <GroupBar position={{ xs: "navbar" }} />
        <GroupBar position={{ xs: "navbar" }}>
          <img src={trainingLog} alt="training_Logo" height={"40px"} />
        </GroupBar>
        <GroupBar position={{ xs: "sidebar" }}>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item xs="12">
              <Icon icon="close" className="uicore_bar_burger_1711de4" onClick={()=>{setopenSide(false)}}/>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#about">
                  {labels?.about?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#objectives">
                  {labels?.objectives?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#features" >
                  {labels?.features?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#sections">
                  {labels?.sections?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#innovationWeek">
                  {labels?.innovationWeek?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#organizers">
                  {labels?.organizers?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="subtitle3">
                <a className={classes?.navbarSS} href="#sponsors">
                  {labels?.sponsors?.titleNav?.[lang]}
                </a>
              </Typography>
            </Grid>
            <span style={{margin:"0",padding:"10px 5px"}}>{JXSButton}</span>
          </Grid>
        </GroupBar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
