import { Button, Container, Grid, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import React from "react";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as appColor from "../../../../../appHelper/appColor";
import { App_Server_Url_DownloadFiles } from "../../../../../appHelper/appVariables";
import appRouting from "../../../../../../client/configuration/routing/appRouting";

//!IMPORTANT CHANGE item?.strLogoImg TO  appVariables.App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path
function Partners({ lang, appState }) {
  const labels = dictionary?.components?.public?.landingPage?.home?.partners;
  const lstPartner = appState?.clientInfo?.objSystemInfo?.lstPartner?.filter(item=>item?.strLogoImg?.[0])
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container pb-10 px-0 spacing={0} id="partners">
        <Grid item xs="12" container justify="center">
          <Container py-10>
            <Grid container justify="center" spacing={3} pb-sm-8 pb-md-8 pb-lg-8 pb-xl-8>
              <Grid item xs="12" pb-5>
                <Typography as="h4" color="primary">
                  {labels?.title?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12" md="11">
                <Typography as="body2">{labels?.p1?.[lang]}</Typography>
              </Grid>
              {!lstPartner?.length ? null : (
                <Grid item xs="12" md="11">
                  <Typography as="body2">{labels?.p2?.[lang]}</Typography>
                </Grid>
              )}
            </Grid>
            {!lstPartner?.length ? null : (
              <Grid container spacing={3} justify="center">
                <Grid item xs="12" justify="center">
                  <Paper className="partnerPaper" sx={{ width: "100%", overflowX: "auto" }} elevation={0} p-0>
                    <Grid
                      container
                      justify={"center"}
                      spacing={2}
                      sx={{ sm: { flexWrap: "nowrap", justifyContent: lstPartner?.length === 1 ? "center" : "flex-start !important" } }}
                    >
                      {[...lstPartner]?.map((item, index) => {
                        return (
                          !item?.strLogoImg?.[0] ? null: 
                          <Grid item key={index}>
                            <Paper
                              elevation={0}
                              outline={1}
                              title={item?.jsnPartnerInfo?.strPlaceName}
                              onClick={() => {
                                if (item?.jsnPartnerInfo?.strURL) {
                                  window.open(item?.jsnPartnerInfo.strURL, { traget: "_blank" });
                                }
                              }}
                              sx={item?.jsnPartnerInfo?.strURL ? { cursor: "pointer" } : {}}
                              className="border-radius"
                              p-1
                            >
                              <img
                                src={App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path}
                                alt={item?.jsnPartnerInfo?.title}
                                title={item?.jsnPartnerInfo?.title}
                                height={"100px"}
                              />
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {(appState?.userInfo?.blnIsSponsor || !(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsPaid || appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsContact )) ? null : (
              <Grid container justify="center">
                <Grid item xs="12">
                  <Button
                    label={labels?.btn?.[lang]}
                    color="primary"
                    sx={{ border: `2px solid ${appColor.App_orang_color} !important` }}
                    onClick={() => navigate(appRouting?.Register?.url)}
                  />
                </Grid>
              </Grid>
            )}
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Partners;
