import { Alert } from "@cbmisorg/material";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { App_Server_Url_DownloadFiles } from "../../../configuration/appVariables";
import { logError } from "../../../util/appFunctions";
import jsPDF from "jspdf";
import { generateQueries, objTableAttributes } from "../../../graphql/schema/GenerateQuery";
import { alertStyle } from "../../../util/appStyle";
const activityGuestApi = generateQueries("tblActivityGuest");
const forumApi = generateQueries("tblForum");

const strPageInfo = "@src/client/component/public/forumBadge/forumBadge.controller.js";

export default function forumBadgeCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage || "arb";

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState, bigActivityType, strSubjectKey, jsnSystemConfig }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);

          const result = await activityGuestApi(strPageInfo, appState, "aaiotAppInitForumActivityGuest", {
            arrAttributes: objTableAttributes.tblActivityGuest.full,
            objectCondition: {
              bigActivityGuestID: appState?.userInfo?.bigUserID,
              bigActivityType: bigActivityType,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }

          state.objGuestActivityInfo = result?.response?.activityGuestInfo;
          state.objForumInfo = result?.response?.forumInfo;
          state.objSubForumInfo = null;

          state.lstBadgeFile = Array.isArray(state?.objForumInfo?.jsnForumInfo?.lstForumBadgeFile) ? state?.objForumInfo?.jsnForumInfo?.lstForumBadgeFile : [];
          state.lstCertificateFile = Array.isArray(state?.objForumInfo?.jsnForumInfo?.lstForumCertificateFile)
            ? state?.objForumInfo?.jsnForumInfo?.lstForumCertificateFile
            : [];

          state.objBadgeSelectInfo = state?.objForumInfo?.jsnForumInfo?.objForumBadgeSelectInfo;
          state.objCertificateSelectInfo = state?.objForumInfo?.jsnForumInfo?.objForumCertificateSelectInfo;

          state.lstBrochureFile = Array.isArray(state?.objForumInfo?.jsnForumInfo?.lstForumBrochureFile) ? state?.objForumInfo?.jsnForumInfo?.lstForumBrochureFile : [];
          if (state.objForumInfo?.jsnForumInfo?.blnHaveSubForum) {
            let lstSubject = Array.isArray(state.objForumInfo?.jsnForumInfo?.lstSubForum) ? state.objForumInfo?.jsnForumInfo?.lstSubForum : [];
            let objSubject = lstSubject.filter((subject) => String(subject?.strSubForumPath).toLowerCase().trim() === String(strSubjectKey).toLowerCase().trim())?.[0];
            if (objSubject) {
              state.objSubForumInfo = objSubject;
              state.lstBadgeFile = Array.isArray(objSubject?.lstSubForumBadgeFile) ? objSubject?.lstSubForumBadgeFile : [];
              state.lstCertificateFile = Array.isArray(objSubject?.lstSubForumCertificateFile) ? objSubject?.lstSubForumCertificateFile : [];
              state.objBadgeSelectInfo = objSubject?.objSubForumBadgeSelectInfo;
              state.objCertificateSelectInfo = objSubject?.objSubForumCertificateSelectInfo;
            }
          }

          if (state.objForumInfo?.blnStrictRegForum) {
            if (!state.objGuestActivityInfo?.blnIsConfirmed) {
              state.blnIsNeedToConfirm = true;
            }
          }

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          if (document.getElementById("my-certificate-template") && document.getElementById("my-canvas-name") && state?.lstBadgeFile?.length) {
            await handler.initCanvasBadge({ strSubjectKey, state });
          }

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          logError(strPageInfo, "initData-ERROR", error);
        }
      },

    updateBadgeView:
      ({ fields, state, setState, isLoading, setIsLoading, bigActivityType, strSubjectKey }) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);

          const verifyCode = String(state?.objGuestActivityInfo?.bigActivityGuestID)?.slice(7) + String(state?.objGuestActivityInfo?.bigActivityType)?.slice(9);
          if (fields?.strBadgeID?.getValue() !== verifyCode) {
            Alert.viewAlert(dictionary?.components.public.regFormActivity.alertNeedToGetBadgeNumber?.[lang], "error", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }

          const result = await activityGuestApi(strPageInfo, appState, "aaiotAppUpdate", {
            objectCondition: {
              bigActivityGuestID: appState?.userInfo?.bigUserID,
              bigActivityType: bigActivityType,
            },
            objectInput: {
              blnIsConfirmed: true,
              dtmUpdatedDate: new Date().toUTCString(),
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          state.objGuestActivityInfo.blnIsConfirmed = true;
          state.blnIsNeedToConfirm = false;

          try {
            await forumApi(strPageInfo, appState, "aaiotAppIncDecInt", {
              objectCondition: {
                bigForumID: bigActivityType,
                operation: "dec",
              },
              arrAttributes: ["intNumNeedToConfirmUser"],
            });
          } catch (error) {
            logError(strPageInfo, "updateBadgeView-DecConfirmedUser-ERROR", error);
          }

          setState({ ...state });
          setIsLoading(false);

          if (document.getElementById("my-certificate-template") && document.getElementById("my-canvas-name") && state?.lstBadgeFile?.length) {
            await handler.initCanvasBadge({ strSubjectKey, state });
          }
        } catch (error) {
          setIsLoading(false);
          logError(strPageInfo, "updateBadgeView-ERROR", error);
        }
      },

    initCanvasBadge: async ({ strSubjectKey, state }) => {
      const canvasInfo = state.objBadgeSelectInfo;
      const preview = document.getElementById("my-certificate-template");

      const resImg = await fetch(App_Server_Url_DownloadFiles + state?.lstBadgeFile?.[0]?.path);
      const blbImg = await resImg.blob();
      preview.setAttribute("src", URL.createObjectURL(blbImg));

      preview.width = canvasInfo.imageWidth;
      preview.height = canvasInfo.imageHeight;
      // preview.style.border = `10px solid #e9e6e3`;
      // preview.style.borderTop = `52px solid #e9e6e3`;
      preview.style.borderRadius = `0.8em`;
      preview.style.opacity = `0`;

      preview.onload = (e) => {
        const canvas = document.getElementById("my-canvas-name");
        let ctxb = canvas.getContext("2d");

        const rectImg = preview.getBoundingClientRect();

        canvas.style.position = "absolute";
        canvas.style.direction = "ltr";
        canvas.width = canvasInfo.imageWidth;
        canvas.height = canvasInfo.imageHeight;
        // canvas.style.top = rectImg.top;
        // canvas.style.left = rectImg.left;
        // canvas.style.right = rectImg.right;
        // canvas.style.bottom = rectImg.bottom;
        canvas.style.top = canvasInfo.imageTop;
        canvas.style.left = canvasInfo.imageLeft;
        canvas.style.right = canvasInfo.imageRight;
        canvas.style.bottom = canvasInfo.imageBottom;
        // canvas.style.border = `10px solid #e9e6e3`;
        // canvas.style.borderTop = `52px solid #e9e6e3`;
        canvas.style.borderRadius = `0.8em`;

        ctxb.drawImage(preview, 0, 0, canvasInfo.imageWidth, canvasInfo.imageHeight);

        ctxb.font = `bold ${canvasInfo?.fontSize}px "MonteCarlo", cursive`;

        const fontCharWidth = (blnIsArabic) => (blnIsArabic ? canvasInfo?.fontSize / 2.75 : canvasInfo?.fontSize / 2.75);
        const str =
          appState?.userInfo?.strFullName?.[lang] ||
          appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] ||
          appState?.userInfo?.jsnUserInfo?.strFullName ||
          appState?.userInfo?.strFullName;

        let blnIsArabic = false;
        if (/[\u0600-\u06FF]/.test(str)) {
          blnIsArabic = true;
        }

        ctxb.textAlign = "center";
        ctxb.fillStyle = canvasInfo?.fontColor || "#324476";

        //let wordWidth = str.length * fontCharWidth(blnIsArabic);
        //let intStartX = canvasInfo?.ctxWidth < 0 ? (Math.abs(canvasInfo?.ctxWidth) < wordWidth ? canvasInfo.mouseX : canvasInfo?.startX - wordWidth) : canvasInfo?.startX;
        //let intPadding = canvasInfo?.ctxWidth / 2 + (canvasInfo?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
        //intPadding = (canvasInfo?.ctxWidth < 0 && intPadding >= 0) || (canvasInfo?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
        //intStartX = intStartX + intPadding;

        //ctxb.fillText(str, intStartX, canvasInfo?.startY + canvasInfo?.ctxHeight / 1.5, Math.abs(canvasInfo?.ctxWidth));
        ctxb.fillText(str, canvasInfo?.startX + canvasInfo?.ctxWidth / 2, canvasInfo?.startY + canvasInfo?.ctxHeight / 1.5, Math.abs(canvasInfo?.ctxWidth));
      };
    },

    downloadBadge:
      ({ state }) =>
      () => {
        const canvasInfo = state.objBadgeSelectInfo;

        const canvas = document.getElementById("my-canvas-name");

        const imgAsUrl = canvas?.toDataURL("image/png");
        const str =
          appState?.userInfo?.strFullName?.[lang] ||
          appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] ||
          appState?.userInfo?.jsnUserInfo?.strFullName ||
          appState?.userInfo?.strFullName;

        const docMulti = new jsPDF({
          unit: "px",
          orientation: "portrait",
          format: [canvas.width, canvas.height],
          //format: [canvasInfo.imageWidth, canvasInfo.imageHeight],
          // format: [2480, 3508],
          //format: [1240, 1754],
        });

        docMulti.addImage(imgAsUrl, "image/png", 0, 0, canvas.width, canvas.height);
        docMulti.save(`${str}.pdf`);
      },

    initCertificatePreview:
      ({
        bigActivityType,
        strSubjectKey,
        state = { objGuestActivityInfo: null, objForumInfo: null, lstCertificateFile: [], objCertificateSelectInfo: [] },
        blnExternal = false,
      }) =>
      async () => {
        try {
          /*  if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          setIsLoading(true); */

          if (blnExternal) {
            const result = await activityGuestApi(strPageInfo, appState, "aaiotAppInitForumActivityGuest", {
              arrAttributes: objTableAttributes.tblActivityGuest.full,
              objectCondition: {
                bigActivityGuestID: appState?.userInfo?.bigUserID,
                bigActivityType: bigActivityType,
              },
            });

            if (!result?.blnIsRequestSuccessful || !result?.response?.forumInfo) {
              return;
            }

            let objGuestActivityInfo = result?.response?.activityGuestInfo;
            let objForumInfo = result?.response?.forumInfo;
            state.lstCertificateFile = Array.isArray(objForumInfo?.jsnForumInfo?.lstForumCertificateFile) ? objForumInfo?.jsnForumInfo?.lstForumCertificateFile : [];
            state.objCertificateSelectInfo = objForumInfo?.jsnForumInfo?.objForumCertificateSelectInfo;
            if (objForumInfo?.jsnForumInfo?.blnHaveSubForum) {
              let lstSubject = Array.isArray(objForumInfo?.jsnForumInfo?.lstSubForum) ? objForumInfo?.jsnForumInfo?.lstSubForum : [];
              let objSubject = lstSubject.filter((subject) => String(subject?.strSubForumPath).toLowerCase().trim() === String(strSubjectKey).toLowerCase().trim())?.[0];
              if (objSubject) {
                state.lstCertificateFile = Array.isArray(objSubject?.lstSubForumCertificateFile) ? objSubject?.lstSubForumCertificateFile : [];
                state.objCertificateSelectInfo = objSubject?.objSubForumCertificateSelectInfo;
              }
            }
          }

          const canvasInfo = state.objCertificateSelectInfo;

          const preview = new Image();

          //const resImg = await fetch(App_Server_Url_DownloadFiles + "aaiot/certificate/" + strSubjectKey + ".png");

          const resImg = await fetch(App_Server_Url_DownloadFiles + state?.lstCertificateFile?.[0]?.path);
          const blbImg = await resImg.blob();

          preview.setAttribute("src", URL.createObjectURL(blbImg));
          preview.onload = (e) => {
            const canvas = document.createElement("canvas");
            let ctxb = canvas.getContext("2d");

            canvas.style.position = "absolute";
            canvas.style.direction = "ltr";
            canvas.width = canvasInfo.imageWidth;
            canvas.height = canvasInfo.imageHeight;
            canvas.style.top = canvasInfo.imageTop;
            canvas.style.left = canvasInfo.imageLeft;
            canvas.style.right = canvasInfo.imageRight;
            canvas.style.bottom = canvasInfo.imageBottom;

            ctxb.drawImage(preview, 0, 0, canvasInfo.imageWidth, canvasInfo.imageHeight);

            ctxb.font = `bold ${canvasInfo?.fontSize}px "MonteCarlo", cursive`;

            const str =
              appState?.userInfo?.strFullName?.[lang] ||
              appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] ||
              appState?.userInfo?.jsnUserInfo?.strFullName ||
              appState?.userInfo?.strFullName;

            let blnIsArabic = false;
            if (/[\u0600-\u06FF]/.test(str)) {
              blnIsArabic = true;
            }

            ctxb.textAlign = "center";
            ctxb.fillStyle = canvasInfo?.fontColor || "#324476";

            const fontCharWidth = (blnIsArabic) => (blnIsArabic ? canvasInfo?.fontSize / 2.75 : canvasInfo?.fontSize / 2.75);

            //let wordWidth = str.length * fontCharWidth(blnIsArabic);
            //let intStartX =
            //  canvasInfo?.ctxWidth < 0 ? (Math.abs(canvasInfo?.ctxWidth) < wordWidth ? canvasInfo.mouseX : canvasInfo?.startX - wordWidth) : canvasInfo?.startX;
            //let intPadding = canvasInfo?.ctxWidth / 2 + (canvasInfo?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
            //intPadding = (canvasInfo?.ctxWidth < 0 && intPadding >= 0) || (canvasInfo?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
            //intStartX = intStartX + intPadding;

            ctxb.fillText(str, canvasInfo?.startX + canvasInfo?.ctxWidth / 2, canvasInfo?.startY + canvasInfo?.ctxHeight / 1.5, Math.abs(canvasInfo?.ctxWidth));

            const imgAsUrl = canvas?.toDataURL("image/png");

            const docMulti = new jsPDF({
              unit: "px",
              orientation: "landscape",
              format: [canvasInfo.imageWidth, canvasInfo.imageHeight],
              //format: [2480, 3508],
            });

            docMulti.addImage(imgAsUrl, "image/png", 0, 0, canvasInfo.imageWidth, canvasInfo.imageHeight);
            docMulti.save(`${str}.pdf`);
          };

          /* setIsLoading(false); */
        } catch (error) {
          logError(strPageInfo, "initCertificatePreview-ERROR", error);
          /* setIsLoading(false); */
        }
      },
  };

  return handler;
}
