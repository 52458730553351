import { Button, Container, Grid, Typography, useNavigate } from '@cbmisorg/client-app';
import React, {  useEffect } from 'react'
import ShredHeader from '../../sharedUi/header/ShredHeader';
import { dictionary } from '../../../appHelper/appLanguage/dictionary';
import { App_Text_Color } from '../../../appHelper/appColor';
import waitingImg from "../../../assets/images/waiting.png";
import { clearAppLocalStorage, getNextStrURL } from '../../../appHelper/appFunctions';
import objAppRouting from '../../../appHelper/routing/appRouting';
import { useApp } from '../../../../client/configuration/contextapi/context';

function WaitingPage() {
    const navigate = useNavigate()
    const {appState} = useApp()
    // useCheckUserLoginDevice()
    const lang = appState?.clientInfo?.strLanguage;
   
    useEffect(() => {
        const accountUrl = getNextStrURL()
        if(accountUrl !== objAppRouting?.waiting?.url){
            navigate(accountUrl, { replace: true })
        }
    }, [])
    
    const funLogout=()=>{
      clearAppLocalStorage(appState)
      navigate(objAppRouting?.Public_LandingPage?.url, { replace: true })
    }
    return (
      <React.Fragment>
        <ShredHeader lang={lang} blnBottomBar={false}/>
        <Container py-3 sx={{overflowY:"none"}}>
          <Grid container justify={'center'} spacing={1}>
            <Grid item xs="12">
                <Typography as="h4" color="primary">{dictionary?.components?.waiting?.title?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
                <Typography as="h6" color={App_Text_Color}>{dictionary?.components?.waiting?.subTitle?.[lang]}</Typography>
            </Grid>
            <Grid item xs="12">
                <Typography as="h6" color={App_Text_Color}>{dictionary?.components?.waiting?.waite?.[lang]}...</Typography>
            </Grid>
            <Grid item xs="12" md="8" lg="6">
                <img src={waitingImg} alt={dictionary?.components?.waiting?.title?.[lang]} width={"95%"}/>
            </Grid>
            <Grid item xs="12" container p-0>
              <Grid item>
                  <Button size="sm" label={dictionary?.components?.waiting?.btn?.[lang]} icon="home" onClick={()=>navigate(objAppRouting?.Public_LandingPage?.url)}/>
              </Grid>
              <Grid item>
                  <Button size="sm"  mode="link" color="error" label={dictionary?.components?.account?.logout?.[lang]} icon="logout" onClick={funLogout}/>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }

export default WaitingPage