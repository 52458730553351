import React, { forwardRef, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./DatePicker.css"
import * as componentsFunction from "../../../helper/CbmisFunction";
import Text from "../Text/Text";


const DatePicker = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,onMouseOver,onBlur,
          type,
          sx,hidden,disabled,dir,className,...rest} = props

    const [theType, setTheType] = useState("text");

    const nameID = useRef(id || componentsFunction.randomName("datePicker"));

    const handelOnMouseOver=(event)=>{
      if(theType === "text"){
        setTheType(type || "date")
      }
      if(onMouseOver){
        onMouseOver(event)
      }
    }

    const handelBlur=(event)=>{
      if(!event.target.value){
        setTheType("text")
      }
      if(onBlur){
        onBlur(event)
      }
    }

  return (
      <React.Suspense fallback="">
        <Text
          ref={ref}
          type={theType}
          id={nameID.current}
          name={nameID.current}
          label={label}
          placeholder={placeholder}
          errorMessage={errorMessage}
          mode={mode}
          color={color}
          icon={icon}
          iconColor={iconColor}
          adornment={adornment}
          endIcon={endIcon || type === "time" ? "accessTime" : "calendarToday"}
          endAdornment={endAdornment}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onMouseOver={handelOnMouseOver}
          onBlur={handelBlur}
          endIconRest={{onMouseOver:onMouseOver, onMouseLeave:()=>{}}}
          sx={sx}
          hidden={hidden}
          disabled={disabled}
          dir={dir}
          className={`cbmis-input-date-calender ${className}`}
          {...rest}
          />
      </React.Suspense>
    )
});

export default DatePicker