import React, { useEffect, useRef } from "react";
import "./Breadcrumbs.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import "../../../style/main.css";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Breadcrumbs = (props) => {
  const { separator, breadcrumbs, textColor, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Breadcrumbs"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [separator, breadcrumbs, textColor, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (styleRef.current.strSxClassName) {
      componentsFunction.changeItemColor("." + styleRef.current.strSxClassName, textColor || "var(--cbmis-gray-700)");
    }
  }, [styleRef.current.strSxClassName]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-breadcrumb-item");
    }
  }, [sx, screenWidth]);

  return (
    <ol
      id={getID.current}
      className={`cbmis-breadcrumbs ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
        hidden
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {breadcrumbs && breadcrumbs.length > 0
        ? breadcrumbs.map((item, index) => {
            return (
              <li className={`cbmis-breadcrumb-item ${className || ""}`} key={index}>
                {item}
                <span className="cbmis-breadcrumb-item-separator">{separator ? separator : "/"}</span>
              </li>
            );
          })
        : ""}
    </ol>
  );
};

export default Breadcrumbs;
