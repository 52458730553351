import React, { useEffect, useRef, useState } from 'react'
import "./camera.css"
import useCamera from '../useCamera'
import CamFeatureBtn from '../cam-feature-btn/CamFeatureBtn';
import Icon from "../../../icon/CbmisIcon";
import * as permissionsVariable from "../../CbmisPermissions/PermissionsVariable/PermissionsVariable"

import PermissionControl from '../../CbmisPermissions/PermissionControl';


function Camera(props) {
    const {open,eventClose,maxFilesNumber,blnDownload,appPermissionState,changeAppPermissionState,lang,color,blnChangeName,imageSizeKB,maxWidth,maxHeight,quality} =props
    const permissionType = permissionsVariable.objPermissionKey.cameraPermission
    const videoRef = useRef()
    const photoRef = useRef()

    const [front, setFront] = useState(false)

    const constraints = {
        video: {
            width: 1920,
            height: 1080,
            facingMode: front ? "user" : "environment"
        }
    };

    const {
        getVideo,
        stopVideo,
        takePicture,
        closePicture,
        tookPhoto,
        flipCam, // test 
        saveImage,
        imagesList,
        showImage,
        deleteImage,
        showIndex,
        changeImageName,
        resizeCameraImage // kb
    } = useCamera(videoRef, photoRef, constraints, front, setFront,maxFilesNumber)

    useEffect(() => {
        if(open){
            if(((appPermissionState?.[permissionType].user && appPermissionState?.[permissionType].system)&& appPermissionState) || !appPermissionState){
                getVideo()
            }

            document.body.style.overflowY = 'hidden';
        }
        return()=>{
            document.body.style.overflowY = '';
        }
    }, [videoRef,open,appPermissionState])

    const handelClose=async()=>{
        stopVideo()
        let FilesList = await resizeCameraImage(imagesList,imageSizeKB,maxWidth,maxHeight,quality,maxFilesNumber,blnDownload);
        if(eventClose){
            eventClose(FilesList)
        }
        // document.body.style.overflowY = '';
    }



    const handelChangePermatision=()=>{
        if(changeAppPermissionState){
            changeAppPermissionState({...appPermissionState})
        }
    }

    useEffect(() => {
    }, [appPermissionState])
    
  return (
    open?
    (
        <div className='camera-container'>
            <div className='camera'>
                <video ref={videoRef} className="camera-video"></video>
                <button className='capture-btn' onClick={takePicture} />
                <CamFeatureBtn
                    top={15}
                    right={15}
                    icon={"close"}
                    func={handelClose}
                />
                <CamFeatureBtn
                    top={15}
                    left={15}
                    icon={"flipCameraIosOutlined"}
                    func={flipCam}
                    extraClass='flip-btn'
                />
                {
                    (!appPermissionState?.[permissionType].user || !appPermissionState?.[permissionType].system)&& appPermissionState?(
                            <div className='camera-image-features-input-image-name camera-Permission-container'>
                                <PermissionControl appPermissionState={appPermissionState} changeAppPermissionState={handelChangePermatision} color={color} lang={lang} viewLst={["cameraPermission"]}/>
                            </div>
                    ):null
                }
            </div>
            <div className={'camera-result  ' + (tookPhoto ? 'camera-tookPhoto' : '')}>
                <canvas ref={photoRef} className="camera-canvas"></canvas>
                <CamFeatureBtn
                    top={15}
                    right={15}
                    icon={"close"}
                    func={closePicture}
                />
                <CamFeatureBtn
                    top={15}
                    left={15}
                    icon={"saveAlt"}
                    func={saveImage}
                />
            </div>
            <div className={'camera-result  ' + (showIndex >-1 ? 'camera-tookPhoto' : '')}>
                <img src={imagesList[showIndex]?.url} alt="" className="camera-canvas" />
                <CamFeatureBtn
                    top={15}
                    right={15}
                    icon={"close"}
                    func={()=>showImage(-1)}
                />
                {showIndex<imagesList.length-1 && imagesList.length>1?
                <CamFeatureBtn
                    top={"50vh"}
                    right={15}
                    icon={"arrowForwardIos"}
                    func={()=>showImage(showIndex+1)}
                />
                :null}

                {showIndex>0 && imagesList.length>1?
                <CamFeatureBtn
                    top={"50vh"}
                    left={15}
                    icon={"arrowBackIos"}
                    func={()=>showImage(showIndex-1)}
                />
                :null}
                {
                    blnChangeName?(
                        <div className='camera-image-features-input-image-name'>
                            <input type="text"
                                    className='input-image-name'
                                    name={`imageName-${showIndex}`}
                                    defaultValue={imagesList[showIndex]?.name}
                                    id={showIndex}
                                    onBlur={changeImageName}
                                    />
                        </div>
                    ):null
                }
                
            </div>
            <div className='camera-Images'>
            {
                imagesList.map((img,index)=>{
                    return(
                        <div key={index}>
                            <div className='camera-img-continer' key={index}>
                                <div className='camera-image-features-cn'>
                                    <Icon icon="delete" size={"22px"} onClick={() => {deleteImage(index)}} />
                                    <Icon icon="visibility" size={"22px"} onClick={() => {showImage(index)}} />
                                </div>
                                <img src={img.url} 
                                    className="camera-image"
                                    onClick={() => {showImage(index)}} 
                                    alt="" />
                            </div>
                        </div>
                    )
                })
            }
            </div>

        </div>
    )
    :null
)
}

export default Camera