import React, { useEffect, useState } from "react";
import AnnouncementDisplay from "../../../../shared/announcement/mngAnnouncement.display";
import { Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import UploaderReader from "../../../../../../../old/components/sharedUi/uploader/UploadRead";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./Announcement.style";
import { Icon } from "@cbmisorg/icons";
import { App_info_Color } from "../../../../../../util/appStyle";
import { modalOverFllow } from "../../../../../../util/appFunctions";
// import robotImg from "../../../../../../util/assets/images/robot11.png";

function AnnouncementView({ appState }) {
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, {lang});
  const { objAnnouncement } = AnnouncementDisplay();
  const [state, setState] = useState({
    lstNotification: [],
    blnIsInitDone: false,
    selectedNotification: null,
    blnOpenModal: false,
    indexOpen: -1,
  });

  useEffect(() => {
    if (!state.blnIsInitDone) {
      state.blnIsInitDone = objAnnouncement?.lstNotification?.length ? true : false;

      state.lstNotification = [...objAnnouncement?.lstNotification]?.reverse()?.filter((notification) => {
        return !notification?.blnIsPublic;
      });
      setState({ ...state });
    }
  }, [objAnnouncement]);

  const dialogModal =
    (selectedNotification = null) =>
    () => {
      modalOverFllow(selectedNotification ? true : false)
      setState({ ...state, selectedNotification, blnOpenModal: selectedNotification ? true : false });
    };

  return !state?.lstNotification?.length ? null : (
    <React.Fragment>
      <Paper className={classes?.mainPaper}>
        <Grid container spacing={0}>
          {state?.lstNotification?.map((notification, index) => {
            return (
              <Grid key={index} item xs="12">
                <Grid container justifyContent="space-between">
                  <Grid item xs={notification?.strAnnouncementBody?.arb || notification?.strAnnouncementBody?.eng ? "11" : "12"} container alignItems="flex-start">
                    <Grid item xs="1" md="auto">
                      <Icon icon="Campaign" size="25px" color={App_info_Color} />
                    </Grid>
                    <Grid item xs="11">
                      <Typography as="body2" pt-0 className={`${classes?.title} title`}>
                        {notification?.strAnnouncementTitle?.[lang]}
                      </Typography>
                      <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`}>
                        <Grid item xs="auto" md="0">
                          <Icon icon="KeyboardArrowUp" size="28px" color={App_info_Color} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {notification?.strAnnouncementBody?.arb || notification?.strAnnouncementBody?.eng ? (
                    <Grid item justifyContent="flex-end" xs="1" sm="auto">
                      <Icon icon="moreoutlined" className={classes?.icon} onClick={dialogModal(notification)} />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
      <Modal open={state?.blnOpenModal} eventClose={dialogModal()} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Grid container spacing={0}>
            <Grid item xs="0" md="12">
              <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
                {state?.selectedNotification?.strAnnouncementTitle?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Grid container py-3>
            <Grid item xs="12" md="0">
              <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
                {state?.selectedNotification?.strAnnouncementTitle?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <Typography as="body2" className={classes?.labelModal}>
                {state?.selectedNotification?.strAnnouncementBody?.[lang]}
              </Typography>
            </Grid>
            {!state?.selectedNotification?.lstFile?.length ? null : (
              <Grid item xs={12} p-2>
                <UploaderReader lstFiles={state?.selectedNotification?.lstFile} />
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default AnnouncementView;
