import React, { useEffect, useRef, useState } from "react";
import "./AppBar.css";
import Drawer from "../Drawer/Drawer";
import BottomBar from "../BottomBar/BottomBar";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { log } from "async";

function AppBar(props) {
  const {
    textColor,
    color,
    position,
    isClose,
    justify,
    sideBarMode,
    sideBarTitle,
    sideBarTextColor,
    sideBarColor,
    sideBarClose,
    sideBarPosition,
    sx,
    hidden,
    disabled,
    dir,
    className,
    id,
    ...rest
  } = props;

  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("AppBar"));
  const styleRef = useRef({ strSxClassName: "" });

  var viewport_width = window.innerWidth;
  const [openMenu, setOpenMenu] = useState({
    sideBarOpen: false,
    bottomOpen: false,
    bottomBarContent: "",
    bottomBarTitle: "",
  });

  useEffect(() => {}, [position, isClose, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-app-bar");
    }
  }, [sx, screenWidth]);

  const bodyMarginTop = () => {
    document.body.style.paddingTop = "5ram";
  };

  const getPostion = () => {
    if (position) {
      const positionAppBar = String(position).toLowerCase();
      switch (positionAppBar) {
        case "fixed":
          bodyMarginTop();
          return "cbmis-app-bar-position-fixed";
        case "relative":
          return "cbmis-app-bar-position-relative";
        case "static":
          return "cbmis-app-bar-position-static";
        case "absolute":
          bodyMarginTop();
          return "cbmis-app-bar-position-absolute";
        case "sticky":
          return "cbmis-app-bar-position-sticky";
        default:
          return "";
      }
    }
  };

  const getJustify = () => {
    switch (String(justify).toLowerCase()) {
      case "start":
        return "cbmis-toolbar-justify-self-flex-start";
      case "center":
        return "cbmis-toolbar-justify-self-center";
      case "end":
        return "cbmis-toolbar-justify-self-flex-end";
      case "space-between":
        return "cbmis-toolbar-justify-self-space-between";
      case "space-around":
        return "cbmis-toolbar-justify-self-space-around";
      case "space-evenly":
        return "cbmis-toolbar-justify-self-space-evenly";
      default:
        return "cbmis-toolbar-justify-self-space-between";
    }
  };
  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const handelDialogDrawer = () => {
    openMenu.sideBarOpen = !openMenu.sideBarOpen;
    setOpenMenu({ ...openMenu });
  };
  const handelDialogBottomMenu =(item = {}) =>
    () => {
      if (!openMenu.bottomOpen) {
        if (item?.props?.menu && String(item?.props?.ssMode).toLowerCase() === "bottombar" && viewport_width <= 800) {
          openMenu.bottomOpen = true;
          openMenu.bottomBarTitle = item?.props?.label;
          openMenu.bottomBarContent = item?.props?.menu || "";
        }
      } else {
        openMenu.bottomOpen = false;
        openMenu.bottomBarContent = "";
        openMenu.bottomBarTitle = "";
      }
      setOpenMenu({ ...openMenu });
    };

  const handelCloseBottomMenu = () => {
    openMenu.bottomOpen = false;
    openMenu.bottomBarContent = "";
    setOpenMenu({ ...openMenu });
  };

  const checkChildren = (propsChildren) => {
    let children = [];
    if (propsChildren) {
      if (Array.isArray(propsChildren)) {
        children = propsChildren;
      } else {
        children = [propsChildren];
      }
    }
    return children;
  };

  const getContent = () => {
    const children = checkChildren(props?.children);
    let navBar = [];
    let sideBar = [];
    let bottomBar = [];
    let logo = "";

    const checkMode = (mode, item, defaultItem) => {
      switch (String(mode).toLowerCase()) {
        case "logo":
          logo = item;
          break;
        case "sidebar":
          sideBar.push(item);
          break;
        case "navbar":
          navBar.push(item);
          break;
        case "bottombar":
          const bottomItem = {
            icon: item?.props?.icon,
            path: item?.props?.path,
            label: item.props?.label || item?.props?.children,
            onClick: handelDialogBottomMenu(item),
          };
          if (Number(item.props.bottomBarIndex)) {
            bottomBar[Number(item.props.bottomBarIndex)] = bottomItem;
          } else {
            bottomBar.push(bottomItem);
          }
          break;
        case "none":
          break;
        default:
          switch (String(defaultItem).toLowerCase()) {
            case "sidebar":
              sideBar.push(item);
              break;
            case "navbar":
              navBar.push(item);
              break;
            default:
              break;
          }
          break;
      }
    };

    children.forEach((item) => {
      const children2 = checkChildren(item?.props?.children);

      if (viewport_width > 800) {
        if (item.props.sxMode) {
          checkMode(item.props.sxMode, item, "navbar");
        } else {
          children2.forEach((child) => {
            checkMode(child.props.sxMode, child, "sidebar");
          });
        }
      } else {
        if (item.props.ssMode) {
          checkMode(item.props.ssMode, item);
        } else {
          children2.forEach((child) => {
            checkMode(child.props.ssMode, child);
          });
        }
      }
    });

    return [navBar, sideBar, bottomBar, logo];
  };

  useEffect(() => {
    if(sideBarClose === true && viewport_width < 800 ){
      handelDialogDrawer()
    }
  }, [sideBarClose])

  const [navBar, sideBar, bottomBar, logo] = getContent();

  return (
    <React.Fragment>
      <header
        className={`cbmis-app-bar ${getPostion()} ${getJustify()} ${sideBar.length ? "cbmis-app-bar-have-hamburger-menu" : ""} ${
          String(sideBarMode).toLowerCase() === "show-header" ? "cbmis-app-bar-withsidebar" : ""
        }
              ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${componentsFunction.CheckDirectionItem(dir)} ${className || ""}`}
        id={getID.current}
        {...getRestProps()}
      >
        {logo && String(sideBarMode).toLowerCase() !== "show-header" ? <div className={`cbmis-app-bar-logo`}>{logo}</div> : null}

        {sideBar.length && String(sideBarMode).toLowerCase() !== "show-header" ? (
          <div className={`cbmis-app-bar-hamburger`} onClick={handelDialogDrawer}>
            <div className="cbmis-app-bar-hamburger-bars1"></div>
            <div className="cbmis-app-bar-hamburger-bars2"></div>
            <div className="cbmis-app-bar-hamburger-bars3"></div>
          </div>
        ) : null}
        {navBar}
      </header>

  
      <Drawer
        open={openMenu.sideBarOpen}
        eventClose={handelDialogDrawer}
        mode={sideBarMode}
        title={sideBarTitle}
        color={sideBarColor || color}
        textColor={sideBarTextColor || textColor}
        position={sideBarPosition}
      >
        <div className={logo && String(sideBarMode).toLowerCase() === "show-header" ? "" : "sideBar-appBar"}>
          {logo && String(sideBarMode).toLowerCase() === "show-header" ? <div className={`cbmis-app-bar-logo`}>{logo}</div> : null}
          {sideBar}
        </div>
      </Drawer>

      {viewport_width > 800 ? null : (
        <Drawer open={openMenu.bottomOpen} eventClose={handelCloseBottomMenu} mode="overlay-opacity" position="bottom" title={openMenu.bottomBarTitle}>
          <div className={logo && String(sideBarMode).toLowerCase() === "show-header" ? "" : "sideBar-appBar"}>{openMenu.bottomBarContent}</div>
        </Drawer>
      )}
      <BottomBar content={bottomBar} />
    </React.Fragment>
  );
}

export default AppBar;
