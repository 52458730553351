// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useLocation, useNavigate } from "@cbmisorg/router";
import { Container, Grid, Typography, Loader } from "@cbmisorg/material";

import { useApp } from "../../../configuration/contextapi/context";
import { FormData } from "../../../util/formApi/FormAPI";
import { dictionary } from "../../../util/appLanguage/dictionary";

import { CtrlResearcherRegistration } from "./researcherRegistration.ctrl";
import { objStyle } from "./researcherRegistration.style";

import ShredHeader from "../../shared/shredHeader";
import { App_info_Color } from "../../../util/appStyle";
import useCheckSystemInfo from "../../../util/hooks/useCheckSystemInfo";
import useCheckCat from "../../../util/hooks/useCheckCat";
import { useSearchParams } from "react-router-dom";
import { objUploadType } from "../../../configuration/appVariables";
import Form from "./component/Form";
import { useParams } from "@cbmisorg/client-app";
import Uploader from "../../../../old/components/sharedUi/uploader/Uploader";

const labels = dictionary?.components?.public?.researcherRegistration;

function ResearcherRegistration() {
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const { getNewSystemInfo } = useCheckSystemInfo({ appState });
  const { getNewCatInfo } = useCheckCat({ appState });
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const userInfo = location?.state?.userInfo ? location?.state?.userInfo : appState?.userInfo;
  const langSearch = searchParams.get("lang");
  const { countrycode } = useParams();

  const lang = langSearch ? langSearch : appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    countryCode: "",
    areaCode: "",
    blnDefaultCountry: true,
    intStep: 1,
    lstIntroductoryVideo: [],
    lstTranscript: [],
    blnIsAdminEdit: location?.state?.userInfo ? true : false,
  });
  const uploaderVideo = Uploader(state, setState, "lstIntroductoryVideo", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: labels?.video?.[lang],
    blnHoverd: false,
  });

  const fields = FormData({
    // bigWorkSector2: {
    //   control: "list",
    //   blnLanguage: false,
    //   validations: { required: true },
    //   options: [
    //     { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
    //     { key: workSectorID?.student, value: dictionary?.shared?.app?.student?.title, jsx: () => <WorkSectorOption type="student" lang={lang} /> },
    //     { key: workSectorID?.unEmployee, value: dictionary?.shared?.app?.unEmployee?.title, jsx: () => <WorkSectorOption type="unEmployee" lang={lang} /> },
    //     { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
    //   ],
    // },
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, email: true },
    },
    bigAreaCountryID: { control: "list", validations: {}, blnLanguage: false },
    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true, minLength: 5, maxLength: 10 },
    },

    bigCountryOfCitizenID: { control: "list", validations: {}, blnLanguage: false },
    bigCountryOfResidenceID: { control: "list", validations: {}, blnLanguage: false },
 
    strPositionTitle: { control: "text", validations: {}, blnLanguage: false },
    bigMajorID: { control: "list", validations: {}, blnLanguage: false },
    strPlaceName: { control: "text", validations: {}, blnLanguage: false },
    intExperienceYear: { control: "text", validations: { onlyNumbers: true }, blnLanguage: false },

    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        minLength: 4,
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        minLength: 4,
        equal: "strUserPassword",
      },
    },
  });

  const handlers = CtrlResearcherRegistration({ userInfo, appState, appDispatch, isLoading, setIsLoading, countryCode: countrycode || "kw" });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields, lang });
  const handleSubmit = () => handlers.handleSubmit({ fields, navigate, state, setState, uploaderVideo });
  const onChangeAreaCode = handlers.onChangeAreaCode({ fields, state, setState });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);
  return (
    <React.Fragment>
      <ShredHeader lang={lang} title={labels?.title?.[lang]} blnBack={false} sx={{ position: "sticky" }} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer} py-3>
          <Grid container justifyContent={"center"} alignSelf="center" mt-5>
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container}>
                <Grid container justifyContent="center">
                  <Grid item xs="12" justifyContent="center">
                    <Typography as="subtitle2" className={`${classes?.mainTitle}`}>
                      {labels?.subTitle?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                <Form
                  state={state}
                  fields={fields}
                  classes={classes}
                  appState={appState}
                  lang={lang}
                  uploaderVideo={uploaderVideo}
                  onChangeAreaCode={onChangeAreaCode}
                  handleSubmit={handleSubmit}
                />
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default ResearcherRegistration;
