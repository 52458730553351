import React from "react";
import MngPotentialSponsorshipView from "./mngPotentialSponsorship.view";
import { objTrainingToSupport } from "../../../../appHelper/appVariables";

export default function MngPotentialSponsorship({ strTrainingType = objTrainingToSupport.fullstack.key }) {
  return (
    <>
      <MngPotentialSponsorshipView strTrainingType={strTrainingType} />
    </>
  );
}
