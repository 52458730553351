import { Alert, ClientFunction, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { App_ID } from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/LandingPage/controller/CrtlLandingPage.js";

const tblStatistic = generateQueries("tblStatistic");
const tblMail = generateQueries("tblMail");
const tblSubscription = generateQueries("tblSubscription");

export const CtrlLandingPage = (appState, isLoading, setIsLoading) => {
  const lang = appState?.clientInfo?.strLanguage;

  let handler = {
    addMail: async (fields, submitClicked = null) => {
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);

        let bigID = ClientFunction.generateID(10);

        const result = await tblMail(strPageInfo, appState, "aaiotAppAdd", {
          objectInput: {
            bigAppID: App_ID,
            bigMailID: bigID,
            bigSystemID: appState?.userInfo?.bigSystemID,
            jsnMailInfo: {
              strFullName: fields?.strFullName?.getValue(),
              strEmail: fields?.strEmail?.getValue(),
              strPhone: fields?.strPhone?.getValue(),
              bigNationality: fields?.bigNationality?.getValue()?.key,
              bigSubjectID: fields?.bigSubjectID?.getValue()?.key,
              strMessage: fields?.strMessage?.getValue(),
            },
          },
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
          return;
        }

        const resultMailIncCount = await tblStatistic(strPageInfo, appState, "aaiotAppIncDecInt", {
          arrAttributes: ["intCountMail"],
          objectCondition: {
            bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID,
            blnIsActive: true,
            blnIsDeleted: false,
          },
        });

        if (!resultMailIncCount?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          return;
        }
        setIsLoading(false);
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.submit_Contact_Successfully?.[lang], "success");
        if (submitClicked) {
          submitClicked();
        }
        fields.resetField();
        //#region
        fields?.bigNationality?.setValue("");
        fields?.bigSubjectID?.setValue("");
        //#endregion
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
        setIsLoading(false);
      }
    },

    addSubscription: (fields) => {
      const addFun = async () => {
        try {
          setIsLoading(true);

          let bigID = ClientFunction.generateID(10);

          const checkEmailResult = await tblSubscription(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: ["bigSubscriptionID"],
            objectCondition: {
              bigAppID: App_ID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              strEmail: String(fields?.strVisitorEmail?.getValue()).toLowerCase()?.trim(""),
            },
          });

          if (checkEmailResult?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.already_subscrbtion?.[lang], "info");
            fields.resetField();
            setIsLoading(false);
            return;
          }

          const result = await tblSubscription(strPageInfo, appState, "aaiotAppAdd", {
            objectInput: {
              bigAppID: App_ID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              bigSubscriptionID: bigID,
              strSubscriptionEmail: String(fields?.strVisitorEmail?.getValue()).toLowerCase()?.trim(""),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            return;
          }
          fields.resetField();
          setIsLoading(false);
          Alert.viewAlert(dictionary?.components?.public?.footer?.newsletterAlert?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
          setIsLoading(false);
        }
      };

      if (!fields?.isFormValid()) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field_Email?.[lang], "");
        return;
      }
      if (isLoading) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
        return;
      }

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.subsecrbtionConf?.[lang],
        addFun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
  };

  return handler;
};
