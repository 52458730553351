import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Container, Grid, Icon, Typography } from '@cbmisorg/client-app';
import React, { useEffect } from 'react'
import * as appColor from '../../../../../appHelper/appColor'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary';
import img from "../../../../../assets/images/img.png"

import { appStyle } from '../../../../../appHelper/appStyle';

const classes={
  img:{
    width:"90%",
    height:"100%"
  },
  quote:{
    display:"inline",
    ".cbmisIconMainContainer":{
      display: "inline-block"
    }
  },

}
function MessageOfSheikhMuhammad({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.messageofSheikhMuhammad

  useEffect(() => {
  }, [])

  return (
    <React.Fragment>
      <Container py-10  id="messageofSheikhMuhammad">
            <Grid container spacing={3} justify={'center'}>
                <Grid item xs="12" lg="0" p-5  mb-5>
                  <Typography as="h4" sx={{xl:appStyle?.title1,sm:{border:"none !important"}}} color={appColor.App_Primary_Color}>{labels?.subtitle?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12" lg="0" alignSelf="flex-start" p-0 m-0>
                    <img src={img} alt="Sheikh Muhammad" width={"95%"} style={classes?.img}/>
                </Grid>
                <Grid item xs="12" lg="7"  container spacing="3" justify="center">
                        <Grid container spacing={3}>
                            <Grid item xs="0" lg="12">
                              <Typography as="h4" sx={appStyle?.title1}>{labels?.subtitle?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12">
                                <Typography as="h6">{labels?.title?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12" lg="0" container>
                                  <Typography as="subtitle2" sx={classes?.quote}>
                                    <Icon icon="formatQuote" size='25px' color="primary" p-0 m-0/>
                                    {labels?.quote?.[lang]}
                                    <Icon icon="formatQuote" size='25px' color="primary" p-0 m-0 className='quoteEnd'/>
                                  </Typography>
                            </Grid>
                            <Grid item xs="12">
                                  <Typography as="">{labels?.p1?.[lang]}</Typography>
                            </Grid>
                            <Grid item xs="12" className="accordionTab_landingPage" dir={lang==="arb"?"rtl":"ltr"}>
                              <Accordion mx-0>
                              {
                                labels?.lst?.map((item,index)=>{
                                  return(
                                    <AccordionTab my-1 key={index}>
                                      <AccordionSummary expandIcon={<Icon icon="checkCircle"  />} py-3 px-3 sx={appStyle?.accordionSummary}>
                                        <Typography as="body1" px-2 color={appColor?.App_Dark_Color}>{item?.title?.[lang]}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails sx={appStyle?.accordionDetails}>
                                        <Typography as="body2">{item?.point?.[lang]}</Typography>
                                      </AccordionDetails>
                                    </AccordionTab>
                                    )
                                })
                              }
                              </Accordion>
                            </Grid>
                            <Grid item xs="12" lg="0">
                                <Typography as="body1">{labels?.p2?.[lang]}</Typography>
                            </Grid>
                        </Grid>
                </Grid>
                <Grid item xs="0" lg="5" alignSelf="flex-start" container py-4 px-3>
                    <Grid item xs="12">
                        <img src={img} alt="Sheikh Muhammad" style={classes?.img}/>
                    </Grid>
                      <Grid item xs="0" lg="11" py-3>
                        <Typography as="h6" sx={classes?.quote} color="primary" className='fontHand'>
                          <Icon icon="formatQuote" size='25px' color="primary" p-0 m-0/>
                          {labels?.quote?.[lang]}
                          <Icon icon="formatQuote" size='25px' color="primary" p-0 m-0 className='quoteEnd'/>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </React.Fragment>
  )
}

export default MessageOfSheikhMuhammad
