import { Grid, Paper, Typography } from "@cbmisorg/client-app";
import React from "react";
import { appStyle } from "../../../../../appHelper/appStyle";
import { FormField } from "../../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";

const labels = dictionary?.components?.manageSystem;

function ResearcherConfig({ fields, appState,}) {
    const lang = appState?.clientInfo?.strLanguage;

  return (
    <React.Fragment>
      <Grid container justify={"center"} spacing={3}>
        <Grid item xs="12">
          <Paper className="cardContainer">
            <Grid container spacing={2} py-0>
              <Grid item xs="12">
                <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                  {labels?.ResearcherConfig?.[lang]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs="12">
              <FormField objHandler={fields?.lstContryResearcher} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              <Grid item xs="12">
              <FormField objHandler={fields?.lstContryResearcherReqVideo} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ResearcherConfig;