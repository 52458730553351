"use client";
import React from "react";
import { aaiot_Initiative_System_ID, App_Setting_Mode } from "../../../../configuration/appVariables";
import { getAppLocalStorage } from "../../../../util/appFunctions";
import ShredHeader from "../../../shared/shredHeader";
import { Container } from "@cbmisorg/material";
// import { useApp } from "../../../configuration/contextapi/context";

const proLink = `https://initiative.cbmis.com/adsdashboard/${aaiot_Initiative_System_ID}`;
const testLink = `https://initiative.cbmis.click/adsdashboard/${aaiot_Initiative_System_ID}`;
const localhostLink = `http://localhost:3001/adsdashboard/${aaiot_Initiative_System_ID}`;

function ManageInitiative() {
  const aaiotLocalStorage = getAppLocalStorage();
  const lang = aaiotLocalStorage?.clientInfo?.strDataLanguage
  return (
    <React.Fragment>
      <ShredHeader title={{ arb: "ادارة المبادرات", eng: "Manage Initiative" }?.[lang]} blnIsLight={true} blnIsAllLight={true} lang={lang} />
      <Container mt-10 />
      <iframe
        id="initiativeIfream"
        src={`${App_Setting_Mode === "production" ? proLink : App_Setting_Mode === "testing" ? localhostLink : localhostLink}?aaiotR=1572490785`}
        frameBorder="0"
        height="100vh"
        width="100vw"
        style={{ overflow: "hidden", border: 0, width: "100vw", height: "100%",}}
      ></iframe>
    </React.Fragment>
  );
}

export default ManageInitiative;
