import { Icon } from "@cbmisorg/icons";
import { Grid, Typography } from "@cbmisorg/material";
import React from "react";
import { App_info_Color, App_Light_Text_Color } from "../../../../../../../util/appStyle";
// import { dictionary } from "../../../../../../../util/appLanguage/dictionary";

// const labels = dictionary?.components?.stakeholders?.member?.account?.regActivityGuest;

const SubjectOption = ({ element, lang, state, setState, blnLst = false, classes }) => {
  const lstTargeted = String(element?.targeted?.value?.[lang])?.split("/");
  // const handleOpenModal = () => {
  //   state.subjectSelected = element;
  //   state.blnOpenModal = true;
  //   setState({ ...state });
  // };

  return (
    <Grid container py-2 spacing={0} m-0>
      {/* <Grid item xs={blnLst ? "auto" : "0"} alignSelf="flex-start" pt-2>
        <Icon icon="circle" size="12px" color={App_info_Color} />
      </Grid> */}
      <Grid item xs={"12"} container>
        <Typography as="body2" className={classes?.labelsLight} color={App_info_Color} sx={{display: "flex", alignContent: "baseline"}}>
          {!blnLst ? null : <Icon icon="circle" size="14px" color={App_info_Color} mt-1 mx-1/>}
          {element?.value?.[lang]}
        </Typography>

        <Grid item xs="12" lg="auto" p-0>
          {lstTargeted?.length > 0? (
            lstTargeted?.map((item, ind) => (
              <Typography as="body3" className={classes?.labelsInfo} sx={{ display: "flex" }} color={App_Light_Text_Color} key={ind} py-1 px-2>
                <label style={{ color: App_info_Color }}>{String(ind + 1) + ". "}</label>
                {item}
              </Typography>
            ))
          ) : (
            <Typography as="body3" className={classes?.labelsInfo} color={App_Light_Text_Color} p-2>
              {element?.targeted?.value?.[lang]}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubjectOption;
