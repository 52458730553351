import React, { useEffect, useRef } from 'react'

function Message(props) {
  const {messageInfo} = props

  const getTime=()=>{
    const d = new Date();
    let hour = d.getHours();
    let minutes = d.getMinutes()

    hour = hour<10?`0${hour}`:hour;
    minutes = minutes<10?`0${minutes}`:minutes;
    return `${hour}:${minutes}`
  }
  useEffect(() => {
  }, [messageInfo])

  const getIsMsgPersonal=()=>{
      if(messageInfo?.isPersonal){
          return "cbmis-chat-message-personal"
      }else{
          return ""
      }
  }
  
  const getIsLoading=()=>{
    if(messageInfo?.isLoading){
        return "cbmis-chat-msg-loading"
    }else{
        return ""
    }
}
  return (
    
      messageInfo.message.map((msg,index)=>{
        return(
          <React.Fragment key={index}>
            <div className={`cbmis-chat-message ${getIsMsgPersonal()} ${getIsLoading()}`}>
                  {msg?.text}
                {/* <div className="cbmis-chat-message-info">
                  <div className="cbmis-chat-timestamp">{!getIsLoading() ? getTime():""}</div>
                  <div className="cbmis-chat-checkmark-read">✓</div>
                  <div className="cbmis-chat-checkmark-sent-delivered">✓</div>
                </div> */}
            </div>
            {
              msg?.image?(
                <div className={`cbmis-chat-message ${getIsMsgPersonal()} ${getIsLoading()}`}>
                  <img src={msg.image} alt="MessageImage" className="cbmis-chat-message-image"/>
                </div>
              ):null
            }
          </React.Fragment>
        )
      }
      )
    
  )
}

export default Message