import React, { useEffect, useRef } from "react";
import "./Typography.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Typography = (props) => {
  const { as, sx, hidden, disabled, dir, className, color, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Typography"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName(".cbmis-typography") });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [as, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, { ...sx, ...(color ? { color: `${componentsFunction.checkColorReturnValue(color)}` } : {}) }, "cbmis-typography");
    } else if (color) {
      setSxClassName(styleRef, getID.current, { color: `${componentsFunction.checkColorReturnValue(color)}` }, "cbmis-typography");
    }
  }, [sx, screenWidth]);

  const getFontSizeAsReturnValue = () => {
    const getSize = String(as).toLowerCase();
    switch (getSize) {
      case "h1":
        return "cbmis-typography-h1";
      case "h2":
        return "cbmis-typography-h2";
      case "h3":
        return "cbmis-typography-h3";
      case "h4":
        return "cbmis-typography-h4";
      case "h5":
        return "cbmis-typography-h5";
      case "h6":
        return "cbmis-typography-h6";
      case "subtitle1":
        return "cbmis-typography-subtitle1";
      case "subtitle2":
        return "cbmis-typography-subtitle2";
      case "body1":
        return "cbmis-typography-body1";
      case "body2":
        return "cbmis-typography-body2";
      case "caption":
        return "cbmis-typography-caption";
      case "overline":
        return "cbmis-typography-overline";
      default:
        return "cbmis-typography-body1";
    }
  };

  return (
    <div
      id={getID.current}
      className={`cbmis-typography ${getFontSizeAsReturnValue()} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {props.children}
    </div>
  );
};

export default Typography;
