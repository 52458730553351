import { Alert } from "@cbmisorg/material";
import { generateQueries, objTableAttributes } from "../../../../graphql/schema/GenerateQuery";
import { aaiot_System_ID } from "../../../../configuration/appVariables";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { logMessage } from "../../../../util/appFunctions";
import { Confirmation } from "@cbmisorg/client-app";

const strPageInfo = "@src/client/component/stakeholders/admin/mngSurvey/mngSurvey.ctrl.js";

const tblSurvey = generateQueries("tblSurvey");

export const CtrlMngSurvey = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID;

  let handler = {
    initData:
      ({ isLoading, setIsLoading, stateInfo, setStateInfo }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
          const result = await tblSurvey(strPageInfo, appState, "aaiotAppSurveyGetAllFK", {
            arrAttributes: objTableAttributes?.tblSurvey?.full,
            objectCondition: {
              bigSystemID: bigSystemID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");

            setIsLoading(false);
            return;
          }

          stateInfo.blnIsInitDone = true;
          let lstTmp = Array.isArray(result?.response) ? result?.response : [];

          stateInfo.lstSurvey = (lstTmp || [])?.reverse();
          setIsLoading(false);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },

    openModal:
      ({ stateInfo, setStateInfo }) =>
      (openKey, intSurveySelected = -1, flag = true) =>
      () => {
        if (flag) {
          setStateInfo({ ...stateInfo, [openKey]: !stateInfo?.[openKey], intSurveySelected: intSurveySelected });
        }
      },

    handelDelete:
      ({ stateInfo, setStateInfo, isLoading, setIsLoading, bigSurveyID }) =>
      async () => {
        try {
          
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
          const result = await tblSurvey(strPageInfo, appState, "aaiotAppUpdate", {
            objectInput: {
              blnIsDeleted: true,
              dtmUpdatedDate: new Date(),
            },
            objectCondition: {
              bigSurveyID: bigSurveyID,
              bigSystemID: bigSystemID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");

            setIsLoading(false);
            return;
          }

          setIsLoading(false);
          setStateInfo({ ...stateInfo, lstSurvey: stateInfo?.lstSurvey?.filter((item) => item?.bigSurveyID !== bigSurveyID) });
        } catch (error) {
          logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },

    handelDeleteConfirmation:
      ({ stateInfo, setStateInfo, isLoading, setIsLoading }) =>
      (bigSurveyID) =>
      async () => {
        const handelDelete = handler?.handelDelete({ stateInfo, setStateInfo, isLoading, setIsLoading, bigSurveyID });
        Confirmation.viewConfirmation(
          dictionary?.shared?.confirmationMsg?.deleteItem?.[lang],
          handelDelete,
          () => null,
          dictionary?.shared?.confirmationMsg?.yes?.[lang],
          dictionary?.shared?.confirmationMsg?.no?.[lang]
        );
      },

     
  };

  return handler;
};
