import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { Avatar, Button, Container, Grid, Loader, Modal, ModalBody, ModalHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@cbmisorg/material";
import { Icon } from "@cbmisorg/icons";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import { useApp } from "../../../../configuration/contextapi/context";
import { objStyle } from "./Supportinfo.style";
import { FormData, FormField } from "../../../../util/formApi/FormAPI";
import ShredHeader from "../../../shared/shredHeader";
import * as appStyle from "../../../../util/appStyle";
import { App_Server_Url_DownloadFiles, objSponserCat, objSupportTransStatus, objUploadType, workSectorID } from "../../../../configuration/appVariables";
import WorkSectorOption from "../account/components/regActivityGuest/component/WorkSectorOption.view";
import Uploader from "../../../../../old/components/sharedUi/uploader/Uploader";
import { CtrlSponsorProfileInfo } from "./Supportinfo.controller";
import crossImg from "../../../../util/assets/images/cross-red.png";
import { decompressText, getAppLocalStorage, getDateUSFormat, getSponsorTypeAccordingToTraineeNum, modalOverFllow } from "../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.supportinfo;
const objCat = decompressText(getAppLocalStorage()?.clientInfo?.objCatInfo);

function SupportinfoView() {
  useCheckHooksGlb();
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    objPartner: {},
    listLogo: [],
    blnOpenTranstionModal: false,
    blnOpenImgeModal: false,
    selectedLstImg: "",
    selectedImgType: "",
  });

  const objPartnerCount = state?.objPartner?.jsnSupportTrans?.[state?.objPartner?.jsnSupportTrans.length - 1]?.strSupportCatType
    ? state?.objPartner?.jsnSupportTrans?.[state?.objPartner?.jsnSupportTrans.length - 1]?.strSupportCatType
    : getSponsorTypeAccordingToTraineeNum(
        state?.objPartner?.jsnSupportTrans?.[state?.objPartner?.jsnSupportTrans.length - 1]?.intTraineesCount ||
          state?.objPartner?.jsnSupportTrans?.[state?.objPartner?.jsnSupportTrans.length - 1]?.intTrainingProgPrice,
        state?.objPartner?.jsnSupportTrans?.[state?.objPartner?.jsnSupportTrans?.length - 1]?.strTrainingType
      );

  const uploaderLogo = Uploader(
    state,
    setState,
    "listLogo",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType?.["aaiot"].id,
      title: dictionary?.components?.public?.join?.logoNote?.[lang],
      file: dictionary?.components?.public?.join?.logo?.[lang],
    },
    "edit",
    false
  );

  const fields = FormData({
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    strURL: { control: "text", blnLanguage: false },
    blnVewLogo: {
      control: "switch",
      blnLanguage: false,
    },
  });

  const handlers = CtrlSponsorProfileInfo(appState, appDispatch);
  const initData = handlers?.initData({ isLoading, setIsLoading, state, setState, fields });
  const handelSubmit = handlers?.handelSubmit({ isLoading, setIsLoading, state, setState, fields, uploaderLogo });
  const dilogeImgModal = handlers?.dilogeImgModal({ state, setState });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader lang={lang} title={labels?.title?.[lang]} blnBack={true} />
      <Loader backdrop={isLoading} color={appStyle.App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"}>
            <Grid item xs="12" md="10" lg="9" xl="8" className={classes?.bgBox} py-10>
              <Container className={classes?.container}>
                <Grid container justifyContent={"center"}>
                  {!state?.objPartner?.jsnSupportTrans?.length ? null : (
                    <Grid item xs="12" container justifyContent="space-between" pt-5>
                      <Grid item>
                        {!objPartnerCount ? null : (
                          <Typography as="body2" className={classes?.partnerCat}>
                            <Grid container py-0 spacing={0} alignItems={"center"} sx={{ flexWrap: "nowrap", minWidth: "max-content !important" }}>
                              <Grid item alignItems={"center"}>
                                <Icon color={appStyle.App_info_Color} icon={objPartnerCount !== objSponserCat?.main?.key ? "star" : "AutoAwesome"} />
                              </Grid>
                              <Grid item alignItems={"center"}>
                                <Typography as="body3" color={appStyle.App_info_Color}>
                                  {" "}
                                  {dictionary?.shared?.app?.sponserLabelCat[objPartnerCount]?.[lang]}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        )}
                      </Grid>

                      <Grid item>
                        <Icon
                          icon="RequestQuoteOutlined"
                          color={appStyle?.App_info_Color}
                          size="24px"
                          className={classes?.icon}
                          onClick={() => {
                            modalOverFllow(true);
                            setState({ ...state, blnOpenTranstionModal: true });
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Avatar className={classes?.imageAvatar}>
                          {!state?.listLogo?.[0]?.blnIsNew && !state?.objPartner?.strLogoImg?.[0]?.path ? (
                            <Icon icon={"business"} size="43px" />
                          ) : (
                            <img
                              src={
                                !state?.listLogo?.[0]?.blnIsNew && !state?.objPartner?.strLogoImg?.[0]?.path
                                  ? crossImg
                                  : state?.listLogo?.[0]?.blnIsNew
                                  ? URL.createObjectURL(state?.listLogo?.[0])
                                  : App_Server_Url_DownloadFiles + state?.objPartner?.strLogoImg?.[0]?.path
                              }
                              alt={state?.objPartner?.jsnPartnerInfo?.strPlaceName}
                              title={state?.objPartner?.jsnPartnerInfo?.strPlaceName}
                              className={classes?.imageAvatar}
                            />
                          )}
                        </Avatar>
                      </Grid>
                      <Grid item className={classes?.uploader}>
                        {uploaderLogo?.JSX}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="12">
                    <Typography as="caption" mx-auto>
                      {labels?.logo?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent={"center"}>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigWorkSector} icon="Work" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={fields.strURL} icon="link" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} inputDir="ltr"/>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"}>
                  <Grid item xs="12" p-1>
                    <FormField objHandler={fields.blnVewLogo} color={appStyle.App_info_Color} className={classes?.field} defaultChecked={state.objPartner?.blnVewLogo} />
                  </Grid>
                </Grid>
                <Grid item xs="12" pt-2>
                  <Button
                    py-0
                    px-10
                    label={dictionary?.shared?.buttons?.save?.[lang]}
                    onClick={handelSubmit}
                    mode="outlined"
                    color={appStyle.App_info_Color}
                    className={classes?.btnBox}
                  />
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Modal
        open={state.blnOpenTranstionModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenTranstionModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.support?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justifyContent={"center"} py-2>
            <Grid item xs="12">
              <Table>
                <TableHead>
                  <TableRow className={classes?.tableRow}>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.supportStatus?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.supportType?.[lang]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.supportCat?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.studentNumber?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.amount?.[lang]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography as="subtitle3">{labels?.date?.[lang]}</Typography>
                    </TableCell>
                    {state?.objPartner?.jsnSupportTrans?.length === 1 &&
                    !state?.objPartner?.jsnSupportTrans[0]?.lstReceipt?.length &&
                    !state?.objPartner?.jsnSupportTrans[0]?.lstReceiptCatch?.length ? null : (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...(state?.objPartner?.jsnSupportTrans || [])]?.reverse()?.map((item) => (
                    <TableRow key={item?.id} className={classes?.tblBodyRow}>
                      <TableCell headLabel={labels?.supportStatus?.[lang]}>
                        <Typography
                          as="subtitle3"
                          color={
                            item?.intSupportTransStatus === objSupportTransStatus?.Approved
                              ? "success"
                              : item?.intSupportTransStatus === objSupportTransStatus?.Approved
                              ? "error"
                              : appStyle.App_Second_Color
                          }
                        >
                          {labels.objStatus?.[item?.intSupportTransStatus]?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.supportType?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {objCat?.Root?.toSupport?.[item?.strTrainingType]?.value?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.supportCat?.[lang]}>
                        <Typography as="body3">
                          {
                            dictionary?.shared?.app.sponserLabelCat?.[
                              item?.strSupportCatType
                                ? item?.strSupportCatType
                                : getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount || item?.intTrainingProgPrice, item?.strTrainingType)
                            ]?.[lang]
                          }
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.studentNumber?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {objCat?.Root?.toSupport?.[item?.strTrainingType]?.blnIsTraining?.value?.eng === "false" ? "-" : item?.intTraineesCount}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.amount?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {objCat?.Root?.toSupport?.[item?.strTrainingType]?.blnIsTraining?.value?.eng  === "false"
                            ?  Number(item?.intReceivedAmount && !item?.lstReceipt?  item?.intReceivedAmount :item?.intTrainingProgPrice)
                            : Number(item?.intReceivedAmount && !item?.lstReceipt? item?.intReceivedAmount : item?.intTrainingProgPrice * item?.intTraineesCount).toLocaleString()}
                          {dictionary?.shared?.app?.dinar?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.date?.[lang]}>
                        <Typography as="body3">{getDateUSFormat(item?.dtmCreatedDate)}</Typography>
                      </TableCell>
                      {!item?.lstReceipt?.length && !item?.lstReceiptCatch?.length ? null : (
                        <TableCell py-3 sx={{ sm: { minWidth: "250px" } }}>
                          <Grid container justifyContent={"center"}>
                            <Grid item xs="6" sm="auto">
                              {!item?.lstReceipt?.length ? null : (
                                <Button label={labels?.receipt?.[lang]} size="sm" p-0 className={classes?.btn} onClick={dilogeImgModal(item?.lstReceipt, "receipt")} />
                              )}
                            </Grid>
                            <Grid item xs="6" sm="auto">
                              {!item?.lstReceiptCatch?.length ? null : (
                                <Button
                                  label={labels?.receiptCatch?.[lang]}
                                  size="sm"
                                  p-0
                                  color="success"
                                  className={classes?.btn}
                                  onClick={dilogeImgModal(item?.lstReceiptCatch, "receiptCatch")}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={state?.blnOpenImgeModal} eventClose={dilogeImgModal()} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.[state?.selectedImgType]?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs="12">
              <img src={App_Server_Url_DownloadFiles + state?.selectedLstImg?.[0]?.path} alt={labels?.[state?.selectedImgType]?.[lang]} width="100%" />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default SupportinfoView;
