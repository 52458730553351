export const objLabels={
    "search":{eng:"Search",arb:"بحث"},
    "add":{eng:"Add",arb:"اضافة"},
    "save":{eng:"Save",arb:"حفظ"},
    "cancel":{eng:"Cancel",arb:"الغاء"},
    "delete":{eng:"Delete",arb:"حذف"},
    "edit":{eng:"Edit",arb:"تعديل"},
    "create":{eng:"Create",arb:"انشاء"},
    "close":{eng:"Close",arb:"اغلاق"},
    "select":{eng:"Select",arb:"تحديد"},
    "manageCategory":{eng:"Manage",arb:"ادارة"},
    "viewCategory":{eng:"View",arb:"عرض"},
    "strName":{eng:"Name",arb:"الاسم"},
    "required":{eng:"The field required!",arb:"الحقل مطلوب!"},
}

export const objLanguage={
    eng: { blnActive: true, blnIsMandatory: true, label: "English" },
    arb: { blnActive: true, blnIsMandatory: true, label: "العربية" }
};

export const currentLanguage="eng"


export const objFelidName={intID:"intID",strName:"strName",intParentID:"intParentID"};
