import React from 'react'
import ManageMediaView from './ManageMedia.view'

function ManageMedia() {
  return (
    <React.Fragment>
        <ManageMediaView />
    </React.Fragment>
  )
}

export default ManageMedia