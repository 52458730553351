
export function ViewAlert(strMessage,App_IsInTestingMode) {
  if (App_IsInTestingMode) {
    alert(strMessage);
  }
}

export function ThrowError(error,App_IsInTestingMode) {
  if (App_IsInTestingMode) {
    throw new Error(error);
  }
}

export function logMessage(strPageName, strTitle, strMessage,App_IsInTestingMode) {
  if (App_IsInTestingMode) {
    console.log(" ");
    console.log(strPageName + "*------------------(" + strTitle + ")----------------------------");
    console.log("*");
    console.log(strMessage);
    console.log("*");
    console.log("**********************************************************************************************************");
    console.log(" ");
  }
}


export function logError(strPageName, strTitle, strMessage,App_IsInTestingMode) {
  if (App_IsInTestingMode) {
    console.log(" ");
    console.error(strPageName + "*------------------(" + strTitle + ")----------------------------");
    console.log("*");
    console.error(strMessage);
    console.log("*");
    console.log("**********************************************************************************************************");
    console.log(" ");
  }
}


