import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";

const strPageInfo = "@src/client/component/public/trainingRegistration/trainingRegistration.ctrl.js";
const tblUser = generateQueries("tblUser");

export const CtrlTrainingRegistration = ({ userInfo, appState, appDispatch, isLoading, setIsLoading, countryCode }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  let jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields, interviewfields, lang }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);

          if (!appState?.clientInfo?.objSystemInfo?.jsnSystemConfig || JSON?.stringify(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig)?.length < 500) {
            const objSystem = await getNewSystemInfo(appDispatch);
            jsnSystemConfig = objSystem.jsnSystemConfig;
          }
          if (!appState?.clientInfo?.objCatInfo || String(appState?.clientInfo?.objCatInfo)?.length < 500) {
            await getNewCatInfo(appDispatch);
          }
          if (userInfo?.strTrainingTypeCode) {
            state.countryCode = userInfo?.strTrainingTypeCode;
            appState.clientInfo.clientCountryCode = userInfo?.strTrainingTypeCode;
          } else {
            if (countryCode && !jsnSystemConfig?.lstContryRegisterCode?.includes(countryCode)) {
              state.countryCode = "";
              navigate(appRouting.page404?.url);
            }
            if (!countryCode) {
              state.countryCode = appState.clientInfo.clientCountryCode ? appState.clientInfo.clientCountryCode : jsnSystemConfig?.strDefaultContryRegisterCode;
            } else {
              state.countryCode = countryCode;
              appState.clientInfo.clientCountryCode = countryCode;
            }
          }

          state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;

          //#endregion set options
          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
          let lstCountryOption = [];
          let lstCountryResidenceOption = [];
          let lstUniversityOption = [];
          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGenderOption = [];
          let lstTypeGovSector = [];
          let lstAeraCodeOption = [];
          let countryID = "";
          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              if (jsnSystemConfig?.lstContryRegisterCode?.includes(value?.code?.value?.eng)) {
                lstCountryOption.push({ key: value?.id, value: value?.nationality?.value });
                lstCountryResidenceOption.push({ key: value?.id, value: value?.value });
                if (value?.phoneCode) {
                  lstAeraCodeOption.push({ key: value?.id, value: { eng: `+${value?.phoneCode?.value?.eng}`, arb: `${value?.phoneCode?.value?.arb}+` } });
                }
                if (state?.countryCode === value?.code?.value?.eng) {
                  countryID = value?.id;
                  state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.eng}`;
                  Object.entries(value?.university || {}).forEach(([key, value]) => {
                    if (key !== "id" && key !== "value") {
                      lstUniversityOption.push({ key: value?.id, value: value?.value });
                      if (value?.phoneCode) {
                        lstAeraCodeOption.push({ key: value?.id, value: { eng: `+${value?.phoneCode?.value?.eng}`, arb: `+${value?.phoneCode?.value?.arb}` } });
                      }
                    }
                  });

                  lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });
                }
              }
            }
          });

          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value], index) => {
            if (key !== "id" && key !== "value" && index < 6) {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.typeGovSector || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstTypeGovSector.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigCountryOfCitizenID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigCountryOfResidenceID.setOptions([...lstCountryResidenceOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields?.bigAreaCountryID?.setOptions(lstAeraCodeOption);
          fields.bigUniversityName.setOptions([...lstUniversityOption], true);
          fields.bigAcademicYearID.setOptions([...lstAcademicYearOption]);
          fields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);
          interviewfields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);

          fields?.bigAreaCountryID?.setValue(countryID);
          fields?.bigCountryOfCitizenID?.setValue(countryID);
          fields?.bigCountryOfResidenceID?.setValue(countryID);

          //#endregion

          if (!appState?.clientInfo?.blnIsUserLoggedIn) {
            fields?.strUserPassword?.setValidation("required", true, false);
            fields?.strConfirmPassword?.setValidation("required", true, false);
          }

          //#region setValues
          if (userInfo?.jsnUserInfo?.strFullName) {
            if (typeof userInfo?.jsnUserInfo?.strFullName === "object" && userInfo?.jsnUserInfo?.strFullName?.[lang]) {
              fields.strFullName.setValue(userInfo?.jsnUserInfo?.strFullName?.[lang]);
            } else if (typeof userInfo?.jsnUserInfo?.strFullName === "string") {
              fields.strFullName.setValue(userInfo?.jsnUserInfo?.strFullName);
            }
          }
          if (userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(userInfo?.jsnUserInfo?.strMajor);
          }
          if (userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(userInfo?.jsnUserInfo?.bigGender);
          }
          if (userInfo?.jsnUserInfo?.bigWorkSector) {
            fields.bigWorkSector2.setValue(userInfo?.jsnUserInfo?.bigWorkSector);
          }
          if (userInfo?.strUserEmail) {
            fields.strUserEmail.setValue(userInfo?.strUserEmail);
          }
          if (userInfo?.strUserPhone) {
            fields.strUserPhone.setValue(userInfo?.strUserPhone);
          }
          if (userInfo?.jsnUserInfo?.bigWorkGovSectorType) {
            fields.bigWorkGovSectorType.setValue(userInfo?.jsnUserInfo?.bigWorkGovSectorType);
            handler.onChangeWorkGovSectorType(fields)();
          }
          if (userInfo?.jsnUserInfo?.bigGovPlaceName) {
            fields.bigGovPlaceName.setValue(userInfo?.jsnUserInfo?.bigGovPlaceName);
          }
          if (userInfo?.jsnUserInfo?.bigCountryOfCitizenID) {
            fields.bigCountryOfCitizenID.setValue(userInfo?.jsnUserInfo?.bigCountryOfCitizenID);
          }
          if (userInfo?.jsnUserInfo?.bigCountryOfResidenceID) {
            fields.bigCountryOfResidenceID.setValue(userInfo?.jsnUserInfo?.bigCountryOfResidenceID);
          }
          if (userInfo?.jsnUserInfo?.strPositionTitle) {
            fields.strPositionTitle.setValue(userInfo?.jsnUserInfo?.strPositionTitle);
          }
          if (userInfo?.jsnUserInfo?.bigMajorID) {
            fields.bigMajorID.setValue(userInfo?.jsnUserInfo?.bigMajorID);
          }
          if (userInfo?.jsnUserInfo?.bigUniversityName) {
            fields.bigUniversityName.setValue(userInfo?.jsnUserInfo?.bigUniversityName);
          }
          if (userInfo?.jsnUserInfo?.strUniversityName) {
            fields.strUniversityName.setValue(userInfo?.jsnUserInfo?.strUniversityName);
          }
          if (userInfo?.jsnUserInfo?.bigAcademicYearID) {
            fields.bigAcademicYearID.setValue(userInfo?.jsnUserInfo?.bigAcademicYearID);
          }
          if (userInfo?.jsnUserInfo?.strYearGraduation) {
            fields.strYearGraduation.setValue(userInfo?.jsnUserInfo?.strYearGraduation);
          }
          if (userInfo?.jsnUserInfo?.strPlaceName) {
            fields.strPlaceName.setValue(userInfo?.jsnUserInfo?.strPlaceName);
          }
          if (userInfo?.jsnUserInfo?.intExperienceYear) {
            fields.intExperienceYear.setValue(userInfo?.jsnUserInfo?.intExperienceYear);
          }

          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev === "boolean") {
            interviewfields.blnAppsNeedDev.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal) {
            interviewfields.strExplainProposal.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec === "boolean") {
            interviewfields.blnlikeWorkinGivSec.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigWorkGovSectorType) {
            interviewfields.bigWorkGovSectorType.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigWorkGovSectorType);
            handler.onChangeWorkGovSectorType(interviewfields)();
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigGovPlaceName) {
            interviewfields.bigGovPlaceName.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigGovPlaceName);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject === "boolean") {
            interviewfields.blnGraduationProject.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject));
          } 
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea === "boolean") {
            interviewfields.blnHaveGoveIdea.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea) {
            interviewfields.strExplainIdea.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs === "boolean") {
            interviewfields.blnExpJs.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs) {
            interviewfields.intYearExpJs.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject) {
            interviewfields.intNumJsProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject) {
            interviewfields.strExpJsProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExFe === "boolean") {
            interviewfields.blnExFe.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExFe));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn) {
            interviewfields.intYearExpFn.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpFeProject) {
            interviewfields.strExpFeProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpFeProject);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode) {
            interviewfields.intYearExpNode.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode === "boolean") {
            interviewfields.blnExpNode.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs) {
            interviewfields.intYearExpJs.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject) {
            interviewfields.intNumNodeProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject) {
            interviewfields.strExpNodeProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExAi === "boolean") {
            interviewfields.blnExAi.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExAi));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi) {
            interviewfields.intYearExpAi.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject) {
            interviewfields.strExpAiProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS === "boolean") {
            interviewfields.blnExCybS.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS) {
            interviewfields.intYearExpCybS.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject) {
            interviewfields.strExpCybSProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject);
          }
          state.lstIntroductoryVideo = userInfo?.jsnUserInfo?.lstIntroductoryVideo || [];
          state.lstTranscript = userInfo?.jsnUserInfo?.lstTranscript || [];
          // if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExMobile === "boolean") {
          //   interviewfields.blnExMobile.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExMobile));
          // }
          // if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpMobile) {
          //   interviewfields.intYearExpMobile.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpMobile);
          // }
          // if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpMobileProject) {
          //   interviewfields.strExpMobileProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpMobileProject);
          // }
          //#endregion
          appState.clientInfo.strLanguage = lang || "arb";
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    onChangeAreaCode:
      ({ fields, state, setState }) =>
      () => {
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        let lstUniversityOption = [];
        Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            if (
              fields?.bigCountryOfCitizenID?.getValue()?.key === value?.id ||
              fields?.bigAreaCountryID?.getValue()?.key === value?.id ||
              fields?.bigCountryOfResidenceID?.getValue()?.key === value?.id
            ) {
              state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.eng}`;
              fields?.bigCountryOfResidenceID?.setValue(value?.id);
              fields?.bigAreaCountryID?.setValue(value?.id);

              Object.entries(value?.university || {}).forEach(([key, value]) => {
                if (key !== "id" && key !== "value") {
                  lstUniversityOption.push({ key: value?.id, value: value?.value });
                }
              });
              lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });
              state.countryCode = value?.code?.value?.eng;
              state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;

              if (fields?.bigWorkSector2?.getValue()?.key === appVariables.workSectorID?.gov) {
                fields?.strPlaceName?.setValidation("required", false);
                fields?.strPlaceName?.setError("");

                fields?.bigGovPlaceName?.setValidation("required", false);
                fields?.bigGovPlaceName?.setError("");

                if (state.blnDefaultCountry) {
                  fields?.bigGovPlaceName?.setValidation("required", true);
                } else {
                  fields?.strPlaceName?.setValidation("required", true);
                }
              }
             
              setState({ ...state });
            }
          }
        });
        fields.bigUniversityName.setOptions([...lstUniversityOption], true);
      },

    getUserObj: ({ fields, interviewfields, state }) => {
      const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
      let bigID = appFunctions.generateID(10);
      let countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[countryCode]];
      const countryInfoObj = {};
      if (jsnSystemConfig?.blnContryName && fields?.bigCountryOfResidenceID?.getValue()?.key) {
        const countryID = fields?.bigCountryOfResidenceID?.getValue()?.key;
        const getCountryKey = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
        state.countryCode = objCat?.Root?.country?.[getCountryKey]?.code?.value?.["eng"];
        countryInfoObj.bigCountryOfResidenceID = countryID;
      } else if (fields?.bigAreaCountryID?.getValue()?.key && jsnSystemConfig?.blnAreaCode) {
        const countryID = fields?.bigAreaCountryID?.getValue()?.key;
        const getCountryKey = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
        state.countryCode = objCat?.Root?.country?.[getCountryKey]?.code?.value?.["eng"];
        countryInfoObj.bigCountryOfResidenceID = countryID;
      } else if (countryCode && countryObj) {
        countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
      } else if (!countryCode) {
        countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[jsnSystemConfig?.strDefaultContryRegisterCode || "kw"]];
        countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
      }
      countryInfoObj.bigCountryOfCitizenID = fields?.bigCountryOfCitizenID?.getValue()?.key
        ? fields?.bigCountryOfCitizenID?.getValue()?.key
        : countryInfoObj.bigCountryOfResidenceID;

      const objectInput = {
        ...(userInfo ? userInfo : {}),
        bigUserID: userInfo?.bigUserID ? userInfo?.bigUserID : bigID,
        bigAppID: appVariables.App_ID,
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,

        strUserEmail: fields?.strUserEmail?.getValue(),
        strUserPhone: fields?.strUserPhone?.getValue(),
        strTrainingTypeCode: state?.countryCode,

        jsnUserInfo: {
          ...(userInfo?.jsnUserInfo ? userInfo?.jsnUserInfo : {}),
          // strFullName: { eng: fields?.strFullName?.getValue() || "", arb: fields?.strFullName?.getValue() || "" },
          strFullName: fields?.strFullName?.getValue(),
          intUserRoleFK: userInfo?.jsnUserInfo?.intUserRoleFK ? userInfo?.jsnUserInfo?.intUserRoleFK : appVariables?.objUserRole?.Member,
          bigWorkGovSectorType: fields?.bigWorkGovSectorType?.getValue()?.key || "",
          bigGovPlaceName: fields?.bigGovPlaceName?.getValue()?.key || "",
          bigWorkSector: fields?.bigWorkSector2?.getValue()?.key,
          strMajor: fields?.bigMajorID?.getValue()?.key,
          strPositionTitle: fields?.strPositionTitle?.getValue(),
          bigGender: fields?.bigGender?.getValue()?.key,
          bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
          strYearGraduation: fields?.strYearGraduation?.getValue(),
          strPlaceName: fields?.strPlaceName?.getValue(),
          intExperienceYear: fields?.intExperienceYear?.getValue(),
          bigUniversityName: fields?.bigUniversityName?.getValue()?.key,
          strUniversityName: fields?.strUniversityName?.getValue(),
          ...countryInfoObj,
          jsnInterviewInfo: {
            blnAppsNeedDev: interviewfields?.blnAppsNeedDev?.getValue() ? interviewfields?.blnAppsNeedDev?.getValue()?.key === "true" : "",
            strExplainProposal: interviewfields?.strExplainProposal?.getValue(),
            blnlikeWorkinGivSec: interviewfields?.blnlikeWorkinGivSec?.getValue() ? interviewfields?.blnlikeWorkinGivSec?.getValue()?.key === "true" : "",
            bigWorkGovSectorType: interviewfields?.bigWorkGovSectorType?.getValue()?.key,
            bigGovPlaceName: interviewfields?.bigGovPlaceName?.getValue()?.key,
            blnGraduationProject: interviewfields?.blnGraduationProject?.getValue() ? interviewfields?.blnGraduationProject?.getValue()?.key === "true" : "",
            blnHaveGoveIdea: interviewfields?.blnHaveGoveIdea?.getValue() ? interviewfields?.blnHaveGoveIdea?.getValue()?.key === "true" : "",
            strExplainIdea: interviewfields?.strExplainIdea?.getValue(),
            blnExpJs: interviewfields?.blnExpJs?.getValue() ? interviewfields?.blnExpJs?.getValue()?.key === "true" : "",
            intYearExpJs: interviewfields?.intYearExpJs?.getValue(),
            intNumJsProject: interviewfields?.intNumJsProject?.getValue(),
            strExpJsProject: interviewfields?.strExpJsProject?.getValue(),
            blnExFe: interviewfields?.blnExFe?.getValue() ? interviewfields?.blnExFe?.getValue()?.key === "true" : "",
            intYearExpFn: interviewfields?.intYearExpFn?.getValue(),
            strExpFeProject: interviewfields?.strExpFeProject?.getValue(),
            blnExpNode: interviewfields?.blnExpNode?.getValue() ? interviewfields?.blnExpNode?.getValue()?.key === "true" : "",
            intYearExpNode: interviewfields?.intYearExpNode?.getValue(),
            intNumNodeProject: interviewfields?.intNumNodeProject?.getValue(),
            strExpNodeProject: interviewfields?.strExpNodeProject?.getValue(),
            blnExAi: interviewfields?.blnExAi?.getValue() ? interviewfields?.blnExAi?.getValue()?.key === "true" : "",
            intYearExpAi: interviewfields?.intYearExpAi?.getValue(),
            strExpAiProject: interviewfields?.strExpAiProject?.getValue(),
            blnExCybS: interviewfields?.blnExCybS?.getValue() ? interviewfields?.blnExCybS?.getValue()?.key === "true" : "",
            intYearExpCybS: interviewfields?.intYearExpCybS?.getValue(),
            strExpCybSProject: interviewfields?.strExpCybSProject?.getValue(),
            // blnExMobile: interviewfields?.blnExMobile?.getValue() ? interviewfields?.blnExMobile?.getValue()?.key === "true" : "",
            // intYearExpMobile: interviewfields?.intYearExpMobile?.getValue(),
            // strExpMobileProject: interviewfields?.strExpMobileProject?.getValue(),
          },
          dtmRegisterStudent: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
        },
      };

      return objectInput;
    },
    gOToStep2:
      ({ state, setState, fields, interviewfields }) =>
      () => {
        const bigWorkSectorId = fields?.bigWorkSector2?.getValue()?.key || 0;
        if (bigWorkSectorId) {
          // fields?.strPositionTitle?.setValidation("required", false);
          // fields?.bigWorkGovSectorType?.setValidation("required", false);
          // fields?.bigGovPlaceName?.setValidation("required", false);
          // fields?.intExperienceYear?.setValidation("required", false);
          // fields?.bigCountryOfCitizenID?.setValidation("required", false);
          // fields?.bigUniversityName?.setValidation("required", false);
          // fields?.strUniversityName?.setValidation("required", false);
          // fields?.bigMajorID?.setValidation("required", false);
          // fields?.bigAcademicYearID?.setValidation("required", false);
          // fields?.strYearGraduation?.setValidation("required", false);
          // fields?.strYearGraduation?.setValidation("minLength", "0");
          // fields?.strPlaceName?.setValidation("required", false);
          // fields?.bigCountryOfResidenceID?.setValidation("required", false);
          // fields?.bigAreaCountryID?.setValidation("required", false);
          // fields?.strUserPhone?.setValidation("required", false);

          fields?.strPositionTitle?.setError("");
          fields?.bigWorkGovSectorType?.setError("");
          fields?.bigGovPlaceName?.setError("");
          fields?.intExperienceYear?.setError("");
          fields?.bigCountryOfCitizenID?.setError("");
          fields?.bigCountryOfResidenceID?.setError("");
          fields?.bigAreaCountryID?.setError("");
          fields?.strUserPhone?.setError("");
          fields?.bigUniversityName?.setError("");
          fields?.strUniversityName?.setError("");
          fields?.bigMajorID?.setError("");
          fields?.bigAcademicYearID?.setError("");
          fields?.strYearGraduation?.setError("");
          fields?.strPlaceName?.setError("");

          // switch (bigWorkSectorId) {
          //   case appVariables?.workSectorID?.gov:
          //     // fields?.strPositionTitle?.setValidation("required", true);
          //     // fields?.bigWorkGovSectorType?.setValidation("required", true);
          //     // fields?.intExperienceYear?.setValidation("required", true);
          //     // if (jsnSystemConfig?.strDefaultContryRegisterCode === state?.countryCode) {
          //     //   fields?.bigGovPlaceName?.setValidation("required", true);
          //     // } else {
          //     //   fields?.strPlaceName?.setValidation("required", true);
          //     // }
          //     break;
          //   case appVariables?.workSectorID?.student:
          //     // fields?.bigCountryOfCitizenID?.setValidation("required", true);
          //     // fields?.bigUniversityName?.setValidation("required", true);
          //     // fields?.bigMajorID?.setValidation("required", true);
          //     // fields?.bigAcademicYearID?.setValidation("required", true);
          //     break;
          //   case appVariables?.workSectorID?.unEmployee:
          //     // fields?.bigCountryOfCitizenID?.setValidation("required", true);
          //     // fields?.bigUniversityName?.setValidation("required", true);
          //     // fields?.bigMajorID?.setValidation("required", true);
          //     // fields?.strYearGraduation?.setValidation("required", true);
          //     break;
          //   case appVariables?.workSectorID?.prvt:
          //     // fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
          //     // fields?.strPositionTitle?.setValidation("required", true, false);
          //     // fields?.strPlaceName?.setValidation("required", true, false);
          //     // fields?.intExperienceYear?.setValidation("required", true, false);
          //     break;
          //   default:
          //     break;
          // }

          if (jsnSystemConfig?.blnContryName) {
            fields?.bigCountryOfResidenceID?.setValidation("required", true);
          } else if (jsnSystemConfig?.blnAreaCode) {
            fields?.bigAreaCountryID?.setValidation("required", true);
            fields?.strUserPhone?.setValidation("required", true);
          }
          setState({ ...state, intStep: 2 });
        } else {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        if (!state?.blnIsAdminEdit) {
          appState.userInfo = { ...appState.userInfo, ...handler?.getUserObj({ fields, interviewfields, state }) };
          appDispatch();
        }
      },

    gOToStep3:
      ({ state, setState, fields, interviewfields }) =>
      () => {
        console.log(fields);
        

        // if (fields?.bigUniversityName?.getValue()?.key === "other") {
        //   // fields?.strUniversityName?.setValidation("required", true);
        // } else {
        //   // fields?.strUniversityName?.setValidation("required", false);
          
        // }

        // if (interviewfields?.blnExFe?.getValue()?.key === "true" && interviewfields?.blnExpJs?.getValue()?.key === "false") {
        //   if (!interviewfields?.intYearExpFn?.getValue()) {
        //     interviewfields?.intYearExpFn?.setError(dictionary?.formValidationsMsg?.required);
        //   } else {
        //     interviewfields?.intYearExpFn?.setError("");
        //   }
        //   if (!interviewfields?.strExpFeProject?.getValue()) {
        //     interviewfields?.strExpFeProject?.setError(dictionary?.formValidationsMsg?.required);
        //   } else {
        //     interviewfields?.strExpFeProject?.setError("");
        //   }
        // } else {
        //   interviewfields?.intYearExpFn?.setValidation("required", false);
        //   interviewfields?.strExpFeProject?.setValidation("required", false);

        //   interviewfields?.intYearExpFn?.setError("");
        //   interviewfields?.strExpFeProject?.setError("");
        // }

        // interviewfields?.blnAppsNeedDev?.setValidation("required", false);
        // interviewfields?.strExplainProposal?.setValidation("required", false);
        // interviewfields?.blnlikeWorkinGivSec?.setValidation("required", false);
        // interviewfields?.bigWorkGovSectorType?.setValidation("required", false);
        // interviewfields?.bigGovPlaceName?.setValidation("required", false);
        // interviewfields?.blnGraduationProject?.setValidation("required", false);
        // interviewfields?.blnHaveGoveIdea?.setValidation("required", false);
        // interviewfields?.strExplainIdea?.setValidation("required", false);
        // // interviewfields?.blnExpJs?.setValidation("required", false);
        // interviewfields?.intYearExpJs?.setValidation("required", false);
        // interviewfields?.intNumJsProject?.setValidation("required", false);
        // interviewfields?.strExpJsProject?.setValidation("required", false);
        // interviewfields?.blnExFe?.setValidation("required", false);
        // interviewfields?.intYearExpFn?.setValidation("required", false);
        // interviewfields?.strExpFeProject?.setValidation("required", false);
        // // interviewfields?.blnExpNode?.setValidation("required", false);
        // interviewfields?.intYearExpNode?.setValidation("required", false);
        // interviewfields?.intNumNodeProject?.setValidation("required", false);
        // interviewfields?.strExpNodeProject?.setValidation("required", false);
        // // interviewfields?.blnExAi?.setValidation("required", false);
        // interviewfields?.intYearExpAi?.setValidation("required", false);
        // interviewfields?.strExpAiProject?.setValidation("required", false);
        // // interviewfields?.blnExCybS?.setValidation("required", false);
        // interviewfields?.intYearExpCybS?.setValidation("required", false);
        // interviewfields?.strExpCybSProject?.setValidation("required", false);

        interviewfields?.blnAppsNeedDev?.setError("");
        interviewfields?.strExplainProposal?.setError("");
        interviewfields?.blnlikeWorkinGivSec?.setError("");
        interviewfields?.bigWorkGovSectorType?.setError("");
        interviewfields?.bigGovPlaceName?.setError("");
        interviewfields?.blnGraduationProject?.setError("");
        interviewfields?.blnHaveGoveIdea?.setError("");
        interviewfields?.strExplainIdea?.setError("");
        interviewfields?.blnExpJs?.setError("");
        interviewfields?.intYearExpJs?.setError("");
        interviewfields?.intNumJsProject?.setError("");
        interviewfields?.strExpJsProject?.setError("");
        interviewfields?.blnExFe?.setError("");
        interviewfields?.intYearExpFn?.setError("");
        interviewfields?.strExpFeProject?.setError("");
        interviewfields?.blnExpNode?.setError("");
        interviewfields?.intYearExpNode?.setError("");
        interviewfields?.intNumNodeProject?.setError("");
        interviewfields?.strExpNodeProject?.setError("");
        interviewfields?.blnExAi?.setError("");
        interviewfields?.intYearExpAi?.setError("");
        interviewfields?.strExpAiProject?.setError("");
        interviewfields?.blnExCybS?.setError("");
        interviewfields?.intYearExpCybS?.setError("");
        interviewfields?.strExpCybSProject?.setError("");

        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        setState({ ...state, intStep: 3 });
        if (!state?.blnIsAdminEdit) {
          appState.userInfo = { ...appState.userInfo, ...handler?.getUserObj({ fields, interviewfields, state }) };
          appDispatch();
        }
      },
    gOToStepPre:
      ({ fields, interviewfields, state, setState }) =>
      () => {
        setState({ ...state, intStep: state.intStep - 1 });
        if (!state?.blnIsAdminEdit) {
          appState.userInfo = { ...appState.userInfo, ...handler?.getUserObj({ fields, interviewfields, state }) };
          appDispatch();
        }
      },

    onChangeWorkGovSectorType: (fields) => () => {
      try {
        //#endregion set options
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        const workGovSectorTypeid = fields.bigWorkGovSectorType.getValue()?.key || "";
        if (!workGovSectorTypeid) return;

        const workGovSectorTypeKey = appFunctions?.appCategoryGetKey(objCat?.Root?.typeGovSector, workGovSectorTypeid);
        let lstGovPlaceName = [];
        Object.entries(objCat?.Root?.typeGovSector?.[workGovSectorTypeKey] || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstGovPlaceName.push({ key: value?.id, value: value?.value });
          }
        });

        fields.bigGovPlaceName.setOptions([...lstGovPlaceName, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }], true);

        //#endregion

        //#region setValues
        if (userInfo?.jsnUserInfo?.strFullName) {
          fields.strFullName.setValue(userInfo?.jsnUserInfo?.strFullName?.[lang] || userInfo?.jsnUserInfo?.strFullName);
        }
        if (userInfo?.jsnUserInfo?.strMajor) {
          fields.bigMajorID.setValue(userInfo?.jsnUserInfo?.strMajor);
        }
        if (userInfo?.jsnUserInfo?.bigGender) {
          fields.bigGender.setValue(userInfo?.jsnUserInfo?.bigGender);
        }
        if (userInfo?.jsnUserInfo?.bigWorkSector) {
          fields.bigWorkSector2.setValue(userInfo?.jsnUserInfo?.bigWorkSector);
        }
        //#endregion

        setIsLoading(false);
        // if (!state?.blnIsAdminEdit) {
        //   appDispatch();
        // }
      } catch (error) {
        setIsLoading(false);
        appFunctions.logError(strPageInfo, "initData-ERROR", error);
      }
    },

    onChangesetValidation:
      ({ fields, interviewfields, state }) =>
      (mainfieldName, lstfieldsName = [], lstNotfieldsName = []) =>
      () => {
        const blnValue = interviewfields?.[mainfieldName]?.getValue()?.key === "true";

        for (let index = 0; index < lstfieldsName.length; index++) {
          if (blnValue) {
            interviewfields?.[lstfieldsName[index]]?.setValidation("required", true);
            if (!interviewfields?.[lstfieldsName[index]]?.getValue() || !interviewfields?.[lstfieldsName[index]]?.getValue()?.key) {
              if (!interviewfields?.[lstNotfieldsName[index]]?.getValue()) {
                interviewfields?.[lstfieldsName[index]]?.setError(dictionary?.formValidationsMsg?.required);
              } else {
                interviewfields?.[lstfieldsName[index]]?.setError("");
              }
            } else {
              interviewfields?.[lstfieldsName[index]]?.setValidation("required", false);
              interviewfields?.[lstfieldsName[index]]?.setError("");
            }
          } else {
            interviewfields?.[lstfieldsName[index]]?.setValidation("required", false);
            interviewfields?.[lstfieldsName[index]]?.setError("");
          }
        }
        for (let index = 0; index < lstNotfieldsName.length; index++) {
          if (!blnValue) {
            interviewfields?.[lstfieldsName[index]]?.setValidation("required", true);
            if (!interviewfields?.[lstfieldsName[index]]?.getValue()) {
              if (!interviewfields?.[lstNotfieldsName[index]]?.getValue() || !interviewfields?.[lstfieldsName[index]]?.getValue()) {
                interviewfields?.[lstfieldsName[index]]?.setError(dictionary?.formValidationsMsg?.required);
              } else {
                interviewfields?.[lstfieldsName[index]]?.setError("");
              }
            } else {
              interviewfields?.[lstfieldsName[index]]?.setValidation("required", false);
              interviewfields?.[lstfieldsName[index]]?.setError("");
            }
          } else {
            interviewfields?.[lstNotfieldsName[index]]?.setValidation("required", false);
            interviewfields?.[lstNotfieldsName[index]]?.setError("");
          }
        }
      },

    handleSubmit: async ({ fields, interviewfields, navigate, state, setState, uploaderVideo, uploaderTranscript }) => {
      try {
        const onChangesetValidation = handler.onChangesetValidation({ fields, interviewfields, state });

        if (fields?.bigWorkSector2?.getValue()?.key === appVariables.workSectorID?.gov) {
          onChangesetValidation("blnGraduationProject", ["strExplainProposal"])();
        } else {
          onChangesetValidation("blnlikeWorkinGivSec", ["bigWorkGovSectorType", "bigGovPlaceName", "blnGraduationProject", "blnHaveGoveIdea"])();
        }
        onChangesetValidation("blnHaveGoveIdea", ["strExplainIdea"])();

        onChangesetValidation("blnExpJs", ["intYearExpJs", "intNumJsProject", "strExpJsProject"])();
        if (interviewfields?.blnExpJs?.getValue()?.key === "true") {
          interviewfields?.blnExFe?.setValidation("required", false);
          interviewfields?.blnExFe?.setError("");
          interviewfields?.intYearExpFn?.setValidation("required", false);
          interviewfields?.intYearExpFn?.setError("");
          interviewfields?.strExpFeProject?.setValidation("required", false);
          interviewfields?.strExpFeProject?.setError("");
        } else {
          onChangesetValidation("blnExFe", ["intYearExpFn", "strExpFeProject"])();
        }
        onChangesetValidation("blnExpNode", ["intYearExpNode", "intNumNodeProject", "strExpNodeProject"])();
        onChangesetValidation("blnExAi", ["intYearExpAi", "strExpAiProject"])();
        onChangesetValidation("blnExCybS", ["intYearExpCybS", "strExpCybSProject"])();

        if (!fields?.controller?.isFormValid() || !interviewfields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);

          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqVideoCode?.includes(state?.countryCode)) {
          if (!state?.lstIntroductoryVideo?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.studentUploadFile?.[lang], "error", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          if (state?.lstIntroductoryVideo?.length) {
            const resultUpload = await uploaderVideo?.uploadHandler("lstIntroductoryVideo");
            if (!resultUpload) {
              setIsLoading(false);
              return;
            }
          }
        }

        if (
          jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqTranscriptCode?.includes(state?.countryCode) &&
          fields?.bigWorkSector2?.getValue()?.key === appVariables.workSectorID?.student
        ) {
          if (!state?.lstTranscript?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.studentUploadFile?.[lang], "error", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          if (state?.lstTranscript?.length) {
            const resultUpload = await uploaderTranscript?.uploadHandler("lstTranscript");
            if (!resultUpload) {
              setIsLoading(false);
              return;
            }
          }
        }

        const objectInput = handler?.getUserObj({ fields, interviewfields, state });
        objectInput.jsnUserInfo.lstIntroductoryVideo = state?.lstIntroductoryVideo;
        objectInput.jsnUserInfo.lstTranscript = state?.lstTranscript;

        if (!appState?.clientInfo?.blnIsUserLoggedIn) {
          objectInput.strUserPassword = fields?.strUserPassword?.getValue();
          objectInput.lstStatisticFelid = ["intCountJoinUser", "intCountJoinUserAll", "intCountJoinStudentAll", "intCountJoinStudent"];
          objectInput.blnIsStudent = true;
          objectInput.jsnUserInfo.strFullName = { eng: fields?.strFullName?.getValue() || "", arb: fields?.strFullName?.getValue() || "" };
          objectInput.jsnUserInfo.blnNewRegStudent = true;

          const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
            objectInput: objectInput,
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              state.intStep = 2;
              if (result?.response === "strUserPhone already used by another users!") {
                fields.strUserPhone.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
              if (result?.response === "strUserEmail already used by another users!") {
                fields.strUserEmail.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
              setState({ ...state });
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }

          if (!state?.blnIsAdminEdit) {
            appState.userInfo = result.response;
            appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
            appDispatch();
          }
        } else {
          if (!objectInput?.blnIsStudent) {
            objectInput.lstStatisticFelid = ["intCountJoinStudentAll", "intCountJoinStudent"];
            objectInput.blnIsStudent = true;
          }

          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: { ...objectInput, dtmUpdatedDate: new Date()?.toUTCString() },
            objectCondition: {
              bigAppID: appVariables.App_ID,
              bigSystemID: userInfo?.bigSystemID,
              bigUserID: userInfo?.bigUserID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              state.intStep = 2;
              setState({ ...state });
              if (result?.response === "strUserPhone already used by another users!") {
                fields.strUserPhone.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
              if (result?.response === "strUserEmail already used by another users!") {
                fields.strUserEmail.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }
          if (!state?.blnIsAdminEdit) {
            appState.userInfo = { ...userInfo, ...objectInput };
          }
        }

        setIsLoading(false);
        if (!appState.clientInfo.blnIsUserLoggedIn) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.submit_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } else {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
        }

        if (!state?.blnIsAdminEdit) {
          appState.clientInfo.blnIsUserLoggedIn = true;
          appDispatch();
          navigate(appRouting?.Account?.url, { replace: true });
        } else {
          // navigate( -1, { replace: true });
          window.history.back();
        }
        fields?.controller?.resetForm();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};
