import React, { useEffect, useRef } from "react";
import "./Card.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const CardDetails = (props) => {
  const { sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  if(props?.[strTestProps]){
    if(props?.sx){
      console.log("CardDetails", "sx",);
    }
  }
  const getID = useRef(id || componentsFunction.randomName("CardDetails"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-card-details");
    }
  }, [sx, screenWidth]);

  return (
    <div
      id={getID.current}
      className={`cbmis-card-details ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
        hidden
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      <div className="cbmis-card-details-frame">{props.children}</div>
    </div>
  );
};

export default CardDetails;
