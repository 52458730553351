import React, { useEffect, useState } from 'react'
import "./ViewCategories.css"

import Modal from '../../../material/dataDisplay/Modal/Modal';
import ModalHeader from '../../../material/dataDisplay/Modal/ModalHeader';
import ModalBody from '../../../material/dataDisplay/Modal/ModalBody';
import Typography from '../../../material/dataDisplay/Typography/Typography';
import Grid from '../../../material/layout/Grid/Grid';
import Button from '../../../material/inputs/Button/Button';
import Text from '../../../material/inputs/Text/Text';

import * as categoryVariables from "../CategoryVariables"
import RecursiveAccordion from '../RecursiveAccordion/RecursiveAccordion'
import AddEditForm from '../AddEditForm/AddEditForm'



function ViewCategories(props) {
    const {
        blnIsSelect,
        list=[],
        rootID=0,
        objFelidName=categoryVariables.objFelidName,
        lstSelectedID=[],
        blnRootHasCheckbox=true,
        blnAddUnderTheRoot = true,
        blnAddRoot=true,
        blnEditRoot=true,
        blnDeleteRoot= true,
        blnSearch=true,
        blnAdd=true,
        blnEdit=true,
        blnDelete=true,
        blnOpenDialog=true,
        handleAdd=null,
        handleEdit=null,
        handleDelete=null,
        handleChecked=null,
        handleSubmit=null,
        FormComponent=null,
        handleOpenDialog=null,
        color="primary",
        objLabels=categoryVariables.objLabels,
        objLanguage=categoryVariables.objLanguage,
        currentLanguage=categoryVariables.currentLanguage,
          } = props

    const [stateInfo, setStateInfo] = useState({
        lstSelectedID:  [],
        treeList:[],
        readTreeList:[],
        open:false,
        selection:"",
        bigParentID:-1
    });

    const listToObjTree = (arr = [],idRoot=rootID) => {
        const obj ={}
        return arr.filter((item)=>String(item[objFelidName?.intParentID]) === String(idRoot)).map((item)=>{
          item.children = listToObjTree(list,item?.[objFelidName?.intID])
           return obj[item?.[objFelidName?.intID]] = item
        })
    }

    useEffect(() => {
        let theTreeList = list
        if(Array.isArray(list)){
            theTreeList = listToObjTree(list)
        }
        setStateInfo({
            ...stateInfo,
            treeList:theTreeList,
            readTreeList:theTreeList,
        })
    },[list,rootID])

    const handleOpenAddEdit = (event,categorySelect,parentID=-1)=>{
        if(categorySelect){
            if(handleOpenDialog){
                handleOpenDialog(event,"edit",categorySelect,parentID)
            }
            setStateInfo({
                ...stateInfo,
                open:true,
                selection:categorySelect,
                bigParentID:categorySelect.bigParentID,
            })
            
        }else{
            if(handleOpenDialog){
                handleOpenDialog(event,"add",null,parentID)
            }
            setStateInfo({
                ...stateInfo,
                open:true,
                selection:null,
                bigParentID:parentID,
            })
        }
    }

    const handleCloseModal = ()=>{
        setStateInfo({
            ...stateInfo,
            open:false,
            selection:null,
            bigParentID:-1,
        })
    }

    const handleSearchChange=e=>{
        const value= e.target.value
        if(value){
          const listFilter=list.filter(item=>String(item?.[objFelidName?.strName]).toLowerCase().includes(String(value).toLowerCase()))
          setStateInfo({
            ...stateInfo,
            readTreeList:listFilter,
        })
          
        }else{
          setStateInfo({
            ...stateInfo,
            readTreeList:stateInfo?.treeList,
        })
    
        }
    }

  return (
    <React.Fragment>
        <Grid container>
            {
                blnSearch || blnAddRoot?(
                    <Grid item xs="12" container pb-3>
                       {
                        !blnSearch?null:(
                            <Grid item xs={blnAddRoot?10:12} lg={blnAddRoot?11:12} sx={{placeContent: "baseline start !important"}}>
                                <Text icon="search" placeholder={objLabels?.search?.[currentLanguage]} onChange={handleSearchChange}/>
                            </Grid>
                        )
                       }
                       {!blnAddRoot && !blnIsSelect?null:(
                            <Grid item sx={{placeContent: "baseline end !important"}} pt-2>
                              <Button icon="add" 
                                    onClick={(event)=>handleOpenAddEdit(event,null,rootID)}
                              />
                            </Grid>
                        )}
                    </Grid>
                ):null
            }
            <Grid item xs="12">
                <ul className="cbmis_tree">
                {
                    stateInfo?.readTreeList?.map((category) => {
                        return (
                            <React.Fragment key={category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}>
                                <RecursiveAccordion
                                    blnIsSelect={blnIsSelect}

                                    stateInfo={stateInfo}
                                    setStateInfo={setStateInfo}

                                    category={category} 
                                    objFelidName={objFelidName}
                                    rootID={rootID}

                                    blnRootHasCheckbox={blnRootHasCheckbox}
                                    lstSelectedID={lstSelectedID}

                                    blnAddUnderTheRoot={blnAddUnderTheRoot}
                                    blnEditRoot={blnEditRoot}
                                    blnDeleteRoot={blnDeleteRoot}

                                    blnAdd={blnAdd}
                                    blnEdit={blnEdit}
                                    blnDelete={blnDelete}

                                    handleOpenAddEdit={handleOpenAddEdit}
                                    handleAdd={handleAdd}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    handleChecked={handleChecked}

                                    color={color}
                                    objLabels={objLabels}
                                    objLanguage={objLanguage}
                                    currentLanguage={currentLanguage}
                                />
                            </React.Fragment>
                        )

                    })
                }
                </ul>
            </Grid>
            {
                !handleSubmit?null:(
                    <Grid item xs="12"  sx={{placeContent: "baseline end !important"}} px-4>
                        <Button label={objLabels?.save?.[currentLanguage]}
                                onClick={handleSubmit}
                                size="sm"
                                px-5
                        />
                    </Grid>
                )
            }
            
        </Grid>

        <Modal open={stateInfo.open && blnOpenDialog} eventClose={handleCloseModal}>
            <ModalHeader>
            <Typography>
                {`${stateInfo.selection?`${objLabels?.edit?.[currentLanguage]}`:`${objLabels?.add?.[currentLanguage]}`}`}
            </Typography>
            </ModalHeader>
            <ModalBody>
                    {
                        FormComponent?(FormComponent)
                        :(
                            <AddEditForm
                                rootID={rootID} 
                                objFelidName={objFelidName}

                                blnAddRoot={blnAddRoot}

                                blnAdd={blnAdd}
                                blnEdit={blnEdit}

                                handleAdd={handleAdd}
                                handleEdit={handleEdit}

                                stateInfo={stateInfo}
                                setStateInfo={setStateInfo}

                                objCategorySelectedInfo={stateInfo.selection}  
                                handleSubmitCategory={handleSubmit} 
                                handleUpdateCategory={handleEdit} 
                                bigParentID={stateInfo.bigParentID} 
                                handleCloseModal={handleCloseModal} 

                                color={color}
                                objLabels={objLabels}
                                objLanguage={objLanguage}
                                currentLanguage={currentLanguage}
                                />

                        )
                    }
            </ModalBody>
        </Modal>
    </React.Fragment>
  )
}

export default ViewCategories