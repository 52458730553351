import * as CryptoJS from "crypto-js";

//#region date functions
export function blnIsTodayBiggerThanTheDate(theDate) {
  const _currentDate = new Date();
  const _theDate = new Date(theDate);
  return _theDate < _currentDate;
}

export const isDate = (theDate) => {
  const isDate = Object.prototype.toString.call(theDate) === "[object Date]";
  const isValidDate = theDate && !Number.isNaN(theDate.valueOf());
  return isDate && isValidDate;
};

export function getCurrentDate(date = null) {
  if (date !== null) {
    return new Date(date);
  } else {
    return new Date();
  }
}

export function addDaysToDate(date = null, days) {
  let dtm = new Date();
  try {
    if (date) {
      dtm = new Date(date);
    }
  } catch {
    dtm = new Date();
  }
  const newDate = new Date();
  newDate.setDate(dtm.getDate() + Number(days));

  return newDate;
}

export function getDateUSFormat(dtmDate, fullDate = false) {
  try {
    if (fullDate) {
      return new Date(dtmDate).toLocaleString("default", {
        month: "short",
        day: "numeric",
        year: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "numeric",
        hour12: false,
      });
    }
    return new Date(dtmDate).toLocaleDateString("default", { month: "short", day: "numeric", year: "2-digit" });
  } catch {
    if (fullDate) {
      return new Date(dtmDate).toLocaleString("default", { month: "short", day: "numeric", year: "2-digit" });
    }
    return new Date(dtmDate).toLocaleDateString("default", { month: "short", day: "numeric", year: "2-digit" });
  }
}

export function getISODate(theDate, time_OR_date_OR_all = "all") {
  switch (time_OR_date_OR_all) {
    case "time":
      const time = new Date(theDate).toISOString().split("T")[1];
      return time.slice(1, time.length - 5);
    case "date":
      return new Date(theDate).toISOString().split("T")[0];
    case "all":
      return new Date(theDate).toISOString();
    default:
      return null;
  }
}

/**
 * - Its will get future date of previous date as object of Date.
 * @param {Date} [currentDate] by default now date
 * @param {number} intEditFactor may represent number of year or month or day or hour or minute or second.
 * @param {"second" | "minute" | "hour" | "day" | "month"  | "year"} strJumpPeriodType represent number of (intEditFactor) you want to pass.
 * @param {"after" | "before" } strMomentType to get date in future or past
 * @returns {Date}
 */
export function getSpecificDateFromNow(strMomentType, strJumpPeriodType, intEditFactor, currentDate = new Date()) {
  try {
    let currentDateFromUnixEpoch = new Date(currentDate).getTime();
    switch (strMomentType) {
      case "after":
        switch (strJumpPeriodType) {
          case "second":
            return new Date(currentDateFromUnixEpoch + intEditFactor * 1000);
          case "minute":
            return new Date(currentDateFromUnixEpoch + intEditFactor * 1000 * 60);
          case "hour":
            return new Date(currentDateFromUnixEpoch + intEditFactor * 1000 * 60 * 60);
          case "day":
            return new Date(currentDateFromUnixEpoch + intEditFactor * 1000 * 60 * 60 * 24);
          case "month":
            return new Date(currentDateFromUnixEpoch + intEditFactor * 1000 * 60 * 60 * 24 * 30.4375);
          case "year":
            return new Date(currentDateFromUnixEpoch + intEditFactor * 1000 * 60 * 60 * 24 * 30.4375 * 12);
          default:
            return new Date();
        }
      case "before":
        switch (strJumpPeriodType) {
          case "second":
            return new Date(currentDateFromUnixEpoch - intEditFactor * 1000);
          case "minute":
            return new Date(currentDateFromUnixEpoch - intEditFactor * 1000 * 60);
          case "hour":
            return new Date(currentDateFromUnixEpoch - intEditFactor * 1000 * 60 * 60);
          case "day":
            return new Date(currentDateFromUnixEpoch - intEditFactor * 1000 * 60 * 60 * 24);
          case "month":
            return new Date(currentDateFromUnixEpoch - intEditFactor * 1000 * 60 * 60 * 24 * 30.4375);
          case "year":
            return new Date(currentDateFromUnixEpoch - intEditFactor * 1000 * 60 * 60 * 24 * 30.4375 * 12);
          default:
            return new Date();
        }
      default:
        return new Date();
    }
  } catch {
    return new Date();
  }
}

/**
 * - This function return different between dtmOldestDate and dtmNewestDate, strIntervalReturnValue specify period of deference.
 * - If error occurs NaN will return.
 * - dtmOldestDate > dtmNewestDate => less than 0
 * - dtmOldestDate < dtmNewestDate => great than 0
 * @param {Date | string} dtmOldestDate
 * @param {Date | string} dtmNewestDate
 * @param {"second" | "minute" | "hour" | "day" | "month"  | "year"} strIntervalReturnValue
 * @returns {number}
 */

export function getDifferenceBetweenTwoDate(dtmOldestDate, dtmNewestDate, strIntervalReturnValue) {
  try {
    const intTime2 = new Date(dtmNewestDate).getTime();
    const intTime1 = new Date(dtmOldestDate).getTime();
    const diffTime = intTime2 - intTime1;

    switch (strIntervalReturnValue) {
      case "second":
        return diffTime / 1000;
      case "minute":
        return diffTime / 1000 / 60;
      case "hour":
        return diffTime / 1000 / 60 / 60;
      case "day":
        return diffTime / 1000 / 60 / 60 / 24;
      case "month":
        return diffTime / 1000 / 60 / 60 / 24 / 30.4375;
      case "year":
        return diffTime / 1000 / 60 / 60 / 24 / 30.4375 / 12;
      default:
        return Number.NaN;
    }
  } catch (error) {
    return Number.NaN;
  }
}

/**
 * @returns {boolean}
 */
export function checkIsValidDate(date) {
  if (!date || String(date).length < 1) {
    return false;
  }

  let dtmTmpDate = null;

  try {
    dtmTmpDate = new Date(date);
  } catch {}

  if (!dtmTmpDate || typeof dtmTmpDate?.getMonth !== "function" || Object.prototype.toString.call(dtmTmpDate) !== "[object Date]" || !(dtmTmpDate instanceof Date) || isNaN(dtmTmpDate)) {
    return false;
  }

  return true;
}

export function excelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region checking fun

export function checkParseObject(object) {
  try {
    if (object === undefined || object === null) {
      throw new Error("Unsupported data-type");
    }
    if (typeof object === "string") {
      return JSON.parse(object);
    } else if (typeof object === "object") {
      return object;
    } else {
      throw new Error("Unsupported data-type");
    }
  } catch (error) {
    return {};
  }
}

export function checkIsObjectFine(object) {
  try {
    if (object !== undefined && object !== null && object && String(object).length > 2 && String(object) !== "undefined" && String(object) !== "null" && String(object) !== "" && String(object) !== " ") {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export function checkNumber(number) {
  if (isNaN(Number(number))) {
    return 0;
  } else {
    return Number(number);
  }
}

export function safeInvoke(theObject, strKeyNameTarget) {
  try {
    return theObject[strKeyNameTarget];
  } catch (error) {
    return null;
  }
}

export const trimDash = (value) => {
  const strValue = String(value);
  if (strValue.includes("-")) {
    let temp = "";
    for (let i = 0; i < strValue.length; i++) {
      if (strValue.charAt(i) !== "-") {
        temp = temp + strValue.charAt(i);
      }
    }
    return temp;
  }
  return value;
};

export function checkFileName(objFile, strAlternativeName) {
  let fileName = String(objFile.name);
  const isContainNoneLatinsChar = /[^\u0020-\u007E]/.test(fileName);
  if (isContainNoneLatinsChar === true) {
    let lstSplitString = fileName.split(".");
    let strFileExtension = lstSplitString[lstSplitString.length - 1];
    let newFileName = strAlternativeName + "." + strFileExtension;
    return new File([objFile], newFileName, objFile);
  } else {
    return objFile;
  }
}

export const compareTwoArraysIsEqual = (array1, array2) => {
  if (Array.isArray(array1) && Array.isArray(array2) && array1.length === array2.length) {
    array1.sort();
    array2.sort();
    let blnIsEqual = true;
    for (var i = 0; i < array1.length; i++) {
      if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
        blnIsEqual = false;
        break;
      }
    }
    return blnIsEqual;
  } else {
    return false;
  }
};
//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region generating functions

/**
 * Returns a random number
 * @param {number} intLength
 * @returns {number}
 */
export function getRandomNumber(intLength) {
  let intMaxNumber = "1";
  let intLowerNumber = "1";

  for (let i = 0; i < intLength; i++) {
    if (i > 0) {
      intLowerNumber += "0";
    }
    intMaxNumber += "0";
  }

  return Math.ceil(Math.random() * (Number(intMaxNumber) - (Number(intLowerNumber) + 1)) + Number(intLowerNumber));
}

/**
 * can generate number up to 64 Digits, > 16 string else number
 * @param {number} intGeneratedIDLength
 * @returns {string | number}
 */
export function generateID(intGeneratedIDLength = 10) {
  //#region make random number series
  let dtmCurrentDate = getCurrentDate();
  let intCurrentDateFromUnixEpoch = dtmCurrentDate.getTime();
  let strCurrentDateFromUnixEpoch = String(intCurrentDateFromUnixEpoch);

  let strRandomNumberPh1 = strCurrentDateFromUnixEpoch;

  if (intGeneratedIDLength > 48) {
    strRandomNumberPh1 = strCurrentDateFromUnixEpoch + String(getRandomNumber(16)) + String(getRandomNumber(16)) + String(getRandomNumber(16)) + String(getRandomNumber(16));
  } else if (intGeneratedIDLength > 32) {
    strRandomNumberPh1 = strCurrentDateFromUnixEpoch + String(getRandomNumber(16)) + String(getRandomNumber(16)) + String(getRandomNumber(16));
  } else if (intGeneratedIDLength > 18) {
    strRandomNumberPh1 = strCurrentDateFromUnixEpoch + String(getRandomNumber(16)) + String(getRandomNumber(16));
  } else if (intGeneratedIDLength > 6) {
    strRandomNumberPh1 = strCurrentDateFromUnixEpoch + String(getRandomNumber(16));
  }
  //#endregion

  //#region shuffle random numbers
  let lstRandomNumberPh2 = Array.from(strRandomNumberPh1);
  for (let i = 0; i < lstRandomNumberPh2.length; i++) {
    for (let j = 0; j < 2; j++) {
      let intRandomIndexFrom = Math.floor(Math.random() * (lstRandomNumberPh2.length - 1 + 1) + 1) - 1;
      let intRandomIndexTo = Math.floor(Math.random() * (lstRandomNumberPh2.length - 1 + 1) + 1) - 1;
      let strCurrentDigit = lstRandomNumberPh2[intRandomIndexFrom];
      lstRandomNumberPh2[intRandomIndexFrom] = lstRandomNumberPh2[intRandomIndexTo];
      lstRandomNumberPh2[intRandomIndexTo] = strCurrentDigit;
    }
  }
  //#endregion

  //#region minimizing zeros
  let strRandomNumberPh3 = lstRandomNumberPh2.join("");

  let strRandomNumberPh4 = "";
  let intCounter = 0;
  let intGroupBy = 13;
  for (let i = 0; i < strRandomNumberPh3.length; i++) {
    if (i * intGroupBy >= strRandomNumberPh3.length - 1) {
      let intNumberTmp = Number(strRandomNumberPh3.substring(intCounter * intGroupBy, strRandomNumberPh3.length));
      strRandomNumberPh4 += String(intNumberTmp);
      break;
    } else {
      let intNumberTmp = Number(strRandomNumberPh3.substring(intCounter * intGroupBy, (intCounter + 1) * intGroupBy));
      strRandomNumberPh4 += String(intNumberTmp);
    }
    intCounter++;
  }
  //#endregion

  if (String(strRandomNumberPh4).length > intGeneratedIDLength) {
    strRandomNumberPh4 = String(strRandomNumberPh4).slice(0, intGeneratedIDLength);
  }

  if (intGeneratedIDLength > 16) {
    return String(strRandomNumberPh4);
  } else {
    return Number(strRandomNumberPh4);
  }
}

//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region encrypting functions
/**
 * To encrypt text by key (strKeyOfEncrypt)
 * @param {string} strPlaintText
 * @param {string} strKeyOfEncrypt
 * @returns {string}
 */
export function encryptText(strPlaintText, strKeyOfEncrypt) {
  // Encrypt
  let cipherText = String(CryptoJS.AES.encrypt(strPlaintText, strKeyOfEncrypt));
  return cipherText;
}

/**
 * To decrypt text by key (strKeyOfDecrypt)
 * @param {string} strCipherText
 * @param {string} strKeyOfDecrypt
 * @returns {string}
 */
export function decryptText(strCipherText, strKeyOfDecrypt) {
  //Decrypt
  let bytes = CryptoJS.AES.decrypt(strCipherText, strKeyOfDecrypt);
  let plaintText = bytes.toString(CryptoJS.enc.Utf8);

  return plaintText;
}
//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region storage functions

export function recursionResetObject(objAppState) {
  let _objAppState = objAppState;
  if (typeof _objAppState !== "object" || !_objAppState) {
    return _objAppState;
  }
  Object.entries(_objAppState).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      _objAppState[key] = [];
    } else if (typeof value === "string") {
      _objAppState[key] = "";
    } else if (typeof value === "number" || typeof value === "bigint") {
      _objAppState[key] = 0;
    } else if (typeof value === "boolean") {
      _objAppState[key] = null;
    } else if (typeof value === "object" && value instanceof Object && !Array.isArray(value)) {
      recursionResetObject(value);
    } else {
      _objAppState[key] = null;
    }
  });

  return _objAppState;
}

export function setAppLocalStorage(appStateObject, strLocalStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  let plainTextLocalStorage = JSON.stringify(appStateObject);

  if (blnIsEncrypted) {
    let cipherTextLocalStorage = encryptText(plainTextLocalStorage, strEncryptKey);
    localStorage.setItem(strLocalStorageName, cipherTextLocalStorage);
  } else {
    localStorage.setItem(strLocalStorageName, plainTextLocalStorage);
  }
}

export function getAppLocalStorage(strLocalStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  try {
    let txtLocalStorage = localStorage.getItem(strLocalStorageName);

    if (blnIsEncrypted) {
      let plainTextLocalStorage = decryptText(txtLocalStorage, strEncryptKey);
      return checkParseObject(plainTextLocalStorage);
    } else {
      return checkParseObject(txtLocalStorage);
    }
  } catch (e) {
    return {};
  }
}

export function clearLocalStorageObject(appStateObject, strLocalStorageName, blnIsEncrypted = false, strEncryptKey = "secret", setPayload) {
  localStorage.removeItem(strLocalStorageName);
  let _appStateObject = appStateObject;

  let clientInfo = null;
  if (_appStateObject?.clientInfo) {
    clientInfo = checkParseObject(JSON.stringify(_appStateObject.clientInfo));
    clientInfo.blnIsUserLoggedIn = false;
    clientInfo.dtmLastUpdate = getCurrentDate();
    clientInfo.dtmLastRequest = getCurrentDate();
  }

  let plainObjLocalStorage = recursionResetObject(_appStateObject);
  plainObjLocalStorage = { ...plainObjLocalStorage, clientInfo: clientInfo };

  if (setPayload && typeof setPayload === "function") {
    setPayload(plainObjLocalStorage);
  }

  let plainTextLocalStorage = JSON.stringify(plainObjLocalStorage);

  if (blnIsEncrypted) {
    let cipherTextLocalStorage = encryptText(plainTextLocalStorage, strEncryptKey);
    localStorage.setItem(strLocalStorageName, cipherTextLocalStorage);
  } else {
    localStorage.setItem(strLocalStorageName, plainTextLocalStorage);
  }
}

export function setAppSessionStorage(object, strSessionStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  let plainTextSessionStorage = JSON.stringify(object);

  if (blnIsEncrypted) {
    let cipherTextSessionStorage = encryptText(plainTextSessionStorage, strEncryptKey);
    sessionStorage.setItem(strSessionStorageName, cipherTextSessionStorage);
  } else {
    sessionStorage.setItem(strSessionStorageName, plainTextSessionStorage);
  }
}

export function getAppSessionStorage(strSessionStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  try {
    let txtSessionStorage = sessionStorage.getItem(strSessionStorageName);

    if (blnIsEncrypted) {
      let plainTextSessionStorage = decryptText(txtSessionStorage, strEncryptKey);
      return checkParseObject(plainTextSessionStorage);
    } else {
      return checkParseObject(txtSessionStorage);
    }
  } catch (e) {
    return {};
  }
}

export function clearSessionStorageObject(object, SessionStorageName, SessionStorageIsEncrypted = false, SessionStorageKey = "secret") {
  sessionStorage.removeItem(SessionStorageName);

  let _object = object;

  let clientInfo = null;
  if (_object?.clientInfo) {
    clientInfo = checkParseObject(JSON.stringify(_object.clientInfo));
    clientInfo.blnIsUserLoggedIn = false;
    clientInfo.dtmLastUpdate = getCurrentDate();
    clientInfo.dtmLastRequest = getCurrentDate();
  }

  let plainTextSessionStorage = recursionResetObject(_object);

  plainTextSessionStorage = { ...plainTextSessionStorage, clientInfo: clientInfo };
  plainTextSessionStorage = JSON.stringify(plainTextSessionStorage);

  if (SessionStorageIsEncrypted) {
    let cipherTextSessionStorage = String(CryptoJS.AES.encrypt(plainTextSessionStorage, SessionStorageKey));
    sessionStorage.setItem(SessionStorageName, cipherTextSessionStorage);
  } else {
    sessionStorage.setItem(SessionStorageName, plainTextSessionStorage);
  }
}
//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region Html handler functions

export const scrollToElement = (path) => {
  var offSet = 0;
  var scrollType = "smooth";
  const tesNode = document.querySelector(path);
  if (true) {
    window.scrollTo({
      left: 0,
      top: tesNode.offsetTop + offSet,
      behavior: scrollType,
    });
  }
};

export const fetchAndDownloadFile = async (fileUrl, fileNameToSave) => {
  await fetch(fileUrl)
    .then(async (response) => await response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileNameToSave;
      link.click();
    })
    .catch(console.error);
};

export function copyTextToClipboard(copyText) {
  navigator.clipboard.writeText(copyText);
}

//#endregion

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region resize image
export async function downloadFile(objFile) {
  try {
    const element = document.createElement("a");
    const file = objFile;
    element.href = URL.createObjectURL(file);
    element.download = file.name;
    document.body.appendChild(element);
    element.click();

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 *
 * @param {FileList | any[]} lstFileList
 * @param {*} intMaxWidth
 * @param {*} intMaxHeight
 * @param {number} [fltQuality] quality of image max value 1
 * @param {boolean} [blnForceResize] force to resize image according to intMaxWidth and intMaxHeight
 * @param {"image/apng" | "image/avif" | "image/gif" | "image/jpeg" | "image/png" | "image/webp" | "image/bmp" | "image/x-icon" | "image/tiff"} [strImgType]
 * @param {boolean} [blnShowPreview] To show img preview of the result
 * @param {boolean} [blnDownloadAfterConvert] Download Each image after convert it.
 */
export async function resizeImg(lstFileList, intMaxWidth = 620, intMaxHeight = 620, fltQuality = 0.95, blnForceResize = false, strImgType = null, blnShowPreview = false, blnDownloadAfterConvert = false) {
  try {
    let files = lstFileList;
    let lstResultFile = [];

    for (var i = 0; i < files.length; i++) {
      let strBase64ResizedImg = await processFile(files[i], i, intMaxWidth, intMaxHeight, fltQuality, strImgType, blnForceResize, blnShowPreview); // process each file at once

      if (strBase64ResizedImg) {
        let fileNew = await fetch(strBase64ResizedImg);
        let blob = await fileNew.blob();
        let strFileName = files[i].name;
        let strNewFileType = files[i].type;
        if (strImgType) {
          strFileName = String(files[i].name).split(".")[0] + "." + String(strImgType).split("/")[1];
          strNewFileType = strImgType;
        }

        let newObjFile = new File([blob], strFileName, { type: strNewFileType });
        lstResultFile.push(newObjFile);

        if (blnDownloadAfterConvert) {
          await downloadFile(newObjFile);
        }
      }
    }

    return lstResultFile;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 *
 * @param {File} objFile
 * @param {number} intIndex
 * @param {number} intMaxWidth
 * @param {number} intMaxHeight
 * @param {number} fltQuality
 * @param {string} strImgType
 * @param {boolean} blnForceResize
 * @param {boolean} blnShowPreview
 * @returns {Promise<string | false>}
 */
function processFile(objFile, intIndex, intMaxWidth, intMaxHeight, fltQuality, strImgType, blnForceResize, blnShowPreview) {
  return new Promise((resolve, reject) => {
    try {
      if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
        console.error("The File APIs are not fully supported in this browser.");
        resolve(false);
        return;
      }

      if (!/image/i.test(objFile.type)) {
        console.error("File " + objFile.name + " is not an image.");
        resolve(false);
        return;
      }

      // read the files
      let reader = new FileReader();
      reader.readAsArrayBuffer(objFile);

      reader.onload = (event) => {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;
        let blobURL = window.URL.createObjectURL(blob); // and get it's URL

        // helper Image object
        let objImg = new Image();
        objImg.src = blobURL;

        objImg.onload = () => {
          // have to wait till it's loaded

          if (!strImgType) {
            strImgType = objFile.type;
          }

          let canvas = document.createElement("canvas");

          let width = objImg.width;
          let height = objImg.height;

          // calculate the width and height, constraining the proportions
          if (blnForceResize) {
            width = intMaxWidth;
            height = intMaxHeight;
          } else {
            if (width > height) {
              if (width > intMaxWidth) {
                //height *= intMaxWidth / width;
                height = Math.round((height *= intMaxWidth / width));
                width = intMaxWidth;
              }
            } else {
              if (height > intMaxHeight) {
                //width *= intMaxHeight / height;
                width = Math.round((width *= intMaxHeight / height));
                height = intMaxHeight;
              }
            }
          }

          if (!canvas.getContext) {
            console.error("Canvas are not fully supported in this browser.");
            resolve(false);
            return;
          }

          // resize the canvas and draw the image data into it
          canvas.width = width;
          canvas.height = height;

          let ctx = canvas.getContext("2d");
          ctx.drawImage(objImg, 0, 0, width, height);

          let dataURL = canvas.toDataURL(strImgType, fltQuality); // get the data from canvas as 70% JPG (can be also PNG, etc.)

          if (blnShowPreview && dataURL) {
            let previewImg = document.createElement("img");
            previewImg.id = "previewResizeImg-" + intIndex;
            previewImg.src = dataURL;
            previewImg.alt = "";
            document.body.appendChild(previewImg);
          }

          resolve(dataURL);

          canvas.onerror = (error) => {
            console.error(`Error occurred while canvas initialized.\n${error.toString()}`);
            resolve(false);
          };
        };

        objImg.onerror = () => {
          console.error(`Error occurred when loading image: ${objFile.name}.`);
          resolve(false);
        };
      };

      reader.onerror = (error) => {
        console.error(`Error occurred reading file: ${objFile.name}.\n${error.target.error}`);
        resolve(false);
      };
    } catch (error) {
      console.error(error);
      resolve(false);
    }
  });
}

//#region util func

export function parseArabicNumber(str) {
  let number = str;
  try {
    number = String(
      str
        .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
          return d.charCodeAt(0) - 1632; // Convert Arabic numbers
        })
        .replace(
          /[۰۱۲۳۴۵۶۷۸۹]/g,
          function (d) {
            return d.charCodeAt(0) - 1776; // Convert Persian numbers
          },
          10000000000
        )
    );
    return number;
  } catch {
    return str;
  }
}

export function camelize(str, blnClassName = false) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? (blnClassName ? match.toUpperCase() : match.toLowerCase()) : match.toUpperCase();
  });
}

export function normalizeText(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toLowerCase();
  });
}

export const getKeyByValue = (obj, value) => Object.keys(obj).find((key) => obj[key] === value);

/**
 *
 * @param {"datetime" | "date"} type
 */
export function convertDateToInputForm(date, type) {
  try {
    if (type === "datetime") {
      let dtmInput = new Date(date);
      dtmInput.setMinutes(dtmInput.getMinutes() - dtmInput.getTimezoneOffset());
      let strInputDate = dtmInput.toISOString().slice(0, 16);
      return strInputDate;
    } else {
      let dtmInput = new Date(date);
      let strInputDate = dtmInput.toISOString().slice(0, 10);
      return strInputDate;
    }
  } catch {
    if (type === "datetime") {
      let dtmInput = new Date();
      dtmInput.setMinutes(dtmInput.getMinutes() - dtmInput.getTimezoneOffset());
      let strInputDate = dtmInput.toISOString().slice(0, 16);
      return strInputDate;
    } else {
      let dtmInput = new Date();
      let strInputDate = dtmInput.toISOString().slice(0, 10);
      return strInputDate;
    }
  }
}

export function searchThrowAppCategory(objCategory, id_or_name) {
  try {
    let result = {};
    let lstCat = Object.entries(objCategory);

    for (let i = 0; i < lstCat.length; i++) {
      let [key, value] = lstCat[i];

      if (key === "id" || key === "value") {
        continue;
      }

      if (!Number.isNaN(Number(id_or_name))) {
        if (String(value?.id) === String(id_or_name)) {
          result = value?.value;
          break;
        }
      } else {
        if (String(key) === String(id_or_name)) {
          result = value?.value;
          break;
        }
      }
    }

    return result;
  } catch {
    return {};
  }
}

//#endregion



export const generateRandomStringSecure = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  // Create an array of 32-bit unsigned integers
  const randomValues = new Uint32Array(length);

  // Generate random values
  window.crypto.getRandomValues(randomValues);
  randomValues.forEach((value) => {
    result += characters.charAt(value % charactersLength);
  });
  return result;
};

export function getRandomNumberSecure(length) {
  const characters = "0123456789";
  const charactersLength = characters.length;

  let result = "";

  // Create an array of 32-bit unsigned integers
  const randomValues = new Uint32Array(length);

  // Generate random values
  window.crypto.getRandomValues(randomValues);
  randomValues.forEach((value, index) => {
    let char = characters.charAt(value % charactersLength);
    if (index === 0 && char === "0") {
      char = "1";
    }
    result += char;
  });
  return Number(result);
}



export function fetchFileFromUrlAndDownload (url, strDownloadName) {
  // (A) FETCH FILE
  fetch(url)
 
  // (B) RETURN AS BLOB
  .then(res => {
    if (res.status != 200) { throw new Error("Bad server response"); }
    return res.blob();
  })
 
  // (C) BLOB DATA
  .then(data => {
    // (C1) "FORCE DOWNLOAD"
    var url = window.URL.createObjectURL(data),
        anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = strDownloadName;
    anchor.click();
 
    // (C2) CLEAN UP
    window.URL.revokeObjectURL(url);
    document.removeChild(anchor);
  })
 
  // (D) HANDLE ERRORS - OPTIONAL
  .catch(err => console.error(err));
}