import React, { useEffect, useRef } from "react";
import "./Note.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function Note(props) {
  const { type, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const getID = useRef(id || componentsFunction.randomName("Container"));
  const styleRef = useRef({ strSxClassName: "" });

  const screenWidth = useCheckScreenSize();

  useEffect(() => {}, [type, , hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-container");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getMode = () => {
    switch (String(type).toLowerCase()) {
      case "error":
        return "cbmis-alert-note-danger";
      case "info":
        return "cbmis-alert-note-info";
      case "success":
        return "cbmis-alert-note-success";
      case "primary":
        return "cbmis-alert-note-primary";
      case "warning":
        return "cbmis-alert-note-warning";
      default:
        return "cbmis-alert-note-primary";
    }
  };

  return (
    <React.Suspense fallback="">
      <div
        id={getID.current}
        className={`cbmis-alert ${getMode()} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
      >
        {props.children}
      </div>
    </React.Suspense>
  );
}

export default Note;
