import React, { useEffect, useState, Fragment, useRef } from "react";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import TabsHead from "./TabsHead";
import { setSxClassName } from "../../../helper/convertJsCss";

function Tab(props) {
  const { tabsContent, currentTab, activeColor, mode, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Tabs"));
  const styleRef = useRef({ strSxClassName: "" });

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [tabsContentArr, setTabsContentArr] = useState([]);

  useEffect(() => {}, [tabsContent, currentTab, activeColor, mode, sx, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (currentTab && parseInt(currentTab) > 1) {
      setCurrentTabIndex(parseInt(currentTab) - 1);
    } else {
      setCurrentTabIndex(0);
    }
  }, [currentTab]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-tabs-container");
    }
  }, [sx, screenWidth]);

  useEffect(() => {
    if (tabsContent) {
      setTabsContentArr(tabsContent || []);
    }
  }, [tabsContent]);

  const navigateTotabHandler = (index) => {
    if (index !== currentTabIndex) {
      setCurrentTabIndex(index);
    }
  };
  const getMode = (mode) => {
    switch (String(mode).toLowerCase()) {
      case "nav":
        return "cbmis-tabs-container-mode-nav";
      case "box":
        return "cbmis-tabs-container-mode-box";
      case "pill":
        return "cbmis-tabs-container-mode-pill";
      case "vertical":
        return "cbmis-tabs-container-mode-vertical";
      default:
        return "cbmis-tabs-container-mode-nav";
    }
  };

  // const getPosition=()=>{
  // 	switch (String(mode).toLowerCase() === "vertical") {
  // 		case "end":
  // 		  return("cbmis-vertical-tab-position-end")
  // 		case "top":
  // 		  return("cbmis-vertical-tab-position-top")
  // 		case "bottom":
  // 		  return("cbmis-vertical-tab-position-bottom")
  // 		case "start":
  // 			return("cbmis-vertical-tab-position-start")
  // 	  default:
  // 		  return("cbmis-vertical-tab-Position-start")
  //   }
  //   }

  return (
    <div id={getID.current} className={`cbmis-tabs-container ${getMode(mode)}`}>
      <TabsHead
        tabsContent={tabsContentArr}
        currentTabIndex={currentTabIndex}
        activeColor={activeColor ? componentsFunction.checkColorReturnValue(activeColor) : ""}
        navigateTotabHandler={navigateTotabHandler}
        sx={sx}
        className={className}
        hidden={hidden}
        disabled={disabled}
        dir={dir}
        {...rest}
      />
      <div className="cbmis-tabs-body">
        {tabsContentArr.map((el, i) => (
          <Fragment key={i}>{i === currentTabIndex && el.content}</Fragment>
        ))}
      </div>
    </div>
  );
}

export default Tab;
