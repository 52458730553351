import React, { useEffect, useRef } from "react";
import "./BottomBar.css";
import Icon from "../../../icon/CbmisIcon";
import { useNavigate } from "react-router-dom";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function BottomBar(props) {
  const { content, activeColor, sx, hidden, disabled, dir, className, id, tab, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const styleRef = useRef({ strSxClassName: "", strSXComponentID: id || componentsFunction.randomName("cbmis-bottom-bar") });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const handleClicked = (index, onClick, path, blank) => {
    if (onClick) {
      onClick();
    }
    if (String(path)[0] === "#" || String(path)[0] === ".") {
      if (String(path).length > 1) componentsFunction.scrollToElement(path);
    } else if (path && (String(path).toLowerCase().includes(".com") || String(path).toLowerCase().includes("http"))) {
      window.open(`${path}`, blank ? "_blank" : "_self", "", true);
    } else if (path) {
      navigate(path);
    }
  };

  useEffect(() => {}, [content, sx, hidden, disabled, dir, className, id, tab]);

  const bodyMarginBottom = () => {
    document.body.style.paddingBottom = "75px";
    const drawer = document.querySelector(".cbmis-drawer");
    if (drawer) {
      drawer.style.maxHeight = "93.32%";
    }
  };

  useEffect(() => {
    if (content.length) {
      bodyMarginBottom();
    }
  }, []);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-bottom-bar");
    }
  }, [sx, screenWidth]);

  return content?.length ? (
    <React.Fragment>
      <div
        id={styleRef.current.strSXComponentID}
        className={`cbmis-bottom-bar ${content?.length > 5 ? "cbmis-bottom-bar-scroll" : ""} ${componentsFunction.CheckDirectionItem(
          dir
        )} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
          rest
        )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
      >
        {content.map((item, index) => {
          return (
            <span
              key={index}
              id={index}
              className={`cbmis-bottom-bar-element ${
                item.path === pathName ? "cbmis-bottom-bar-element-selected" : String(tab) === String(index) ? "cbmis-bottom-bar-element-selected" : ""
              }`}
              data-component={item.component || ""}
              data-path={item.path || ""}
              onClick={() => handleClicked(index, item.onClick, item.path || "")}
            >
              <Icon icon={item.icon} size="30px" />
              {item.label ? <label className="cbmis-bottom-bar-element-label">{item.label}</label> : ""}
            </span>
          );
        })}
      </div>
    </React.Fragment>
  ) : null;
}

export default BottomBar;
