import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./RadioButtons.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Icon from "../../../icon/CbmisIcon";

const RadioButtons = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,checkMode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,options,
          optionStyle,display,
          sx,hidden,disabled,dir,className,...rest} = props

    const inputRef = useRef({})
    const [inputState,setInputState] = useState({
      value:"",
      key:"",
      objValue:""
    })

    const screenWidth = useCheckScreenSize();
    const nameID = useRef(id || componentsFunction.randomName("RadioButtons"));
    const getID = useRef(componentsFunction.randomName("RadioButtons"));
    const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-input")});


  //#region Effect
    useEffect(()=>{
        if(value || defaultValue) {
            inputRef.current.value = value?.value || defaultValue?.value || ""
            inputRef.current.key = value?.key || defaultValue?.key || ""
            inputRef.current.objValue={key:inputRef.current.key,value:inputRef.current.value}

            if(ref){
              ref.current.value = value?.value || defaultValue?.value || ""
              ref.current.key = value?.key || defaultValue?.key || ""
              ref.current.objValue={key:ref.current.key,value:ref.current.value}

            }
            setInputState({
              ...inputState,
              value: inputRef.current.value,
              key: inputRef.current.key,
              objValue: inputRef.current.objValue,
            })
        }
    },[value, defaultValue])

    useEffect(()=>{
    },[id,label,placeholder,errorMessage,checkMode,
        color,icon,iconColor,adornment,endIcon,endAdornment,
        value,defaultValue,optionStyle,display,
        sx,hidden,disabled,dir,className,rest,
        inputState,
        ])

//#endregion

  //#region style

  const getBoxMarkCheck = () => {
    const getMarkStyle = String(checkMode).toLowerCase();
    switch (getMarkStyle) {
      case "border":
        return "cbmis-radio-border";
      default:
        return "cbmis-radio-colored";
    }
  };
  const getDisplayRadio = () => {
    const getDisplay = String(display).toLowerCase();
    switch (getDisplay) {
      case "inline":
        return "cbmis-radio-bottons-container-inline-display";
      default:
        return "cbmis-radio-bottons-container-block-display";
    }
  };

    useEffect(() => {
      if (sx) {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-input");
      }
    }, [sx, screenWidth]);

    useEffect(() => {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, { "&focus":{color: `${componentsFunction.checkColorReturnValue(color || "primary")}`} }, "cbmis-input-focus-color");
    }, [screenWidth, color]);

    const getRestProps = () => {
      const newRest = componentsFunction.filterPaddingAndMargin(rest);
      return { ...newRest };
    };
  //#endregion

  //#region change
    const handelChange = (event) => {
      const selectedValue = event.target.value;
      var selectedKey = event.target.value;
      if (event.target.id !== "") {
        selectedKey = event.target.id;
      }
      inputRef.current.value = selectedValue
      inputRef.current.key = selectedKey
      inputRef.current.objValue = {key: selectedKey, value: selectedValue};
      if(ref){
        ref.current.value = selectedValue
        ref.current.key = selectedKey
        ref.current.objValue = {key: selectedKey, value: selectedValue};
      }
      if (onChange) {
        onChange(event);
      }
    };
  //#endregion

  return (
      <React.Suspense fallback="">
        <div id={getID.current}
              className={`cbmis-main-container
                          ${icon || adornment?"cbmis-input-haveIcon":""} 
                          ${componentsFunction.CheckHiddenItem(hidden) || ""}`}

              {...getRestProps()}
        >
          <div className={componentsFunction.CheckDirectionItem(dir)}>
            <div className={`cbmis-input-container 
                            ${componentsFunction.CheckDisabledItem(disabled)} 
                            ${errorMessage?"cbmis-input-have-error":""}
                          `}
                           >
              {icon?
                <div className="cbmis-radio-checkbox-icon-container" >
                <Icon icon= {icon} color={errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}/>
                </div>
                :adornment?
                <div className="cbmis-radio-checkbox-adornment-container" style={{color:errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}} >
                    <label>{adornment}</label>
                </div>
                :""
                }
                <div className={icon ? "cbmis-checkbox-haveIcon" : ""}>
                  {label?.default || label?
                    <label className="cbmis-input-label" htmlFor={`${nameID.current}`}>{label?.default || label}</label>
                  :""}
                  {options
                    ? options.map((option, index) => {
                        return (
                          <label key={option.key} htmlFor={option.value} className={`cbmis-radio-bottons-container ${getDisplayRadio()}`} style={optionStyle || {}}>
                            <div style={{ color: color ? componentsFunction.checkColorReturnValue(color) : "var(--cbmis-primary)" }}>
                              <input
                                type="radio"
                                id={option.key}
                                key={option.key + index + inputRef?.current?.value || ""}
                                name={nameID.current}
                                className="cbmis-input-botton-radio"
                                ref={ref || inputRef}
                                defaultValue={option?.value || ""}
                                defaultChecked={(String(value?.value) === String(option.value) && String(value?.key) === String(option.key)) || (String(defaultValue?.value) === String(option.value) && String(defaultValue?.key) === String(option.key)) || (String(inputRef.current?.value) === String(option.value) && String(inputRef.current?.key) === String(option.key))}
                                onChange={handelChange}
                                {...getRestProps()}
                              />
                              <span className={`cbmis-box-mark-radio ${getBoxMarkCheck()}`}></span>
                            </div>
                            <span className={`cbmis-option-input-label`}>{option.jxs && option.jxs()? option.jxs() : option.label || option.value}</span>
                          </label>
                        );
                      })
                    : ""}
                </div>
            </div>

            

            <ErrorMessage errorMessage={errorMessage} />
          </div>
        </div>
      </React.Suspense>
    )
});

export default RadioButtons