import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Menu.css";
import "../../../style/main.css";
import CbmisIcon from "../../../icon/CbmisIcon";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const MenuItem = (props) => {
  const { headItem, onClick, footerItem, outlined, selected, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const ID = useRef(id || componentsFunction.randomName("MenuItem"));
  const styleRef = useRef({ strSxClassName: "" });

  const [isDropMenu, setisDropMenu] = useState(false);

  useEffect(() => {}, [headItem, onClick, footerItem, outlined, selected, sx, hidden, disabled, dir, className, id]);

  useEffect(() => {
    const menuItem = document.querySelector(`#${ID.current} >.cbmis-menu-item-head-content .cbmis-menu-item-content .cbmis-menu-contener`) || "";
    if (menuItem) {
      setisDropMenu(true);
      menuItem.style.display = "none";
    }
    // menuItem.style.display ="block"
  }, []);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, ID.current, sx, "cbmis-menu-item");
    }
  }, [sx, screenWidth]);

  const handleClick = (e) => {
    const elementChildren = e.target.childNodes;
    const childrenIndex = Object.values(elementChildren).findIndex((child) => {
      return child.className === "cbmis-menu-contener";
    });
    if (childrenIndex !== -1) {
      var dropdownContent = elementChildren[childrenIndex];
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    }

    if (onClick) {
      onClick();
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <React.Fragment>
      <li
        className={`cbmis-menu-item ${outlined ? "cbmis-menu-item-outlined" : ""}  ${disabled ? "cbmis-disabled" : ""} ${
          selected ? "cbmis-menu-item-selected" : ""
        } 
                ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        onClick={handleClick}
        id={ID.current}
        {...getRestProps()}
      >
        <div className={`cbmis-menu-item-head-content`}>
          {headItem ? <div className="cbmis-menu-item-head">{headItem}</div> : ""}
          <div className={`cbmis-menu-item-content`}>{props.children}</div>
          <div className="cbmis-menu-item-footer">
            {footerItem}
            {isDropMenu ? <CbmisIcon icon="add" /> : ""}
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

export default MenuItem;
