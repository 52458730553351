import { ClassesBuilder } from "@cbmisorg/styles";
import React, { useEffect, useState } from "react";
import { useApp } from "../../../configuration/contextapi/context";
import { objStyle } from "./Media.style";
import { objStyle as registrationStyle } from "../registration/Registration.style";

import { useNavigate, useParams } from "@cbmisorg/router";
import { Button, Container, Grid, Loader, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import { App_info_Color, App_Light_Text_Color } from "../../../util/appStyle";
import ShredHeader from "../../shared/shredHeader";
import NoResultImg from "../../../../old/components/sharedUi/noResultImg/NoResultImg";
import { App_Server_Url_DownloadFiles } from "../../../configuration/appVariables";
import { CtrlMedia } from "./Media.controller";
import robot from "../../../util/assets/images/robot6.png";
import robot7 from "../../../util/assets/images/robot7.png";
import robot9 from "../../../util/assets/images/robot9.png";
import lineImage from "../../../util/assets/images/Mediamodifier-Design.svg";
import { Icon } from "@cbmisorg/icons";
import RegNewForumView from "../regNewForum/RegNewForum.view";
import TraineeSetterModalView from "../../stakeholders/member/account/components/traineeSetterModal/traineeSetterModal.view";
import { useLocation } from "@cbmisorg/client-app";
import MDEASetterModalView from "../../stakeholders/member/account/components/MDEASetterModal/MDEASetterModal.view";
import VolunteerSetterModalView from "../../stakeholders/member/account/components/volunteerSetterModal/volunteerSetterModal.view";
import ResearcherSetterModalView from "../../stakeholders/member/account/components/researcherSetterModal/researcherSetterModal.view";
// import video from "../../../util/assets/images/video.jpg";
// import video from "../../../util/assets/images/1.jpg";

function MediaView() {
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();

  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    objInfo: null,
    openModalVideo: false,
    openRegModal: false,
    blnHoverd1: false,
    blnHoverd2: false,
    lstCertificateFile: [],
  });
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder({ ...registrationStyle, ...objStyle }, { lang });
  const { strUrlVedio } = useParams();
  const location = useLocation();

  const objForumInfo = location?.state?.objForumInfo;

  const handlers = CtrlMedia(appState, appDispatch, navigate);
  const getMedia = handlers.getMedia({ isLoading, setIsLoading, stateInfo, setStateInfo, strUrlVedio, objForumInfo });
  const openVedioModal = handlers.openVedioModal({ stateInfo, setStateInfo });
  // const goPath = handlers.goPath;

  const JsxRegNewForumView = RegNewForumView({ isLoading, setIsLoading });
  const jsxTraineeSetter = TraineeSetterModalView({ isLoading, setIsLoading, classes, parentState: stateInfo, blnPublic: true });
  const jsxVolunteerSetter = VolunteerSetterModalView({ isLoading, setIsLoading, classes, parentState: stateInfo, blnPublic: true });
  const jsxMDEASetter = MDEASetterModalView({ isLoading, setIsLoading, classes, parentState: stateInfo, blnPublic: true });
  const jsxResearcherSetter = ResearcherSetterModalView({ isLoading, setIsLoading, classes, parentState: stateInfo, blnPublic: true });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      getMedia();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ShredHeader
        lang={lang}
        title={stateInfo?.objInfo?.jsnInfo?.title?.[lang] || stateInfo?.objInfo?.jsnInfo?.title?.["arb"] || stateInfo?.objInfo?.jsnInfo?.title?.["eng"] || ""}
        blnBack={false}
        blnHome={false}
      />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        {!stateInfo.blnIsInitDone ? null : (
          <Container className={classes?.mainContainer}>
            <Grid container justifyContent={"center"} alignSelf="center" alignContent="flex-start" alignItems="flex-start">
              {!stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.blnIsViw ? null : (
                <Grid item md="12" sm="8">
                  <Paper className={classes?.bgBox2} px-0>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={"12"} md="10" lg="8" justifyContent="center">
                        <Typography as="subtitle2" color={App_Light_Text_Color}>
                          {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.subtitle?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs={"12"} md="10" lg="8" justifyContent="center">
                        <Typography as="subtitle1" color={App_info_Color} sx={{ textAlign: "center !important" }}>
                          {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.title?.[lang]}
                        </Typography>
                      </Grid>
                      {stateInfo?.objInfo?.jsnInfo?.lstVideos?.length > 1 ? (
                        <Grid item xs="12" md="10" lg="8">
                          <video controls className="videoTraining" onContextMenu={(e) => e.preventDefault()} controlsList="nodownload noremoteplayback" poster={stateInfo?.objInfo?.jsnInfo?.lstVideosPoster?.[0]?.path? App_Server_Url_DownloadFiles + stateInfo?.objInfo?.jsnInfo?.lstVideosPoster?.[0]?.path:  ""} >
                            <source src={stateInfo?.objInfo?.jsnInfo?.lstVideos?.[0]?.path} type="video/mp4" />
                            <source src={App_Server_Url_DownloadFiles + stateInfo?.objInfo?.jsnInfo?.lstVideos?.[0]?.path} type="video/mp4" />
                          </video>
                        </Grid>
                      ) : null}
                      <Grid item xs={"12"} md="10" lg="8" className={`${classes?.descriptionLightText} ${stateInfo?.blnHoverd1 ? classes?.descriptionLightTextActive : ""}`}>
                        <Grid item xs={"12"} justifyContent="center">
                          {!stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.strDscrp?.[lang] ? null : (
                            <Typography as="body2" color={App_Light_Text_Color}>
                              {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.strDscrp?.[lang]}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={"12"} justifyContent="center">
                          {!stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.strDscrp2?.[lang] ? null : (
                            <Typography as="body2" color={App_Light_Text_Color}>
                              {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.strDscrp2?.[lang]}
                            </Typography>
                          )}
                        </Grid>

                        <Grid container spacing={2} justifyContent="center" pt-1>
                          {(stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.lstPoints || [])?.map((dscrption, inddscrption) => {
                            return (
                              <Grid item xs="12" justifyContent="center" key={inddscrption}>
                                <Grid item xs={"12"} container py-2 spacing={1}>
                                  <Typography as="body2" color={App_info_Color} sx={{ display: "flex", alignContent: "baseline" }}>
                                    <Icon icon="circle" size="14px" color={App_info_Color} mt-1 mx-1 />
                                    {dscrption?.title?.[lang]}
                                  </Typography>
                                </Grid>
                                <Grid item xs={"12"} container justifyContent="center">
                                  {(dscrption?.lstPoint || [])?.map((point, indpoint) => {
                                    return (
                                      <Grid item xs="12" key={indpoint}>
                                        <Typography as="body3" color={App_Light_Text_Color} sx={{ display: "flex" }}>
                                          <label style={{ color: App_info_Color }}>{String(indpoint + 1) + ". "}</label>
                                          {point?.[lang]}
                                        </Typography>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            );
                          })}
                          <Grid item xs={"12"} md="10" lg="8" justifyContent="center" py-2>
                            <Typography as="body3" color={App_Light_Text_Color}>
                              {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.caption?.[lang]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={"12"} justifyContent="center">
                        <Icon
                          icon={stateInfo?.blnHoverd1 ? "arrowupward" : "arrowdownward"}
                          color={App_info_Color}
                          sx={{ "&hover": { background: "transparent" } }}
                          onClick={() => {
                            setStateInfo({ ...stateInfo, blnHoverd1: !stateInfo?.blnHoverd1 });
                          }}
                        />
                      </Grid>

                      {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.mediaBtn?.blnIsViw ? (
                        <Grid item pb-8>
                          <Button
                            label={stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.mediaBtn?.label?.[lang]}
                            mode="outlined"
                            color={App_info_Color}
                            className={classes?.btnBox}
                            onClick={openVedioModal}
                          />
                        </Grid>
                      ) : null}
                      {stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.forumBtn?.blnIsViw ? (
                        <Grid item pb-5>
                          <JsxRegNewForumView.OpenEle
                            forumCode={stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.forumBtn?.strForumKey}
                            buttonLabel={stateInfo?.objInfo?.jsnInfo?.objCardBeforMedia?.forumBtn?.label?.[lang]}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
            <div className="arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Grid container justifyContent={"center"} alignSelf="center" alignContent="flex-start" alignItems="flex-start">
              {!stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.blnIsViw ? null : (
                <Grid item xs="12">
                  <Paper className={classes?.bgBox2}>
                    <Grid container justifyContent="center" spacing={2} py-3>
                      <Grid item xs={"12"} md="10" lg="8" justifyContent="center">
                        <Typography as="subtitle2" color={App_Light_Text_Color}>
                          {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.subtitle?.[lang]}
                        </Typography>
                      </Grid>

                      <Grid item xs="0" md="10" lg="8" justifyContent="center">
                        <Typography as="subtitle1" color={App_info_Color} className="textAligncenter">
                          {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.title?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs="auto" md="0" justifyContent="center">
                        {String(stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.title?.[lang])
                          ?.split("–")
                          ?.map((title, index) => (
                            <Typography key={index} as="subtitle1" color={App_info_Color} className="textAligncenter">
                              {title}
                            </Typography>
                          ))}
                      </Grid>
                      <Grid item xs={"12"} md="10" lg="8" justifyContent="center" pt-1>
                        <Typography as="body3" color={App_Light_Text_Color}>
                          {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.caption?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs={"12"} md="10" lg="8" justifyContent="center">
                        <Typography as="body2" color={App_Light_Text_Color}>
                          {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.strDscrp?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs="12" justifyContent="center">
                        <img src={robot} alt="robotImg" className={classes?.robotImg} />
                      </Grid>
                      {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.mediaBtn?.blnIsViw ? (
                        <Grid item pb-5>
                          <Button
                            label={stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.mediaBtn?.label?.[lang]}
                            mode="outlined"
                            color={App_info_Color}
                            className={classes?.btnBox}
                            onClick={openVedioModal}
                          />
                        </Grid>
                      ) : null}
                      {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.forumBtn?.blnIsViw ? (
                        <Grid item pb-5>
                          <JsxRegNewForumView.OpenEle
                            forumCode={stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.forumBtn?.strForumKey}
                            buttonLabel={stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.forumBtn?.label?.[lang]}
                          />
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs="12"
                        className={`${classes?.descriptionLightText2} ${stateInfo?.blnHoverd2 ? classes?.descriptionLightTextActive : ""}`}
                        // onMouseEnter={() => {
                        //   setStateInfo({ ...stateInfo, blnHoverd2: true });
                        // }}
                        // onMouseLeave={() => {
                        //   setStateInfo({ ...stateInfo, blnHoverd2: false });
                        // }}
                        container
                        justifyContent="center"
                      >
                        <Grid item xs={"12"} justifyContent="center">
                          <Typography as="body2" color={App_Light_Text_Color}>
                            {stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.strDscrp2?.[lang]}
                          </Typography>
                        </Grid>
                        {(stateInfo?.objInfo?.jsnInfo?.objCardAfterMedia?.lstPoints || [])?.map((dscrption, inddscrption) => {
                          return (
                            <Grid item xs="12" justifyContent="center" key={inddscrption}>
                              <Grid item xs={"12"} md="10" lg="8" container py-2 spacing={1}>
                                <Typography as="body2" color={App_info_Color} sx={{ display: "flex", alignContent: "baseline" }}>
                                  <Icon icon="circle" size="14px" color={App_info_Color} mt-1 mx-1 />
                                  {dscrption?.title?.[lang]}
                                </Typography>
                              </Grid>
                              <Grid item xs="12" md="10" lg="8" container justifyContent="center">
                                {(dscrption?.lstPoint || [])?.map((point, indpoint) => {
                                  return (
                                    <Grid item xs="12" key={indpoint}>
                                      <Typography as="body3" color={App_Light_Text_Color} sx={{ display: "flex" }}>
                                        <label style={{ color: App_info_Color }}>{String(indpoint + 1) + ". "}</label>
                                        {point?.[lang]}
                                      </Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={"12"} justifyContent="center">
                        <Icon
                          icon={stateInfo?.blnHoverd2 ? "arrowupward" : "arrowdownward"}
                          color={App_info_Color}
                          sx={{ "&hover": { background: "transparent" } }}
                          onClick={() => {
                            setStateInfo({ ...stateInfo, blnHoverd2: !stateInfo?.blnHoverd2 });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>

            <img src={lineImage} alt="" className={classes?.imgLine} />

            {!stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.includes("blnMDEA") ? null : (
              <Grid container justifyContent="center" alignContent="center" py-5 spacing={1}>
                {stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.indexOf("blnMDEA") % 2 === 0 ? (
                  <React.Fragment>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot7} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxMDEASetter.openEle}
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxMDEASetter.openEle}
                    </Grid>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot9} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
            {!stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.includes("blnResearcher") ? null : (
              <Grid container justifyContent="center" alignContent="center" py-5 spacing={1}>
                {stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.indexOf("blnResearcher") % 2 === 0 ? (
                  <React.Fragment>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot7} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxResearcherSetter.openEle}
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxResearcherSetter.openEle}
                    </Grid>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot9} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
            {!stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.includes("blnTrainee") ? null : (
              <Grid container justifyContent="center" alignContent="center" py-5 spacing={1}>
                {stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.indexOf("blnTrainee") % 2 === 0 ? (
                  <React.Fragment>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot7} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxTraineeSetter.openEle}
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxTraineeSetter.openEle}
                    </Grid>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot9} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
            {!stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.includes("blnVolunteer") ? null : (
              <Grid container justifyContent="center" alignContent="center" py-5 spacing={1}>
                {stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.indexOf("blnVolunteer") % 2 === 0 ? (
                  <React.Fragment>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot7} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxVolunteerSetter.openEle}
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs="12" md="8" lg="9">
                      {jsxVolunteerSetter.openEle}
                    </Grid>
                    <Grid item xs="0" md="4" lg="3">
                      <img src={robot9} alt="robotImg" width={"75%"} style={{ height: "fit-content", margin: "auto" }} />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
            {!stateInfo?.objInfo?.jsnInfo?.lstWhoRegister?.includes("blnSponser") ? null : ""}
          </Container>
        )}
      </section>

      <Modal
        open={stateInfo?.openModalVideo}
        //sx={{  alignContent: "center" }}
        eventClose={openVedioModal}
        className={classes?.modalVideo}
        size="xl"
      >
        <ModalHeader className={classes?.modalHeader}></ModalHeader>
        <ModalBody>
          <Grid container justifyContent="center">
            {stateInfo?.objInfo?.jsnInfo?.lstVideos?.length ? (
              stateInfo?.objInfo?.jsnInfo?.lstVideos?.map((video, index) => {
                if (stateInfo?.objInfo?.jsnInfo?.lstVideos?.length > 1 && !index) return null;
                return (
                  <Grid item xs="12" key={index}>
                    <video controls className="videoTraining" onContextMenu={(e) => e.preventDefault()} controlsList="nodownload noremoteplayback" poster={stateInfo?.objInfo?.jsnInfo?.lstVideosPoster?.[index]?.path? App_Server_Url_DownloadFiles + stateInfo?.objInfo?.jsnInfo?.lstVideosPoster?.[index]?.path:  ""}>
                      <source src={video?.path} type="video/mp4" />
                      <source src={App_Server_Url_DownloadFiles + video?.path} type="video/mp4" />
                    </video>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs="12">
                <NoResultImg />
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>

      {JsxRegNewForumView?.modal}
      {jsxTraineeSetter.modal}
      {jsxVolunteerSetter.modal}
      {jsxMDEASetter.modal}
      {jsxResearcherSetter.modal}
    </React.Fragment>
  );
}

export default MediaView;
