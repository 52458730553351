import React, { useEffect, useMemo, useState } from 'react'
import inputHelper from './formHelper/inputHelper'
import FormControl from './FormControl'
import { fieldType } from './formHelper/formVariables/FormVariables'

function FormField(props) {
  const {fieldName,controller,onBlur,onChange,...rest} = props
  const { formState, getValueByFieldName}= controller

  const {
    getLabel,
    getPlaceholder,
    getObjLanguage,
    getOptions,
    getHidden,
    getDisabled,

    handelChange,
    handelBlur
  }= inputHelper(controller,fieldName)

  const [inputState,setInputState] = useState({
    inputCurrentLang:formState?.appLanguage || "",
  })

  const setInputCurrentLang =(inputCurrentLang)=>{
    inputState.inputCurrentLang = inputCurrentLang
    setInputState({...inputState})
  }


  const onFieldChange=(event)=>{
     handelChange(event,inputState.inputCurrentLang);
     if(onChange) onChange(event)
  }

  const onFieldBlur=(event)=>{
    handelBlur(event)
    if(onBlur){
      onBlur(event)
    }
  }

  const setValue= (value)=>{
    if(typeof value === "object"){
      controller.setValue(fieldName,JSON?.stringify(value))
    }
    controller.setValue(fieldName,value)
  }

  

  const FormCrl = useMemo(
    () => (
      formState?.fields?.[fieldName]?
      <React.Fragment>
          <FormControl
              control={formState?.fields?.[fieldName]?.control || fieldType.text}
              id={fieldName}
              name={fieldName}

              onChange={onFieldChange}
              onBlur={onFieldBlur}

              defaultValue={getValueByFieldName(fieldName)}
              // value={getValueByFieldName(fieldName)}

              label={getLabel(inputState?.inputCurrentLang)}
              placeholder={getPlaceholder(inputState?.inputCurrentLang)}
              currentLang={inputState?.inputCurrentLang}
              setCurrentLang={setInputCurrentLang}
              objLang = {getObjLanguage(inputState?.inputCurrentLang)}
              options={getOptions(inputState?.inputCurrentLang)}
              errorMessage={formState?.fields?.[fieldName]?.error?.[inputState?.inputCurrentLang] || formState?.fields?.[fieldName]?.error}
  
              hidden={getHidden()}
              disabled={getDisabled()}

              setValue ={setValue}  
              {...rest}
          />
        </React.Fragment>
        :""
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [inputState,formState?.appLanguage,formState?.labels?.[fieldName], formState?.fields?.[fieldName], rest])

  return FormCrl
}

export default FormField