import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionTab,
  Avatar,
  Badge,
  Button,
  Container,
  Grid,
  Line,
  Modal,
  ModalBody,
  ModalHeader,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@cbmisorg/material";
import ShredHeader from "../../../shared/shredHeader";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { useApp } from "../../../../configuration/contextapi/context";
// import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "../mngUsers/mngUser.style";
import { FormData, FormField } from "../../../../util/formApi/FormAPI";
import { objQuestionType, objUserRole } from "../../../../configuration/appVariables";
import { Icon } from "@cbmisorg/icons";
import { CtrlMngSurvey } from "./mngSurvey.ctrl";
import { useNavigate } from "@cbmisorg/router";
import { generatePath } from "react-router-dom";
import appRouting from "../../../../configuration/routing/appRouting";
import { getDateUSFormat } from "../../../../util/appFunctions";

const label = dictionary?.components?.stakeholders?.admin?.mngSurvey;
function MngSurveyView() {
  // useCheckHooksGlb();
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const lang = appState.clientInfo.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    lstSurvey: [],
    blnModalOpen: false,
    blnModalAnswersOpen: false,
    intSurveySelected: -1,
  });

  const fieldSearch = FormData({
    strSrearh: { control: "text", blnLanguage: false },
  });

  const handlers = CtrlMngSurvey(appState, appDispatch);
  const initData = handlers.initData({ isLoading, setIsLoading, stateInfo, setStateInfo });
  const openModal = handlers.openModal({ stateInfo, setStateInfo });
  const handelDeleteConfirmation = handlers.handelDeleteConfirmation({ isLoading, setIsLoading, stateInfo, setStateInfo });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      initData();
    }
  }, []);

  const lstAnswersConfirmed = stateInfo?.lstSurvey?.[stateInfo?.intSurveySelected]?.lstAnswers?.filter((ans) => ans?.blnIsConfirmed);

  return (
    <React.Fragment>
      <ShredHeader title={label?.title?.[lang]} blnIsLight={true} blnIsAllLight={true} lang={lang} />
      <Container py-10 my-5>
        <Grid container justifyContent="center">
          <Grid item xs="12">
            <Paper py-0>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button icon="add" size="sm" p-1 onClick={() => navigate(generatePath(appRouting?.MngSurveySave?.url, { type: "add" }), { state: { surveyInfo: null } })} />
                </Grid>
                <Grid item xs="6" sm="auto" md="3" lg="2">
                  <FormField
                    objHandler={fieldSearch?.strSrearh}
                    className={classes?.fieldSearch}
                    endIcon="search"
                    iconColor={fieldSearch?.strSrearh?.getValue() ? "primary" : "#999"}
                  />
                </Grid>
              </Grid>
              <Line sx={{ width: "100%" }} />
              <Grid container justifyContent="center">
                {stateInfo?.lstSurvey?.map((survey, ind) => {
                  const notConfirmed = survey?.lstAnswers?.filter((answ) => !answ?.blnIsConfirmed)?.length;
                  const confirmed = survey?.lstAnswers?.filter((answ) => answ?.blnIsConfirmed)?.length;

                  if (fieldSearch?.strSrearh?.getValue() && !JSON?.stringify(survey?.jsnSurveyInfo?.strTitle)?.includes(fieldSearch?.strSrearh?.getValue())) {
                    return null;
                  }
                  return (
                    <Grid item xs="12" key={survey?.bigSurveyID}>
                      <Paper elevation="0" outline="1" sx={{ overflowY: "hidden" }}>
                        <Grid container>
                          <Grid item>
                            <Badge mx-2  content={survey?.lstSurveyQus?.length} color={"primary"} title={{ eng: "Number of questions", arb: "عدد الأسئلة" }?.[lang]}>
                              <Icon
                                icon="help"
                                pt-2
                                mx-2
                                title={{ eng: "Number of questions", arb: "عدد الأسئلة" }?.[lang]}
                              />
                            </Badge>
                          </Grid>
                          <Grid item xs="10" px-0>
                            <Typography as="subtitle2">{survey?.jsnSurveyInfo?.strTitle?.[lang]}</Typography>
                          </Grid>
                          <Grid item xs="12" justifyContent="space-between" pt-5 pb-2>
                            {String(appState?.userInfo?.jsnUserInfo?.intUserRoleFK) !== String(objUserRole?.RootAdmin) ? null : (
                              <Badge mx-2 content={notConfirmed} color={"error"} title={label?.pendingApproval?.[lang]}>
                                <Icon icon="people" pt-2 mx-2 onClick={openModal("blnModalOpen", ind, notConfirmed)} title={label?.pendingApproval?.[lang]} />
                              </Badge>
                            )}

                            <Badge mx-2 title={label?.answersView?.[lang]} content={survey?.lstAnswers?.filter((answ) => answ?.blnIsConfirmed)?.length} color={"success"}>
                              <Icon icon="people" pt-2 mx-2 onClick={openModal("blnModalAnswersOpen", ind, confirmed)} title={label?.answersView?.[lang]} />
                            </Badge>
                            <Icon
                              icon="visibility"
                              pt-2
                              color="info"
                              mx-2
                              title={dictionary?.shared?.buttons?.view?.[lang]}
                              onClick={() => navigate(generatePath(appRouting?.surveymonkey?.url, { surveyCode: survey?.strSurveyPath }))}
                            />

                            <Icon
                              icon="edit"
                              pt-2
                              color="primry"
                              mx-2
                              title={dictionary?.shared?.buttons?.editBtn?.[lang]}
                              onClick={() => navigate(generatePath(appRouting?.MngSurveySave?.url, { type: "edit" }), { state: { surveyInfo: survey } })}
                            />
                            <Icon
                              icon="delete"
                              pt-2
                              color="error"
                              mx-2
                              onClick={handelDeleteConfirmation(survey?.bigSurveyID)}
                              title={dictionary?.shared?.buttons?.delete?.[lang]}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo?.blnModalOpen} eventClose={openModal("blnModalOpen", -1, true)} size="xl">
        <ModalHeader>{label?.pendingApproval?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container>
            {stateInfo?.lstSurvey?.[stateInfo?.intSurveySelected]?.lstAnswers
              ?.filter((answ) => !answ?.blnIsConfirmed)
              ?.map((answ, ind) => {
                return (
                  <Grid item xs="12" key={ind}>
                    <Paper outline="1" elevation="0">
                      <Grid container justifyContent="space-between">
                        <Grid item xs="9" container>
                          <Grid item xs="12">
                            <Typography as="subtitle2">
                              {
                                {
                                  eng: "Response Form No:",
                                  arb: "نموذج الاجابة رقم: ",
                                }?.[lang]
                              }
                              {answ?.id}
                            </Typography>
                          </Grid>
                          <Grid item xs="12">
                            <Typography as="body2">
                              {
                                {
                                  eng: "Dated ",
                                  arb: "بتاريخ ",
                                }?.[lang]
                              }
                              {getDateUSFormat(answ?.dtmCreatedDate)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Icon
                            icon="visibility"
                            color="primary"
                            onClick={() =>
                              navigate(
                                generatePath(appRouting?.surveymonkeyWithAnswerID?.url, {
                                  surveyCode: stateInfo?.lstSurvey?.[stateInfo?.intSurveySelected]?.strSurveyPath,
                                  bigAnswerID: answ?.bigAnswerID,
                                }),
                                {
                                  state: {
                                    objAnswer: answ,
                                  },
                                }
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo?.blnModalAnswersOpen} eventClose={openModal("blnModalAnswersOpen", -1, true)} size="xl">
        <ModalHeader>{label?.answersView?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container>
            {stateInfo?.lstSurvey?.[stateInfo?.intSurveySelected]?.lstSurveyQus?.map((qs) => {
              return (
                <Grid item xs="12" key={qs?.bigQID}>
                  <Paper outline="1" elevation="0">
                    <Grid container>
                      <Grid item xs="12">
                        <Typography as="subtitle3">{qs?.strQ?.[lang]}</Typography>
                      </Grid>
                      <Grid item xs="12">
                        {qs?.intQType === objQuestionType?.writing ? (
                          <Accordion>
                            <AccordionTab p-0>
                              <AccordionSummary p-0 sx={{ label: { maxWidth: "100% !important" } }}>
                                <Grid container p-0 m-0>
                                  <Grid item>
                                    <Avatar sx={{ width: "26px", height: "25px" }} color="primary" m-0>
                                      <Typography as="subtitle3" color="#fff">
                                        {lstAnswersConfirmed?.filter(res=> res?.lstSurveyQusAns?.[qs?.bigQID])?.length}
                                      </Typography>
                                    </Avatar>
                                  </Grid>
                                  <Grid item>
                                    <Typography as="subtitle3" m-auto>
                                      {label?.responses?.[lang]}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                {lstAnswersConfirmed?.map((res) => {
                                  if(!res?.lstSurveyQusAns?.[qs?.bigQID]) return null;
                                  return (
                                    <React.Fragment>
                                      <Typography as="body3" p-2>
                                        {res?.lstSurveyQusAns?.[qs?.bigQID]}
                                      </Typography>
                                      <Line sx={{ width: "100%" }} />
                                    </React.Fragment>
                                  );
                                })}
                              </AccordionDetails>
                            </AccordionTab>
                          </Accordion>
                        ) : (
                          <Table sx={{ overflow: "hidden" }}>
                            <TableHead>
                              <TableRow>
                                {qs?.intQType === objQuestionType?.multipleChoice ? (
                                  qs?.lstOpt?.map((opt) => (
                                    <TableCell sx={{ background: "#fff" }}>
                                      <Grid container p-0 m-0>
                                        <Grid item>
                                          <Avatar sx={{ width: "33px", height: "31px" }} color="primary" m-0>
                                            <Typography as="subtitle3" color="#fff">
                                              {(
                                                ((lstAnswersConfirmed?.filter((res) => String(res?.lstSurveyQusAns?.[qs?.bigQID]) === String(opt?.intOptID))?.length || 0) /
                                                  lstAnswersConfirmed?.length) *
                                                100
                                              )?.toFixed()}
                                              %
                                            </Typography>
                                          </Avatar>
                                        </Grid>
                                        <Grid item>
                                          <Typography as="subtitle3" m-auto>
                                            {opt?.strOpt?.[lang]}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  ))
                                ) : (
                                  <React.Fragment>
                                    <TableCell sx={{ background: "#fff" }}>
                                      <Grid container p-0 m-0>
                                        <Grid item>
                                          <Avatar sx={{ width: "33px", height: "31px" }} color="primary" m-0>
                                            <Typography as="subtitle3" color="#fff">
                                              {(
                                                ((lstAnswersConfirmed?.filter((res) => String(res?.lstSurveyQusAns?.[qs?.bigQID]) === "true")?.length || 0) /
                                                  lstAnswersConfirmed?.length) *
                                                100
                                              )?.toFixed()}
                                              %
                                            </Typography>
                                          </Avatar>
                                        </Grid>
                                        <Grid item>
                                          <Typography as="subtitle3" m-auto>
                                            {{ eng: "Correct", arb: "نعم" }?.[lang]}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell sx={{ background: "#fff" }}>
                                      <Grid container p-0 m-0>
                                        <Grid item>
                                          <Avatar sx={{ width: "33px", height: "31px" }} color="primary" m-0>
                                            <Typography as="subtitle3" color="#fff">
                                              {(
                                                ((lstAnswersConfirmed?.filter((res) => String(res?.lstSurveyQusAns?.[qs?.bigQID]) !== "true")?.length || 0) /
                                                  lstAnswersConfirmed?.length) *
                                                100
                                              )?.toFixed()}
                                              %
                                            </Typography>
                                          </Avatar>
                                        </Grid>
                                        <Grid item>
                                          <Typography as="subtitle3" m-auto>
                                            {{ eng: "Incorrect", arb: "لا" }?.[lang]}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </React.Fragment>
                                )}
                              </TableRow>
                            </TableHead>
                          </Table>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default MngSurveyView;
