// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useNavigate } from "@cbmisorg/router";
import { Container, Grid, Typography, Loader, Modal, ModalBody, ModalHeader } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { workSectorID } from "../../../../../../configuration/appVariables";
import WorkSectorOption from "../../../../member/account/components/regActivityGuest/component/WorkSectorOption.view";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { CtrlTraineeSetterModal } from "./traineeSetterModal.ctrl";
// import { CtrlTraineeSetterModal } from "./traineeSetterModal.ctrl";


const labels = dictionary?.components?.public?.trainingRegistration;

function TraineeSetterModalView({lang,appState,appDispatch, blnOpen, objUser, handelParentChangeObjUser, eventClose}) {
  const navigate = useNavigate();
  // const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    countryCode: "",
    areaCode: "",
    blnDefaultCountry: true,
    intStep: 1,
  });

  const fields = FormData({
    bigWorkSector2: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.student, value: dictionary?.shared?.app?.student?.title, jsx: () => <WorkSectorOption type="student" lang={lang} /> },
        { key: workSectorID?.unEmployee, value: dictionary?.shared?.app?.unEmployee?.title, jsx: () => <WorkSectorOption type="unEmployee" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, email: true },
    },
    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, onlyNumbers: true, minLength: 5, maxLength: 10 },
    },

    bigWorkGovSectorType: { control: "list", blnLanguage: false, validations: {} },
    bigGovPlaceName: { control: "list", blnLanguage: false, validations: {} },
    bigCountryOfCitizenID: { control: "list", validations: {}, blnLanguage: false },
    strPositionTitle: { control: "text", validations: {}, blnLanguage: false },
    bigMajorID: { control: "list", validations: {}, blnLanguage: false },
    bigUniversityName: { control: "list", validations: {}, blnLanguage: false },
    strUniversityName: { control: "text", validations: { required: () => fields?.bigUniversityName?.getValue()?.key === "other" }, blnLanguage: false },
    bigAcademicYearID: { control: "list", validations: {}, blnLanguage: false },
    strYearGraduation: { control: "text", validations: { onlyNumbers: true, minLength: 4, maxLength: 4 }, blnLanguage: false },
    strPlaceName: { control: "text", validations: {}, blnLanguage: false },
    intExperienceYear: { control: "text", validations: { onlyNumbers: true }, blnLanguage: false },

    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        minLength: 4,
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        minLength: 4,
        equal: "strUserPassword",
      },
    },
  });

 

  const handlers = CtrlTraineeSetterModal({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, fields, objUser });
  // const gOToStep3 = handlers.gOToStep3({ fields, state, setState });
  const onChangeWorkGovSectorType = handlers.onChangeWorkGovSectorType;
  // const onChangesetValidation = handlers.onChangesetValidation({ fields, state });
  // const handleSubmit = () => handlers.handleSubmit({ fields, navigate, state });

  useEffect(() => {
    if (blnOpen) {
      initData();
    }
  }, [blnOpen]);

  return (
    <React.Fragment>
      <Modal open={blnOpen} eventClose={eventClose}>
        <ModalHeader>
          <Typography as="subtitle2">{labels?.internInfo?.[lang]}</Typography>
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormField objHandler={fields.strFullName} icon="person"/>
              </Grid>
              <Grid item xs={12}>
                <FormField objHandler={fields.bigGender} icon="Wc"/>
              </Grid>
              <Grid item xs={"12"}>
                <FormField objHandler={fields.strUserEmail} icon="email" inputDir="ltr"/>
              </Grid>
              <Grid item xs={12}>
                <FormField
                  objHandler={fields.strUserPhone}
                  icon={"phone"}
                  endAdornment={String(state.areaCode)}
                  inputDir="ltr"
                  
                />
              </Grid>
              <Grid item xs="12">
                <FormField objHandler={fields?.bigWorkSector2} />
              </Grid>

              {fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov ? (
                <React.Fragment>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strPositionTitle} icon="work" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField
                      objHandler={fields.bigWorkGovSectorType}
                      icon="businesscenter"
                      
                      // onChange={onChangeWorkGovSectorType(fields)}
                    />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigGovPlaceName} icon="business" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.intExperienceYear} icon="AutoAwesome" />
                  </Grid>
                </React.Fragment>
              ) : fields?.bigWorkSector2?.getValue()?.key === workSectorID?.student ? (
                <React.Fragment>
                  <Grid item xs={12} md="12">
                    <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" />
                  </Grid>
                  <Grid item xs={12} sm={fields?.bigUniversityName?.getValue()?.key !== "other" ? "12" : "6"}>
                    <FormField objHandler={fields.bigUniversityName} icon="accountBalance" />
                  </Grid>
                  {fields?.bigUniversityName?.getValue()?.key !== "other" ? null : (
                    <Grid item xs={12} sm="6">
                      <FormField objHandler={fields.strUniversityName} icon="accountBalance" />
                    </Grid>
                  )}
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigMajorID} icon="book" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigAcademicYearID} icon="SquareFoot" />
                  </Grid>
                </React.Fragment>
              ) : fields?.bigWorkSector2?.getValue()?.key === workSectorID?.unEmployee ? (
                <React.Fragment>
                  <Grid item xs={12} md="12">
                    <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" />
                  </Grid>
                  <Grid item xs={12} sm={fields?.bigUniversityName?.getValue()?.key !== "other" ? "12" : "6"}>
                    <FormField objHandler={fields.bigUniversityName} icon="accountBalance" />
                  </Grid>
                  {fields?.bigUniversityName?.getValue()?.key !== "other" ? null : (
                    <Grid item xs={12} sm="6">
                      <FormField objHandler={fields.strUniversityName} icon="accountBalance" />
                    </Grid>
                  )}
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigMajorID} icon="book" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strYearGraduation} icon="school" />
                  </Grid>
                </React.Fragment>
              ) : fields?.bigWorkSector2?.getValue()?.key === workSectorID?.prvt ? (
                <React.Fragment>
                  <Grid item xs={12} md="12">
                    <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strPositionTitle} icon="work" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.bigMajorID} icon="book" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.strPlaceName} icon="business" />
                  </Grid>
                  <Grid item xs={12} md="6">
                    <FormField objHandler={fields.intExperienceYear} icon="AutoAwesome" />
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>

            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item py-5>
                {/* <Button py-0 px-10 label={dictionary?.shared?.buttons?.subtitle?.[lang]} mode="outlined" onClick={handleSubmit} className={classes?.btn} /> */}
              </Grid>
            </Grid>
            <input type="submit" style={{ visibility: "hidden" }} />
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default TraineeSetterModalView;
