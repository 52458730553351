import React from 'react'

import Grid from '../../../material/layout/Grid/Grid'
import Badge from '../../../material/dataDisplay/Badge/Badge'
import Checkbox from '../../../material/inputs/CheckboxGroup/CheckboxGroup'
import * as ClientFunction from '../../../helper/CbmisFunction'
import * as categoryVariables from "../CategoryVariables"
import Button from '../../../material/inputs/Button/Button'
import Icon from '../../../icon/CbmisIcon'


function RecursiveAccordion(props) {
    const {
        blnIsSelect,

        category, 
        objFelidName,
        rootID,

        stateInfo,
        setStateInfo,

        blnRootHasCheckbox,
        lstSelectedID,

        blnAdd,
        blnEdit,
        blnDelete,

        blnAddUnderTheRoot,
        blnEditRoot,
        blnDeleteRoot,

        handleOpenAddEdit,
        handleAdd,
        handleEdit,
        handleDelete,
        handleChecked,

        color,
        objLabels,
        objLanguage,
        currentLanguage,
    }=props


    const handleShow=(id)=>()=>{
        try{
            const list= document.querySelector(`#list-${id}`)
            if (ClientFunction.hasClass(list, "hide")){
                ClientFunction.replaceClass(list,"hide","show")
            }else if (ClientFunction.hasClass(list, "show")){
                ClientFunction.replaceClass(list,"show", "hide")
            }
        } catch {}
    }

    const handleCheckBox=(event)=>{
        try{
            const id = event.target.id
            const id_arr = String(id).split("-")
            const bigID = Number(id_arr[0])
            // const bigParentID =Number(id_arr[1])
            var newList = lstSelectedID || []
            if(handleChecked){
                handleChecked(event)
                if(event.target.checked){
                    newList.push(bigID)
                }else{
                    newList = newList.filter(item=>item ===bigID)
                }
                handleChecked(event,newList)

            }
        }catch(error){}
    }

    const handelEventDeleted=(event, categorySelect)=>{
        const theTreeLst = stateInfo?.treeList || []
        const newList = theTreeLst.filter(item=>item?.[objFelidName?.intID] === categorySelect?.[objFelidName?.intID])
        if(handleDelete){
            handleDelete(event,newList)
        }
        stateInfo.treeList = newList
        setStateInfo({...stateInfo})
    }
  return (
    <React.Fragment key={category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}>
        <li  key={category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}>
            <Grid container>
                <Grid item xs="6">
                    {
                        blnIsSelect && blnRootHasCheckbox?(
                            <React.Fragment>
                                <label id={category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}>
                                    <Icon icon={"arrowRight"}
                                          p-0 m-0 onClick={handleShow(category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID])} 
                                          disabled={!category?.children?.length}
                                        />
                                    <Checkbox
                                        options={[{key:`${category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}-${category?.[objFelidName?.intParentID] || category?.[categoryVariables?.objFelidName?.intParentID]}`,value:category?.[objFelidName?.strName] || category?.[categoryVariables?.objFelidName?.strName]}]} 
                                        onChange={handleCheckBox}
                                        defaultChecked={lstSelectedID.includes(category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID])}
                                        color={color}
                                        my-0
                                        py-0
                                    />
                                </label>

                            </React.Fragment>
                        ):(
                            <label id={category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}>
                                <Icon icon={"arrowRight"}
                                      p-0 m-0
                                      onClick={handleShow(category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID])} 
                                      disabled={!category?.children?.length}
                                    />
                                {category?.[objFelidName?.strName] || category?.[categoryVariables?.objFelidName?.strName]}
                            </label>
                        )
                    }
                </Grid>
                <Grid item xs="6" container justify="end" spacing={0} sx={{placeContent: "baseline end !important"}}>
                {
                    blnIsSelect?null:(
                        <React.Fragment>
                            {
                                blnAdd && blnAddUnderTheRoot && (
                                    <Grid item>
                                        <Button icon="addCircleOutlined" color="primary"
                                              onClick={(event)=>handleOpenAddEdit(event,null,category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID])}
                                             size="sm" p-1 mode="link"
                                        />
                                    </Grid>
                                )
                            }
                            {
                                (blnEdit && blnEditRoot) && (
                                    <Grid item>
                                        <Button icon="edit" color="info"
                                              onClick={(event)=>handleOpenAddEdit(event,category)}
                                             size="sm" p-1 mode="link"
                                        />
                                    </Grid>
                                )
                            }
                            {
                                (blnDelete && blnDeleteRoot) && (
                                    <Grid item>
                                        <Button icon="delete" color="error" 
                                              onClick={(event)=>handelEventDeleted(event,category)} 
                                             size="sm" p-1 mode="link"
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item p-0 px-3 m-0>
                                {category?.children?.length?<Badge content={category?.children?.length} color={color} sx={{top:"4px !important"}}/>:""}                        
                            </Grid>

                        </React.Fragment>
                    )
                }
                </Grid>
            </Grid>
            <ul id={`list-${category?.[objFelidName?.intID] || category?.[categoryVariables?.objFelidName?.intID]}`} className={`hide`}>
            {
                category?.children.map(item=>{
                    return(
                        <React.Fragment key={item?.[objFelidName?.intID]}>
                            <RecursiveAccordion
                                    blnIsSelect={blnIsSelect}

                                    stateInfo={stateInfo}
                                    setStateInfo={setStateInfo}

                                    category={item}
                                    objFelidName={objFelidName}
                                    rootID={rootID}

                                    blnRootHasCheckbox={true}
                                    lstSelectedID={lstSelectedID}

                                    blnAddRoot={true}
                                    blnEditRoot={true}
                                    blnDeleteRoot={true}

                                    blnAddUnderTheRoot={true}

                                    blnAdd={blnAdd}
                                    blnEdit={blnEdit}
                                    blnDelete={blnDelete}

                                    handleOpenAddEdit={handleOpenAddEdit}
                                    handleAdd={handleAdd}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    handleChecked={handleChecked}

                                    color={color}
                                    objLabels={objLabels}
                                    objLanguage={objLanguage}
                                    currentLanguage={currentLanguage}
                                />
                        </React.Fragment>
                    )
                })
            }
            </ul>
        </li>
    </React.Fragment>
  )
}

export default RecursiveAccordion