import React, { useState } from 'react'
import "./uploadImage.css"
import UploaderImageBox from './mode/UploaderImageBox'
import UploaderImageMultipleBox from './mode/UploaderImageMultipleBox'
import UploaderImageAvatar from './mode/UploaderImageAvatar'
import UploaderImageList from './mode/UploaderImageList'
import UploaderImageArea from './mode/UploaderImageArea'
import UploaderImageButton from './mode/UploaderImageButton'
import PreviewImage from '../modalFileUploader/PreviewImage'
import UploadModal from '../modalFileUploader/UploadModal'
import Camera from '../../cbmisCamera/camera/Camera'

function ImageUploader(props) {
  const {camera=true,gallery=true,mode,maxFilesNumber=1,maxSizeKB,blnChangeName,label,icon,maxWidth,maxHeight,quality=1,getImages,
    appPermissionState,handelChangePermission,lang,color,blnDownload}=props
  const [state, setState] = useState({
    files:[],
    cameraOpen:false,
    uploadOpen:false,
    imgInfo:{
      open:false,
      imageSelected:null,
      index:-1
    }
  })

    const handelOpenCamera=()=>{
      state.cameraOpen = true
      setState({...state})
    }
    const handelCloseCamera=(imageList)=>{
      state.cameraOpen = false
      state.files= imageList
      setState({...state})
    }

    const handleDeleteFile=(indexSelected)=>()=>{
      const filteredArray = state.files.filter(file=>state.files.indexOf(file) !==indexSelected)
      state.files = filteredArray
      setState({...state})
    }
    const handleClear=()=>{
      state.files = []
      setState({...state})
    }

    const handlePreview=(image,index)=>()=>{
      state.imgInfo = {
        open:true,
        imageSelected:image,
        index:index
      }
      setState({...state})
    }

    const handelUploadOpen=()=>{
      state.uploadOpen=true
      setState({...state})
    }
    const HandelCloseUpload=()=>{
      state.uploadOpen=false
      setState({...state})
    }
    const handelSetImage=(imageListFile)=>{
      if(imageListFile){
        state.files = imageListFile
        setState({...state})

        if(getImages){
          getImages(imageListFile)
        }
      }
    }
    const handelCloseModal=()=>{
      state.imgInfo = {
        open:false,
        imageSelected:"",
        index:-1
      }
      setState({...state})
    }


    const GetComponentJxs=()=>{
      switch (String(mode).toLowerCase()) {
        case "box":
          return(
            <UploaderImageBox
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handlePreview={handlePreview}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
            />
          )
        case "multiplebox":
          return(
            <UploaderImageMultipleBox 
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handlePreview={handlePreview}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
            />
          )
        case "avatar":
          return(
            <UploaderImageAvatar
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handlePreview={handlePreview}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
            />
          )
        case "list":
          return(
            <UploaderImageList
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handleClear={handleClear}
              handlePreview={handlePreview}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
            />
          )
        case "area":
          return(
            <UploaderImageArea
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handleClear={handleClear}
              handelUploadOpen={handelUploadOpen}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
            />
          )

          case "button":
          return(
            <UploaderImageButton
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handelUploadOpen={handelUploadOpen}
              handlePreview ={handlePreview}
              handleClear={handleClear}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
            />
          )
        default:
          return(
            <UploaderImageButton
              images={state.files}
              setImages={handelSetImage}
              gallery={gallery}
              camera={camera}
              handelOpenCamera={handelOpenCamera}
              handleDeleteFile={handleDeleteFile}
              handelUploadOpen={handelUploadOpen}
              handlePreview ={handlePreview}
              handleClear={handleClear}
              maxSizeKB={maxSizeKB}
              maxFilesNumber={maxFilesNumber}
              label={label}
              icon={icon}
              maxWidth={maxWidth} 
              maxHeight={maxHeight} 
              quality={quality}
              mode={mode}
            />
          )
      }
    }

    return (
    <React.Fragment>
          <GetComponentJxs />

        <Camera open={state.cameraOpen}
                eventClose={handelCloseCamera}
                maxFilesNumber={maxFilesNumber}
                imageSizeKB={maxSizeKB}
                maxWidth={maxWidth} 
                maxHeight={maxHeight} 
                quality={quality}
                blnChangeName={blnChangeName}
                appPermissionState={appPermissionState} 
                changeAppPermissionState={handelChangePermission} 
                color={color} lang={lang}
                blnDownload={blnDownload}
        />
          <UploadModal
             open={state.uploadOpen}
             setOpen={HandelCloseUpload}
             images={state.files}
             setImages={handelSetImage}
             gallery={gallery}
             camera={camera}
             handelOpenCamera={handelOpenCamera}
             handleDeleteFile={handleDeleteFile}
             handleClear={handleClear}
             handlePreview={handlePreview}
             maxSizeKB={maxSizeKB}
             maxFilesNumber={maxFilesNumber}
          />

    <PreviewImage 
            images={state.files}
            setImages={handelSetImage}
            imgInfo={state.imgInfo}
            handelCloseModal={handelCloseModal}
            handleDeleteFile={handleDeleteFile}
            blnChangeName={blnChangeName}
          />

    </React.Fragment>
  )
}

export default ImageUploader