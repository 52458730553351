
import { capitalize, funAppContext, funAppProvider } from './AppProviderHelper'
import searchFile from './searchState';

// console.log(searchFile("./src"));

export default function ContextProvider(appInitialState,contextName="app",strAppVersion="v1", strAppLocalStorageName = "appLocalStorageName", blnIsEncrypt = false, strEncryptKey = "secret", blnIsTestingMode = true) {
  const AppContext = funAppContext();
  if(appInitialState?.clientInfo?.strAppVersion){
    appInitialState.clientInfo.strAppVersion = strAppVersion
  }
  const {AppProvider, appContext} = funAppProvider(
    appInitialState,
    AppContext,
    contextName,
    strAppVersion,
    strAppLocalStorageName,
    blnIsEncrypt,
    strEncryptKey,
    blnIsTestingMode,
  );

  return{
    [`${capitalize(contextName)}Context`]:AppContext,
    [`${capitalize(contextName)}Provider`]:AppProvider,
    [`use${capitalize(contextName)}`]:appContext
  }
}