import React, { useEffect, useRef } from "react";
import "./Stepper.css";
import "../../../style/main.css";
import Icon from "../../../icon/CbmisIcon";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function Step(props) {
  const { indicator, stepClicked, label, navigateToStepHandler, index, mode, isActive, isComplete, isWarning, isError, completedColor, sx, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const styleRef = useRef({ strSxClassName: "", strSXComponentID: id || componentsFunction.randomName("cbmis-is-stepper-complete") });

  const classes = [""];
  if (isActive) {
    classes.push("cbmis-is-stepper-active");
  }
  if (isComplete) {
    classes.push("cbmis-is-stepper-complete");
  }
  if (isWarning) {
    classes.push("cbmis-is-stepper-warning");
  }
  if (isError) {
    classes.push("cbmis-is-stepper-error");
  }

  const handleStepClicked = (index) =>(event)=> {
    if (typeof stepClicked === "undefined" || stepClicked) {
      navigateToStepHandler(index);
      if(props?.onClick){
        props?.onClick(event)
      }
    }
  };

  useEffect(() => {}, [indicator, stepClicked, label, navigateToStepHandler, index, mode, isActive, isComplete, isWarning, isError, completedColor, sx, className]);

  useEffect(() => {
    if (completedColor && isComplete) {
      componentsFunction.getbgColorReturnClassName(`.${styleRef.current.strSxClassName} .cbmis-stepper-indicator-info`, completedColor);
      componentsFunction.changeItemBorderColor(`.${styleRef.current.strSxClassName} .cbmis-stepper-indicator-info`, completedColor);
      componentsFunction.changeItemColor(`.${styleRef.current.strSxClassName} .cbmis-stepper-label`, completedColor);
    }
  }, [isComplete]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-is-stepper-complete");
    }
  }, [sx, screenWidth]);

  return (
    <div id={styleRef.current.strSXComponentID} className={`cbmis-stepper-step ${classes.join(" ")} ${className || ""}`}>
      <div className="cbmis-stepper-indicator">
        <span
          className={`cbmis-stepper-indicator-info ${String(mode).toLowerCase() === "dot" ? "cbmis-stepper-indicator-info-dot" : ""}`}
          id={index}
          onClick={() => handleStepClicked(index)}
          {...rest}
        >
          {String(mode).toLowerCase() !== "dot" ? (
            isComplete || isError || isWarning ? (
              <Icon icon={isError ? "clear" : isWarning ? "warning" : "check"} size="26px" />
            ) : (
              indicator
            )
          ) : (
            ""
          )}
        </span>
      </div>
      {String(mode).toLowerCase() !== "dot" ? <div className="cbmis-stepper-label">{label}</div> : ""}
    </div>
  );
}

export default Step;
