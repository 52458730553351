import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../../util/appStyle";

export const objStyle = makeStyle({
    secDark: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: "calc(100vh - 0px)",
      overflowY: "auto",
      margin: "auto",
      backgroundColor: "rgb(0, 2, 36)",
      background: "linear-gradient(155deg, #000224 0%, #010219 55%, #0371a0 100%)",
    },
    mainContainer: {
      width: `${(11 / 12) * 100}%`,
    },
    container: {
      width: `${(11 / 12) * 100}%`,
      color: appStyle?.App_Light_Text_Color,
      ...appStyle.fontsChanga,
      textAlign: "justify",
      xs: {
        width: `${(11 / 12) * 100}%`,
      },
    },
    field: {
      fieldset: {
        borderRadius: 0,
      },
      input: {
        color: appStyle.App_Input_Text_Color,
        ...appStyle?.fontsMada,
      },
      label: {
        ...appStyle?.fontsMada,
      },
      ".uicore_switch_label_1gtyshu": {
        color: appStyle?.App_Light_Text_Color,
        textAlign: "justify",
      },
      ".uicore_menu": {
        background: appStyle?.App_Dark_Color,
        border: "1px solid",
        borderColor: appStyle?.App_info_Color,
        borderRadius: 0,
        color: appStyle?.App_Light_Text_Color,
        ...appStyle?.fontsMada,
        ".uicore_radio_opt_label": {
          "&hover": {
            background: appStyle?.App_Dark_menu_Color,
          },
        },
      },
    },
    bgBox: {
      zIndex: 10,
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        border: "1px solid",
        borderColor: appStyle?.App_info_Color,
        borderRadius: "1rem",
        background: appStyle?.App_Dark_menu_Color,
        paddingBlock: "0px",
      },
    },
    btnBox: {
      fontSize: "large",
      borderWidth: "1px",
      span: {
        ...appStyle?.fontsMada,
      },
      "&hover": {
        background: appStyle.App_Second_Opacity_Color,
        color: appStyle.App_Light_Text_Color,
      },
    },
    imageAvatar: {
      width: "90px",
      height: "90px",
      color: appStyle.App_Dark_Opacity_Color,
      border: "1px solid",
      borderColor: appStyle.App_info_Color,
      borderRadius: "50%",
    },
    uploader: {
      position: "absolute",
      right: "0px",
    },
    btnTab: {
      background: appStyle?.App_Dark_menu_Color,
      color: appStyle.App_info_Color,
      top: "2px",
      borderBottom: "none",
      transition: "zIndex 2s",
      span: {
        ...appStyle?.fontsMada,
      },
    },
    btnActive: {
      borderColor: appStyle?.App_info_Color,
      borderWidth: "1px",
      top: "2.3px",
      zIndex: "1000",
      borderRadius: "0.25rem 0.25rem 0 0",
      background: appStyle?.App_Dark_menu_Color,
      sm:{
        top: "3px",
      },
    },
    mainTitle: {
      color: appStyle.App_info_Color,
      position: "relative",
      margin: "10px auto 0px",
      ...appStyle.fontsChanga,
      textAlign: "center",
      fontWeight: "600",
      xs: {
        padding: "22px 0 0",
      },
    },
  });
