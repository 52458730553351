import "./Alert.css";
import '../../../style/main.css'

/**
 * @param {string} message
 * @param {"warning" | "error" | "info" | "success"} type
 * @param {boolean} fade
 * @param {string} vertical
 * @param {string} horizontal
 * @param {boolean} canClose
 */

  //#region alert type
  export const Alert_Type_Warning = "warning";
  export const Alert_Type_Error = "error";
  export const Alert_Type_Info = "info";
  export const Alert_Type_Success = "success";

  //#region alert vertical
  export const Alert_Vertical_Top = "top";
  export const Alert_Vertical_Center = "center";
  export const Alert_Vertical_Bottom = "bottom";

  //#region alert horizontal
  export const Alert_Horizontal_Left = "left";
  export const Alert_Horizontal_Center = "center";
  export const Alert_Horizontal_Right = "right";

  export const Alert_Fade_On = true;
  export const Alert_Fade_Off = false;

  export function viewAlert(message, type, vertical, horizontal,fade,canClose=true) {

  //#endregion
  //////////////////////////////////////////// icon ///////////////////////////////////////////////////
  const closeIcon = `<div class="cbmis-alert-content-icon-close-flex"><svg class="cbmis-alert-content-icon-close" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></div>`;
  const successIcon = `<div class="cbmis-alert-content-icon-flag-flex"><svg class="cbmis-alert-content-icon-flag" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></div>`;
  const warningIcon = `<div class="cbmis-alert-content-icon-flag-flex"><svg class="cbmis-alert-content-icon-flag" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg></div>`;
  const errorIcon = `<div class="cbmis-alert-content-icon-flag-flex"><svg class="cbmis-alert-content-icon-flag" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg></div>`;
  const infoIcon = `<div class="cbmis-alert-content-icon-flag-flex"><svg class="cbmis-alert-content-icon-flag" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>`;
  //////////////////////////////////////// root of modal //////////////////////////////////////////////
  const modal = document.createElement("div");
  modal.classList.add("cbmis-alert-modal-container");

  ////////////////////////////////////// container ///////////////////////////////////////////////////
  const modalItem = document.createElement("div");
  modalItem.classList.add("cbmis-alert-modal-Item");

  ////////////////////////////////////// postion ///////////////////////////////////////////////////

    switch (String(horizontal).toLowerCase()) {
    case Alert_Horizontal_Left:
        modalItem.classList.add("cbmis-alert-box-horizontal-left-position");
        break;
    case Alert_Horizontal_Right:
        modalItem.classList.add("cbmis-alert-box-horizontal-right-position");
        break;
    default:
        break;
    }

    switch (String(vertical).toLowerCase()) {
        case Alert_Vertical_Top:
            modalItem.classList.add("cbmis-alert-box-vertical-top-position");
            break;
        case Alert_Vertical_Bottom:
            modalItem.classList.add("cbmis-alert-box-vertical-bottom-position");
            break;
        default:
            break;
        }
  ////////////////////////////////////// content ///////////////////////////////////////////////////
  const modalContentContainer = document.createElement("div");
  modalContentContainer.classList.add("cbmis-alert-content-container");
 

  ////////////////////////////////////// icon && type ///////////////////////////////////////////////////
  const modalFlagIconItem = document.createElement("div");
  modalFlagIconItem.classList.add("cbmis-alert-content-icon-flag-item");

  switch (String(type).toLowerCase()) {
    case Alert_Type_Error:
      modalItem.classList.add("cbmis-alert-modal-container-error");
      modalFlagIconItem.innerHTML = errorIcon;
      break;
  case Alert_Type_Warning:
      modalItem.classList.add("cbmis-alert-modal-container-warning");
      modalFlagIconItem.innerHTML = warningIcon;
      break;
  case Alert_Type_Info:
      modalItem.classList.add("cbmis-alert-modal-container-info");
      modalFlagIconItem.innerHTML = infoIcon;
      break;
  case Alert_Type_Success:
      modalItem.classList.add("cbmis-alert-modal-container-success");
      modalFlagIconItem.innerHTML = successIcon;
      break;
    default:
      modalItem.classList.add("cbmis-alert-modal-container-primary");
      modalFlagIconItem.innerHTML = infoIcon;
      break;
}

  ////////////////////////////////// text content /////////////////////////////////////////////////
  const msgItem = document.createElement("div");
  msgItem.classList.add("cbmis-alert-message-text-item");

  const msgContent = document.createElement("div");
  msgItem.classList.add("cbmis-alert-message-text-content");
  msgContent.innerHTML = message;

  ////////////////////////////////// close button ////////////////////////////////////////////////
 
  const modalCloseIconItem = document.createElement("div");
  modalCloseIconItem.classList.add("cbmis-alert-content-icon-close-item");
  modalCloseIconItem.innerHTML = closeIcon;

  modalCloseIconItem.addEventListener("click", () => {
    if (modal) {
      modal.classList.remove("cbmis-alert-modal-container");
      modal.classList.add("cbmis-alert-modal-container-hidden");
      setTimeout(() => {
        modal.remove();
      }, 50);
    }
  });
  
  modal.appendChild(modalItem);
  modalItem.appendChild(modalContentContainer);
  modalContentContainer.appendChild(modalFlagIconItem);
  msgItem.appendChild(msgContent);
  modalContentContainer.appendChild(msgItem);
  if(canClose){
    modalContentContainer.appendChild(modalCloseIconItem);
  }
  
  ////////////////////////////////////// add to body /////////////////////////////////////////////
  document.body.appendChild(modal);


    ////////////////////////////////////// fade ///////////////////////////////////////////////////

      if(modal &&  fade === Alert_Fade_On && canClose){
        setInterval( ()=>{
          modal.classList.remove("cbmis-alert-modal-container");
          modal.classList.add("cbmis-alert-modal-container-hidden");
          setTimeout(() => {
            modal.remove();
          }, 100);
        }, 8000);
    }

  window.onclick = function (event) {
    if (event.target === modal && canClose) {
      modal.classList.remove("cbmis-alert-modal-container");
      modal.classList.add("cbmis-alert-modal-container-hidden");
      setTimeout(() => {
        modal.remove();
      }, 50);
    }
  };
}

