import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { Button, Grid,  Typography } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import regActivityGuestStudentCtrl from "./regActivityGuestStudent.controller";
import { App_info_Color } from "../../../../../../util/appStyle";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { objUploadType } from "../../../../../../configuration/appVariables";

import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "../regActivityGuest/RegActivityGuest.style";
import { modalOverFllow } from "../../../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.account?.runningActivity;

export default function RegActivityGuestStudentView({ appState, appDispatch, isLoading, setIsLoading, parentState, setParentState, activityInfo, closeModal }) {
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });

  const [state, setState] = useState({ blnIsInitDone: false, blnIsNew: true, lstTranscript: [] });
  const uploaderFirstProofSection = Uploader(state, setState, "lstTranscript", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
  });
  const fields = FormData({
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    bigMajorID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    strGPA: { control: "text", validations: { required: true }, blnLanguage: false },
    // student
    strUniversityName: { control: "list", validations: { required: true }, blnLanguage: false },
    bigAcademicYearID: { control: "list", validations: { required: true }, blnLanguage: false },
    strGPAof: { control: "list", validations: { required: true }, blnLanguage: false, options: [{key:"100", value:"/100"}, {key:"4", value:"/4"},  {key: "A", value:"/A"}] },
  });

  const handlers = regActivityGuestStudentCtrl({ appDispatch, appState, isLoading, setIsLoading });
  // const openModal = handlers.openModal({ fields, setState, state });
  // const onChangeCountry = handlers.onChangeCountry({ fields });
  const updateTraineeInfo = handlers.addGuest({ fields, setState, state, activityInfo, uploaderFirstProofSection, closeModal, parentState, setParentState });
  const initData = handlers.initData({ state, setState, fields });
  const handelGPAtypeChange = handlers?.handelGPAtypeChange({fields})

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
    return () => {
      modalOverFllow()
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"center"} alignContent="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <FormField objHandler={fields.strFullName} icon="person" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField
            objHandler={fields.strUniversityName}
            icon="accountBalance"
            mode={"classic"}
            
            color={App_info_Color}
            className={classes?.field}
          />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={"8"} sm="10" md="10" xl="11" className={classes?.paddingInlineEnd}>
          <FormField objHandler={fields.strGPA} icon="grade" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={"4"} sm="3" md="2" xl="1" className={classes?.paddingInlineStart} sx={fields.strGPA?.getValue()?{marginTop: "2px !important"}:{marginTop: "0px !important"}}>
          <FormField objHandler={fields.strGPAof}  mode={"classic"}  color={App_info_Color}  className={classes?.field} onChange={handelGPAtypeChange} />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField
            objHandler={fields.bigAcademicYearID}
            icon="AutoStories"
            mode={"classic"}
            
            color={App_info_Color}
            className={classes?.field}
          />
        </Grid>
        <Grid item xs={12} sm="6">
          <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs="12" container sx={{ sm: { justifyContent: "center" } }}>
          <Grid item xs="12" md="auto">
            <Typography as="body2" className={classes?.descriptionLightText}>
              {labels?.proofUpload?.[lang]}
            </Typography>
          </Grid>
          <Grid item>{uploaderFirstProofSection?.JSX}</Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent={"center"} spacing={2}>
        <Grid item xs="12" pt-8 pb-5>
          <Button
            py-0
            px-10
            label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
            onClick={updateTraineeInfo}
            color={App_info_Color}
            mode="outlined"
            className={classes?.btnBox}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
