import React from "react";
import { Box, Button, Container, Grid, Typography, useNavigate } from "@cbmisorg/client-app";
import heroposter from "../../../../../assets/images/heroImg.jpg";

import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import appRouting from "../../../../../../client/configuration/routing/appRouting";
import imgkw from "../../../../../assets/images/kw.png";
import { generatePath } from "react-router-dom";

const labels = dictionary?.components?.public?.landingPage?.home?.hero;

const lstVideos = ["https://static-media-project.s3.eu-central-1.amazonaws.com/aaiot/digital-city-concept-artificial-intelligence-technology-in-smart-city-free-video.mp4"];
const classes = {
  title: {
    sm: {
      fontSize: "var(--cbmis-h5-font-size)!important",
    },
  },
  background: {
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    width: "100% !important",
    height: "auto !important",
    minHeight: "350px",
    sm: {
      height: "auto !important",
      minHeight: "200px",
    },
  },
  backgroundBlack: {
    "&before": {
      content: "",
      position: "absolute",
      width: "100%",
      height: "100%",
      minHeight: "350px !important",
      display: "block",
      opacity: ".3",
      borderRadius: "0",
      background: "#111",
      sm: {
        height: "auto !important",
        minHeight: "200px !important",
      },
    },
  },
};

function Hero({ lang, blnVideo, appState, appDispatch }) {
  const navigate = useNavigate();
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  // const goToReg = () => {
  //   if (!appState?.clientInfo?.blnIsUserLoggedIn) {
  //     navigate(appRouting?.Register?.url);
  //   } else {
  //     navigate(appRouting?.Account?.url);
  //   }
  // };
  return (
    <React.Fragment>
      <Container sx={{ ...classes?.background }} m-0>
        <video autoPlay muted loop playsInline controls={false} preload="none" className="heroVideo" poster={heroposter}>
          <source src={lstVideos[0]} type="video/mp4" />
        </video>
        <Grid container justify="center" className="hightFull" sx={classes?.backgroundBlack}>
          <Grid item xs="12" md="10" lg="9" alignSelf="center">
            <Container py-5>
              <Grid container justify="center" spacing={3}>
                <Grid item xs="12" pt-10>
                  <Typography as="h3" color="light" sx={classes?.title} className="textcenter fontHand lineUp ">
                    {labels?.slide4?.title?.[lang]}
                  </Typography>
                </Grid>
                <Grid item xs="12">
                  <Typography as="subtitle1" color={"light"} className="textjustifyCener">
                    {labels?.slide4?.body?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Grid container>
        <Grid item xs="12">
          <Container py-5 mt-5>
            <Grid container justify={"center"}>
              {appState?.userInfo?.blnIsStudent ? null : (
                <Grid item xs="11" sx={{ alignSelf: "stretch" }}>
                  <Box
                    outline={"1"}
                    className="boxViewNoHeight"
                    sx={{ minWidth: "100%" }}
                    style={{
                      background: `url(${imgkw})`,
                      backgroundAttachment: "fixed",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Grid container justify={"space-between"} spacing={2}>
                      <Grid item xs="12" px-4 sx={{ justifyContent: "center" }}>
                        <Typography as="subtitle1" color={"primary"} className="textCenter">
                          {jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryTitle?.[lang] || labels?.training?.title?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item xs="12" px-4>
                        <Typography as="body2" color={"primary"}>
                          {labels?.training?.subTitle?.[lang]}
                        </Typography>
                      </Grid>
                      <Grid item pb-3 mx-auto>
                        <Button
                          size="sm"
                          px-3
                          color={"primary"}
                          label={labels?.training?.button?.[lang]}
                          sx={{ borderRadius: "1rem", minWidth: "100px" }}
                          onClick={() =>
                            navigate(
                              generatePath(appRouting?.TrainingRegistration?.url, {
                                countrycode: appState?.clientInfo?.clientCountryCode ? appState?.clientInfo?.clientCountryCode : "kw",
                              })
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {/* <Grid item xs="11" sx={{ alignSelf: "stretch" }}>
                <Box
                  outline={"1"}
                  className="boxViewNoHeight"
                  sx={{ minWidth: "100%" }}
                  style={{
                    background: `url(${imgkw})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Grid container justify={"space-between"} spacing={2}>
                    <Grid item xs="12" px-4 sx={{ justifyContent: "center" }}>
                      <Typography as="subtitle1" color={"primary"} className="textCenter">
                        {labels?.training?.titlekuwait?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs="12" px-4>
                      <Typography as="body2" color={"primary"}>
                        {labels?.training?.kuwait?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item pb-3 mx-auto>
                        <Button
                          size="sm"
                          px-3
                          color={"primary"}
                          label={labels?.training?.buttonKuwait?.[lang]}
                          sx={{ borderRadius: "1rem", minWidth: "100px" }}
                          onClick={() =>
                            navigate(
                              generatePath(appRouting?.media?.url, {
                                strUrlVedio: "kuwait",
                              })
                            )
                          }
                        />
                      </Grid>
                  </Grid>
                </Box>
              </Grid> */}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Hero;
