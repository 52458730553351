import React, { useEffect, useRef } from "react";
import "./Table.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function Table(props) {
  const { mobileMode, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const styleRef = useRef({ strSxClassName: "" });
  const getID = useRef(id || componentsFunction.randomName("Table"));

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-table-contenter");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getTableClassName = () => {
    if ((mobileMode === false || mobileMode === "off") && typeof mobileMode !== "undefined") {
      return "cbmis-mobile-mod-off-contenter";
    } else {
      return "cbmis-table-contenter";
    }
  };

  return (
    <div
      id={getID.current}
      className={`${getTableClassName()} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      <table className="cbmis-table">{props.children}</table>
    </div>
  );
}

export default Table;
