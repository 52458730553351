import React from 'react'
import AgentHeader from '../agentHeader/AgentHeader'

function Header(props) {
  const {blnAgentImg,icon,iconSize,iconColor,avatarColor,avatarSize}=props

  const handelChatOpen = ()=>{
    const messenger = document.querySelector(".cbmis-messenger-main-container")
    const close = document.querySelector(".cbmis-messenger-close")
    if(close){
        messenger.classList.remove("cbmis-messenger-close")
    }else{
        messenger.classList.add("cbmis-messenger-close")
    }
    const items = document.querySelector(".items span")
      items.style.opacity=0
  }

  const handelMenuClick=()=>{
    const items = document.querySelector(".items span")
    items.style.opacity=1
  }

  
  return (
    <React.Fragment>
        <div className="cbmis-chat-menu">
            <div className="items">
                    <span>
                        {/* <label title="Minimize">&mdash;</label>
                        <br /> */}
                        <label title="End Chat" onClick={handelChatOpen}>&#10005;</label>
                    </span>
                </div>
                <div className="cbmis-chat-button" onClick={handelMenuClick}>...</div>
         </div>

         <AgentHeader handelChatOpen={handelChatOpen} blnAgentImg={blnAgentImg} icon={icon} iconColor={iconColor} iconSize={iconSize} avatarColor={avatarColor} avatarSize={avatarSize}/>
    </React.Fragment>
  )
}

export default Header