import React, { useEffect, useState } from "react";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { Container, Grid, Paper, Typography } from "@cbmisorg/material";
import { App_Light_Color } from "../../../../appHelper/appColor";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@cbmisorg/client-app";
import attendeeManagementCtrl from "./AttendeeManagement.controller";
import { Icon } from "@cbmisorg/icons";
import excelIcon from "../../../../assets/images/excel-icon.png";

const labels = dictionary?.components?.attendeeManagement;

function AttendeeManagementView({ bigActivityType }) {
  const { appState, appDispatch } = useApp();

  const lang = appState?.clientInfo?.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsFetchDone: false,
    lstAttendee: [],
  });

  const fields = FormData({
    strSearch: {
      strControl: "text",
      objLanguage: false,
      lstOption: [],
    },
  });
  const handlers = attendeeManagementCtrl({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, fields, bigActivityType });
  const restSearch = handlers.restSearch({ fields });
  const exportExcelFile = handlers.exportExcelFile({ state });

  useEffect(() => {
    if (!state.blnIsFetchDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      {bigActivityType ? null : <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />}
      <Container sx={{ width: bigActivityType ? "100%" : null, padding: bigActivityType ? "0px" : "25px 0 25px 0" }}>
        <Grid container justifyContent="center">
          <Grid item xs="12">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs="10">
                  <FormField objHandler={fields?.strSearch} icon="search" />
                </Grid>
                <Grid item alignItems="center">
                  <Icon icon="restartalt" onClick={restSearch} />
                </Grid>
                <Grid item xs="1" justify={"end"} px-3 alignItems="center">
                  <span onClick={exportExcelFile} style={{ display: "flex" }}>
                    <Typography as="caption" px-2 py-0>
                      {labels?.certificateBtn?.[lang]}
                    </Typography>
                    <img src={excelIcon} alt="" width={"25px"} height={"25px"} style={{ borderRadius: "4px" }} />
                  </span>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs="12">
                  <Table mobileMode={false}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "start" }}>{labels?.name?.[lang]}</TableCell>
                        <TableCell>{labels?.code?.[lang]}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state?.lstAttendee?.map((attendee) => {
                        if (
                          (fields?.strSearch?.getValue() && !JSON?.stringify(attendee?.jsnGuestInfo?.strFullName)?.includes(fields?.strSearch?.getValue())) ||
                          attendee?.blnIsConfirmed
                        ) {
                          return null;
                        }
                        return (
                          <TableRow key={attendee?.bigActivityGuestID}>
                            <TableCell>
                              <Typography>
                                {typeof attendee?.jsnGuestInfo?.strFullName === "object"
                                  ? attendee?.jsnGuestInfo?.strFullName?.[lang]
                                  : attendee?.jsnGuestInfo?.strFullName}
                              </Typography>
                            </TableCell>
                            <TableCell>{String(attendee?.bigActivityGuestID)?.slice(7) + String(attendee?.bigActivityType)?.slice(9)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default AttendeeManagementView;
