import React from 'react'
import Modal from '../../../material/dataDisplay/Modal/Modal'
import ModalBody from '../../../material/dataDisplay/Modal/ModalBody'
import ModalHeader from '../../../material/dataDisplay/Modal/ModalHeader'
import Typography from '../../../material/dataDisplay/Typography/Typography'
import Grid from '../../../material/layout/Grid/Grid'
import Line from '../../../material/layout/Line/Line'
import Icon from '../../../icon/CbmisIcon'
import Uploader from '../Uploader'
import "./modal.css"

function UploadModal(props) {
    const {open,setOpen,images,setImages,gallery,camera,handelOpenCamera,handleDeleteFile,handleClear,handlePreview,maxSizeKB,maxFilesNumber,maxWidth,maxHeight,quality}=props

    const handelClose =()=>{
        if(setOpen){
            setOpen(false)
        }
    }
  return (
    <React.Fragment>
        <Modal open={open} eventClose={handelClose}>
            <ModalHeader>
                <Typography as="subtitle1"></Typography>
            </ModalHeader>
            <ModalBody>
                <Grid container>
                    <Grid item xs="2" lg="1" container spacing="0" p-0>
                    {
                        !gallery?null:(
                            <Grid item xs="12" p-0>
                                <label className="list-preview-btn">
                                    <Uploader images={images} setImages={setImages}  maxSizeKB={maxSizeKB} maxFilesNumber={maxFilesNumber} maxWidth={maxWidth} maxHeight={maxHeight} quality={quality}/>
                                    <Icon icon="uploadFile" />
                                </label>
                            </Grid>
                        )
                    }
                    {
                        !camera?null:(
                            <Grid item xs="12">
                                <button className="list-preview-btn" onClick={handelOpenCamera}>
                                    <Icon icon="cameraAlt" />
                                </button>
                            </Grid>
                        )
                    }
                    </Grid>
                    <Grid item xs="10" lg="11" container>
                        <div className='image-preview-modal-upload'>
                            {!images.length?(
                            <Icon icon="image" className="iconImage"/>
                            ):Object.values(images).map((imgFile,index)=>{
                                    return(
                                        <div className="image-preview-container-list image-preview-container-list-upload-modal" key={index}>
                                            <div className="image-preview-container-header" >
                                                <img src={URL.createObjectURL(imgFile)} alt="/" className="image-preview image-preview-list"/>
                                                <label className='label-preview-list'>
                                                    {imgFile.name}
                                                </label>
                                            </div>
                                            <div className="image-preview-container-header" >
                                                <Icon icon="designServices" onClick={handlePreview(imgFile,index)} size="1.3rem"/>
                                                <Icon icon="close" onClick={handleDeleteFile(index)} size="1.3rem"/>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Grid>
                </Grid>
                <Line />
                <Grid container justify="space-between" spacing="0" px-2 >
                    <Grid item>
                        <Typography>
                            Total: {images.length}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <button className="list-preview-btn" onClick={handleClear}>
                            <Icon icon="delete" />
                        </button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    </React.Fragment>
  )
}

export default UploadModal