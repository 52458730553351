import * as cbmisTest from "../cbmisTest/CbmisTest";
import * as appFunctions from "../../helper/CbmisFunction";
import CryptoJS from "crypto-js";

/**
 *
 * @param {string} strURL
 * @param {{appName?:string, tableName?:string, queryName?:string, queryBody?:string, appState?:any}} objRequestQuery
 * @param {{token?:string, bigUserID: number, bigUserRoleID: number, bigFeatureID: number }} strRequester
 * @param {{blnIsTestingMode?:boolean, strPageInfo?:string, blnIsLocalStorageEncrypted?: boolean, strLocalStorageEncryptKey?: string, signal?: AbortSignal}} [objTestingConfig]
 * @returns
 */
export async function fetchData(
  strURL,
  objRequestQuery,
  strRequester,
  objTestingConfig = { blnIsTestingMode: true, strPageInfo: "", blnIsLocalStorageEncrypted: false, strLocalStorageEncryptKey: "secret", signal: null }
) {
  try {
    if (!objTestingConfig?.strPageInfo) {
      objTestingConfig.strPageInfo = "";
    }
    if (!objTestingConfig?.blnIsLocalStorageEncrypted) {
      objTestingConfig.blnIsLocalStorageEncrypted = false;
    }
    if (!objTestingConfig?.strLocalStorageEncryptKey) {
      objTestingConfig.strLocalStorageEncryptKey = "secret";
    }

    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(
        objTestingConfig.strPageInfo + "*------------------( " + objRequestQuery.tableName + "-" + objRequestQuery.queryName + "-Query )----------------------------"
      );
      console.log("*");
      console.log(objRequestQuery.queryBody);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    let objReqCounter = null;
    if (localStorage.hasOwnProperty("SRPM") && localStorage.getItem("SRPM") !== null) {
      objReqCounter = getAppLocalStorage("SRPM", objTestingConfig?.blnIsLocalStorageEncrypted, objTestingConfig?.strLocalStorageEncryptKey);
    }

    if (!objReqCounter || !objReqCounter?.rqNb || !objReqCounter?.lasR) {
      objReqCounter = {
        rqNb: 1,
        lasR: new Date(),
      };
    } else {
      if (getDifferenceBetweenTwoDate(objReqCounter.lasR, new Date(), "minute") > 1) {
        objReqCounter = {
          rqNb: 1,
          lasR: new Date(),
        };
      } else {
        ++objReqCounter.rqNb;
      }
    }

    setAppLocalStorage(objReqCounter, "SRPM", objTestingConfig?.blnIsLocalStorageEncrypted, objTestingConfig?.strLocalStorageEncryptKey);

    let header = new Headers();
    header.append("Content-Type", "application/json; charset=utf-8");
    if (strRequester?.token) {
      header.append("Authorization", `Bearer ${strRequester.token}`);
    } else {
      strRequester.token = generatePatternID();
      header.append("Authorization", `Bearer ${strRequester.token}`);
    }

    const res = await fetch(strURL, {
      method: "POST",
      ...(objTestingConfig?.signal ? { signal: objTestingConfig.signal } : {}),
      body: JSON.stringify({
        query: objRequestQuery.queryBody,
        strRequester: `${objRequestQuery.appName}*-$-*${objRequestQuery.tableName}*-$-*${objRequestQuery.queryName}*-$-*${strRequester.bigUserID}*-$-*${strRequester.bigUserRoleID}*-$-*${strRequester.bigFeatureID}*-$-*${objReqCounter.rqNb}`,
      }),
      headers: header,
    });
    const json = await res.json();

    let response = json?.data?.[objRequestQuery.queryName];

    if (!response) {
      response = { ...response };
    }

    response.serverResponse = appFunctions.checkParseObject(response?.serverResponse);

    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(
        objTestingConfig.strPageInfo + "*------------------( " + objRequestQuery.tableName + "-" + objRequestQuery.queryName + "-Response )----------------------------"
      );
      console.log("*");
      console.log(response);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    if (response.serverResponse?.error) {
      return {
        blnIsRequestSuccessful: response?.blnIsRequestSuccessful ? true : false,
        response: response?.serverResponse?.error,
      };
    }

    if (!response?.serverResponse && !response?.blnIsRequestSuccessful) {
      return { blnIsRequestSuccessful: false, response: "Server sent an empty response." };
    }

    return { blnIsRequestSuccessful: response?.blnIsRequestSuccessful ? true : false, response: response?.serverResponse };
  } catch (error) {
    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig.strPageInfo + "*------------------( Error Fetch Data )----------------------------");
      console.log("*");
      console.error(error);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    if (objTestingConfig.blnIsTestingMode) {
      return { blnIsRequestSuccessful: false, response: error instanceof SyntaxError ? "Server block the request." : error?.message ? error?.message : error };
    } else {
      return {
        blnIsRequestSuccessful: false,
        response: "Sorry, we can`t process your request at this time, Please check you internet connection then try again.",
      };
    }
  }
}

/**
 * @param {string} strUploadFileLink
 * @param {Array.<{fileType, fileNewName, fileOriginalName, fileSizeByte, subDirPath}>} userData
 * @param {FileList | any[]} userFiles
 * @param {{appName:string, queryName:string, bigUserID, bigUserRoleID, bigFeatureID, token?}} strRequester
 * @param {{blnIsTestingMode?: boolean, strPageInfo?:string, blnIsLocalStorageEncrypted?: boolean, strLocalStorageEncryptKey?: string, signal?: AbortSignal, blnIsServerFile?: boolean  }} objTestingConfig
 */
async function fetchFiles(
  strUploadFileLink,
  userData,
  userFiles,
  strRequester,
  objTestingConfig = { blnIsTestingMode: true, strPageInfo: "", blnIsLocalStorageEncrypted: false, strLocalStorageEncryptKey: "secret", signal: null }
) {
  try {
    if (!objTestingConfig?.strPageInfo) {
      objTestingConfig.strPageInfo = "";
    }

    if (!objTestingConfig?.blnIsLocalStorageEncrypted) {
      objTestingConfig.blnIsLocalStorageEncrypted = false;
    }
    if (!objTestingConfig?.strLocalStorageEncryptKey) {
      objTestingConfig.strLocalStorageEncryptKey = "secret";
    }
    if (!objTestingConfig?.blnIsServerFile) {
      objTestingConfig.blnIsServerFile = false;
    }

    let objReqCounter = null;
    if (localStorage.hasOwnProperty("SRPM") && localStorage.getItem("SRPM") !== null) {
      objReqCounter = getAppLocalStorage("SRPM", objTestingConfig?.blnIsLocalStorageEncrypted, objTestingConfig?.strLocalStorageEncryptKey);
    }

    if (!objReqCounter || !objReqCounter?.rqNb || !objReqCounter?.lasR) {
      objReqCounter = {
        rqNb: 1,
        lasR: new Date(),
      };
    } else {
      if (getDifferenceBetweenTwoDate(objReqCounter.lasR, new Date(), "minute") > 1) {
        objReqCounter = {
          rqNb: 1,
          lasR: new Date(),
        };
      } else {
        ++objReqCounter.rqNb;
      }
    }

    setAppLocalStorage(objReqCounter, "SRPM", objTestingConfig?.blnIsLocalStorageEncrypted, objTestingConfig?.strLocalStorageEncryptKey);

    const formData = new FormData();
    formData.append(
      "strRequester",
      `${strRequester.appName}*-$-*uploadFile*-$-*${strRequester.queryName}*-$-*${strRequester.bigUserID}*-$-*${strRequester.bigUserRoleID}*-$-*${strRequester.bigFeatureID}*-$-*${objReqCounter.rqNb}`
    );
    formData.append("userData", JSON.stringify(userData));
    Array.from(userFiles).forEach((file) => {
      formData.append("userFiles", file);
    });
    let header = new Headers();
    header.append("Accept", "application/json");
    header.append("cbmis-auth-data-op", "file");

    if (objTestingConfig?.blnIsServerFile) {
      header.append("cbmis-client-data-op", "file");
    }

    if (strRequester?.token) {
      header.append("Authorization", `Bearer ${strRequester.token}`);
    } else {
      strRequester.token = generatePatternID();
      header.append("Authorization", `Bearer ${strRequester.token}`);
    }

    //header.append("Access-Control-Allow-Origin", "*");
    const res = await fetch(strUploadFileLink, {
      method: "POST",
      ...(objTestingConfig?.signal ? { signal: objTestingConfig.signal } : {}),
      //enctype: "multipart/form-data",
      headers: header,
      body: formData,
    });
    const json = await res.json();

    if (objTestingConfig?.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig?.strPageInfo + "*------------------( UploadFile-Response )----------------------------");
      console.log("*");
      console.log(json?.response);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    return json.response;
  } catch (error) {
    if (objTestingConfig?.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig?.strPageInfo + "*------------------( Error Fetch File )----------------------------");
      console.log("*");
      console.error(error);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    if (objTestingConfig?.blnIsTestingMode) {
      return { error: error instanceof SyntaxError ? "Server block the request." : error?.message ? error?.message : error };
    } else {
      return {
        error: "Sorry, we can`t process your request at this time, Please check you internet connection then try again.",
      };
    }
  }
}

/**
 *
 * @param {string} strUploadFileLink
 * @param {FileList} lstFiles
 * @param {string} fileType
 * @param {string} subDirPath
 * @param {{appName:string, queryName:string, bigUserID, bigUserRoleID, bigFeatureID, token?}} strRequester
 * @param {{blnIsMultiFile?:boolean, newFileName?: string | string[] , blnReturnFullResponse?:boolean}} objUploadConfig
 * @param {{blnIsTestingMode?: boolean, strPageInfo?:string, blnIsLocalStorageEncrypted?: boolean, strLocalStorageEncryptKey?: string, signal?: AbortSignal, blnIsServerFile?:boolean  }} objTestingConfig
 * @returns
 */
export async function uploadFile(
  strUploadFileLink,
  lstFiles,
  fileType,
  subDirPath = null,
  strRequester,
  objUploadConfig = { blnIsMultiFile: false, newFileName: undefined, blnReturnFullResponse: true },
  objTestingConfig = {
    blnIsTestingMode: true,
    strPageInfo: "",
    blnIsLocalStorageEncrypted: false,
    strLocalStorageEncryptKey: "secret",
    signal: null,
    blnIsServerFile: false,
  }
) {
  if (!objUploadConfig || typeof objUploadConfig !== "object") {
    objUploadConfig = {};
  }

  if (objUploadConfig?.blnIsMultiFile === undefined || objUploadConfig?.blnIsMultiFile === null) {
    objUploadConfig.blnIsMultiFile = false;
  }

  if (objUploadConfig?.blnReturnFullResponse === undefined || objUploadConfig?.blnReturnFullResponse === null) {
    objUploadConfig.blnReturnFullResponse = true;
  }

  if (!objTestingConfig?.strPageInfo) {
    objTestingConfig.strPageInfo = "";
  }

  if (!objTestingConfig?.blnIsLocalStorageEncrypted) {
    objTestingConfig.blnIsLocalStorageEncrypted = false;
  }
  if (!objTestingConfig?.strLocalStorageEncryptKey) {
    objTestingConfig.strLocalStorageEncryptKey = "secret";
  }
  if (!objTestingConfig?.blnIsServerFile) {
    objTestingConfig.blnIsServerFile = false;
  }

  try {
    let userData = [];
    let userFiles = [];
    Array.from(lstFiles).forEach((file, intIndex) => {
      let newFileName = !objUploadConfig?.newFileName ? null : objUploadConfig?.newFileName;

      if (Array.isArray(newFileName)) {
        if (newFileName.length !== lstFiles.length) {
          newFileName = null;
        } else {
          newFileName = objUploadConfig?.newFileName?.[intIndex];
        }
      }

      let currentFile = file;

      userFiles.push(currentFile);

      userData.push({
        fileType: fileType, //!!IMPORTANT upload type goes here
        subDirPath: !subDirPath ? "" : subDirPath,
        fileNewName: newFileName ? newFileName : currentFile.name,
        fileOriginalName: currentFile.name,
        fileSizeByte: currentFile.size,
      });
    });

    if (objTestingConfig?.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig?.strPageInfo + "*------------------( userFiles )----------------------------");
      console.log("*");
      console.log(userFiles);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    if (objTestingConfig?.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig?.strPageInfo + "*------------------( userData )----------------------------");
      console.log("*");
      console.log(userData);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    const result = await fetchFiles(strUploadFileLink, userData, userFiles, strRequester, objTestingConfig);
    if (!result || result === undefined || result === null || result.error || !Array.isArray(result)) {
      return false;
    }
    let blnIsSaved = true;
    for (let i = 0; i < result.length; i++) {
      if (String(result[i].isSaved) !== String(true)) {
        blnIsSaved = false;
        break;
      }
    }

    if (!blnIsSaved) {
      return false;
    }

    let strFileNameInDB = result;
    if (!objUploadConfig?.blnReturnFullResponse) {
      strFileNameInDB = result[0].fileNameToSaveInDB;
    }

    if (objUploadConfig?.blnIsMultiFile) {
      let lstTmp = [];

      for (let i = 0; i < result.length; i++) {
        if (!objUploadConfig?.blnReturnFullResponse) {
          lstTmp.push(result[i].fileNameToSaveInDB);
        } else {
          lstTmp.push(result[i]);
        }
        strFileNameInDB = lstTmp;
      }
    }

    return strFileNameInDB;
  } catch (error) {
    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig?.strPageInfo + "*------------------( uploadFile-ERROR )----------------------------");
      console.log("*");
      console.error(error);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    return false;
  }
}

export async function fetchGenericPost(strURL, strPageInfo, body) {
  try {
    cbmisTest.logMessage(strPageInfo, "Request", body);
    const res = await fetch(strURL, {
      method: "POST",
      body: JSON.stringify({ ...body }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();

    let response = json;

    cbmisTest.logMessage(strPageInfo, " Response", json);

    return response;
  } catch (error) {
    cbmisTest.logMessage("@fetchGenericPost>fetchGenericPost - (Server Response Error)", strPageInfo, error);
    return { error: "400 Error :" + error };
  }
}

function generatePatternID() {
  const element = [
    ["q", "m", "p", "z"],
    ["u", "x", "c", "v"],
    ["W", "V", "Y", "N", "O"],
    ["$", "%"],
    ["2", "3", "9", "7"],
    [">", ")", "!"],
    ["t", "u", "i", "o", "l"],
    ["f", "g", "y", "r"],
    ["H", "T", "B", "I"],
    ["#", "*"],
    ["0", "1"],
    ["K", "E", "T", "G"],
    ["b", "j", "o", "w", "a"],
    ["{", ")", "@"],
    ["+", "_", "/"],
    ["6", "7", "2", "8", "1"],
    ["a", "h", "l", "<", ";"],
    ["~", "3", "&", "m", "5"],
    ["|", "*", "2", "v", "9", "s"],
    ["%", "1", "8", "t", "q", "z", "7", "@", "-"],
  ];

  let result = "";

  for (let i = 0; i < element.length; i++) {
    result += element[i][Math.floor(Math.random() * element[i].length)];
  }

  return result;
}

function setAppLocalStorage(appStateObject, strLocalStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  let plainTextLocalStorage = JSON.stringify(appStateObject);

  if (blnIsEncrypted) {
    let cipherTextLocalStorage = encryptText(plainTextLocalStorage, strEncryptKey);
    localStorage.setItem(strLocalStorageName, cipherTextLocalStorage);
  } else {
    localStorage.setItem(strLocalStorageName, plainTextLocalStorage);
  }
}

function getAppLocalStorage(strLocalStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  try {
    let txtLocalStorage = localStorage.getItem(strLocalStorageName);

    if (blnIsEncrypted) {
      let plainTextLocalStorage = decryptText(txtLocalStorage, strEncryptKey);
      return appFunctions.checkParseObject(plainTextLocalStorage);
    } else {
      return appFunctions.checkParseObject(txtLocalStorage);
    }
  } catch (e) {
    return {};
  }
}

function encryptText(strPlaintText, strKeyOfEncrypt) {
  // Encrypt
  let cipherText = String(CryptoJS.AES.encrypt(strPlaintText, strKeyOfEncrypt));
  return cipherText;
}

function decryptText(strCipherText, strKeyOfDecrypt) {
  //Decrypt
  let bytes = CryptoJS.AES.decrypt(strCipherText, strKeyOfDecrypt);
  let plaintText = bytes.toString(CryptoJS.enc.Utf8);

  return plaintText;
}

function getDifferenceBetweenTwoDate(dtmOldestDate, dtmNewestDate, strIntervalReturnValue) {
  try {
    const intTime2 = new Date(dtmNewestDate).getTime();
    const intTime1 = new Date(dtmOldestDate).getTime();
    const diffTime = intTime2 - intTime1;

    switch (strIntervalReturnValue) {
      case "second":
        return diffTime / 1000;
      case "minute":
        return diffTime / 1000 / 60;
      case "hour":
        return diffTime / 1000 / 60 / 60;
      case "day":
        return diffTime / 1000 / 60 / 60 / 24;
      case "month":
        return diffTime / 1000 / 60 / 60 / 24 / 30.4375;
      case "year":
        return diffTime / 1000 / 60 / 60 / 24 / 30.4375 / 12;
      default:
        return Number.NaN;
    }
  } catch (error) {
    return Number.NaN;
  }
}

export async function fetchPostRestful(
  strURL,
  objBody,
  strRequester,
  objTestingConfig = { blnIsTestingMode: true, strPageInfo: "", blnIsLocalStorageEncrypted: false, strLocalStorageEncryptKey: "secret", signal: null }
) {
  try {
    if (!objTestingConfig?.strPageInfo) {
      objTestingConfig.strPageInfo = "";
    }
    if (!objTestingConfig?.blnIsLocalStorageEncrypted) {
      objTestingConfig.blnIsLocalStorageEncrypted = false;
    }
    if (!objTestingConfig?.strLocalStorageEncryptKey) {
      objTestingConfig.strLocalStorageEncryptKey = "secret";
    }

    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig.strPageInfo + "*------------------( routingPath" + strRequester.routingPath + " )----------------------------");
      console.log("*");
      console.log(objBody);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    let objReqCounter = null;
    if (localStorage.hasOwnProperty("SRPM") && localStorage.getItem("SRPM") !== null) {
      objReqCounter = getAppLocalStorage("SRPM", objTestingConfig?.blnIsLocalStorageEncrypted, objTestingConfig?.strLocalStorageEncryptKey);
    }

    if (!objReqCounter || !objReqCounter?.rqNb || !objReqCounter?.lasR) {
      objReqCounter = {
        rqNb: 1,
        lasR: new Date(),
      };
    } else {
      if (getDifferenceBetweenTwoDate(objReqCounter.lasR, new Date(), "minute") > 1) {
        objReqCounter = {
          rqNb: 1,
          lasR: new Date(),
        };
      } else {
        ++objReqCounter.rqNb;
      }
    }

    setAppLocalStorage(objReqCounter, "SRPM", objTestingConfig?.blnIsLocalStorageEncrypted, objTestingConfig?.strLocalStorageEncryptKey);

    let header = new Headers();
    header.append("Content-Type", "application/json; charset=utf-8");
    if (strRequester?.token) {
      header.append("Authorization", `Bearer ${strRequester.token}`);
    } else {
      strRequester.token = generatePatternID();
      header.append("Authorization", `Bearer ${strRequester.token}`);
    }

    const res = await fetch(strURL, {
      method: "POST",
      ...(objTestingConfig?.signal ? { signal: objTestingConfig.signal } : {}),
      body: JSON.stringify({
        ...objBody,
        strRequester: `${strRequester.appName}*-$-*routingPath*-$-*${strRequester.routingPath}*-$-*${strRequester.bigUserID}*-$-*${strRequester.bigUserRoleID}*-$-*${strRequester.bigFeatureID}*-$-*${objReqCounter.rqNb}`,
      }),
      headers: header,
    });
    const json = await res.json();

    let response = json;

    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig.strPageInfo + "*------------------( routingPath" + strRequester.routingPath + " )----------------------------");
      console.log("*");
      console.log(response);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    return response;
  } catch (error) {
    if (objTestingConfig.blnIsTestingMode) {
      console.log(" ");
      console.log(objTestingConfig.strPageInfo + "*------------------( Error Fetch Data )----------------------------");
      console.log("*");
      console.error(error);
      console.log("*");
      console.log("**********************************************************************************************************");
      console.log(" ");
    }

    if (objTestingConfig.blnIsTestingMode) {
      return error instanceof SyntaxError ? "Server block the request." : error?.message ? error?.message : error;
    }
  }
}


