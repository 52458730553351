import { App_Server_Url_DownloadFiles } from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";

const strPageInfo = "@src/client/component/stakeholders/admin/mngRegForum/components/selectNameCoordinate/selectNameCoordinate.controller.js";
export default function selectNameCoordinateCtrl({ appState }) {
  //const fontCanvas = `bold 72px "MonteCarlo", cursive`;
  //const fontCharWidth = (blnIsArabic) => (blnIsArabic ? 26 : 26);

  const handler = {
    initCanvas:
      ({ state, setState }) =>
      () => {
        try {
        } catch (error) {
          appFunctions.logError(strPageInfo, "initCanvas-ERROR", error);
        }
      },

    getCanvasRef: ({ imageID, canvasID }) => {
      try {
        //get image reference
        /**
         * @type {HTMLImageElement}
         */
        let image = document.getElementById(imageID);
        let imageOffset = image.getBoundingClientRect();

        // get references to the canvas and context
        /**
         * @type {HTMLCanvasElement}
         */
        let canvas = document.getElementById(canvasID);
        let ctx = canvas.getContext("2d");

        
        //canvas.width = imageOffset.width;
        //canvas.height = imageOffset.height;
        canvas.style.position = "absolute";
        canvas.style.direction = "ltr";
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.style.top = imageOffset.top;
        canvas.style.left = imageOffset.left;
        canvas.style.right = imageOffset.right;
        canvas.style.bottom = imageOffset.bottom;

        // style the context
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;

        // calculate where the canvas is on the window
        let canvasOffset = canvas.getBoundingClientRect();
        let offsetX = canvasOffset.left;
        let offsetY = canvasOffset.top;

        return {
          offsetX,
          offsetY,
          ctx,
          width: canvas.width,
          height: canvas.height,
          canvas,
          imageBoundary: {
            top: imageOffset?.top,
            left: imageOffset?.left,
            right: imageOffset?.right,
            bottom: imageOffset?.bottom,
            width: image.width,
            height: image.height,
          },
        };
      } catch (error) {
        appFunctions.logError(strPageInfo, "getCanvasRef-ERROR", error);
        return { offsetX: 0, offsetY: 0, ctx: {}, width: 0, height: 0 };
      }
    },

    handleMouseDown:
      ({ state, canvasRef, scrollRef }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */
      (event) => {
        let baseEvent = event;
        let blnIsPassive = false;
        if (event?.targetTouches && event?.targetTouches?.length) {
          if (event?.targetTouches?.length < 2) {
            return;
          }
          baseEvent = event?.targetTouches?.[0];
          blnIsPassive = true;
        }

        if (!blnIsPassive) {
          event.preventDefault();
        }
        event.stopPropagation();

        const canvas = canvasRef.current;
        // save the starting x/y of the rectangle
        state.startX = Number(baseEvent.clientX + scrollRef?.current?.scrollLeft - canvas?.offsetX);
        state.startY = Number(baseEvent.clientY + scrollRef?.current?.scrollTop - canvas?.offsetY);

        // set a flag indicating the drag has begun
        state.isDown = true;
        //setState({ ...state });
      },

    handleMouseUp:
      ({ state }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */ (event) => {
        let baseEvent = event;
        let blnIsPassive = false;
        if (event?.targetTouches && event?.targetTouches?.length) {
          if (event?.targetTouches?.length < 2) {
            return;
          }
          baseEvent = event?.targetTouches?.[0];
          blnIsPassive = true;
        }

        if (!blnIsPassive) {
          event.preventDefault();
        }
        event.stopPropagation();

        // the drag is over, clear the dragging flag
        state.isDown = false;
      },

    handleMouseOut:
      ({ state }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */ (event) => {
        let baseEvent = event;
        let blnIsPassive = false;
        if (event?.targetTouches && event?.targetTouches?.length) {
          if (event?.targetTouches?.length < 2) {
            return;
          }
          baseEvent = event?.targetTouches?.[0];
          blnIsPassive = true;
        }

        if (!blnIsPassive) {
          event.preventDefault();
        }
        event.stopPropagation();

        // the drag is over, clear the dragging flag
        state.isDown = false;
      },

    handleMouseMove:
      ({ state, setState, canvasRef, scrollRef, fields }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */
      (event) => {
        try {
          let baseEvent = event;
          let blnIsPassive = false;
          if (event?.targetTouches && event?.targetTouches?.length) {
            if (event?.targetTouches?.length < 2) {
              return;
            }
            baseEvent = event?.targetTouches?.[1];
            blnIsPassive = true;
          }

          if (!blnIsPassive) {
            event.preventDefault();
          }
          event.stopPropagation();

          // if we're not dragging, just return
          if (!state.isDown) {
            return;
          }
          const canvas = canvasRef.current;

          // get the current mouse position
          let mouseX = Number(baseEvent.clientX + scrollRef?.current?.scrollLeft - canvas?.offsetX);
          let mouseY = Number(baseEvent.clientY + scrollRef?.current?.scrollTop - canvas?.offsetY);

          // Put your mousemove stuff here

          // clear the canvas
          canvas.ctx.clearRect(0, 0, canvas.width, canvas.height);

          // calculate the rectangle width/height based
          // on starting vs current mouse position
          let width = mouseX - state.startX;
          let height = mouseY - state.startY;

          // draw a new rect from the start position
          // to the current mouse position
          canvas.ctx.strokeRect(state.startX, state.startY, width, height);

          const fontSize = Number(fields.strFontSize.getValue()) || 72;

          canvas.ctx.font = `bold ${fontSize}px "MonteCarlo", cursive`;
          const fontCharWidth = (blnIsArabic) => (blnIsArabic ? fontSize / 2.75 : fontSize / 2.75);

          const str = fields.strTestLabel.getValue() || "Test Label";

          let blnIsArabic = false;
          if (/[\u0600-\u06FF]/.test(str)) {
            blnIsArabic = true;
          }

          state.mouseX = mouseX;
          state.mouseY = mouseY;
          state.ctxWidth = width;
          state.ctxHeight = height;


          canvas.ctx.textAlign  = "center"
          canvas.ctx.fillStyle = fields.strFontColor.getValue() || "#324476";

          //let wordWidth = str.length * fontCharWidth(blnIsArabic);
          //let intStartX = state?.ctxWidth < 0 ? (Math.abs(state?.ctxWidth) < wordWidth ? state.mouseX : state?.startX - wordWidth) : state?.startX;
          //let intPadding = state?.ctxWidth / 2 + (state?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
          //intPadding = (state?.ctxWidth < 0 && intPadding >= 0) || (state?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
          //intStartX = intStartX + intPadding;

          canvas.ctx.fillText(str, state?.startX + state.ctxWidth / 2, state?.startY + state?.ctxHeight / 1.5, Math.abs(state?.ctxWidth));

          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "handleMouseMove-ERROR", error);
        }
      },

    handleScrollRegion:
      ({ scrollRef }) =>
      /**
       *
       * @param {React.UIEvent<HTMLDivElement, UIEvent>} event
       */
      (event) => {
        scrollRef.current.scrollTop = event?.target?.scrollTop;
        scrollRef.current.scrollLeft = event?.target?.scrollLeft;
      },

    previewPhoto:
      ({ state, setState, scrollRef, imageID, canvasID, canvasRef, imageRef, fields }) =>
      (fileCertificate, funSelectDone = null, defaultInfo, blnIsBadge) =>
      /**
       *
       * @param {React.ChangeEvent<HTMLInputElement>} event
       */
      async (event) => {
        try {
          state.startX = defaultInfo?.startX ? defaultInfo?.startX : null;
          state.startY = defaultInfo?.startY ? defaultInfo?.startY : null;
          state.mouseX = defaultInfo?.mouseX ? defaultInfo?.mouseX : null;
          state.mouseY = defaultInfo?.mouseY ? defaultInfo?.mouseY : null;
          state.ctxWidth = defaultInfo?.ctxWidth ? defaultInfo?.ctxWidth : null;
          state.ctxHeight = defaultInfo?.ctxHeight ? defaultInfo?.ctxHeight : null;
          state.isDown = false;
          scrollRef.current.scrollTop = 0;
          scrollRef.current.scrollLeft = 0;
          state.funSelectDone = funSelectDone;
          state.blnIsBadge = blnIsBadge ? true : false;

          fields.strTestLabel.setValue("محمد أحمد عبدالله محمود");
          fields.strFontSize.setValue(Number(defaultInfo?.fontSize) ? defaultInfo?.fontSize : "72");
          fields.strFontColor.setValue(defaultInfo?.fontColor ? defaultInfo?.fontColor : "#324476");

          if (window?.innerWidth <= 900) {
            let viewport = document.querySelector('meta[name="viewport"]');
            if (viewport) {
              viewport.content = "width=1280, user-scalable=no";
            }
          }

          if (fileCertificate?.blnIsNew) {
            const file = fileCertificate;
            if (file) {
              state.blnOpenCanvasModal = true;
              setState({ ...state });
              const fileReader = new FileReader();
              fileReader.onload = function (eventReader) {
                const preview = document.getElementById(imageID);
                imageRef.current = eventReader?.target?.result;
                preview.setAttribute("src", eventReader?.target?.result);
              };
              fileReader.onloadend = function (eventReader) {
                canvasRef.current = handler.getCanvasRef({ canvasID, imageID });

                if (state.startX || state.startY || state.mouseX || state.mouseY || state.ctxWidth || state.ctxHeight) {
                  handler.setDefaultValue({ state, canvasRef, fields });
                }
              };
              fileReader.readAsDataURL(file);
            }
          } else {
            if (fileCertificate) {
              state.blnOpenCanvasModal = true;
              setState({ ...state });

              const imgSrc = App_Server_Url_DownloadFiles + fileCertificate?.path;
              const imgPrev = new Image();
              imgPrev.src = imgSrc;
              imgPrev.onload = function (event) {
                const preview = document.getElementById(imageID);
                preview.setAttribute("src", imgSrc);

                imageRef.current = preview;
                canvasRef.current = handler.getCanvasRef({ canvasID, imageID });
                if (state.startX || state.startY || state.mouseX || state.mouseY || state.ctxWidth || state.ctxHeight) {
                  handler.setDefaultValue({ state, canvasRef, fields });
                }
              };
            }
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "previewPhoto-ERROR", error);
        }
      },

    setDefaultValue: ({ state, fields, canvasRef }) => {
      try {
        const canvas = canvasRef.current;

        // clear the canvas
        canvas.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // draw a new rect from the start position
        // to the current mouse position
        canvas.ctx.strokeRect(state.startX, state.startY, state.ctxWidth, state.ctxHeight);

        const fontSize = Number(fields.strFontSize.getValue()) || 72;

        canvas.ctx.font = `bold ${fontSize}px "MonteCarlo", cursive`;
        const fontCharWidth = (blnIsArabic) => (blnIsArabic ? fontSize / 2.75 : fontSize / 2.75);

        const str = fields.strTestLabel.getValue() || "Test Label";

        let blnIsArabic = false;
        if (/[\u0600-\u06FF]/.test(str)) {
          blnIsArabic = true;
        }

        canvas.ctx.textAlign  = "center"
        canvas.ctx.fillStyle = fields.strFontColor.getValue() || "#324476";

        //let wordWidth = str.length * fontCharWidth(blnIsArabic);
        //let intStartX = state?.ctxWidth < 0 ? (Math.abs(state?.ctxWidth) < wordWidth ? state.mouseX : state?.startX - wordWidth) : state?.startX;
        //let intPadding = state?.ctxWidth / 2 + (state?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
        //intPadding = (state?.ctxWidth < 0 && intPadding >= 0) || (state?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
        //intStartX = intStartX + intPadding;

        canvas.ctx.fillText(str, state?.startX + state.ctxWidth / 2, state?.startY + state?.ctxHeight / 1.5, Math.abs(state?.ctxWidth));
      } catch (error) {
        appFunctions.logError(strPageInfo, "setDefaultValue-ERROR", error);
      }
    },
    
  };

  return handler;
}
