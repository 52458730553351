import React from "react";
import { Button, Grid, Icon, Line, Typography } from "@cbmisorg/client-app";
import { App_Primary_Color, App_orang_color } from "../../../../../appHelper/appColor";
import { appLangDirection, dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as appVariables from "../../../../../appHelper/appVariables";
import * as appFunctions from "../../../../../appHelper/appFunctions";

const labels = dictionary.components.mngPotentialSponsorship;

function Info({ appState, fields, handlers, state, setState, blnFollowUp, objTracking, intIndex }) {
  const lang = appState?.clientInfo?.strLanguage;
  const openEditDialog = handlers.openEditDialog({ fields, setState, state });
  const getAddedBeforeLabel = handlers.getAddedBeforeLabel({ state });
  const openLogDialog = handlers.openLogDialog({ state, setState });
  const confirmClose = handlers.confirmClose({ state, setState, fields });

  
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            as="caption"
            sx={{
              borderInlineStart: `5px solid ${App_Primary_Color} !important`,
              paddingInlineStart: "8px !important",
              color: App_Primary_Color,
              fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
            }}
          >
            {dictionary?.components?.public?.join?.partnerInfo?.[lang]}
          </Typography>
        </Grid>

        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
          <Typography as="caption">
            <b>{dictionary.formapi.strSponsorEmail?.label?.[lang]}</b> :{" "}
            {!objTracking?.jsnPartnershipTrackInfo?.strSponsorEmail ? (
              "-"
            ) : (
              <a target="_blank" href={`mailto:${objTracking?.jsnPartnershipTrackInfo?.strSponsorEmail}`}>
                {objTracking?.jsnPartnershipTrackInfo?.strSponsorEmail}
              </a>
            )}
          </Typography>
        </Grid>

        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
          <Typography as="caption">
            <b>{dictionary.formapi.strSponsorPhone?.label?.[lang]}</b> :{" "}
            {!objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone ? (
              "-"
            ) : (
              <a target="_blank" href={`tel:${objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone}`}>
                {appLangDirection?.[lang] === "rtl"
                  ? `${objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone} ${appVariables.globalKey}`
                  : `+${appVariables.globalKey.replace("+", "")} ${objTracking?.jsnPartnershipTrackInfo?.strSponsorPhone}`}
              </a>
            )}
          </Typography>
        </Grid>

        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
          <Typography as="caption">
            <b>{labels?.sponsorURl?.[lang]}</b> :  {` `}
            {!objTracking?.jsnPartnershipTrackInfo?.strURL ? (
              "-"
            ) : (
              <a target="_blank" href={objTracking?.jsnPartnershipTrackInfo?.strURL}>
               {labels?.moveLink?.[lang]}
              </a>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Line pt-3 pb-2 />
      <Grid container>
        <Grid item xs={12}>
          <Typography
            as="caption"
            sx={{
              borderInlineStart: `5px solid ${App_Primary_Color} !important`,
              paddingInlineStart: "8px !important",
              color: App_Primary_Color,
              fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
            }}
          >
            {labels?.recipientInfo?.[lang]}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
          <Typography as="caption">
            <b>{dictionary.formapi.strFullNameRecipient?.label?.[lang]}</b> :{" "}
            {objTracking?.jsnPartnershipTrackInfo?.strFullName ? (
              <span style={{ color: App_orang_color }}>{objTracking?.jsnPartnershipTrackInfo?.strFullName}</span>
            ) : (
              "-"
            )}
          </Typography>
        </Grid>

        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
          <Typography as="caption">
            <b>{dictionary.formapi.strUserEmailRecipient?.label?.[lang]}</b> :{" "}
            {!objTracking?.jsnPartnershipTrackInfo?.strUserEmail ? (
              "-"
            ) : (
              <a target="_blank" href={`mailto:${objTracking?.jsnPartnershipTrackInfo?.strUserEmail}`}>
                {objTracking?.jsnPartnershipTrackInfo?.strUserEmail}
              </a>
            )}
          </Typography>
        </Grid>

        <Grid item md={12} xs={12} sx={{ xs: { justifyContent: "flex-start !important" } }}>
          <Typography as="caption">
            <b>{dictionary.formapi.strUserPhoneRecipient?.label?.[lang]}</b> :{" "}
            {!objTracking?.jsnPartnershipTrackInfo?.strUserPhone ? (
              "-"
            ) : (
              <a target="_blank" href={`tel:${objTracking?.jsnPartnershipTrackInfo?.strUserPhone}`}>
                {appLangDirection?.[lang] === "rtl"
                  ? `${objTracking?.jsnPartnershipTrackInfo?.strUserPhone} ${appVariables.globalKey}`
                  : `+${appVariables.globalKey.replace("+", "")} ${objTracking?.jsnPartnershipTrackInfo?.strUserPhone}`}
              </a>
            )}
          </Typography>
        </Grid>
      </Grid>
      {!blnFollowUp && !(Array.isArray(objTracking?.jsnTrackingHistory) && objTracking?.jsnTrackingHistory?.length > 0) ? null : <Line pt-3 pb-2 />}

      {Array.isArray(objTracking?.jsnTrackingHistory) && objTracking?.jsnTrackingHistory?.length > 0 && (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                as="caption"
                sx={{
                  borderInlineStart: `5px solid ${App_Primary_Color} !important`,
                  paddingInlineStart: "8px !important",
                  color: App_Primary_Color,
                  fontFamily: "'Hand Font', 'Reem Kufi', sans-serif !important",
                }}
              >
                {labels?.history?.[lang]}
              </Typography>
            </Grid>
            {[...objTracking?.jsnTrackingHistory]?.reverse()?.map((item, index) => (
              <Grid item xs="12" container key={index}>
                <Grid item xs="11" md="auto">
                  <Typography as="caption">
                    {appFunctions.getDateUSFormat(item?.dtmCreatedDate, true)}
                    &nbsp;{getAddedBeforeLabel(item)}
                  </Typography>
                </Grid>

                {!item?.strNotes ? null : (
                  <Grid item xs="1">
                    <Icon icon="notes" color="primary" onClick={openLogDialog(item)} />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      )}
      {blnFollowUp ? (
        <>
          <Line pt-3 pb-5 />
          <Grid container justify="center">
            <Grid item>
              <Button size="sm" label={labels?.Follow_UpAgain?.[lang]} onClick={openEditDialog(objTracking, intIndex, true, true)} />
            </Grid>
            <Grid item>
              <Button size="sm" label={labels?.close?.[lang]} color={App_orang_color} py-1 onClick={confirmClose(intIndex)} />
            </Grid>
          </Grid>
        </>
      ) : null}
    </React.Fragment>
  );
}

export default Info;
