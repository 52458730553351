import React, { useEffect, useRef } from "react";
import "./Chip.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import Avatar from "../Avatar/Avatar";

const Chip = (props) => {
  const { label, mode, size, color, avatar, endAvatar, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Chip"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [label, size, avatar, endAvatar, hidden, disabled, dir, className, id]);

  useEffect(() => {
    const chipStyle = String(mode).toLowerCase();
    setSxClassName(
      styleRef,
      getID.current,
      {
        ...(sx ? { ...sx } : {}),
        ...(chipStyle !== "outlined"
          ? { background: `${color} !important` }
          : {
              borderColor: `"var(--cbmis-whait)" !important`,
              color: `${color} !important`,
              background: `transparent !important`,
            }),
      },
      "cbmis-chip"
    );
  }, [sx, screenWidth, color, mode]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getChipStyle = () => {
    const chipStyle = String(mode).toLowerCase();
    switch (chipStyle) {
      case "outlined":
        return "cbmis-chip-outlined";
      default:
        return "cbmis-chip-filled";
    }
  };

  const getChipSize = () => {
    const chipSize = String(size).toLowerCase();
    switch (chipSize) {
      case "sm":
        return { chipSize: "cbmis-chip-size-sm", iconSize: "15px" };
      default:
        return { chipSize: "cbmis-chip-size-md", iconSize: "20px" };
    }
  };

  return (
    <div
      id={getID.current}
      className={`cbmis-chip ${props.onClick ? "cbmis-chip-active" : ""} ${getChipStyle()} ${getChipSize().chipSize} ${componentsFunction.CheckDirectionItem(
        dir
      )} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
        rest
      )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {avatar ? (
        <Avatar alt={avatar.props.alt} src={avatar.props.src} bgcolor={avatar.props.bgcolor} shape={avatar.props.shape} sx={{ width: 24, height: 24 }} {...avatar.props}>
          {avatar.props.children}
        </Avatar>
      ) : (
        ""
      )}
      <div className="cbmis-chip-content">{label}</div>
      {endAvatar ? (
        <Avatar
          alt={endAvatar.props.alt}
          src={endAvatar.props.src}
          bgcolor={endAvatar.props.bgcolor}
          shape={endAvatar.props.shape}
          sx={{ width: 24, height: 24 }}
          {...endAvatar.props}
        >
          {endAvatar.props.children}
        </Avatar>
      ) : (
        ""
      )}
    </div>
  );
};

export default Chip;
