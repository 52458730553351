import { useNavigate } from "@cbmisorg/router";
import React, { useState } from "react";
import { useApp } from "../../../configuration/contextapi/context";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./surveymonkey.style";
import ShredHeader from "../../shared/shredHeader";
import { Button, Container, Grid, Loader, Typography } from "@cbmisorg/material";
import { App_info_Color } from "../../../util/appStyle";
import { dictionary } from "../../../util/appLanguage/dictionary";
import robot1 from "../../../util/assets/images/robot1.png";

const labels = dictionary?.components?.stakeholders?.admin?.mngSurvey;

function SurveymonkeyThankView() {
  const { appState } = useApp();
  const navigate = useNavigate();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading] = useState(false);
  

  return (
    <React.Fragment>
      <ShredHeader lang={lang} blnBack={false} sx={{ position: "sticky" }} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer} py-3>
          <Grid container justifyContent={"center"} alignSelf="center" mt-5>
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container}>
                <Grid container justifyContent="center" py-2>
                  <img src={robot1} alt="robotImg" width={"90px"} style={{ height: "fit-content", margin: "auto" }} />
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xs="12" justifyContent="center">
                    <Typography as="body2" className={`${classes?.labelsLight}`}>
                      {labels?.surveyThank?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      label={"www.AAIOT.org/Kuwait"}
                      mode="link"
                      color={App_info_Color}
                      onClick={()=>navigate("/kuwait")}
                      className={classes?.btn}
                      sx={{ textTransform: "none", textDecoration: "underline" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default SurveymonkeyThankView;
