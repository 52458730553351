import React from "react";
import { App_Beige_Color4, App_Primary_Color, App_Second_Color } from "../../../appHelper/appColor";
import { Container, Paper } from "@cbmisorg/client-app";

function PaperSection(props) {


  const style = {
  
    width:"100%",
    borderColor:App_Primary_Color,
    background:App_Beige_Color4,
    borderRadius:".8rem",
    ...props.style,
    ...props.sx

  };

  const funClicked = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
   <Container width={"fluid"} p-0 m-0>
      <Paper elevation={props.elevation || "0"} outline={props?.outline || 0} style={style} sx={style} onClick={funClicked} {...props.rest}>
        {props.children}
      </Paper>
   </Container>
  );
}

export default PaperSection;
