import React, { useEffect, useState } from 'react'
import { getDifferenceBetweenTwoDate, getNextStrURL } from '../../../appHelper/appFunctions'
import objAppRouting from '../../../appHelper/routing/appRouting'
import { Grid, Icon, Modal, ModalHeader, ModalBody, Note, useNavigate, Paper, Avatar, Line, Typography, Button, Alert,} from '@cbmisorg/client-app';
import { dictionary } from '../../../appHelper/appLanguage/dictionary';
import { App_Beige_Color3, App_Primary_Color } from '../../../appHelper/appColor';
import { FormData, FormField } from '../../../appHelper/formApi/FormAPI';
import { App_Server_Url_DownloadFiles, degreeID, employmentStatusID, genderID, globalKey, nationalityID, objUploadType, objUserRole,} from '../../../appHelper/appVariables';
import Uploader from '../uploader/Uploader';
import profilefemail from '../../../assets/images/profilefemail.jpg'
import profilemail from '../../../assets/images/profilemail.jpg'
const labels = dictionary?.components?.manageMembers

function UserForm({handlers,objSystemInfo,userInfo,lang, stateInfo, setStateInfo, blnCall,blnActive,blnRest=true, blnChangImg=false}) {
  
  const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const uploaderImg = Uploader(stateInfo, setStateInfo,"imgProfile", "", {blnIsMultiple: false, blnForceNewName: true, strSpecificName: userInfo?.bigUserID, fileType: objUploadType?.["aaiot"].id}, "cameraAlt",false, {
      position: "absolute",
      top: "-5px",
      left: lang === "arb"? 0 :"-7px",
      right: lang !== "arb"? 0 :"-7px",
      zIndex: 1,
    })

    const fields = FormData({
        strFullName: {
          strControl: "text",
          objLanguage: false,
          objValidations: { required: true },
        },
        strPhone: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: true ,onlyNumbers:true, minLength:{condition:8}, maxLength:{condition:8}},
        },
        dtmDob: {
            strControl: "date",
            objLanguage: false,
            objValidations: { required: true , },
        },
        bigNationality: {
            strControl: "list",
            objLanguage: false,
            objValidations: { required: true },
      lstOption:[
        {key:nationalityID?.kuwaiti, value:dictionary?.shared?.account?.country?.kwt?.[lang]},
        {key:nationalityID?.notKuwaiti, value:dictionary?.shared?.account?.country?.notKwt?.[lang]}, 
      ]
        },
        bigGender: {
            strControl: "list",
            objLanguage: false,
            objValidations: { required: true },
      lstOption:[
        {key:genderID.male, value:dictionary?.shared?.account?.gender?.male?.[lang]},
        {key:genderID.female, value:dictionary?.shared?.account?.gender?.female?.[lang]}, 
      ]
        },
        bigDegreeID: {
            strControl: "list",
            objLanguage: false,
            objValidations: { required: {condition:()=> {return userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.member}} },
      lstOption: [
        {key:degreeID.diploma, value:dictionary?.shared?.account?.degreey?.diploma?.[lang]},
        {key:degreeID.bachelor,value:dictionary?.shared?.account?.degreey?.bachelor?.[lang]},
        {key:degreeID.masters,value:dictionary?.shared?.account?.degreey?.masters?.[lang]},
        {key:degreeID.doctorate,value:dictionary?.shared?.account?.degreey?.doctorate?.[lang]},
      ]
        },
        strMajor: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: {condition:()=> {return userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.member}} },
        },
        bigEmploymentID: {
            strControl: "list",
            objLanguage: false,
            objValidations: { required: {condition:()=> {return userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.member}} },
      lstOption:[
        {key:employmentStatusID?.professor, value:dictionary?.components?.public?.landingPage?.members?.professor?.[lang]},
        {key:employmentStatusID?.student, value:dictionary?.components?.public?.landingPage?.members?.student?.[lang]},
        {key:employmentStatusID?.govEmployee, value:dictionary?.components?.public?.landingPage?.members?.govEmployee?.[lang]},
        {key:employmentStatusID?.prvtEmployee, value:dictionary?.components?.public?.landingPage?.members?.prvtEmployee?.[lang]},
        {key:employmentStatusID?.notEmployee, value:dictionary?.components?.public?.landingPage?.members?.notEmployee?.[lang]},
      ]
        },
        strWorkPlace: {
            strControl: "text",
            objLanguage: false,
            objValidations: { required: {condition:()=> {return userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.member}} },
        },
        blnIsActive: {
            strControl: "switch",
            objLanguage: false,
            lstOption:[{key:"0", value:"0"},{key:"1", value:"1"}]
          },
      });
    
      const fieldPassword=FormData({
        strOldUserPassword: {
          strControl: "password",
          objLanguage: false,
          objValidations: { required: true , minLength:{condition:6},},
       },
        strRestPassword: {
          strControl: "password",
          objLanguage: false,
          objValidations: { required: true , minLength:{condition:6},},
       },
        strConfirmPassword: {
          strControl: "password",
          objLanguage: false,
          objValidations: { required: true, minLength:{condition:6} , equal:{condition:"strRestPassword"}},
       },
      });
    
      const fieldEmail=FormData({
        strRestEmail: {
          strControl: "text",
          objLanguage: false,
          objValidations: { required: true , email: true},
      },
      });
    
      async function handleSave() {
       try {
        if(stateInfo?.imgProfile){
          setIsLoading(true)
          const uploadImg = await uploaderImg.uploadHandler(objUploadType?.profile);
          if(!uploadImg){
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantUploadFile?.[lang])
          }
          stateInfo.steImgProfile = stateInfo.imgProfile?.[0]?.name
          stateInfo.imgProfile =[]
          setStateInfo({...stateInfo})
          setIsLoading(false)
        }
        if(!isLoading){
          handlers.funSave(fields,userInfo)
        }
       } catch (error) {
        
       }
     }
     function handleRestPassword() {
      handlers.funRestPassword(fieldPassword,userInfo)
     }
    
     function handleRestEmail() {
      handlers.funRestEmail(fieldEmail,userInfo)
     }

    useEffect(() => {
        const accountUrl = getNextStrURL()
        if(accountUrl !== objAppRouting?.Account?.url && (window.location.pathname === objAppRouting?.profile?.url)){
          navigate(accountUrl, { replace: true })
        }else if(userInfo){
          const memmberInfo=userInfo
          fields?.strFullName?.setValue(memmberInfo?.jsnUserInfo?.strFullName)
          fields?.bigDegreeID?.setValue(memmberInfo?.jsnUserInfo?.bigDegreeID)
          fields?.bigEmploymentID?.setValue(memmberInfo?.jsnUserInfo?.bigEmploymentID)
          fields?.strMajor?.setValue(memmberInfo?.jsnUserInfo?.strMajor)
          fields?.bigNationality?.setValue(memmberInfo?.jsnUserInfo?.bigNationality)
          fields?.bigGender?.setValue(memmberInfo?.jsnUserInfo?.bigGender)
          fields?.strWorkPlace?.setValue(memmberInfo?.jsnUserInfo?.strWorkPlace)
          fields?.dtmDob?.setValue(memmberInfo?.jsnUserInfo?.dtmDob)
          fields?.strPhone?.setValue(memmberInfo?.strUserPhone?.slice(5))
    
          fieldEmail?.strRestEmail?.setValue(memmberInfo?.strUserEmail)
          fields?.blnIsActive?.setValue(memmberInfo?.blnIsActive?{key:"1", value:"1"}:"0")
        }
      }, [])

  return (
    <React.Fragment>
        {
          !blnRest?null:(
            <Grid item xs="12" container justify={'space-between'} spacing={0} pb-3>
                <Grid item xs="auto">
                  <Button icon="AlternateEmail" 
                    label={<Typography as="caption">{labels?.resetEmail?.[lang]}</Typography>} 
                    size="sm" pt-0 mode="outlined" color={App_Primary_Color} onClick={()=>setStateInfo({...stateInfo, modalEmailOpen:true})} />
                </Grid>
                <Grid item xs="auto">
                    <Button icon="vpnKey" 
                    label={<Typography as="caption">{labels?.resetPassword?.[lang]}</Typography>} 
                    size="sm" pt-0 mode="outlined" color={App_Primary_Color} onClick={()=>setStateInfo({...stateInfo, modalOpen:true})} />
                </Grid>
            </Grid>
          )
        }
        <Grid item xs="12">
            {
            objSystemInfo?.jsnSystemConfig?.blnIsPaySubscribed 
            && userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.member
            && userInfo?.jsnPaySubscribed?.dtmSubscribedDeactivate
            && getDifferenceBetweenTwoDate(new Date(), userInfo?.jsnPaySubscribed?.dtmSubscribedDeactivate, "day") <= Number(objSystemInfo?.jsnSystemConfig?.intSubscriptionTimeNotify)
            ? (
                <Grid container justify={'center'} spacing={0} pb-5>
                <Grid item xs="12">
                    <Note py-1 type="error">
                    <Grid container>
                        <Grid item>
                        <Icon icon="info" />
                        </Grid>
                        <Grid item>
                        <Typography as="body1">{labels?.subscription?.[lang]}</Typography>
                        </Grid>
                        <Grid item>
                        <Typography as="subtitle1">{userInfo?.jsnPaySubscribed?.dtmSubscribedDeactivate}</Typography>
                        </Grid>
                    </Grid>
                    </Note>
                </Grid>
                </Grid>
            ):null
            }
            <Paper p-0 className='cardContainer' py-2>
            {
                !blnCall?null:(
                    <React.Fragment>
                        <Grid container justify={'space-between'} >
                            <Grid item px-2>
                            <Icon icon="email" color={App_Primary_Color} onClick={()=>handlers?.funCall(userInfo,"email")} />
                        </Grid>
                        <Grid item px-2>
                            <Icon icon="phone" color={App_Primary_Color} onClick={()=>handlers?.funCall(userInfo,"phone")} />
                        </Grid>
                        </Grid>
                        <Line />
                    </React.Fragment>
                )
            }
            <Grid container>
                <Grid item xs="12" >
                    <Avatar mode={'circle'}  src={
                      stateInfo?.imgProfile?.length? URL.createObjectURL(stateInfo?.imgProfile[0]) 
                      : userInfo?.jsnUserInfo?.strImg? App_Server_Url_DownloadFiles + userInfo?.jsnUserInfo?.strImg : userInfo?.jsnUserInfo?.bigGender === genderID?.female? profilefemail: profilemail
                    } 
                      color={App_Beige_Color3} sx={{width:"75px",height:"75px", backgroundSize:"cover !important", border:"1px solid var(--cbmis-primary)"}}>
                    {!blnChangImg?null:uploaderImg?.JSX}
                    </Avatar>
                </Grid>
            </Grid>
            <Grid container p-1 spacing={2}>
                <Grid item xs={12}>
                <FormField objHandler={fields.strFullName} icon="AccountCircle" />
                </Grid>
                <Grid item xs={12}>
                <FormField objHandler={fields.strPhone} icon={lang==="arb"?"phone":null} endIcon={lang!=="arb"?"phone":null} endAdornment={lang==="arb"?globalKey:null} adornment={lang!=="arb"?globalKey:null}  className={`inputLeft`} />
                </Grid>
                <Grid item xs={12} md="4">
                <FormField objHandler={fields.dtmDob} icon="Cake" dir={lang === "arb"?"rtl":"ltr"} />
                </Grid>
                <Grid item xs={6} md="4">
                <FormField objHandler={fields.bigGender} icon="Wc" dir={lang === "arb"?"rtl":"ltr"}/>
                </Grid>
                <Grid item xs={6} md="4">
                <FormField objHandler={fields.bigNationality} icon="public" dir={lang === "arb"?"rtl":"ltr"} />
                </Grid>
                <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigDegreeID} icon="ImportContacts" dir={lang === "arb"?"rtl":"ltr"}/>
                </Grid>
                <Grid item xs={12} md="6">
                <FormField objHandler={fields.strMajor} icon="School" dir={lang === "arb"?"rtl":"ltr"}/>
                </Grid>
                <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigEmploymentID} icon="Work" dir={lang === "arb"?"rtl":"ltr"}/>
                </Grid>
                <Grid item xs={12} md="6">
                <FormField objHandler={fields.strWorkPlace} icon="business" dir={lang === "arb"?"rtl":"ltr"}/>
                </Grid>
                {
                    !blnActive?null:(
                        <Grid item xs={12} pt-1>
                            <FormField objHandler={fields.blnIsActive}  showValue={false}/>
                        </Grid>
                    )
                }
            </Grid>
            <Grid container spacing={2} justify={'center'}>
                <Grid item xs="10" sm="4" md="3">
                <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} sx={{minWidth:"100%"}} onClick={handleSave}/>
                </Grid>
            </Grid>
            </Paper>
        </Grid>
       


      {!blnRest?null:(
        <React.Fragment>
          <Modal open={stateInfo?.modalOpen} eventClose={handlers?.funCloseModal}>
          <ModalHeader>{labels?.resetPassword?.[lang]}</ModalHeader>
          <ModalBody>
            <Grid container spacing={3}>
                <Grid item xs={12}  sx={{alignSelf:"flex-start"}}>
                  <FormField objHandler={fieldPassword.strOldUserPassword} icon="vpnKey" className={`inputLeft`} dir={lang==="arb"?"rtl":"ltr"}/>
                </Grid>
                <Grid item xs={12} md="6" sx={{alignSelf:"flex-start"}}>
                  <FormField objHandler={fieldPassword.strRestPassword} icon="vpnKey" className={`inputLeft`} dir={lang==="arb"?"rtl":"ltr"}/>
                </Grid>
                <Grid item xs={12} md="6" sx={{alignSelf:"flex-start"}}>
                  <FormField objHandler={fieldPassword.strConfirmPassword} icon="vpnKey" className={`inputLeft`} dir={lang==="arb"?"rtl":"ltr"}/>
                </Grid>
              <Grid item xs="12">
                <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} mx-auto onClick={handleRestPassword} px-3/>
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>

        <Modal open={stateInfo?.modalEmailOpen} eventClose={handlers?.funCloseModal}>
          <ModalHeader>{labels?.resetEmail?.[lang]}</ModalHeader>
          <ModalBody>
            <Grid container spacing={3}> 
              <Grid item xs={12}>
                <FormField objHandler={fieldEmail.strRestEmail} icon={"email"} className={`inputLeft inputLeft${lang}`} />
              </Grid>
              <Grid item xs="12">
                <Button color="primary" size="sm" label={dictionary?.shared?.btn?.save?.[lang]} mx-auto onClick={handleRestEmail} px-3/>
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>
        </React.Fragment>
      )}
      
    </React.Fragment>
  )
}

export default UserForm