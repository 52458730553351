import React, {  useEffect, useState } from 'react'
import { Container, Grid,  Paper, Typography, useLocation, useNavigate, Button, Modal, ModalHeader, ModalBody, Loader, } from '@cbmisorg/client-app';
import { App_Primary_Color } from '../../../../appHelper/appColor';
import { CtrlRequested } from './controler/CtrlRequested';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import { FormData, FormField } from '../../../../appHelper/formApi/FormAPI';
import { appStyle } from '../../../../appHelper/appStyle';
import { CtrlManageMembers } from '../manageMembers/controler/CtrlManageMembers';
import UserForm from '../../../sharedUi/userForm/UserForm';
import { useApp } from '../../../../../client/configuration/contextapi/context';

const labels = dictionary?.components?.mnageRequestJoin

function RouteViewRequestedActive() {
  const { appState,appDispatch } = useApp
  ();
  const lang = appState.clientInfo.strLanguage
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const {state} = useLocation()
  const [stateInfo, setStateInfo] = useState({
      blnIsInitDone:false,
      modalOpen:false,
      modalType:"",
      modalImgPath:"",
      modalTitle:""
  })

  const handlers = CtrlRequested(appState,isLoading, setIsLoading,stateInfo, setStateInfo,navigate);
  const handlersManageMembers = CtrlManageMembers(appState,isLoading, setIsLoading,stateInfo, setStateInfo,navigate);

  const fieldNote = FormData({
    strNote: {
      strControl: "textArea",
    },
  });

  function handleApprove() {
    handlers.funApprove(state?.selected)
 }
  function handleReSubmit() {
    handlers.funReSubmit(state?.selected,fieldNote)
  }

  useEffect(() => {  
    return () => {
      if(!stateInfo?.blnIsInitDone && state?.selected){
        handlers?.viewRequested(state?.selected, appDispatch)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <ShredHeader title={labels?.vewtitle?.[lang]} blnBottomBar={false} lang={lang}/>
      <Container dir="lrt" py-1 >
        {
          isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
        }
        <Grid container justify={'center'} spacing={2}>

        <UserForm
          stateInfo={stateInfo} 
          setStateInfo={setStateInfo} 
          objSystemInfo={appState?.clientInfo?.objSystemInfo} 
          handlers={handlersManageMembers} 
          userInfo={state?.selected} 
          lang={lang}
          blnActive={true}
          blnCall={true}
          blnRest={false}
      />
     <Grid item xs="12">
       <Paper p-0 className='cardContainer'>
         <Grid container justify={'center'}>
             <Grid item xs="12" pt-3 pb-8>
               <Typography as="subtitle1" sx={appStyle?.title2}>{labels?.requiremenFiles?.[lang]}</Typography>
             </Grid>
             <Grid item>
               <Button icon="badge" label={dictionary?.shared?.btn?.viewGovID?.[lang]} size="sm" mode="link" onClick={()=>handlers?.funOpenImg(state?.selected?.jsnUserInfo?.strGovIDFilePath,dictionary?.components?.manageSystem?.govID)}/>
             </Grid>
             <Grid item>
               <Button icon="listAlt" label={dictionary?.shared?.btn?.viewRest?.[lang]} size="sm" mode="link" onClick={()=>handlers?.funOpenImg(state?.selected?.jsnUserInfo?.strReceiptFilePath,dictionary?.components?.manageSystem?.rest)}/>
             </Grid>
         </Grid>

         <Grid container py-3 spacing={2} justify={'center'}>
           <Grid item xs="10" sm="4" md="3">
             <Button color="success" size="sm" label={dictionary?.shared?.btn?.approve?.[lang]} sx={{minWidth:"100%"}} onClick={handleApprove}/>
           </Grid>
           <Grid item xs="10" sm="4" md="3">
             <Button color="primary"  size="sm" label={dictionary?.shared?.btn?.resubmission?.[lang]} sx={{minWidth:"100%"}} onClick={()=>setStateInfo({...stateInfo,modalOpen:true})}/>
           </Grid>
         </Grid>
       </Paper>
     </Grid>

        </Grid>
      </Container>


      <Modal open={stateInfo?.modalOpen} eventClose={handlers?.funCloseModal}>
        <ModalHeader>{stateInfo?.modalTitle?.[lang]}</ModalHeader>
        <ModalBody>
          {
            stateInfo?.modalType==="img"?(
              <Grid container justify={'center'}>
                <Grid item xs="12">
                  <img src={stateInfo?.modalImgPath} alt={stateInfo?.modalTitle?.[lang]} width={"100%"} />
                </Grid>
              </Grid>
            ):(
              <Grid container>
                   <Grid item xs="12">
                    <Typography as="subtitle2">{dictionary?.components?.manageSystem?.noteType?.[lang]}:</Typography>
                  </Grid>
                  <Grid item xs="12" className={lang==="arb"?null:"textareaeng"}>
                    <FormField objHandler={fieldNote?.strNote}  dir="ltr"/>
                </Grid>
                <Grid item xs="12">
                  <Button color="primary" size="sm" label={dictionary?.shared?.btn?.send?.[lang]} mx-auto onClick={handleReSubmit}/>
                </Grid>
              </Grid>
            )
          }
        </ModalBody>
      </Modal>

    </React.Fragment>
  )
}

export default RouteViewRequestedActive