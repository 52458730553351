import { logError, modalOverFllow } from "../../../util/appFunctions";
import * as XLSX from "xlsx";
import { ClientFunction } from "@cbmisorg/helper";
import { updatePkgConfig, FileIDB } from "@cbmisorg/indexdb-api";
import { jsPDF } from "jspdf";
import { fetchFile } from "../../../graphql/fetchData";
import { App_Server_Url_DownloadFiles, objUploadType } from "../../../configuration/appVariables";
import { generateQueries, objTableAttributes } from "../../../graphql/schema/GenerateQuery";
import { Alert } from "@cbmisorg/material";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import appRouting from "../../../configuration/routing/appRouting";
import { generatePath } from "react-router-dom";
import { alertStyle } from "../../../util/appStyle";
import * as appFunctions from "../../../util/appFunctions";
const certificateApi = generateQueries("tblCertificate");

updatePkgConfig({ blnIsInTestMode: true });

const strPageInfo = "@src/client/component/public/eCertificate/eCertificate.controller.js";

export default function eCertificateCtrl({ appState }) {
  const lang = appState?.clientInfo?.strLanguage;
  const maxCertificatePerPatch = 80;
  const fontSizeNameCert = 52;
  // const fontCharWidth = (blnIsArabic) => (blnIsArabic ? fontSizeNameCert / 2.85 : fontSizeNameCert / 2.75);

  const handler = {
    getCanvasRef: ({ imageID, canvasID }) => {
      try {
        //get image reference
        /**
         * @type {HTMLImageElement}
         */
        let image = document.getElementById(imageID);
        let imageOffset = image.getBoundingClientRect();

        // get references to the canvas and context
        /**
         * @type {HTMLCanvasElement}
         */
        let canvas = document.getElementById(canvasID);
        let ctx = canvas.getContext("2d");

        //canvas.width = imageOffset.width;
        //canvas.height = imageOffset.height;
        canvas.style.position = "absolute";
        canvas.style.direction = "ltr";
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.style.top = imageOffset.top;
        canvas.style.left = imageOffset.left;
        canvas.style.right = imageOffset.right;
        canvas.style.bottom = imageOffset.bottom;

        // style the context
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;

        // calculate where the canvas is on the window
        let canvasOffset = canvas.getBoundingClientRect();
        let offsetX = canvasOffset.left;
        let offsetY = canvasOffset.top;

        return {
          offsetX,
          offsetY,
          ctx,
          width: canvas.width,
          height: canvas.height,
          canvas,
          imageBoundary: {
            top: imageOffset?.top,
            left: imageOffset?.left,
            right: imageOffset?.right,
            bottom: imageOffset?.bottom,
            width: image.width,
            height: image.height,
          },
        };
      } catch (error) {
        logError(strPageInfo, "getCanvasRef-ERROR", error);
        return { offsetX: 0, offsetY: 0, ctx: {}, width: 0, height: 0 };
      }
    },

    handleMouseDown:
      ({ state, canvasRef, scrollRef, fields }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */
      (event) => {
        let baseEvent = event;
        let blnIsPassive = false;
        if (event?.targetTouches && event?.targetTouches?.length) {
          if (event?.targetTouches?.length < 2) {
            return;
          }
          baseEvent = event?.targetTouches?.[0];
          blnIsPassive = true;
        }

        if (!blnIsPassive) {
          event.preventDefault();
        }
        event.stopPropagation();

        const canvas = canvasRef.current;
        // save the starting x/y of the rectangle
        state.startX = Number(baseEvent.clientX + scrollRef?.current?.scrollLeft - canvas?.offsetX);
        state.startY = Number(baseEvent.clientY + scrollRef?.current?.scrollTop - canvas?.offsetY);

        // set a flag indicating the drag has begun
        state.isDown = true;
        //setState({ ...state });
      },

    handleMouseUp:
      ({ state }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */ (event) => {
        let baseEvent = event;
        let blnIsPassive = false;
        if (event?.targetTouches && event?.targetTouches?.length) {
          if (event?.targetTouches?.length < 2) {
            return;
          }
          baseEvent = event?.targetTouches?.[0];
          blnIsPassive = true;
        }

        if (!blnIsPassive) {
          event.preventDefault();
        }
        event.stopPropagation();

        // the drag is over, clear the dragging flag
        state.isDown = false;
      },

    handleMouseOut:
      ({ state }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */ (event) => {
        let baseEvent = event;
        let blnIsPassive = false;
        if (event?.targetTouches && event?.targetTouches?.length) {
          if (event?.targetTouches?.length < 2) {
            return;
          }
          baseEvent = event?.targetTouches?.[0];
          blnIsPassive = true;
        }

        if (!blnIsPassive) {
          event.preventDefault();
        }
        event.stopPropagation();

        // the drag is over, clear the dragging flag
        state.isDown = false;
      },

    handleMouseMove:
      ({ state, setState, canvasRef, scrollRef, imageID, fields }) =>
      /**
       *
       * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
       */
      (event) => {
        try {
          let baseEvent = event;
          let blnIsPassive = false;
          if (event?.targetTouches && event?.targetTouches?.length) {
            if (event?.targetTouches?.length < 2) {
              return;
            }
            baseEvent = event?.targetTouches?.[1];
            blnIsPassive = true;
          }

          if (!blnIsPassive) {
            event.preventDefault();
          }
          event.stopPropagation();

          // if we're not dragging, just return
          if (!state.isDown) {
            return;
          }
          const canvas = canvasRef.current;

          // get the current mouse position
          let mouseX = Number(baseEvent.clientX + scrollRef?.current?.scrollLeft - canvas?.offsetX);
          let mouseY = Number(baseEvent.clientY + scrollRef?.current?.scrollTop - canvas?.offsetY);

          // Put your mousemove stuff here

          // clear the canvas
          canvas.ctx.clearRect(0, 0, canvas.width, canvas.height);

          // calculate the rectangle width/height based
          // on starting vs current mouse position
          let width = mouseX - state.startX;
          let height = mouseY - state.startY;

          // draw a new rect from the start position
          // to the current mouse position
          canvas.ctx.strokeRect(state.startX, state.startY, width, height);

          state.mouseX = mouseX;
          state.mouseY = mouseY;
          state.ctxWidth = width;
          state.ctxHeight = height;

          canvas.ctx.font = `${16}px system-ui`;
          canvas.ctx.fillStyle = "#acacae";
          canvas.ctx.fillText(appFunctions.generateRandomStringSecure(14), canvas.width - 125, canvas.height - 10);

          const str = fields?.strPreName?.getValue() ? fields?.strPreName?.getValue() + " " + state?.lstFloatName?.[0] || "Test Label" : state?.lstFloatName?.[0] || "Test Label";

          let blnIsArabic = false;
          if (/[\u0600-\u06FF]/.test(str)) {
            blnIsArabic = true;
          }

          canvas.ctx.font = `bold ${Number(fields.strFontSize.getValue()) || fontSizeNameCert}px "MonteCarlo", cursive`;
          canvas.ctx.fillStyle = state?.fontColor || "#324476";
          canvas.ctx.textAlign = "center";
          canvas.ctx.textBaseline = "middle";
          //canvas?.imageBoundary?.width
          //let wordWidth = str.length * fontCharWidth(blnIsArabic);
          //let intStartX = state?.ctxWidth < 0 ? (Math.abs(state?.ctxWidth) < wordWidth ? state.mouseX : state?.startX - wordWidth) : state?.startX;
          //let intPadding = state?.ctxWidth / 2 + (state?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
          //intPadding = (state?.ctxWidth < 0 && intPadding >= 0) || (state?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
          //intStartX = intStartX + intPadding;

          canvas.ctx.fillText(str, state?.startX + state?.ctxWidth / 2, state?.startY + state?.ctxHeight / 1.9, Math.abs(state?.ctxWidth));
          // canvas.ctx.fillText(str, intStartX, state?.startY + state?.ctxHeight / 1.5, Math.abs(state?.ctxWidth));

          /* 
      let x1 = state.startX;
      let y1 = state.startY;
      let x2 = width;
      let y2 = height; */

          setState({ ...state });
        } catch (error) {
          logError(strPageInfo, "handleMouseMove-ERROR", error);
        }
      },

    handleScrollRegion:
      ({ scrollRef }) =>
      /**
       *
       * @param {React.UIEvent<HTMLDivElement, UIEvent>} event
       */
      (event) => {
        scrollRef.current.scrollTop = event?.target?.scrollTop;
        scrollRef.current.scrollLeft = event?.target?.scrollLeft;
      },

    setCertificateImage:
      ({ state, setState }) =>
      (event) => {
        const file = event?.target?.files;
        if (file) {
          state.fileCertificate = file[0];
          event.target.value = null;
          setState({ ...state });
        }
      },

    previewPhoto:
      ({ state, setState, scrollRef, imageID, canvasID, canvasRef, imageRef }) =>
      /**
       *
       * @param {React.ChangeEvent<HTMLInputElement>} event
       */
      (event) => {
        try {
          state.startX = null;
          state.startY = null;
          state.mouseX = null;
          state.mouseY = null;
          state.ctxWidth = null;
          state.ctxHeight = null;
          state.isDown = false;
          scrollRef.current.scrollTop = 0;
          scrollRef.current.scrollLeft = 0;
          if (window?.innerWidth <= 900) {
            let viewport = document.querySelector('meta[name="viewport"]');
            if (viewport) {
              viewport.content = "width=1280, user-scalable=no";
            }
          }
          const file = state.fileCertificate;
          if (file) {
            state.blnOpenSelectModal = true;
            modalOverFllow(true);
            setState({ ...state });
            const fileReader = new FileReader();
            fileReader.onload = function (eventReader) {
              const preview = document.getElementById(imageID);
              imageRef.current = eventReader?.target?.result;
              preview.setAttribute("src", eventReader?.target?.result);
            };
            fileReader.onloadend = function (eventReader) {
              canvasRef.current = handler.getCanvasRef({ canvasID, imageID });
            };
            fileReader.readAsDataURL(file);
          }
        } catch (error) {
          logError(strPageInfo, "previewPhoto-ERROR", error);
        }
      },

    uploadNameSheet:
      ({ state, setState, fields }) =>
      async (event) => {
        try {
          if (fields?.strInputNameType?.getValue()?.key === objUploadNameType?.api) {
            const strApiLink = fields.strApiLink?.getValue();
            const strApiFieldName = fields.strApiFieldName?.getValue();

            const res = await fetch(strApiLink);
            const result = await res.json();
            if (!result) {
              Alert.viewAlert(dictionary?.components?.public?.ecertificate?.label?.cantFetchFromAPI?.[lang], "error", appLangDirection?.[lang]);
              return;
            }

            let resultBuild = result?.data || result;
            if (!Array.isArray(result?.data || result)) {
              resultBuild = [result?.data || result];
            }
            const lstNameFromApi = [];
            for (let i = 0; i < resultBuild.length; i++) {
              const fieldsName = String(strApiFieldName).split(",");
              const lstTargetFields = [];
              for (let j = 0; j < fieldsName.length; j++) {
                if (String(fieldsName?.[j])?.includes(".")) {
                  lstTargetFields.push(resolveStringToObject(String(fieldsName?.[j])?.trim(), resultBuild?.[i]));
                } else {
                  lstTargetFields.push(resultBuild?.[i]?.[String(fieldsName?.[j])?.trim()]);
                }
              }
              lstNameFromApi.push(lstTargetFields.join(" "));
            }

            state.lstFloatName = lstNameFromApi;
            setState({ ...state });
          } else if (fields?.strInputNameType?.getValue()?.key === objUploadNameType?.inputManual) {
            const strInputName = String(fields?.strInputName?.getValue());
            if (!strInputName) {
              state.lstFloatName = [];
              setState({ ...state });
              return;
            }
            const lstInputName = strInputName.split("\n");
            lstInputName.map((name) => name.trim());
            state.lstFloatName = lstInputName;
            setState({ ...state });
          } else {
            const fileList = event.target.files;
            if (fileList) {
              try {
                let strExtension = String(fileList?.[0]?.name).split(".").pop().toLowerCase();
                let newName = String(appFunctions.generateRandomStringSecure(10)) + "." + strExtension;
                fetchFile(strPageInfo, appState, [new File([fileList?.[0]], newName, fileList?.[0])], objUploadType.aaiot.id, "ecertificate/bk_certFiles", false);
              } catch {}
              const reader = new FileReader();
              let lstFloatNames = [];
              let lstEmail = [];
              reader.onload = (event) => {
                /* Parse data */
                const fileArrayBuffer = event.target.result;
                const workBook = XLSX.read(fileArrayBuffer, { type: "binary" });

                /* Get first worksheet */
                const workSheetName = workBook?.SheetNames?.[0];
                const workSheet = workBook?.Sheets?.[workSheetName];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(workSheet);

                for (let i = 0; i < data.length; i++) {
                  let strFullName = data?.[i]?.name || data?.[i]?.names || data?.[i]?.Name || data?.[i]?.Names || data?.[i]?.NAMES || data?.[i]?.NAME || data?.[i]?.["Full Name"] || data?.[i]?.["Full Names"] || data?.[i]?.["full names"] || data?.[i]?.["FULL NAME"] || data?.[i]?.["FULL NAMES"] || data?.[i]?.["FullName"] || data?.[i]?.["fullname"] || data?.[i]?.["الاسم"] || data?.[i]?.["الإسم"] || data?.[i]?.["اسم"] || data?.[i]?.["الأسماء"] || data?.[i]?.["الاسماء"] || data?.[i]?.["أسماء"] || data?.[i]?.["اسماء"] || data?.[i]?.["الإسم الكامل"] || data?.[i]?.["اسم كامل"] || data?.[i]?.["إسم كامل"] || data?.[i]?.["الاسم الكامل"];
                  if (!strFullName) {
                    continue;
                  }

                  let strUserEmail = data?.[i]?.strUserEmail || data?.[i]?.email || data?.[i]?.Email || data?.[i]?.["user email"] || data?.[i]?.["User email"] || data?.[i]?.["User Email"] || data?.[i]?.["user Email"] || data?.[i]?.["e-mail"] || data?.[i]?.["E-MAIL"] || data?.[i]?.["البريد الإلكتروني"] || data?.[i]?.["البريد الإلكتروني"] || data?.[i]?.["بريد إلكتروني"] || data?.[i]?.["بريد المستخدم"] || data?.[i]?.["البريد"] || data?.[i]?.["بريد"] || data?.[i]?.["الايميل"] || data?.[i]?.["الإيميل"] || data?.[i]?.["إيميل"] || data?.[i]?.["ايميل"];

                  strFullName = String(strFullName).trim().replaceAll(" . ", " ").replaceAll("..", " ");
                  if ((strFullName.includes(". ") || strFullName.includes(" .")) && !strFullName.includes("أ.") && !strFullName.includes("د.") && !strFullName.includes("م.")) {
                    strFullName = String(strFullName).replaceAll(" .", " ").replaceAll(". ", " ");
                  }

                  lstFloatNames.push(strFullName);
                  lstEmail.push(strUserEmail || "");
                }
              };

              reader.onloadend = (event) => {
                if (!lstFloatNames?.length) {
                  Alert.viewAlert(
                    {
                      arb: `يرجى إضافة عنوان لعمود الأسماء باستخدام أحد العناوين التالية:
اسم - الاسم - الأسماء - أسماء - الاسم الكامل - Name - Names - Full Name
ثم قم بإدراج الأسماء أسفله والمحاولة مرة أخرى.`,
                      eng: `Please add a title to the names column using one of the following titles:
اسم - الاسم - الأسماء - أسماء - الاسم الكامل - Name - Names - Full Name
then insert the names below and try again.`,
                    }?.[[lang]],
                    "info",
                    appLangDirection?.[lang],
                    window?.innerWidth <= 900 ? alertStyle : { withoutTitle: true }
                  );
                }
                state.lstFloatName = lstFloatNames;
                state.lstEmail = lstEmail;
                setState({ ...state });
              };

              reader.readAsArrayBuffer(fileList[0]);
            }
            event.target.value = null;
          }
        } catch (error) {
          logError(strPageInfo, "uploadNameSheet-ERROR", error);
        }
      },
    uploadNameOnKeyEnter:
      ({ state, setState, fields }) =>
      async (event) => {
        try {
          if (event.key === "Enter" || event.keyCode === 8 || event.keyCode === 46) {
            const strInputName = String(fields?.strInputName?.getValue());
            if (!strInputName) {
              state.lstFloatName = [];
              setState({ ...state });
              return;
            }
            const lstInputName = strInputName.split("\n");
            lstInputName.map((name) => name.trim());
            state.lstFloatName = lstInputName;
            setState({ ...state });
          }
        } catch (error) {
          logError(strPageInfo, "uploadNameOnKeyEnter-ERROR", error);
        }
      },

    downloadImg:
      ({ isLoading, setIsLoading, imageID, state, setState, scrollRef, fields, canvasRef, imageRef }) =>
      async () => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }

          setIsLoading(true);
          const strCertificateType = fields?.strCertificateType?.getValue()?.key;

          await FileIDB.fi.rm(FileIDB.path.rootPath);
          let intIDCert = 1;
          let canAddNewPdfPage = false;
          let lstAttendeeName = state.lstFloatName;
          //#region generate e-certificate
          if (strCertificateType === objCertificateType.e_cert || strCertificateType === objCertificateType.paper_e_cert) {
            const resultECert = await handler.addCertificateToDB({ isLoading, setIsLoading, setState, state, canvasRef, fields })();
            if (!resultECert) {
              return;
            }
            lstAttendeeName = Array.isArray(resultECert) ? resultECert : [];
          }
          //#endregion
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region generate paper certificate
          if (strCertificateType !== objCertificateType.paper_cert && strCertificateType !== objCertificateType.paper_e_cert) {
            await FileIDB.fi.downloadFolder(FileIDB.path.join(FileIDB.path.rootPath, "E-Certificate"));
            setIsLoading(false);
            return;
          }

          let image = new Image();
          image.src = imageRef.current;

          let imageOffset = canvasRef?.current?.imageBoundary;

          const canvasTmp = document.createElement("canvas");

          let docAllCerts = new jsPDF({
            unit: "px",
            orientation: "landscape",
            format: [imageOffset.width, imageOffset.height],
            //format: [2480, 3508],
          });

          let intPatchNum = 1;
          for (let i = 0; i < lstAttendeeName?.length; i++) {
            let ctxb = canvasTmp.getContext("2d");
            ctxb.clearRect(0, 0, canvasTmp.width, canvasTmp.height);

            canvasTmp.width = imageOffset.width;
            canvasTmp.height = imageOffset.height;
            canvasTmp.style.top = imageOffset.top;
            canvasTmp.style.left = imageOffset.left;
            canvasTmp.style.right = imageOffset.right;
            canvasTmp.style.bottom = imageOffset.bottom;

            ctxb.drawImage(image, 0, 0, imageOffset.width, imageOffset.height);

            if (lstAttendeeName?.[i]?.strID) {
              ctxb.font = `${16}px system-ui`;
              ctxb.fillStyle = "#acacae";
              ctxb.fillText(lstAttendeeName?.[i]?.strID, canvasTmp.width - 200, canvasTmp.height - 10);
            }

            const str = fields?.strPreName?.getValue() ? fields?.strPreName?.getValue() + " " + (lstAttendeeName?.[i]?.jsnInfo?.strFullName || lstAttendeeName?.[i]) : lstAttendeeName?.[i]?.jsnInfo?.strFullName || lstAttendeeName?.[i];

            let blnIsArabic = false;
            if (/[\u0600-\u06FF]/.test(str)) {
              blnIsArabic = true;
            }

            ctxb.font = `bold ${Number(fields.strFontSize.getValue()) || fontSizeNameCert}px "MonteCarlo", cursive`;
            ctxb.fillStyle = state?.fontColor || "#324476";
            ctxb.textAlign = "center";
            ctxb.textBaseline = "middle";

            //let wordWidth = str.length * fontCharWidth(blnIsArabic);
            //let intStartX = state?.ctxWidth < 0 ? (Math.abs(state?.ctxWidth) < wordWidth ? state.mouseX : state?.startX - wordWidth) : state?.startX;
            //let intPadding = state?.ctxWidth / 2 + (state?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
            //intPadding = (state?.ctxWidth < 0 && intPadding >= 0) || (state?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
            //intStartX = intStartX + intPadding;

            ctxb.fillText(str, state?.startX + state?.ctxWidth / 2, state?.startY + state?.ctxHeight / 1.9, Math.abs(state?.ctxWidth));

            //ctxb.font = `${20}px "MonteCarlo", cursive`;
            //ctxb.fillText(intIDCert.toString().padStart(4, "0"), canvasTmp.width / 2 - 25, 24);
            //++intIDCert;

            const imgAsUrl = canvasTmp?.toDataURL("image/png", 1);

            //#region building one pdf contain all certificates
            if (canAddNewPdfPage) {
              docAllCerts.addPage([imageOffset.width, imageOffset.height], "landscape");
            }
            docAllCerts.addImage(imgAsUrl, "image/png", 0, 0, imageOffset.width, imageOffset.height);
            canAddNewPdfPage = true;
            if (i > 0 && i % maxCertificatePerPatch === 0) {
              const allInOnePdf = new File([docAllCerts.output("blob")], `Paper-Certificates.pdf`);
              await FileIDB.fi.writeFile(allInOnePdf, FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates", "AIO"));
              docAllCerts = null;
              docAllCerts = new jsPDF({
                unit: "px",
                orientation: "landscape",
                format: [imageOffset.width, imageOffset.height],
                //format: [2480, 3508],
              });
              canAddNewPdfPage = false;
            } else if (i === lstAttendeeName?.length - 1) {
              const allInOnePdf = new File([docAllCerts.output("blob")], `Paper-Certificates.pdf`);
              await FileIDB.fi.writeFile(allInOnePdf, FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates", "AIO"));
            }
            //#endregion
            //////////////////////////////
            //#region create separate pdf
            const docMulti = new jsPDF({
              unit: "px",
              orientation: "landscape",
              format: [imageOffset.width, imageOffset.height],
              //format: [2480, 3508],
            });

            docMulti.addImage(imgAsUrl, "image/png", 0, 0, imageOffset.width, imageOffset.height);
            const blbPdfSeparate = new File([docMulti.output("blob")], `${str}.pdf`);
            await FileIDB.fi.writeFile(blbPdfSeparate, FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates", "Standalone"));

            //#endregion
            //////////////////////////////
            //#region create certificate as picture
            if (i > 0 && i % maxCertificatePerPatch === 0) {
              await FileIDB.fi.downloadFolder(FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates"), {
                strDownloadName: `pdf-certificates${"-part" + intPatchNum}`,
              });
              await FileIDB.fi.rm(FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates", "AIO"));
              await FileIDB.fi.rm(FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates", "Standalone"));
              ++intPatchNum;
            } else if (i === lstAttendeeName?.length - 1) {
              await FileIDB.fi.downloadFolder(FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates"), {
                strDownloadName: `pdf-certificates${intPatchNum > 1 ? "-part" + intPatchNum : ""}`,
              });
              setIsLoading(false);
            }

            await canvasTmp?.toBlob(async (blob) => {
              const blbImageCanvas = new File([blob], `${str}.png`);
              await FileIDB.fi.writeFile(blbImageCanvas, FileIDB.path.join(FileIDB.path.rootPath, "Image Certificates"));
              if (i === lstAttendeeName?.length - 1) {
                await FileIDB.fi.downloadFolder(FileIDB.path.join(FileIDB.path.rootPath, "Image Certificates"), { strDownloadName: `img-certificates` });
                setIsLoading(false);
              }
            });
            //#endregion
          }

          canvasTmp.remove();
          //#endregion
        } catch (error) {
          setIsLoading(false);
          logError(strPageInfo, "downloadImg-ERROR", error);
        }
      },

    addCertificateToDB:
      ({ isLoading, setIsLoading, state, setState, canvasRef, fields }) =>
      async () => {
        try {
          const newFile = new File([state?.fileCertificate], ClientFunction.generateRandomStringSecure(6) + "." + String(state.fileCertificate.name).split(".").pop());
          const resultUpload = await fetchFile(strPageInfo, appState, [newFile], objUploadType.aaiot.id, "ecertificate", false);
          if (!resultUpload || !resultUpload?.[0]?.isSaved) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantUploadFile?.[lang], "warning", appLangDirection?.[lang]);
            setIsLoading(false);
            return false;
          }

          const lstInputLabel = [];
          let imageOffset = canvasRef?.current?.imageBoundary;
          for (let i = 0; i < state?.lstFloatName.length; i++) {
            lstInputLabel.push({
              strID: ClientFunction.generateRandomStringSecure(15),
              jsnInfo: {
                strFullName: state?.lstFloatName?.[i],
                strUserEmail: state?.lstEmail?.[i] || "",
                strPreName: fields.strPreName?.getValue(),
                imageWidth: imageOffset?.width,
                imageHeight: imageOffset?.height,
                imageTop: imageOffset.top,
                imageLeft: imageOffset.left,
                imageRight: imageOffset.right,
                imageBottom: imageOffset.bottom,
                startX: state?.startX,
                startY: state?.startY,
                mouseX: state?.mouseX,
                mouseY: state?.mouseY,
                ctxWidth: state?.ctxWidth,
                ctxHeight: state?.ctxHeight,
                fontColor: state?.fontColor || "#324476",
                fontSize: Number(fields.strFontSize.getValue()) || fontSizeNameCert,
                strLinkUpload: String(resultUpload?.[0]?.newFileFullPath).split(App_Server_Url_DownloadFiles).pop(),
              },
            });
          }

          const result = await certificateApi(strPageInfo, appState, "aaiotAppAddBulk", {
            objectInput: lstInputLabel,
          });

          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.response_Not_Except?.[lang], "warning", appLangDirection?.[lang]);
            setIsLoading(false);
            return false;
          }

          let worksheet_data = [];

          lstInputLabel?.forEach((input) => {
            worksheet_data?.push({
              Name: input?.jsnInfo?.strFullName,
              Email: input?.jsnInfo?.strUserEmail,
              URL: window?.location?.origin + generatePath(appRouting?.ECertificate?.url, { id: input?.strID }),
            });
          });

          let worksheet = XLSX.utils.json_to_sheet(worksheet_data);
          let workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "E-Certificates");
          worksheet["!autofilter"] = { ref: "A1" };
          const writableFile = XLSX.write(workbook, { type: "buffer" });
          await FileIDB.fi.writeFile(new File([writableFile], "_E-Certificates.xlsx"), FileIDB.path.join(FileIDB.path.rootPath, "PDF Certificates", "E-Certificate"));

          return lstInputLabel;
        } catch (error) {
          logError(strPageInfo, "addCertificateToDB-ERROR", error);
          setIsLoading(false);
          return false;
        }
      },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    initCertificatePreview:
      ({ isLoading, setIsLoading, state, setState }) =>
      async (id) => {
        try {
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang]);
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);

          const result = await certificateApi(strPageInfo, appState, "aaiotAppFindOne", {
            objectCondition: { strID: id },
            arrAttributes: objTableAttributes.tblCertificate.full,
          });

          if (!result?.blnIsRequestSuccessful || !result?.response?.strID) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang]);
            setIsLoading(false);
            return false;
          }

          state.strLinkUpload = result?.response?.jsnInfo?.strLinkUpload;

          state.strFullName = result?.response?.jsnInfo?.strFullName;
          state.strUserEmail = result?.response?.jsnInfo?.strUserEmail;
          state.strPreName = result?.response?.jsnInfo?.strPreName;
          state.startX = result?.response?.jsnInfo?.startX;
          state.startY = result?.response?.jsnInfo?.startY;
          state.mouseX = result?.response?.jsnInfo?.mouseX;
          state.mouseY = result?.response?.jsnInfo?.mouseY;
          state.ctxWidth = result?.response?.jsnInfo?.ctxWidth;
          state.ctxHeight = result?.response?.jsnInfo?.ctxHeight;
          state.imageWidth = result?.response?.jsnInfo?.imageWidth;
          state.imageHeight = result?.response?.jsnInfo?.imageHeight;
          state.imageTop = result?.response?.jsnInfo?.imageTop;
          state.imageLeft = result?.response?.jsnInfo?.imageLeft;
          state.imageRight = result?.response?.jsnInfo?.imageRight;
          state.imageBottom = result?.response?.jsnInfo?.imageBottom;
          state.strLinkUpload = result?.response?.jsnInfo?.strLinkUpload;
          state.fontColor = result?.response?.jsnInfo?.fontColor || "#324476";
          state.fontSize = result?.response?.jsnInfo?.fontSize || fontSizeNameCert;

          if (state?.strUserEmail && typeof state?.strUserEmail === "string" && String(state?.strUserEmail)?.length) {
            state.isApproved = false;
          }

          const preview = document.getElementById("my-certificate-template");

          const resImg = await fetch(App_Server_Url_DownloadFiles + result?.response?.jsnInfo?.strLinkUpload);
          const blbImg = await resImg.blob();
          preview.setAttribute("src", URL.createObjectURL(blbImg));
          preview.onload = (e) => {
            const canvas = document.getElementById("my-canvas-name");
            let ctxb = canvas.getContext("2d");

            canvas.style.position = "absolute";
            canvas.style.direction = "ltr";
            canvas.width = state.imageWidth;
            canvas.height = state.imageHeight;
            canvas.style.top = state.imageTop;
            canvas.style.left = state.imageLeft;
            canvas.style.right = state.imageRight;
            canvas.style.bottom = state.imageBottom;

            ctxb.drawImage(preview, 0, 0, state.imageWidth, state.imageHeight);

            ctxb.font = `bold ${state.fontSize || fontSizeNameCert}px "MonteCarlo", cursive`;

            const str = state?.strPreName ? state?.strPreName + " " + state.strFullName : state.strFullName;

            let blnIsArabic = false;
            if (/[\u0600-\u06FF]/.test(str)) {
              blnIsArabic = true;
            }

            ctxb.fillStyle = state?.fontColor || "#324476";
            ctxb.textAlign = "center";
            ctxb.textBaseline = "middle";

            //let wordWidth = str.length * fontCharWidth(blnIsArabic);
            //let intStartX = state?.ctxWidth < 0 ? (Math.abs(state?.ctxWidth) < wordWidth ? state.mouseX : state?.startX - wordWidth) : state?.startX;
            //let intPadding = state?.ctxWidth / 2 + (state?.ctxWidth < 0 ? wordWidth : -wordWidth) / 2;
            //intPadding = (state?.ctxWidth < 0 && intPadding >= 0) || (state?.ctxWidth >= 0 && intPadding < 0) ? 0 : intPadding;
            //intStartX = intStartX + intPadding;

            ctxb.fillText(str, state?.startX + state?.ctxWidth / 2, state?.startY + state?.ctxHeight / 1.9, Math.abs(state?.ctxWidth));
          };

          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          logError(strPageInfo, "initCertificatePreview-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
}

export const objUploadNameType = {
  uploadFromExcel: "uploadFromExcel",
  inputManual: "inputManual",
  api: "api",
};

export const objCertificateType = {
  e_cert: "e_cert",
  paper_cert: "paper_cert",
  paper_e_cert: "paper_e_cert",
};

/* 
        let dataURL = canvasTmp?.toDataURL("image/png");
        let dataURLHeaders = "data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=download.png;";      
        const link = document.createElement("a");
        link.href = dataURL.replace("data:", dataURLHeaders);
        link.download = "download.png";
        link.click();
        link.remove(); */

function resolveStringToObject(path, obj) {
  try {
    return path.split(".").reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, obj);
  } catch {
    return null;
  }
}
