import React, { useEffect, useState } from "react";
import { Avatar, Button, Container, Grid, Line, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import ShredHeader from "../../../shared/shredHeader";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { useApp } from "../../../../configuration/contextapi/context";
import { FormData, FormField } from "../../../../util/formApi/FormAPI";
import { CtrlMngSurveySave } from "./MngSurveySave.ctrl";
import { useLocation, useNavigate, useParams } from "@cbmisorg/router";
import { objQuestionType } from "../../../../configuration/appVariables";
import { Icon } from "@cbmisorg/icons";

const label = dictionary?.components?.stakeholders?.admin?.mngSurvey;
function MngSurveySaveView() {
  const { appState, appDispatch } = useApp();
  const { state } = useLocation();
  const { type } = useParams();
  const navigate = useNavigate();
  const lang = appState.clientInfo.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    surveyInfo: {
      lstSurveyQus: [
        {
          bigQID: 0,
          strQ: {},
          intQType: 0,
          strQCaptionB: {},
          strQCaptionA: {},
          lstOpt: [
            {
              intOptID: 0,
              strOpt: {},
            },
          ],
        },
      ],
    },
    blnOpenModal: false,
    intSelectedQuestionsInd: -1,

    blnOpenOptModal: false,
    intSelectedOptInd: -1,
    lstTmpOption: [],
  });

  const field = FormData({
    strUrlCode: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, maxWord: 1 },
    },
    strTitle: {
      control: "text",
      blnLanguage: true,
    },
    strSubtitle: {
      control: "text",
      blnLanguage: true,
    },
    strDscrp: {
      control: "textarea",
      blnLanguage: true,
    },
  });

  const fieldQuestions = FormData({
    strQuestion: {
      control: "text",
      blnLanguage: true,
      validations: { required: true },
    },
    strQuestionGrp: {
      control: "text",
      blnLanguage: true,
      validations: {},
    },
    strQCaptionB: {
      control: "textarea",
      blnLanguage: true,
      validations: {},
    },
    strQCaptionA: {
      control: "textarea",
      blnLanguage: true,
      validations: {},
    },
    lstQuestionType: {
      control: "radio",
      validations: { required: true },
      options: [objQuestionType?.trueFalse, objQuestionType?.writing, objQuestionType?.multipleChoice],
    },
    questionNo: {
      control: "text",
      validations:{onlyNumbers: true}
    },
    required: {
      control: "switch",
    },
  });

  const fieldOption = FormData({
    strOption: {
      control: "text",
      blnLanguage: true,
      validations: { required: true },
    },
  });
  const handlers = CtrlMngSurveySave(appState, appDispatch);
  const initData = handlers.initData({ isLoading, setIsLoading, stateInfo, setStateInfo, state, field });
  const handleOpenModale = handlers.handleOpenModale({ stateInfo, setStateInfo, fieldQuestions });
  const handleOpenOptModale = handlers.handleOpenOptModale({ stateInfo, setStateInfo, fieldOption });
  const handleSubmit = handlers.handleSubmit({ isLoading, setIsLoading, stateInfo, navigate, field, type });
  const handelDeleteQuesConfirmation = handlers.handelDeleteQuesConfirmation({ stateInfo, setStateInfo });
  const handelDeleteQuesOpConfirmation = handlers.handelDeleteQuesOpConfirmation({ stateInfo, setStateInfo });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={label?.[type]?.[lang]} blnIsLight={true} blnIsAllLight={true} lang={lang} />
      <Container py-10 my-5>
        <Grid container justifyContent="center">
          <Grid item xs="12" md="8">
            <Paper py-0 elevation="1">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs="12" container dir={"ltr"}>
                  <Grid item xs="6" sm="5" md="4" lg="3" xl="2" alignContent="center">
                    <Typography as="body2">{`${window?.location?.origin}/`}</Typography>
                  </Grid>
                  <Grid item xs="6" sm="7" md="8" lg="9" xl="10">
                    <FormField objHandler={field?.strUrlCode} dir={"ltr"} />
                  </Grid>
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={field?.strTitle} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={field?.strSubtitle} />
                </Grid>
                <Grid item xs="12">
                  <FormField objHandler={field?.strDscrp} />
                </Grid>
              </Grid>
              <Line sx={{ width: "100%" }} />
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs="9" container>
                  <Grid item>
                    <Typography as="subtitle2">{label?.questions?.[lang]}</Typography>
                  </Grid>
                  <Grid item>
                    <Avatar color="primary" sx={{ width: "25px", height: "25px" }}>
                      {stateInfo?.surveyInfo?.lstSurveyQus?.length || 0}
                    </Avatar>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button icon="add" size="sm" p-1 onClick={handleOpenModale(-1)} />
                </Grid>
                {stateInfo?.surveyInfo?.lstSurveyQus?.map((qus, ind) => (
                  <Grid item xs="12" key={qus?.bigQID}>
                    <Grid item xs="9" container>
                      <Grid item xs="1" md="auto">
                        <Icon
                          icon={qus?.intQType === objQuestionType?.writing ? "DriveFileRenameOutline" : qus?.intQType === objQuestionType?.trueFalse ? "Flaky" : "Rule"}
                          color="primary"
                        />
                      </Grid>
                      <Grid item xs="11">
                        <Typography as="body3">{ind +1}. {qus?.strQ?.[lang]}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item>
                        <Icon icon="edit" color="primary" onClick={handleOpenModale(ind)} />
                      </Grid>
                      <Grid item>
                        <Icon icon="delete" color="error" onClick={handelDeleteQuesConfirmation(qus)} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <Button
                  label={dictionary?.shared?.buttons?.save?.[lang]}
                  disabled={!field?.strUrlCode?.getValue() || !stateInfo?.surveyInfo?.lstSurveyQus?.length}
                  color={field?.strUrlCode?.getValue() && stateInfo?.surveyInfo?.lstSurveyQus?.length ? "primary" : "#ccc"}
                  onClick={handleSubmit}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo.blnOpenModal} eventClose={handleOpenModale(-1)}>
        <ModalHeader>{stateInfo?.intSelectedQuestionsInd === -1 ? label?.addQuestion?.[lang] : label?.editQuestion?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.strQuestionGrp} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.strQCaptionB} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.strQuestion} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.strQCaptionA} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.lstQuestionType} />
            </Grid>
          </Grid>
          {fieldQuestions?.lstQuestionType?.getValue()?.key !== objQuestionType?.multipleChoice ? null : (
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs="9" container>
                <Grid item>
                  <Typography as="subtitle2">{label?.option?.[lang]}</Typography>
                </Grid>
                <Grid item>
                  <Avatar color="primary" sx={{ width: "25px", height: "25px" }}>
                    {[...(stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt || []), ...stateInfo?.lstTmpOption]?.length || 0}
                  </Avatar>
                </Grid>
              </Grid>
              <Grid item>
                <Button icon="add" size="sm" p-1 onClick={handleOpenOptModale(-1)} />
              </Grid>
              {[...(stateInfo?.surveyInfo?.lstSurveyQus?.[stateInfo?.intSelectedQuestionsInd]?.lstOpt || []), ...stateInfo?.lstTmpOption]?.map((opt, ind) => (
                <Grid item xs="12" key={opt?.intOptID}>
                  <Grid item xs="9" container>
                    <Grid item xs="1" md="auto">
                      <Icon icon="circle" color="primary" />
                    </Grid>
                    <Grid item xs="11">
                      <Typography as="body3">{opt?.strOpt?.[lang]}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item>
                      <Icon icon="edit" color="primary" onClick={handleOpenOptModale(ind)} />
                    </Grid>
                    <Grid item>
                      <Icon icon="delete" color="error" onClick={handelDeleteQuesOpConfirmation(opt)} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.questionNo} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldQuestions?.required} />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                label={dictionary?.shared?.buttons?.save?.[lang]}
                disabled={!fieldQuestions?.lstQuestionType?.getValue()?.key || !Object.values(fieldQuestions?.strQuestion?.getValue())?.length}
                color={fieldQuestions?.lstQuestionType?.getValue()?.key && Object.values(fieldQuestions?.strQuestion?.getValue())?.length ? "primary" : "#ccc"}
                onClick={handleOpenModale(-1, "save")}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo.blnOpenOptModal} eventClose={handleOpenOptModale(-1)} size="sm">
        <ModalHeader>{stateInfo?.intSelectedOptInd === -1 ? label?.addOptions?.[lang] : label?.editOptions?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="12">
              <FormField objHandler={fieldOption?.strOption} />
            </Grid>
            <Grid item>
              <Button
                label={dictionary?.shared?.buttons?.save?.[lang]}
                disabled={!Object.values(fieldOption?.strOption?.getValue())?.length}
                color={Object.values(fieldOption?.strOption?.getValue())?.length ? "primary" : "#ccc"}
                onClick={handleOpenOptModale(-1, "save")}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default MngSurveySaveView;
