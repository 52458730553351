export const fontsize = {
  sm: {
    h4: "31px",
    h5: "23px",
    h6: "19px",
    subtitle1: "15px",
    subtitle2: "13px",
    body1: "14px",
    body2: "12px",
    caption: "10px",
  },
  md: {
    h4: "33px",
    h5: "25px",
    h6: "21px",
    subtitle1: "17px",
    subtitle2: "15px",
    body1: "16px",
    body2: "14px",
    caption: "11px",
  },
  lg: {
    h4: "35px",
    h5: "27px",
    h6: "23px",
    subtitle1: "19px",
    subtitle2: "17px",
    body1: "18px",
    body2: "16px",
    caption: "12px",
  },
  xl: {
    h4: "37px",
    h5: "29px",
    h6: "25px",
    subtitle1: "21px",
    subtitle2: "19px",
    body1: "20px",
    body2: "18px",
    caption: "14px",
  },
};

export function ChangFontSize(appFontSize) {
  switch (String(appFontSize)?.toLowerCase()) {
    case "sm":
      changSizeVar("sm");
      break;
    case "md":
      changSizeVar("md");
      break;
    case "lg":
      changSizeVar("lg");
      break;
    case "xl":
      changSizeVar("xl");
      break;
    default:
      changSizeVar("lg");
      break;
  }
}

export const changSizeVar = (size = "lg") => {
  const objSize = fontsize?.[size];
  document.documentElement.style.setProperty("--app-h4-font-size", objSize?.h4);
  document.documentElement.style.setProperty("--app-h5-font-size", objSize?.h5);
  document.documentElement.style.setProperty("--app-h6-font-size", objSize?.h6);
  document.documentElement.style.setProperty("--app-subtitle1-font-size", objSize?.subtitle1);
  document.documentElement.style.setProperty("--app-subtitle2-font-size", objSize?.subtitle2);
  document.documentElement.style.setProperty("--app-body1-font-size", objSize?.body1);
  document.documentElement.style.setProperty("--app-body2-font-size", objSize?.body2);
  document.documentElement.style.setProperty("--app-caption-font-size", objSize?.caption);
};
