export const FileExtensions2ToIcon = {
  "gemfile.lock": "file_type_bundler.svg",
  "css.map": "file_type_cssmap.svg",
  "drawio.png": "file_type_drawio.svg",
  "drawio.svg": "file_type_drawio.svg",
  "js.flow": "file_type_flow.svg",
  "ejs.t": "file_type_hygen.svg",
  "js.snap": "file_type_jest_snapshot.svg",
  "jsx.snap": "file_type_jest_snapshot.svg",
  "ts.snap": "file_type_jest_snapshot.svg",
  "tsx.snap": "file_type_jest_snapshot.svg",
  "js.map": "file_type_light_jsmap.svg",
  "layout.html": "file_type_layout.svg",
  "layout.htm": "file_type_layout.svg",
  "marko.js": "file_type_markojs.svg",
  "format.ps1xml": "file_type_powershell_format.svg",
  "types.ps1xml": "file_type_powershell_types.svg",
  "story.js": "file_type_storybook.svg",
  "story.jsx": "file_type_storybook.svg",
  "story.ts": "file_type_storybook.svg",
  "story.tsx": "file_type_storybook.svg",
  "story.mdx": "file_type_storybook.svg",
  "stories.js": "file_type_storybook.svg",
  "stories.jsx": "file_type_storybook.svg",
  "stories.ts": "file_type_storybook.svg",
  "stories.tsx": "file_type_storybook.svg",
  "stories.mdx": "file_type_storybook.svg",
  "test.js": "file_type_light_testjs.svg",
  "test.jsx": "file_type_light_testjs.svg",
  "test.mjs": "file_type_light_testjs.svg",
  "spec.js": "file_type_light_testjs.svg",
  "spec.jsx": "file_type_light_testjs.svg",
  "spec.mjs": "file_type_light_testjs.svg",
  "test.ts": "file_type_testts.svg",
  "test.tsx": "file_type_testts.svg",
  "spec.ts": "file_type_testts.svg",
  "spec.tsx": "file_type_testts.svg",
  "e2e-test.ts": "file_type_testts.svg",
  "e2e-test.tsx": "file_type_testts.svg",
  "e2e-spec.ts": "file_type_testts.svg",
  "e2e-spec.tsx": "file_type_testts.svg",
  "d.ts": "file_type_typescriptdef.svg",
};
