import React, { useEffect, useState } from "react";
import "./RadialProgressBar.css";

function RadialProgressBar({ value, maxValue, color }) {
  const [state, setState] = useState({
    value: 0,
    backgroundImage: `linear-gradient(90deg, #ddd 50%, transparent 50%), linear-gradient(90deg, ${color} 50%, #ddd 50%)`,
  });

  useEffect(() => {
    const number = Math.floor((value / maxValue) * 100);
    state.value = number;
    const deg = 90 - (360 * state.value) / 100 - 1;
    const deg2 =  360 * ((state.value - 50) / 100) + deg;
    let backgroundImage =
      state.value <= 50 ? `linear-gradient(${deg}deg, #ddd 50%, transparent 50%), linear-gradient(90deg, ${color} 50%, #ddd 50%)` : `linear-gradient(${deg}deg, ${color} 50%, transparent 50%), linear-gradient(${deg2}deg, ${color} 50%, #ddd 50%)`;

    state.backgroundImage = backgroundImage;
    setState({ ...state });
  }, [value, maxValue]);

  return (
    <React.Fragment>
      <div className="radialProgressBar" style={{ backgroundImage: state.backgroundImage }}>
        <div className="overlay">
          <div className="inside-circle"> {state.value} %</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RadialProgressBar;
