import { makeStyle } from "@cbmisorg/styles";
import { App_info_Opacity_Color } from "../../../../util/appStyle";

export const objStyle = makeStyle({
    fieldSearch:{
        borderRadius: "1rem",
        "fieldset":{
            borderRadius: "1rem",
            minHeight:"2rem",
            maxHeight:"2.2rem",
        },
        "input":{
            fontSize: ".8rem",
            minHeight:"2rem",
            maxHeight:"2.2rem",
        },
        ".uicore_icon_container":{
            cursor: "pointer"
        }
    },
    btnAdd:{
        borderRadius: "1rem",
        padding: "2px 3px"
    },

    subTabel:{
        width: "0px",
        transition: "width 1s",
        display: "none"
    },
    subTabelOpen:{
        width: "fit-content",
        transition: "width 1s",
        display: "auto",
        background: App_info_Opacity_Color
    },
    tableCellHead:{
        // display: "flow"
    }
});
