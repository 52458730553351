import React from 'react'
import Icon from "../../../../icon/CbmisIcon"

function UploaderImageArea(props) {
    const {images,handelUploadOpen} = props

  return (
    <React.Fragment>
        <div className="area-upload">
            <div className="area-preview" onClick={handelUploadOpen}>
                 {images.length?
                        <img src={URL.createObjectURL(images[0])} alt="/" className="image-preview"/>
                    :
                        <React.Fragment>
                            <Icon icon="image" className="iconImage"/>
                        </React.Fragment>
                }
            </div>
        </div>
    </React.Fragment>
  )
}

export default UploaderImageArea