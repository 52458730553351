import React, { useEffect } from "react";
import { useApp } from "../../../../configuration/contextapi/context";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import AccountMember from "../../member/account";
import { objUserRole } from "../../../../configuration/appVariables";
import { dictionary } from "../../../../util/appLanguage/dictionary";

//old
import AdminAccount from "../../../../../old/components/stakeholders/admin/account/Account";
import ShredHeader from "../../../../../old/components/sharedUi/header/ShredHeader";
import { ClientDirection } from "@cbmisorg/helper";


function Account() {
  useCheckHooksGlb();
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;

  const handelChangeLang = () => {
    if (lang === "arb") {
      ClientDirection.setDirection("ltr");
      appState.clientInfo.strLanguage = "eng";
      appState.clientInfo.strDataLanguage = "eng";
    } else {
      ClientDirection.setDirection("rtl");
      appState.clientInfo.strLanguage = "arb";
      appState.clientInfo.strDataLanguage = "arb";
    }
    appDispatch();
  };

  useEffect(() => {
    document.body.classList.add("paddingBottom");
  }, [])
  
  return (
    <React.Fragment>
      {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Member ? (
        <React.Fragment>
          <AccountMember />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ShredHeader title={dictionary?.components?.public?.footer?.account?.[lang]} lang={lang} blnHome={true} blnLang={true} blnBack={false} changLang={handelChangeLang} />
          <AdminAccount />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Account;
