import React, { useEffect, useState } from "react";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import { useApp } from "../../../configuration/contextapi/context";
import { dictionary } from "../../../util/appLanguage/dictionary";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "../../stakeholders/member/account/Account.style";
import { Button, Container, Grid, Loader, Modal, ModalBody, Paper, Typography } from "@cbmisorg/material";
import { App_info_Color, App_Light_Text_Color, appStyle } from "../../../util/appStyle";
import { useLocation } from "@cbmisorg/router";
import * as appFunctions from "../../../util/appFunctions";
import "../eCertificate/assets/font.css";
import forumBadgeCtrl from "./forumBadge.controller";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { App_Server_Url_DownloadFiles } from "../../../configuration/appVariables";
import { Icon } from "@cbmisorg/icons";

export default function ForumBadgeView() {
  useCheckHooksGlb();
  const { appState, appDispatch } = useApp();

  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.public?.regFormActivity;
  const location = useLocation();
  const bigActivityType = location?.state?.bigActivityType;
  // const bigSubject = location?.state?.bigSubject;
  const strSubjectKey = location?.state?.strSubjectKey;
  // const strActivityKey = location?.state?.strActivityKey;
  const classes = ClassesBuilder(objStyle, { lang });
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const [state, setState] = useState({
    blnIsInitDone: false,
    objGuestActivityInfo: {},
    objForumInfo: {},
    objSubForumInfo: {},
    lstBadgeFile: [],
    lstCertificateFile: [],
    objBadgeSelectInfo: {},
    objCertificateSelectInfo: {},
    lstBrochureFile: [],
    blnIsNeedToConfirm: false,

    blnOpenInfoModal: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fields = FormData({
    strBadgeID: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, minLength: 3 },
    },
  });

  const handler = forumBadgeCtrl({ appState, appDispatch });
  const initCertificatePreview = handler.initCertificatePreview({ isLoading, setIsLoading, strSubjectKey, state });
  const initData = handler.initData({ isLoading, setIsLoading, bigActivityType, setState, state, strSubjectKey, jsnSystemConfig });
  // const downloadBadge = handler.downloadBadge({ state });
  const updateBadgeView = handler.updateBadgeView({ bigActivityType, fields, isLoading, setIsLoading, setState, state, strSubjectKey });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <>
      <Loader backdrop={isLoading} color={App_info_Color} />
      {/* <ShredHeader title={labels?.title?.[lang]} lang={lang} blnHome={false} blnBack={true} blnLang={false} blnLogout={false} sx={{ position: "absolute" }} /> */}
      <section className={classes?.secDark} style={{ overflow: "hidden", paddingTop: "0px" }}>
        <Container className={classes?.mainContainer} my-0>
          <Container>
            {state?.blnIsNeedToConfirm ? (
              <>
                <Paper className={classes?.bgBox2} sx={{ marginTop: "calc(49vh - 100px)" }}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs="12">
                      <Typography as="subtitle2" className={classes?.mainTitle} p-0>
                        {labels?.needToConfirmation?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs="12">
                      <FormField objHandler={fields.strBadgeID} icon="password" mode={"classic"} color={App_info_Color} className={classes?.field} dir="ltr" />
                    </Grid>
                    <Grid item xs="12">
                      <Button
                        onClick={updateBadgeView}
                        label={dictionary?.shared?.buttons?.sendBtn?.[lang]}
                        mode="outlined"
                        color={App_info_Color}
                        className={classes?.btn}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : (
              <>
                {/*  <Grid container justifyContent="center">
                  // {!state?.lstCertificateFile?.length ? null : (
                  //   <Grid item xs={4} p-0>
                  //     <Button onClick={initCertificatePreview} icon="receiptLong" mode="link" color={App_info_Color} className={classes?.btnBox} p-0 iconSize="24px" />
                  //   </Grid>
                  // )}

                  {!state?.lstCertificateFile?.length ? null : (
                    <Grid item xs={4} p-0>
                      <Button
                        onClick={() => setState({ ...state, blnOpenInfoModal: true })}
                        icon="info"
                        mode="link"
                        color={App_info_Color}
                        className={classes?.btnBox}
                        p-0
                        iconSize="24px"
                      />
                    </Grid>
                  )}

                  {!state?.lstBadgeFile?.length || state?.blnIsNeedToConfirm ? null : (
                    <Grid item xs={4} p-0>
                      <Button onClick={downloadBadge} icon={"downloadForOffline"} mode="link" color={App_info_Color} className={classes?.btnBox} p-0 iconSize="24px" />
                    </Grid>
                  )}
                </Grid> */}
              </>
            )}

            <Grid
              container
              justifyContent="center"
              sx={{
                opacity: state?.blnIsNeedToConfirm ? 0 : 1,
                height: state?.blnIsNeedToConfirm ? "0px" : "auto",
                marginTop: "24px",
                //marginTop: `calc((100vh - ${state.objBadgeSelectInfo.imageHeight}px - 54px - 12px) / 2)`,
              }}
            >
              {/*  <Grid container justifyContent="center" sx={{ zIndex: 20, width: state?.objBadgeSelectInfo?.imageWidth + "px", position: "absolute", marginTop: "20px" }}>
                {!state?.lstCertificateFile?.length ? null : (
                  <Grid item xs={4} p-0>
                    <Button onClick={initCertificatePreview} icon="localactivity" iconColor={"#9b9997"} color="#e9e6e3" p-0 iconSize="24px" />
                  </Grid>
                )}
                
                {!state?.lstBrochureFile?.length ? null : (
                  <Grid item xs={4} p-0>
                    <a href={App_Server_Url_DownloadFiles + state?.lstBrochureFile?.[0]?.path} target="_blank" rel="noreferrer" className={classes?.btnBoxHref}>
                      <Button icon="schedule" iconColor={"#9b9997"} color="#e9e6e3" p-0 iconSize="24px" />
                    </a>
                  </Grid>
                )}

                {!state?.lstBadgeFile?.length || state?.blnIsNeedToConfirm ? null : (
                  <Grid item xs={4} p-0>
                    <Button onClick={downloadBadge} icon={"downloadForOffline"} iconColor={"#9b9997"} color="#e9e6e3" p-0 iconSize="24px" />
                  </Grid>
                )}
              </Grid> */}

              <Grid
                item
                p-0
                sx={{
                  overflow: "visible",
                  maxWidth: "100vw",
                  maxHeight: "90vh",
                  width: "378px",
                  sm: {
                    width: "95vw",
                  },
                }}
              >
                <img alt="" id={"my-certificate-template"} style={{ zIndex: 5, width: "100%" }} />
                <canvas id={"my-canvas-name"} style={{ position: "absolute", zIndex: 10, overflow: "auto", width: "100%" }}></canvas>
              </Grid>
            </Grid>
          </Container>

          <div style={{ fontFamily: "'MonteCarlo', cursive", opacity: 0 }}>
            {String(appState?.userInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName?.[lang])}
          </div>
        </Container>
      </section>

      <Modal
        open={state.blnOpenInfoModal}
        eventClose={() => {
          setState({ ...state, blnOpenInfoModal: false });
        }}
        className={classes?.modal}
        p-0
      >
        <ModalBody p-0>
          <Grid container justifyContent="space-between">
            <Grid item alignItems="center" justifyContent="center">
              <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
                {labels?.label?.selectModalTitle?.[lang]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs="12" justifyContent="center">
              <Typography as="subtitle1" color={App_info_Color} p-0>
                {state?.objForumInfo?.jsnForumInfo?.strForumName}
              </Typography>
            </Grid>
            {!state?.objSubForumInfo ? null : (
              <Grid item xs="12">
                <Typography as="subtitle3" className={classes?.mainTitle} p-0>
                  {state?.objSubForumInfo?.strSubForumName}
                </Typography>
              </Grid>
            )}
            <Grid item xs="12" justifyContent="center" container>
              <Grid item>
                <Icon icon="calendartoday" color={App_info_Color} />
              </Grid>
              <Grid item>
                <Typography as="body3" color={App_Light_Text_Color} p-0>
                  {state?.objSubForumInfo
                    ? appFunctions.getDateUSFormat(state?.objSubForumInfo?.dtmSubForumData)
                    : appFunctions.getDateUSFormat(state?.objForumInfo?.jsnForumInfo?.dtmForumDate)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs="12" justifyContent="center" container>
              <Grid item>
                <Icon icon="locationonoutlined" color={App_info_Color} />
              </Grid>
              <Grid item>
                <Typography as="body3" color={App_Light_Text_Color} p-0>
                  {state?.objSubForumInfo ? state?.objSubForumInfo?.strSubForumLocation : state?.objForumInfo?.jsnForumInfo?.strForumLocation}
                </Typography>
              </Grid>
            </Grid>

            {!state?.lstBrochureFile?.length ? null : (
              <Grid item py-3 px-2>
                <a href={App_Server_Url_DownloadFiles + state?.lstBrochureFile?.[0]?.path} target="_blank" rel="noreferrer" className={classes?.btnBoxHref}>
                  <Button label={labels?.btnDetails?.[lang]} mode="outlined" color={App_info_Color} className={classes?.btnBox} />
                </a>
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
}
