import React, { useEffect, useRef } from "react";
import "./Box.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const Box = (props) => {
  const { color, outline, hoverElevation, elevation, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const styleRef = useRef({ strSxClassName: "", strSXComponentID: id || componentsFunction.randomName("cbmis-box") });

  if(props?.[strTestProps]){
    if(props?.color){
      console.log("Box", "color");
    }
    if(props?.outline){
      console.log("Box", "outline");
    }
    if(props?.hoverElevation){
      console.log("Box", "hoverElevation");
    }
    if(props?.elevation){
      console.log("Box", "elevation");
    }
    if(props?.sx){
      console.log("Box", "sx",);
    }
  }
  useEffect(() => {}, [hoverElevation, elevation, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-box");
    } else {
      setSxClassName(
        styleRef,
        styleRef.current.strSXComponentID,
        !outline || parseInt(outline) === 0
          ? {
              background: `${color} !important`,
            }
          : { borderColor: `${color} !important`, color: `${color} !important`, background: `transparent !important` },
        "cbmis-box"
      );
    }
  }, [sx, screenWidth, color, outline]);

  const getOutline = () => {
    switch (parseInt(outline)) {
      case 1:
        return "cbmis-border-width-1";
      case 2:
        return "cbmis-border-width-2";
      case 3:
        return "cbmis-border-width-3";
      case 4:
        return "cbmis-border-width-4";
      case 5:
        return "cbmis-border-width-5";

      default:
        return "cbmis-border-width-0";
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <React.Suspense fallback="">
      <div
        className={`cbmis-box cbmis-box-${elevation || 0} ${getOutline()} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        id={styleRef.current.strSXComponentID}
        {...getRestProps()}
      >
        {props.children}
      </div>
    </React.Suspense>
  );
};

export default Box;
