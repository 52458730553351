import React, { useEffect, useState } from "react";
import { Grid, Loader, Button, Container, Modal, ModalBody } from "@cbmisorg/material";
import eCertificateCtrl from "./eCertificate.controller";
import { useApp } from "../../../configuration/contextapi/context";
import { objStyle } from "./eCertificate.style";
import { ClassesBuilder } from "@cbmisorg/styles";
import { Typography, useParams } from "@cbmisorg/client-app";
import jsPDF from "jspdf";
import "./assets/font.css";
import { FormData, FormField } from "../../../util/formApi/FormAPI";

export default function ECertificatePreviewView() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    strFullName: null,
    strUserEmail: null,
    startX: null,
    startY: null,
    ctxWidth: null,
    ctxHeight: null,
    imageWidth: null,
    imageHeight: null,
    imageTop: null,
    imageLeft: null,
    imageRight: null,
    imageBottom: null,
    strLinkUpload: null,
    blnIsInitDone: false,
    fontColor: "#324476",
    fontSize: 52,
    isApproved: true,
  });
  const param = useParams();

  const fields = FormData({
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: {
        email: true,
        required: true,
      },
    },
  });

  const handlers = eCertificateCtrl({ appState });
  const initCertificatePreview = handlers.initCertificatePreview({ isLoading, setIsLoading, setState, state });

  useEffect(() => {
    let viewport = document.querySelector('meta[name="viewport"]');
    if (window?.innerWidth <= 900) {
      if (viewport) {
        viewport.content = "width=1280, user-scalable=no";
      }
    }

    if (!state?.blnIsInitDone) {
      initCertificatePreview(param?.id);
    }

    return () => {
      if (viewport && viewport.content !== "width=device-width, initial-scale=1") {
        viewport.content = "width=device-width, initial-scale=1";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadCertificate = async () => {
    const canvas = document.getElementById("my-canvas-name");

    const imgAsUrl = canvas?.toDataURL("image/png");

    const docMulti = new jsPDF({
      unit: "px",
      orientation: "landscape",
      format: [state.imageWidth, state.imageHeight],
      //format: [2480, 3508],
    });

    docMulti.addImage(imgAsUrl, "image/png", 0, 0, state.imageWidth, state.imageHeight);
    docMulti.save(`${state?.strFullName}.pdf`);
  };

  return (
    <>
      <section className={classes?.secDark} style={{ paddingTop: "0px" }}>
        {!isLoading ? null : <Loader backdrop={true} color="secondary" />}
        <Container sx={{ filter: !state.isApproved ? "blur(20px)" : null }}>
          <Grid container justifyContent="center">
            <Grid item xs="12">
              <Button icon={"download"} label="PDF" py-0 iconSize="23px" size="sm" onClick={downloadCertificate} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item p-0 sx={{ overflow: "auto", maxWidth: "100vw", maxHeight: "90vh" }}>
              <img alt="" id={"my-certificate-template"} style={{ zIndex: 5, width: "11.69in", height: "8.27in" }} />
              <canvas id={"my-canvas-name"} style={{ /* border: "1px solid red", */ position: "absolute", zIndex: 10, overflow: "auto" }}></canvas>
            </Grid>
          </Grid>
        </Container>
      </section>
      <div style={{ fontFamily: "'MonteCarlo', cursive", opacity: 0 }}>{String(state?.strFullName)}</div>

      <Modal open={!state.isApproved} eventClose={() => null}>
        <ModalBody>
          <Grid container>
            <Grid item xs={12} justifyContent="center">
              <Typography as="body3" sx={{ color: "#0d6883" }}>
                {{ arb: "يرجى إدخال بريدك الإلكتروني لعرض الشهادة الالكترونية", eng: "Please enter your email address to view the electronic certificate." }?.[lang]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fields.strUserEmail} icon={"email"} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item>
              <Button
                label={{ arb: "عرض الشهادة", eng: "View Certificate" }?.[lang]}
                onClick={() => {
                  if (!fields.controller.isFormValid()) {
                    return;
                  }
                  setState({ ...state, isApproved: true });
                }}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
}
