import React, { useEffect, useRef } from "react";
import "./Card.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const CardImage = (props) => {
  const { src, title, hoverContent, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  if(props?.[strTestProps]){
    if(props?.src){
      console.log("CardImage", "src",);
    }
    if(props?.title){
      console.log("CardImage", "title",);
    }
    if(props?.hoverContent){
      console.log("CardImage", "hoverContent",);
    }
    if(props?.sx){
      console.log("CardImage", "sx",);
    }
  }
  const getID = useRef(id || componentsFunction.randomName("CardImage"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [src, title, hoverContent, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-card-image");
    }
  }, [sx, screenWidth]);

  return (
    <div
      id={getID.current}
      className={`cbmis-card-image ${hoverContent ? "cbmis-card-image-hover" : ""} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      style={{ backgroundImage: `url(${src})` }}
      {...getRestProps()}
      title={title}
    >
      <div className="cbmis-card-frame-content">{props.children}</div>
      <div className={`cbmis-card-frame`}>
        <span className="cbmis-card-frame-bg"></span>
        <div className="cbmis-card-frame-content">{hoverContent}</div>
      </div>
    </div>
  );
};

export default CardImage;
