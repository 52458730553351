import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./MultipleList.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import { setSXStyle } from "../../../helper/convertJsCss";

import Menu from '../../navigation/Menu/Menu'
import MenuItem from '../../navigation/Menu/MenuItem'
import Line from "../../layout/Line/Line";
import Text from "../Text/Text";

const MultipleList = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,checkMode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,onFocus,options=[],optionStyle,autocomplete=true,
          sx,hidden,disabled,dir,className,readOnly,...rest} = props

    const inputRef = useRef({})

    const nameID = useRef(id || componentsFunction.randomName("MultipleList"));
    const [initialState, setInitialState] = useState({
      listValues:[],
      listKeys:[],
      listObjValues:[],
      openMenu:false,
      filterOption: options,
      autocompleteValue :""
    })

  //#region Effect
    useEffect(()=>{
        initialState.filterOption = options
        if(value || defaultValue){
          const theValue = value || defaultValue
          const values = []
          const keys = []
          theValue.forEach(item=>{
              values.push(item.value)
              keys.push(item.key)
          })
          inputRef.current.value = values
          inputRef.current.values = values
          inputRef.current.keys = keys
          if(ref){
            ref.current.value = values
            ref.current.values = values
            ref.current.keys = keys
          }
          initialState.listValues = values
          initialState.selectedKey = keys
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value, defaultValue, options, ref])

    useEffect(()=>{
    },[id,label,placeholder,errorMessage,mode,
        color,icon,iconColor,adornment,endIcon,endAdornment,
        value,defaultValue,
        sx,hidden,disabled,dir,className,rest,
        initialState])

    useEffect(() => {
      document.body.style.overflowY = "";
    }, []);

    useEffect(() => {
      setInitialState({
        ...initialState,
        filterOption:options,
      })
    }, [options])
    
//#endregion

  //#region style
    const getBoxMarkCheck = () => {
      const getMarkStyle = String(checkMode).toLowerCase();
      switch (getMarkStyle) {
        case "tick":
          return "cbmis-checkbox-tick";
        case "cross":
          return "cbmis-checkbox-cross";
        case "indeterminate":
          return "cbmis-checkbox-indeterminate";
        default:
          return "cbmis-checkbox-tick";
      }
    };
  //#endregion

  //#region event
  const handleInputTextChange = (e) => {
    if (options && Array.isArray(options)) {
      const arrStartsWith = [];
      const arrIncludes = [];

      options.forEach((item) => {
        if (String(item.value).toLowerCase().startsWith(String(e.target.value).toLowerCase())) {
          arrStartsWith.push(item);
        } else if (String(item.value).toLowerCase().includes(String(e.target.value).toLowerCase())) {
          arrIncludes.push(item);
        }
      });

      const arrOption = [...arrStartsWith, ...arrIncludes];

      initialState.autocompleteValue = e.target.value
      initialState.filterOption = arrOption;
      setInitialState({ ...initialState });
    }
  };
  const handelChange = (event) => {
    const selectedValue = event.target.value;
    var selectedKey = event.target.value;
    if (event.target.id !== "") {
      selectedKey = event.target.id;
    }
    if(event.target.checked){
      initialState.listValues.push(selectedValue)
      initialState.listKeys.push(selectedKey)
      initialState.listObjValues.push({key:selectedKey, value:selectedValue})

    }else{
      initialState.listValues =initialState.listValues.filter(value=>value!==selectedValue)
      initialState.listKeys = initialState.listKeys.filter(key=>key!==selectedKey)
      initialState.listObjValues = initialState.listObjValues.filter(item=>item.key!==selectedKey && item.value!==selectedValue)
    }
    inputRef.current.value = initialState.listValues;
    inputRef.current.listValues = initialState.listValues
    inputRef.current.listKeys = initialState.listKeys
    inputRef.current.listObjValues = initialState.listObjValues

    if(ref){
      ref.current.value = initialState.listValues;
      ref.current.listValues = initialState.listValues
      ref.current.listKeys = initialState.listKeys
      ref.current.listObjValues = initialState.listObjValues
    }
    if (onChange) {
      onChange(event);
    }else{
    }
  };
  const handelFocus = (e) => {
    if(!readOnly){
      document.body.style.overflowY = "hidden";
      const left = e.target.getBoundingClientRect().left;
      const top = e.target.getBoundingClientRect().top;
      const width = e.target.offsetWidth;
      // const height = e.target.offsetHeight;
      const txlist = { top: `${top + 2}px !important`, left: `${left}px !important`, width: `${width}px !important` };
      setSXStyle(`cbmis-options-list-${nameID.current}`, txlist);

      initialState.openMenu=true
      setInitialState({ ...initialState });
    }

    if (onFocus) {
      onFocus(e);
    }
  };
  const handelMouseLeave = () => {
    if (initialState.openMenu) {
      initialState.openMenu = false;
      initialState.autocompleteValue = ""
      initialState.filterOption = options
      setInitialState({ ...initialState });
      document.body.style.overflowY = "";
    }
  };
  //#endregion

  //#region list
    const getOptionsMultipleList=()=>{
      const Options = []
      const SelectedOptions = []

        options.filter(option=>initialState.listValues.includes(option.value)).forEach(
          (option,index) => {
            if(option.key){
              SelectedOptions.push(
                <MenuItem key={option.key + index} selected={false} sx={optionStyle || {}}>
                <label key={option.key} htmlFor={option.value} className={`cbmis-checkbox-container cbmis-checkbox-multipleList-container`}>
                  <div style={{ color: color ? componentsFunction.checkColorReturnValue(color) : "var(--cbmis-primary)" }}>
                    <input
                        type="checkbox"
                        id={option.key}
                        key={option.key + index}
                        name={nameID.current}
                        className="cbmis-input-checkbox"
                        defaultValue={option?.value || ""}
                        defaultChecked={defaultValue?.value === option?.value || ref?.current?.value === option.value ||inputRef?.current?.value === option.value || initialState.listValues.includes(option.value)}
                        onChange={handelChange}
                      />

                    <span className={`cbmis-box-mark-check ${getBoxMarkCheck()}`}></span>
                  </div>
                  <span className={`cbmis-option-input-label`}>{option.value}</span>
                </label>
              </MenuItem>
              )
              }
          }
        )
        SelectedOptions.push(<Line />)

       if(initialState.listValues.length){
        Options.push(
          <dev className="cbmis-selected-options-list">
            {SelectedOptions}
          </dev>
        )
       }
       initialState.filterOption.filter(option=>!initialState.listValues.includes(option.value)).forEach((option,index)=>{
            if(option.key){
            Options.push(
              <MenuItem key={option.key + index} selected={false} sx={optionStyle || {}}>
              <label key={option.key} htmlFor={option.value} className={`cbmis-checkbox-container cbmis-checkbox-multipleList-container`}>
                <div style={{ color: color ? componentsFunction.checkColorReturnValue(color) : "var(--cbmis-primary)" }}>
                  <input
                      type="checkbox"
                      id={option.key}
                      key={option.key + index}
                      name={nameID.current}
                      className="cbmis-input-checkbox"
                      defaultValue={option?.value || ""}
                      defaultChecked={defaultValue?.value === option?.value || ref?.current?.value === option.value ||inputRef?.current?.value === option.value || initialState.listValues.includes(option.value)}
                      onChange={handelChange}
                    />

                  <span className={`cbmis-box-mark-check ${getBoxMarkCheck()}`}></span>
                </div>
                <span className={`cbmis-option-input-label`}>{option.value}</span>
              </label>
            </MenuItem>
            )
            }else{
              Options.push(
                <MenuItem key={index} sx={optionStyle || {}} p-1 onClick={handelMouseLeave}>
                {option}
              </MenuItem>
              )
            }
      })
      return (
        <React.Fragment>
            <div className={autocomplete?"cbmis-options-list-with-autocomplete":"cbmis-options-list-without-autocomplete"}>{Options}</div>
            {
              autocomplete?(
                <React.Fragment>
                    <Line />
                    <dev className="autocompleteSearchInput">
                      <Text icon="search"
                            defaultValue={initialState.autocompleteValue}
                            onChange={handleInputTextChange}
                            color={color}
                            iconColor={color}
                            mode="classic"

                      />
                    </dev>
                </React.Fragment>
              ):""
            }
        </React.Fragment>
      )
    }

  //#endregion

  return (
    <React.Suspense fallback="">
      <div className={initialState.openMenu ? "lstContainerOpen" : "lstContainerClose"} onClick={handelMouseLeave} />
      <Text id={nameID.current} name={nameID.current}
                  ref={ref || inputRef}
                  onFocus={handelFocus}
                  readOnly={true}
                  label={label}
                  placeholder={placeholder}
                  mode={mode}
                  color={color}
                  icon={icon}
                  iconColor={iconColor}
                  adornment={adornment}
                  sx={sx}
                  hidden={hidden}
                  disabled={disabled}
                  dir={dir}
                  className={className}
                  endIcon={endIcon || "arrowDropDown"}
                  errorMessage={errorMessage}
            />
      <div className={componentsFunction.CheckDirectionItem(dir)}>
        {initialState.filterOption ? 
          <Menu  open={initialState.openMenu} className={`cbmis-options-list cbmis-options-list-${nameID.current} ${autocomplete?"cbmis-options-list-autocompleteSearch":""}`}>
                  {getOptionsMultipleList()}
          </Menu> 
        :""} 
      </div>
      </React.Suspense>
    )
});

export default MultipleList