import React, { useEffect, useRef, useState } from "react";
import "./Counter.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function Counter(props) {
  const { count, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();
  const getID = useRef(id || componentsFunction.randomName("Counter"));
  const styleRef = useRef({ strSxClassName: "" });

  const maximum = parseInt(count) || 100;
  const [counterState, setCounterState] = useState(0);

  const counter = () => {
    for (let i = 0; i <= maximum; i++) {
      setTimeout(() => {
        setCounterState(i);
      }, 1000);
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [hidden, disabled, dir, className, id]);

  useEffect(() => {
    return () => {
      counter();
    };
  }, [count]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-counter");
    }
  }, [sx, screenWidth]);

  return (
    <React.Fragment>
      <div
        id={getID.current}
        className={`cbmis-counter ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.CheckDirectionItem(
          dir
        )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
      >
        {counterState}
      </div>
    </React.Fragment>
  );
}

export default Counter;
