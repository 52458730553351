// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useNavigate } from "@cbmisorg/router";
import { Button, Container, Grid, Typography, Loader } from "@cbmisorg/material";

import { useApp } from "../../../configuration/contextapi/context";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { dictionary } from "../../../util/appLanguage/dictionary";

import { CtrlRegistration } from "./Registration.controller";
import { objStyle } from "./Registration.style";

import ShredHeader from "../../shared/shredHeader";
import { App_info_Color } from "../../../util/appStyle";
import appRouting from "../../../configuration/routing/appRouting";
import { Icon } from "@cbmisorg/icons";
import { useSearchParams } from "react-router-dom";
import { useParams } from "@cbmisorg/client-app";
import useCheckSystemInfo from "../../../util/hooks/useCheckSystemInfo";
import useCheckCat from "../../../util/hooks/useCheckCat";

const labels = dictionary?.components?.public?.registration;

function RegistrationView() {
  useCheckHooksGlb();
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const { getNewCatInfo } = useCheckCat({ appState, blnIsNotHook: true });
  const { getNewSystemInfo } = useCheckSystemInfo({ appState });

  const { countryCode } = useParams();
  const [searchParams] = useSearchParams();
  const langSearch = searchParams.get("lang");
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const lang = langSearch ? langSearch : appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    countryCode: "",
    blnDefaultCountry: true,
  });

  const fields = FormData({
    strFullName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: () => true, email: true },
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: true,
        minLength: 4,
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: true,
        minLength: 4,
        equal: "strUserPassword",
      },
    },

    bigCountryOfCitizenID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfResidenceID: { control: "list", validations: {}, blnLanguage: false },
    bigAreaCountryID: { control: "list", validations: {}, blnLanguage: false },
    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true, minLength: 5, maxLength: 10 },
    },
  });

  const handlers = CtrlRegistration({ appState, appDispatch, isLoading, setIsLoading, countryCode });
  const handleSubmit = () => handlers.handleSubmit({ fields, navigate, state });
  const onChangeContry = handlers.onChangeContry({ fields, setState, state });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields });

  useEffect(() => {
    if (appState?.clientInfo?.dtmLastLoggingIn) {
      navigate(appRouting?.Account?.url);
    }
    document.body.classList.add("paddingBottom");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (langSearch && langSearch !== lang) {
      appState.clientInfo.strLanguage = langSearch;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langSearch]);

  useEffect(() => {
    if (!state.blnIsInitDone) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openWindow = (path) => () => {
    if (typeof window !== "undefined" && path) {
      window.open(path, "_blank");
    }
  };

  return (
    <React.Fragment>
      <ShredHeader lang={lang} title={labels?.title?.[lang]} blnBack={!langSearch} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"} alignSelf="center">
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container} pt-10>
                {!jsnSystemConfig?.lstContryDetailsRegisterCode?.includes(state?.countryCode) ? null : (
                  <Grid container justifyContent="center">
                    <Grid item xs="12" justifyContent="center">
                      <Typography as="body2" className={`${classes?.labelsLight} ${classes?.descriptionLightText}`}>
                        {state.blnDefaultCountry ? jsnSystemConfig?.strDetailsAAiotDefaultContryRegister?.[lang] : jsnSystemConfig?.strDetailsAAiotRegister?.[lang]}
                      </Typography>
                      <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`}>
                        <Grid item xs="auto" md="0">
                          <Icon icon="KeyboardArrowUp" size="28px" color={App_info_Color} onClick={() => {}} />
                        </Grid>
                      </Grid>
                    </Grid>
                    {(state.blnDefaultCountry && !jsnSystemConfig?.strDetailsAAiotDefaultContryRegisterLink) ||
                    (!state.blnDefaultCountry && !jsnSystemConfig?.strDetailsAAiotRegisterLink) ? null : (
                      <Grid item xs="12">
                        <Button
                          label={dictionary?.shared?.buttons?.details?.[lang]}
                          size="sm"
                          color={App_info_Color}
                          mode="outlined"
                          className={classes?.btnbox}
                          onClick={openWindow(state.blnDefaultCountry ? jsnSystemConfig?.strDetailsAAiotDefaultContryRegisterLink : jsnSystemConfig?.strDetailsAAiotRegisterLink)}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item className={classes?.subtitle}>
                      <Typography as={lang === "arb" ? "subtitle1" : "subtitle2"} className={classes?.subtitleLabel}>
                        {labels?.registrationInfo?.[lang]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} pt-0>
                      <FormField objHandler={fields.strFullName} icon="AccountCircle" mode={"classic"} color={App_info_Color} className={classes?.field} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} onChange={onChangeContry}/>
                    </Grid>
                    {!jsnSystemConfig?.blnContryName ? null : (
                      <Grid item xs={12}>
                        <FormField objHandler={fields.bigCountryOfResidenceID} icon="public" mode={"classic"} color={App_info_Color} className={classes?.field} />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormField objHandler={fields.strUserPhone} icon="phone" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} endAdornment={fields?.bigAreaCountryID?.getValue()?.value?.[lang]} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
                    </Grid>
                    <Grid item xs={12} md="6">
                      <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
                    </Grid>
                    <Grid item xs={12} md="6">
                      <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs="12" pt-5 pb-5>
                      <Button py-0 px-10 label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={handleSubmit} className={classes?.btn} />
                    </Grid>
                  </Grid>
                  <input type="submit" style={{ visibility: "hidden" }} onClick={handleSubmit} />
                </form>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* <div className={classes?.anmition}>
          <div className={classes?.anmitiongif} />
        </div> */}
    </React.Fragment>
  );
}

export default RegistrationView;
