import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import volunteerSetterModalCtrl from "./volunteerSetterModal.controller";
import { App_info_Color, App_Light_Color } from "../../../../../../util/appStyle";
import { workSectorID } from "../../../../../../configuration/appVariables";

import { Icon } from "@cbmisorg/icons";
import WorkSectorOption from "../regActivityGuest/component/WorkSectorOption.view";
import { modalOverFllow } from "../../../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.account?.volunteerSetter;

export default function VolunteerSetterModalView({ parentState, isLoading, setIsLoading, classes, blnPublic = false }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({ blnOpenSetterModal: false, blnIsNew: true, blnHoverd: false });
  const fields = FormData({
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    bigMajorID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigDegreeID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    bigEmploymentID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfCitizenID: { control: "list", validations: { required: true }, blnLanguage: false },

    bigCountryOfResidenceID: { control: "list", blnLanguage: false }, //disabled

    // student
    strUniversityName: { control: "list", validations: {}, blnLanguage: false },
    bigAcademicYearID: { control: "list", validations: {}, blnLanguage: false },
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: {},
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },

    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn, email: !appState?.clientInfo?.blnIsUserLoggedIn },
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn, minLength: !appState?.clientInfo?.blnIsUserLoggedIn ? 4 : false },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn, minLength: !appState?.clientInfo?.blnIsUserLoggedIn ? 4 : false },
    },
  });

  const handlers = volunteerSetterModalCtrl({ appDispatch, appState, isLoading, setIsLoading });
  const openModal = handlers.openModal({ fields, setState, state });
  const updateVolunteerInfo = handlers.updateVolunteerInfo({ fields, setState, state });
  const onChangeCountry = handlers.onChangeCountry({ fields });
  const handelChangeEmployment = handlers.handelChangeEmployment({ fields });

  useEffect(() => {
    return () => {
      modalOverFllow();
    };
  }, []);

  return {
    openEle: (
      <Paper className={classes?.bgBox}>
        <Grid container justifyContent="center" sx={blnPublic ? { paddingTop: "15px" } : {}}>
          <Grid item>
            <Typography as="subtitle2" className={classes?.mainTitle}>
              {labels?.title?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12" md="12" className={classes?.descriptionLightTextContainer} key="trainingContainer">
            <Typography
              as="body2"
              color={App_Light_Color}
              className={
                blnPublic
                  ? classes?.descriptionLightTextActive
                  : `${!parentState?.objPartnerInfo?.jsnSupportTrans?.length ? classes?.descriptionLightText : classes?.descriptionLightText5} ${
                      state?.blnHoverd ? classes?.descriptionLightTextActive : ""
                    }`
              }
              key="training"
              onMouseEnter={() => {
                setState({ ...state, blnHoverd: true });
              }}
              onMouseLeave={() => {
                setState({ ...state, blnHoverd: false });
              }}
            >
              {labels?.description?.[lang]}
            </Typography>
            {blnPublic ? null : (
              <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`} key="arrowClose_training">
                <Grid item xs="auto" md="0" my-2 key="arrowClose_training_item">
                  <Icon icon="KeyboardArrowUp" size="30px" color={App_info_Color} onClick={() => {}} key="arrowClose_icon" />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Button
              label={appState?.userInfo?.blnIsVolunteer ? dictionary?.shared?.buttons?.editUpd?.[lang] : labels?.btn?.[lang]}
              mode="outlined"
              color={App_info_Color}
              onClick={openModal}
              className={classes?.btnBox}
            />
          </Grid>
        </Grid>
      </Paper>
    ),

    modal: (
      <Modal
        open={state.blnOpenSetterModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenSetterModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <FormField objHandler={fields.strFullName} icon="person" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="12">
              <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} onChange={onChangeCountry} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigEmploymentID} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} onChange={handelChangeEmployment} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigDegreeID} icon="documentScannerRounded" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            {handlers?.objCat?.Root?.employment?.student?.id === fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.strUniversityName} icon="accountBalance" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.bigAcademicYearID} icon="AutoStories" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
              </React.Fragment>
            ) : handlers?.objCat?.Root?.employment?.unemployed?.id !== fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                <Grid item xs={12} sm="12">
                  <FormField objHandler={fields.bigWorkSector} icon="Work" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>

          {appState?.clientInfo?.blnIsUserLoggedIn ? null : (
            <Grid container spacing={3} pt-0>
              <Grid item className={classes?.subtitle}>
                <Typography as="subtitle1" className={classes?.subtitleLabel}>
                  {dictionary?.components?.public?.regActivityGuest?.registrationInfo?.[lang]}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  objHandler={fields.strUserEmail}
                  icon="email"
                  mode={"classic"}
                  iconColor={App_info_Color}
                  color={App_info_Color}
                  inputDir="ltr"
                  className={classes?.field}
                />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs="12" pt-8 pb-5>
              <Button
                py-0
                px-10
                label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
                color={App_info_Color}
                onClick={updateVolunteerInfo}
                mode="outlined"
                className={classes?.btnBox}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
