import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

export const SECURE_ROUTE_ROLE_TYPE = {
  defaultRole: "defaultRole",
  blnMultiFlag: "blnMultiFlag",
};

/**
 *
 * @param {{[x:string] : {url, role?, status?, featureID?, customAuth?, featureAuth?}}} routingConfig
 * @param {{
* userRole?: object | string | number | Function | undefined,
* userStatus?: object | string | number | Function | undefined,
* userFeature?: object | number[] | Function | undefined,
* userAuth?: () => {userRole?: object | string | number, userStatus?: object | string | number, userFeature?: number[] }
* }} userInfo
* @param {string} errorPath
* @param {{strRoleType?: "blnMultiFlag" | "defaultRole"  ,blnIsTestingMode?: boolean, blnIsLocalStorageEncrypted?: boolean, strLocalStorageEncryptKey?: string  }} objRouteConfig
* @returns {(props: {children?: JSX.Element, element?: JSX.Element}) => JSX.Element | null}
*/
export function SecureRoute(
 routingConfig,
 userInfo = undefined,
 errorPath = "/",
 objRouteConfig = { strRoleType: "defaultRole", blnIsTestingMode: true, blnIsLocalStorageEncrypted: false, strLocalStorageEncryptKey: "secure" }
) {
 if (!objRouteConfig) {
   objRouteConfig = {};
 }
 if (!objRouteConfig?.strLocalStorageEncryptKey) {
   objRouteConfig.strLocalStorageEncryptKey = "secret";
 }
 if (!objRouteConfig?.blnIsLocalStorageEncrypted) {
   objRouteConfig.blnIsLocalStorageEncrypted = false;
 }
 if (!objRouteConfig?.strRoleType) {
   objRouteConfig.strRoleType = "defaultRole";
 }
 if (!userInfo) {
   userInfo = {};
 }
 if (!userInfo?.userRole) {
   userInfo.userRole = null;
 }
 if (!userInfo?.userStatus) {
   userInfo.userStatus = null;
 }
 if (!userInfo?.userFeature) {
   userInfo.userFeature = null;
 }
 if (!userInfo?.userAuth) {
   userInfo.userAuth = null;
 }

 function isAuth(pathname) {
   try {
     let lstRoutingConfig = Object.entries(routingConfig);
     let objConfig = null;

     let lstPotentialMatch = [];
     for (let i = 0; i < lstRoutingConfig.length; i++) {
       if (String(lstRoutingConfig?.[i]?.[1]?.url).toLowerCase() === String(pathname).toLowerCase()) {
         objConfig = lstRoutingConfig?.[i]?.[1];

         break;
       }

       if (String(lstRoutingConfig?.[i]?.[1]?.url).includes(":")) {
         if (String(lstRoutingConfig?.[i]?.[1]?.url).split("/").length === String(pathname).split("/").length) {
           lstPotentialMatch.push(lstRoutingConfig?.[i]?.[1]);
         } else if (String(lstRoutingConfig?.[i]?.[1]?.url).split("/").length > 1) {
           if (String(lstRoutingConfig?.[i]?.[1]?.url).split("/").length - 1 === String(pathname).split("/").length) {
             lstPotentialMatch.push(lstRoutingConfig?.[i]?.[1]);
           }
         }
       }
     }

     if (!objConfig) {
       if (lstPotentialMatch.length === 1) {
         objConfig = lstPotentialMatch[0];
       } else {
         for (let i = 0; i < lstPotentialMatch.length; i++) {
           let lstPathname = String(pathname).split("/");
           let lstPotentialSplit = String(lstPotentialMatch[i]?.url).split("/");

           let blnIsMatched = false;
           for (let j = 0; j < lstPathname.length; j++) {
             if (!String(lstPotentialSplit[j]).includes(":")) {
               if (lstPathname[j] === lstPotentialSplit[j]) {
                 blnIsMatched = true;
               } else {
                 blnIsMatched = false;
                 break;
               }
             }
           }

           if (blnIsMatched) {
             objConfig = lstPotentialMatch[i];
             break;
           }
         }
       }
     }

     let featureID = objConfig?.featureID;
     let role = objConfig?.role;
     let status = objConfig?.status;
     let customAuth = objConfig?.customAuth;
     let featureAuth = objConfig?.featureAuth;
     let roleGivin = userInfo.userRole;
     let statusGivin = userInfo.userStatus;
     let featureGivin = userInfo.userFeature;

     if (featureID) {
       setAppLocalStorage(featureID, "FID", objRouteConfig.blnIsLocalStorageEncrypted, objRouteConfig.strLocalStorageEncryptKey);
     }

     if (userInfo?.userAuth && typeof userInfo?.userAuth === "function") {
       let userAuthGiven = userInfo?.userAuth();
       roleGivin = userAuthGiven?.userRole;
       statusGivin = userAuthGiven?.userStatus;
       featureGivin = userAuthGiven?.userFeature;
     } else {
       if (typeof roleGivin === "function") {
         roleGivin = userInfo.userRole();
       }

       if (typeof statusGivin === "function") {
         statusGivin = userInfo.userStatus();
       }

       if (typeof featureGivin === "function") {
         featureGivin = userInfo.userFeature();
       }
     }

     if ((!role || (Array.isArray(role) && role.length < 1)) && (!status || (Array.isArray(status) && status.length < 1)) && !customAuth && !featureAuth) {
       return true;
     }

     if (customAuth) {
       return customAuth(pathname, roleGivin, statusGivin, featureGivin);
     }

     if (featureID && featureGivin && featureAuth) {
       if (Array.isArray(featureGivin) && (featureGivin?.includes(Number(featureID)) || featureGivin?.includes(String(featureID)))) {
         return true;
       } else {
         return false;
       }
     }

     if (role && !status) {
       if (objRouteConfig.strRoleType === "defaultRole") {
         if (Array.isArray(role)) {
           return role.includes(roleGivin);
         } else {
           return String(roleGivin) === String(role);
         }
       } else {
         if (Array.isArray(role)) {
           let isAuth = false;

           for (let i = 0; i < role.length; i++) {
             if (roleGivin?.[role[i]]) {
               isAuth = true;
               break;
             }
           }
           return isAuth;
         } else {
           return roleGivin?.[role] ? true : false;
         }
       }
     }

     if (role && status) {
       if (objRouteConfig.strRoleType === "defaultRole") {
         if (Array.isArray(role)) {
           return role.includes(roleGivin) && String(status) === String(statusGivin);
         } else {
           return String(roleGivin) === String(role) && String(status) === String(statusGivin);
         }
       } else {
         if (Array.isArray(role)) {
           let isAuth = false;

           for (let i = 0; i < role.length; i++) {
             if (roleGivin?.[role[i]]) {
               isAuth = true;
               break;
             }
           }
           return isAuth && String(status) === String(statusGivin);
         } else {
           return roleGivin?.[role] && String(status) === String(statusGivin) ? true : false;
         }
       }
     }

     return false;
   } catch (error) {
     if (objRouteConfig?.blnIsTestingMode) {
       console.log(" ");
       console.log("*------------------( SecureRoute )----------------------------");
       console.log("*");
       console.log(error);
       console.log("*");
       console.log("**********************************************************************************************************");
       console.log(" ");
     }
     return false;
   }
 }

 return function ReqAuth(props) {
   const location = useLocation();

   if (isAuth(location.pathname)) {
     return props?.children ? props?.children : props?.element;
   } else {
     return <Navigate to={errorPath} replace />;
   }
 };
}
function setAppLocalStorage(appStateObject, strLocalStorageName, blnIsEncrypted = false, strEncryptKey = "secret") {
  let plainTextLocalStorage = JSON.stringify(appStateObject);

  if (blnIsEncrypted) {
    let cipherTextLocalStorage = encryptText(plainTextLocalStorage, strEncryptKey);
    localStorage.setItem(strLocalStorageName, cipherTextLocalStorage);
  } else {
    localStorage.setItem(strLocalStorageName, plainTextLocalStorage);
  }
}

function encryptText(strPlaintText, strKeyOfEncrypt) {
  // Encrypt
  let cipherText = String(CryptoJS.AES.encrypt(strPlaintText, strKeyOfEncrypt));
  return cipherText;
}