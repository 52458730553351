import React, { useState } from "react";
import * as XLSX from "xlsx";
import { logError } from "../../../../../../util/appFunctions";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { Alert } from "@cbmisorg/material";
import { ClientFunction } from "@cbmisorg/helper";
const activityGuestApi = generateQueries("tblActivityGuest");

const strPageInfo = "@src/client/component/stakeholders/admin/mngRegForum/components/addGuestManual/addGuestManual.js";
export default function AddGuestManual() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  const [state, setState] = useState({ lstUser: [] });

  const uploadFile = async (event) => {
    const fileList = event.target.files;
    if (fileList) {
      const reader = new FileReader();
      let lstUsers = [];
      reader.onload = (event) => {
        /* Parse data */
        const fileArrayBuffer = event.target.result;
        const workBook = XLSX.read(fileArrayBuffer, { type: "binary" });

        /* Get first worksheet */
        const workSheetName = workBook?.SheetNames?.[0];
        const workSheet = workBook?.Sheets?.[workSheetName];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(workSheet);

        for (let i = 0; i < data.length; i++) {
          let strFullName =
            data?.[i]?.name ||
            data?.[i]?.names ||
            data?.[i]?.Name ||
            data?.[i]?.Names ||
            data?.[i]?.NAMES ||
            data?.[i]?.NAME ||
            data?.[i]?.["Full Name"] ||
            data?.[i]?.["Full Names"] ||
            data?.[i]?.["full names"] ||
            data?.[i]?.["FULL NAME"] ||
            data?.[i]?.["FULL NAMES"] ||
            data?.[i]?.["FullName"] ||
            data?.[i]?.["fullname"] ||
            data?.[i]?.["الاسم"] ||
            data?.[i]?.["الإسم"] ||
            data?.[i]?.["اسم"] ||
            data?.[i]?.["الأسماء"] ||
            data?.[i]?.["الاسماء"] ||
            data?.[i]?.["أسماء"] ||
            data?.[i]?.["اسماء"] ||
            data?.[i]?.["الإسم الكامل"] ||
            data?.[i]?.["اسم كامل"] ||
            data?.[i]?.["إسم كامل"] ||
            data?.[i]?.["الاسم الكامل"];
          if (!strFullName) {
            continue;
          }

          strFullName = String(strFullName).trim().replaceAll(" . ", " ").replaceAll("..", " ");
          if ((strFullName.includes(". ") || strFullName.includes(" .")) && !strFullName.includes("أ.") && !strFullName.includes("د.") && !strFullName.includes("م.")) {
            strFullName = String(strFullName).replaceAll(" .", " ").replaceAll(". ", " ");
          }

          lstUsers.push({
            strFullName,
            strUserEmail: data?.[i]?.["البريد الإلكتروني"],
            strPlaceName: data?.[i]?.["المسمى الوظيفي"],
          });
        }
      };

      reader.onloadend = async (event) => {
        //state.lstUser = lstUsers;

        for (let i = 0; i < lstUsers.length; i++) {
          await saveActivityGuestInToDB({
            state: {},
            objUserInfo: {
              strUserEmail: lstUsers?.[i]?.strUserEmail,
              strFullName: lstUsers?.[i]?.strFullName,
              strPlaceName: lstUsers?.[i]?.strPlaceName,
            },
          });
        }

        //setState({ ...state });
      };

      reader.readAsArrayBuffer(fileList[0]);
    }
    event.target.value = null;
  };

  const saveActivityGuestInToDB = async ({ objUserInfo, state }) => {
    try {
      const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

      const result = await activityGuestApi(strPageInfo, appState, "aaiotActivityGuestAdd", {
        objectInput: {
          //#region tblUser Information
          bigAppID: appVariables.App_ID,
          bigSystemID: appVariables.aaiot_System_ID,
          bigUserID: appFunctions.generateID(10),
          strUserEmail: objUserInfo?.strUserEmail,
          strUserPassword: "0000",

          strFullName: objUserInfo?.strFullName,
          intUserRoleFK: appVariables?.objUserRole?.Member,

          jsnUserInfo: {
            strFullName: { eng: objUserInfo?.strFullName, arb: objUserInfo?.strFullName },
            intUserRoleFK: appVariables?.objUserRole?.Member,
            bigCountryOfCitizenID: objCat?.Root?.country?.kuwait?.id,
            bigCountryOfResidenceID: objCat?.Root?.country?.kuwait?.id,
          },

          strTrainingTypeCode: Object.entries(appVariables?.countryCodeObj || {}).filter(([key, value]) => value.toLowerCase() === "kuwait")?.[0]?.[0],
          blnIsUserLoggedIn: false, //appState?.clientInfo?.blnIsUserLoggedIn,
          blnIsActivityUser: true,
          blnAddSubjectOnly: true,
          //#endregion
          ///////////////////////////////////////////////////
          //#region tblActivityGuest information
          bigActivityType: objCat?.Root?.activity?.aiweek?.id,
          blnNeedToConfirmed: false,
          blnIsConfirmed: true,
          jsnGuestInfo: {
            objSubject: [objCat?.Root?.activity?.aiweek?.subject?.smartDigitalEmpowerment?.id],
            objTargted: appVariables.workSectorCat?.leader,
            dtmRegister: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
          },
          bigWorkSector: appVariables.workSectorID?.gov,
          strPlaceName: objUserInfo?.strPlaceName || "",
          //#endregion
          ///////////////////////////////////////////////////
          //#region Statistic ??
          //lstStatisticFelidIfUserNew: ["intCountJoinUserAll"],
          //lstStatisticFelidIfGuestActivityNew: ["intCountActivityGuestAll"],
          //#endregion
        },
      });

      if (!result?.blnIsRequestSuccessful) {
        appFunctions.logMessage(result?.response, "");

        return;
      }

      ClientFunction.setAppLocalStorage(
        {
          rqNb: 1,
          rqTM: new Date().toUTCString(),
          lasR: new Date().toUTCString(),
        },
        "SRPM",
        appVariables.App_LocalStorage_Is_Encrypted,
        appVariables.App_LocalStorage_Encrypted_KEY
      );
    } catch (error) {
      logError(strPageInfo, "saveActivityGuestInToDB-ERROR", error);
    }
  };

  return (
    <div>
      <input type="file" multiple={false} onChange={uploadFile} />
    </div>
  );
}
