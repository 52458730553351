import React, {  useEffect, useState } from "react";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { appLanguage, dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Button, Container, Grid, Loader, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/client-app";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import { CtrlAdvertisingVideoManagement } from "./controler/CtrlAdvertisingVideoManagement";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import Uploader from "../../../sharedUi/uploader/Uploader";
import { objUploadType } from "../../../../appHelper/appVariables";
import { useApp } from "../../../../../client/configuration/contextapi/context";

const labels = dictionary?.components?.advertisingVideoManagement;
function AdvertisingVideoManagement() {
  const { getNewSystemInfo } = useCheckSystemInfo();
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    systemInfo: null,
    lstVideos: [],
    blnOpenModal: false,
    selectedVideoInfo: null,
  });
  const uploaderVideo = Uploader(stateInfo, setStateInfo, "lstVideos", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: labels?.video?.[lang],
  });

  const fieldVedio = FormData({
    strVedioUrl: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, maxWord: { condition: 1 } },
    },
    strVedioTitle: {
      strControl: "text",
      objLanguage: appLanguage,
    },
  });

  const fieldSearch = FormData({
    strSearch: {
      strControl: "text",
      objLanguage: false,
    },
  });

  const handlers = CtrlAdvertisingVideoManagement(appState, appDispatch, isLoading, setIsLoading, stateInfo, setStateInfo);
  const handleSubmitModal = handlers.handleSubmitModal({ fieldVedio, uploaderVideo });
  const handleOpenModale = handlers.openModale({ fieldVedio });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      handlers.getSystemInfo({ getNewSystemInfo });
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-5>
        {!isLoading ? null : <Loader backdrop={isLoading} />}
        <Grid container justify={"space-between"}>
          <Grid item xs="10" md="11">
            <FormField objHandler={fieldSearch?.strSearch} icon="searchAlt" />
          </Grid>
          <Grid item xs="2" md="auto">
            <Button icon="add" onClick={handleOpenModale()} />
          </Grid>
        </Grid>
        <Grid container justify={"center"}>
          <Grid item xs="12">
            <Grid container>
              {(stateInfo?.systemInfo?.jsnSystemConfig?.lstAdvVideos || [])?.map((video) => {
                if (
                  fieldSearch?.strSearch?.getValue() &&
                  !JSON.stringify(video?.strVedioTitle)?.includes(fieldSearch?.strSearch?.getValue()) &&
                  !video?.strVedioUrl?.includes(fieldSearch?.strSearch?.getValue())
                ) {
                  return null;
                }
                return (
                  <Grid item xs="12" key={video?.id}>
                    <Paper elevation={"0"} outline={"1"}>
                      <Grid container>
                        <Grid item xs="6" container>
                          <Grid item>
                            <Typography as="body2">{video?.strVedioTitle?.[lang] || video?.strVedioUrl}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="6" container sx={{ justifyContent: "flex-end" }}>
                          <Grid item>
                            <Button icon={"delete"} color="error" mode="link" onClick={handlers?.delateVideoConfirmation(video)} />
                          </Grid>
                          <Grid item>
                            <Button icon={"edit"} color="primary" mode="link" onClick={handleOpenModale(video)} />
                          </Grid>
                          <Grid item>
                            <Button icon={"openInNew"} color="info" mode="link" onClick={() => window.open(`/videoes/${video?.strVedioUrl}`, "_blank")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo.blnOpenModal} eventClose={handlers?.closeModale}>
        <ModalHeader>{labels?.videoInfo?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justify={"center"}>
            <Grid item xs="12" container dir={"ltr"}>
              <Grid item xs="6" sm="5" md="4" lg="3" xl="2">
                <Typography as="caption">{`${window?.location?.origin}/videoes/`}</Typography>
              </Grid>
              <Grid item xs="6" sm="7" md="8"  lg="9" xl="10">
                <FormField objHandler={fieldVedio?.strVedioUrl} dir={"ltr"} />
              </Grid>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldVedio?.strVedioTitle} />
            </Grid>
          </Grid>
          <Grid container justify={"space-between"}>
            <Grid item xs="8">
              <Typography as="caption">{labels?.videoUpload?.[lang]}</Typography>
            </Grid>
            <Grid item mx-xs-auto mx-sm-auto px-1>
              {uploaderVideo?.JSX}
            </Grid>
          </Grid>
          <Grid container justify={"center"} py-3>
            <Grid item xs="12">
              <Button
                py-0
                px-3
                label={!stateInfo?.selectedVideoInfo ? dictionary?.shared?.btn?.addBtn?.[lang] : dictionary?.shared?.btn?.update?.[lang]}
                onClick={handleSubmitModal}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default AdvertisingVideoManagement;
