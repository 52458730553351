import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, aaiot_System_ID, objUserRole } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/components/stakeholders/shared/manageMembers/controler/CtrlManageMembers.js";

const tblUser = generateQueries("tblUser")

export const CtrlManageMembers = (appState, isLoading, setIsLoading, stateInfo, setStateInfo,navigate=null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID

  let handler = {
    getMembers: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        

        setIsLoading(true);
        const result = await tblUser(strPageInfo, appState, "aaiotAppUserAll", {
          objectCondition: {
            bigSystemID: bigSystemID,
            blnIsActive: true,
            blnIsDeleted: false,
            intUserRoleFK: objUserRole.member,
          },
          arrAttributes: objTableAttributes.tblUser.full.filter(
            (field) =>
              field !== "strUserPassword" &&
              field !== "txtLoginKeys" &&
              field !== "txtLoginIP" &&
              field !== "txtPushSubscription" &&
              field !== "intNumLoginAttempt" &&
              field !== "blnIsAccountLocked" &&
              field !== "dtmAccountLocked" &&
              field !== "strLastLoginUserAgent" &&
              field !== "strLastLoginIPAddress" &&
              field !== "txtChangePasswordInfo" &&
              field !== "txtLoginHistoryInfo"
          ),
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang],"warning")

          setIsLoading(false);
          return;
        }

        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : [];

        stateInfo.listMembers = lstTmp
        stateInfo.listReadMembers = lstTmp
        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false)
      }
    },
    searchMember: (e)=>{
      const value = e.target.value;
      if(!value){
        stateInfo.listReadMembers = stateInfo.listMembers
      }else{
        stateInfo.listReadMembers = stateInfo.listMembers.filter(item=>String(item?.jsnUserInfo?.strFullName).startsWith(value) || String(item?.strUserPhone).startsWith(value) || String(item?.strUserEmail).startsWith(value))
      }
      setStateInfo({...stateInfo})
    },
    funDelete:(item)=>{
      const fun=async()=>{
        try {
          if(item){
              if (isLoading) {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
                return;
              }
              setIsLoading(true);
  
              const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
              {
                objectInput:{ blnIsActive:false, blnIsDeleted:true },
                objectCondition:{bigAppID: App_ID, bigUserID:item?.bigUserID, bigSystemID:bigSystemID}
              },
              );
  
              if (!result?.blnIsRequestSuccessful) {
                appFunctions.logMessage(result?.response, "");
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")

                setIsLoading(false);
                return;
              }
              setIsLoading(false)
              stateInfo.listMembers = stateInfo?.listMembers.filter(ele=>ele?.bigUserID !== item?.bigUserID)
              stateInfo.listReadMembers= stateInfo.listMembers
              setStateInfo({ ...stateInfo, blnIsInitDone: true });
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
          }
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false)
        }
      }

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deletemember?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funSave:async(fields,item)=>{
      try {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);

        const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
        {
          objectInput:{

            strUserPhone: fields?.strPhone?.getValue(),
            blnIsActive:fields?.blnIsActive?.getValue()?.key==="1",
            jsnUserInfo: {
              ...item?.jsnUserInfo,
              strFullName:fields?.strFullName?.getValue(),
              bigNationality:fields?.bigNationality?.getValue()?.key,
              strMajor:fields?.strMajor?.getValue(),
              bigEmploymentID:fields?.bigEmploymentID?.getValue()?.key,
              bigDegreeID:fields?.bigDegreeID?.getValue()?.key,
              bigGender:fields?.bigGender?.getValue()?.key,
              dtmDob:fields?.dtmDob?.getValue(),
              strWorkPlace:fields?.strWorkPlace?.getValue(),
            },
          },
          objectCondition:{
            bigAppID: App_ID,
            bigSystemID: item?.bigSystemID || bigSystemID,
            bigUserID:item?.bigUserID,
          }
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning")
          setIsLoading(false);
          return;
        }
        setIsLoading(false)
        setStateInfo({ ...stateInfo, blnIsInitDone: true });
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
      setIsLoading(false)
    }
    },
    funRestPassword:(fields,item)=>{
      const fun=async()=>{
        try {
            if (!fields.isFormValid()) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
              return;
            }
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            setIsLoading(true);

            const result = await tblUser(strPageInfo,appState,"aaiotAppUserResetPassword",
            {
              objectInput:{
                strUserPassword: fields?.strRestPassword?.getValue()
              },
              objectCondition:{
                bigAppID: App_ID,
                bigSystemID: item?.bigSystemID || bigSystemID,
                bigUserID:item?.bigUserID,
              }
            });

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")

              setIsLoading(false);
              return;
            }
            setIsLoading(false)
            setStateInfo({ ...stateInfo, blnIsInitDone: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
            fields?.resetField()
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false)
        }
      }
      if (!fields.isFormValid()) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
        return;
      }
      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.changeUserInfo?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funRestEmail:(fields,item)=>{
      const fun=async()=>{
        try {
            if (!fields.isFormValid()) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
              return;
            }
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            if(String(item?.strUserEmail).toLowerCase().split(" ") === String(fields?.strUserEmail?.getValue()).toLowerCase().split(" ")){
              stateInfo.modalEmailOpen=false
              setStateInfo({ ...stateInfo, blnIsInitDone: true });
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
              return
            }

            setIsLoading(true);
            const resultRead = await tblUser(strPageInfo,appState,"aaiotAppUserOne",{
              objectInput:{
                arrAttributes:["bigUserID"],
                objectCondition:{
                  bigAppID: App_ID,
                  bigSystemID: item?.bigSystemID || bigSystemID,
                  strUserEmail:fields?.strUserEmail?.getValue(),

                }
              }
            })

            if(resultRead.blnIsRequestSuccessful){
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.email_Already_Used?.[lang], "");
              setIsLoading(false);
              return

            }

            const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
            {
              objectInput:{
                strUserEmail: fields?.strUserEmail?.getValue()
              },
              objectCondition:{
                bigAppID: App_ID,
                bigSystemID: item?.bigSystemID || bigSystemID,
                bigUserID:item?.bigUserID,
              }
            });

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")
              setIsLoading(false);
              return;
            }
            setIsLoading(false)
            appState.userInfo.strUserEmail = fields?.strUserEmail?.getValue()
            stateInfo.modalEmailOpen=false
            setStateInfo({ ...stateInfo, blnIsInitDone: true });
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false)
        }
      }

      if (!fields.isFormValid()) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang],"")
        return;
      }
      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.changeUserInfo?.[lang],
        fun,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    funCall:(item, type) => {
      if(type ==="email"){
        window.open(`mailto:${item?.strUserEmail}`)
      }else if(type ==="phone"){
        window.open(`tel:${item?.strUserPhone}`)
      }
    },
    funCloseModal:() => {
      setStateInfo({
        ...stateInfo,
        modalOpen:false,
        modalEmailOpen:false
      })
    },

  };

  return handler;
};
