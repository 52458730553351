import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";

const strPageInfo = "@src/client/component/public/ResearcherRegistration/ResearcherRegistration.ctrl.js";
const tblUser = generateQueries("tblUser");

export const CtrlResearcherRegistration = ({ userInfo, appState, appDispatch, isLoading, setIsLoading, countryCode, fields }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  let jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields, interviewfields, lang }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);
          if (!jsnSystemConfig || JSON?.stringify(jsnSystemConfig)?.length < 500) {
            const objSystem = await getNewSystemInfo(appDispatch);
            jsnSystemConfig = objSystem.jsnSystemConfig
          }
          if (!appState?.clientInfo?.objCatInfo || String(appState?.clientInfo?.objCatInfo)?.length < 500) {
            await getNewCatInfo(appDispatch);
          }
          if (userInfo?.strTrainingTypeCode) {
            state.countryCode = userInfo?.strTrainingTypeCode;
            appState.clientInfo.clientCountryCode = userInfo?.strTrainingTypeCode;
          } else {
            const jsnSystemConfig = jsnSystemConfig;
            if (countryCode && !jsnSystemConfig?.lstContryRegisterCode?.includes(countryCode)) {
              state.countryCode = "";
              navigate(appRouting.page404?.url);
            }
            if (!countryCode) {
              state.countryCode = appState.clientInfo.clientCountryCode ? appState.clientInfo.clientCountryCode : jsnSystemConfig?.strDefaultContryRegisterCode;
            } else {
              state.countryCode = countryCode;
              appState.clientInfo.clientCountryCode = countryCode;
            }
          }

          state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;

          //#endregion set options
          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
          let lstCountryOption = [];
          let lstCountryResidenceOption = [];
          let lstUniversityOption = [];
          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGenderOption = [];
          let lstTypeGovSector = [];
          let lstAeraCodeOption = [];
          let countryID = "";
          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.nationality?.value });
              if (jsnSystemConfig?.lstContryRegisterCode?.includes(value?.code?.value?.eng)) {
                lstCountryResidenceOption.push({ key: value?.id, value: value?.value });
                if (value?.phoneCode) {
                  lstAeraCodeOption.push({ key: value?.id, value: { eng: `+${value?.phoneCode?.value?.eng}`, arb: `+${value?.phoneCode?.value?.arb}` } });
                }
                if (state?.countryCode === value?.code?.value?.eng) {
                  countryID = value?.id;
                  state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.eng}`;
                  Object.entries(value?.university || {}).forEach(([key, value]) => {
                    if (key !== "id" && key !== "value") {
                      lstUniversityOption.push({ key: value?.id, value: value?.value });
                      if (value?.phoneCode) {
                        lstAeraCodeOption.push({ key: value?.id, value: { eng: `+${value?.phoneCode?.value?.eng}`, arb: `+${value?.phoneCode?.value?.arb}` } });
                      }
                    }
                  });

                  lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });
                }
              }
            }
          });

          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value], index) => {
            if (key !== "id" && key !== "value" && index < 6) {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.typeGovSector || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstTypeGovSector.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigCountryOfCitizenID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigCountryOfResidenceID.setOptions([...lstCountryResidenceOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields?.bigAreaCountryID?.setOptions(lstAeraCodeOption);
          fields?.bigAreaCountryID?.setValue(countryID);
          fields?.bigAreaCountryID?.setOptions(lstAeraCodeOption);
          fields.bigUniversityName.setOptions([...lstUniversityOption], true);
          fields.bigAcademicYearID.setOptions([...lstAcademicYearOption]);
          // fields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);
          // interviewfields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);

          fields?.bigAreaCountryID?.setValue(countryID);
          fields?.bigCountryOfCitizenID?.setValue(countryID);
          fields?.bigCountryOfResidenceID?.setValue(countryID);

          //#endregion

          if (!appState?.clientInfo?.blnIsUserLoggedIn) {
            fields?.strUserPassword?.setValidation("required", true, false);
            fields?.strConfirmPassword?.setValidation("required", true, false);
          }

          //#region setValues
          if (userInfo?.jsnUserInfo?.strFullName) {
            if (typeof userInfo?.jsnUserInfo?.strFullName === "object" && userInfo?.jsnUserInfo?.strFullName?.[lang]) {
              fields.strFullName.setValue(userInfo?.jsnUserInfo?.strFullName?.[lang]);
            } else if (typeof userInfo?.jsnUserInfo?.strFullName === "string") {
              fields.strFullName.setValue(userInfo?.jsnUserInfo?.strFullName);
            }
          }
          if (userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(userInfo?.jsnUserInfo?.strMajor);
          }
          if (userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(userInfo?.jsnUserInfo?.bigGender);
          }
          if (userInfo?.jsnUserInfo?.bigWorkSector) {
            fields.bigWorkSector2.setValue(userInfo?.jsnUserInfo?.bigWorkSector);
          }
          if (userInfo?.strUserEmail) {
            fields.strUserEmail.setValue(userInfo?.strUserEmail);
          }
          if (userInfo?.strUserPhone) {
            fields.strUserPhone.setValue(userInfo?.strUserPhone);
          }
          if (userInfo?.jsnUserInfo?.bigWorkGovSectorType) {
            fields.bigWorkGovSectorType.setValue(userInfo?.jsnUserInfo?.bigWorkGovSectorType);
            handler.onChangeWorkGovSectorType(fields)();
          }
          if (userInfo?.jsnUserInfo?.bigGovPlaceName) {
            fields.bigGovPlaceName.setValue(userInfo?.jsnUserInfo?.bigGovPlaceName);
          }
          if (userInfo?.jsnUserInfo?.bigCountryOfCitizenID) {
            fields.bigCountryOfCitizenID.setValue(userInfo?.jsnUserInfo?.bigCountryOfCitizenID);
          }
          if (userInfo?.jsnUserInfo?.bigCountryOfResidenceID) {
            fields.bigCountryOfResidenceID.setValue(userInfo?.jsnUserInfo?.bigCountryOfResidenceID);
          }
          if (userInfo?.jsnUserInfo?.strPositionTitle) {
            fields.strPositionTitle.setValue(userInfo?.jsnUserInfo?.strPositionTitle);
          }
          if (userInfo?.jsnUserInfo?.bigMajorID) {
            fields.bigMajorID.setValue(userInfo?.jsnUserInfo?.bigMajorID);
          }
          if (userInfo?.jsnUserInfo?.bigUniversityName) {
            fields.bigUniversityName.setValue(userInfo?.jsnUserInfo?.bigUniversityName);
          }
          if (userInfo?.jsnUserInfo?.strUniversityName) {
            fields.strUniversityName.setValue(userInfo?.jsnUserInfo?.strUniversityName);
          }
          if (userInfo?.jsnUserInfo?.bigAcademicYearID) {
            fields.bigAcademicYearID.setValue(userInfo?.jsnUserInfo?.bigAcademicYearID);
          }
          if (userInfo?.jsnUserInfo?.strYearGraduation) {
            fields.strYearGraduation.setValue(userInfo?.jsnUserInfo?.strYearGraduation);
          }
          if (userInfo?.jsnUserInfo?.strPlaceName) {
            fields.strPlaceName.setValue(userInfo?.jsnUserInfo?.strPlaceName);
          }
          if (userInfo?.jsnUserInfo?.intExperienceYear) {
            fields.intExperienceYear.setValue(userInfo?.jsnUserInfo?.intExperienceYear);
          }

          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev === "boolean") {
            interviewfields.blnAppsNeedDev.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal) {
            interviewfields.strExplainProposal.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec === "boolean") {
            interviewfields.blnlikeWorkinGivSec.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigWorkGovSectorType) {
            interviewfields.bigWorkGovSectorType.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigWorkGovSectorType);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigGovPlaceName) {
            interviewfields.bigGovPlaceName.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigGovPlaceName);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject === "boolean") {
            interviewfields.blnGraduationProject.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject));
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea === "boolean") {
            interviewfields.blnHaveGoveIdea.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea) {
            interviewfields.strExplainIdea.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs === "boolean") {
            interviewfields.blnExpJs.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs) {
            interviewfields.intYearExpJs.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject) {
            interviewfields.intNumJsProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject) {
            interviewfields.strExpJsProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExFe === "boolean") {
            interviewfields.blnExFe.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExFe));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn) {
            interviewfields.intYearExpFn.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpFeProject) {
            interviewfields.strExpFeProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpFeProject);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode) {
            interviewfields.intYearExpNode.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode === "boolean") {
            interviewfields.blnExpNode.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs) {
            interviewfields.intYearExpJs.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject) {
            interviewfields.intNumNodeProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject) {
            interviewfields.strExpNodeProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExAi === "boolean") {
            interviewfields.blnExAi.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExAi));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi) {
            interviewfields.intYearExpAi.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject) {
            interviewfields.strExpAiProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject);
          }
          if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS === "boolean") {
            interviewfields.blnExCybS.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS));
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS) {
            interviewfields.intYearExpCybS.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS);
          }
          if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject) {
            interviewfields.strExpCybSProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject);
          }
          state.lstIntroductoryVideo = userInfo?.jsnUserInfo?.lstIntroductoryVideo || [];
          state.lstTranscript = userInfo?.jsnUserInfo?.lstTranscript || [];
          // if (typeof userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExMobile === "boolean") {
          //   interviewfields.blnExMobile.setValue(String(userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExMobile));
          // }
          // if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpMobile) {
          //   interviewfields.intYearExpMobile.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpMobile);
          // }
          // if (userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpMobileProject) {
          //   interviewfields.strExpMobileProject.setValue(userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpMobileProject);
          // }
          //#endregion
          appState.clientInfo.strLanguage = lang || "arb";
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    onChangeAreaCode:
      ({ fields, state, setState }) =>
      () => {
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        let lstUniversityOption = [];
        Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            if (fields?.bigAreaCountryID?.getValue()?.key === value?.id || fields?.bigCountryOfResidenceID?.getValue()?.key === value?.id) {
              state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.eng}`;
              Object.entries(value?.university || {}).forEach(([key, value]) => {
                if (key !== "id" && key !== "value") {
                  lstUniversityOption.push({ key: value?.id, value: value?.value });
                }
              });
              lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });
              state.countryCode = value?.code?.value?.eng;
              state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;
              setState({ ...state });
            }
          }
        });
        fields.bigUniversityName.setOptions([...lstUniversityOption], true);
      },

    getUserObj: ({ fields, interviewfields, state }) => {
      const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
      let bigID = appFunctions.generateID(10);
      let countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[countryCode]];
      const countryInfoObj = {};
      if (jsnSystemConfig?.blnContryName && fields?.bigCountryOfResidenceID?.getValue()?.key) {
        const countryID = fields?.bigCountryOfResidenceID?.getValue()?.key;
        const getCountryKey = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
        state.countryCode = objCat?.Root?.country?.[getCountryKey]?.code?.value?.["eng"];
        countryInfoObj.bigCountryOfResidenceID = countryID;
      } else if (fields?.bigAreaCountryID?.getValue()?.key && jsnSystemConfig?.blnAreaCode) {
        const countryID = fields?.bigAreaCountryID?.getValue()?.key && jsnSystemConfig?.blnAreaCode;
        const getCountryKey = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
        state.countryCode = objCat?.Root?.country?.[getCountryKey]?.code?.value?.["eng"];
        countryInfoObj.bigCountryOfResidenceID = countryID;
      } else if (countryCode && countryObj) {
        countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
      } else if (!countryCode) {
        countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[jsnSystemConfig?.strDefaultContryRegisterCode || "kw"]];
        countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
      }
      countryInfoObj.bigCountryOfCitizenID = fields?.bigCountryOfCitizenID?.getValue()?.key
        ? fields?.bigCountryOfCitizenID?.getValue()?.key
        : countryInfoObj.bigCountryOfResidenceID;

      const objectInput = {
        ...(userInfo ? userInfo : {}),
        bigUserID: userInfo?.bigUserID ? userInfo?.bigUserID : bigID,
        bigAppID: appVariables.App_ID,
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,

        strUserEmail: fields?.strUserEmail?.getValue(),
        strUserPhone: fields?.strUserPhone?.getValue(),
        strTrainingTypeCode: state?.countryCode,

        jsnUserInfo: {
          ...(userInfo?.jsnUserInfo ? userInfo?.jsnUserInfo : {}),
          // strFullName: { eng: fields?.strFullName?.getValue() || "", arb: fields?.strFullName?.getValue() || "" },
          strFullName: fields?.strFullName?.getValue(),
          intUserRoleFK: userInfo?.jsnUserInfo?.intUserRoleFK ? userInfo?.jsnUserInfo?.intUserRoleFK : appVariables?.objUserRole?.Member,
          bigWorkGovSectorType: fields?.bigWorkGovSectorType?.getValue()?.key || "",
          strMajor: fields?.bigMajorID?.getValue()?.key,
          strPositionTitle: fields?.strPositionTitle?.getValue(),
          bigGender: fields?.bigGender?.getValue()?.key,
          strPlaceName: fields?.strPlaceName?.getValue(),
          intExperienceYear: fields?.intExperienceYear?.getValue(),
          ...countryInfoObj,
          dtmRegisterResearcher: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
        },
      };

      return objectInput;
    },
    handleSubmit: async ({ fields, interviewfields, navigate, state, setState, uploaderVideo, uploaderTranscript }) => {
      try {
        if (!fields?.controller?.isFormValid() || !interviewfields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqVideoCode?.includes(state?.countryCode)) {
          if (!state?.lstIntroductoryVideo?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.studentUploadFile?.[lang], "error", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          if (state?.lstIntroductoryVideo?.length) {
            const resultUpload = await uploaderVideo?.uploadHandler("lstIntroductoryVideo");
            if (!resultUpload) {
              setIsLoading(false);
              return;
            }
          }
        }

        if (
          jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqTranscriptCode?.includes(state?.countryCode) &&
          fields?.bigWorkSector2?.getValue()?.key === appVariables.workSectorID?.student
        ) {
          if (!state?.lstTranscript?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.studentUploadFile?.[lang], "error", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          if (state?.lstTranscript?.length) {
            const resultUpload = await uploaderTranscript?.uploadHandler("lstTranscript");
            if (!resultUpload) {
              setIsLoading(false);
              return;
            }
          }
        }

        const objectInput = handler?.getUserObj({ fields, interviewfields, state });
        objectInput.jsnUserInfo.lstIntroductoryVideo = state?.lstIntroductoryVideo;
        objectInput.jsnUserInfo.lstTranscript = state?.lstTranscript;

        if (!appState?.clientInfo?.blnIsUserLoggedIn) {
          objectInput.strUserPassword = fields?.strUserPassword?.getValue();
          objectInput.lstStatisticFelid = ["intCountJoinUser", "intCountJoinUserAll", "intCountJoinResearcherAll", "intCountJoinResearcher"];
          objectInput.blnIsResearcher = true;
          objectInput.jsnUserInfo.strFullName = { eng: fields?.strFullName?.getValue() || "", arb: fields?.strFullName?.getValue() || "" };
          objectInput.jsnUserInfo.blnNewRegResearcher = true;

          const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
            objectInput: objectInput,
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              state.intStep = 2;
              if (result?.response === "strUserPhone already used by another users!") {
                fields.strUserPhone.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
              if (result?.response === "strUserEmail already used by another users!") {
                fields.strUserEmail.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
              setState({ ...state });
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }

          if (!state?.blnIsAdminEdit) {
            appState.userInfo = result.response;
            appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
          }
        } else {
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: { ...objectInput, dtmUpdatedDate: new Date()?.toUTCString() },
            objectCondition: {
              bigAppID: appVariables.App_ID,
              bigSystemID: userInfo?.bigSystemID,
              bigUserID: userInfo?.bigUserID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              state.intStep = 2;
              setState({ ...state });
              if (result?.response === "strUserPhone already used by another users!") {
                fields.strUserPhone.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
              if (result?.response === "strUserEmail already used by another users!") {
                fields.strUserEmail.setError(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang]);
              }
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }
          if (!state?.blnIsAdminEdit) {
            appState.userInfo = { ...userInfo, ...objectInput };
          }
        }

        setIsLoading(false);
        if (!appState.clientInfo.blnIsUserLoggedIn) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.submit_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } else {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
        }

        if (!state?.blnIsAdminEdit) {
          appState.clientInfo.blnIsUserLoggedIn = true;
          appDispatch();
          navigate(appRouting?.Account?.url, { replace: true });
        } else {
          // navigate( -1, { replace: true });
          window.history.back();
        }
        fields?.controller?.resetForm();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};
