import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Icon,
  MenuItem,
  Typography,
  Loader,
  Button,
  Avatar,
  Modal,
  ModalHeader,
  ModalBody,
  Line,
  Tabs,
  Paper,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@cbmisorg/client-app";
import { CtrlPartner } from "./controler/CtrlPartner";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import { App_Server_Url_DownloadFiles, globalKey, objUploadType, workSectorID, workSectorIDLabel } from "../../../../appHelper/appVariables";
import Uploader from "../../../sharedUi/uploader/Uploader";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import { appStyle } from "../../../../appHelper/appStyle";
import * as appColor from "../../../../appHelper/appColor";
import * as appVariables from "../../../../appHelper/appVariables";
import * as appFunctions from "../../../../appHelper/appFunctions";

import crossImg from "../../../../assets/images/cross-red.png";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import { objSupportTransStatus } from "../../../../../client/configuration/appVariables";
import { getSponsorTypeAccordingToTraineeNum } from "../../../../../client/util/appFunctions";

const classes = {
  paper: {
    boxShadow: `1px -1px 5px #dddddd`,
    border: `1px solid ${appColor.App_Primary_Color}`,
    borderRadius: "0.4em",
  },
};

function RouteMngPartner() {
  useCheckSystemInfo();
  useCheckCat();
  const { appState } = useApp();
  const lang = appState.clientInfo.strLanguage;
  const labels = dictionary?.components?.managePartner;
  const labelsSupportTrans = dictionary?.components?.mngSupportTrans;
  const objCat = appFunctions.getCategoryCache();

  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    listPartner: [],

    blnOpenChangeStatusModal: false,
    objSelectedPartner: {},
    blnOpenEditPartnerInfoModal: false,
    intEditPartnerInfoIndex: null,

    bigCurrentTab: appVariables.objPartnerStatus.New,
    intNumViewItem: 0,

    intNewNum: 0,
    intApprovedNum: 0,
    intRejectedNum: 0,

    listLogo: [],

    //////////////////////////////
    //section partner transaction
    blnOpenTransLogModal: false,
    intPartnerToLogIndex: null,

    blnOpenEditTransModal: false,
    blnEditTransOperationUpdate: null,
    intEditTransIndex: null,

    lstPaymentVoucher: [],
    lstReceiptVoucher: [],

    blnOpenImgeModal: false,
    selectedLstImg: [],
    selectedImgType: "",
  });

  const uploaderLogo = Uploader(
    stateInfo,
    setStateInfo,
    "listLogo",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType?.["aaiot"].id,
      title: dictionary?.components?.public?.join?.logoNote?.[lang],
      file: dictionary?.components?.public?.join?.logo?.[lang],
    },
    "edit",
    false
  );

  const fields = FormData({
    bigWorkSector: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: workSectorID?.gov, value: dictionary?.components?.joinSymposium?.gov?.[lang] },
        { key: workSectorID?.prvt, value: dictionary?.components?.joinSymposium?.prvt?.[lang] },
      ],
    },
    strPlaceName: { strControl: "text", objLanguage: false, objValidations: { required: true } },
    strURL: { strControl: "text", objLanguage: false },
    blnViewLogo: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [
        { key: "true", value: { eng: "Add the sponsor's logo on the main page of the association", arb: "إضافة شعار الجهة الداعمة في الصفحة الرئيسية للجمعية" }?.[lang] },
      ],
    },

    strFullName: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true },
    },
    strEmail: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, email: true },
    },
    strPhone: {
      strControl: "text",
      objLanguage: false,
      objValidations: { required: true, onlyNumbers: true, minLength: { condition: 8 }, maxLength: { condition: 8 } },
    },
  });

  const fldSupportTrans = FormData({
    strToSupport: { strControl: "list", objLanguage: false, objValidations: { required: true } },
    strSponsorTrainingType: { strControl: "list", objLanguage: false, objValidations: { required: true } },
    intSupportTransStatus: {
      strControl: "list",
      objLanguage: false,
      objValidations: { required: true },
      lstOption: [
        { key: appVariables?.objSupportTransStatus?.Approved, value: labelsSupportTrans?.Approved1?.[lang] },
        { key: appVariables?.objSupportTransStatus?.Under_Process, value: labelsSupportTrans?.Under_Process1?.[lang] },
      ],
    },
    intTrainingProgPrice: { strControl: "text", objLanguage: false, objValidations: { required: true, onlyNumbers: true } },
    trainingNumberAdmin: { strControl: "text", objLanguage: false, objValidations: { required: true, onlyNumbers: true } },
  });
  const uploaderPayment = Uploader(stateInfo, setStateInfo, "lstPaymentVoucher", "", {
    fileType: appVariables.objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: labelsSupportTrans?.paymentVoucher?.[lang],
  });

  const uploaderReceipt = Uploader(stateInfo, setStateInfo, "lstReceiptVoucher", "", {
    fileType: appVariables.objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: labelsSupportTrans?.receiptVoucher?.[lang],
  });

  const handlers = CtrlPartner(appState, isLoading, setIsLoading, stateInfo, setStateInfo);
  const openEditPartnerInfoModal = handlers.openEditPartnerInfoModal({ fields });
  const updatePartnerInfoAndUserInfoDB = handlers.updatePartnerInfoAndUserInfoDB({ fields, uploaderLogo });

  const sortPartnerResult = handlers.sortPartnerResult;
  const getAddedBeforeLabel = handlers.getAddedBeforeLabel;

  const openChangeStatusModal = handlers.openChangeStatusModal({ fields });
  const confirmUpdatePartnerStatusDB = handlers.confirmUpdatePartnerStatusDB({ fields, uploaderLogo });

  // const getPartnerSponsorPlanDetail = handlers.getPartnerSponsorPlanDetail({ objCat });
  const openSupportTransLogDialog = handlers.openSupportTransLogDialog({ stateInfo, setStateInfo });
  const dilogeImgModal = handlers?.dilogeImgModal({ stateInfo, setStateInfo });

  const openSupportTransEditDialog = handlers.openSupportTransEditDialog({ stateInfo, setStateInfo, fldSupportTrans });
  const onChangeCatType = handlers.onChangeCatType({ objCat, fldSupportTrans });
  const onChangetoSupportType = handlers.onChangetoSupportType({ objCat, fldSupportTrans });
  const onChangeTraneeCount = handlers.onChangeTraneeCount({ objCat, fldSupportTrans });
  const updatePartnerSupportTransDB = handlers.updatePartnerSupportTransDB({
    stateInfo,
    setStateInfo,
    fldSupportTrans,
    isLoading,
    setIsLoading,
    uploaderPayment,
    uploaderReceipt,
  });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      handlers?.initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      {/* //////////////////////////////////// body of page //////////////////////////////////// */}
      <Container py-3>
        {isLoading ? <Loader backdrop={true} color={appColor.App_Primary_Color} /> : null}

        {/* //////////////////////////////////// body of page //////////////////////////////////// */}
        <Grid container justify={"center"}>
          {!stateInfo?.listPartner?.length ? (
            <NoResultImg />
          ) : (
            <>
              <Grid item xs={12} p-0 container justify={"space-between"}>
                <Grid item xs={12} p-0 pt-2 sx={{ ".cbmis-tabs": { width: "100% !important", maxWidth: "99% !important" } }}>
                  <Tabs
                    mode="box"
                    activeColor="primary"
                    p-0
                    dir={lang === "arb" ? "rtl" : "ltr"}
                    currentTab={1}
                    tabsContent={[
                      {
                        tabLabel: (
                          <Grid container>
                            <Grid item xs="9" sm="auto">
                              {labels?.pendingUser?.[lang]}
                            </Grid>
                            <Grid item xs="2" sm="auto">
                              <Box py-0 px-2 m-0 color=" " sx={{ background: appColor.App_Primary_Color }}>
                                <Typography as="caption" color="light" p-0 m-0>
                                  <b>{stateInfo?.intNewNum || 0}</b>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ),
                        onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objPartnerStatus.New }),
                      },
                      {
                        tabLabel: (
                          <Grid container>
                            <Grid item xs="9" sm="auto">
                              {labels?.approvedUser?.[lang]}
                            </Grid>
                            <Grid item xs="2" sm="auto">
                              <Box py-0 px-2 m-0 color=" " sx={{ background: appColor.App_Dark_green }}>
                                <Typography as="caption" color="light" p-0 m-0>
                                  <b>{stateInfo?.intApprovedNum || 0}</b>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ),
                        onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objPartnerStatus.Approved }),
                      },
                      {
                        tabLabel: (
                          <Grid container>
                            <Grid item xs="9" sm="auto">
                              {labels?.excludedUser?.[lang]}
                            </Grid>
                            <Grid item xs="2" sm="auto">
                              <Box py-0 px-2 m-0 color=" " sx={{ background: appColor?.App_Dark_orange }}>
                                <Typography as="caption" color="light" p-0 m-0>
                                  <b>{stateInfo?.intRejectedNum || 0}</b>
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ),
                        onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objPartnerStatus.Rejected }),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container justify={"center"}>
                {!stateInfo?.listPartner?.length ? (
                  <NoResultImg />
                ) : (
                  stateInfo?.listPartner?.map((item, index) => {
                    if (index === 0) {
                      stateInfo.intNumViewItem = 0;
                    }

                    if (!sortPartnerResult(item)) {
                      if (!stateInfo.intNumViewItem && index >= stateInfo.listPartner?.length - 1) {
                        return <NoResultImg key={item?.bigPartnerID} xs={6} />;
                      }
                      return null;
                    }
                    ++stateInfo.intNumViewItem;
                    return (
                      <Grid item xs="12" key={item?.bigPartnerID}>
                        <MenuItem sx={{ width: "100%", background: !item?.blnIsSeen ? appColor.App_Light_blue : "#fefefe" }} outlined>
                          <Grid container>
                            <Grid item xs="9" sm="10" container sx={{ justifyContent: "start" }}>
                              <Grid
                                item
                                xs="auto"
                                sx={{
                                  ".CbmisIconContainer": {
                                    width: "48px !important",
                                    height: "48px !important",
                                    borderRadius: "50% !important",
                                    color: appColor.App_Primary_Color,
                                  },
                                }}
                              >
                                <Avatar
                                  src={
                                    !item?.strLogoImg?.[0]?.path ? crossImg : item?.strLogoImg?.[0]?.path ? App_Server_Url_DownloadFiles + item?.strLogoImg?.[0]?.path : crossImg
                                  }
                                  alt={item?.jsnPartnerInfo?.strPlaceName?.[lang] || String(item?.jsnPartnerInfo?.strPlaceName)}
                                  sx={{ width: "50px", height: "50px" }}
                                  mt-2
                                  key={String(item?.bigPartnerID)}
                                />
                              </Grid>
                              <Grid item xs="9" px-2>
                                <Grid item xs="12" pb-0>
                                  <Typography as="body2" pb-0 color={!item?.blnIsSeen ? appColor.App_Text_Color : ""} className="inline-ellipsis textstart">
                                    {item?.jsnPartnerInfo?.strPlaceName?.[lang] || String(item?.jsnPartnerInfo?.strPlaceName)}
                                  </Typography>
                                </Grid>
                                <Grid item xs="12" pb-0>
                                  <Typography as="caption" pb-0>
                                    {workSectorIDLabel?.[item?.jsnPartnerInfo?.bigWorkSector]?.[lang]}
                                  </Typography>
                                </Grid>
                                <Grid item xs="12" pb-0>
                                  {/* {!item?.blnIsManagerSponsor ? null : (
                                    <>
                                      <Typography as="caption" pb-0 color={objSponsorCatInfo.colorManager}>
                                        <b>
                                          {`${item?.intManagerTrainCount || 0} ${dictionary?.components?.public?.join?.managerCustomNumber?.[lang]}`}
                                          {!objSponsorCatInfo?.strManagerSponsorCatLabel ? null : ` ( ${objSponsorCatInfo?.strManagerSponsorCatLabel} )`}
                                        </b>
                                      </Typography>
                                      {!item?.blnIsFullstackSponsor ? null : <Typography as="caption">{`/`}&nbsp; </Typography>}
                                    </>
                                  )}
                                  {!item?.blnIsFullstackSponsor ? null : (
                                    <Typography as="caption" pb-0 color={objSponsorCatInfo.colorFullstack}>
                                      <b>
                                        {`${item?.intTraineesCount || 0} ${dictionary?.components?.public?.join?.trainingCustomNumber?.[lang]}`}
                                        {!objSponsorCatInfo?.strFullstackSponsorCatLabel ? null : ` ( ${objSponsorCatInfo?.strFullstackSponsorCatLabel} )`}
                                      </b>
                                    </Typography>
                                  )} */}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item sm="2" xs="3" justify={"end"} sx={{ ".cbmis-icon-container-in-btn .CbmisIconContainer": { xs: { width: "18px !important" } } }}>
                              {!item?.blnIsConfirmed || String(item?.bigPartnerStatusID) !== String(appVariables?.objPartnerStatus?.Approved) ? null : (
                                <Icon icon={item?.blnVewLogo ? "webAsset" : "webAssetOff"} color={item?.blnVewLogo ? "info" : "dark"} />
                              )}
                              <Button icon="visibility" p-0 onClick={openChangeStatusModal(item, index)} />
                              {!item?.blnIsConfirmed || String(item?.bigPartnerStatusID) !== String(appVariables?.objPartnerStatus?.Approved) ? null : (
                                <Button sx={{ margin: "2px" }} size="sm" icon="requestPage" p-0 onClick={openSupportTransLogDialog(index)} color={appColor?.App_Dark_green} />
                              )}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      {/* //////////////////////////////////// update status Partner Modal //////////////////////////////////// */}
      <Modal open={stateInfo?.blnOpenChangeStatusModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenChangeStatusModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "85%" }}>
            <Grid item>
              <Typography as="caption" sx={{ textAlign: "center !important", fontWeight: "bold" }}>
                {stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strPlaceName?.[lang] || String(stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strPlaceName)}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Grid container justify={"space-between"}>
            <Grid item />
            <Grid
              item
              xs={!stateInfo?.listLogo?.[0]?.blnIsNew && !stateInfo?.objSelectedPartner?.strLogoImg?.[0]?.path ? "auto" : "6"}
              md={!stateInfo?.listLogo?.[0]?.blnIsNew && !stateInfo?.objSelectedPartner?.strLogoImg?.[0]?.path ? "auto" : "4"}
              lg={!stateInfo?.listLogo?.[0]?.blnIsNew && !stateInfo?.objSelectedPartner?.strLogoImg?.[0]?.path ? "auto" : "2"}
              sx={{
                justifyContent: "center",
              }}
            >
              <img
                src={
                  !stateInfo?.listLogo?.[0]?.blnIsNew && !stateInfo?.objSelectedPartner?.strLogoImg?.[0]?.path
                    ? crossImg
                    : stateInfo?.listLogo?.[0]?.blnIsNew
                    ? URL.createObjectURL(stateInfo?.listLogo?.[0])
                    : App_Server_Url_DownloadFiles + stateInfo?.objSelectedPartner?.strLogoImg?.[0]?.path
                }
                alt={""}
                title={stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strPlaceName}
                width={"100px"}
                height={"100px"}
                style={{ borderRadius: "50%", border: `1px solid ${appColor.App_Primary_Color}` }}
              />
            </Grid>

            <Grid item alignSelf={"baseline"}>
              {String(stateInfo?.objSelectedPartner?.bigPartnerStatusID) === String(appVariables?.objPartnerStatus?.Rejected) ? null : (
                <Button icon="edit" p-0 onClick={openEditPartnerInfoModal(stateInfo?.objSelectedPartner, stateInfo?.intEditPartnerInfoIndex)} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} px-1>
              <Typography as="body2" sx={appStyle?.title1}>
                {dictionary?.components?.public?.join?.partnerInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify={"center"}></Grid>

          <Grid container spacing={2} mt-4>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary.formapi.bigWorkSector?.label?.[lang]}</b> :{" "}
              </Typography>
              <Typography as="caption" color={appColor.App_orang_color}>
                {appVariables.workSectorIDLabel?.[stateInfo?.objSelectedPartner?.jsnPartnerInfo?.bigWorkSector]?.[lang] || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary.formapi.strURL?.label?.[lang]}</b> :{" "}
              </Typography>
              <Typography as="caption">
                {stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strURL ? <a href={stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strURL}>{labels?.moveLink?.[lang]}</a> : "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography as="caption">
                <b>{labels?.sponserInfo?.supportCat?.[lang]}</b> :{" "}
                {
                dictionary?.shared?.app.sponserLabelCat?.[
                  stateInfo?.objSelectedPartner?.jsnSupportTrans?.[stateInfo?.objSelectedPartner?.jsnSupportTrans.length - 1]?.strSupportCatType
                    ? stateInfo?.objSelectedPartner?.jsnSupportTrans?.[stateInfo?.objSelectedPartner?.jsnSupportTrans.length - 1]?.strSupportCatType
                    : getSponsorTypeAccordingToTraineeNum(
                        stateInfo?.objSelectedPartner?.jsnSupportTrans?.[stateInfo?.objSelectedPartner?.jsnSupportTrans.length - 1]?.intTraineesCount ||
                          stateInfo?.objSelectedPartner?.jsnSupportTrans?.[stateInfo?.objSelectedPartner?.jsnSupportTrans.length - 1]?.intTrainingProgPrice,
                        stateInfo?.objSelectedPartner?.jsnSupportTrans?.[stateInfo?.objSelectedPartner?.jsnSupportTrans?.length - 1]?.strTrainingType
                      )
                ]?.[lang]
              }
              </Typography>
              
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} px-1>
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.partnerContactInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary.formapi.strFullNameRecipient?.label?.[lang]}</b> :&nbsp;
              </Typography>
              <Typography as="caption" color={appColor.App_orang_color}>
                {stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strFullName?.[lang] || stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strFullName}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary.formapi.strUserEmailRecipient?.label?.[lang]}</b> :{" "}
              </Typography>
              <Typography as="caption">
                <a href={`mailto:${stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strUserEmail}`}>{stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strUserEmail}</a>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography as="caption">
                <b>{dictionary.formapi.strUserPhoneRecipient?.label?.[lang]}</b> :{" "}
              </Typography>
              <Typography as="caption">
                <a href={`tel:${stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strUserPhone}`}>{stateInfo?.objSelectedPartner?.jsnPartnerInfo?.strUserPhone}</a>
              </Typography>
            </Grid>
          </Grid>
          {String(stateInfo?.objSelectedPartner?.bigPartnerStatusID) !== String(appVariables?.objPartnerStatus?.New) ? null : (
            <React.Fragment>
              <Line my-2 />

              <Grid container justify={"center"}>
                <Grid item xs="12">
                  <FormField objHandler={fields.blnViewLogo} />
                </Grid>
              </Grid>

              <Grid container mt-5 mb-2 justify={"space-between"}>
                <Grid item>
                  <Button p-0 size="sm" color={"success"} label={labels?.Approved?.[lang]} onClick={confirmUpdatePartnerStatusDB(appVariables?.objPartnerStatus.Approved)} />
                </Grid>
                <Grid item>
                  <Button p-0 size="sm" color={"error"} label={labels?.Rejected?.[lang]} onClick={confirmUpdatePartnerStatusDB(appVariables?.objPartnerStatus.Rejected)} />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>

      {/* //////////////////////////////////// Edit Partner Modal //////////////////////////////////// */}
      <Modal open={stateInfo?.blnOpenEditPartnerInfoModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenEditPartnerInfoModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "85%" }}>
            <Grid item>
              <Typography as="caption" sx={{ textAlign: "center !important", fontWeight: "bold" }}>
                {stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.jsnPartnerInfo?.strPlaceName?.[lang] ||
                  String(stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.jsnPartnerInfo?.strPlaceName)}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography as="body2" sx={appStyle?.title1}>
                {dictionary?.components?.public?.join?.partnerInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify={"center"}>
            <Grid item>
              <Grid container>
                <Grid
                  item
                  sx={{
                    ".CbmisIconContainer": {
                      zIndex: -1,
                      width: "90px !important",
                      height: "90px !important",
                      borderRadius: "50% !important",
                      color: appColor.App_Primary_Color,
                    },
                  }}
                >
                  {!stateInfo?.listLogo?.[0]?.blnIsNew && !stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.strLogoImg?.[0]?.path ? (
                    <Icon icon={"business"} />
                  ) : (
                    <img
                      src={
                        !stateInfo?.listLogo?.[0]?.blnIsNew && !stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.strLogoImg?.[0]?.path
                          ? crossImg
                          : stateInfo?.listLogo?.[0]?.blnIsNew
                          ? URL.createObjectURL(stateInfo?.listLogo?.[0])
                          : App_Server_Url_DownloadFiles + stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.strLogoImg?.[0]?.path
                      }
                      alt={stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.jsnPartnerInfo?.strPlaceName}
                      title={stateInfo?.listPartner?.[stateInfo?.intEditPartnerInfoIndex]?.jsnPartnerInfo?.strPlaceName}
                      height={"100px"}
                      width={"100px"}
                      style={{ borderRadius: "50%", border: `1px solid ${appColor.App_Primary_Color}` }}
                    />
                  )}
                </Grid>
                <Grid item justify={"start"} sx={{ position: "absolute", bottom: "-6px" }}>
                  {uploaderLogo?.JSX}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} justify={"center"} mt-4>
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.bigWorkSector} icon="Work" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.strPlaceName} icon="business" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fields.strURL} icon="link" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography as="body2" sx={appStyle?.title1}>
                {dictionary?.components?.public?.join?.personalInfo?.[lang]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify={"center"}>
            <Grid item xs={12}>
              <FormField objHandler={fields.strFullName} icon="AccountCircle" />
            </Grid>
            <Grid item xs={12} md="6">
              <FormField objHandler={fields.strEmail} icon="email" className={`inputLeft inputLeft${lang}`} />
            </Grid>
            <Grid item xs={12} md="6">
              <FormField
                objHandler={fields.strPhone}
                icon={lang === "arb" ? "phone" : null}
                endIcon={lang !== "arb" ? "phone" : null}
                endAdornment={lang === "arb" ? globalKey : null}
                adornment={lang !== "arb" ? globalKey : null}
                className={`inputLeft`}
              />
            </Grid>
          </Grid>

          <Line my-4 />
          <Grid container justify={"center"} mt-6>
            <Grid item xs="12">
              <FormField objHandler={fields.blnViewLogo} />
            </Grid>
          </Grid>

          <Grid container justify={"center"} mt-6>
            <Grid item>
              <Button label={dictionary?.shared?.btn?.save?.[lang]} onClick={updatePartnerInfoAndUserInfoDB} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      {/* //////////////////////////////////// History Partner Transaction Modal //////////////////////////////////// */}

      <Modal open={stateInfo.blnOpenTransLogModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenTransLogModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="body2" sx={{ textAlign: "center !important" }}>
                {labelsSupportTrans.transactionHistory?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody>
          <Grid container>
            <Grid item>
              <Button size="sm" label={labelsSupportTrans.addNewSupport?.[lang]} onClick={openSupportTransEditDialog(null, false)} />
            </Grid>
          </Grid>
          <Grid container>
            {!stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.jsnSupportTrans?.length ? (
              <NoResultImg md={6} />
            ) : (
              <Grid item xs="12">
                <Paper p-0 elevation={0}>
                  <Table sx={{ minWidth: "max-content !important" }} mobileMode="off">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.sponserInfo?.supportStatus?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.sponserInfo?.supportType?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.sponserInfo?.supportCat?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.sponserInfo?.studentNumber?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.sponserInfo?.amount?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.sponserInfo?.date?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center !important", fontWeight: "bold" }} as="caption">
                            {labels?.strNote?.[lang]}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(Array.isArray(stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.jsnSupportTrans)
                        ? JSON.parse(JSON.stringify(stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.jsnSupportTrans))
                        : []
                      )
                        ?.reverse()
                        ?.map((item, intIndex) => (
                          <TableRow
                            key={item?.id}
                            className={classes?.tblBodyRow}
                            sx={!item?.lstReceipt?.length || !item?.lstReceiptCatch?.length ? { background: "#FF00002a !important" } : {}}
                          >
                            <TableCell headLabel={labels?.sponserInfo?.supportStatus?.[lang]}>
                              <Typography
                                as="caption"
                                sx={{ textAlign: "center !important", fontWeight: "bold" }}
                                color={
                                  item?.intSupportTransStatus === objSupportTransStatus?.Approved
                                    ? "success"
                                    : item?.intSupportTransStatus === objSupportTransStatus?.Approved
                                    ? "error"
                                    : appStyle.App_Second_Color
                                }
                              >
                                {labels.objStatus?.[item?.intSupportTransStatus]?.[lang]}
                              </Typography>
                            </TableCell>
                            <TableCell headLabel={labels?.sponserInfo?.supportType?.[lang]}>
                              <Typography as="caption" sx={{ textAlign: "center !important" }}>
                                {objCat?.Root?.toSupport?.[item?.strTrainingType]?.value?.[lang]}
                              </Typography>
                            </TableCell>
                            <TableCell headLabel={labels?.sponserInfo?.supportCat?.[lang]}>
                              <Typography as="caption" sx={{ textAlign: "center !important" }}>
                                {
                                  dictionary?.shared?.app.sponserLabelCat?.[
                                    item?.strSupportCatType
                                      ? item?.strSupportCatType
                                      : getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount || item?.intTrainingProgPrice, item?.strTrainingType)
                                  ]?.[lang]
                                }
                              </Typography>
                            </TableCell>
                            <TableCell headLabel={labels?.sponserInfo?.studentNumber?.[lang]}>
                              <Typography as="caption" sx={{ textAlign: "center !important" }}>
                                {objCat?.Root?.toSupport?.[item?.strTrainingType]?.blnIsTraining?.value?.[lang] === "false" ? "-" : item?.intTraineesCount}
                              </Typography>
                            </TableCell>
                            <TableCell headLabel={labels?.sponserInfo?.amount?.[lang]}>
                              <Typography as="caption" sx={{ textAlign: "center !important" }}>
                                {objCat?.Root?.toSupport?.[item?.strTrainingType]?.blnIsTraining?.value?.[lang] === "false"
                                  ? item?.intTrainingProgPrice
                                  : Number(item?.intReceivedAmount ? item?.intReceivedAmount : item?.intTrainingProgPrice * item?.intTraineesCount).toLocaleString()}
                                {dictionary?.shared?.app?.dinar?.[lang]}
                              </Typography>
                            </TableCell>
                            <TableCell headLabel={labels?.sponserInfo?.date?.[lang]}>
                              <Typography as="caption" sx={{ textAlign: "center !important" }}>
                                {appFunctions.getDateUSFormat(item?.dtmCreatedDate)} {getAddedBeforeLabel(item?.dtmCreatedDate)}
                              </Typography>
                            </TableCell>
                            <TableCell headLabel={labels?.strNote?.[lang]}>
                              <Typography as="caption" sx={{ textAlign: "center !important" }}>
                                {item?.strNote || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell py-3 sx={{ sm: { minWidth: "250px" } }}>
                              <Grid container justifyContent={"center"}>
                                <Grid item xs="auto">
                                  {!item?.lstReceipt?.length ? null : (
                                    <Button
                                      label={<Typography as={"caption"}>{labels?.receipt?.[lang]}</Typography>}
                                      size="sm"
                                      p-0
                                      onClick={dilogeImgModal(item?.lstReceipt, "receipt")}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs="auto">
                                  {!item?.lstReceiptCatch?.length ? null : (
                                    <Button
                                      label={<Typography as={"caption"}>{labels?.receiptCatch?.[lang]}</Typography>}
                                      size="sm"
                                      p-0
                                      color="success"
                                      onClick={dilogeImgModal(item?.lstReceiptCatch, "receiptCatch")}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs="auto">
                                  <Button
                                    icon="edit"
                                    size="sm"
                                    p-0
                                    color="info"
                                    onClick={openSupportTransEditDialog(stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.jsnSupportTrans?.length - intIndex - 1, true)}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo?.blnOpenImgeModal} eventClose={dilogeImgModal()} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>{labels?.[stateInfo?.selectedImgType]?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs="12">
              <img src={App_Server_Url_DownloadFiles + stateInfo?.selectedLstImg?.[0]?.path} alt={labels?.[stateInfo?.selectedImgType]?.[lang]} width="100%" />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      {/* //////////////////////////////////// Edit Partner Transaction Modal //////////////////////////////////// */}

      <Modal open={stateInfo.blnOpenEditTransModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenEditTransModal: false })}>
        <ModalHeader>
          <Grid container justify={"center"} sx={{ width: "90%" }}>
            <Grid item>
              <Typography as="body2" sx={{ textAlign: "center !important" }}>
                {stateInfo?.blnEditTransOperationUpdate ? labelsSupportTrans?.updateSupportTransInfo?.[lang] : labelsSupportTrans?.addNewSupport?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </ModalHeader>

        <ModalBody>
          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSupportTrans.strToSupport} dir={lang === "arb" ? "rtl" : "ltr"} onChange={onChangetoSupportType} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSupportTrans.strSponsorTrainingType} dir={lang === "arb" ? "rtl" : "ltr"} onChange={onChangeCatType} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSupportTrans.intSupportTransStatus} dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>
          {fldSupportTrans.intSupportTransStatus?.getValue()?.key !== appVariables?.objSupportTransStatus?.Approved ? null : (
            <React.Fragment>
              <Line my-1 />
              <Grid container>
                <Grid item xs={12} justify={"center"} pt-2 pb-5>
                  <Typography as="caption">{labelsSupportTrans?.paymentVoucher?.[lang]}</Typography>
                  {uploaderPayment?.JSX}
                </Grid>
                {objCat?.Root?.toSupport?.[fldSupportTrans?.strToSupport?.getValue()?.key]?.blnIsTraining?.value?.eng === "false" ? null : (
                  <Grid item xs="12">
                    <FormField
                      objHandler={fldSupportTrans.trainingNumberAdmin}
                      endAdornment={dictionary?.components?.public?.join?.trainingCustomNumber?.[lang]}
                      onChange={onChangeTraneeCount}
                    />
                  </Grid>
                )}
                <Grid item xs="12" sx={{ xs: { padding: "0px !important" } }}>
                  <FormField objHandler={fldSupportTrans.intTrainingProgPrice} endAdornment={dictionary?.shared?.app?.dinar?.[lang]} />
                </Grid>
                <Grid item xs={12} justify={"center"} py-2>
                  <Typography as="caption">{labelsSupportTrans?.receiptVoucher?.[lang]}</Typography>
                  {uploaderReceipt?.JSX}
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          <Grid container justify={"center"} mt-4>
            <Grid item>
              <Button size="sm" label={dictionary.shared.btn?.[stateInfo?.blnEditTransOperationUpdate ? "save" : "addBtn"]?.[lang]} onClick={updatePartnerSupportTransDB} />
            </Grid>
          </Grid>

          <Grid container sx={{ direction: "ltr" }} mt-6>
            <Grid item>
              <Typography as="caption">
                {!appFunctions.checkIsValidDate(stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.jsnSupportTrans?.[stateInfo.intEditTransIndex]?.dtmUpdatedDate)
                  ? null
                  : `Last updated date was : ${appFunctions.getDateUSFormat(
                      stateInfo.listPartner?.[stateInfo?.intPartnerToLogIndex]?.jsnSupportTrans?.[stateInfo.intEditTransIndex]?.dtmUpdatedDate,
                      true
                    )}`}
              </Typography>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RouteMngPartner;
