import React, { useEffect, useRef } from 'react'
import Step from './Step'
import './Stepper.css'
import '../../../style/main.css'

function StepperHead(props) {
    const {stepperContent,stepClicked,navigateToStepHandler,mode,currentTabIndex,completedColor,sx,className} = props
	
	useEffect(() => {
	}, [stepperContent,stepClicked,navigateToStepHandler,mode,currentTabIndex,completedColor,sx,className])
	
	return (
        <div
		className={`cbmis-stepper-head ${String(mode).toLowerCase()=== "isvertical" ? 'cbmis-vertical-stepper-head' : ''} 
		${String(mode).toLowerCase()=== "isinline" ? 'cbmis-inline-stepper-head' : ''}
		${String(mode).toLowerCase()=== "dot" ? 'cbmis-dot-stepper-head' : ''}`}
	>
		{stepperContent.map((el, i) => (
			<Step
				key={i}
				index={i}
				mode={mode}
				navigateToStepHandler={navigateToStepHandler}
				isActive={i === currentTabIndex}
				isComplete={el.isComplete}
				isWarning={el.isWarning}
				isError={el.isError}
				indicator={i + 1}
				label={el.label}
				completedColor={completedColor}
				sx={sx}
				className={className}
				stepClicked={stepClicked}
				{...el}
			/>
		))}
	</div>
    )
}

export default StepperHead
