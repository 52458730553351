import * as formVariables from "../formVariables/FormVariables";
export function getError(name, formState){
  try{
    return formState?.fields?.[name]?.error
  }catch(error){}
}
export function updateError(newError, name,formState){
  try{
    formState.fields[name].error = newError
    return {...formState};
  }catch(error){}
  }

export  const getErrorLabel=(rule,name,formState)=>{
  const customValidationsMsg = formState?.fields?.[name]?.validations?.[rule]?.validationMsg
    const fieldValidationsMsg  = formState?.labels?.[name]?.validationsMsg || "";
    const formValidationsMsg = formState?.validationsMsg

    const theRule= String(rule).toLowerCase()
    if(customValidationsMsg){
      return customValidationsMsg
    }else if(fieldValidationsMsg?.[rule]){
      return fieldValidationsMsg?.[rule]

    }else if(formValidationsMsg?.[rule]){
      return formValidationsMsg?.[rule]
    }else{
      // form api masge defualt arb & eng
      return formVariables?.formValidationsMsg?.[theRule]
    }
  }