import { Button, Grid, ModalHeader, Modal, ModalBody, Typography } from "@cbmisorg/material";
import React from "react";
import { useState } from "react";
import AttendeeManagementView from "../../../../../../../old/components/stakeholders/admin/AttendeeManagement/AttendeeManagement.view";

import { dictionary } from "../../../../../../util/appLanguage/dictionary";

export default function AttendeeConfirmationView({ bigForumID, lang, intNum }) {
  const [state, setState] = useState({ blnOpenModal: false });
  const labels = dictionary?.components?.stakeholders?.admin?.mngRegForum;

  return (
    <>
      <Button
        size="sm"
        p-0
        label={labels?.label?.numRegGuestNeedToConfirm?.[lang] + ` (${intNum})`}
        mode="outlined"
        onClick={() => setState({ ...state, blnOpenModal: true })}
      />
      <Modal size="xl" open={state.blnOpenModal} eventClose={() => setState({ ...state, blnOpenModal: false })}>
        <ModalHeader>
          <Typography as="subtitle2">{labels?.label?.mngAttendee?.[lang]}</Typography>
        </ModalHeader>
        <ModalBody>
          <AttendeeManagementView bigActivityType={bigForumID} />
        </ModalBody>
      </Modal>
    </>
  );
}
