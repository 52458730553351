import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Container, Grid, Icon, Typography } from '@cbmisorg/client-app'
import * as  appColor  from '../../../../../appHelper/appColor'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary'
import { appStyle } from '../../../../../appHelper/appStyle'
import associationImg from '../../../../../assets/images/association-img.png'

function Association({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.association

  return (
    <React.Fragment >
      <Container pt-2 pb-5 id="association">
          <Grid container spacing="3">
              <Grid item xs="0" lg="5" alignSelf="flex-start" p-0 m-0 container spacing={3}>
                <Grid item xs="12" px-0 >
                    <img src={associationImg} alt="" loading='lazy' width={"90%"} />
                </Grid>
              </Grid>
              <Grid item xs="12" lg="7"  container spacing="3" justify={'center'}>
                    <Grid item xs="12" lg="12" pb-0>
                    <Typography as="h4" color={appColor.App_Primary_Color}>{labels?.title?.[lang]}</Typography>
                    </Grid>
                    <Grid item xs="12" pt-0>
                        <Typography as="h6">{labels?.subtitle?.[lang]}</Typography>
                    </Grid>
                    <Grid item xs="12">
                        <Typography as="body1">{labels?.p1?.[lang]}</Typography>
                    </Grid>
                    <Grid item xs="12" className="accordionTab_landingPage">
                    <Accordion mx-0 className="accordionTab_landingPage" dir={lang==="arb"?"rtl":"ltr"}>
                    {
                        labels?.lst?.map((item,index)=>{
                        return(
                            <AccordionTab my-1 key={index}>
                            <AccordionSummary expandIcon={<Icon icon="checkCircle" />}  py-3 px-3 sx={appStyle?.accordionSummary}>
                                <Typography as="body1" px-2 color={appColor?.App_Dark_Color}>{item?.title?.[lang]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={appStyle?.accordionDetails}>
                                <Typography as="body2">{item?.point?.[lang]}</Typography>
                            </AccordionDetails>
                            </AccordionTab>
                            )
                        })
                    }
                    </Accordion>
                    </Grid>
                    <Grid item xs="12" >
                        <Typography as="body1">
                          {labels?.p2?.[lang]}
                        </Typography>
                    </Grid>
                    <Grid item xs="12" >
                        <Typography as="body1"  mt-0>
                          <a href={labels?.link?.url} target="_blank" rel="noreferrer" >
                          {labels?.link?.[lang]}
                          </a>
                        </Typography>
                    </Grid>
              </Grid>
          </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Association