import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const CtrlRegistration = ({ appState, appDispatch, isLoading, setIsLoading, countryCode }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  let jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const handler = {
    initData:
      ({ state, setState, isLoading, setIsLoading, navigate, getNewSystemInfo, getNewCatInfo, fields }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);
          if (!jsnSystemConfig || JSON?.stringify(jsnSystemConfig)?.length < 500) {
            jsnSystemConfig = await getNewSystemInfo(appDispatch);
          }
          if (!appState?.clientInfo?.objCatInfo || JSON?.stringify(appState?.clientInfo?.objCatInfo)?.length < 500) {
            await getNewCatInfo();
          }
          
          if (countryCode && !jsnSystemConfig?.lstContryRegisterCode?.includes(countryCode)) {
            state.countryCode = "";
            navigate(appRouting.page404?.url);
          }
          if (!countryCode) {
            state.countryCode = appState.clientInfo.clientCountryCode ? appState.clientInfo.clientCountryCode : jsnSystemConfig?.strDefaultContryRegisterCode;
          } else {
            state.countryCode = countryCode;
            appState.clientInfo.clientCountryCode = countryCode;
          }

          // if (jsnSystemConfig?.blnAreaCode || jsnSystemConfig?.blnContryName) {
            const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
            let lstCountryOption = [];
            let lstEaraCodeOption = [];
            let lstCountryNaOption = [];
            let countryID = "";
            Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
              if (key !== "id" && key !== "value") {
                
                if (jsnSystemConfig?.lstContryRegisterCode?.includes(value?.code?.value?.eng)) {
                  lstCountryNaOption.push({ key: value?.id, value: value?.nationality?.value });
                  lstCountryOption.push({ key: value?.id, value: value?.value });
                  if (value?.phoneCode) {
                    lstEaraCodeOption.push({ key: value?.id, value: { eng: `+${value?.phoneCode?.value?.eng}`, arb: `${value?.phoneCode?.value?.arb}+` } });
                  }
                  if (state?.countryCode === value?.code?.value?.eng) {
                    countryID = value?.id;
                  }
                }
              }
            });
            fields.bigCountryOfCitizenID.setOptions([...lstCountryNaOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
            fields.bigCountryOfResidenceID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);

            fields?.bigAreaCountryID?.setOptions(lstEaraCodeOption);
            fields?.bigAreaCountryID?.setValue(countryID);
            fields?.bigCountryOfResidenceID?.setValue(countryID);
            fields?.bigCountryOfCitizenID?.setValue(countryID);

            // if (jsnSystemConfig?.blnContryName) {
            //   fields?.bigCountryOfResidenceID?.setValidation("required", true);
            // } else if (jsnSystemConfig?.blnAreaCode) {
            //   fields?.bigAreaCountryID?.setValidation("required", true);
            //   fields?.strUserPhone?.setValidation("required", true);
            // }
          // }

          state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    onChangeContry:
      ({ fields, state, setState }) =>
      () => {
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            if (
              fields?.bigCountryOfCitizenID?.getValue()?.key === value?.id ||
              fields?.bigAreaCountryID?.getValue()?.key === value?.id ||
              fields?.bigCountryOfResidenceID?.getValue()?.key === value?.id
            ) {
              state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.arb}`;
              fields?.bigCountryOfResidenceID?.setValue(value?.id);
              fields?.bigAreaCountryID?.setValue(value?.id);
              state.countryCode = value?.code?.value?.eng;
              state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;
              setState({ ...state });
            }
          }
        });
      },
    handleSubmit: async ({ fields, navigate, state }) => {
      try {
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

        let bigID = appFunctions.generateID(10);

        if (jsnSystemConfig?.blnAreaCode || jsnSystemConfig?.blnContryName) {
          let countryID = 0;
          if (jsnSystemConfig?.blnAreaCode) {
            countryID = fields?.bigAreaCountryID?.getValue()?.key;
          } else {
            countryID = fields?.bigCountryOfResidenceID?.getValue()?.key;
          }

          if (countryID) {
            let countryKeyjID = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
            state.countryCode = objCat?.Root?.country?.[countryKeyjID]?.code?.value?.eng;
          }
        }

        let countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[state.countryCode]];
        const countryInfoObj = {};
        if (state.countryCode && countryObj) {
          countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
          countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
        } else if (!state.countryCode) {
          countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[jsnSystemConfig?.strDefaultContryRegisterCode || "kw"]];
          countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
          countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
        }
        const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
          objectInput: {
            bigAppID: appVariables.App_ID,
            bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
            bigUserID: bigID,

            strUserEmail: fields?.strUserEmail?.getValue(),
            strUserPhone: fields?.strUserPhone?.getValue(),
            strUserPassword: fields?.strUserPassword?.getValue(),
            strTrainingTypeCode: state?.countryCode,

            jsnUserInfo: {
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
              intUserRoleFK: appVariables?.objUserRole?.Member,
              ...countryInfoObj,
            },
            blnIsManager: false,
            blnIsSponsor: false,
            blnIsStartUp: false,
            blnIsStudent: false,

            lstStatisticFelid: ["intCountJoinUser", "intCountJoinUserAll"],
          },
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          } else {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        appState.clientInfo.clientCountryCode = state?.countryCode;
        appState.clientInfo.blnIsUserLoggedIn = true;
        appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
        appState.userInfo = result.response;
        appDispatch();
        fields?.controller?.resetForm();
        navigate(appRouting?.Account?.url, { replace: true });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};
