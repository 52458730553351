"use client";
import { useEffect, useState } from "react";

export default function useScreenSize() {

  function getSize() {
    const isClient = typeof window !== "undefined";

    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState({width:window?.innerWidth || undefined, height:window?.innerHeight || undefined});

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}
