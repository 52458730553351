import React, { useEffect, useRef } from "react";

const ErrorMessage = (props) => {
  const { errorMessage, className } = props;

  useEffect(() => {}, [errorMessage, className]);

  return <div className={`cbmis-error-label ${className || ""}`}>{errorMessage}</div>;
};

export default ErrorMessage;
