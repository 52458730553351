import React from 'react'
import MngUsersView from './mngUsers.view'

function MngUsers() {
  return (
    <React.Fragment>
        <MngUsersView />
    </React.Fragment>
  )
}

export default MngUsers