import { Button, Grid, Line, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/client-app";
import React from "react";
import { appStyle } from "../../../../../appHelper/appStyle";
import { FormField } from "../../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { objTrainingToSupport, objUploadType } from "../../../../../appHelper/appVariables";
import Uploader from "../../../../sharedUi/uploader/Uploader";

const labels = dictionary?.components?.manageSystem;

function TrainingConfig({ fields, stateInfo, setStateInfo, appState, handlers, fieldTraining }) {
  const lang = appState?.clientInfo?.strLanguage;
  const uploaderVideo = Uploader(stateInfo, setStateInfo, "lstVideo", "", {
    fileType: objUploadType.video.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: labels?.video?.[lang],
  });
  const uploaderSponserMain = Uploader(stateInfo, setStateInfo, "lstSponserMain", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
  });
  const uploaderSponser = Uploader(stateInfo, setStateInfo, "lstSponser", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
  });

  const handleSubmitSponserModal = handlers.handleSubmitSponserModal({ fieldTraining, uploaderVideo, uploaderSponser, uploaderSponserMain });
  const handleCloseSponserModal = handlers.handleCloseSponserModal({ fieldTraining, stateInfo, setStateInfo });

  return (
    <React.Fragment>
      <Grid container justify={"center"} spacing={3}>
        <Grid item xs="12">
          <Paper className="cardContainer">
            <Grid container spacing={2} py-0>
              <Grid item xs="12">
                <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                  {labels?.trainingConfig?.[lang]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs="12">
                <FormField objHandler={fields.lstTrainingToSupport} m-0 />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Modal open={stateInfo?.blnOpenModalSponser} eventClose={handleCloseSponserModal}>
        <ModalHeader>{stateInfo?.selectedTrainingInfo?.value?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.catTrainingSponser?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.lstSponser} />
            </Grid>
          </Grid>
          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.trainingPublicVedio?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.strVedioTitle} />
            </Grid>
            <Grid item xs="12" container justify={"space-between"}>
              <Grid item xs="8">
                <Typography as="caption">{labels?.videoUpload?.[lang]}</Typography>
              </Grid>
              <Grid item mx-xs-auto mx-sm-auto px-1>
                {uploaderVideo?.JSX}
              </Grid>
            </Grid>
          </Grid>
          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {labels?.accountVisibilityOptions?.[lang]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.blnShowOptionInAdminAccount} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.lstCountryTraining} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.lstContryTrainingReqVideo} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.lstContryTrainingReqTranscript} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>
          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {/* {labels?.accountVisibilityOptions?.[lang]} */}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.strMainContryTitle} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.strMainContryDecBeforeConf} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strMainContryDecAfterConf} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strMainContrytmsLink} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strMainContrySponserName} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strMainContrySponserPostion} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strMainContryDate} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" container justify={"space-between"}>
              <Grid item xs="8">
                <Typography as="caption"> صورة الراعي للكويت</Typography>
              </Grid>
              <Grid item mx-xs-auto mx-sm-auto px-1>
                {uploaderSponserMain?.JSX}
              </Grid>
            </Grid>
          </Grid>

          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Typography as="body2" sx={appStyle?.title1}>
                {/* {labels?.accountVisibilityOptions?.[lang]} */}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.strTitle} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldTraining?.strDecBeforeConf} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strDecAfterConf} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strtmsLink} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strSponserName} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strSponserPostion} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" pt-5>
              <FormField objHandler={fieldTraining?.strDate} icon="listAlt" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs="12" container justify={"space-between"}>
              <Grid item xs="8">
                <Typography as="caption"> صورة الراعي</Typography>
              </Grid>
              <Grid item mx-xs-auto mx-sm-auto px-1>
                {uploaderSponser?.JSX}
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify={"center"}>
            <Grid item xs="12">
              <Button color="primary" px-3 size="sm" label={dictionary?.shared?.btn?.save?.[lang]} onClick={handleSubmitSponserModal} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default TrainingConfig;
