import React, { useEffect, useRef } from "react";
import "./Drawer.css";
import Icon from "../../../icon/CbmisIcon";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Drawer = (props) => {
  const { open, position, eventClose, color, title, blnHaveTitle= true, mode, textColor, width, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Drawer"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [open, position, eventClose, mode, color, sx, hidden, disabled, dir, className, sx]);

  useEffect(() => {
    if (styleRef.current.strSxClassName) {
      if (color) {
        componentsFunction.getbgColorReturnClassName(`.${styleRef.current.strSxClassName} .cbmis-drawer`, color);
      } else {
        componentsFunction.getbgColorReturnClassName(`.${styleRef.current.strSxClassName} .cbmis-drawer`, "var(--cbmis-white)");
      }
    }
  }, [styleRef.current.strSxClassName, color]);

  useEffect(() => {
    if (styleRef.current.strSxClassName) {
      if (textColor) {
        componentsFunction.changeItemColor(`.${styleRef.current.strSxClassName} .cbmis-drawer`, textColor);
      } else {
        componentsFunction.changeItemColor(`.${styleRef.current.strSxClassName} .cbmis-drawer`, "var(--cbmis-dark)");
      }
    }
  }, [styleRef.current.strSxClassName, textColor]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-drawer-container");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const handlePositionChange = () => {
    if (position) {
      const getPostion = String(position).toLowerCase();
      switch (getPostion) {
        case "top":
          return "cbmis-drawer-position-top cbmis-drawer-open-100";
        case "left":
          return "cbmis-drawer-position-left";
        case "bottom":
          return "cbmis-drawer-position-bottom cbmis-drawer-open-100";
        case "right":
          return "cbmis-drawer-position-right";
        default:
          break;
      }
    } else if (document.querySelector(".cbmis-body-dir-rtl")) {
      return "cbmis-drawer-position-right";
    } else {
      return "cbmis-drawer-position-left";
    }
  };

  const handleClose = (e) => {
    if (eventClose) {
      eventClose();
    }
  };

  const bodyMarginLeft = () => {
    document.body.style.paddingLeft = "5.1vw";
  };
  const getMode = () => {
    switch (String(mode).toLowerCase()) {
      case "overlay":
        return "cbmis-drawer-persistent";
      case "overlay-opacity":
        return "cbmis-drawer-opacity";
      case "show-header":
        bodyMarginLeft();
        return "cbmis-drawer-persistent cbmis-drawer-show-header";
      default:
        return "cbmis-drawer-persistent";
    }
  };

  const getWidth = () => {
    if (width) {
      return `cbmis-drawer-open-${String(width).toLowerCase()}`;
    } else {
      return "";
    }
  };

  return (
    <div
      id={getID.current}
      className={`cbmis-drawer-container ${open ? "cbmis-drawer-container-open" : ""} ${componentsFunction.CheckHiddenItem(hidden)} ${getMode()}`}
      {...getRestProps()}
    >
      {eventClose && open ? <div className="cbmis-drawer-tollage" onClick={handleClose} /> : ""}
      <div
        className={`cbmis-drawer ${handlePositionChange()} ${open ? `cbmis-drawer-open ${getWidth()}` : ""} ${componentsFunction.CheckDirectionItem(
          dir
        )} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${
          className || ""
        }`}
      >
        {String(mode).toLowerCase() !== "show-header" && blnHaveTitle? (
          <div className="cbmis-drawer-close">
            <label className={`cbmis-drawer-title`}>{title ? title : ""}</label>
            {eventClose ? <Icon icon="close" size="28px" onClick={handleClose} /> : ""}
          </div>
        ) : (
          ""
        )}
        <div className="cbmis-drawer-contant">{props.children}</div>
      </div>
    </div>
  );
};

export default Drawer;
