import { useState } from 'react'
import * as FormHelper from './formHelper/FormHelper';

function useForm(initialState) {
    const [formState, setFormState] = useState({...initialState})

    

     /********************** values functions *********************/
      //#region 
        const getCurrentFormStep = () => {
            return formState.step;
        };
        const getCurrentFormValues = () => {
            return FormHelper.getFormValues(formState);
        };

        const getValueByFieldName = (fieldName) => {
            return FormHelper.getValueByFieldName(fieldName, formState);
        };

        const setValue = (fieldName, newTextInput) => {
            if(fieldName){
              let newFormState = FormHelper.updateValue(JSON.stringify(newTextInput), fieldName, formState);
              const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState)?.error
              newFormState = FormHelper.updateError(errorMessage,fieldName, newFormState)
              setFormState({...newFormState});
            }
        };

        const setHidden = (fieldName, newConation) => {
          const newFormState = formState;
          if(!newFormState.fields[fieldName].hidden){
            newFormState.fields[fieldName].hidden = {}
          }
          newFormState.fields[fieldName].hidden.condition = newConation;
          setFormState({...newFormState});
        };

        const setDisabled = (fieldName, newConation) => {
          const newFormState = formState;
          if(!newFormState.fields[fieldName].hidden){
            newFormState.fields[fieldName].hidden = {}
          }
          newFormState.fields[fieldName].disabled.condition = newConation;
          setFormState({...newFormState});
        };

        //checkbox value
        const addCheckboxValue = (fieldName,newPairValue) => {
            let newFormState = FormHelper.addToCheckboxArray(newPairValue, fieldName, formState);
            const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState)?.error
            newFormState = FormHelper.updateError(errorMessage,fieldName, newFormState)
            setFormState({...newFormState});
        };

        const deleteCheckboxValue = (fieldName,key) => {
            let newFormState = FormHelper.deleteFromCheckboxArray(key, fieldName, formState);
            const errorMessage = FormHelper.checkFormValueByState(fieldName, newFormState)?.error
            newFormState = FormHelper.updateError(errorMessage,fieldName, newFormState)
            setFormState({...newFormState});
        };

        //#endregion


      /********************** Errors functions *********************/
      //#region 
      const setError = (fieldName, newError) => {
        let newFormState = FormHelper.updateError(newError, fieldName, formState);
        setFormState({...newFormState});
      };

      //#endregion
      /********************** validate functions *********************/
      //#region 
        const isFormValid = (fieldName = "") => {
          if(fieldName){
            return FormHelper.getError(fieldName, formState)?true:false;
          }else{
            let newFormState = FormHelper.checkFormValues(formState);
            setFormState({...newFormState});
            return FormHelper.isFormValid(newFormState);
          }
        };

      //#endregion

      /********************** Buttons functions *********************/
      //#region 
      //Reset button
      const resetForm = () => {
        const newFormState ={...formState}
        Object.keys(newFormState.fields).forEach(filed=>{
          newFormState.fields[filed].value="";
          newFormState.fields[filed].error="";

          if(String(newFormState.fields[filed].control).toLowerCase() === "checkbox" || String(newFormState.fields[filed].control).toLowerCase() === "multiplelist"){
            newFormState.fields[filed].value=[];
          }
        })
        setFormState({...newFormState});
      };
      //Next button
      const eventButtonNext = () => {
        const { step, fields } = formState;
        const newFormState = { step: parseInt(step) + 1, fields: fields };
        setFormState({...newFormState});
      };

      //Previous button
      const eventButtonPrevious = () => {
        const { step, fields } = formState;
        if (step > 0) {
          const newFormState = { step: parseInt(step) - 1, fields: fields };
          setFormState({...newFormState});
        }
      };

      //#endregion

      //#region options
        const setOptions =(fieldName,lstOptions=[],objOptionLang={})=>{
          if(fieldName && formState.fields?.[fieldName] && JSON.stringify(formState.fields[fieldName].options) !== JSON.stringify(lstOptions)){
            let newFormState = FormHelper.updateOptions(lstOptions,objOptionLang, fieldName, formState);
            setFormState({...newFormState});
          }
        }
      //#endregion

      //#region Add List Fields
       const addListFields =(fields=[])=>{
          const newFormState = formState
          fields.forEach(theField=>{
            newFormState.fields[theField?.fieldName] = {
              ...theField.field,
            }
            newFormState.labels[theField?.fieldName]={
                ...theField.labels,
            }
          })
          setFormState({...newFormState});
        }
    //#endregion


    //#region delete List Fields
    const deleteListFields =(fields=[])=>{
      fields.forEach(theField=>{
       delete formState?.fields?.[theField]
       delete formState?.labels?.[theField]
      })
      setFormState({...formState});
    }

    const deleteForm =()=>{
      formState.fields = {}
      formState.labels = {}
      setFormState({...formState});
    }
//#endregion

      const controller={
        formState,
        setFormState,

        getCurrentFormStep,
        getCurrentFormValues,
        getValueByFieldName,

        isFormValid,

        setValue,
        setError,
        setHidden,
        setDisabled,
        setOptions,

        addCheckboxValue,
        deleteCheckboxValue,

        addListFields, //[]
        deleteListFields,
        deleteForm,

        resetForm,
        eventButtonNext,
        eventButtonPrevious,
      }

    return {
      controller
    }
}

export default useForm
