import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";
import SponsorTrainingTypeOptionsView from "../../stakeholders/member/account/components/sponsorSetterModal/component/sponsorTrainingTypeOptions.view";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");
const partnerApi = generateQueries("tblPartner");
const statisticApi = generateQueries("tblStatistic");

export const CtrlSponserRegistration = ({ appState, appDispatch, isLoading, setIsLoading, countryCode }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  let jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  const handler = {
    initData:
      ({ state, setState, isLoading, setIsLoading, navigate, getNewSystemInfo, getNewCatInfo, fields }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);
          if (!jsnSystemConfig || JSON?.stringify(jsnSystemConfig)?.length < 500) {
            jsnSystemConfig = await getNewSystemInfo(appDispatch);
          }
          if (!appState?.clientInfo?.objCatInfo || JSON?.stringify(appState?.clientInfo?.objCatInfo)?.length < 500) {
            await getNewCatInfo();
          }

          if (countryCode && !jsnSystemConfig?.lstContryRegisterCode?.includes(countryCode)) {
            state.countryCode = "";
            navigate(appRouting.page404?.url);
          }
          if (!countryCode) {
            state.countryCode = appState.clientInfo.clientCountryCode ? appState.clientInfo.clientCountryCode : jsnSystemConfig?.strDefaultContryRegisterCode;
          } else {
            state.countryCode = countryCode;
            appState.clientInfo.clientCountryCode = countryCode;
          }

          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
          let lstCountryOption = [];
          let lstEaraCodeOption = [];
          let lstCountryNaOption = [];
          let countryID = "";
          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              if (jsnSystemConfig?.lstContryRegisterCode?.includes(value?.code?.value?.eng)) {
                lstCountryNaOption.push({ key: value?.id, value: value?.nationality?.value });
                lstCountryOption.push({ key: value?.id, value: value?.value });
                if (value?.phoneCode) {
                  lstEaraCodeOption.push({ key: value?.id, value: { eng: `+${value?.phoneCode?.value?.eng}`, arb: `${value?.phoneCode?.value?.arb}+` } });
                }
                if (state?.countryCode === value?.code?.value?.eng) {
                  countryID = value?.id;
                }
              }
            }
          });
          fields?.bigAreaCountryID?.setOptions(lstEaraCodeOption);
          fields?.bigAreaCountryID?.setValue(countryID);

          let lstToSupportOption = [];
          let lstSponsorTrainingTypeOption = [];

          [...Object.entries(objCat?.Root?.toSupport || {})]
            ?.filter(([key, value]) => value?.blnIsView?.value?.[lang] === "true")
            .reverse()
            .forEach(([key, value]) => {
              if (key !== "id" && key !== "value") {
                lstToSupportOption.push({ key: key, value: value?.supportLabel?.value?.[lang] });
              }
            });
          Object.entries(objCat?.Root?.toSupport?.[lstToSupportOption[0]?.key]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              const SponsorTrainingTypeOptions = SponsorTrainingTypeOptionsView({
                lang,
                value,
                getPartnerTransPlanTotalPrice: handler?.getPartnerTransPlanTotalPrice(lstToSupportOption[0]?.key, key, objCat),
                blnIsTraining: false,
                blnCurrency: true,
              });
              lstSponsorTrainingTypeOption.push({ key: key, value: SponsorTrainingTypeOptions?.optionLabel, jsx: () => SponsorTrainingTypeOptions?.jsx });
            }
          });
          fields?.strToSupport?.setOptions(lstToSupportOption, true);
          fields?.strSponsorTrainingType?.setOptions(lstSponsorTrainingTypeOption, true);
          fields?.strToSupport?.setValue(lstToSupportOption[0]);

          state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    getPartnerTransPlanTotalPrice: (strSponsorTrainingType, strSponsorType, objCat) => {
      try {
        const intCountStudentNumSystem = Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
        const intCountPrice = Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.sponser?.[strSponsorType]?.price?.value?.eng) || 0;
        if (intCountStudentNumSystem && !intCountPrice) {
          const priceSystem = Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.price?.value?.eng);
          return ((priceSystem || 0) * (intCountStudentNumSystem || 0)).toLocaleString();
        } else if (intCountPrice) {
          return intCountPrice.toLocaleString();
        }
        return 0;
      } catch {
        return 0;
      }
    },

    onChangeContry:
      ({ fields, state, setState }) =>
      () => {
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            if (
              // fields?.bigCountryOfCitizenID?.getValue()?.key === value?.id ||
              fields?.bigAreaCountryID?.getValue()?.key === value?.id
              // fields?.bigCountryOfResidenceID?.getValue()?.key === value?.id
            ) {
              state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.arb}`;
              fields?.bigCountryOfResidenceID?.setValue(value?.id);
              fields?.bigAreaCountryID?.setValue(value?.id);
              state.countryCode = value?.code?.value?.eng;
              state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;
              setState({ ...state });
            }
          }
        });
      },
    handleChangeToSupport:
      ({ fields }) =>
      async () => {
        try {
          const strSponsorTrainingType = fields?.strToSupport?.getValue()?.key;
          let lstSponsorTrainingTypeOption = [];

          Object.entries(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              const SponsorTrainingTypeOptions = SponsorTrainingTypeOptionsView({
                lang,
                value,
                getPartnerTransPlanTotalPrice: handler?.getPartnerTransPlanTotalPrice(strSponsorTrainingType, key, objCat),
                blnIsTraining: objCat?.Root?.toSupport?.[strSponsorTrainingType]?.blnIsTraining?.value?.eng !== "false",
              });
              lstSponsorTrainingTypeOption.push({ key: key, value: SponsorTrainingTypeOptions?.optionLabel, jsx: () => SponsorTrainingTypeOptions?.jsx });
            }
          });
          fields?.strSponsorTrainingType?.setOptions(lstSponsorTrainingTypeOption, true);
          fields?.strSponsorTrainingType?.setValue("");
        } catch (err) {
          console.error("Failed to handleChangeToSupport: ", err);
        }
      },
    handleSubmit: async ({ fields, navigate, state }) => {
      try {
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

        let hisPartner = null;
        let bigID = appFunctions.generateID(10);

        if (jsnSystemConfig?.blnAreaCode || jsnSystemConfig?.blnContryName) {
          let countryID = 0;
          if (jsnSystemConfig?.blnAreaCode) {
            countryID = fields?.bigAreaCountryID?.getValue()?.key;
          } else {
            countryID = fields?.bigCountryOfResidenceID?.getValue()?.key;
          }

          if (countryID) {
            let countryKeyjID = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
            state.countryCode = objCat?.Root?.country?.[countryKeyjID]?.code?.value?.eng;
          }
        }

        const strTrainingType = fields?.strToSupport?.getValue()?.key;
        let bigPartnerStatusID = !appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsConfiremSponer
          ? appVariables.objPartnerStatus.Approved
          : appVariables.objPartnerStatus.New;

        const intCountStudentNum = Number(objCat?.Root?.toSupport?.[strTrainingType].sponser?.[fields?.strSponsorTrainingType?.getValue()?.key]?.studentNumber?.value?.eng) || 0;
        const intPriceSystem = intCountStudentNum
          ? Number(objCat?.Root?.toSupport?.[strTrainingType]?.price?.value?.eng)
          : Number(objCat?.Root?.toSupport?.[strTrainingType].sponser?.[fields?.strSponsorTrainingType?.getValue()?.key]?.price?.value?.eng) || 0;

        if (fields?.blnRegister?.getValue()) {
          const result = await partnerApi(strPageInfo, appState, "aaiotPartnerAdd", {
            objectInput: {
              bigAppID: appVariables.App_ID,
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
              bigUserID: bigID,

              strUserEmail: fields?.strUserEmail?.getValue(),
              strUserPhone: fields?.strUserPhone?.getValue(),
              strUserPassword: fields?.strUserPassword?.getValue(),
              strTrainingTypeCode: state?.countryCode,

              bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
              strPlaceName: fields?.strPlaceName2?.getValue(),
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },

              intUserRoleFK: appVariables?.objUserRole?.Member,

              jsnUserInfo: {
                strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                intUserRoleFK: appVariables?.objUserRole?.Member,
                bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
                strPlaceName: fields?.strPlaceName2?.getValue(),
              },
              blnIsManager: false,
              blnIsSponsor: true,
              blnIsStartUp: false,
              blnIsStudent: false,

              jsnPartnerInfo: {
                strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
                strPlaceName: fields?.strPlaceName2?.getValue(),
                strUserEmail: fields?.strUserEmail?.getValue(),
                strUserPhone: fields?.strUserPhone?.getValue(),
                strURL: "",
                blnRegister: fields?.blnRegister?.getValue() === true,
              },

              intTraineesCount: intCountStudentNum,

              intSupportTransStatus: appVariables.objSupportTransStatus.Under_Process,

              bigPartnerStatusID: bigPartnerStatusID,
              jsnSupportTrans: [
                {
                  id: appFunctions.generateID(6),
                  dtmCreatedDate: new Date().toUTCString(),
                  dtmUpdatedDate: new Date().toUTCString(),
                  strTrainingType: strTrainingType,
                  intSupportTransStatus: appVariables.objSupportTransStatus.Send_New,
                  intTraineesCount: intCountStudentNum,
                  intTrainingProgPrice: intPriceSystem,
                  lstReceipt: [],
                  lstReceiptCatch: [],
                  intReceivedAmount:
                    objCat?.Root?.toSupport?.[strTrainingType]?.blnIsTraining?.value?.eng === "false" ? intPriceSystem : intPriceSystem * intCountStudentNum || null,
                  strNote: fields?.strNote?.getValue(),
                },
              ],
              blnIsConfirmed: bigPartnerStatusID === appVariables.objPartnerStatus.Approved,

              lstStatisticFelid: ["intCountJoinUser", "intCountJoinUserAll", "intCountJoinSponsor", "intCountJoinSponsorAll", "intCountTransSponsorTraining"],
              lstStatisticFelidOnUpdate: ["intCountJoinUser", "intCountJoinUserAll", "intCountTransSponsorTraining"],
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }
          appState.clientInfo.clientCountryCode = state?.countryCode;
          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
          appState.userInfo = result?.response;
          appDispatch();
          setIsLoading(false);
          fields?.controller?.resetForm();
          navigate(appRouting?.Account?.url, { replace: true });
          return;
        } else {
          const objectInput = {
            bigAppID: appVariables.App_ID,
            bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
            bigPartnerID: bigID,

            strUserEmail: fields?.strUserEmail?.getValue(),
            strUserPhone: fields?.strUserPhone?.getValue(),

            bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
            strPlaceName: fields?.strPlaceName2?.getValue(),
            strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },

            jsnPartnerInfo: {
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
              bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
              strPlaceName: fields?.strPlaceName2?.getValue(),
              strUserEmail: fields?.strUserEmail?.getValue(),
              strUserPhone: fields?.strUserPhone?.getValue(),
              strURL: "",
              blnRegister: fields?.blnRegister?.getValue() === true,
            },

            intTraineesCount: intCountStudentNum,

            intSupportTransStatus: appVariables.objSupportTransStatus.Under_Process,

            bigPartnerStatusID: bigPartnerStatusID,
            jsnSupportTrans: [
              {
                id: appFunctions.generateID(6),
                dtmCreatedDate: new Date().toUTCString(),
                dtmUpdatedDate: new Date().toUTCString(),
                strTrainingType: strTrainingType,
                intSupportTransStatus: appVariables.objSupportTransStatus.Send_New,
                intTraineesCount: intCountStudentNum,
                intTrainingProgPrice: intPriceSystem,
                lstReceipt: [],
                lstReceiptCatch: [],
                intReceivedAmount: objCat?.Root?.toSupport?.[strTrainingType]?.blnIsTraining?.value?.eng === "false" ? intPriceSystem : intPriceSystem * intCountStudentNum || null,
                strNote: fields?.strNote?.getValue(),
              },
            ],
            blnIsConfirmed: bigPartnerStatusID === appVariables.objPartnerStatus.Approved,
            lstStatisticFelidOnAdd: ["intCountJoinSponsor", "intCountJoinSponsorAll", "intCountTransSponsorTraining"],
            lstStatisticFelidOnUpdate: ["intCountTransSponsorTraining"],
          };

          const resultP = await partnerApi(strPageInfo, appState, "aaiotPartnerOnlyAdd", {
            objectInput: { ...objectInput, blnNeedUpdate: false },
          });

          if (!resultP?.blnIsRequestSuccessful) {
            if (typeof resultP?.response === "object") {              
              hisPartner = resultP.response;
              const resultP2 = await partnerApi(strPageInfo, appState, "aaiotPartnerOnlyAdd", {
                objectInput: {
                  ...hisPartner,
                  ...objectInput,
                  bigPartnerID: hisPartner?.bigPartnerID,
                  strUserEmail: objectInput?.strUserEmail || hisPartner?.strUserEmail || "",
                  jsnSupportTrans: [...hisPartner?.jsnSupportTrans, ...objectInput?.jsnSupportTrans],
                  blnNeedUpdate: true,
                },
                objectCondition: {
                  bigAppID: appVariables.App_ID,
                  bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
                  bigPartnerID: hisPartner?.bigPartnerID,
                },
              });

              if (!resultP2?.blnIsRequestSuccessful) {
                if (typeof resultP2?.response === "object") {
                  hisPartner = resultP2.response;
                } else {
                  appFunctions.logMessage(resultP2?.response, "");
                  if (dictionary?.shared?.alertFetchNote?.[resultP2?.response]) {
                    Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[resultP2?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
                  } else {
                    Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
                  }
                  setIsLoading(false);
                  return;
                }
              }
            } else {
              appFunctions.logMessage(resultP?.response, "");
              if (dictionary?.shared?.alertFetchNote?.[resultP?.response]) {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[resultP?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              } else {
                Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
              }
              setIsLoading(false);
              return;
            }
          }
          setIsLoading(false);
          fields?.controller?.resetForm();
          navigate(appRouting?.Public_Support?.url, { replace: true });
        }
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};
