import React, { useEffect, useRef, useState } from "react";
import "./Accordion.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const AccordionSummary = (props) => {
  const { children, id, expandIcon, open = false, blnOpenOne = false, arrowIcon = true, sx, hidden, disabled, dir, className, ...rest } = props;

  if(props?.[strTestProps]){
    if(children){
      console.log("AccordionSummary", "children");
    }
    if(props?.expandIcon){
      console.log("AccordionSummary", "expandIcon",);
    }
    if(props?.blnOpenOne){
      console.log("AccordionSummary", "blnOpenOne",);
    }
    if(props?.arrowIcon){
      console.log("AccordionSummary", "arrowIcon",);
    }
  }

  const screenWidth = useCheckScreenSize();

  const [ariaControlName, setAriaControlName] = useState("accordionSummaryInput");
  const getID = useRef(id || componentsFunction.randomName("AccordionSummary"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-accordion-tab-summary") });

  useEffect(() => {}, [children, id, expandIcon, open, arrowIcon, hidden, disabled, dir, className]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-accordion-tab-summary");
    }
  }, [sx, screenWidth]);

  useEffect(() => {
    const attribute = componentsFunction.checkParseObject(document.querySelector(".cbmis-accordion-tabs").attributes[2].value);
    if (attribute.ariaControl) {
      setAriaControlName(attribute.ariaControl);
    }
  }, []);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <React.Fragment>
      <input
        type={blnOpenOne?"radio":"checkbox"}
        name={ariaControlName}
        className={!disabled ? `cbmis-accordion-tab-summary-input` : "cbmis-accordion-tab-summary-input-dontOpen"}
        id={getID.current}
        {...getRestProps()}
        defaultChecked={open}
      />
      <label
        id={styleRef.current.strSXComponentID}
        className={`cbmis-accordion-tab-summary-label ${!arrowIcon ? "cbmis-accordion-tab-summary-label-arrowIcon" : ""} ${componentsFunction.CheckDirectionItem(
          dir
        )} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
          rest
        )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        htmlFor={getID.current}
      >
        <label className={`cbmis-accordion-tab-summary-label-content`}>
          <label className="cbmis-accordion-tab-summary-icon">{expandIcon}</label>
          <label className="cbmis-accordion-tab-summary-label-content-children">{children}</label>
        </label>
      </label>
    </React.Fragment>
  );
};

export default AccordionSummary;
