/* eslint-disable no-fallthrough */
import { useEffect, useRef } from "react";
import * as Alert from "../../material/feedback/Alert/Alert"

import * as permissionsVariable from "./PermissionsVariable/PermissionsVariable"

export default function usePermissions(appPermissionsState= permissionsVariable.objPermissionState,setAppPermissionsState,lang) {
  /**
 * @param {"cameraPermission" | "microphonePermission" | "notificationsPermission" | "locationPermission" | "midiPermission"} permissionType
 */
  var noop = async function () {};

    function checkPermission(permissionType,isViweAlart,descriptor) {
     try{
        let getPermission 
        switch (permissionType) {
          case permissionsVariable.objPermissionKey.midiPermission:
            getPermission =  navigator.permissions.query({name: permissionsVariable.objPermissionName[permissionType] , sysex: true})
            break;
          default:
            getPermission =  navigator.permissions.query({name: permissionsVariable.objPermissionName[permissionType] },descriptor)
          break;
        }
        getPermission.then(function (permission) {
          permission.addEventListener('change', function (e) {
            if(permission.state === "granted"){
              appPermissionsState[permissionType].system=true
              appPermissionsState[permissionType].user=true
              document.getElementById(permissionType).checked=true
            }else if(permission.state === 'denied'){   
              appPermissionsState[permissionType].system=false
              appPermissionsState[permissionType].user=false
              if(isViweAlart === true){
                Alert.viewAlert(permissionsVariable.permissionLangAlert[lang].allow,Alert.Alert_Type_Warning)
              }
            }else{
              appPermissionsState[permissionType].system=false
              appPermissionsState[permissionType].user=false
            }
            if(setAppPermissionsState){
              setAppPermissionsState({...appPermissionsState})
            }
          });

          if(permission.state === "granted"){
            appPermissionsState[permissionType].system=true
          }else if(permission.state === 'denied'){   
            appPermissionsState[permissionType].system=false
            appPermissionsState[permissionType].user=false
            // if(isViweAlart === true){
            //   Alert.viewAlert('Go to the browser sittings to allow the camera permission first',Alert.Alert_Type_Warning)
            // }
          }else{
            appPermissionsState[permissionType].system=false
            appPermissionsState[permissionType].user=false
          }
        })
      }catch (error) {
        switch (error.name){
          case "PermissionDeniedError":
              console.log("User denied access to geolocation")
              break;
          case "PermissionDismissedError":
              console.log("User dissmissed access to geolocation")
              break;
          case "PermissionTimeoutError":
              console.log("Couldn't find GPS position in time")
              break;
          case "TypeError": 
              console.log("The provided value geolocation is not a valid enum value of type PermissionName.")
              break;
          default:
            break;
        }
        appPermissionsState[permissionType].system=false
        appPermissionsState[permissionType].user=false
      }
      return appPermissionsState 
   
    }

    function checkAllPermission() {
      navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
        if('permissions' in navigator){
          const arrPermissionTypes = Object.keys(appPermissionsState)
          var allPermission = {}
          arrPermissionTypes.forEach(type => {
          allPermission =  checkPermission(type,false)
          });
          // if(setAppPermissionsState){
          //   setAppPermissionsState({...allPermission})
          // }
      }
    }

    function handleCameraAllow() {
      if ('permissions' in navigator) {
        if((navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)){
          if(!appPermissionsState[permissionsVariable.objPermissionKey.cameraPermission].system){      
              navigator.getUserMedia({video: true}, noop, noop)
              appPermissionsState = checkPermission(permissionsVariable.objPermissionKey.cameraPermission,true)
          }
          appPermissionsState = checkPermission(permissionsVariable.objPermissionKey.cameraPermission,true)
          if(appPermissionsState[permissionsVariable.objPermissionKey.cameraPermission].system){
            appPermissionsState[permissionsVariable.objPermissionKey.cameraPermission].user=true
          }else{
            appPermissionsState[permissionsVariable.objPermissionKey.cameraPermission].user=false
          }
        }else{
          Alert.viewAlert("This browser does not support desktop camera");
        }  
      }
      if(setAppPermissionsState){
        setAppPermissionsState({...appPermissionsState})
      }
      }

    function handleMicrophoneAllow() {
      if ('permissions' in navigator) {
        if((navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)){
          if(!appPermissionsState[permissionsVariable.objPermissionKey.microphonePermission].system){
              navigator.getUserMedia({audio: true}, noop, noop)
              checkPermission(permissionsVariable.objPermissionKey.microphonePermission,true);
          }
          checkPermission(permissionsVariable.objPermissionKey.microphonePermission,true);
          if(appPermissionsState[permissionsVariable.objPermissionKey.microphonePermission].system){
            appPermissionsState[permissionsVariable.objPermissionKey.microphonePermission].user=true
          }else{
            appPermissionsState[permissionsVariable.objPermissionKey.microphonePermission].user=false
          }
        }else{
          Alert.viewAlert("This browser does not support desktop microphone");
        }  
      }
      if(setAppPermissionsState){
        setAppPermissionsState({...appPermissionsState})
      }
    }

    function handleNotificationsAllow() {
      if ('permissions' in navigator) {
        if((navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)){
          if(!appPermissionsState[permissionsVariable.objPermissionKey.notificationsPermission].system){
              Notification.requestPermission();
              checkPermission(permissionsVariable.objPermissionKey.notificationsPermission,true);
          }
          checkPermission(permissionsVariable.objPermissionKey.notificationsPermission,true);
          if(appPermissionsState[permissionsVariable.objPermissionKey.notificationsPermission].system){
            appPermissionsState[permissionsVariable.objPermissionKey.notificationsPermission].user=true
          }else{
            appPermissionsState[permissionsVariable.objPermissionKey.notificationsPermission].user=false
          }
        }else{
          Alert.viewAlert("This browser does not support desktop notifications");
        }
        if(setAppPermissionsState){
          setAppPermissionsState({...appPermissionsState})
        }
        }
     }

    function handleLocationAllow() {
      if ('permissions' in navigator) {
        if((navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)){
          if(!appPermissionsState[permissionsVariable.objPermissionKey.locationPermission].system){
              navigator.geolocation.getCurrentPosition(noop);
              checkPermission(permissionsVariable.objPermissionKey.locationPermission,true);
          }
          checkPermission(permissionsVariable.objPermissionKey.locationPermission,true);
          if(appPermissionsState[permissionsVariable.objPermissionKey.locationPermission].system){
            appPermissionsState[permissionsVariable.objPermissionKey.locationPermission].user=true
          }else{
            appPermissionsState[permissionsVariable.objPermissionKey.locationPermission].user=false
          }
        }else{
          Alert.viewAlert("This browser does not support desktop location");
        }  
      }
      if(setAppPermissionsState){
        setAppPermissionsState({...appPermissionsState})
      }
    }

    function handleMidiAllow() {
      if ('permissions' in navigator) {
        if((navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)){
          if(!appPermissionsState[permissionsVariable.objPermissionKey.midiPermission].system){
              navigator.requestMIDIAccess({sysex: true})
              checkPermission(permissionsVariable.objPermissionKey.midiPermission, true);
          }
          
          if(appPermissionsState[permissionsVariable.objPermissionKey.midiPermission].system){
            appPermissionsState[permissionsVariable.objPermissionKey.midiPermission].user=true
          }else{
            appPermissionsState[permissionsVariable.objPermissionKey.midiPermission].user=false
          }
        }else{
          Alert.viewAlert("This browser does not support desktop MIDI");
        }  
      }   
    }

    function handlePushAllow() {
      if ('permissions' in navigator) {
        if((navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)){
          if(!appPermissionsState[permissionsVariable.objPermissionKey.pushPermission].system){
            navigator.serviceWorker.register('')
            .then(function(serviceWorkerRegistration) {
              serviceWorkerRegistration.pushManager.subscribe();
            });
          }
          checkPermission(permissionsVariable.objPermissionKey.pushPermission, {userVisibleOnly: true});
          if(appPermissionsState[permissionsVariable.objPermissionKey.pushPermission].system){
            appPermissionsState[permissionsVariable.objPermissionKey.pushPermission].user=true
          }else{
            appPermissionsState[permissionsVariable.objPermissionKey.pushPermission].user=false
          }
        }else{
          Alert.viewAlert("This browser does not support desktop location");
        }  
      }
        }

    function handleRequestDenied(permissionType){
      appPermissionsState[permissionType].user=false  
      setAppPermissionsState({...appPermissionsState})
    }


    useEffect(() => {
      checkAllPermission()
    }, [])

    return {
      checkPermission,
      checkAllPermission,
      handleCameraAllow,
      handleMicrophoneAllow,
      handleNotificationsAllow,
      handleLocationAllow,
      handleMidiAllow,
      handlePushAllow,
      handleRequestDenied,
  }
}