import React, { useEffect, useRef } from "react";
import "./Avatar.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Avatar = (props) => {
  const screenWidth = useCheckScreenSize();

  const { src, alt, color, mode, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const getID = useRef(id || componentsFunction.randomName("Avatar"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-avatar") });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [src, alt, mode, hidden, disabled, dir, className, id]);



  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-avatar");
    }
  }, [sx, screenWidth]);

  const getAratarMode = () => {
    if (mode) return componentsFunction.getShapeReturnClassName(mode);
    else return componentsFunction.getShapeReturnClassName("circle");
  };

  const avatarMode = useRef(getAratarMode());
  return (
    <div className="cbmis-avatar-container" {...getRestProps()} id={getID.current}>
      <div
        id={styleRef.current.strSXComponentID}
        className={`cbmis-avatar ${avatarMode.current} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
          disabled
        )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
          style={{
            background:`url(${src || ""})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor:componentsFunction.checkColorReturnValue(color || "secondary")
          }}
        >
        <div className="cbmis-avatar-containt">{props.children}</div>
      </div>
    </div>
  );
};

export default Avatar;
