import React from 'react'
import Uploader from '../../Uploader'
import Icon from "../../../../icon/CbmisIcon"

function UploaderImageList(props) {
    const {images,setImages,gallery,camera,handelOpenCamera,handleDeleteFile,handleClear,handlePreview,maxSizeKB,maxFilesNumber,maxWidth,maxHeight,quality} = props

  return (
    <React.Fragment>
        <div className="list-upload">
            <div className='list-image-preview-container'>
                {
                    Object.values(images).map((imgFile,index)=>{
                        return(
                            <div className="image-preview-container-list" key={index}>
                                <div className="image-preview-container-header" >
                                    <img src={URL.createObjectURL(imgFile)} alt="/" className="image-preview image-preview-list"/>
                                    <label className='label-preview-list'>
                                        {imgFile.name}
                                    </label>
                                </div>
                                <div className="image-preview-container-header" >
                                    <Icon icon="designServices" onClick={handlePreview(imgFile,index)} size="1.3rem"/>
                                    <Icon icon="close" onClick={handleDeleteFile(index)} size="1.3rem"/>

                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="list-upload-option">
                {
                    !Object.values(images).length?<span />:(
                        <button className="list-upload-options-btn" onClick={handleClear}>
                            <Icon icon="delete" />
                        </button>
                    )
                }

               <div className="list-upload-option-upload">
                {
                    !gallery?null:(
                        <label className="list-upload-options-btn ">
                            <Uploader images={images} setImages={setImages}  maxSizeKB={maxSizeKB} maxFilesNumber={maxFilesNumber} maxWidth={maxWidth} maxHeight={maxHeight} quality={quality}/>
                            <Icon icon="uploadFile" />
                        </label>
                    )
                }
                {
                    !camera?null:(
                        <button className="list-upload-options-btn list-upload-options-btn-camera" onClick={handelOpenCamera}>
                        <Icon icon="cameraAlt" />
                        </button>
                    )
                }
               </div>
            </div>

        </div>
    </React.Fragment>
  )
}

export default UploaderImageList