import React, { forwardRef, useEffect } from 'react'
import * as FormVariables from "./formHelper/formVariables/FormVariables"
import Text from '../../material/inputs/Text/Text'
import RadioButtons from '../../material/inputs/RadioButtons/RadioButtons'
import List from '../../material/inputs/List/List'
import CheckboxGroup from '../../material/inputs/CheckboxGroup/CheckboxGroup'
import MultipleList from '../../material/inputs/MultipleList/MultipleList'
import Password from '../../material/inputs/Password/Password'
import TextArea from '../../material/inputs/TextArea/TextArea'
import DatePicker from '../../material/inputs/DatePicker/DatePicker'
import Number from '../../material/inputs/Number/Number'
import RangeSlider from '../../material/inputs/RangeSlider/RangeSlider'
import Rating from '../../material/inputs/Rating/Rating'
import Color from '../../material/inputs/Color/Color'
import Switch from '../../material/inputs/Switch/Switch'

const FormControl = forwardRef((props, ref) => {
  const {control,
        currentLang="",
        setCurrentLang=()=>{},
        objLang={},
        setValue,
        ...rest} = props


  const fieldsType = FormVariables.fieldType

  useEffect(()=>{},[control,currentLang,objLang,rest])

  switch (String(control).toLowerCase()) {
    case fieldsType.text:
      return <Text
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
              objLang={objLang}
              ref={ref}
              {...rest}/>
    case fieldsType.textArea:
      return <TextArea
              currentLang={currentLang}
              setCurrentLang={setCurrentLang}
              objLang={objLang}
              {...rest}/>
    case fieldsType.password:
      return <Password {...rest} />
    case fieldsType.number:
      return <Number setValue={setValue}  {...rest} />
    case fieldsType.date:
        return <DatePicker {...rest} />
    case fieldsType.radio:
      return <RadioButtons {...rest} />;
    case fieldsType.list:
      return <List  {...rest} />;
    case fieldsType.checkbox:
        return <CheckboxGroup {...rest} />;
    case fieldsType.multipleList:
      return <MultipleList {...rest} />;
    case fieldsType.rangeSlider:
      return <RangeSlider {...rest} />;
    case fieldsType.rating:
      return <Rating {...rest} />;
    case fieldsType.switch:
      return <Switch {...rest} />;
    case fieldsType.color:
      return <Color {...rest} />;
    default:
      return ""
  }
})


export default FormControl