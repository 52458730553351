import { useRef, useState } from "react";
import { resizeImgKB, resizeImgWH } from "../cbmisUploader/rezieImageFun";
// import { ImagesContext } from "../contextApi/contexts/images";
// import * as cbmisFunction from "../../cbmisHelper/forDev/cbmisFunction";
const useCamera = (videoRef, photoRef, constraints, front, setFront,maxFilesNumber=1,maxWidthImage) => {

    const imageUrl =useRef()
    const [imagesList, setImagesList] = useState([])
    const [tookPhoto, setTookPhoto] = useState(false)
    const [showIndex, setShowIndex] = useState(-1)
    let nummaxFilesNumber=Number(maxFilesNumber)
    const getVideo = () => {
        navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
                const video = videoRef.current;
                video.srcObject = stream
                video.play();
            }).catch(err => {
                console.log(err)
            })
    }

    // stop only camera
    function stopVideo() {
        const video = videoRef.current;
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(function(track) {
            track.stop();
         });
        video.srcObject=null
    }

    const takePicture = () => {
        const width = window.innerWidth
        const height = width / (13 / 9)
        let video = videoRef.current
        let photo = photoRef.current
        photo.width = width
        photo.height = height
        let ctx = photo.getContext('2d')
        ctx.drawImage(video, 0, 0, width, height)
        setTookPhoto(true)
        imageUrl.current = photo.toDataURL('image/jpeg', 0.5)
    }

    const updateStorage = (setVisibility) => {
        try {
            setVisibility(true)
            setTimeout(() => {
                setVisibility(false)
            }, 4000);
        } catch (error) {
            throw new Error(error)
        }

    }


    const flipCam = () => {
        setFront(!front)
    }

    const closePicture = () => {
        setTookPhoto(false)
    }

    const showImage = (index) => {
        setShowIndex(index)
    }

    const changeImageName=(event)=>{
        const index = event.target.id
        const newName = event.target.value
        imagesList[index] = {name:newName,url:imageUrl.current}
    }

    const saveImage = () => {
        if(imagesList.length < nummaxFilesNumber){
            imagesList.push({name:`image${imagesList.length+1}`,url:imageUrl.current})
        }else{
            imagesList[nummaxFilesNumber-1]={name:`image${nummaxFilesNumber}`,url:imageUrl.current}
        }
        setImagesList([...imagesList])
        setTookPhoto(false)
    }

    const deleteImage = (imgIndex) => {
        const newImagesList= imagesList.filter((_,index)=>index !== imgIndex)
        setImagesList(newImagesList)
    }

    async function resizeCameraImage(imagesList,imageSizeKB,maxWidth,maxHeight,quality,maxFilesNumber,blnDownload=false) {
        let lstResultFile = [];
        for (var i = 0; i < imagesList.length; i++) {
            let fileNew = await fetch(imagesList[i].url);
            let blob = await fileNew.blob();
            let strFileName = `${imagesList[i].name}.jpg`;
            let strNewFileType = "image/jpeg";

            let newObjFile = new File([blob], strFileName, { type: strNewFileType });
            // Object.defineProperty(newObjFile, 'size', { value: sizeKb * 1024 + 1 })
            if(i < maxFilesNumber){
                lstResultFile.push(newObjFile);
              }else{
                lstResultFile[maxFilesNumber - 1] = newObjFile
              }

            // if (blnDownload) {
            //     await cbmisFunction.downloadResizeFile(newObjFile);
            //   }
        }
        if(imageSizeKB){
            lstResultFile = resizeImgKB(lstResultFile,imageSizeKB,maxFilesNumber,quality,blnDownload)
        }else if(maxWidth||maxHeight){
            lstResultFile =  resizeImgWH(lstResultFile,maxWidth,maxHeight,quality,maxFilesNumber,blnDownload)
        }
        return lstResultFile 
    }

    return {
        getVideo,
        stopVideo,
        takePicture,
        tookPhoto,
        closePicture,
        flipCam,
        showImage,
        saveImage,
        front,
        deleteImage,
        updateStorage,
        imagesList,
        showIndex,
        changeImageName,
        resizeCameraImage
    }
}

export default useCamera