import React, { useEffect, useState } from "react";
import QRCode from "qrcode";

export default function QRCodeGenerator({ strText, id = null }) {
  const [state, setState] = useState({ qrCodeImg: "" });
  useEffect(() => {
    generateQRCode();
  }, [strText]);

  async function generateQRCode() {
    try {
      const qrResult = await QRCode.toDataURL(strText, { errorCorrectionLevel: "H", color: { dark: "#000000ff" }, type: "image/png", scale: 10, });
      state.qrCodeImg = qrResult;
      setState({ ...state });
      return;
    } catch (error) {
      console.log(error);
    }
  }

  return <img id={id} src={state.qrCodeImg} alt="" style={{width:"100%"}} />;
}
