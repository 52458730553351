import { StyleBuilder, styleVariables, cssKeys, mainTheme, sizeOneValueSetter, aggregationSizeStyle, sizeValuesSetter } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [`${styleVariables?.prefixDefaultClassName}_grid_container`]: {
        width: cssObject?.size?.[cssKeys?.full],
        flexWrap: "wrap",
        flexFlow: "wrap",
        margin: "0 auto",
        padding: 0,
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position: cssObject?.position?.[cssKeys?.relative],
        display: cssObject?.display?.[cssKeys?.flex],
        alignContent: "flex-start",
        alignItems: "stretch",
        justifyItems: "stretch",
      },
      [`${styleVariables?.prefixDefaultClassName}_grid_item`]: {
        margin: 0,
        flexWrap: "wrap",
        flexFlow: "wrap",
        justifyItems: "stretch",
        boxSizing: cssObject?.boxSizing?.[cssKeys?.borderBox],
        position: cssObject?.position?.[cssKeys?.relative],
        display: cssObject?.display?.[cssKeys?.flex],
      },
    },
  });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [`${styleVariables?.prefixDefaultClassName}_grid`]: (props) => {
        let objsx = {};
        if (props?.item) {
          const result = sizeValuesSetter({ xs: props?.xs, sm: props?.sm, md: props?.md, lg: props?.lg, xl:props?.xl }, (size, styleValue) => {
            return {
              xl: getGridCol(styleValue),
              lg: getGridCol(styleValue),
              md: getGridCol(styleValue),
              sm: getGridCol(styleValue),
              xs: getGridCol(styleValue),
              default: getGridCol(null),
            }?.[size];
          });      

            
          let objColStyle = { ...getGridCol(null) };
          if (props?.xs) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.xs),
              xs: {
                ...getGridCol(props?.xs),
              },
            };
          }
          if (props?.sm) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.sm),
              sm: {
                ...getGridCol(props?.xs),
              },
            };
          }
          if (props?.md) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.md),
              md: {
                ...getGridCol(props?.md),
              },
            };
          }
          if (props?.lg) {
            objColStyle = {
              ...objColStyle,
              ...getGridCol(props?.lg),
              lg: {
                ...getGridCol(props?.lg),
              },
            };
          }
          if (props?.xl) {
            objColStyle = {
              ...objColStyle,
              xl: {
                ...getGridCol(props?.xl),
              },
            };
          }
          objsx = aggregationSizeStyle(objColStyle,objsx);
        }
        if (props?.container) {
          const spacing = isNaN(parseInt(props?.spacing)) ? 1 : parseInt(props?.spacing);
          objsx = {
            ...objsx,
            "&>": {
              [`.${styleVariables?.prefixDefaultClassName}_grid_item`]: {
                padding: `${currentBaseSize * spacing}rem`,
              },
            },
          };
        }

        objsx = {
          fontSize: cssObject?.text?.size?.body1,
          color: cssObject?.color?.bodyColor,
          ...aggregationSizeStyle(
            sizeOneValueSetter("alignContent", props?.alignContent),
            sizeOneValueSetter("alignItems", props?.alignItems),
            sizeOneValueSetter("alignSelf", props?.alignSelf),
            sizeOneValueSetter("justifyContent", props?.justifyContent),
            sizeOneValueSetter("flexDirection", props?.direction),
            objsx
          ),
        };

        return objsx;
      },
      [`${styleVariables?.prefixDefaultClassName}_grid_sx`]: (props) => {
        return {
          ...props?.sx,
        };
      },
    },
  });

  const getGridCol = (col) => {
    const gridCol = parseInt(col);
    switch (gridCol) {
      case 0:
        return {
          flexGrow: 0,
          width: "0",
          flexBasis: 0,
          display: "none",
        };
      case 1:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["1/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["1/12"]],
          display: "flex",
        };
      case 2:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["2/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["2/12"]],
          display: "flex",
        };
      case 3:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["3/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["3/12"]],
          display: "flex",
        };
      case 4:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["4/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["4/12"]],
          display: "flex",
        };
      case 5:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["5/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["5/12"]],
          display: "flex",
        };
      case 6:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["6/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["6/12"]],
          display: "flex",
        };
      case 7:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["7/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["7/12"]],
          display: "flex",
        };
      case 8:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["8/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["8/12"]],
          display: "flex",
        };
      case 9:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["9/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["9/12"]],
          display: "flex",
        };
      case 10:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["10/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["10/12"]],
          display: "flex",
        };
      case 11:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.["11/12"]],
          flexBasis: cssObject?.size?.[cssKeys?.["11/12"]],
          display: "flex",
        };
      case 12:
        return {
          flexGrow: 0,
          width: cssObject?.size?.[cssKeys?.full],
          flexBasis: cssObject?.size?.[cssKeys?.full],

          display: "flex",
        };
      default:
        return {
          flexGrow: 0,
          width: "auto",
          display: "flex",
          flexBasis: "auto",
        };
    }
  };

  const getGridClassName = ({ container, item, classes, classesSx }) => {
    const isContainer = container ? `${classes?.[`${styleVariables?.prefixDefaultClassName}_grid_container`]}` : "";
    const isItem = item ? `${classes?.[`${styleVariables?.prefixDefaultClassName}_grid_item`]}` : "";
    return `${isContainer} ${isItem} ${classesSx?.[`${styleVariables?.prefixDefaultClassName}_grid`]} ${classesSx?.[`${styleVariables?.prefixDefaultClassName}_grid_sx`]}`;
  };

  return {
    getGridClassName,
    style,
    styleSx,
  };
};
