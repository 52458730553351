
//  self.addEventListener("push", function (event) {
//     try {
//       const data = event.data.json();
//       self.registration.showNotification(data.title, { ...data?.option });
//     } catch (error) {
//       console.log(error);
//     }
//   });

 /**
   *
   * @param {string} title
   * @param {NotificationOptions} option
   * @param {boolean} isTest
   */
 export function offlineNotification(title, option, isTest = true) {
    if (!("Notification" in window && navigator.serviceWorker)) {
      if (isTest) {
        console.warn("This browser does not support desktop notification");
      }
    } else if (Notification.permission === "granted") {
      new Notification(title, option);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          new Notification(title, option);
        }
      });
    }
  }

  /**
   *
   * @param {string} strSubscribeLink
   * @param {string} strGetPublicKeyLink
   * @param {string} strServerWorkerPath
   * @param {boolean} isTest
   */
  export async function pushNotification(strSubscribeLink, strGetPublicKeyLink, strServerWorkerPath = "/worker.js", isTest = true) {
    try {
      function urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      }

      if (!("serviceWorker" in navigator)) {
        if (isTest) {
          console.warn("Service worker not supported.");
        }
        return;
      }

      await navigator.serviceWorker.register(strServerWorkerPath, {
        scope: "/",
      });

      const serviceWorkerReady = await navigator.serviceWorker.ready;

      const prevSubscribe = await serviceWorkerReady.pushManager.getSubscription();

      if (prevSubscribe) {
        const res = await fetch(strSubscribeLink, {
          method: "POST",
          body: JSON.stringify(prevSubscribe),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.status >= 400) {
          await unregisterServiceWorker();
        }
        return;
      }

      const publicVapidIDResponse = await fetch(strGetPublicKeyLink, {
        method: "GET",
      });

      const publicVapidID = await publicVapidIDResponse.json();

      const subscription = await serviceWorkerReady.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidID.publicKey),
      });

      const res = await fetch(strSubscribeLink, {
        method: "POST",
        body: JSON.stringify(subscription),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status >= 400) {
        await unregisterServiceWorker();
      }
    } catch (error) {
      if (isTest) {
        console.error(error);
      }
    }
  }

  export async function unregisterServiceWorker(isTest = false) {
    try {
      if (!("serviceWorker" in navigator)) {
        return;
      }
      const serviceWorkerReady = await navigator.serviceWorker.ready;
      serviceWorkerReady.unregister();
      //window.location.reload();
    } catch (error) {
      if (isTest) {
        console.error(error);
      }
    }
  }
  



  /**
  *
  * @param {string} strSubscribeLink
  * @param {string} strGetPublicKeyLink
  * @param {string} strServerWorkerPath
  * @param {boolean} isTest
  */
  export async function pushNotificationForMe(strSubscribeLink, strGetPublicKeyLink, strServerWorkerPath = "./serviceWorker.js", isTest = true) {
   try {
     function urlBase64ToUint8Array(base64String) {
       const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
       const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
 
       const rawData = window.atob(base64);
       const outputArray = new Uint8Array(rawData.length);
 
       for (let i = 0; i < rawData.length; ++i) {
         outputArray[i] = rawData.charCodeAt(i);
       }
       return outputArray;
     }
 
     if (!("serviceWorker" in navigator)) {
       if (isTest) {
         console.warn("Service worker not supported.");
       }
       return;
     }
 
     if (!("PushManager" in window)) {
       if (isTest) {
         console.warn("Push isn't supported on this browser.");
       }
       return;
     }
 
     if (!("Notification" in window && navigator.serviceWorker)) {
       if (isTest) {
         console.warn("This browser does not support desktop notification");
       }
       return;
     }
 
     if (Notification.permission === "denied") {
       if (isTest) {
         console.warn("Notification permission is denied by the user.");
       }
       return;
     }
 
     await navigator.serviceWorker.register(strServerWorkerPath, {
       scope: "/",
     });
 
     const serviceWorkerReady = await navigator.serviceWorker.ready;
 
     const prevSubscribe = await serviceWorkerReady.pushManager.getSubscription();
 
     if (prevSubscribe) {
       const res = await fetch(strSubscribeLink, {
         method: "POST",
         body: JSON.stringify(prevSubscribe),
         headers: {
           "Content-Type": "application/json",
         },
       });
 
       if (res.status >= 400) {
         await unregisterServiceWorker();
       }
       return;
     }
 
     const publicVapidIDResponse = await fetch(strGetPublicKeyLink, {
       method: "GET",
     });
 
     const publicVapidID = await publicVapidIDResponse.json();
 
     const subscription = await serviceWorkerReady.pushManager.subscribe({
       userVisibleOnly: true,
       applicationServerKey: urlBase64ToUint8Array(publicVapidID.publicKey),
     });
 
     const res = await fetch(strSubscribeLink, {
       method: "POST",
       body: JSON.stringify(subscription),
       headers: {
         "Content-Type": "application/json",
       },
     });
 
     if (res.status >= 400) {
       await unregisterServiceWorker();
     }
   } catch (error) {
     if (isTest) {
       console.error(error);
     }
   }
 }


 

/**
 *
 * @param {string} strSetSubscriptionLink
 * @param {string} strGetPublicKeyLink
 * @param {string} strServerWorkerPath
 * @param {boolean} isTest
 */
export async function registerPushServiceWorker(strSetSubscriptionLink, strGetPublicKeyLink, strServerWorkerPath = "./serviceWorker.js", isTest = false) {
  try {
    function urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    if (!("serviceWorker" in navigator)) {
      if (isTest) {
        console.warn("Service worker not supported.");
      }
      return;
    }

    if (!("PushManager" in window)) {
      if (isTest) {
        console.warn("Push isn't supported on this browser.");
      }
      return;
    }

    if (!("Notification" in window && navigator.serviceWorker)) {
      if (isTest) {
        console.warn("This browser does not support desktop notification");
      }
      return;
    }

    if (Notification.permission === "denied") {
      if (isTest) {
        console.warn("Notification permission is denied by the user.");
      }
      return;
    }

    await navigator.serviceWorker.register(strServerWorkerPath, {
      scope: "/",
    });

    const serviceWorkerReady = await navigator.serviceWorker.ready;

    let activeSubscription;
    const prevSubscribe = await serviceWorkerReady.pushManager.getSubscription();
    activeSubscription = prevSubscribe;
    if (!prevSubscribe) {
      const publicVapidIDResponse = await fetch(strGetPublicKeyLink, {
        method: "GET",
      });

      const publicVapidID = await publicVapidIDResponse.json();

      const subscription = await serviceWorkerReady.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidID.publicKey),
      });
      activeSubscription = subscription;
    }

    await fetch(strSetSubscriptionLink, {
      method: "POST",
      body: JSON.stringify(activeSubscription),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    if (isTest) {
      console.error(error);
    }
  }
}

