import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../../util/appStyle";

export const objStyle = makeStyle({
    secDark: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: "calc(100vh - 0px)",
      margin: "auto",
      backgroundColor: "rgb(0, 2, 36)",
      background: "linear-gradient(155deg, #000224 0%, #010219 55%, #0371a0 100%)",
    },
    mainContainer: {
      width: `${(11 / 12) * 100}%`,
    },
    container: {
      width: `${(10 / 12) * 100}%`,
      color: appStyle?.App_Light_Text_Color,
      ...appStyle.fontsChanga,
      textAlign: "justify",
      paddingInlineEnd: "20px",
      xs: {
        width: `${(11 / 12) * 100}%`,
      },
    },
    field: {
      fieldset: {
        borderRadius: 0,
      },
      input: {
        color: appStyle.App_Input_Text_Color,
        ...appStyle?.fontsMada,
      },
      label: {
        ...appStyle?.fontsMada,
      },
      ".uicore_switch_label_1gtyshu": {
        color: appStyle?.App_Light_Text_Color,
        textAlign: "justify",
      },
      ".uicore_menu": {
        background: appStyle?.App_Dark_Color,
        border: "1px solid",
        borderColor: appStyle?.App_info_Color,
        borderRadius: 0,
        color: appStyle?.App_Light_Text_Color,
        ...appStyle?.fontsMada,
        ".uicore_radio_opt_label": {
          "&hover": {
            background: appStyle?.App_Dark_menu_Color,
          },
        },
      },
    },
    bgBox:({lang})=>({
      zIndex: 10,
      "&before": {
        content: "",
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        background: `url(${require("../../../../util/assets/images/box1-h.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
      },
    }),
    btnBox: {
      fontSize: "large",
      borderWidth: "1px",
      span: {
        ...appStyle?.fontsMada,
      },
      "&hover": {
        background: appStyle.App_Second_Opacity_Color,
        color: appStyle.App_Light_Text_Color,
      },
    },
    imageAvatar: {
      width: "90px",
      height: "90px",
      color: appStyle.App_Dark_Opacity_Color,
      border: "1px solid",
      borderColor: appStyle.App_info_Color,
      borderRadius: "50%",
    },
    uploader: {
      position: "absolute",
      right: "0px",
    },
    partnerCat: {
      border: "1px solid",
      borderColor: `${appStyle?.App_info_Color} !important`,
      color: `${appStyle?.App_info_Color} !important`,
      borderRadius: "1rem",
      paddingInline: "1rem",
    },
    icon: {
      zIndex: 12,
      opacity: 0.8,
      borderRadius: "5px",
      color: appStyle?.App_info_Color,
      "&hover": {
        background: appStyle?.App_info_Color,
        color: appStyle?.App_Dark_Color,
        opacity: 1,
      },
    },
    modal: {
      background: appStyle?.App_Dark_menu_Color,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      minHeight: "25%",
      color: appStyle.App_Light_Text_Color,
      padding: "20px",
      sm: {
        width: "88%",
      },
    },
    modalHeader: {
      border: "none",
      color: appStyle.App_info_Color,
      margin: "auto",
      maxHeight: "10px",
      ".uicore_icon": {
        width: "25px",
        height: "25px",
      },
    },
    labelModal: {
      ...appStyle.fontsChanga,
      textAlign: "justify !important",
    },
    tableRow: {
      backgroundColor: `${appStyle?.App_info_Opacity_Color} !important`,
      color: appStyle.App_info_Color,
    },
    tblBodyRow: {
      color: appStyle.App_Light_Text_Color,
      background: "transparent !important",
    },
    btn: {
      span: {
        ...appStyle?.fontsMada,
      },
    },
  });
