import  * as appFunctions from "../../../../util/appFunctions";
import { generateQueries, objTableAttributes } from "../../../../graphql/schema/GenerateQuery";
import { Alert } from "@cbmisorg/material";
import * as XLSX from "xlsx";
import { appLangDirection, dictionary } from "../../../../util/appLanguage/dictionary";
import appRouting from "../../../../configuration/routing/appRouting";
import { generatePath } from "react-router-dom";
const forumApi = generateQueries("tblForum");
const activityGuestApi = generateQueries("tblActivityGuest");
const certificateApi = generateQueries("tblCertificate");

const strPageInfo = "@src/client/component/stakeholders/admin/mngRegForum/mngRegForum.controller.js";
export default function mngRegForumCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage;
  // const maxCertificatePerPatch = 80;
  // const fontSizeNameCert = 52;
  // const fontCharWidth = (blnIsArabic) => (blnIsArabic ? fontSizeNameCert / 2.85 : fontSizeNameCert / 2.75);

  const handler = {
    initData:
      ({ isLoading, setIsLoading, state, setState }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          setIsLoading(true);
          const result = await forumApi(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes.tblForum.full,
            objectCondition: {
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang]);
            setIsLoading(false);
            return;
          }

          state.lstForum = Array.isArray(result?.response) ? result?.response : [];

          setState({ ...state });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    exportAttendances:
      ({ isLoading, setIsLoading }) =>
      ({ bigActivityType, blnStrictRegForum }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          setIsLoading(true);
          const result = await activityGuestApi(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: objTableAttributes.tblActivityGuest.full,
            objectCondition: {
              blnIsActive: true,
              blnIsDeleted: false,
              bigActivityType: bigActivityType,
              ...(blnStrictRegForum ? { blnIsConfirmed: true } : {}),
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang]);
            setIsLoading(false);
            return;
          }

          const resultCert = await certificateApi(strPageInfo, appState, "aaiotAppFindAll", {
            arrAttributes: ["strID", "jsnInfo"],
            objectCondition: {},
          });
          if (!resultCert?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang]);
            setIsLoading(false);
            return;
          }
          let lstCert = Array.isArray(resultCert?.response) ? resultCert?.response : [];

          let lstInputLabel = Array.isArray(result?.response) ? result?.response : [];

          let worksheet_data = [];

          lstInputLabel?.forEach((input) => {
            let strCertLink = null;

            for (let i = lstCert.length - 1; i >= 0; i--) {
              if (JSON.stringify(lstCert?.[i]?.jsnInfo).includes(String(input?.jsnGuestInfo?.strFullName).trim())) {
                strCertLink = lstCert?.[i]?.strID;
                break;
              }
            }

            worksheet_data?.push({
              "Full Name": input?.jsnGuestInfo?.strFullName,
              Email: input?.strUserEmail,
              Position: input?.strPlaceName,
              "Work Sector": input?.bigWorkSector === 5460695364 ? "حكومي" : input?.bigWorkSector === 1376840499 ? "خاص" : "",
              "Certificate Link": strCertLink ? window.location.origin + generatePath(appRouting.ECertificate.url, { id: strCertLink }) : "",
            });
          });

          let worksheet = XLSX.utils.json_to_sheet(worksheet_data);
          let workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "قائمة الحضور");
          worksheet["!autofilter"] = { ref: "A1" };
          XLSX.writeFile(workbook, "قائمة الحضور.xlsx");

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },
     
  };
  return handler;
}

export const operationForumMode = {
  add: "add",
  edit: "edit",
  delete: "delete",
};


// const jsnForumInfo = {
//   strForumName: "",
//   dtmForumDate: "",
//   strForumLocation: "",
//   lstForumBadgeFile: [],
//   lstForumCertificateFile: [],
//   lstForumBrochureFile: [],
//   objForumBadgeSelectInfo: {
//     imageWidth: "",
//     imageHeight: "",
//     imageTop: "",
//     imageLeft: "",
//     imageRight: "",
//     imageBottom: "",
//     startX: "",
//     startY: "",
//     mouseX: "",
//     mouseY: "",
//     ctxWidth: "",
//     ctxHeight: "",
//     fontSize: "",
//     fontColor: "",
//   },
//   objForumCertificateSelectInfo: {},
//   blnHaveSubForum: false,
//   lstSubForum: [
//     {
//       bigSubForumID: null,
//       strSubForumPath: null,
//       strSubForumName: "",
//       dtmSubForumData: "",
//       strSubForumLocation: "",
//       lstSubForumBadgeFile: [],
//       lstSubForumCertificateFile: [],
//       objSubForumBadgeSelectInfo: {},
//       objSubForumCertificateSelectInfo: {},
//     },
//   ],
// };
