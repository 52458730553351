import { Button, Confirmation, Grid, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import React, { useState } from "react";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import saveForumModalCtrl from "./saveForumModal.controller";
import { operationForumMode } from "../../mngRegForum.controller";
import { App_Server_Url_DownloadFiles, objUploadType } from "../../../../../../configuration/appVariables";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import NoResultImg from "../../../../../../../old/components/sharedUi/noResultImg/NoResultImg";
import { App_Dark_Bluewood } from "../../../../../../../old/appHelper/appColor";
import { Line } from "@cbmisorg/client-app";

export default function SaveForumModalView({ isLoading, setIsLoading, parentState, setParentState, selectNameCoordinateOpenEle }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.stakeholders?.admin?.mngRegForum;

  const [state, setState] = useState({
    objForumInfo: null,

    //conference
    blnOpenSaveModal: false,
    strOperationForum: null,
    intIndexForum: null,

    //sub-forum
    blnOpenSubForumSaveModal: false,
    strOperationSubForum: null,
    intIndexSubForum: null,
    lstSubForum: [],

    //file
    lstForumBrochureFile: [],
    lstForumBadgeFile: [],
    lstForumCertificateFile: [],
    lstSubForumBadgeFile: [],
    lstSubForumCertificateFile: [],

    objForumBadgeSelectInfo: {},
    objForumCertificateSelectInfo: {},
    objSubForumBadgeSelectInfo: {},
    objSubForumCertificateSelectInfo: {},
  });

  const fields = FormData({
    strForumName: { control: "text", blnLanguage: false, validations: { required: true } },
    dtmForumDate: { control: "date", blnLanguage: false, validations: { required: () => !fields?.blnHaveSubForum?.getValue()?.length } },
    strForumLocation: { control: "text", blnLanguage: false, validations: { required: () => !fields?.blnHaveSubForum?.getValue()?.length } },
    blnStrictRegForum: { control: "checkbox", blnLanguage: false, options: ["true"] },
    blnHaveSubForum: { control: "checkbox", blnLanguage: false, options: ["true"] },
  });

  const fldSubForum = FormData({
    strSubForumName: { control: "text", blnLanguage: false, validations: { required: true } },
    dtmSubForumData: { control: "date", blnLanguage: false, validations: { required: true } },
    strSubForumLocation: { control: "text", blnLanguage: false, validations: { required: true } },
  });

  //#region upload files
  const uploaderBrochure = Uploader(
    state,
    setState,
    "lstForumBrochureFile",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType.aaiot.id,
      title: labels.label.uploadForumBoucher?.[lang],
    },
    "upload",
    false
  );

  const uploaderBadge = Uploader(
    state,
    setState,
    "lstForumBadgeFile",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType.aaiot.id,
      title: labels.label.uploadForumBadge?.[lang],
    },
    "upload",
    false
  );

  const uploaderCertificate = Uploader(
    state,
    setState,
    "lstForumCertificateFile",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType.aaiot.id,
      title: labels.label.uploadForumCertificate?.[lang],
    },
    "upload",
    false
  );

  const uploaderBadgeSubForum = Uploader(
    state,
    setState,
    "lstSubForumBadgeFile",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType.aaiot.id,
      title: labels.label.uploadForumBadge?.[lang],
    },
    "upload",
    false
  );

  const uploaderCertificateSubForum = Uploader(
    state,
    setState,
    "lstSubForumCertificateFile",
    "",
    {
      blnIsMultiple: false,
      blnForceNewName: true,
      fileType: objUploadType.aaiot.id,
      title: labels.label.uploadForumCertificate?.[lang],
    },
    "edit",
    false
  );
  //#endregion

  const handlers = saveForumModalCtrl({ appState, appDispatch });
  const openSaveForum = handlers.openSaveForum({ fields, setState, state });
  const openSaveSubForum = handlers.openSaveSubForum({ fldSubForum, setState, state });
  const saveSubForumLocally = handlers.saveSubForumLocally({ isLoading, setIsLoading, fldSubForum, setState, state, uploaderBadgeSubForum, uploaderCertificateSubForum });
  const saveForumInToDB = handlers.saveForumInToDB({
    fields,
    setState,
    state,
    isLoading,
    setIsLoading,
    parentState,
    setParentState,
    uploaderBrochure,
    uploaderBadge,
    uploaderCertificate,
  });

  return {
    openEle: (strOperation, objForumInfo, intIndexForum) => (
      <Button size="sm" icon={strOperation === operationForumMode.edit ? "edit" : "add"} onClick={openSaveForum(strOperation, objForumInfo, intIndexForum)} />
    ),
    modal: (
      <>
        <Modal size="xl" open={state.blnOpenSaveModal} eventClose={() => setState({ ...state, blnOpenSaveModal: false })}>
          <ModalHeader>
            <Typography as="subtitle2">
              {state.strOperationForum === operationForumMode.edit ? labels?.label?.editForum?.[lang] : labels?.label?.addForum?.[lang]}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Grid container>
              <Grid item xs={12}>
                <FormField objHandler={fields?.strForumName} icon="title" />
              </Grid>
            </Grid>

            <Grid container my-2>
              <Grid item sx={{ ".uicore_checkbox": { top: "0.3rem !important", marginLeft: "3px !important", marginRight: "3px !important" } }}>
                <FormField objHandler={fields?.blnStrictRegForum} />
              </Grid>
            </Grid>

            <Grid container my-2>
              <Grid item sx={{ ".uicore_checkbox": { top: "0.3rem !important", marginLeft: "3px !important", marginRight: "3px !important" } }}>
                <FormField objHandler={fields?.blnHaveSubForum} />
              </Grid>
            </Grid>

            {!fields?.blnHaveSubForum?.getValue()?.length ? (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <FormField objHandler={fields?.dtmForumDate} icon="eventOutlined" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormField objHandler={fields?.strForumLocation} icon="LocationOn" />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container justifyContent="space-between">
                  <Grid item alignItems="center">
                    <Typography as="subtitle1">{labels?.label?.subForums?.[lang]}</Typography>
                  </Grid>
                  <Grid item alignItems="center">
                    <Button icon={"add"} size="sm" onClick={openSaveSubForum(operationForumMode.add, null, null)} />
                  </Grid>
                </Grid>

                <Paper sx={{ border: `1px solid ${App_Dark_Bluewood}`, borderRadius: "0.3em" }}>
                  {state?.lstSubForum?.length ? null : <NoResultImg />}

                  {state?.lstSubForum.map((subForum, index) => {
                    return (
                      <Grid container justifyContent="space-between" key={subForum?.bigSubForumID}>
                        <Grid item alignItems="center">
                          <Typography as="subtitle2">{subForum?.strSubForumName}</Typography>
                        </Grid>
                        <Grid item alignItems="center">
                          <Button p-0 mx-1 color="warning" size="sm" icon={"edit"} onClick={openSaveSubForum(operationForumMode.edit, subForum, index)} />
                          <Button
                            p-0
                            mx-1
                            size="sm"
                            color="error"
                            icon={"delete"}
                            onClick={() =>
                              Confirmation.viewConfirmation(
                                labels?.confirmation?.deleteSubForm?.[lang],
                                saveSubForumLocally(operationForumMode.delete, index),
                                null,
                                appLangDirection?.[lang]
                              )
                            }
                          />
                        </Grid>

                        {index < state?.lstSubForum?.length - 1 ? <Line sx={{ width: "100%" }} /> : null}
                      </Grid>
                    );
                  })}
                </Paper>
              </>
            )}

            <Grid container my-2>
              <Grid item alignItems="center">
                <Typography as="body3">{labels.label.uploadForumBoucher?.[lang]}</Typography>
              </Grid>
              <Grid item alignItems="center">
                {uploaderBrochure.JSX}
              </Grid>
              {!state.lstForumBrochureFile?.length ? null : (
                <Grid item alignContent="center">
                  {String(state.lstForumBrochureFile?.[0]?.name).toLowerCase().includes(".png") ||
                  String(state.lstForumBrochureFile?.[0]?.name).toLowerCase().includes(".jpeg") ||
                  String(state.lstForumBrochureFile?.[0]?.name).toLowerCase().includes(".jpg") ||
                  String(state.lstForumBrochureFile?.[0]?.name).toLowerCase().includes(".svg") ? (
                    <img
                      src={
                        state.lstForumBrochureFile?.[0]?.blnIsNew
                          ? URL.createObjectURL(state.lstForumBrochureFile?.[0])
                          : App_Server_Url_DownloadFiles + state.lstForumBrochureFile?.[0]?.path
                      }
                      alt=""
                      width="64px"
                    />
                  ) : (
                    <span style={{fontSize:"12px"}}>{state.lstForumBrochureFile?.[0]?.name}</span>
                  )}
                </Grid>
              )}
            </Grid>

            {fields?.blnHaveSubForum?.getValue()?.length ? null : (
              <>
                <Grid container my-2>
                  <Grid item alignItems="center">
                    <Typography as="body3">{labels.label.uploadForumBadge?.[lang]}</Typography>
                  </Grid>
                  <Grid item alignItems="center">
                    {uploaderBadge.JSX}
                  </Grid>
                  {!state.lstForumBadgeFile?.length ? null : (
                    <>
                      <Grid item alignItems="center">
                        <img
                          src={
                            state.lstForumBadgeFile?.[0]?.blnIsNew
                              ? URL.createObjectURL(state.lstForumBadgeFile?.[0])
                              : App_Server_Url_DownloadFiles + state.lstForumBadgeFile?.[0]?.path
                          }
                          alt=""
                          width="64px"
                        />
                      </Grid>

                      <Grid item alignItems="center">
                        {selectNameCoordinateOpenEle(
                          state.lstForumBadgeFile?.[0],
                          (param) => setState({ ...state, objForumBadgeSelectInfo: param }),
                          state.objForumBadgeSelectInfo,
                          true
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid container my-2>
                  <Grid item alignItems="center">
                    <Typography as="body3">{labels.label.uploadForumCertificate?.[lang]}</Typography>
                  </Grid>
                  <Grid item alignItems="center">
                    {uploaderCertificate.JSX}
                  </Grid>
                  {!state.lstForumCertificateFile?.length ? null : (
                    <>
                      <Grid item alignItems="center">
                        <img
                          src={
                            state.lstForumCertificateFile?.[0]?.blnIsNew
                              ? URL.createObjectURL(state.lstForumCertificateFile?.[0])
                              : App_Server_Url_DownloadFiles + state.lstForumCertificateFile?.[0]?.path
                          }
                          alt=""
                          width="64px"
                        />
                      </Grid>
                      <Grid item alignItems="center">
                        {selectNameCoordinateOpenEle(
                          state.lstForumCertificateFile?.[0],
                          (param) => setState({ ...state, objForumCertificateSelectInfo: param }),
                          state.objForumCertificateSelectInfo
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  label={state.strOperationForum === operationForumMode.edit ? dictionary?.shared?.buttons?.editBtn?.[lang] : dictionary?.shared?.buttons?.addBtn?.[lang]}
                  onClick={saveForumInToDB}
                />
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>

        <Modal size="lg" open={state.blnOpenSubForumSaveModal} eventClose={() => setState({ ...state, blnOpenSubForumSaveModal: false })}>
          <ModalHeader>
            <Typography as="subtitle2">
              {state.strOperationSubForum === operationForumMode.edit ? labels?.label?.editSubForum?.[lang] : labels?.label?.addSubForum?.[lang]}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Grid container>
              <Grid item xs={12}>
                <FormField objHandler={fldSubForum?.strSubForumName} icon="bookmarkAddOutlined" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormField objHandler={fldSubForum?.dtmSubForumData} icon="eventOutlined" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormField objHandler={fldSubForum?.strSubForumLocation} icon="LocationOn" />
              </Grid>
            </Grid>

            <Grid container my-2>
              <Grid item alignItems="center">
                <Typography as="body3">{labels.label.uploadForumBadge?.[lang]}</Typography>
              </Grid>
              <Grid item alignItems="center">
                {uploaderBadgeSubForum.JSX}
              </Grid>
              {!state.lstSubForumBadgeFile?.length ? null : (
                <>
                  <Grid item alignItems="center">
                    <img
                      src={
                        state.lstSubForumBadgeFile?.[0]?.blnIsNew
                          ? URL.createObjectURL(state.lstSubForumBadgeFile?.[0])
                          : App_Server_Url_DownloadFiles + state.lstSubForumBadgeFile?.[0]?.path
                      }
                      alt=""
                      width="64px"
                    />
                  </Grid>
                  <Grid item alignItems="center">
                    {selectNameCoordinateOpenEle(
                      state.lstSubForumBadgeFile?.[0],
                      (param) => setState({ ...state, objSubForumBadgeSelectInfo: param }),
                      state.objSubForumBadgeSelectInfo,
                      true
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container my-2>
              <Grid item alignItems="center">
                <Typography as="body3">{labels.label.uploadForumCertificate?.[lang]}</Typography>
              </Grid>
              <Grid item alignItems="center">
                {uploaderCertificateSubForum.JSX}
              </Grid>
              {!state.lstSubForumCertificateFile?.length ? null : (
                <>
                  <Grid item alignItems="center">
                    <img
                      src={
                        state.lstSubForumCertificateFile?.[0]?.blnIsNew
                          ? URL.createObjectURL(state.lstSubForumCertificateFile?.[0])
                          : App_Server_Url_DownloadFiles + state.lstSubForumCertificateFile?.[0]?.path
                      }
                      alt=""
                      width="64px"
                    />
                  </Grid>
                  <Grid item alignItems="center">
                    {selectNameCoordinateOpenEle(
                      state.lstSubForumCertificateFile?.[0],
                      (param) => setState({ ...state, objSubForumCertificateSelectInfo: param }),
                      state.objSubForumCertificateSelectInfo
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  label={
                    state.strOperationSubForum === operationForumMode.edit ? dictionary?.shared?.buttons?.editBtn?.[lang] : dictionary?.shared?.buttons?.addBtn?.[lang]
                  }
                  onClick={saveSubForumLocally()}
                />
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>
      </>
    ),
  };
}
