import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useNavigate } from "@cbmisorg/router";
import { Button, Grid, Typography, Modal, ModalHeader, ModalBody } from "@cbmisorg/material";

import { useApp } from "../../../configuration/contextapi/context";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { dictionary } from "../../../util/appLanguage/dictionary";

import { App_info_Color, App_Light_Color, App_Second_Color } from "../../../util/appStyle";
import { workSectorID } from "../../../configuration/appVariables";

import useCheckCat from "../../../util/hooks/useCheckCat";
import { CtrlRegNewForum } from "./RegNewForum.controller";
import { objStyle } from "./RegNewForum.style";
import WorkSectorOption from "../../stakeholders/member/account/components/regActivityGuest/component/WorkSectorOption.view";
import { modalOverFllow } from "../../../util/appFunctions";

const labels = dictionary?.components?.public?.regActivityGuest;

function RegNewForumView({ isLoading, setIsLoading }) {
  useCheckHooksGlb();
  const navigate = useNavigate();
  // const { ForumCode } = useParams();
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });

  // const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    objActivityInfo: {},
    lstCurentSubject: [],
    subjectSelected: null,
    lstUserRegActivityTmp: [],
    blnRegOpenModal: false,
    blnIsForumReg: false,
  });

  const fields = FormData({
    strFullName: {
      control: "text",
      blnLanguage: false,
      validations: { required: true },
    },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, email: true },
    },
    bigCountryOfResidenceID: { control: "list", validations: { required: true }, blnLanguage: false },

    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName: {
      control: "text",
      blnLanguage: false,
      validations: {},
    },
    lstSubject: {
      control: "radio",
      blnLanguage: false,
      validations: {},
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: { minLength: 4 },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: { minLength: 4 },
    },
  });

  const { getNewCatInfo } = useCheckCat({ appState: appState });
  const handlers = CtrlRegNewForum({ appState, appDispatch, isLoading, setIsLoading });
  const handelWorkSectorChange = handlers.handelWorkSectorChange({ fields, state, setState });
  const openModal = handlers.openModal({ navigate, state, setState, fields, getNewCatInfo  });
  const handleSubmit = () => handlers.addGuest({ state, setState, fields });

  // useEffect(() => {
  //   if (!state?.blnIsInitDone) {
  //     handlers.initData({ });
  //   }
  // }, []);

  return {
    OpenEle: ({ buttonLabel, forumCode  }) =>
      !state?.blnIsForumReg ? (
        <Button label={buttonLabel} mode="outlined" color={App_info_Color} className={classes?.btnBox} onClick={openModal(forumCode)} />
      ) : (
        <Button label={labels?.btnReg?.[lang]} mode="outlined" color={App_Light_Color} className={classes?.btnBox} disabled sx={{span:{color: `${App_Light_Color} !important`}}} />
      ),

    modal: (
      <Modal
        open={state.blnRegOpenModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnRegOpenModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle3" color={App_info_Color} sx={{ textAlign: "center" }}>
            {state?.objActivityInfo?.value?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={3} pt-0>
            <Grid item className={classes?.subtitle}>
              <Typography as="subtitle1" className={classes?.subtitleLabel}>
                {labels?.registrationInfo?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs={12} pt-0>
              <FormField objHandler={fields.strFullName} icon="AccountCircle" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12}>
              <FormField
                objHandler={fields.strUserEmail}
                icon="email"
                mode={"classic"}
                iconColor={App_info_Color}
                color={App_info_Color}
                inputDir="ltr"
                className={classes?.field}
              />
            </Grid>
            <Grid item xs="12">
              <FormField
                objHandler={fields.bigWorkSector}
                icon="Work"
                mode={"classic"}
                iconColor={App_info_Color}
                color={App_Second_Color}
                className={classes?.field}
                onChange={handelWorkSectorChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
            </Grid>
            {!appState?.clientInfo?.blnIsUserLoggedIn && (
              <React.Fragment>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
                </Grid>
              </React.Fragment>
            )}
          </Grid>

          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs="12" pt-5 pb-10>
              <Button py-0 px-10 label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={handleSubmit} className={classes?.btn} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}

export default RegNewForumView;
