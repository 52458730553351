import { Alert, ClientFunction, Confirmation } from "@cbmisorg/client-app";
import * as XLSX from "xlsx";

import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import { aaiot_System_ID } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/component/public/advertisementSystem/view/contact/controler/CtrlContact.js";

const tblSubscription = generateQueries("tblSubscription")

export const CtrlSubscription = (appState, appDispatch, isLoading, setIsLoading, stateInfo, setStateInfo,navigate=null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || aaiot_System_ID

  let handler = {
    getSubscription: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        
      setIsLoading(true);

        const result = await tblSubscription(strPageInfo,appState,"aaiotAppFindAll",
        {
          arrAttributes: objTableAttributes?.tblSubscription?.full,
          objectCondition:{bigSystemID:bigSystemID,blnIsActive:true,blnIsDeleted:false}
        },
        );

        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang],"warning")
          setIsLoading(false);
          return;
        }


        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : [];

        stateInfo.lstSubscription = lstTmp.reverse();

        for (let index = 0; index < lstTmp.length; index++) {
          const element = lstTmp[index];
          stateInfo.lstMailChecked.push(element?.strSubscriptionEmail)
        }
        setIsLoading(false);
        setStateInfo({ ...stateInfo });
        appDispatch()
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false)
      }
    },
    checkAll:(e)=>{
      stateInfo.lstMailChecked = []
      if(e.target.checked){
        stateInfo?.lstSubscription?.forEach(element => {
          stateInfo.lstMailChecked.push(element.strSubscriptionEmail)
        });
      }
      setStateInfo({...stateInfo})
    },
    checkSubscription:(e)=>{
      if(e.target.checked){
      stateInfo.lstMailChecked.push(e.target.id)
      }else{
        stateInfo.lstMailChecked = stateInfo.lstMailChecked?.filter(element => element !== e.target.id);
      }
      setStateInfo({...stateInfo})
    },

    updateSystem:async()=>{
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        
        setIsLoading(false);
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
        setIsLoading(false);
      }
    },

    delateSubscriptionConfirmation:(item)=>{
      const delateSubscription=async() => {
        try {
          setIsLoading(true);
            const result = await tblSubscription(strPageInfo,appState,"aaiotAppDelete",
            {
              objectCondition:{bigSubscriptionID:item?.bigSubscriptionID, bigSystemID:bigSystemID}
            },
            );
  
            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Delete_Record?.[lang],"warning")
              setIsLoading(false);
              return;
            }
            setIsLoading(false)
            stateInfo.lstSubscription = stateInfo.lstSubscription.filter(Subscription=>Subscription.bigSubscriptionID !== item.bigSubscriptionID)
            setStateInfo({ ...stateInfo });
          } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false)
          navigate(objAppRouting?.Contact?.url, { replace: true })

        }
      };

      
      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deleteMail?.[lang],
        delateSubscription,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },

    exportExcelFile:() => {
        var workbook = XLSX.utils.book_new();
        var worksheet_data = [];

        stateInfo?.lstMailChecked?.forEach((child) => {
          worksheet_data?.push([
            child
          ]);
        });

        var worksheet = XLSX.utils.aoa_to_sheet(worksheet_data);
        worksheet["!autofilter"] = { ref: "F1" };
        workbook.SheetNames.push("subscription");
        workbook.Sheets["subscription"] = worksheet;
        XLSX.writeFile(workbook, `subscription_${ClientFunction?.getDateUSFormat(ClientFunction?.getCurrentDate())}.xlsx`);
    },
  };

  return handler;
};
