
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as ClientFunction from "../system/cbmisFunction"

/**
 *
 * @param {any} appState
 * @param {number} intHourToCheckCheck
 * @param {() => Promise<{blnIsRequestSuccessful:boolean, response:any}>} funFetchFunction
 * @param {string} strPathPublicPage
 * @param {string} strLocalStorageName
 * @param {boolean} blnIsTestingMode (default = true)
 * @param {{blnIsAppStateEncrypt:boolean, strSecretKey:string}} objEncryptOption (default { blnIsAppStateEncrypt: false, strSecretKey: "" })
 * @returns {null}
 */
export default function useCheckUserLoginKey(appState, intHourToCheckCheck, funFetchFunction, strPathPublicPage, strLocalStorageName, blnIsTestingMode = true, objEncryptOption = { blnIsAppStateEncrypt: false, strSecretKey: "" }) {
  const navigate = useNavigate();

  useEffect(() => {
    checkValidity();
  }, []);

  const checkValidity = () => {
    try {
      appState.clientInfo = { ...appState.clientInfo };
      if (!appState.clientInfo?.dtmLastCheckLoginKey) {
        appState.clientInfo.dtmLastCheckLoginKey = "";
      }

      let intLastChecked = ClientFunction.getDifferenceBetweenTwoDate(appState.clientInfo.dtmLastCheckLoginKey, new Date(), "hour");

      if (Number.isNaN(Number(intLastChecked))) {
        if (blnIsTestingMode) {
          console.log("useCheckUserLoginKey need to check your keys");
        }
        checkIsUserLoginKeyActive();
      } else if (Number(intLastChecked) >= Number(intHourToCheckCheck)) {
        if (blnIsTestingMode) {
          console.log("useCheckUserLoginKey need to check your keys");
        }
        checkIsUserLoginKeyActive();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkIsUserLoginKeyActive = async () => {
    try {
      let responseUser = await funFetchFunction();
      if (!responseUser || !responseUser.blnIsRequestSuccessful) {
        return;
      }

      let arrLoginKeys = ClientFunction.checkParseObject(responseUser.response.txtLoginKeys);
      arrLoginKeys = Array.isArray(arrLoginKeys) ? arrLoginKeys : [];

      let bigUserCurrentKeyLogin = appState.userInfo.txtLoginKeys;

      if (!arrLoginKeys.includes(bigUserCurrentKeyLogin)) {
        if (blnIsTestingMode) {
          console.log("Login key Invalid");
        }
        if (objEncryptOption && objEncryptOption?.blnIsAppStateEncrypt) {
          ClientFunction.clearLocalStorageObject(appState, strLocalStorageName, objEncryptOption?.blnIsAppStateEncrypt, objEncryptOption?.strSecretKey);
        } else {
          ClientFunction.clearLocalStorageObject(appState, strLocalStorageName);
        }
        navigate(strPathPublicPage);
      } else {
        if (blnIsTestingMode) {
          console.log("Login key valid");
        }
        appState.clientInfo.dtmLastCheckLoginKey = new Date();
        if (objEncryptOption && objEncryptOption?.blnIsAppStateEncrypt) {
          ClientFunction.setAppLocalStorage(appState, strLocalStorageName, objEncryptOption?.blnIsAppStateEncrypt, objEncryptOption?.strSecretKey);
        } else {
          ClientFunction.setAppLocalStorage(appState, strLocalStorageName);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return null;
}


