/** 
* @param {string} dir
*/
export const App_Direction_LeftToRight = "ltr";
export const App_Direction_RightToLeft = "rtl";

export const setDirection = (dir)=>{
    document.body.setAttribute('dir', 'ltr')
    document.body.style.direction= "ltr";
    if(String(dir).toLowerCase() === "rtl"){
        // document.body.classList.add(`cbmis-body-dir-rtl`);
        document.body.setAttribute('dir', 'rtl')
        document.body.style.direction= "rtl";
    }
}

export function setDirectionByLanguage(strLanguage) {
    const lang = String(strLanguage).toLowerCase();
      if (lang === "ar" || lang === "arb" || lang === "arabic") 
        setDirection(App_Direction_RightToLeft);
      else
        setDirection(App_Direction_LeftToRight);
  }