import React, { useEffect, useState } from 'react'
import CamFeatureBtn from '../../cbmisCamera/cam-feature-btn/CamFeatureBtn'
import Modal from '../../../material/dataDisplay/Modal/Modal'
import ModalBody from '../../../material/dataDisplay/Modal/ModalBody'
import ModalHeader from '../../../material/dataDisplay/Modal/ModalHeader'
import Typography from '../../../material/dataDisplay/Typography/Typography'
import Grid from '../../../material/layout/Grid/Grid'
import Icon from "../../../icon/CbmisIcon"

import "./modal.css"

function PreviewImage(props) {
    const {images,setImages,imgInfo,handelCloseModal,handleDeleteFile,blnChangeName}=props
    const {open,index}= imgInfo
    const [showIndex,setShowIndex] = useState(index || 0)

    function renameFile(originalFile, newName) {
         const newFiles = new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
        return newFiles

    }

    const changeImageName=(event)=>{
        const index = event.target.id
        const newName = event.target.value
        var newArrayImages = images
        newArrayImages[index]= renameFile(newArrayImages[index],newName) || newArrayImages[index]
        setImages([...newArrayImages])
    }

    const handelClose=()=>{
        if(handelCloseModal){
            handelCloseModal()
        }
    }

    useEffect(() => {
        setShowIndex(index || 0)
    }, [index])
    
  return (
    <React.Fragment>
        <Modal open={open} eventClose={handelClose} className="cbmis-modal-preview-Image">
            <ModalHeader>
                <Typography as="subtitle1"> {images[showIndex]?.name}</Typography>
            </ModalHeader>
            <ModalBody>
            {showIndex<images.length-1 && images.length>1?
                <CamFeatureBtn
                    top={"50%"}
                    right={15}
                    icon={"arrowForwardIos"}
                    func={()=>setShowIndex(showIndex+1)}
                    color="#333"
                />
                :null}

                {showIndex>0 && images.length>1?
                <CamFeatureBtn
                    top={"50%"}
                    left={15}
                    icon={"arrowBackIos"}
                    func={()=>setShowIndex(showIndex-1)}
                    color="#333"
                />
                :null}
                <Grid container justify="center">
                    <Grid item xs="12">
                        <img src={images[showIndex]?URL.createObjectURL(images[showIndex]):""} alt="/" className="image-preview-modal-img"/>
                    </Grid>
                </Grid>

                <Grid container spacing="0" px-1>
                    <Grid item xs="12">
                        {
                            blnChangeName?(
                                <input type="text"
                                    className='input-image-name'
                                    name={`imageName-${showIndex}`}
                                    defaultValue={images[showIndex]?.name}
                                    id={showIndex}
                                    onBlur={changeImageName}
                                    />
                            ):(
                                <Typography as="subtitle1" className='input-image-name-label'>{images[showIndex]?.name}</Typography>
                            )
                        }

                     </Grid>
                </Grid>

                <Grid container justify="space-between" spacing="0" px-2 >
                    <Grid item>
                    <Grid item>
                        <Typography>
                            size: {images[showIndex]?.size}
                        </Typography>
                    </Grid>
                    </Grid>
                    <Grid item>
                        <button className="list-preview-btn" onClick={handleDeleteFile(index)}>
                            <Icon icon="delete" />
                        </button>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    </React.Fragment>
  )
}

export default PreviewImage