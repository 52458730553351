import { Button, Grid } from "@cbmisorg/material";
import React from "react";
import { FormField } from "../../../../../util/formApi/FormAPI";
import { App_info_Color } from "../../../../../util/appStyle";
import { dictionary } from "../../../../../util/appLanguage/dictionary";


function RestPassword({ state, setState, fields, handlers, classes, lang, isLoading, setIsLoading }) {
  const handelSubmit = handlers?.funRestPassword({ isLoading, setIsLoading, state, setState, fields });

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"center"} py-10>
        <Grid item xs={12}>
          <FormField objHandler={fields.strOldUserPassword} icon="vpnKey" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={12} md="6">
          <FormField objHandler={fields.strRestPassword} icon="vpnKey" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
        <Grid item xs={12} md="6">
          <FormField objHandler={fields.strConfirmPassword} icon="vpnKey" mode={"classic"}  color={App_info_Color} className={classes?.field} />
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Button py-0 px-10 label={dictionary?.shared?.buttons?.save?.[lang]} onClick={handelSubmit} mode="outlined" color={App_info_Color} className={classes?.btnBox} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RestPassword;
