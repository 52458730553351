import React, { useEffect, useRef } from "react";
import "./Menu.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Menu = (props) => {
  const { open, id, sx, hidden, disabled, dir, className, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Menu"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [open, id, sx, hidden, disabled, dir, className]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-menu");
    }
  }, [sx, screenWidth]);

  return (
    <React.Fragment>
      {open ? (
        <div className={`cbmis-menu-contener`} {...getRestProps()}>
          <ul
            className={`cbmis-menu ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
              hidden
            )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""} ${styleRef.current.strSxClassName}`}
            id={getID.current}
          >
            {props.children}
          </ul>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Menu;
