import React, { useEffect, useRef } from "react";
import "./Loader.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function Loader(props) {
  const { mode, color, backdrop, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-loader") });

  useEffect(() => {}, [mode, backdrop, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (color) {
      document.documentElement.style.setProperty("--cbmis-loader-color", componentsFunction.checkColorReturnValue(color || "primary"));
    }
  }, [color]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-loader");
    }
  }, [sx, screenWidth]);

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getMode = () => {
    switch (String(mode).toLowerCase()) {
      case "noborder":
        return <div className="cbmis-no-border-spinner cbmis-spinner"></div>;
      case "bordertop":
        return <div className="cbmis-border-top-spinner cbmis-spinner"></div>;
      case "doubleborder":
        return <div className="cbmis-double-border-spinner cbmis-spinner"></div>;
      default:
        return <div className="cbmis-no-border-spinner cbmis-spinner"></div>;
    }
  };

  return backdrop ? (
    <div className="cbmis-container-loader" {...getRestProps()}>
      <div
        id={styleRef.current.strSXComponentID}
        className={`cbmis-loader ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.CheckDirectionItem(
          dir
        )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      >
        {getMode()}
      </div>
    </div>
  ) : (
    <div
      id={styleRef.current.strSXComponentID}
      className={`cbmis-loader ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.CheckDirectionItem(
        dir
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {getMode()}
    </div>
  );
}

export default Loader;

// mode :
// noBorder
// BorderTop
// DOUBLE BORDER
// DOUBLE
// REVERSE
// TRIPLE
// CM
// MUTLI
// 3DOT
// FULL CIRCLE
