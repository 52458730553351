import React, { useEffect, useRef } from "react";
import "./GroupBar.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function GroupBar(props) {
  const { sx, hidden, disabled, dir, className, id, logoTextDir, color, pathPages, sxMode, ssMode, position, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const pathName = window.location.pathname;
  var viewport_width = window.innerWidth;

  const getID = useRef(id || componentsFunction.randomName("GroupBar"));
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [sx, hidden, disabled, dir, className, id, logoTextDir, pathPages]);

  useEffect(() => {
    if (styleRef.current.strSxClassName)
      if (color) {
        componentsFunction.getbgColorReturnClassName("." + styleRef.current.strSxClassName, color);
      }
  }, [styleRef.current.strSxClassName]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-groupBar");
    }
  }, [sx, screenWidth]);

  const getLogoTextDirection = () => {
    switch (String(logoTextDir).toLowerCase()) {
      case "end":
        return "cbmis-logo-Text-direction-end";
      case "start":
        return "cbmis-logo-Text-direction-start";
      case "bottom":
        return "cbmis-logo-Text-direction-bottom";
      case "top":
        return "cbmis-logo-Text-direction-top";
      default:
        return "cbmis-logo-Text-direction-end";
    }
  };

  const getPosition = () => {
    switch (String(position).toLowerCase()) {
      case "end":
        return "cbmis-position-groupbar-appbar-end";
      case "start":
        return "cbmis-position-groupbar-appbar-start";
      default:
        return "cbmis-Position-default";
    }
  };

  const getViewToolBar = () => {
    if (pathPages && pathPages.length) {
      const path = pathPages.filter((item) => item === pathName);
      if (path[0]) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return getViewToolBar() ? (
    <div
      className={`cbmis-groupBar ${getLogoTextDirection()}  ${getPosition()}
      ${
        (String(sxMode).toLowerCase() === "sidebar" && viewport_width > 800) || (String(ssMode).toLowerCase() === "sidebar" && viewport_width <= 800)
          ? "cbmis-appbar-toolbar-menu"
          : ""
      } 
      ${componentsFunction.CheckDisabledItem(disabled)} 
      ${componentsFunction.CheckHiddenItem(hidden)} 
      ${componentsFunction.checkExistsMargin(rest)} 
      ${componentsFunction.checkExistsPadding(rest)}
       ${componentsFunction.CheckDirectionItem(dir)} 
       ${className || ""}`}
      id={getID.current}
      key={getID.current}
    >
      {props.children}
    </div>
  ) : (
    ""
  );
}

export default GroupBar;
