import { Alert } from "@cbmisorg/material";
import { logError } from "../../../util/appFunctions";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { alertStyle } from "../../../util/appStyle";
import { generateQueries, objTableAttributes } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
const activityGuestApi = generateQueries("tblActivityGuest");
const forumApi = generateQueries("tblForum");

const strPageInfo = "@src/client/component/public/regForum/regForum.controller.js";

export default function regForumCtrl({ appState, appDispatch }) {
  const lang = appState?.clientInfo?.strLanguage || "arb";

  const handler = {
    openWindow: (path) => () => {
      if (typeof window !== "undefined" && path) {
        window.open(path, "_blank");
      }
    },

    initData:
      ({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, activity, subject, fields }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);

          if (!appState?.clientInfo?.objSystemInfo?.jsnSystemConfig || JSON?.stringify(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig)?.length < 500) {
            await getNewSystemInfo();
          }

          if (!appState?.clientInfo?.objCatInfo || String(appState?.clientInfo?.objCatInfo)?.length < 500) {
            await getNewCatInfo();
          }

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region
          const result = await forumApi(strPageInfo, appState, "aaiotAppFindOne", {
            arrAttributes: objTableAttributes.tblForum.full,
            objectCondition: {
              strForumPath: activity,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          //#endregion
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          const lstActivitySubject = Array.isArray(result?.response?.jsnForumInfo?.lstSubForum) ? result?.response?.jsnForumInfo?.lstSubForum : [];
          const objTargetSubject = lstActivitySubject.filter(
            (objSubject) => String(objSubject?.strSubForumPath).toLowerCase().trim() === String(subject).toLowerCase().trim()
          )?.[0];

          if (!result?.response?.bigForumID) {
            navigate(appRouting.page404?.url);
            return;
          }

          // if (appFunctions?.getDifferenceBetweenTwoDate(new Date(),  result?.response?.jsnForumInfo?.dtmForumExpDateTime, "minute") < 0){
          //   Alert.viewAlert(dictionary?.shared?.alertFetchNote?.alertEndWeek?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          //   setIsLoading(false);
          //   navigate( result?.response?.jsnForumInfo?.strForumExpURL ||  result?.response?.jsnForumInfo?.dtmForustrForumExpURLmDate, {state: {bigActivityType: state?.bigActivityType, strSubjectKey: state?.strSubjectKey, objForumInfo: null}});
            
          //   return;
          // }
          
          state.objForumInfo = result?.response;

          state.objSubForumInfo = null;

          state.lstBrochureFile = Array.isArray(state?.objForumInfo?.jsnForumInfo?.lstForumBrochureFile) ? state?.objForumInfo?.jsnForumInfo?.lstForumBrochureFile : [];
          if (state.objForumInfo?.jsnForumInfo?.blnHaveSubForum) {
            let lstSubject = Array.isArray(state.objForumInfo?.jsnForumInfo?.lstSubForum) ? state.objForumInfo?.jsnForumInfo?.lstSubForum : [];
            let objSubject = lstSubject.filter((objSubject) => String(objSubject?.strSubForumPath).toLowerCase().trim() === String(subject).toLowerCase().trim())?.[0];
            if (objSubject) {
              state.objSubForumInfo = objSubject;
            }
          }

          state.strActivityKey = result?.response?.strForumPath;
          state.strSubjectKey = objTargetSubject?.strSubForumPath;
          state.bigActivityID = result?.response?.bigForumID;
          state.bigSubjectID = objTargetSubject?.bigSubForumID;

          if (appState?.clientInfo?.blnIsUserLoggedIn) {
            fields.strFullName.setValue(appState?.userInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName?.[lang]);
            fields.strUserEmail.setValue(appState?.userInfo?.strUserEmail);
            await handler.saveActivityGuestInToDB({ fields, isLoading, navigate, setIsLoading, setState, state, blnIsHandler: false })();
          }

          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          logError(strPageInfo, "initData-ERROR", error);
        }
      },
    saveActivityGuestInToDB:
      ({ isLoading, setIsLoading, setState, state, navigate, fields, blnIsHandler = true }) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }
          if (isLoading && blnIsHandler) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.invalid_Verification_Code?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }
          setIsLoading(true);

          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          const result = await activityGuestApi(strPageInfo, appState, "aaiotActivityGuestAdd", {
            objectInput: {
              //#region tblUser Information
              bigAppID: appVariables.App_ID,
              bigSystemID: appVariables.aaiot_System_ID,
              bigUserID: appState?.userInfo?.bigUserID || appFunctions.generateID(10),
              strUserEmail: appState?.userInfo?.strUserEmail || fields?.strUserEmail?.getValue(),
              strUserPassword: fields?.strUserPassword?.getValue(),

              strFullName:
                appState?.userInfo?.strFullName?.[lang] ||
                appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] ||
                { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() }?.[lang],

              intUserRoleFK: appVariables?.objUserRole?.Member,

              jsnUserInfo: {
                strFullName: appState?.userInfo?.strFullName ||
                  appState?.userInfo?.jsnUserInfo?.strFullName || { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                intUserRoleFK: appVariables?.objUserRole?.Member,
                bigCountryOfCitizenID: objCat?.Root?.country?.kuwait?.id,
                bigCountryOfResidenceID: objCat?.Root?.country?.kuwait?.id,
              },

              strTrainingTypeCode: Object.entries(appVariables?.countryCodeObj || {}).filter(([key, value]) => value.toLowerCase() === "kuwait")?.[0]?.[0],
              blnIsUserLoggedIn: true, //appState?.clientInfo?.blnIsUserLoggedIn,
              blnIsActivityUser: true,
              blnAddSubjectOnly: true,
              //#endregion
              ///////////////////////////////////////////////////
              //#region tblActivityGuest information
              bigActivityType: state?.bigActivityID,
              blnNeedToConfirmed: state?.objForumInfo?.blnStrictRegForum,
              jsnGuestInfo: {
                objSubject: state?.bigSubjectID ? [state?.bigSubjectID] : [],
                objTargted: appVariables.workSectorCat?.manager,
                dtmRegister: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
              },
              //#endregion
              ///////////////////////////////////////////////////
              //#region Statistic ??
              lstStatisticFelidIfUserNew: ["intCountJoinUser", "intCountJoinUserAll"],
              lstStatisticFelidIfGuestActivityNew: ["intCountActivityGuest", "intCountActivityGuestAll"],
              lstForumFelidIfGuestNew: state?.objForumInfo?.blnStrictRegForum ? ["intNumRegUser", "intNumNeedToConfirmUser"] : ["intNumRegUser"],
              //#endregion
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }

          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
          appState.userInfo = result.response?.userInfo;
          appDispatch();
          fields?.controller?.resetForm();
          navigate(appRouting?.ForumBadge?.url, {
            replace: true,
            state: {
              bigActivityType: state?.bigActivityID,
              bigSubject: state?.bigSubjectID,
              strSubjectKey: state?.strSubjectKey,
              strActivityKey: state?.strActivityKey,
            },
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          logError(strPageInfo, "saveActivityGuestInToDB-ERROR", error);
        }
      },




      //#region newForurm
      
  };

  return handler;
}
