import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./RangeSlider.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";


const RangeSlider = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,
          color,gauge="%",
          value,defaultValue,onChange,
          readOnly,max=100,min=0,step,showMaxMin,showValue=true,
          sx,hidden,disabled,dir,className,...rest} = props

    const inputRef = useRef({})
    const [inputValue, setInputValue] = useState({
      value: "",
    });

    const screenWidth = useCheckScreenSize();
    const nameID = useRef(id || componentsFunction.randomName("RangeSlider"));
    const getID = useRef(componentsFunction.randomName("RangeSlider"));
    const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-range-slider")});

  //#region Effect
    useEffect(()=>{
        inputRef.current.value = value || defaultValue || 0
        if(ref){
          ref.current.value = value || defaultValue || 0
        }
        setInputValue({
          ...inputValue,
          value:  ref?.current?.value || inputRef.current.value || 0,
        })

    },[value, defaultValue])

    useEffect(()=>{
    },[id,label,placeholder,errorMessage,mode,
        color,
        value,defaultValue,
        readOnly,max,min,step,showMaxMin,
        sx,hidden,disabled,dir,className,rest,
        ])

//#endregion

  //#region style
    useEffect(() => {
      if (sx) {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-input-container");
      }
    }, [sx, screenWidth]);

    useEffect(() => {
      var theValue = (Number(inputValue.value) - Number(min)) / (Number(max) - Number(min)) * 100;
      let background = `linear-gradient(to right, ${componentsFunction.checkColorReturnValue(
        color || "primary"
      )} 0%, ${componentsFunction.checkColorReturnValue(
        color || "primary"
      )} ${theValue}%, var(--cbmis-range-slider-background-active) ${theValue}%, var(--cbmis-range-slider-background-active) 100%)`;

      if (dir === "rtl") {
        background = `linear-gradient(to left, ${componentsFunction.checkColorReturnValue(
          color || "primary"
        )} 0%, ${componentsFunction.checkColorReturnValue(
          color || "primary"
        )} ${theValue}%, var(--cbmis-range-slider-background-active) ${theValue}%, var(--cbmis-range-slider-background-active) 100%)`;
      }
      setSxClassName(styleRef, styleRef.current.strSXComponentID, {background: `${background}`, color: `${componentsFunction.checkColorReturnValue(color || "primary")}`});
      const rangeInput = document.querySelector(`#${nameID.current}`);
      rangeInput.style.background = background;
      rangeInput.style.color= componentsFunction.checkColorReturnValue(color || "primary");

  }, [inputValue,color]);

    const getRestProps = () => {
      const newRest = componentsFunction.filterPaddingAndMargin(rest);
      return { ...newRest };
    };
  //#endregion

  //#region change
    const handelChange =(event)=>{
      if(onChange){
        onChange(event)
      }else{
        setInputValue({...inputValue,value:  event.target.value});
      }
    }
  //#endregion

  return (
      <React.Suspense fallback="">
        <div id={getID.current}
              className={`cbmis-main-container
                          ${componentsFunction.CheckHiddenItem(hidden) || ""}`}

              {...getRestProps()}
          >
          <div className={componentsFunction.CheckDirectionItem(dir)}>
            <div className={`cbmis-input-container 
                            ${componentsFunction.CheckDisabledItem(disabled)} 
                            ${errorMessage?"cbmis-input-have-error":""}
                          `}
                           >
              <dev className="input-range-Container">
                  <label className="cbmis-range-input-label" htmlFor={`${nameID.current}`} >{label?.default || label || ""}</label>
                <input
                  className={`cbmis-range-slider ${readOnly ? "cbmis-range-slider-thumb-none" : ""} ${styleRef?.current?.strSxClassName} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""} `}
                  type="range"
                  name={nameID.current}
                  id={nameID.current}
                  min={min ? min : 0}
                  max={max ? max : 100}
                  step={Number(step) || 0}
                  defaultValue={defaultValue || inputValue}
                  onChange={handelChange}
                  ref={ref || inputRef}
                  readOnly={readOnly}
                />
                {
                  !showValue?null:(
                    <output htmlFor={nameID.current} className={`cbmis-range-slider-value-output ${Number(inputRef.current.value) === Number(max)?"cbmis-range-slider-value-output-light":""} ${!label?.default && ! label? "outputRangeNoHaveLabel":""}`}>
                      {ref?.current?.value || inputRef?.current?.value}{gauge}
                    </output>
                  )
                }
              </dev>

              {showMaxMin ? (
                <div className="cbmis-range-slider-min-max" style={!showValue?{top:"35px"}:{}}>
                  {min ? <span className="cbmis-range-slider-min-max-label">{min}</span> : ""}
                  {max ? <span className="cbmis-range-slider-min-max-label">{max}</span> : ""}
                </div>
              ) : (
                ""
              )}
            </div>
            <ErrorMessage errorMessage={errorMessage} />
          </div>
        </div>
      </React.Suspense>
    )
});

export default RangeSlider