import React, { useEffect, useRef } from "react";
import "./SpeedDial.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const SpeedDialAction = (props) => {
  const { sx, hidden, disabled, dir, className, onClick, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("SpeedDialAction"));
  const styleRef = useRef({ strSxClassName: "" });

  const handleClicked = () => {
    if (onClick) {
      onClick();
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [hidden, disabled, dir, className, onClick, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-speed-dial-action");
    }
  }, [sx, screenWidth]);

  return (
    <div
      id={getID.current}
      className={`cbmis-speed-dial-action ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
      onClick={handleClicked}
    >
      {props.children}
    </div>
  );
};

export default SpeedDialAction;
