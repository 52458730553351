import { Button, Grid, Modal, ModalBody, Typography } from "@cbmisorg/material";
import React, { useState } from "react";
import QRCodeGenerator from "../../../../../shared/qrCodeGenerator/QRCodeGenerator";
import appRouting from "../../../../../../configuration/routing/appRouting";
import { generatePath } from "react-router-dom";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { useApp } from "../../../../../../configuration/contextapi/context";

export default function GenerateQRCodeView({ strForumPath, strForumName, strSubForumName = null, strForumLocation = null, dtmForumData = null, strSubForumPath = null }) {
  const { appState, appDispatch } = useApp();

  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.stakeholders?.admin?.mngRegForum;

  const [state, setState] = useState({ blnOpenModal: false });

  const downloadQRCode = () => {
    const qrImg = document.getElementById("qrCodeGeneratorForumLink");
    let a = document.createElement("a");
    const canvas = document.createElement("canvas");
    canvas.width = 1240;
    canvas.height = 1754;

    const ctx = canvas.getContext("2d");
    ctx.textAlign = "center";

    ctx.fillStyle = "#2d77b1";

    ctx.font = "bold 64px serif";
    ctx.fillText(strForumName, canvas.width / 2, 120);

    let offset1 = 120;
    if (strSubForumName) {
      ctx.font = "bold 42px serif";
      ctx.fillText(strSubForumName, canvas.width / 2, 240);
      offset1 += 120;
    }

    ctx.font = "32px serif";
    ctx.fillText(labels?.label?.forumLocation?.[lang], canvas.width / 2, offset1 + 200);
    ctx.font = "bold 64px serif";
    ctx.fillText(strForumLocation, canvas.width / 2, offset1 + 200 + 100);

    offset1 += 300;

    ctx.font = "32px serif";
    ctx.fillText(labels?.label?.forumDate?.[lang], canvas.width / 2, offset1 + 200);
    ctx.font = "bold 64px serif";
    ctx.fillText(dtmForumData, canvas.width / 2, offset1 + 200 + 100);

    offset1 += 300;

    ctx.font = "32px serif";
    ctx.fillText(labels?.label?.forumLink?.[lang], canvas.width / 2, offset1 + 200);
    ctx.drawImage(qrImg, canvas.width / 2 - qrImg?.width / 2, offset1 + 200 + 80);

    const imgUrl = canvas.toDataURL();
    a.href = imgUrl;
    a.download = (strSubForumName || strForumName) + ".png";
    a.click();
  };

  return (
    <>
      <Button size="sm" p-0 icon={"link"} mode="outlined" onClick={() => setState({ ...state, blnOpenModal: true })} />
      <Modal open={state.blnOpenModal} eventClose={() => setState({ ...state, blnOpenModal: false })}>
        <ModalBody>
          <Grid container justifyContent="center">
            <Grid item xs={12} justifyContent="center">
              <Typography as="body3" sx={{wordBreak:"break-word"}}>
                {window?.location?.origin +
                  generatePath(appRouting?.RegForum?.url, {
                    activity: strForumPath,
                    subject: strSubForumPath || "0",
                  })}
              </Typography>
            </Grid>
            <Grid item>
              {QRCodeGenerator({
                id: "qrCodeGeneratorForumLink",
                strText:
                  window?.location?.origin +
                  generatePath(appRouting?.RegForum?.url, {
                    activity: strForumPath,
                    subject: strSubForumPath || "0",
                  }),
              })}
            </Grid>

            <Grid item xs={12} justifyContent="center">
              <Button size="sm" icon={"download"} onClick={downloadQRCode} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
}
