// @ts-nocheck
import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { App_Server_Url_DownloadFiles, objSponserCat, objSupportTransStatus, objUploadType, workSectorID } from "../../../../../../configuration/appVariables";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { Avatar, Button, Grid, Modal, ModalBody, ModalHeader, Paper, Typography, TextArea, Table, TableHead, TableRow, TableCell, TableBody } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import sponsorSetterModalCtrl from "./sponsorSetterModal.controller";
import * as appStyle from "../../../../../../util/appStyle";
import WorkSectorOption from "../regActivityGuest/component/WorkSectorOption.view";
import { Icon } from "@cbmisorg/icons";
import { getSponsorTypeAccordingToTraineeNum, getDateUSFormat, modalOverFllow } from "../../../../../../util/appFunctions";

const labels = dictionary?.components?.stakeholders?.member?.account?.sponsorSetter;
export default function SponsorSetterModalView({ parentState, setParentState, isLoading, setIsLoading, classes }) {
  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage;

  const [state, setState] = useState({
    blnIsInitDone: false,
    blnOpenSetterModal: false,
    blnOpenTranstionModal: false,
    blnOpenImgeModal: false,
    lstLogo: [],
    lstReceipt: [],
    blnIsRecordExist: false,
    selectedTrans: null,
    selectedLstImg: null,
    selectedImgType: null,
    clipboardText: false,
    step: 1,
    blnWatsappClicked: false,
    blnHoverd: false,
  });

  const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const objPartnerCount = parentState?.objPartnerInfo?.jsnSupportTrans?.[parentState?.objPartnerInfo?.jsnSupportTrans.length - 1]?.strSupportCatType ? parentState?.objPartnerInfo?.jsnSupportTrans?.[parentState?.objPartnerInfo?.jsnSupportTrans.length - 1]?.strSupportCatType :getSponsorTypeAccordingToTraineeNum(
    parentState?.objPartnerInfo?.jsnSupportTrans?.[parentState?.objPartnerInfo?.jsnSupportTrans.length - 1].intTraineesCount || 
    parentState?.objPartnerInfo?.jsnSupportTrans?.[parentState?.objPartnerInfo?.jsnSupportTrans.length - 1]?.intTrainingProgPrice,
    parentState?.objPartnerInfo?.jsnSupportTrans?.[parentState?.objPartnerInfo?.jsnSupportTrans?.length - 1]?.strTrainingType
  );

  const uploaderLogo = Uploader(state, setState, "lstLogo", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    title: dictionary?.components?.uploader?.logoNote?.[lang],
    file: dictionary?.components?.uploader?.logo?.[lang],
  });
  const uploaderReceipt = Uploader(state, setState, "lstReceipt", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
  });

  const fields = FormData({
    strSponserName: { control: "text", blnLanguage: false, validations: { required: true } },

    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true, minLength: 5, maxLength: 10 },
    },
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strPlaceName2: {
      control: "text",
      blnLanguage: false,
      // validations: { required: true },
    },
    strURL: { control: "text", blnLanguage: false },
    strToSupport: { control: "list", blnLanguage: false, validations: { required: true } },
    strSponsorTrainingType: { control: "list", blnLanguage: false, validations: { required: true } },
    blnAddNote: { control: "switch", blnLanguage: false },
    strNote: { control: "textArea", blnLanguage: false },
  });
  const handlers = sponsorSetterModalCtrl({ appDispatch, appState, isLoading, parentState, setIsLoading, setParentState });
  const openModal = handlers.openModal({ setState, state, fields });
  const initData = handlers.initData({ fields, setState, state });
  const updatePartnerInfo = handlers.updatePartnerInfo({ fields, setState, state, uploaderLogo, uploaderReceipt });
  const dilogeImgModal = handlers?.dilogeImgModal({ state, setState });
  const handleChangeToSupport = handlers?.handleChangeToSupport({ fields });
  const handleCopy = handlers?.handleCopy({ state, setState });
  const objCat = handlers?.objCat;

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }

    return () => {
      modalOverFllow();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    openEle:
      appState?.userInfo?.jsnUserInfo?.blnCloseSponsor || (!objSystemConfig?.blnIsPaid && !objSystemConfig?.blnIsContact) ? null : (
        <Paper className={classes?.bgBox}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography as="subtitle2" className={classes?.mainTitle}>
                {labels?.title?.[lang]}
              </Typography>
            </Grid>
            {parentState?.objPartnerInfo?.jsnSupportTrans?.length ? (
              <React.Fragment>
                <Grid item xs="12" container justifyContent="space-between">
                  <Grid item>
                    {!objPartnerCount ? null : (
                      <Typography as="body2" className={classes?.partnerCat}>
                        <Grid container py-0 spacing={0} alignItems={"center"} sx={{ flexWrap: "nowrap", minWidth: "max-content !important" }}>
                          <Grid item alignItems={"center"}>
                            <Icon icon={objPartnerCount !== objSponserCat?.main?.key ? "star" : "AutoAwesome"} />
                          </Grid>
                          <Grid item alignItems={"center"}>
                            <Typography as="body3"> {dictionary?.shared?.app?.sponserLabelCat[objPartnerCount]?.[lang]}</Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    )}
                  </Grid>

                  <Grid item>
                    <Icon
                      icon="RequestQuoteOutlined"
                      color={appStyle?.App_info_Color}
                      size="24px"
                      className={classes?.icon}
                      onClick={() => {
                        modalOverFllow(true);
                        setState({ ...state, blnOpenTranstionModal: true });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs="12" md="12" className={classes?.descriptionLightTextContainer} key="sponserContainer">
                  <Typography
                    as="body2"
                    className={`${classes?.descriptionLightText} ${state?.blnHoverd ? classes?.descriptionLightTextActive : ""}`}
                    key="sponser"
                    onMouseEnter={() => {
                      setState({ ...state, blnHoverd: true });
                    }}
                    onMouseLeave={() => {
                      setState({ ...state, blnHoverd: false });
                    }}
                  >
                    {labels?.description?.[lang]}
                  </Typography>
                  <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`} key="arrowClose_sponser">
                    <Grid item xs="auto" md="0" key="sponser_arrow_item">
                      <Icon icon="KeyboardArrowUp" size="28px" color={appStyle.App_info_Color} key="sponser_arrow" />
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs="12" md="12" className={classes?.descriptionLightTextContainer}>
                <Typography
                  as="body2"
                  className={`${classes?.descriptionLightText} ${state?.blnHoverd ? classes?.descriptionLightTextActive : ""}`}
                  key="sponser"
                  onMouseEnter={() => {
                    setState({ ...state, blnHoverd: true });
                  }}
                  onMouseLeave={() => {
                    setState({ ...state, blnHoverd: false });
                  }}
                >
                  {labels?.description?.[lang]}
                </Typography>
                <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`} key="arrowClose_sponser">
                  <Grid item xs="auto" md="0" key="sponser_arrow_item">
                    <Icon icon="KeyboardArrowUp" size="28px" color={appStyle.App_info_Color} key="sponser_arrow" />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {appState?.userInfo?.jsnUserInfo?.blnIsSponsor ? null : (
              <Grid item>
                <Button label={labels?.btn?.[lang]} mode="outlined" color={appStyle.App_info_Color} onClick={openModal} className={classes?.btnBox} />
              </Grid>
            )}
          </Grid>
        </Paper>
      ),

    modal: (
      <Modal
        open={state.blnOpenSetterModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenSetterModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          {appState?.userInfo?.blnIsSponsor || parentState?.objPartnerInfo?.jsnSupportTrans?.length ? null : (
            <React.Fragment>
              <Grid container>
                <Grid item className={classes?.subtitle} p-3>
                  <Typography as="subtitle2" className={classes?.subtitleLabel} style={{ padding: "5px" }}>
                    {labels?.sponsorInfo?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.bigWorkSector} icon="Work" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                </Grid>
                <Grid item xs={12} md="6">
                  <FormField objHandler={fields.strPlaceName2} icon="business" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {objSystemConfig?.blnIsPaid ? (
            <React.Fragment>
              <Grid container>
                <Grid item className={classes?.subtitle} p-3>
                  <Typography as="subtitle1" className={classes?.subtitleLabel}>
                    {labels?.supportInfo?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs="12" container alignItems="center" pt-2>
                  <Grid item xs="2" sm="auto" p-0>
                    <Avatar className={classes?.avatarStep}>
                      <Typography as="subtitle1">1</Typography>
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography as="subtitle2" color={appStyle?.App_info_Color} className={classes?.descriptionInfoText}>
                      {labels?.step1?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} py-3>
                    <FormField
                      objHandler={fields.strToSupport}
                      icon="code"
                      mode={"classic"}
                      color={appStyle?.App_info_Color}
                      className={classes?.field}
                      onChange={handleChangeToSupport}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={fields.strSponsorTrainingType} icon="code" mode={"classic"} color={appStyle?.App_info_Color} className={classes?.field} />
                  </Grid>
                </Grid>
                <Grid item xs="12" container alignItems="center" pt-10>
                  <Grid item xs="2" sm="auto" p-0>
                    <Avatar className={classes?.avatarStep}>
                      <Typography as="subtitle1">2</Typography>
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography as="subtitle2" color={appStyle?.App_info_Color} className={classes?.descriptionInfoText}>
                      {labels?.step2?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs="12">
                    <Grid container>
                      <Grid item xs="12">
                        <TextArea className={classes?.textareBankInfo} defaultValue={objSystemConfig?.strBankInfo?.[lang]} readOnly disabled={true} rows={3} endAdornment={"  "} />
                        <Icon icon="copyall" className={classes?.iconcopyall} onClick={handleCopy} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs="12" container alignItems="center" pt-10>
                  <Grid item xs="2" sm="auto" p-0>
                    <Avatar className={classes?.avatarStep}>
                      <Typography as="subtitle1">3</Typography>
                    </Avatar>
                  </Grid>
                  <Grid item xs={10} sm="11" md="auto">
                    <Typography as="subtitle2" color={appStyle?.App_info_Color} className={classes?.descriptionInfoText}>
                      {labels?.step3?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item m-xs-auto m-sm-auto pt-3>
                    {uploaderReceipt?.JSX}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : objSystemConfig?.blnIsContact ? (
            <React.Fragment>
              <Grid container>
                <Grid item className={classes?.subtitle} p-3>
                  <Typography as="subtitle1" className={classes?.subtitleLabel}>
                    {labels?.step2?.contactTitle?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={12} py-3>
                  <FormField objHandler={fields.strSponserName} icon="person" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                </Grid>
                <Grid item xs={12} pt-3>
                  <FormField objHandler={fields.strUserPhone} icon="phone" mode={"classic"} color={appStyle.App_info_Color} className={classes?.field} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item className={classes?.subtitle} p-3>
                  <Typography as="subtitle1" className={classes?.subtitleLabel}>
                    {labels?.supportInfo?.[lang]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={12} py-3>
                  <FormField
                    objHandler={fields.strToSupport}
                    icon="code"
                    mode={"classic"}
                    color={appStyle?.App_info_Color}
                    className={classes?.field}
                    onChange={handleChangeToSupport}
                  />
                </Grid>
                <Grid item xs={12} pt-3>
                  <FormField objHandler={fields.strSponsorTrainingType} icon="code" mode={"classic"} color={appStyle?.App_info_Color} className={classes?.field} />
                </Grid>
                <Grid item xs={12} pt-4 pb-3>
                  <FormField objHandler={fields.blnAddNote} color={appStyle.App_info_Color} className={classes?.field} />
                </Grid>
                {!fields?.blnAddNote?.getValue() ? null : (
                  <Grid item xs={12}>
                    <FormField objHandler={fields.strNote}  color={appStyle.App_info_Color} className={classes?.field} rows={3} />
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid container justifyContent="center">
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs="12" pt-10>
                <Button
                  py-0
                  px-10
                  color={appStyle.App_info_Color}
                  label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
                  onClick={updatePartnerInfo()}
                  mode="outlined"
                  className={classes?.btnBox}
                />
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
    modalTranstion: (
      <Modal
        open={state.blnOpenTranstionModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenTranstionModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.support?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justifyContent={"center"} py-2>
            <Grid item xs="12">
              <Table>
                <TableHead>
                  <TableRow className={classes?.tableRow}>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.sponserInfo?.supportStatus?.[lang]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.sponserInfo?.supportType?.[lang]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.sponserInfo?.supportCat?.[lang]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.sponserInfo?.studentNumber?.[lang]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.sponserInfo?.amount?.[lang]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center !important" }} as="subtitle3">
                        {labels?.sponserInfo?.date?.[lang]}
                      </Typography>
                    </TableCell>
                    {parentState?.objPartnerInfo?.jsnSupportTrans?.length === 1 &&
                    !parentState?.objPartnerInfo?.jsnSupportTrans[0]?.lstReceipt?.length &&
                    !parentState?.objPartnerInfo?.jsnSupportTrans[0]?.lstReceiptCatch?.length ? null : (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {([...parentState?.objPartnerInfo?.jsnSupportTrans || []])?.reverse()?.map((item) => (
                    <TableRow key={item?.id} className={classes?.tblBodyRow}>
                      <TableCell headLabel={labels?.sponserInfo?.supportStatus?.[lang]}>
                        <Typography
                          as="subtitle3"
                          sx={{ textAlign: "center !important" }}
                          color={
                            item?.intSupportTransStatus === objSupportTransStatus?.Approved
                              ? "success"
                              : item?.intSupportTransStatus === objSupportTransStatus?.Approved
                              ? "error"
                              : appStyle.App_Second_Color
                          }
                        >
                          {labels.objStatus?.[item?.intSupportTransStatus]?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.supportType?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {objCat?.Root?.toSupport?.[item?.strTrainingType]?.value?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.supportCat?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {
                            dictionary?.shared?.app.sponserLabelCat?.[
                              item?.strSupportCatType ? item?.strSupportCatType :
                              getSponsorTypeAccordingToTraineeNum(item?.intTraineesCount || item?.intTrainingProgPrice, item?.strTrainingType)
                            ]?.[lang]
                          }
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.studentNumber?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {objCat?.Root?.toSupport?.[item?.strTrainingType]?.blnIsTraining?.value?.eng  === "false"? "-" : item?.intTraineesCount}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.amount?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {objCat?.Root?.toSupport?.[item?.strTrainingType]?.blnIsTraining?.value?.eng  === "false"
                            ?  Number(item?.intReceivedAmount && !item?.lstReceipt?  item?.intReceivedAmount :item?.intTrainingProgPrice)
                            : Number(item?.intReceivedAmount && !item?.lstReceipt? item?.intReceivedAmount : item?.intTrainingProgPrice * item?.intTraineesCount).toLocaleString()}
                          {dictionary?.shared?.app?.dinar?.[lang]}
                        </Typography>
                      </TableCell>
                      <TableCell headLabel={labels?.sponserInfo?.date?.[lang]}>
                        <Typography as="body3" sx={{ textAlign: "center !important" }}>
                          {getDateUSFormat(item?.dtmCreatedDate)}
                        </Typography>
                      </TableCell>
                      {!item?.lstReceipt?.length && !item?.lstReceiptCatch?.length ? null : (
                        <TableCell py-3 sx={{ sm: { minWidth: "250px" } }}>
                          <Grid container justifyContent={"center"}>
                            <Grid item xs="6" sm="auto">
                              {!item?.lstReceipt?.length ? null : (
                                <Button label={labels?.receipt?.[lang]} size="sm" p-0 className={classes?.btn} onClick={dilogeImgModal(item?.lstReceipt, "receipt")} />
                              )}
                            </Grid>
                            <Grid item xs="6" sm="auto">
                              {!item?.lstReceiptCatch?.length ? null : (
                                <Button
                                  label={labels?.receiptCatch?.[lang]}
                                  size="sm"
                                  p-0
                                  color="success"
                                  className={classes?.btn}
                                  onClick={dilogeImgModal(item?.lstReceiptCatch, "receiptCatch")}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
    modalImage: (
      <Modal open={state?.blnOpenImgeModal} eventClose={dilogeImgModal()} className={classes?.modal}>
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
            {labels?.[state?.selectedImgType]?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs="12">
              <img src={App_Server_Url_DownloadFiles + state?.selectedLstImg?.[0]?.path} alt={labels?.[state?.selectedImgType]?.[lang]} width="100%" />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
