import React, { useEffect, useRef, useState } from "react";
import "./BtnGoToTop.css";
import "../../../style/main.css";
import CbmisIcon from "../../../icon/CbmisIcon";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const BtnGoToTop = (props) => {
  const { icon, color, iconColor, position, sx, hidden, disabled, dir, className, id, onClick, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("BtnGoToTop"));
  const [showButton, setShowButton] = useState(false);
  const styleRef = useRef({ strSxClassName: "" });

  useEffect(() => {}, [icon, iconColor, sx, hidden, disabled, dir, className, id, onClick]);

  useEffect(() => {
    if (color && styleRef.current.strSxClassName) {
      componentsFunction.getbgColorReturnClassName("." + styleRef.current.strSxClassName, color || "#000000cf");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    //clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmisToTopButton");
    }
  }, [sx, screenWidth]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClicked = () => {
    if (onClick) {
      onClick();
    }
    scrollToTop();
  };

  const getPosition = () => {
    switch (String(position).toLowerCase()) {
      case "left":
        return "cbmisToTopButton-position-left";
      default:
        return "";
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    showButton && (
      <button
        className={`cbmisToTopButton ${getPosition()} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
          hidden
        )} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        id={getID.current}
        {...getRestProps()}
        onClick={handleClicked}
      >
        <CbmisIcon icon={icon || "arrowUpward"} color={iconColor || "light"} size="22px" />
      </button>
    )
  );
};

export default BtnGoToTop;
