export const viewConfirmation = (
  strText,
  action = null,
  label = null,
  direction,
  style = {
    title: null,
    containerBk: null,
    borderColor: null,
    iconColorFill: null,
    iconWidth: null,
    titleColor: null,
    textColor: null,
    textAlign: null,
    fontSizeTitle: null,
    fontSizeTitle_xs: null,
    fontSize: null,
    fontSize_xs: null,
    iconEle: null,
    yesBtnColor: null,
    yesBtnBk: null,
    noBtnColor: null,
    btnFontSize: null,
    btnFontSize_xs: null,
  }
) => {
  try {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return;
    }
    //////////////////////////////////////////// constant (FOR INTERNAL COMPARE ) ///////////////////////////////////////////////////
    //#region
    const xmlns = "http://www.w3.org/2000/svg";
    //#endregion
    //////////////////////////////////////////// init style ///////////////////////////////////////////////////
    //#region

    //info #047dbc  #04a8f9 #efeefe  #5458f7
    //error #d60000  #880300 #feeeef #eb5756
    //warning #fec107  #bc910e #fef8e8 #f2c94d
    //success #4cb050  #3a833c #e5faf5 #00cc99
    if (!style) {
      style = {};
    }
    if (!style?.title) {
      style.title = direction === "rtl" ? "تأكيد" : "Confirmation";
    }
    if (!label) {
      label = {};
    }
    if (!label?.yes) {
      label.yes = direction === "rtl" ? "موافق" : "Agree";
    }
    if (!label?.no) {
      label.no = direction === "rtl" ? "إلغاء" : "Cancel";
    }
    if (!style?.containerBk) {
      style.containerBk = "#ffffff";
    }
    if (!style?.borderColor) {
      style.borderColor = "#ffc800";
    }
    if (!style?.iconColorFill) {
      style.iconColorFill = "#ffc800";
    }
    if (!style?.iconWidth) {
      style.iconWidth = "100%";
    }
    if (!style?.titleColor) {
      style.titleColor = "#ecc243";
    }
    if (!style?.textColor) {
      style.textColor = "#55585c";
    }
    if (!style?.textAlign) {
      style.textAlign = "justify";
    }
    if (!style?.fontSizeTitle_xs) {
      style.fontSizeTitle_xs = style?.fontSizeTitle ? style?.fontSizeTitle : "24px";
    }
    if (!style?.fontSizeTitle) {
      style.fontSizeTitle = "24px";
    }
    if (!style?.fontSize_xs) {
      style.fontSize_xs = style?.fontSize ? style?.fontSize : "13px";
    }
    if (!style?.fontSize) {
      style.fontSize = "16px";
    }
    if (!style?.yesBtnColor) {
      style.yesBtnColor = "#ffffff";
    }
    if (!style?.yesBtnBk) {
      style.yesBtnBk = "#1769aa";
    }
    if (!style?.noBtnColor) {
      style.noBtnColor = "#1769aa";
    }
    if (!style?.btnFontSize) {
      style.btnFontSize = "16px";
    }
    if (!style?.btnFontSize_xs) {
      style.btnFontSize_xs = "12px";
    }
    //#endregion
    //////////////////////////////////////////// create header icon ///////////////////////////////////////////////////
    //#region
    let headerIcon = document.createElementNS(xmlns, "svg");
    headerIcon.setAttributeNS(null, "viewBox", "5 5 54 54");

    if (!style?.iconEle) {
      const path1 = document.createElementNS(xmlns, "path");
      path1.setAttributeNS(null, "d", "M0 0h24v24H0z");
      path1.setAttributeNS(null, "fill", "none");
      headerIcon.appendChild(path1);

      const path2 = document.createElementNS(xmlns, "path");
      path2.setAttributeNS(
        null,
        "d",
        "M50.38,31.45,32.55,13.62a.77.77,0,0,0-1.1,0L13.62,31.45a.78.78,0,0,0,0,1.1L31.45,50.38a.77.77,0,0,0,1.1,0L50.38,32.55A.78.78,0,0,0,50.38,31.45ZM32,43a2,2,0,1,1,2-2A2,2,0,0,1,32,43Zm2-7H30L29,22h6Z"
      );
      headerIcon.appendChild(path2);

      const path3 = document.createElementNS(xmlns, "path");
      path3.setAttributeNS(
        null,
        "d",
        "M53.91,27.91,36.09,10.09a5.78,5.78,0,0,0-8.18,0L10.09,27.91a5.78,5.78,0,0,0,0,8.18L27.91,53.91a5.78,5.78,0,0,0,8.18,0L53.91,36.09A5.78,5.78,0,0,0,53.91,27.91ZM51.79,34,34,51.79a2.75,2.75,0,0,1-3.92,0L12.21,34a2.76,2.76,0,0,1,0-3.92L30,12.21a2.75,2.75,0,0,1,3.92,0L51.79,30A2.76,2.76,0,0,1,51.79,34Z"
      );
      headerIcon.appendChild(path3);

      headerIcon.style.fill = style.iconColorFill;
      headerIcon.style.width = style.iconWidth;
      if (window.innerWidth >= 600) {
        headerIcon.style.paddingLeft = "10px";
        headerIcon.style.paddingRight = "10px";
      }
    } else {
      headerIcon = style?.iconEle;
    }
    //#endregion
    //////////////////////////////////////// root of modal //////////////////////////////////////////////
    //#region
    const modal = document.createElement("div");

    modal.style.position = "fixed";
    modal.style.left = "0px";
    modal.style.top = "0px";
    modal.style.bottom = "0px";
    modal.style.right = "0px";
    modal.style.width = "100%";
    modal.style.height = "100%";
    modal.style.margin = "0px";
    modal.style.padding = "0px";
    modal.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    modal.style.opacity = "1";
    modal.style.transform = "scale(1)";
    modal.style.transition = "visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s";
    modal.style.display = "flex";
    modal.style.flexDirection = "row";
    modal.style.justifyContent = "center";
    modal.style.alignSelf = "center";
    modal.style.alignItems = "center";
    modal.style.alignContent = "center";
    modal.style.flexWrap = "wrap";
    modal.style.boxSizing = "border-box";
    modal.style.zIndex = 1301;
    modal.style.direction = direction === "rtl" ? direction : "ltr";
    //#endregion
    ////////////////////////////////////// container ///////////////////////////////////////////////////
    //#region
    const modalContainer = document.createElement("div");
    modalContainer.animate(
      [
        {
          transform: "scale(0)",
          opacity: "0",
        },
        {
          transform: "scale(1)",
          opacity: "1",
        },
      ],
      {
        duration: 200,
        iterations: 1,
      }
    );
    modalContainer.style.position = "relative";
    modalContainer.style.margin = "0px";
    modalContainer.style.display = "flex";
    modalContainer.style.flexGrow = "0";
    modalContainer.style.flexBasis = `${(100 / 12) * (window.innerWidth < 600 ? 11 : 8)}%`;
    modalContainer.style.width = `${(100 / 12) * (window.innerWidth < 600 ? 11 : 8)}%`;
    modalContainer.style.flexWrap = "wrap";
    modalContainer.style.justifyContent = "space-between";
    modalContainer.style.boxSizing = "border-box";
    modalContainer.style.background = "#ffffff";
    modalContainer.style.borderTop = `8px solid ${style.borderColor}`;
    modalContainer.style.padding = "1.25em 1.25em";
    modalContainer.style.borderRadius = "0.3em";
    modalContainer.style.boxShadow = `1px 1px 5px #d9d9d9`;
    modalContainer.style.backgroundColor = style.containerBk;

    modal.appendChild(modalContainer);
    //#endregion
    ////////////////////////////////////// container header icon and title for mobile  ///////////////////////////////////////////////////
    //#region
    let modalContainerHeaderMobile = null;
    if (window.innerWidth < 600) {
      modalContainerHeaderMobile = document.createElement("div");
      modalContainerHeaderMobile.style.position = "relative";
      modalContainerHeaderMobile.style.margin = "0px";
      modalContainerHeaderMobile.style.display = "flex";
      modalContainerHeaderMobile.style.flexGrow = "0";
      modalContainerHeaderMobile.style.flexBasis = `calc(${(100 / 12) * 12})`;
      modalContainerHeaderMobile.style.width = `calc(${(100 / 12) * 12})`;
      modalContainerHeaderMobile.style.flexWrap = "wrap";
      modalContainerHeaderMobile.style.alignSelf = "center";
      modalContainerHeaderMobile.style.alignItems = "center";
      modalContainerHeaderMobile.style.justifyContent = "flex-start";

      modalContainer.appendChild(modalContainerHeaderMobile);
    }
    //#endregion
    ////////////////////////////////////// icon header item ///////////////////////////////////////////////////
    //#region
    const modalIcon = document.createElement("div");
    modalIcon.style.position = "relative";
    modalIcon.style.margin = "0px";
    modalIcon.style.padding = "0px";
    modalIcon.style.display = "flex";
    modalIcon.style.flexGrow = "0";
    modalIcon.style.flexBasis = `${(100 / 12) * (window.innerWidth < 600 ? 3 : 2)}%`;
    modalIcon.style.width = `${(100 / 12) * (window.innerWidth < 600 ? 3 : 2)}%`;
    modalIcon.style.flexWrap = "wrap";
    modalIcon.style.alignSelf = "stretch";
    modalIcon.style.alignItems = "center";
    modalIcon.style.justifyContent = "flex-start";
    modalIcon.appendChild(headerIcon);

    if (window.innerWidth < 600 && modalContainerHeaderMobile) {
      modalContainerHeaderMobile.appendChild(modalIcon);
    } else {
      modalContainer.appendChild(modalIcon);
    }
    //#endregion
    ////////////////////////////////////// container child item ///////////////////////////////////////////////////
    //#region
    const modalContainerChild = document.createElement("div");
    modalContainerChild.style.position = "relative";
    modalContainerChild.style.margin = "0px";
    if (direction === "rtl") {
      modalContainerChild.style.paddingRight = "6px";
    } else {
      modalContainerChild.style.paddingLeft = "6px";
    }
    modalContainerChild.style.display = "flex";
    modalContainerChild.style.flexGrow = "0";
    modalContainerChild.style.flexBasis = `calc(${(100 / 12) * (window.innerWidth < 600 ? 12 : 10)}%${window.innerWidth < 600 ? "" : " - 6px"})`;
    modalContainerChild.style.width = `calc(${(100 / 12) * (window.innerWidth < 600 ? 12 : 10)}%${window.innerWidth < 600 ? "" : " - 6px"})`;
    modalContainerChild.style.flexWrap = "wrap";
    modalContainerChild.style.alignSelf = "center";
    modalContainerChild.style.alignItems = "center";

    modalContainer.appendChild(modalContainerChild);
    //#endregion
    ////////////////////////////////////// title item ///////////////////////////////////////////////////
    //#region
    const modalTitle = document.createElement("div");
    modalTitle.style.position = "relative";
    modalTitle.style.margin = "auto";
    modalTitle.style.display = "flex";
    modalTitle.style.flexGrow = "0";
    modalTitle.style.flexBasis = `calc(${(100 / 12) * (window.innerWidth < 600 ? 9 : 12)}%${window.innerWidth < 600 ? " - 10px" : ""})`;
    modalTitle.style.width = `calc(${(100 / 12) * (window.innerWidth < 600 ? 9 : 12)}%${window.innerWidth < 600 ? " - 10px" : ""})`;
    modalTitle.style.flexWrap = "wrap";
    modalTitle.style.alignSelf = "flex-start";
    modalTitle.style.alignItems = "center";
    modalTitle.style.whiteSpace = "nowrap";
    modalTitle.style.fontWeight = "bold";
    modalTitle.style.fontSize = window.innerWidth < 600 ? style.fontSizeTitle_xs : style.fontSizeTitle;
    modalTitle.style.fontFamily = "system-ui";
    modalTitle.style.textAlign = "start";
    modalTitle.style.lineHeight = "1.5em";
    modalTitle.style.color = style.titleColor;
    modalTitle.innerHTML = style.title;

    if (window.innerWidth < 600 && modalContainerHeaderMobile) {
      modalContainerHeaderMobile.appendChild(modalTitle);
    } else {
      modalContainerChild.appendChild(modalTitle);
    }

    //#endregion
    ////////////////////////////////////// text item ///////////////////////////////////////////////////
    //#region
    const modalText = document.createElement("div");
    modalText.style.position = "relative";
    modalText.style.margin = "auto";
    modalText.style.display = "flex";
    modalText.style.flexGrow = "0";
    modalText.style.flexBasis = `${(100 / 12) * 12}%`;
    modalText.style.width = `${(100 / 12) * 12}%`;
    modalText.style.flexWrap = "wrap";
    modalText.style.alignSelf = "flex-start";
    modalText.style.alignItems = "flex-start";
    modalText.style.whiteSpace = "pre-line";
    modalText.style.overflow = "auto";
    modalText.style.maxHeight = `calc(100vh - 2.5em - 10em${window.innerWidth < 600 ? " - 7em" : ""})`;
    modalText.style.fontSize = window.innerWidth < 600 ? style.fontSize_xs : style.fontSize;
    modalText.style.fontFamily = "system-ui";
    modalText.style.textAlign = style.textAlign;
    modalText.style.color = style.textColor;
    modalText.innerHTML = strText;

    modalContainerChild.appendChild(modalText);
    //#endregion
    ////////////////////////////////////// container action item ///////////////////////////////////////////////////
    //#region
    let lineSeparator = document.createElement("hr");
    lineSeparator.style.width = "100%";
    lineSeparator.style.border = "0";
    lineSeparator.style.margin = "0.5em 0";
    lineSeparator.style.height = "1px";
    lineSeparator.style.background = "#c2c2c2";
    modalContainer.appendChild(lineSeparator);

    const modalContainerAction = document.createElement("div");
    modalContainerAction.style.position = "relative";
    modalContainerAction.style.margin = "0px";
    modalContainerAction.style.display = "flex";
    modalContainerAction.style.flexGrow = "0";
    modalContainerAction.style.flexBasis = `${(100 / 12) * 12}%`;
    modalContainerAction.style.width = `${(100 / 12) * 12}%`;
    modalContainerAction.style.flexWrap = "nowrap";
    modalContainerAction.style.alignSelf = "center";
    modalContainerAction.style.alignItems = "center";
    modalContainerAction.style.justifyContent = "flex-end";
    modalContainerAction.style.marginTop = "0.5em";

    modalContainer.appendChild(modalContainerAction);
    //#endregion
    ////////////////////////////////////// action no button item ///////////////////////////////////////////////////
    //#region
    const noBtnContainer = document.createElement("div");
    noBtnContainer.style.position = "relative";
    noBtnContainer.style.margin = "0px";
    noBtnContainer.style.display = "flex";
    noBtnContainer.style.flexGrow = "0";
    noBtnContainer.style.flexBasis = `auto`;
    noBtnContainer.style.width = `auto`;
    noBtnContainer.style.flexWrap = "wrap";
    noBtnContainer.style.justifyContent = "flex-end";

    modalContainerAction.appendChild(noBtnContainer);

    const noBtn = document.createElement("button");
    noBtn.style.borderRadius = "0.5rem";
    noBtn.style.padding = window.innerWidth < 600 ? "0.35rem" : "0.5rem";
    noBtn.style.lineHeight = window.innerWidth < 600 ? "1.35rem" : "1.5rem";
    noBtn.style.margin = "auto 0.65rem";
    noBtn.style.minHeight = "2rem";
    noBtn.style.minWidth = "3.25rem";
    noBtn.style.outline = "none";
    noBtn.style.boxSizing = "border-box";
    noBtn.style.border = `0.13rem solid ${style?.noBtnColor}`;
    noBtn.style.background = "#ffffff";
    noBtn.style.color = style?.noBtnColor;
    noBtn.style.fontSize = window?.innerWidth < 600 ? style?.btnFontSize_xs : style?.btnFontSize;
    noBtn.style.fontFamily ="system-ui";
    noBtn.style.cursor = "pointer";
    noBtn.style.transition = "all 0.5s ease 0s";
    noBtn.onmouseenter = function () {
      noBtn.style.border = `0.13rem solid ${style?.noBtnColor + "b1"}`;
      noBtn.style.color = style?.noBtnColor + "b1";

      noBtn.style.background = "#fafafa";
      noBtn.style.boxShadow = "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px";
    };

    noBtn.onmouseleave = function () {
      noBtn.style.border = `0.13rem solid ${style?.noBtnColor}`;
      noBtn.style.color = style?.noBtnColor;

      noBtn.style.background = "#ffffff";
      noBtn.style.boxShadow = "none";
    };

    noBtn.innerHTML = label.no;
    noBtnContainer.appendChild(noBtn);

    noBtn.onclick = function (event) {
      if (action?.no && typeof action?.no === "function") {
        action?.no(event);
      }
      while (modal?.firstChild) {
        modal.removeChild(modal.lastChild);
      }
      modal.remove();
    };

    //#endregion
    ////////////////////////////////////// action yes button item ///////////////////////////////////////////////////
    //#region
    const yesBtnContainer = document.createElement("div");
    yesBtnContainer.style.position = "relative";
    yesBtnContainer.style.margin = "0px";
    yesBtnContainer.style.display = "flex";
    yesBtnContainer.style.flexGrow = "0";
    yesBtnContainer.style.flexBasis = `auto`;
    yesBtnContainer.style.width = `auto`;
    yesBtnContainer.style.flexWrap = "wrap";
    yesBtnContainer.style.justifyContent = "flex-end";

    modalContainerAction.appendChild(yesBtnContainer);

    const yesBtn = document.createElement("button");
    yesBtn.style.borderRadius = "0.5rem";
    yesBtn.style.padding = window.innerWidth < 600 ? "0.35rem" : "0.5rem";
    yesBtn.style.lineHeight = window.innerWidth < 600 ? "1.35rem" : "1.5rem";
    yesBtn.style.margin = "auto 0.65rem";
    yesBtn.style.minHeight = "2rem";
    yesBtn.style.minWidth = "3.25rem";
    yesBtn.style.outline = "none";
    yesBtn.style.boxSizing = "border-box";
    yesBtn.style.border = "0.166667rem solid transparent";
    yesBtn.style.background = style?.yesBtnBk;
    yesBtn.style.color = style?.yesBtnColor;
    yesBtn.style.fontSize = window?.innerWidth < 600 ? style?.btnFontSize_xs : style?.btnFontSize;
    yesBtn.style.fontFamily = "system-ui";
    yesBtn.style.cursor = "pointer";
    yesBtn.style.transition = "all 0.5s ease 0s";
    yesBtn.onmouseenter = function () {
      yesBtn.style.background = style?.yesBtnBk + "e1";
      yesBtn.style.boxShadow = "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px";
    };

    yesBtn.onmouseleave = function () {
      yesBtn.style.background = style?.yesBtnBk;
      yesBtn.style.boxShadow = "none";
    };

    yesBtn.innerHTML = label.yes;
    yesBtnContainer.appendChild(yesBtn);

    yesBtn.onclick = function (event) {
      if (action?.yes && typeof action?.yes === "function") {
        action?.yes(event);
      } else if (action && typeof action === "function") {
        action(event);
      }

      while (modal?.firstChild) {
        modal.removeChild(modal.lastChild);
      }
      modal.remove();
    };

    //#endregion
    ////////////////////////////////////// add to body /////////////////////////////////////////////
    //#region
    document.body.appendChild(modal);

    return {
      eventClose: () => {
        while (modal?.firstChild) {
          modal.removeChild(modal.lastChild);
        }
        modal.remove();
      },
    };
    //#endregion
  } catch (error) {
    // console.log(error);
  }
};