import { makeStyle } from "@cbmisorg/styles";
import * as appStyle from "../../../util/appStyle";

export const objStyle = makeStyle({
  secDark: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: "calc(100vh)",
    overflowY: "auto",
    margin: "0px auto",
    padding: "0",
    backgroundColor: "rgb(0, 2, 36)",
    background: "linear-gradient(155deg, #000224 0%, #010219 55%, #0371a0 100%)",
  },
  mainContainer: {
    width: `${(11 / 12) * 100}%`,
    margin: "20px auto 0",
  },
  container: {
    width: `${(10 / 12) * 100}%`,
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
    paddingInlineEnd: "20px",
    xs: {
      width: `${(11 / 12) * 100}%`,
    },
  },

  field: {
    color: appStyle.App_Input_Text_Color,
    fieldset: {
      borderRadius: 0,
      color: appStyle.App_Input_Text_Color,
    },
    input: {
      color: appStyle.App_Input_Text_Color,
      ...appStyle?.fontsMada,
    },
    label: {
      ...appStyle?.fontsMada,
    },
    ".uicore_switch_label_1gtyshu": {
      color: appStyle?.App_Light_Text_Color,
    },
    ".uicore_menu": {
      background: appStyle?.App_Dark_Color,
      border: "1px solid",
      borderColor: appStyle?.App_info_Color,
      borderRadius: 0,
      color: appStyle?.App_Light_Text_Color,
      ...appStyle?.fontsMada,
      ".uicore_radio_opt_label": {
        "&hover": {
          background: appStyle?.App_Dark_menu_Color,
        },
      },
    },
    ".uicore_checkbox_opt": {
      color: `${appStyle?.App_Light_Text_Color} !important`,
    },
  },
  bgBox: ({ lang }) => ({
    zIndex: 10,
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      // background: `url(${require("../../../util/assets/images/box1-v.png")})`,
      background: appStyle.App_info_Opacity_Color,
      border: "1px solid",
      borderColor: appStyle.App_info_Color,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      transform: lang === "arb" ? "rotateY(0deg)" : "rotateY(180deg)",
    },
  }),
  subtitle: ({ lang }) => ({
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 5px 20px 0",
    padding: "25px",
    "&before": {
      content: "",
      display: "block",
      width: "100%",
      height: "100%",
      minWidth: "210px",
      // minHeight: "50px",
      position: "absolute",
      top: "0",
      background: `url(${require("../../../util/assets/images/gif1.gif")})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      margin: "10px",
      transform: lang === "arb" ? "rotateY(180deg)" : "rotateY(0deg)",
    },
  }),
  subtitleLabel: {
    color: appStyle.App_info_Color,
    position: "relative",
    right: "35px",
    left: "35px",
    padding: "5px 20px",
    ...appStyle?.fontsHandjet,
    fontWeight: "500",
  },
  mainTitle: {
    color: appStyle.App_info_Color,
    position: "relative",
    margin: "auto",
    ...appStyle.fontsChanga,
    textAlign: "center !important",
    fontWeight: "600",
    lineHeight: "100%",
  },
  labelsLight: {
    color: appStyle?.App_Light_Text_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
  },
  labelsInfo: {
    color: appStyle?.App_info_Color,
    ...appStyle.fontsChanga,
    textAlign: "justify",
  },
  line: {
    width: "50%",
    padding: "20px 0 10px",
    color: appStyle?.App_info_Color,
  },
  btn: {
    // clipPath: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
    fontSize: "large",
    // lineHeight: "40px",
    span: {
      ...appStyle?.fontsHandjet,
    },
    "&hover": {
      background: appStyle.App_Second_Opacity_Color,
    },
  },
  arrowClose: {
    display: "none",
  },
  descriptionLightText: {
    userSelect: "none",
    lineHeight: "1.5rem",
    lineClamp: 6,
    boxOrient: "vertical",
    WebkitLineClamp: 6,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    cursor: "pointer",
    // padding: "5px",
    "&hover": {
      overflow: "visible",
      WebkitLineClamp: "unset",
      WebkitBoxOrient: "initial",
      padding: "10px",
      "&+": {
        ".arrowClose": {
          display: "flex",
          zIndex: 1,
        },
      },
    },
  },
  gridBorder:{
    border: "1px dashed",
    borderColor: appStyle?.App_info_Opacity_Color,
  }
});
