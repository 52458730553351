import React, { useEffect, useState, useRef } from "react";
import "./Slideshow.css";
import "../../../style/main.css";
import CbmisIcon from "../../../icon/CbmisIcon";
import VideoPlayer from "../../dataDisplay/videoPlayer/VideoPlayer";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName, setSXStyle } from "../../../helper/convertJsCss";

function Slideshow(props) {
  const { slides = [], autoShow, delay, activeColor, dotsShow, slideNumber = 1, sx, hidden, disabled, dir, className, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-slid-show") });
  const sliderSplitNum = new Array(Math.round(slides.length / slideNumber));
  const extraSlide = slides.length % slideNumber;

  const canMove = Math.round(slides.length / slideNumber) > 1;
  const isAutoShow = autoShow && canMove;
  const timeDelay = delay || 3000;

  useEffect(() => {}, [slides, autoShow, delay, activeColor, dotsShow, sx, hidden, disabled, dir, className]);

  useEffect(() => {
    if (isAutoShow) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => setIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1)), timeDelay);

      return () => {
        resetTimeout();
      };
    }
  }, [index]);

  useEffect(() => {
    if (activeColor) {
      document.documentElement.style.setProperty("--cbmis-slideshow-active-color", componentsFunction.checkColorReturnValue(activeColor));
    }
  }, []);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-slid-show");
    }
  }, [sx, screenWidth]);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const handelNext = () => {
    if (canMove) {
      var next = index + 1;
      if (next === sliderSplitNum.length) {
        next = 0;
      }
      setIndex(next);
    }
  };

  const handelPrev = () => {
    if (canMove) {
      var Prev = index - 1;
      if (Prev === -1) {
        Prev = sliderSplitNum.length - 1;
      }
      setIndex(Prev);
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  return (
    <div
      className={`cbmis-slideshow  ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
        hidden
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {canMove ? (
        <React.Fragment>
          <button className="cbmis-slideshow-btn-slide cbmis-slideshow-prev" onClick={handelPrev}>
            <CbmisIcon icon="ArrowBackIos" />
          </button>

          <button className="cbmis-slideshow-btn-slide cbmis-slideshow-next" onClick={handelNext}>
            <CbmisIcon icon="ArrowForwardIos" />
          </button>
        </React.Fragment>
      ) : (
        ""
      )}
      {dotsShow   && canMove? (
        <div className="cbmis-slideshowDots">
          {slides.map((_, idx) => (
            <div
              key={idx}
              className={`cbmis-slideshowDot${index === idx ? " cbmis-slideshowDot-active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      ) : (
        ""
      )}

      <div
        id={styleRef.current.strSXComponentID}
        className={`cbmis-slideshowSlider`}
        style={{ transform: `translateX(${document.querySelector(".cbmis-body-dir-rtl") || dir === "rtl" ? index * 100 : -index * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div className="cbmis-slide" key={index} style={{ backgroundImage: `${slide.image ? `url(${slide.image})` : ""}`, flex: ` 1 0 ${100 / slideNumber}%` }}>
            {!slide.videoMp4 ? <div className="cbmis-silde-containt">{slide.content}</div> : <VideoPlayer src={slide.videoMp4} imageSrc={slide.image} />}
          </div>
        ))}
        {slides.map((slide, index) => { setSXStyle(`cbmis-slide-${index}`, slide.sx);
          return index < extraSlide ? (
            <div
              className={`cbmis-slide cbmis-slide-${index}`}
              key={index}
              style={{backgroundImage: `${slide.image ? `url(${slide.image})` : ""}`, flex: ` 1 0 ${100 / slideNumber}%` }}
            >
              {!slide.videoMp4 ? <div className="cbmis-silde-containt">{slide.content}</div> : <VideoPlayer src={slide.videoMp4} imageSrc={slide.image} />}
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default Slideshow;
