import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./CheckboxGroup.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Icon from "../../../icon/CbmisIcon";

const CheckboxGroup = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,checkMode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,options,
          optionStyle,display,
          sx,hidden,disabled,dir,className,...rest} = props

    const inputRef = useRef({})
    const [inputState,setInputState] = useState({
      listObjValues:[],
      listValues:[],
      listKeys:[],
    })

    const screenWidth = useCheckScreenSize();
    const nameID = useRef(id || componentsFunction.randomName("CheckboxGroup"));
    const getID = useRef(componentsFunction.randomName("CheckboxGroup"));
    const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-input")});


  //#region Effect
    useEffect(()=>{
        inputRef.current.listObjValues = value || defaultValue || ""
        const theValue = value || defaultValue
        if(theValue){
          theValue.forEach(element => {
            inputState.listObjValues.push({key:element.key, value:element.value})
            inputState.listValues.push(element.value)
            inputState.listKeys.push(element.key)
          });
          inputRef.current.listValues = inputState.listValues
          inputRef.current.listKeys = inputState.listKeys
          inputRef.current.listObjValues = inputState.listObjValues

          if(ref){
            ref.current.listValues = inputState.listValues
            ref.current.listKeys = inputState.listKeys
            ref.current.listObjValues = inputState.listObjValues

          }
          setInputState({...inputState})
        }
    },[value, defaultValue, ref])

    useEffect(()=>{
    },[id,label,placeholder,errorMessage,checkMode,
        color,icon,iconColor,adornment,endIcon,endAdornment,
        value,defaultValue,optionStyle,display,
        sx,hidden,disabled,dir,className,rest,
        inputState,
        ])

//#endregion

  //#region style

  const getBoxMarkCheck = () => {
    const getMarkStyle = String(checkMode).toLowerCase();
    switch (getMarkStyle) {
      case "tick":
        return "cbmis-checkbox-tick";
      case "cross":
        return "cbmis-checkbox-cross";
      case "indeterminate":
        return "cbmis-checkbox-indeterminate";
      default:
        return "cbmis-checkbox-tick";
    }
  };
  const getDisplayRadio = () => {
    const getDisplay = String(display).toLowerCase();
    switch (getDisplay) {
      case "inline":
        return "cbmis-checkbox-container-inline-display";
      default:
        return "cbmis-checkbox-container-block-display";
    }
  };

    useEffect(() => {
      if (sx) {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-input");
      }
    }, [sx, screenWidth]);

    useEffect(() => {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, { "&focus":{color: `${componentsFunction.checkColorReturnValue(color || "primary")}`} }, "cbmis-input-focus-color");
    }, [screenWidth, color]);

    const getRestProps = () => {
      const newRest = componentsFunction.filterPaddingAndMargin(rest);
      return { ...newRest };
    };
  //#endregion

  //#region change
    const handelChange = (event) => {
      const selectedValue = event.target.value;
      var selectedKey = event.target.value;
      if (event.target.id !== "") {
        selectedKey = event.target.id;
      }
      if(event.target.checked){
        inputState.listValues.push(selectedValue)
        inputState.listKeys.push(selectedKey)
        inputState.listObjValues.push({key:selectedKey, value:selectedValue})

      }else{
        inputState.listValues =inputState.listValues.filter(value=>value!==selectedValue)
        inputState.listKeys = inputState.listKeys.filter(key=>key!==selectedKey)
        inputState.listObjValues = inputState.listObjValues.filter(item=>item.key!==selectedKey && item.value!==selectedValue)
      }
      inputRef.current.listValues = inputState.listValues
      inputRef.current.listKeys = inputState.listKeys
      inputRef.current.listObjValues = inputState.listObjValues

      if(ref){
        ref.current.listValues = inputState.listValues
        ref.current.listKeys = inputState.listKeys
        ref.current.listObjValues = inputState.listObjValues

      }
      setInputState({...inputState})

      if (onChange) {
        onChange(event);
      }else{
      }
    };
  //#endregion

  return (
      <React.Suspense fallback="">
        <div id={getID.current}
              className={`cbmis-main-container
                          ${icon || adornment?"cbmis-input-haveIcon":""} 
                          ${componentsFunction.CheckHiddenItem(hidden) || ""}`}

              {...getRestProps()}
        >
          <div className={componentsFunction.CheckDirectionItem(dir)}>
            <div className={`cbmis-input-container 
                            ${componentsFunction.CheckDisabledItem(disabled)} 
                            ${errorMessage?"cbmis-input-have-error":""}
                          `}
                           >
              {icon?
                <div className="cbmis-radio-checkbox-icon-container" >
                <Icon icon= {icon} color={errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}/>
                </div>
                :adornment?
                <div className="cbmis-radio-checkbox-adornment-container" style={{color:errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}} >
                    <label>{adornment}</label>
                </div>
                :""
                }
                <div className={icon ? "cbmis-checkbox-haveIcon" : ""}>
                  {label?.default || label?
                    <label className="cbmis-input-label" htmlFor={`${nameID.current}`}>{label?.default || label}</label>
                  :""}
                  {options
                    ? options.map((option, index) => {
                        return (
                          <label key={option.key} htmlFor={option.value} className={`cbmis-checkbox-container ${getDisplayRadio()}`} style={optionStyle || {}}>
                            <div style={{ color: color ? componentsFunction.checkColorReturnValue(color) : "var(--cbmis-primary)" }}>
                              <input
                                type="checkbox"
                                id={option.key}
                                key={option.key + index}
                                name={nameID.current}
                                className={`cbmis-input-checkbox ${inputState.listValues.includes(String(option.value)) && inputState.listKeys.includes(String(option.key)) ?"cbmis-input-checkbox-check":""}`}
                                ref={ref || inputRef}
                                defaultValue={option?.value || ""}
                                checked={inputState.listValues.includes(String(option.value)) || inputState.listKeys.includes(String(option.key))}
                                onChange={handelChange}
                                {...getRestProps()}
                              />
                              <span className={`cbmis-box-mark-check ${getBoxMarkCheck()}`}></span>
                            </div>
                            <span className={`cbmis-option-input-label`}>{option.jxs && option.jxs()? option.jxs() : option.label || option.value}</span>
                          </label>
                        );
                      })
                    : ""}
                </div>
            </div>


            <ErrorMessage errorMessage={errorMessage} />
          </div>
        </div>
      </React.Suspense>
    )
});

export default CheckboxGroup