import { Alert } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { App_ID, objUploadType} from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/requirementInfo/CtrlRequirementInfo.js";
const tblUser = generateQueries("tblUser")
const tblSystem = generateQueries("tblSystem")

export const CtrlRequirementInfo = (appState, appDispatch, isLoading, setIsLoading, navigate, stateInfo) => {
  const lang = appState?.clientInfo?.strDataLanguage ||  appState?.clientInfo?.strLanguage;
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;

  return {
    updateRequested:
    async (uploaderGovID,uploaderRest) => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);


        const blnSubscribeRequest = appState?.userInfo?.blnIsApproved && !appState?.userInfo?.blnIsPaySubscribed

        if(!stateInfo?.listFileGovID?.length && !stateInfo?.listFileRestID?.length){
          setIsLoading(false);
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.requeioUploadFile?.[lang], "");
          return 
        }
        let govIDFile = null
        let receiptFile=null
        if(stateInfo?.listFileGovID?.length){
          govIDFile =await uploaderGovID?.uploadHandler()
        }
        if(stateInfo?.listFileRestID?.length){
          receiptFile = await uploaderRest?.uploadHandler()
        }
        if(!govIDFile &&  !receiptFile){
          setIsLoading(false);
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantUploadFile?.[lang], "");
          return 
        }

        const result = await tblUser(strPageInfo,appState,"aaiotAppUserUpdate",
        {
          objectInput:{
            blnIsCompleteReg :true,
            jsnUserInfo: {
              ...appState?.userInfo?.jsnUserInfo,
              strGovIDFilePath:govIDFile && stateInfo?.listFileGovID?.length?`${objUploadType?.aaiot?.fileType}/${objUploadType?.payment}/${stateInfo?.listFileGovID?.[0]?.name}`:appState?.userInfo?.jsnUserInfo?.strGovIDFilePath || null,
              strReceiptFilePath:receiptFile && stateInfo?.listFileRestID?.length?`${objUploadType?.aaiot?.fileType}/${objUploadType?.payment}/${stateInfo?.listFileRestID?.[0]?.name}`:appState?.userInfo?.jsnUserInfo?.strReceiptFilePath || null,
              blnIsSeen:false
            },
            jsnPaySubscribed:{
              ...appState?.userInfo?.jsnPaySubscribed,
              dtmSubscribedDeactivate:null
            }
          },
          objectCondition:{
            bigAppID: App_ID,
            bigSystemID: appState?.userInfo?.bigSystemID,
            bigUserID:appState?.userInfo?.bigUserID,
            blnIsActive:true,
            blnIsDeleted:false
          }
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang],"warning")
          return;
        }

        if(!blnSubscribeRequest){
          const resultJoinIncCount = await tblSystem(strPageInfo,appState,"aaiotAppIncDecInt",
          {
            arrAttributes:["intCountRequestedToJoin"],
            objectCondition:{
              bigAppID: App_ID ,
              bigSystemID:appState?.userInfo?.bigSystemID,
              blnIsActive:true,blnIsDeleted:false,
            },
          });

          if (!resultJoinIncCount?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            return;
          }
        }else{
          const resultSubscriptionCount = await tblSystem(strPageInfo,appState,"aaiotAppIncDecInt",
        {
          arrAttributes:["intCountSubscription"],
          objectCondition:{
            bigAppID: App_ID ,
            bigSystemID:appState?.userInfo?.bigSystemID,
            blnIsActive:true,blnIsDeleted:false,
          },
        });

        if (!resultSubscriptionCount?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          return;
        }
        }
        

        setIsLoading(false);
        Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully?.[lang], "success");
        navigate(objAppRouting?.Waiting?.url, { replace: true })
        appState.userInfo.blnIsCompleteReg = true
        appDispatch()
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addCategory-ERROR", error);
        setIsLoading(false);
      }
    },
  };
};
