import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
// import {styleFunction} from "@cbmisorg/styles"

export default function QRCodeGenerator({ strText , background= "light", color="dark", size=3}) {
  const [state, setState] = useState({ qrCodeImg: "" });
  useEffect(() => {
    generateQRCode();
  }, [strText]);

  async function generateQRCode() {
    try {
      const qrResult = await QRCode.toDataURL(strText, { errorCorrectionLevel: "H", color: { dark: color, light:background }, type: "image/png", scale: size});
      state.qrCodeImg = qrResult;
      setState({ ...state });
      return;
    } catch (error) {
      console.log(error);
    }
  }

  return <img src={state.qrCodeImg} alt="" />;
}
