// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ClassesBuilder } from "@cbmisorg/styles";
import { useLocation, useNavigate } from "@cbmisorg/router";
import { Container, Grid, Typography, Loader } from "@cbmisorg/material";

import { useApp } from "../../../configuration/contextapi/context";
import { FormData } from "../../../util/formApi/FormAPI";
import { dictionary } from "../../../util/appLanguage/dictionary";

import { CtrlTrainingRegistration } from "./trainingRegistration.ctrl";
import { objStyle } from "./trainingRegistration.style";

import ShredHeader from "../../shared/shredHeader";
import { App_info_Color } from "../../../util/appStyle";
import useCheckSystemInfo from "../../../util/hooks/useCheckSystemInfo";
import useCheckCat from "../../../util/hooks/useCheckCat";
import { useSearchParams } from "react-router-dom";
import { objUploadType, workSectorID } from "../../../configuration/appVariables";
import WorkSectorOption from "../../stakeholders/member/account/components/regActivityGuest/component/WorkSectorOption.view";
import Step1 from "./component/Step1";
import Step2 from "./component/Step2";
import Step3 from "./component/Step3";
import { useParams } from "@cbmisorg/client-app";
import Uploader from "../../../../old/components/sharedUi/uploader/Uploader";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";

const labels = dictionary?.components?.public?.trainingRegistration;
const kwCode = "kw";

function TrainingRegistration() {
  useCheckHooksGlb();
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const { getNewSystemInfo } = useCheckSystemInfo({ appState });
  const { getNewCatInfo } = useCheckCat({ appState });
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const userInfo = location?.state?.userInfo ? location?.state?.userInfo : appState?.userInfo;
  const langSearch = searchParams.get("lang");
  const { countrycode } = useParams();
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const lang = langSearch ? langSearch : appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    countryCode: "",
    areaCode: "",
    blnDefaultCountry: true,
    intStep: 1,
    lstIntroductoryVideo: [],
    lstTranscript: [],
    blnIsAdminEdit: location?.state?.userInfo ? true : false,
  });
  const uploaderVideo = Uploader(state, setState, "lstIntroductoryVideo", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: labels?.video?.[lang],
    blnHoverd: false,
  });
  const uploaderTranscript = Uploader(state, setState, "lstTranscript", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    // file: labels?.video?.[lang],
    blnHoverd: false,
  });

  const fields = FormData({
    bigWorkSector2: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.student, value: dictionary?.shared?.app?.student?.title, jsx: () => <WorkSectorOption type="student" lang={lang} /> },
        { key: workSectorID?.unEmployee, value: dictionary?.shared?.app?.unEmployee?.title, jsx: () => <WorkSectorOption type="unEmployee" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
    strFullName: { control: "text", validations: { required: true, minWord: "2" }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, email: true },
    },
    bigAreaCountryID: { control: "list", validations: {}, blnLanguage: false },
    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true, minLength: 5, maxLength: 10 },
    },

    bigWorkGovSectorType: {
      control: "list",
      blnLanguage: false,
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov && state?.countryCode === kwCode,
      },
    },
    bigGovPlaceName: {
      control: "list",
      blnLanguage: false,
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov && state?.countryCode === kwCode,
      },
    },
    bigCountryOfCitizenID: {
      control: "list",
      validations: {
        required: () =>
          fields?.bigWorkSector2?.getValue()?.key === workSectorID?.prvt ||
          fields?.bigWorkSector2?.getValue()?.key === workSectorID?.unEmployee ||
          fields?.bigWorkSector2?.getValue()?.key === workSectorID?.student,
      },
      blnLanguage: false,
    },
    bigCountryOfResidenceID: { control: "list", validations: {}, blnLanguage: false },
    strPositionTitle: {
      control: "text",
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov || fields?.bigWorkSector2?.getValue()?.key === workSectorID?.prvt,
      },
      blnLanguage: false,
    },
    bigMajorID: {
      control: "list",
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.unEmployee || fields?.bigWorkSector2?.getValue()?.key === workSectorID?.student,
      },
      blnLanguage: false,
    },
    bigUniversityName: {
      control: "list",
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.unEmployee || fields?.bigWorkSector2?.getValue()?.key === workSectorID?.student,
      },
      blnLanguage: false,
    },
    strUniversityName: { control: "text", validations: { required: () => fields?.bigUniversityName?.getValue()?.key === "other" }, blnLanguage: false },
    bigAcademicYearID: {
      control: "list",
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.student,
      },
      blnLanguage: false,
    },
    strYearGraduation: {
      control: "text",
      validations: {
        onlyNumbers: true,
        maxLength: 4,
        minLength: 4,
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.unEmployee,
      },
      blnLanguage: false,
    },
    strPlaceName: {
      control: "text",
      validations: {
        required: () =>
          (fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov && state?.countryCode === kwCode && fields?.bigGovPlaceName?.getValue()?.key === "other") ||
          // (jsnSystemConfig?.strDefaultContryRegisterCode !== state?.countryCode && fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov) ||
          (state?.countryCode !== kwCode && fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov) ||
          fields?.bigWorkSector2?.getValue()?.key === workSectorID?.prvt,
      },
      blnLanguage: false,
    },
    intExperienceYear: {
      control: "text",
      validations: {
        onlyNumbers: true,
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov || fields?.bigWorkSector2?.getValue()?.key === workSectorID?.prvt,
      },
      blnLanguage: false,
    },

    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        minLength: 4,
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        minLength: 4,
        equal: "strUserPassword",
      },
    },
  });

  // console.log(state?.countryCode , jsnSystemConfig?.strDefaultContryRegisterCode,state?.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode);

  useEffect(() => {}, [state?.countryCode]);

  const interviewfields = FormData({
    blnAppsNeedDev: {
      control: "radio",
      blnLanguage: false,
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov && state?.countryCode === kwCode,
      },
      options: [{ key: "true" }, { key: "false" }],
    },
    strExplainProposal: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },
    blnlikeWorkinGivSec: {
      control: "radio",
      blnLanguage: false,
      validations: {
        required: () => fields?.bigWorkSector2?.getValue()?.key !== workSectorID?.gov && state?.countryCode === kwCode,
      },
      options: [{ key: "true" }, { key: "false" }],
    },
    bigWorkGovSectorType: { control: "list", blnLanguage: false, validations: {} },
    bigGovPlaceName: { control: "list", blnLanguage: false, validations: {} },
    blnGraduationProject: {
      control: "radio",
      blnLanguage: false,
      validations: { required: () => state?.countryCode === kwCode },
      options: [{ key: "true" }, { key: "false" }],
    },
    blnHaveGoveIdea: {
      control: "radio",
      blnLanguage: false,
      validations: {},
      options: [{ key: "true" }, { key: "false" }],
    },
    strExplainIdea: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },

    blnExpJs: {
      control: "radio",
      blnLanguage: false,
      options: [{ key: "true" }, { key: "false" }],
      validations: { required: true },
    },
    intYearExpJs: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    intNumJsProject: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    strExpJsProject: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },
    blnExFe: {
      control: "radio",
      blnLanguage: false,
      options: [{ key: "true" }, { key: "false" }],
      validations: {},
    },
    intYearExpFn: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    strExpFeProject: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },
    blnExpNode: {
      control: "radio",
      blnLanguage: false,
      options: [{ key: "true" }, { key: "false" }],
      validations: { required: true },
    },
    intYearExpNode: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    intNumNodeProject: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    strExpNodeProject: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },
    blnExAi: {
      control: "radio",
      blnLanguage: false,
      options: [{ key: "true" }, { key: "false" }],
      validations: { required: true },
    },
    intYearExpAi: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    strExpAiProject: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },
    blnExCybS: {
      control: "radio",
      blnLanguage: false,
      options: [{ key: "true" }, { key: "false" }],
      validations: { required: true },
    },
    intYearExpCybS: {
      control: "text",
      blnLanguage: false,
      validations: { onlyNumbers: true },
    },
    strExpCybSProject: {
      control: "textarea",
      blnLanguage: false,
      validations: {},
    },
    // blnExMobile: {
    //   control: "radio",
    //   blnLanguage: false,
    //   options: [{ key: "true" }, { key: "false" }],
    //   validations: { required: true },
    // },
    // intYearExpMobile: {
    //   control: "text",
    //   blnLanguage: false,
    //   validations: { onlyNumbers: true },
    // },
    // strExpMobileProject: {
    //   control: "textarea",
    //   blnLanguage: false,
    //   validations: {},
    // },
  });

  const handlers = CtrlTrainingRegistration({ userInfo, appState, appDispatch, isLoading, setIsLoading, countryCode: countrycode || kwCode });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields, interviewfields, lang });
  const gOToStep2 = handlers.gOToStep2({ fields, interviewfields, state, setState });
  const gOToStep3 = handlers.gOToStep3({ fields, interviewfields, state, setState });
  const gOToStepPre = handlers.gOToStepPre({ fields, interviewfields, state, setState });
  const onChangeWorkGovSectorType = handlers.onChangeWorkGovSectorType;
  const onChangesetValidation = handlers.onChangesetValidation({ fields, interviewfields, state });
  const handleSubmit = () => handlers.handleSubmit({ fields, interviewfields, navigate, state, setState, uploaderVideo, uploaderTranscript });
  const onChangeAreaCode = handlers.onChangeAreaCode({ fields, state, setState });

  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader
        lang={lang}
        blnHome={false}
        title={
          state?.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode
            ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryTitle?.[lang] || labels?.title?.[lang]
            : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strTitle?.[lang] || labels?.title?.[lang]
        }
        blnBack={window?.history?.length > 2}
        sx={{ position: "sticky" }}
      />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer} py-3>
          <Grid container justifyContent={"center"} alignSelf="center" mt-6>
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container}>
                <Grid container justifyContent="center">
                  <Grid item xs="12" justifyContent="center">
                    <Typography as="subtitle2" className={`${classes?.mainTitle}`}>
                      {labels?.subTitle?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>
                {state?.intStep === 1 ? (
                  <Step1 fields={fields} classes={classes} gOToStep2={gOToStep2} lang={lang} />
                ) : state?.intStep === 2 ? (
                  <Step2
                    state={state}
                    fields={fields}
                    classes={classes}
                    gOToStep3={gOToStep3}
                    gOToStepPre={gOToStepPre}
                    onChangeWorkGovSectorType={onChangeWorkGovSectorType}
                    appState={appState}
                    lang={lang}
                    onChangeAreaCode={onChangeAreaCode}
                  />
                ) : state?.intStep === 3 ? (
                  <Step3
                    state={state}
                    uploaderVideo={uploaderVideo}
                    uploaderTranscript={uploaderTranscript}
                    fields={fields}
                    interviewfields={interviewfields}
                    classes={classes}
                    handleSubmit={handleSubmit}
                    gOToStepPre={gOToStepPre}
                    onChangeWorkGovSectorType={onChangeWorkGovSectorType}
                    onChangesetValidation={onChangesetValidation}
                    appState={appState}
                    lang={lang}
                  />
                ) : null}
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default TrainingRegistration;
