import React from 'react'
import "./uploader.css"
import {resizeImgKB,resizeImgWH} from "./rezieImageFun"

function Uploader({images,setImages, maxFilesNumber,maxSizeKB,maxWidth,maxHeight,quality}) {


  async function changeImage(e){
    let litsFile= []
      if(maxSizeKB){
        litsFile =  await resizeImgKB(Array.from(e.currentTarget.files),maxSizeKB,maxFilesNumber,quality,false)
      }else if(maxWidth || maxHeight){
          resizeImgWH(Array.from(e.currentTarget.files),maxWidth,maxHeight,quality,maxFilesNumber)
      }
      else{
        litsFile = []
        Array.from(e.currentTarget.files).forEach((file,index)=>{
              if(index < Number(maxFilesNumber)){
                litsFile.push(file);
              }
            })
      }
    setImages(litsFile)
  }

  return (
    <React.Fragment>
      <input type="file"
              className="cbmis-input-uploader-file"
              accept="image/*"
              onChange={changeImage}
              multiple={Number(maxFilesNumber)>1}
              />
    </React.Fragment>
  )
}

export default Uploader