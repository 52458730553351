import React, { useState } from "react";
import { Button, Container, Grid, Icon, Line, Nav, Typography } from "@cbmisorg/client-app";
import * as appColor from "../../../../../appHelper/appColor";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { CtrlLandingPage } from "../../controller/CrtlLandingPage";
import { FormData, FormField } from "../../../../../appHelper/formApi/FormAPI";

const classes = {
  footer: {
    background: appColor?.App_Dark_Bluewood,
    color: appColor?.App_Light_Color,
    width: "100% !important",
  },
  companyName: {
    borderBottom: `1px solid ${appColor.App_Light_Color}`,
    // paddingBottom:"5px"
  },
  copyright: {
    background: appColor?.App_Dark_Color,
    color: "#fff",
  },
  nav: {
    lineHeight: 1,
  },
};

function Footer({ lang, appState }) {
  const labels = dictionary?.components?.public?.footer;
  const [isLoading, setIsLoading] = useState(false);
  const objContact = appState?.clientInfo?.objSystemInfo?.jsnContact;
  const handlers = CtrlLandingPage(appState, isLoading, setIsLoading);

  const fields = FormData({
    strVisitorEmail: {
      strControl: "text",
      objValidations: { required: true, email: true },
      objLanguage: false,
    },
  });

  function handleSubmit() {
    handlers.addSubscription(fields);
  }
  return (
    <React.Fragment>
      <Container my-0 py-0 sx={classes.footer} id="footer">
        <Container>
          <Grid container spacing={3} justify={"center"}>
            <Grid item xs="12" container justify={""}>
              {!objContact?.strEmail ? null : (
                <Grid item xs="4" container justify={"center"}>
                  <Grid item xs="12" alignSelf="flex-start" mx-auto>
                    <Icon icon="email" color={appColor.App_orang_color} onClick={() => window?.open(`mailto:${objContact?.strEmail}`, "_blank")} />
                  </Grid>
                  <Grid item xs="12">
                    <Typography as="caption">{objContact?.strEmail}</Typography>
                  </Grid>
                </Grid>
              )}
              {!objContact?.strPhoneNumber ? null : (
                <Grid item xs="4" container justify={"center"}>
                  <Grid item alignSelf="flex-start" mx-auto>
                    <Icon icon="phone" color={appColor.App_orang_color} onClick={() => window?.open(`tel:${objContact?.strPhoneNumber}`, "_blank")} />
                  </Grid>
                  <Grid item xs="12">
                    <Typography as="caption">{objContact?.strPhoneNumber}</Typography>
                  </Grid>
                </Grid>
              )}
              {!objContact?.strAddress?.[lang] ? null : (
                <Grid item xs="4" container justify={"center"}>
                  <Grid item xs="12" alignSelf="flex-start" mx-auto>
                    <Icon icon="LocationOn" color={appColor.App_orang_color} />
                  </Grid>
                  <Grid item xs="12">
                    <Typography as="caption">{objContact?.strAddress?.[lang]}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs="12" sx={{ display: "block !important" }}></Grid>
          </Grid>
          <Line />
          <Grid container spacing={3} justify={"space-between"}>
            <Grid item xs="12" md="4" container alignSelf="flex-start" justify={"start"}>
              <Grid item xs="12">
                <Typography as="subtitle2" color={appColor.App_orang_color}>
                  {labels?.about?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12">
                <Typography as="caption" className="block-ellipsis hightFit">
                  {dictionary?.components?.public?.landingPage?.home?.aboutus?.p1?.[lang]}
                </Typography>
                <Nav 
                  label={
                    <Typography as="caption" color={appColor?.App_orang_color} >
                      {dictionary?.shared?.btn?.readMore?.[lang]}
                    </Typography>
                  }
                  path="#aboutus"
                />
              </Grid>
              <Grid item xs="12" container>
                {!objContact?.strLinkedInLink ? null : (
                  <Grid item px-3>
                    <Icon icon="linkedIn" color={appColor.App_orang_color} onClick={() => window?.open(objContact?.strLinkedInLink, "_blank")} />
                  </Grid>
                )}
                {!objContact?.strXLink ? null : (
                  <Grid item px-3>
                    <Icon icon="twitter" color={appColor.App_orang_color} onClick={() => window?.open(objContact?.strXLink, "_blank")} />
                  </Grid>
                )}
                {!objContact?.strFacebookLink ? null : (
                  <Grid item px-3>
                    <Icon icon="facebook" color={appColor.App_orang_color} onClick={() => window?.open(objContact?.strFacebookLink, "_blank")} />
                  </Grid>
                )}
                {!objContact?.strInstgramLink ? null : (
                  <Grid item px-3>
                    <Icon icon="instagram" color={appColor.App_orang_color} onClick={() => window?.open(objContact?.strInstgramLink, "_blank")} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs="12" md="4" lg="2" container alignSelf="flex-start">
              <Grid item xs="12">
                <Typography as="subtitle2" color={appColor.App_orang_color}>
                  {labels?.informed?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12" py-0>
                <Nav sx={classes?.nav} py-0 my-0 label={<Typography as="caption">{dictionary?.components?.public?.landingPage?.home?.association?.title?.[lang]}</Typography>} path="#association" />
              </Grid>
              <Grid item xs="12" py-0>
                <Nav sx={classes?.nav} py-0 my-0 label={<Typography as="caption">{dictionary?.components?.public?.landingPage?.home?.aboutus?.titleVision?.[lang]}</Typography>} path="#aboutus2" />
              </Grid>
              <Grid item xs="12" py-0>
                <Nav sx={classes?.nav} py-0 my-0 label={<Typography as="caption">{dictionary?.components?.public?.landingPage?.home?.aboutus?.titleMission?.[lang]}</Typography>} path="#aboutus2" />
              </Grid>
              <Grid item xs="12" py-0>
                <Nav sx={classes?.nav} py-0 my-0 label={<Typography as="caption">{dictionary?.components?.public?.landingPage?.home?.ourGoal?.title?.[lang]}</Typography>} path="#ourGoal" />
              </Grid>
            </Grid>
            <Grid item xs="12" md="4" container alignSelf="flex-start">
              <Grid item xs="12">
                <Typography as="subtitle2" color={appColor.App_orang_color}>
                  {labels?.newsletter?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12">
                <Typography as="caption">{labels?.newsletterBody?.[lang]}</Typography>
              </Grid>
              <Grid item xs="12" container spacing={0} pt-5>
                <Grid item xs="10" px-0 sx={{ alignSelf: "flex-start !important" }}>
                  <FormField
                    objHandler={fields.strVisitorEmail}
                    icon="email"
                    placeholder={dictionary?.formapi?.strUserEmail?.label?.[lang]}
                    className={`inputLeft inputLeft${lang}`}
                    iconColor={appColor.App_Primary_Color}
                  />
                </Grid>
                <Grid item xs="2" px-0 pt-2 sx={{ alignSelf: "flex-start !important" }}>
                  <Button icon={lang === "arb" ? "arrowBack" : "arrowForward"} py-1 color={appColor?.App_orang_color} onClick={handleSubmit} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Grid container justify="center" my-0 py-0 sx={classes.copyright} pb-xs-10 mb-xs-10>
        <Grid item xs="12" pb-xs-10 mb-xs-10>
          <Typography as="caption">{labels?.copyRight?.[lang]}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Footer;
