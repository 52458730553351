export const LanguagesToIcon = {
  as: "file_type_actionscript.svg",
  ada: "file_type_light_ada.svg",
  prw: "file_type_advpl.svg",
  affect: "file_type_affectscript.svg",
  al: "file_type_al.svg",
  ansible: "file_type_ansible.svg",
  g4: "file_type_antlr.svg",
  any: "file_type_anyscript.svg",
  cls: "file_type_apex.svg",
  apl: "file_type_light_apl.svg",
  applescript: "file_type_applescript.svg",
  adoc: "file_type_asciidoc.svg",
  asp: "file_type_asp.svg",
  ats: "file_type_ats.svg",
  au3: "file_type_autoit.svg",
  avcs: "file_type_avro.svg",
  azcli: "file_type_azure.svg",
  bal: "file_type_ballerina.svg",
  bat: "file_type_bat.svg",
  cmd: "file_type_bat.svg",
  bats: "file_type_bats.svg",
  bzl: "file_type_bazel.svg",
  bf: "file_type_befunge.svg",
  bicep: "file_type_bicep.svg",
  bib: "file_type_light_tex.svg",
  biml: "file_type_biml.svg",
  "blade.php": "file_type_blade.svg",
  blitzbasic: "file_type_blitzbasic.svg",
  bolt: "file_type_bolt.svg",
  bsq: "file_type_bosque.svg",
  c: "file_type_c.svg",
  cabal: "file_type_cabal.svg",
  Caddyfile: "file_type_caddy.svg",
  casc: "file_type_casc.svg",
  cddl: "file_type_cddl.svg",
  ceylon: "file_type_ceylon.svg",
  cfc: "file_type_cfc.svg",
  clj: "file_type_clojure.svg",
  cljs: "file_type_clojure.svg",
  cljc: "file_type_clojure.svg",
  cljx: "file_type_clojure.svg",
  clojure: "file_type_clojure.svg",
  edn: "file_type_clojure.svg",
  clojurescript: "file_type_clojurescript.svg",
  cmake: "file_type_cmake.svg",
  cbl: "file_type_cobol.svg",
  coffee: "file_type_coffeescript.svg",
  cson: "file_type_coffeescript.svg",
  confluence: "file_type_confluence.svg",
  ckbk: "file_type_chef_cookbook.svg",
  cpp: "file_type_cpp.svg",
  cc: "file_type_cpp.svg",
  cxx: "file_type_cpp.svg",
  hpp: "file_type_cpp.svg",
  hh: "file_type_cpp.svg",
  hxx: "file_type_cpp.svg",
  h: "file_type_cpp.svg",
  ino: "file_type_cpp.svg",
  inl: "file_type_cpp.svg",
  ipp: "file_type_cpp.svg",
  cr: "file_type_light_crystal.svg",
  cs: "file_type_csharp.svg",
  csx: "file_type_csharp.svg",
  cake: "file_type_csharp.svg",
  css: "file_type_css.svg",
  cu: "file_type_cuda.svg",
  pyx: "file_type_cython.svg",
  dal: "file_type_dal.svg",
  dart: "file_type_dartlang.svg",
  diff: "file_type_diff.svg",
  patch: "file_type_diff.svg",
  rej: "file_type_diff.svg",
  dockerfile: "file_type_docker.svg",
  dtx: "file_type_light_tex.svg",
  env: "file_type_light_config.svg",
  dot: "file_type_dotjs.svg",
  dox: "file_type_doxygen.svg",
  drl: "file_type_drools.svg",
  dust: "file_type_dustjs.svg",
  edge: "file_type_edge.svg",
  eex: "file_type_eex.svg",
  ex: "file_type_elixir.svg",
  elm: "file_type_elm.svg",
  erb: "file_type_erb.svg",
  erl: "file_type_erlang.svg",
  falcon: "file_type_falcon.svg",
  fs: "file_type_fsharp.svg",
  fsi: "file_type_fsharp.svg",
  fsx: "file_type_fsharp.svg",
  fsscript: "file_type_fsharp.svg",
  fthtml: "file_type_fthtml.svg",
  gspec: "file_type_galen.svg",
  gcode: "file_type_gcode.svg",
  gen: "file_type_genstat.svg",
  glsl: "file_type_glsl.svg",
  glyphs: "file_type_glyphs.svg",
  gp: "file_type_gnuplot.svg",
  go: "file_type_go.svg",
  gql: "file_type_graphql.svg",
  groovy: "file_type_groovy.svg",
  gvy: "file_type_groovy.svg",
  gradle: "file_type_groovy.svg",
  haml: "file_type_haml.svg",
  handlebars: "file_type_handlebars.svg",
  hbs: "file_type_handlebars.svg",
  hjs: "file_type_handlebars.svg",
  prg: "file_type_harbour.svg",
  hs: "file_type_haskell.svg",
  haxe: "file_type_haxe.svg",
  "helm.tpl": "file_type_helm.svg",
  hjson: "file_type_light_hjson.svg",
  hlsl: "file_type_hlsl.svg",
  hlsli: "file_type_hlsl.svg",
  fx: "file_type_hlsl.svg",
  fxh: "file_type_hlsl.svg",
  vsh: "file_type_hlsl.svg",
  psh: "file_type_hlsl.svg",
  cginc: "file_type_hlsl.svg",
  compute: "file_type_hlsl.svg",
  hosts: "file_type_host.svg",
  html: "file_type_html.svg",
  htm: "file_type_html.svg",
  shtml: "file_type_html.svg",
  xhtml: "file_type_html.svg",
  mdoc: "file_type_html.svg",
  jsp: "file_type_html.svg",
  aspx: "file_type_html.svg",
  jshtm: "file_type_html.svg",
  volt: "file_type_html.svg",
  ejs: "file_type_html.svg",
  rhtml: "file_type_html.svg",
  http: "file_type_http.svg",
  hy: "file_type_hy.svg",
  hypr: "file_type_hypr.svg",
  icl: "file_type_icl.svg",
  imba: "file_type_imba.svg",
  properties: "file_type_light_ini.svg",
  gitconfig: "file_type_light_ini.svg",
  cfg: "file_type_light_ini.svg",
  conf: "file_type_light_ini.svg",
  ink: "file_type_ink.svg",
  iss: "file_type_innosetup.svg",
  io: "file_type_light_io.svg",
  janet: "file_type_janet.svg",
  java: "file_type_java.svg",
  jav: "file_type_java.svg",
  js: "file_type_light_js.svg",
  es6: "file_type_light_js.svg",
  mjs: "file_type_light_js.svg",
  pac: "file_type_light_js.svg",
  jsx: "file_type_reactjs.svg",
  jekyll: "file_type_jekyll.svg",
  json: "file_type_light_json.svg",
  bowerrc: "file_type_light_json.svg",
  jshintrc: "file_type_light_json.svg",
  jscsrc: "file_type_light_json.svg",
  eslintrc: "file_type_light_json.svg",
  babelrc: "file_type_light_json.svg",
  webmanifest: "file_type_light_json.svg",
  jsonc: "file_type_light_json.svg",
  jsonnet: "file_type_jsonnet.svg",
  json5: "file_type_light_json5.svg",
  jl: "file_type_julia.svg",
  id: "file_type_iodine.svg",
  kv: "file_type_kivy.svg",
  ks: "file_type_kos.svg",
  kt: "file_type_kotlin.svg",
  ".kusto": "file_type_kusto.svg",
  tex: "file_type_light_tex.svg",
  lat: "file_type_latino.svg",
  less: "file_type_less.svg",
  flex: "file_type_lex.svg",
  lisp: "file_type_lisp.svg",
  log: "file_type_log.svg",
  lol: "file_type_lolcode.svg",
  lsl: "file_type_lsl.svg",
  lua: "file_type_lua.svg",
  mk: "file_type_makefile.svg",
  md: "file_type_markdown.svg",
  mdown: "file_type_markdown.svg",
  markdown: "file_type_markdown.svg",
  markdn: "file_type_markdown.svg",
  marko: "file_type_marko.svg",
  mat: "file_type_matlab.svg",
  ms: "file_type_maxscript.svg",
  mdx: "file_type_light_mdx.svg",
  mediawiki: "file_type_mediawiki.svg",
  mel: "file_type_maya.svg",
  "meson.build": "file_type_meson.svg",
  mjml: "file_type_mjml.svg",
  ep: "file_type_mojolicious.svg",
  mongo: "file_type_mongo.svg",
  mson: "file_type_mson.svg",
  ne: "file_type_nearly.svg",
  nim: "file_type_nim.svg",
  nimble: "file_type_nimble.svg",
  nix: "file_type_nix.svg",
  nsi: "file_type_nsi.svg",
  nunjucks: "file_type_nunjucks.svg",
  ml: "file_type_ocaml.svg",
  o3: "file_type_ogone.svg",
  pas: "file_type_delphi.svg",
  pddl: "file_type_pddl.svg",
  pl: "file_type_perl.svg",
  pm: "file_type_perl.svg",
  pod: "file_type_perl.svg",
  t: "file_type_perl.svg",
  PL: "file_type_perl.svg",
  psgi: "file_type_perl.svg",
  p6: "file_type_perl.svg",
  pl6: "file_type_perl.svg",
  pm6: "file_type_perl.svg",
  nqp: "file_type_perl.svg",
  pgsql: "file_type_pgsql.svg",
  php: "file_type_php.svg",
  php4: "file_type_php.svg",
  php5: "file_type_php.svg",
  phtml: "file_type_php.svg",
  ctp: "file_type_php.svg",
  pine: "file_type_pine.svg",
  txt: "file_type_text.svg",
  ddl: "file_type_plsql.svg",
  polymer: "file_type_polymer.svg",
  pony: "file_type_pony.svg",
  pcss: "file_type_postcss.svg",
  ps1: "file_type_powershell.svg",
  psm1: "file_type_powershell.svg",
  psd1: "file_type_powershell.svg",
  pssc: "file_type_powershell.svg",
  psrc: "file_type_powershell.svg",
  prisma: "file_type_light_prisma.svg",
  pro: "file_type_prolog.svg",
  rules: "file_type_prometheus.svg",
  pp: "file_type_puppet.svg",
  purs: "file_type_light_purescript.svg",
  arr: "file_type_pyret.svg",
  py: "file_type_python.svg",
  rpy: "file_type_python.svg",
  pyw: "file_type_python.svg",
  cpy: "file_type_python.svg",
  gyp: "file_type_python.svg",
  gypi: "file_type_python.svg",
  qvs: "file_type_qlikview.svg",
  qml: "file_type_qml.svg",
  qs: "file_type_qsharp.svg",
  r: "file_type_r.svg",
  rhistory: "file_type_r.svg",
  rprofile: "file_type_r.svg",
  rt: "file_type_r.svg",
  rkt: "file_type_racket.svg",
  cshtml: "file_type_razor.svg",
  raml: "file_type_raml.svg",
  re: "file_type_reason.svg",
  red: "file_type_red.svg",
  res: "file_type_rescript.svg",
  rst: "file_type_rest.svg",
  rex: "file_type_rexx.svg",
  tag: "file_type_riot.svg",
  rmd: "file_type_rmd.svg",
  robot: "file_type_robotframework.svg",
  rb: "file_type_ruby.svg",
  rbx: "file_type_ruby.svg",
  rjs: "file_type_ruby.svg",
  gemspec: "file_type_ruby.svg",
  rake: "file_type_ruby.svg",
  ru: "file_type_ruby.svg",
  rs: "file_type_light_rust.svg",
  san: "file_type_san.svg",
  sbt: "file_type_sbt.svg",
  scad: "file_type_openscad.svg",
  scala: "file_type_scala.svg",
  sce: "file_type_scilab.svg",
  scss: "file_type_scss.svg",
  shader: "file_type_light_shaderlab.svg",
  sh: "file_type_shell.svg",
  bash: "file_type_shell.svg",
  bashrc: "file_type_shell.svg",
  bash_aliases: "file_type_shell.svg",
  bash_profile: "file_type_shell.svg",
  bash_login: "file_type_shell.svg",
  ebuild: "file_type_shell.svg",
  install: "file_type_shell.svg",
  profile: "file_type_shell.svg",
  bash_logout: "file_type_shell.svg",
  zsh: "file_type_shell.svg",
  zshrc: "file_type_shell.svg",
  zprofile: "file_type_shell.svg",
  zlogin: "file_type_shell.svg",
  zlogout: "file_type_shell.svg",
  zshenv: "file_type_shell.svg",
  "zsh-theme": "file_type_shell.svg",
  slang: "file_type_slang.svg",
  ice: "file_type_slice.svg",
  slim: "file_type_slim.svg",
  ss: "file_type_silverstripe.svg",
  tpl: "file_type_smarty.svg",
  snort: "file_type_snort.svg",
  sol: "file_type_light_solidity.svg",
  rq: "file_type_sparql.svg",
  sqf: "file_type_sqf.svg",
  sql: "file_type_sql.svg",
  dsql: "file_type_sql.svg",
  nut: "file_type_squirrel.svg",
  stan: "file_type_stan.svg",
  bazel: "file_type_bazel.svg",
  do: "file_type_stata.svg",
  stencil: "file_type_stencil.svg",
  "st.css": "file_type_stylable.svg",
  styl: "file_type_light_stylus.svg",
  svelte: "file_type_svelte.svg",
  swagger: "file_type_swagger.svg",
  swift: "file_type_swift.svg",
  swig: "file_type_swig.svg",
  sv: "file_type_light_systemverilog.svg",
  tt: "file_type_t4tt.svg",
  teal: "file_type_teal.svg",
  tera: "file_type_tera.svg",
  tf: "file_type_terraform.svg",
  sty: "file_type_light_tex.svg",
  textile: "file_type_textile.svg",
  Tiltfile: "file_type_tiltfile.svg",
  toml: "file_type_light_toml.svg",
  ttcn3: "file_type_ttcn.svg",
  tuc: "file_type_tuc.svg",
  twig: "file_type_twig.svg",
  ts: "file_type_typescript.svg",
  tsx: "file_type_reactts.svg",
  vb: "file_type_vb.svg",
  brs: "file_type_vb.svg",
  vbs: "file_type_vb.svg",
  bas: "file_type_vb.svg",
  wsf: "file_type_script.svg",
  vm: "file_type_velocity.svg",
  v: "file_type_verilog.svg",
  vhdl: "file_type_vhdl.svg",
  vim: "file_type_vim.svg",
  vue: "file_type_vue.svg",
  wasm: "file_type_wasm.svg",
  wy: "file_type_wenyan.svg",
  wl: "file_type_wolfram.svg",
  wurst: "file_type_wurst.svg",
  "xmake.lua": "file_type_xmake.svg",
  xml: "file_type_xml.svg",
  xsd: "file_type_xml.svg",
  ascx: "file_type_xml.svg",
  atom: "file_type_xml.svg",
  axml: "file_type_xml.svg",
  bpmn: "file_type_xml.svg",
  config: "file_type_xml.svg",
  cpt: "file_type_xml.svg",
  csl: "file_type_xml.svg",
  csproj: "file_type_xml.svg",
  "csproj.user": "file_type_xml.svg",
  dita: "file_type_xml.svg",
  ditamap: "file_type_xml.svg",
  dtd: "file_type_xml.svg",
  dtml: "file_type_xml.svg",
  fsproj: "file_type_xml.svg",
  fxml: "file_type_xml.svg",
  iml: "file_type_xml.svg",
  isml: "file_type_xml.svg",
  jmx: "file_type_xml.svg",
  launch: "file_type_xml.svg",
  menu: "file_type_xml.svg",
  mxml: "file_type_xml.svg",
  nuspec: "file_type_xml.svg",
  opml: "file_type_xml.svg",
  owl: "file_type_xml.svg",
  proj: "file_type_xml.svg",
  props: "file_type_xml.svg",
  pt: "file_type_xml.svg",
  publishsettings: "file_type_xml.svg",
  pubxml: "file_type_xml.svg",
  "pubxml.user": "file_type_xml.svg",
  rdf: "file_type_xml.svg",
  rng: "file_type_xml.svg",
  rss: "file_type_xml.svg",
  shproj: "file_type_xml.svg",
  storyboard: "file_type_xml.svg",
  svg: "file_type_xml.svg",
  targets: "file_type_xml.svg",
  tld: "file_type_xml.svg",
  tmx: "file_type_xml.svg",
  vbproj: "file_type_xml.svg",
  "vbproj.user": "file_type_xml.svg",
  vcxproj: "file_type_xml.svg",
  "vcxproj.filters": "file_type_xml.svg",
  wsdl: "file_type_xml.svg",
  wxi: "file_type_xml.svg",
  wxl: "file_type_xml.svg",
  wxs: "file_type_xml.svg",
  xaml: "file_type_xml.svg",
  xbl: "file_type_xml.svg",
  xib: "file_type_xml.svg",
  xlf: "file_type_xml.svg",
  xliff: "file_type_xml.svg",
  xpdl: "file_type_xml.svg",
  xul: "file_type_xml.svg",
  xoml: "file_type_xml.svg",
  xquery: "file_type_xquery.svg",
  xsl: "file_type_xsl.svg",
  xslt: "file_type_xsl.svg",
  bison: "file_type_yacc.svg",
  eyaml: "file_type_light_yaml.svg",
  eyml: "file_type_light_yaml.svg",
  yaml: "file_type_light_yaml.svg",
  yml: "file_type_light_yaml.svg",
  yang: "file_type_yang.svg",
  zig: "file_type_zig.svg",
};
