export const FolderNamesToIcon = {
  android: "folder_type_android.svg",
  api: "folder_type_api.svg",
  ".api": "folder_type_api.svg",
  apis: "folder_type_api.svg",
  ".apis": "folder_type_api.svg",
  app: "folder_type_app.svg",
  ".app": "folder_type_app.svg",
  arangodb: "folder_type_arangodb.svg",
  arango: "folder_type_arangodb.svg",
  assets: "folder_type_asset.svg",
  ".assets": "folder_type_asset.svg",
  audio: "folder_type_audio.svg",
  ".audio": "folder_type_audio.svg",
  audios: "folder_type_audio.svg",
  ".audios": "folder_type_audio.svg",
  sound: "folder_type_audio.svg",
  ".sound": "folder_type_audio.svg",
  sounds: "folder_type_audio.svg",
  ".sounds": "folder_type_audio.svg",
  aurelia_project: "folder_type_aurelia.svg",
  aws: "folder_type_aws.svg",
  ".aws": "folder_type_aws.svg",
  azure: "folder_type_azure.svg",
  ".azure": "folder_type_azure.svg",
  "azure-pipelines": "folder_type_azurepipelines.svg",
  ".azure-pipelines": "folder_type_azurepipelines.svg",
  bin: "folder_type_binary.svg",
  ".bin": "folder_type_binary.svg",
  blocs: "folder_type_bloc.svg",
  bloc: "folder_type_bloc.svg",
  blueprint: "folder_type_blueprint.svg",
  ".blueprint": "folder_type_blueprint.svg",
  blueprints: "folder_type_blueprint.svg",
  ".blueprints": "folder_type_blueprint.svg",
  bower_components: "folder_type_bower.svg",
  ".buildkite": "folder_type_buildkite.svg",
  cake: "folder_type_cake.svg",
  ".cake": "folder_type_cake.svg",
  certificates: "folder_type_certificate.svg",
  ".certificates": "folder_type_certificate.svg",
  certs: "folder_type_certificate.svg",
  "certs.": "folder_type_certificate.svg",
  chef: "folder_type_chef.svg",
  ".chef": "folder_type_chef.svg",
  ".circleci": "folder_type_circleci.svg",
  cli: "folder_type_cli.svg",
  cmd: "folder_type_cli.svg",
  command: "folder_type_cli.svg",
  commands: "folder_type_cli.svg",
  commandline: "folder_type_cli.svg",
  console: "folder_type_cli.svg",
  client: "folder_type_client.svg",
  clients: "folder_type_client.svg",
  ".cmake": "folder_type_cmake.svg",
  cmake: "folder_type_cmake.svg",
  component: "folder_type_component.svg",
  components: "folder_type_component.svg",
  ".components": "folder_type_component.svg",
  widgets: "folder_type_component.svg",
  composer: "folder_type_composer.svg",
  ".composer": "folder_type_composer.svg",
  conf: "folder_type_config.svg",
  ".conf": "folder_type_config.svg",
  config: "folder_type_config.svg",
  ".config": "folder_type_config.svg",
  configs: "folder_type_config.svg",
  ".configs": "folder_type_config.svg",
  configuration: "folder_type_config.svg",
  ".configuration": "folder_type_config.svg",
  configurations: "folder_type_config.svg",
  ".configurations": "folder_type_config.svg",
  setting: "folder_type_config.svg",
  ".setting": "folder_type_config.svg",
  settings: "folder_type_config.svg",
  ".settings": "folder_type_config.svg",
  ini: "folder_type_config.svg",
  ".ini": "folder_type_config.svg",
  initializers: "folder_type_config.svg",
  ".initializers": "folder_type_config.svg",
  controller: "folder_type_controller.svg",
  controllers: "folder_type_controller.svg",
  ".controllers": "folder_type_controller.svg",
  handlers: "folder_type_controller.svg",
  ".handlers": "folder_type_controller.svg",
  coverage: "folder_type_coverage.svg",
  css: "folder_type_css.svg",
  _css: "folder_type_css.svg",
  cubits: "folder_type_cubit.svg",
  cubit: "folder_type_cubit.svg",
  cypress: "folder_type_cypress.svg",
  ".dapr": "folder_type_dapr.svg",
  dapr: "folder_type_dapr.svg",
  db: "folder_type_db.svg",
  database: "folder_type_db.svg",
  sql: "folder_type_db.svg",
  data: "folder_type_db.svg",
  repo: "folder_type_db.svg",
  repository: "folder_type_db.svg",
  repositories: "folder_type_db.svg",
  debian: "folder_type_debian.svg",
  deb: "folder_type_debian.svg",
  ".dependabot": "folder_type_dependabot.svg",
  ".devcontainer": "folder_type_devcontainer.svg",
  dist: "folder_type_dist.svg",
  ".dist": "folder_type_dist.svg",
  dists: "folder_type_dist.svg",
  out: "folder_type_dist.svg",
  outs: "folder_type_dist.svg",
  export: "folder_type_dist.svg",
  exports: "folder_type_dist.svg",
  build: "folder_type_dist.svg",
  ".build": "folder_type_dist.svg",
  builds: "folder_type_dist.svg",
  release: "folder_type_dist.svg",
  releases: "folder_type_dist.svg",
  target: "folder_type_dist.svg",
  targets: "folder_type_dist.svg",
  docker: "folder_type_docker.svg",
  ".docker": "folder_type_docker.svg",
  docs: "folder_type_docs.svg",
  doc: "folder_type_docs.svg",
  e2e: "folder_type_e2e.svg",
  ".elasticbeanstalk": "folder_type_elasticbeanstalk.svg",
  ".ebextensions": "folder_type_elasticbeanstalk.svg",
  electron: "folder_type_light_electron.svg",
  ".expo": "folder_type_light_expo.svg",
  ".expo-shared": "folder_type_light_expo.svg",
  favicon: "folder_type_favicon.svg",
  favicons: "folder_type_favicon.svg",
  flow: "folder_type_flow.svg",
  "flow-typed": "folder_type_flow.svg",
  fonts: "folder_type_light_fonts.svg",
  font: "folder_type_light_fonts.svg",
  fnt: "folder_type_light_fonts.svg",
  gcp: "folder_type_gcp.svg",
  ".gcp": "folder_type_gcp.svg",
  ".git": "folder_type_git.svg",
  submodules: "folder_type_git.svg",
  ".submodules": "folder_type_git.svg",
  ".github": "folder_type_github.svg",
  ".gitlab": "folder_type_gitlab.svg",
  gradle: "folder_type_light_gradle.svg",
  ".gradle": "folder_type_light_gradle.svg",
  graphql: "folder_type_graphql.svg",
  grunt: "folder_type_grunt.svg",
  gulp: "folder_type_gulp.svg",
  "gulpfile.js": "folder_type_gulp.svg",
  "gulpfile.coffee": "folder_type_gulp.svg",
  "gulpfile.ts": "folder_type_gulp.svg",
  "gulpfile.babel.js": "folder_type_gulp.svg",
  "gulpfile.babel.coffee": "folder_type_gulp.svg",
  "gulpfile.babel.ts": "folder_type_gulp.svg",
  ".haxelib": "folder_type_haxelib.svg",
  haxe_libraries: "folder_type_haxelib.svg",
  helper: "folder_type_helper.svg",
  ".helper": "folder_type_helper.svg",
  helpers: "folder_type_helper.svg",
  ".helpers": "folder_type_helper.svg",
  hook: "folder_type_hook.svg",
  ".hook": "folder_type_hook.svg",
  hooks: "folder_type_hook.svg",
  ".hooks": "folder_type_hook.svg",
  ".husky": "folder_type_husky.svg",
  ".idea": "folder_type_idea.svg",
  images: "folder_type_images.svg",
  image: "folder_type_images.svg",
  img: "folder_type_images.svg",
  icons: "folder_type_images.svg",
  icon: "folder_type_images.svg",
  ico: "folder_type_images.svg",
  screenshot: "folder_type_images.svg",
  screenshots: "folder_type_images.svg",
  svg: "folder_type_images.svg",
  include: "folder_type_include.svg",
  includes: "folder_type_include.svg",
  incl: "folder_type_include.svg",
  inc: "folder_type_include.svg",
  ".include": "folder_type_include.svg",
  ".includes": "folder_type_include.svg",
  ".incl": "folder_type_include.svg",
  ".inc": "folder_type_include.svg",
  _include: "folder_type_include.svg",
  _includes: "folder_type_include.svg",
  _incl: "folder_type_include.svg",
  _inc: "folder_type_include.svg",
  interface: "folder_type_interfaces.svg",
  interfaces: "folder_type_interfaces.svg",
  ios: "folder_type_ios.svg",
  js: "folder_type_js.svg",
  json: "folder_type_json.svg",
  kubernetes: "folder_type_kubernetes.svg",
  k8s: "folder_type_kubernetes.svg",
  kube: "folder_type_kubernetes.svg",
  kuber: "folder_type_kubernetes.svg",
  ".kubernetes": "folder_type_kubernetes.svg",
  ".k8s": "folder_type_kubernetes.svg",
  ".kube": "folder_type_kubernetes.svg",
  ".kuber": "folder_type_kubernetes.svg",
  less: "folder_type_less.svg",
  _less: "folder_type_less.svg",
  lib: "folder_type_library.svg",
  libs: "folder_type_library.svg",
  ".lib": "folder_type_library.svg",
  ".libs": "folder_type_library.svg",
  library: "folder_type_library.svg",
  libraries: "folder_type_library.svg",
  linux: "folder_type_linux.svg",
  lang: "folder_type_locale.svg",
  language: "folder_type_locale.svg",
  languages: "folder_type_locale.svg",
  locale: "folder_type_locale.svg",
  locales: "folder_type_locale.svg",
  _locale: "folder_type_locale.svg",
  _locales: "folder_type_locale.svg",
  internationalization: "folder_type_locale.svg",
  globalization: "folder_type_locale.svg",
  localization: "folder_type_locale.svg",
  i18n: "folder_type_locale.svg",
  g11n: "folder_type_locale.svg",
  l10n: "folder_type_locale.svg",
  log: "folder_type_log.svg",
  logs: "folder_type_log.svg",
  macos: "folder_type_macos.svg",
  darwin: "folder_type_macos.svg",
  mariadb: "folder_type_mariadb.svg",
  maria: "folder_type_mariadb.svg",
  ".mvn": "folder_type_maven.svg",
  memcached: "folder_type_memcached.svg",
  ".memcached": "folder_type_memcached.svg",
  ".meteor": "folder_type_light_meteor.svg",
  middleware: "folder_type_middleware.svg",
  middlewares: "folder_type_middleware.svg",
  minikube: "folder_type_minikube.svg",
  minik8s: "folder_type_minikube.svg",
  minikuber: "folder_type_minikube.svg",
  mjml: "folder_type_mjml.svg",
  ".mjml": "folder_type_mjml.svg",
  mocks: "folder_type_mock.svg",
  ".mocks": "folder_type_mock.svg",
  __mocks__: "folder_type_mock.svg",
  model: "folder_type_model.svg",
  ".model": "folder_type_model.svg",
  models: "folder_type_model.svg",
  ".models": "folder_type_model.svg",
  entities: "folder_type_model.svg",
  ".entities": "folder_type_model.svg",
  modules: "folder_type_module.svg",
  mongodb: "folder_type_mongodb.svg",
  mongo: "folder_type_mongodb.svg",
  mysqldb: "folder_type_light_mysql.svg",
  mysql: "folder_type_light_mysql.svg",
  ".next": "folder_type_next.svg",
  nginx: "folder_type_nginx.svg",
  "conf.d": "folder_type_nginx.svg",
  node_modules: "folder_type_light_node.svg",
  notification: "folder_type_notification.svg",
  notifications: "folder_type_notification.svg",
  event: "folder_type_notification.svg",
  events: "folder_type_notification.svg",
  ".nuget": "folder_type_nuget.svg",
  ".nuxt": "folder_type_nuxt.svg",
  package: "folder_type_package.svg",
  packages: "folder_type_package.svg",
  ".package": "folder_type_package.svg",
  ".packages": "folder_type_package.svg",
  pkg: "folder_type_package.svg",
  ".paket": "folder_type_paket.svg",
  php: "folder_type_php.svg",
  ".pio": "folder_type_platformio.svg",
  ".pioenvs": "folder_type_platformio.svg",
  plugin: "folder_type_plugin.svg",
  ".plugin": "folder_type_plugin.svg",
  plugins: "folder_type_plugin.svg",
  ".plugins": "folder_type_plugin.svg",
  extension: "folder_type_plugin.svg",
  ".extension": "folder_type_plugin.svg",
  extensions: "folder_type_plugin.svg",
  ".extensions": "folder_type_plugin.svg",
  private: "folder_type_private.svg",
  ".private": "folder_type_private.svg",
  public: "folder_type_public.svg",
  ".public": "folder_type_public.svg",
  ".venv": "folder_type_python.svg",
  ".virtualenv": "folder_type_python.svg",
  ravendb: "folder_type_ravendb.svg",
  redis: "folder_type_redis.svg",
  redux: "folder_type_light_redux.svg",
  route: "folder_type_route.svg",
  routes: "folder_type_route.svg",
  _route: "folder_type_route.svg",
  _routes: "folder_type_route.svg",
  routers: "folder_type_route.svg",
  sass: "folder_type_light_sass.svg",
  scss: "folder_type_light_sass.svg",
  _sass: "folder_type_light_sass.svg",
  _scss: "folder_type_light_sass.svg",
  script: "folder_type_script.svg",
  scripts: "folder_type_script.svg",
  server: "folder_type_server.svg",
  service: "folder_type_services.svg",
  services: "folder_type_services.svg",
  src: "folder_type_src.svg",
  source: "folder_type_src.svg",
  sources: "folder_type_src.svg",
  sso: "folder_type_sso.svg",
  story: "folder_type_story.svg",
  stories: "folder_type_story.svg",
  __stories__: "folder_type_story.svg",
  ".storybook": "folder_type_story.svg",
  style: "folder_type_style.svg",
  styles: "folder_type_style.svg",
  temp: "folder_type_temp.svg",
  ".temp": "folder_type_temp.svg",
  tmp: "folder_type_temp.svg",
  ".tmp": "folder_type_temp.svg",
  template: "folder_type_template.svg",
  ".template": "folder_type_template.svg",
  templates: "folder_type_template.svg",
  ".templates": "folder_type_template.svg",
  tests: "folder_type_test.svg",
  ".tests": "folder_type_test.svg",
  test: "folder_type_test.svg",
  ".test": "folder_type_test.svg",
  __tests__: "folder_type_test.svg",
  __test__: "folder_type_test.svg",
  spec: "folder_type_test.svg",
  ".spec": "folder_type_test.svg",
  specs: "folder_type_test.svg",
  ".specs": "folder_type_test.svg",
  integration: "folder_type_test.svg",
  theme: "folder_type_theme.svg",
  themes: "folder_type_theme.svg",
  tool: "folder_type_tools.svg",
  tools: "folder_type_tools.svg",
  ".tools": "folder_type_tools.svg",
  util: "folder_type_tools.svg",
  utils: "folder_type_tools.svg",
  ".travis": "folder_type_travis.svg",
  typescript: "folder_type_typescript.svg",
  ts: "folder_type_typescript.svg",
  typings: "folder_type_typings.svg",
  "@types": "folder_type_typings.svg",
  vagrant: "folder_type_vagrant.svg",
  ".vagrant": "folder_type_vagrant.svg",
  video: "folder_type_video.svg",
  ".video": "folder_type_video.svg",
  videos: "folder_type_video.svg",
  ".videos": "folder_type_video.svg",
  html: "folder_type_view.svg",
  view: "folder_type_view.svg",
  views: "folder_type_view.svg",
  layout: "folder_type_view.svg",
  layouts: "folder_type_view.svg",
  page: "folder_type_view.svg",
  pages: "folder_type_view.svg",
  _view: "folder_type_view.svg",
  _views: "folder_type_view.svg",
  _layout: "folder_type_view.svg",
  _layouts: "folder_type_view.svg",
  _page: "folder_type_view.svg",
  _pages: "folder_type_view.svg",
  ".vs": "folder_type_vs.svg",
  ".vscode": "folder_type_vscode.svg",
  vscode: "folder_type_vscode.svg",
  ".vscode-test": "folder_type_vscode_test.svg",
  webpack: "folder_type_webpack.svg",
  windows: "folder_type_windows.svg",
  win32: "folder_type_windows.svg",
  www: "folder_type_www.svg",
  wwwroot: "folder_type_www.svg",
};
