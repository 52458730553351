import { Icon } from "@cbmisorg/icons";
import { Grid, Typography, Paper, Button } from "@cbmisorg/material";
import React from "react";
import { App_info_Color, appStyle } from "../../../../../../util/appStyle";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { useNavigate } from "@cbmisorg/router";
import ServicesCard from "./ServicesCard";

const labels = dictionary?.components?.stakeholders?.member?.account?.services;
function ServicesView({ isLoading, setIsLoading, parentState, classes, lang }) {
 
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs="12">
          <Typography as="subtitle1" className={classes.mainTitle}>
            {labels?.title?.[lang]}
          </Typography>
        </Grid>
        {parentState?.lstServices?.map((service) => {
          return (
            <Grid item xs={12} sm="6" md="4" lg="3" key={service?.id}>
             <ServicesCard service={service} classes={classes} lang={lang}/>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default ServicesView;
