import React, { useEffect, useRef } from "react";
import "./Modal.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Modal = (props) => {
  const { open, eventClose, size, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Modal"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [open, size, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (styleRef.current.strSxClassName) {
      if (open) {
        //const container = document.querySelector("." + styleRef.current.strSxClassName);
        const container = document.getElementById(getID.current);
        const closeBtn = container.querySelectorAll(`.cbmis-modal-close-btn`);

        if (closeBtn.length > 0) {
          document.querySelector(`#${getID.current} .cbmis-modal-close-btn`).addEventListener("click", () => {
            eventClose();
          });
        }
      }
    }
  }, [styleRef.current.strSxClassName, eventClose]);

  useEffect(() => {
    setSxClassName(styleRef, getID.current, sx, "cbmis-modal-container");
  }, [sx, screenWidth]);

  const getSizeClass = () => {
    switch (size) {
      case "xl":
        return "cbmis-modal-xl";
      case "lg":
        return "cbmis-modal-lg";

      case "md":
        return "cbmis-modal-md";

      case "sm":
        return "cbmis-modal-sm";

      case "xs":
        return "cbmis-modal-xs";
      case "fluid":
        return "cbmis-modal-fluid";

      case "half":
        return "cbmis-modal-half";

      default:
        return "cbmis-modal-lg";
    }
  };

  const sizeClass = useRef(getSizeClass());

  return open ? (
    <div id={getID.current} className={`cbmis-modal-container`} tabIndex={-1} {...getRestProps()}>
      <div className="cbmis-modal-out" onClick={eventClose}></div>
      <div
        className={`cbmis-modal ${sizeClass.current} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
          disabled
        )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      >
        <div className="cbmis-modal-details">{props.children}</div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default Modal;
