import React from 'react'
import {Avatar, Grid,Nav,Typography} from "@cbmisorg/client-app"
import "./card.css"
import * as appVariables from "../../../../../appHelper/appVariables"
import barCode from '../../../../../assets/images/barCode.png'
import profilefemail from '../../../../../assets/images/profilefemail.jpg'
import profilemail from '../../../../../assets/images/profilemail.jpg'
import logo from '../../../../../assets/images/logo.png'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary'

function AdvisorCard({userInfo,lang="arb"}) {
  return (
    <React.Fragment>
       <div className="rootAdvisorCard">
           <Grid container pt-10 justify={"center"} spacing="0">
                <Grid item xs="12" pt-10>
                    <Typography as="subtitle2" color={"primary"} pt-3>{dictionary?.components?.public?.appName?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12">
                    <Typography as="body2" >
                        {userInfo?.jsnUserInfo?.blnIsFound
                        ?dictionary?.components?.account?.founder?.[lang]
                        :userInfo?.jsnUserInfo?.intUserRoleFK===appVariables.objUserRole?.member
                        ? dictionary?.components?.account?.member?.[lang]
                        :dictionary?.components?.account?.employee?.[lang]
                        }
                    </Typography>
                </Grid>
                <Grid item xs="12" pt-5>
                    <div className="divAvatar">
                        <img className="largeAvatar" src={userInfo?.jsnUserInfo?.strImg? appVariables.App_Server_Url_DownloadFiles + userInfo?.jsnUserInfo?.strImg : userInfo?.jsnUserInfo?.bigGender === appVariables?.genderID?.female? profilefemail: profilemail} alt=""/>
                    </div>
                </Grid>
                <Grid item xs="12" pt-1>
                    <Typography className="EmployeeName">
                        <span className="name">{userInfo?.jsnUserInfo?.strFullName?.[lang] || userInfo?.jsnUserInfo?.strFullName}</span>{" "}
                    </Typography>
                </Grid>
                <Grid item xs="12">
                    <Typography as="caption">{appVariables?.employmentStatusIDlabel?.[userInfo?.jsnUserInfo?.bigEmploymentID]?.[lang]}</Typography>
                </Grid>
                <Grid item xs="12">
                    <Typography as="caption" color={"primary"}>
                        {userInfo?.jsnUserInfo?.blnIsFound? userInfo?.jsnUserInfo?.strPosition?.[lang]: userInfo?.jsnUserInfo?.strWorkPlace || userInfo?.jsnUserInfo?.strPosition?.[lang]}
                    </Typography>
                </Grid>

                {
                 userInfo?.jsnUserInfo?.intUserRoleFK === appVariables?.objUserRole?.member?(
                    <Grid item xs="12" py-3>
                        <Typography  as={"caption"} className="activeLabel">
                            <span>{dictionary?.components?.account?.dtmEnd?.[lang]} : {userInfo?.jsnPaySubscribed?.dtmSubscribedDeactivate}</span>
                        </Typography>
                    </Grid>
                    ):(
                        <Grid item xs="12" py-3>
                           <Avatar src={logo} />
                        </Grid>
                        )
                }
           </Grid>

           <Grid container justify={'center'} sx={{position:"absolute",bottom:"23px"}}>
            <Grid item xs="12" pb-3>
                <div >
                    <img className="image" src={barCode} alt="" style={{width:"auto",height:"50px"}}/>
                </div>
            </Grid>
            <Grid item xs="12"  dir="ltr">
                <Nav path="http://www.aaiot.org/" target='_blank'>
                    <Typography as="caption" pt-0 color="#fff">http://www.aaiot.org/</Typography>
                </Nav>
            </Grid>
           </Grid>

          
       </div>
    </React.Fragment>
  )
}

export default AdvisorCard