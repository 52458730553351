import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./Color.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Icon from "../../../icon/CbmisIcon";

const Color = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,
          color,icon,iconColor,adornment,
          value,defaultValue,onChange,readOnly=false,
          sx,hidden,disabled,dir,className,...rest} = props

    const inputRef = useRef({})
    const [inputValue, setInputValue] = useState({
        value: 0,
      });

    const screenWidth = useCheckScreenSize();
    const nameID = useRef(id || componentsFunction.randomName("Color"));
    const getID = useRef(componentsFunction.randomName("Color"));
    const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-color-input-custom")});

    //#region Effect
        useEffect(()=>{
            const theColor = componentsFunction.checkColorReturnValue(value || defaultValue || "#1769aa")
            inputRef.current.value = theColor
            if(ref){
                ref.current.value =  theColor;
            }
            setInputValue({
                value: theColor
              })
        },[value, defaultValue])

        useEffect(()=>{
        },[id,label,placeholder,errorMessage,
            color,icon,iconColor,adornment,
            value,defaultValue,readOnly,
            sx,hidden,disabled,dir,className,rest,
            ])

    //#endregion

    //#region style

        useEffect(() => {
        if (sx) {
            setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-color-input-custom");
        }
        }, [sx, screenWidth]);

        const getRestProps = () => {
        const newRest = componentsFunction.filterPaddingAndMargin(rest);
        return { ...newRest };
        };
    //#endregion

    //#region change
    const handelChange =(event)=>{
        inputRef.current.value=event.target.value;
        if(ref){
            ref.current.value = event.target.value;
        }
        if(onChange){
            onChange(event)
        }
        setInputValue({
            value: event.target.value
        })
        }
    //#endregion

    return (
        <React.Suspense fallback="">
            <div id={getID.current}
                className={`cbmis-main-container
                            ${icon || adornment?"cbmis-input-haveIcon":""} 
                            ${componentsFunction.CheckHiddenItem(hidden) || ""}`}

                {...getRestProps()}
            >
            <div className={componentsFunction.CheckDirectionItem(dir)}>
                <div className={`cbmis-input-container 
                                ${componentsFunction.CheckDisabledItem(disabled)} 
                                ${errorMessage?"cbmis-input-have-error":""}
                            `}
                            >
                {icon?
                    <div className="cbmis-radio-checkbox-icon-container" >
                    <Icon icon= {icon} color={errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}/>
                    </div>
                    :adornment?
                    <div className="cbmis-radio-checkbox-adornment-container" style={{color:errorMessage?"error":iconColor?componentsFunction.checkColorReturnValue(iconColor):""}} >
                        <label>{adornment}</label>
                    </div>
                    :""
                    }
                    <div className={icon ? "cbmis-checkbox-haveIcon" : ""}>
                    {label?.default || label?
                        <label className="cbmis-radio-checkbox-label" htmlFor={`${nameID.current}`}>
                            {label?.default || label}
                        </label>
                    :""}
                    </div>
                    <div id={`custom-${nameID}`} className={`cbmis-color-input-custom ${styleRef.current.strSxClassName} ${readOnly?"":"canHover"}`} style={{background:componentsFunction.checkColorReturnValue(inputValue.value || "#1769aa")}}>
                        {
                            readOnly?null:(
                                <input type="color"
                                    id={nameID}
                                    name={nameID}
                                    ref={ref || inputRef}
                                    className="cbmis-color-input"
                                    onChange={handelChange}
                                    {...getRestProps()}
                                />
                            )
                        }
                    </div>
                </div>
                <ErrorMessage errorMessage={errorMessage} />
            </div>
            </div>
        </React.Suspense>
        )
});

export default Color