import { Alert } from "@cbmisorg/material";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import { alertStyle } from "../../../../../../util/appStyle";
const userApi = generateQueries("tblUser");
const statisticApi = generateQueries("tblStatistic");

const strPageInfo = "@src/client/component/shared/account/components/MDEASetterModal/MDEASetterModal.controller.js";

export default function MDEASetterModalCtrl({ appState, appDispatch, isLoading, setIsLoading }) {
  const lang = appState?.clientInfo?.strLanguage;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const strUserTrainingTypeCode = appState?.userInfo?.strTrainingTypeCode;

  const handler = {
    openModal:
    ({ state, setState, fields }) =>
      async () => {
        try {
          fields.controller.resetForm();
          appFunctions.modalOverFllow(true);

          let lstCountryOption = [];
          let lstDegreeOption = [];
          let lstEmploymentOption = [];
          let lstMajorOption = [];
          let lstGenderOption = [];

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstCountryOption.push({ key: value?.id, value: value?.nationality?.value });
            }
          });
          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (String(value?.id) === String(objCat?.Root?.degree?.master?.id) || String(value?.id) === String(objCat?.Root?.degree?.PHD?.id)) {
              lstDegreeOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigDegreeID.setOptions([...lstDegreeOption]);
          fields.bigCountryOfCitizenID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);

          state.blnIsNew = !appState?.userInfo?.blnIsMDEA;
          if (appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID) {
            fields.bigCountryOfCitizenID.setValue(appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID);
            handler.onChangeCountry({ fields })({ target: { id: appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID } });
          } else {
            fields.bigCountryOfCitizenID.setValue(objCat?.Root?.country?.kuwait?.id);
            handler.onChangeCountry({ fields })({ target: { id: objCat?.Root?.country?.kuwait?.id } });
          }
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigDegreeID && (String(appState?.userInfo?.jsnUserInfo?.bigDegreeID) === String(objCat?.Root?.degree?.master?.id) || String(appState?.userInfo?.jsnUserInfo?.bigDegreeID) === String(objCat?.Root?.degree?.PHD?.id))) {
            fields.bigDegreeID.setValue(appState?.userInfo?.jsnUserInfo?.bigDegreeID);
          }

          state.lstResume = Array.isArray(appState?.userInfo?.jsnUserInfo?.lstResume) ? appState?.userInfo?.jsnUserInfo?.lstResume : [];

          state.blnOpenSetterModal = true;
          setState({ ...state });
        } catch (error) {
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },

    onChangeCountry:
      ({ fields }) =>
      (event) => {
        try {
          const bigCountryID = event?.target?.id;

          // const objCat = appFunctions.getCategoryCache();

          let lstUniversityOption = [];
          Object.entries(objCat?.Root?.country?.[appFunctions.appCategoryGetKey(objCat?.Root?.country, bigCountryID)]?.university || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstUniversityOption.push({ key: value?.id, value: value?.value });
            }
          });
          lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });

          fields.strUniversityName.setOptions(lstUniversityOption, true);
          if (lstUniversityOption?.length) {
            fields.strUniversityName.setValue(lstUniversityOption?.[0]);
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "onChangeCountry-ERROR", error);
        }
      },

    updateMDEAInfo:
      ({ state, setState, fields, uploaderResume }) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          setIsLoading(true);

          if (
            !state?.lstResume?.length) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.resumeUploadFile?.[lang], "error", appLangDirection?.[lang], alertStyle);
            setIsLoading(false);
            return;
          }
          if (state?.lstResume?.length) {
            const resultUpload = await uploaderResume?.uploadHandler("lstResume");
            if (!resultUpload) {
              setIsLoading(false);
              return;
            }
          }
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add user

          if (!appState?.clientInfo?.blnIsUserLoggedIn) {
            const result = await userApi(strPageInfo, appState, "aaiotItcUserAdd", {
              objectInput: {
                bigAppID: appVariables.App_ID,
                bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
                bigUserID: appFunctions?.generateID(10),

                strUserEmail: fields?.strUserEmail?.getValue(),
                strUserPassword: fields?.strUserPassword?.getValue(),
                strTrainingTypeCode: "kw",

                jsnUserInfo: {
                  strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
                  intUserRoleFK: appVariables?.objUserRole?.Member,
                  bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
                  bigCountryOfResidenceID: appState?.userInfo?.jsnUserInfo?.bigCountryOfResidenceID || fields?.bigCountryOfCitizenID?.getValue()?.key,
                  strMajor: fields?.bigMajorID?.getValue()?.key,
                  bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
                  lstResume: state?.lstResume,
                  dtmRegisterMDEA: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
                },
                blnIsMDEA: true,

                lstStatisticFelid: ["intCountJoinUser", "intCountJoinUserAll", "intCountJoinMDEA", "intCountJoinMDEAAll"], // count
              },
            });

            appFunctions.modalOverFllow();
            appState.clientInfo.blnIsUserLoggedIn = true;
            appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
            appState.userInfo = result.response;

            state.blnOpenSetterModal = false;
            appDispatch();

            setState({ ...state });
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.updated_innfo_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
            return;
          }

          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region update user
          const objectInput = {
            jsnUserInfo: {
              ...appState?.userInfo?.jsnUserInfo,
              strFullName: fields?.strFullName?.getValue(),
              bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
              bigCountryOfResidenceID: appState?.userInfo?.jsnUserInfo?.bigCountryOfResidenceID || fields?.bigCountryOfCitizenID?.getValue()?.key,
              strMajor: fields?.bigMajorID?.getValue()?.key,
              bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
              lstResume: state?.lstResume,
            },
            blnIsMDEA: true,
            dtmUpdatedDate: new Date(),
          };
          if (!appState?.userInfo?.blnIsMDEA) {
            objectInput.jsnUserInfo.dtmRegisterMDEA = appFunctions?.getDateUSFormat(appFunctions.getCurrentDate());
          }
          const resultUser = await userApi(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
            },
            objectInput: objectInput,
          });

          if (!resultUser?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cant_Update_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

     
            appState.userInfo.blnIsMDEA = true;
          

          appState.userInfo.jsnUserInfo = {
            ...appState?.userInfo?.jsnUserInfo,
            strFullName: fields?.strFullName?.getValue(),
            bigCountryOfCitizenID: fields?.bigCountryOfCitizenID?.getValue()?.key,
            bigCountryOfResidenceID: appState?.userInfo?.jsnUserInfo?.bigCountryOfResidenceID || fields?.bigCountryOfCitizenID?.getValue()?.key,
            strMajor: fields?.bigMajorID?.getValue()?.key,
            bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
            lstResume: state?.lstResume,
            dtmRegisterMDEA: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
          };
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region statistics update
          let arrAttributes = [];
            if (state?.blnIsNew) { // count
              arrAttributes.push("intCountJoinMDEAAll"); 
              arrAttributes.push("intCountJoinMDEA");
            }
          if (arrAttributes?.length) {
            await statisticApi(strPageInfo, appState, "aaiotAppIncDecInt", {
              objectCondition: {
                bigSystemID: appVariables.aaiot_System_ID,
              },
              arrAttributes: arrAttributes,
            });
          }
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          state.blnOpenSetterModal = false;
          appFunctions.modalOverFllow();
          appDispatch();
          setState({ ...state });
          setIsLoading(false);
          Alert.viewAlert(dictionary.shared.alertFetchNote.updated_innfo_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } catch (error) {
          appFunctions.logError(strPageInfo, "updateMDEAInfo-ERROR", error);
          setIsLoading(false);
        }
      },

    handelChangeEmployment:
      ({ fields }) =>
      (event) => {
        try {
          const employmentValue = String(event?.target?.id); //fields.bigEmploymentID?.getValue()?.key;

          fields.bigWorkSector?.setError("");
          fields.strUniversityName?.setError("");

          if (String(objCat?.Root?.employment?.unemployed?.id) === employmentValue) {
            fields?.strUniversityName?.setValidation("required", false, true);
            fields?.bigWorkSector?.setValidation("required", false, true);
          }
          if (String(objCat?.Root?.employment?.student?.id) === employmentValue) {
            fields?.strUniversityName?.setValidation("required", true, true);
            fields?.bigWorkSector?.setValidation("required", false, true);
          } else {
            fields?.bigWorkSector?.setValidation("required", true, true);
            fields?.strUniversityName?.setValidation("required", false, true);
          }
        } catch (error) {
          appFunctions.logError(strPageInfo, "handelChangeEmployment-ERROR", error);
        }
      },

    objCat: objCat,
  };
  return handler;
}
