import React, { useEffect, useRef } from "react";
import "./Table.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

function TableCell(props) {
  const { headLabel, rowSpan, colSpan, sx, hidden, disabled, dir, className, id, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("TableCell"));
  const styleRef = useRef({ strSxClassName: "" });

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [headLabel, rowSpan, colSpan, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, getID.current, sx, "cbmis-table-cell");
    }
  }, [sx, screenWidth]);

  return (
    <td
      id={getID.current}
      data-label={headLabel}
      rowSpan={rowSpan}
      colSpan={colSpan}
      className={`cbmis-table-cell ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
        hidden
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
      {...getRestProps()}
    >
      {props.children}
    </td>
  );
}

export default TableCell;
