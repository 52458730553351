import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./Rating.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import RatingRadioInput from "./RatingRadioInput";

const Rating = forwardRef((props, ref) => {
  const {id,label,errorMessage,
        color,icon,iconSize,
        value,defaultValue,onChange, readOnly = false,
        sx,hidden,disabled,dir,className,...rest
      } = props

  const inputRef = useRef({})
  const screenWidth = useCheckScreenSize();
  const nameID = useRef(id || componentsFunction.randomName("Rating"));
  const getID = useRef(componentsFunction.randomName("Rating"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-input-rating-owner")});
  const [inputValue, setInputValue] = useState({
    value: 0,
  });

  //#region Effect
   useEffect(()=>{
    inputRef.current.value = parseInt(value || defaultValue || 0)
    if(ref){
      ref.current.value = parseInt(value || defaultValue || 0)
    }
    setInputValue({
      value: parseInt(value || defaultValue || 0)
    })
  },[value, defaultValue])

  useEffect(()=>{
  },[id,label,errorMessage,
      color,icon,iconSize,
      value,defaultValue,onChange, readOnly,
      sx,hidden,disabled,dir,className,rest
    ])

  //#endregion

  //#region style
    useEffect(() => {
      if (sx) {
        setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-input-rating-owner");
      }
    }, [sx, screenWidth]);

    useEffect(() => {
      var root = document.querySelector(':root');
      root.style.setProperty('--cbmis-rating-active-color', componentsFunction.checkColorReturnValue(color || "primary"));
   }, [color]);

    const getRestProps = () => {
      const newRest = componentsFunction.filterPaddingAndMargin(rest);
      return { ...newRest };
    };
  //#endregion

    //#region change
    const handelChange =(event)=>{
      const id = String(event.target.id).split("-")
      const value = parseInt(id[0].charAt(id[0].length - 1))

      inputRef.current.value=value;
      if(ref){
        ref.current.value = value;
      }
      if(onChange){
        onChange(event)
      }
      setInputValue({
        value: value
      })

    }
  //#endregion


return (
    <React.Suspense fallback="">
      <div
        id={getID.current}
        className={`cbmis-main-container  ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(
          rest
        )} ${componentsFunction.checkExistsPadding(rest)} ${className || ""}`}
        {...getRestProps()}
      >
        <div className={componentsFunction.CheckDirectionItem(dir)}>
          <div className={`cbmis-input-container ${componentsFunction.CheckDisabledItem(disabled)} ${errorMessage ? "cbmis-input-have-error" : ""}`}>
            {label?.default || label?
              <label className="cbmis-radio-checkbox-label" htmlFor={`${nameID.current}`}>
                  {label?.default || label}
              </label>
            :""}
            <div className={`cbmis-rating`} id={styleRef.current.strSXComponentID}>
              <RatingRadioInput
                  name={nameID.current}
                  id={`star5-${nameID.current}`}
                  value={5}
                  ref={ref || inputRef}
                  readOnly={readOnly}
                  icon={icon}
                  iconSize={iconSize}
                  checked={inputValue.value === 5 ?true: false}
                  onChange={handelChange}
                />
                <RatingRadioInput
                  name={nameID.current}
                  id={`star4-${nameID.current}`}
                  value={4}
                  ref={ref || inputRef}
                  readOnly={readOnly}
                  icon={icon} 
                  iconSize={iconSize}
                  checked={inputValue.value === 4 ?true: false}
                  onChange={handelChange}
                />
                <RatingRadioInput
                  name={nameID.current}
                  id={`star3-${nameID.current}`}
                  value={3}
                  ref={ref || inputRef}
                  readOnly={readOnly}
                  icon={icon} 
                  iconSize={iconSize}
                  checked={inputValue.value === 3 ?true: false}
                  onChange={handelChange}
                />
                <RatingRadioInput
                  name={nameID.current}
                  id={`star2-${nameID.current}`}
                  value={2}
                  ref={ref || inputRef}
                  readOnly={readOnly}
                  icon={icon} 
                  iconSize={iconSize}
                  checked={inputValue.value === 2 ?true: false}
                  onChange={handelChange}
                />
                <RatingRadioInput
                  name={nameID.current}
                  id={`star1-${nameID.current}`}
                  value={1}
                  ref={ref || inputRef}
                  readOnly={readOnly}
                  icon={icon} 
                  iconSize={iconSize}
                  checked={inputValue.value === 1 ?true: false}
                  onChange={handelChange}
                />
            </div>
          </div>
          <ErrorMessage errorMessage={errorMessage} />
        </div>
      </div>
    </React.Suspense>
  )
});

export default Rating