export const App_Primary_Color = "#0371a0";
export const App_Primary_Opacity_Color = "rgb(3 113 160 / 30%)";
export const App_info_Color = "#02FEFE";
export const App_info_Opacity_Color = "rgb(2 254 254 / 10%)";
export const App_info_Opacity_Color1 = "rgb(2 254 254 / 30%)";
export const App_info_Opacity_Color2 = "rgb(2 254 254 / 90%)";
export const App_Second_Color = "#0496be";
export const App_Second_Opacity_Color = "rgb(4 150 190 / 90%)";
export const App_Dark_Color = "#01021B";
export const App_Dark_Opacity_Color = "rgba(1, 2, 27, 0.85)";
export const App_Light_Color = "#fefefe";
export const App_Light_Text_Color = "#eaeff3";
export const App_Dark_Text_Color = "#CEE1EA";
export const App_Input_Text_Color = "#eaeff3";
export const App_Dark_menu_Color = "#011C35";

export const App_Gray_Color = "#989898";


export const App_Light_purple = "#E5D1FA";
export const App_Light_blue = "#def0ff";
export const App_Light_Bluewood = "#657990";
export const App_Light_green = "#b9F3E4";
export const App_Light_yellow = "#FFF4D2";
export const App_Light_orange = "#FFD1DA";

export const App_Dark_purple = "#6b0b60";
export const App_Dark_blue = "#044d88";
export const App_Dark_Bluewood = "#2B3C4F";
export const App_Dark_green = "#027a5a";
export const App_Dark_yellow = "#ffd24c";
export const App_Dark_orange = "#ff365e";

export const App_Light_Gray = "#fefefe";

// 2f3640 
// 414b57
export const App_Orang_Color = "#EC9403";
export const App_Orang_Light_Color = "#fce5bf";

export const fontsChanga = {
  fontFamily: "'Changa', 'Mada', 'Handjet', sans-serif !important",
  fontOpticalSizing: "auto",
  fontWeight: "400",
  fontStyle: "normal",
  fontVariationSettings: "'ELGR' 1, 'ELSH' 2",
};

export const fontsMada = {
  fontFamily: "'Mada','Changa', 'Handjet', sans-serif !important",
  fontOpticalSizing: "auto",
  fontWeight: "400",
  fontStyle: "normal",
  fontVariationSettings: "'ELGR' 1, 'ELSH' 2",
};

export const fontsHandjet = {
  fontFamily: "'Handjet','Mada','Changa', sans-serif !important",
  fontOpticalSizing: "auto",
  fontWeight: "400",
  fontStyle: "normal",
  fontVariationSettings: "'ELGR' 1, 'ELSH' 2",
};

export const  bgBox1v= {
  zIndex: 1,
  "&before": {
    content: "",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: `url(${require("./assets/images/box1-v.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
  },
};

export const bgBox1h= {
  zIndex: 1,
  "&before": {
    content: "",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: `url(${require("./assets/images/box1-h.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
  },
};

export const bgBox2= {
  zIndex: 1,
  "&before": {
    content: "",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: `url(${require("./assets/images/box2.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
  },
};

export const bgBox3= {
  zIndex: 1,
  "&before": {
    content: "",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    background: `url(${require("./assets/images/box3.png")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
  },
};





export const subtitle= {
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 5px 20px 0",
  padding: "25px",
  "&before": {
    content: "",
    display: "block",
    width: "100%",
    height: "100%",
    minWidth: "210px",
    // minHeight: "50px",
    position: "absolute",
    top: "0",
    background: `url(${require("./assets/images/gif1.gif")})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    transform: "scaleX(-1)",
    margin: "10px",
  },
};
export const subtitleLabel= {
  color: App_info_Color,
  position: "relative",
  right: "35px",
  left: "35px",
  padding: "5px 20px",
  ...fontsHandjet,
  fontWeight: "500",
};
export const mainTitle= {
  color: App_info_Color,
  position: "relative",
  margin: "20px auto",
  ...fontsChanga,
  textAlign: "center",
  fontWeight: "600",
  lineHeight: "100%",
};
export const labelsLight= {
  color: App_Light_Text_Color,
  ...fontsChanga,
  textAlign: "justify",
};
export const labelsInfo= {
  color: App_info_Color,
  ...fontsChanga,
  textAlign: "justify",
};


export const appStyle = {
  fontsChanga,
  fontsMada,
  fontsHandjet,
  bgBox1v,
  bgBox1h,
  bgBox2,
  bgBox3,
  subtitle,
  subtitleLabel,
  mainTitle,
  labelsLight,
  labelsInfo,
}

export const alertStyle={
  backDropColor:App_Dark_Opacity_Color,
  withoutTitle: true,
  borderColor: App_info_Color,
  containerBk: App_Dark_menu_Color,
  title: "",
  textColor: App_Light_Text_Color,
  closeIconBk:App_Dark_Color,
  closeIconBk_hover:App_Dark_Color,

  closeIconColor: App_info_Color,
  closeIconColor_hover: App_info_Color,

  iconColorFill: App_info_Color,

  headerIconSameLvl_xs: true,
  closeIconBorder: `1px dashed ${App_info_Color}`,
  
    
}