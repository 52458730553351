"use client";
import { StyleBuilder } from "..";

export default function ClassesBuilder(objStyles, props = {}, objOption) {
  const classesClient = StyleBuilder({
    blnRandomPrefix: typeof objOption?.blnRandomPrefix !== "boolean" ? true : objOption?.blnRandomPrefix,
    blnIsDefaultStyle: false,
    blnCbmisStyle: false,
    objStyles: { ...objStyles },
  });
  return classesClient(props);
}
export function makeStyle(rules) {
  return rules;
}
