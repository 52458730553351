// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Button, Grid, Container, Loader } from "@cbmisorg/material";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import { useApp } from "../../../../configuration/contextapi/context";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { FormData } from "../../../../util/formApi/FormAPI";
import { CtrlProfile } from "./Profile.controler";
import ShredHeader from "../../../shared/shredHeader";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./Profile.style";
import { App_info_Color } from "../../../../util/appStyle";
import Uploader from "../../../../../old/components/sharedUi/uploader/Uploader";
import { objUploadType, workSectorID } from "../../../../configuration/appVariables";

import Info from "./components/Info";
import RestEmail from "./components/RestEmail";
import RestPhone from "./components/RestPhone";
import RestPassword from "./components/RestPassword";
import WorkSectorOption from "../../member/account/components/regActivityGuest/component/WorkSectorOption.view";

const labels = dictionary?.components?.stakeholders?.shared?.profile;

const lstIcon = ["person", "email", "phoneOutlined", "lock"];
function ProfileView() {
  useCheckHooksGlb();

  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const classes = ClassesBuilder(objStyle,{lang});

  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    blnIsInitDone: false,
    tab: 0,
    listLogo: [],
    lstTranscript: [],
  });
  const uploaderFirstProofSection = Uploader(state, setState, "lstTranscript", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    file: dictionary?.components?.uploader?.receipt?.[lang],
  });

  const fields = FormData({
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    bigMajorID: { control: "list", options: [], validations: { required: true } },
    bigDegreeID: { control: "list", options: [], validations: { required: true } },
    bigGender: { control: "list", options: [], validations: { required: true } },
    // bigEmploymentID: { control: "list", options: [], validations: { required: true } },
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: { required: true },
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },
  });

  const fieldPassword = FormData({
    strOldUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: true, minLength: 4  },
    },
    strRestPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: true, minLength: 4 },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: true, minLength: 4, equal: "strRestPassword"},
    },
  });
  const fieldEmail = FormData({
    strRestEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, email: true },
    },
  });

  const fieldPhone = FormData({
    strUserPhone: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, onlyNumbers: true, minLength: 5, maxLength: 10 },
    },
  });

  const handlers = CtrlProfile(appState, appDispatch);
  const initData = handlers?.initData({ isLoading, setIsLoading, state, setState, fields, fieldEmail, fieldPhone });


  useEffect(() => {
    if (!state?.blnIsInitDone) {
      initData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTab = (tab) => () => {
    setState({ ...state, tab: tab });
  };

  const GetElementTab = () => {
    switch (state.tab) {
      case 1:
        return (
          <RestEmail fields={fieldEmail} handlers={handlers} classes={classes} lang={lang} isLoading={isLoading} setIsLoading={setIsLoading} state={state} setState={setState} />
        );
      case 2:
        return (
          <RestPhone fields={fieldPhone} handlers={handlers} classes={classes} lang={lang} isLoading={isLoading} setIsLoading={setIsLoading} state={state} setState={setState} />
        );
      case 3:
        return (
          <RestPassword
            fields={fieldPassword}
            handlers={handlers}
            classes={classes}
            lang={lang}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            state={state}
            setState={setState}
          />
        );
      default:
        return (
          <Info
            fields={fields}
            handlers={handlers}
            classes={classes}
            lang={lang}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            appState={appState}
            uploaderFirstProofSection={uploaderFirstProofSection}
          />
        );
    }
  };
  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBack={true} />
      <Loader backdrop={isLoading} color={App_info_Color} />
      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer} pt-5>
          <Grid container justifyContent={"center"} spacing={0}>
            <Grid item xs="12" md="10" lg="9" xl="8" container py-0 px-3>
              {lstIcon?.map((icon, index) => (
                <Grid item key={index} px-0>
                  <Button icon={icon} size="sm" className={`${classes?.btnTab} ${state?.tab === index? classes?.btnActive: null}`} onClick={setTab(index)} m-0 px-6 />
                </Grid>
              ))}
            </Grid>
            <Grid item xs="12" md="10" lg="9" xl="8" className={classes?.bgBox}>
              <Container className={classes?.container}>{GetElementTab()}</Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default ProfileView;
