export const headerLabel ="App Permissions"

export const objPermissionState={
    cameraPermission:{user:false,system:false,backUrl:""},
    locationPermission:{user:false,system:false,backUrl:""},
    microphonePermission:{user:false,system:false,backUrl:""},
    notificationsPermission:{user:false,system:false,backUrl:""},
}

export const viewLst = ["cameraPermission","locationPermission","microphonePermission","notificationsPermission","midiPermission"]
export const objPermissionKey={
    cameraPermission:"cameraPermission",
    locationPermission:"locationPermission",
    microphonePermission:"microphonePermission",
    notificationsPermission:"notificationsPermission",
    pushPermission:"pushPermission",
    deviceInfoPermission:"deviceInfoPermission"
}

export const objPermissionName={
    cameraPermission:"camera",
    locationPermission:"geolocation",
    microphonePermission:"microphone",
    notificationsPermission:"notifications",
    pushPermission:"push",
    deviceInfoPermission:"device-info",
    backgroundSyncPermission:"background-sync",
    bluetoothPermission:"bluetooth",
    persistentStoragePermission:"persistent-storage"
}

export const objPermissionLabel={
    arb:{
        cameraPermission:"كاميرا",
        locationPermission:"الموقع",
        microphonePermission:"الميكروفون",
        notificationsPermission:"الاشعارات",
        pushPermission:"Push",
        speakerPermission:"Speaker",
        deviceInfoPermission:"Device Info",
        backgroundSyncPermission:"Background Sync",
        bluetoothPermission:"بلوتوث",
        persistentStoragePermission:"Persistent Storage"
    },
    eng:{
        cameraPermission:"Camera",
        locationPermission:"Location",
        microphonePermission:"Microphone",
        notificationsPermission:"Notifications",
        pushPermission:"Push",
        speakerPermission:"Speaker",
        deviceInfoPermission:"Device Info",
        backgroundSyncPermission:"Background Sync",
        bluetoothPermission:"Bluetooth",
        persistentStoragePermission:"Persistent Storage"
    }
}

export const objPermissionIcon={
    cameraPermission:"cameraAlt",
    locationPermission:"locationOn",
    microphonePermission:"mic",
    notificationsPermission:"notifications",
    pushPermission:"push",
    speakerPermission:"speaker",
    deviceInfoPermission:"device-info",
    backgroundSyncPermission:"background-sync",
    bluetoothPermission:"bluetooth",
    persistentStoragePermission:"persistent-storage"
}

export const permissionOptions = [
    {key:0,value:"OFF"},
    {key:1,value:"ON"},
  ]

  export const permissionLangConfirmation = {
  arb:{
    cameraPermission:"الوصول للكاميرا",
    locationPermission:"الوصول للموقع",
    microphonePermission:"الوصول الميكرفون",
    notificationsPermission:"استقبال الاشعارات",
    yes:"تأكيد",
    no:"رفض"
  },
  eng:{
    cameraPermission:"Use Your Camera",
    locationPermission:"Know Your Location",
    microphonePermission:"Use Your Microphone",
    notificationsPermission:"Show Notifications",
    yes:"Yes",
    no:"No"
  }
  }

  export const permissionLangAlert = {
    arb:{
      allow:'الذهاب الي اعدادات المتصفح وتفعل الأذونات'
    },
    eng:{
     allow:'Go to the browser sittings to allow the camera permission first'
      
    }
    }