import React, { useEffect, useState } from "react";
import { useApp } from "../../../../configuration/contextapi/context";
import { CtrlManageMedia } from "./ManageMedia.controller";
import Uploader from "../../../../../old/components/sharedUi/uploader/Uploader";
import { objUploadType } from "../../../../configuration/appVariables";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { FormData, FormField } from "../../../../util/formApi/FormAPI";
import ShredHeader from "../../../shared/shredHeader";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Button, Container, Grid, Line, Modal, ModalBody, ModalHeader, Paper, Typography } from "@cbmisorg/material";
import { useNavigate } from "@cbmisorg/router";
import { Icon } from "@cbmisorg/icons";
import { App_Primary_Color } from "../../../../util/appStyle";
import { getDateUSFormat } from "../../../../util/appFunctions";

const label = dictionary?.components?.stakeholders?.admin?.manageMedia;
function ManageMediaView() {
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const lang = appState.clientInfo.strLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    listMedia: [],
    lstVideos: [],
    lstVideosPoster: [],
    blnOpenModal: false,
    selectedMediaInfo: null,
    objPointSelected: null,
    intPointSelectedIndex: -1,
    objPointSelectedBeforPoint: [],
    objPointSelectedAfterPoint: [],
    strPointTypeSelected: "",
    blnOpenPointModal: false,
  });

  const uploaderVideo = Uploader(stateInfo, setStateInfo, "lstVideos", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: true,
    blnForceNewName: true,
    strSpecificName: "media" + getDateUSFormat(new Date(), true),
    file: label?.video?.[lang],
    lstPoints: [],
  });
  const uploaderVideosPoster = Uploader(stateInfo, setStateInfo, "lstVideosPoster", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: true,
    blnForceNewName: true,
    strSpecificName: "media" + getDateUSFormat(new Date(), true),
    file: {eng:"img", arb:"صور"}?.[lang],
    lstPoints: [],
  });

  const fieldVedio = FormData({
    strUrlCode: {
      control: "text",
      blnLanguage: false,
      validations: { required: true, maxWord: 1 },
    },
    strTitle: {
      control: "text",
      blnLanguage: true,
    },
    lstReg: {
      control: "checkbox",
      blnLanguage: false,
      options: [
        {
          key: "blnMDEA",
          value: {
            eng: "Member of the Digital Empowerment Committee",
            arb: "عضو في لجنة التمكين الرقمي",
          },
        },
        {
          key: "blnResearcher",
          value: {
            eng: "Researcher",
            arb: "باحث",
          },
        },
        {
          key: "blnTrainee",
          value: {
            eng: "Trainee",
            arb: "متدرب",
          },
        },
        {
          key: "blnVolunteer",
          value: {
            eng: "Volunteer",
            arb: "متطوع",
          },
        },
        // {
        //   "key": "blnSponser",
        //   "value": {
        //     "eng": "Sponser",
        //     "arb": "راعي"
        //   }
        // },
      ],
    },
  });

  const fieldVedioBefor = FormData({
    blnIsViwBefor: {
      control: "switch",
    },
    strTitle: {
      control: "text",
      blnLanguage: true,
    },
    strSubtitle: {
      control: "text",
      blnLanguage: true,
    },
    strDscrp: {
      control: "textarea",
      blnLanguage: true,
    },
    strDscrp2: {
      control: "textarea",
      blnLanguage: true,
    },
    caption: {
      control: "text",
      blnLanguage: true,
    },
    isViewMediaBtn: {
      control: "switch",
    },
    mediaBtn: {
      control: "text",
      blnLanguage: true,
    },
    isForumBtn: {
      control: "switch",
    },
    strForumBtn: {
      control: "text",
      blnLanguage: true,
    },
    strForumKey: {
      control: "text",
      blnLanguage: false,
    },
  });

  const fieldVedioAfter = FormData({
    blnIsViwAfter: {
      control: "switch",
    },
    strTitle: {
      control: "text",
      blnLanguage: true,
    },
    strSubtitle: {
      control: "text",
      blnLanguage: true,
    },
    strDscrp: {
      control: "textarea",
      blnLanguage: true,
    },
    strDscrp2: {
      control: "textarea",
      blnLanguage: true,
    },
    caption: {
      control: "text",
      blnLanguage: true,
    },
    isViewMediaBtn: {
      control: "switch",
    },
    mediaBtn: {
      control: "text",
      blnLanguage: true,
    },
    isForumBtn: {
      control: "switch",
    },
    strForumBtn: {
      control: "text",
      blnLanguage: true,
    },
    strForumKey: {
      control: "text",
      blnLanguage: false,
    },
  });

  const fieldPoints = FormData({
    strTitle: {
      control: "text",
      blnLanguage: true,
    },
    lstPoints: {
      control: "textarea",
      blnLanguage: true,
    },
  });
  const fieldSearch = FormData({
    strSearch: {
      control: "text",
      blnLanguage: false,
    },
  });

  const handlers = CtrlManageMedia(appState, appDispatch, navigate);
  const getMedia = handlers.getMedia({ isLoading, setIsLoading, stateInfo, setStateInfo });
  const addMedia = handlers.addMedia({ isLoading, setIsLoading, stateInfo, setStateInfo, uploaderVideo, uploaderVideosPoster, fieldVedio, fieldVedioBefor, fieldVedioAfter });
  const updateMedia = handlers.updateMedia({ isLoading, setIsLoading, stateInfo, setStateInfo, uploaderVideo,uploaderVideosPoster, fieldVedio, fieldVedioBefor, fieldVedioAfter });
  const handleOpenModale = handlers.openModale({ stateInfo, setStateInfo, fieldVedio, fieldVedioBefor, fieldVedioAfter });
  const handleOpenPointModale = handlers.openPointModal({ stateInfo, setStateInfo, fieldPoints });
  const handleOpenSavePointModal = handlers.openSavePointModal({ stateInfo, setStateInfo, fieldPoints });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      getMedia();
    }
  }, []);

  const handleSubmitModal = () => {
    if (stateInfo?.selectedMediaInfo) {
      updateMedia();
    } else {
      addMedia();
    }
  };
  return (
    <React.Fragment>
      <ShredHeader title={label?.title?.lang} blnIsLight={true} />
      <Container py-10 my-10>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs="10" md="11">
            <FormField objHandler={fieldSearch?.strSearch} icon="searchAlt" />
          </Grid>
          <Grid item xs="2" md="auto">
            <Button icon="add" onClick={handleOpenModale()} />
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item xs="12">
            <Grid container>
              {(stateInfo?.listMedia || [])?.map((video) => {
                if (
                  fieldSearch?.strSearch?.getValue() &&
                  !JSON.stringify(video?.jsnInfo?.title)?.includes(fieldSearch?.strSearch?.getValue()) &&
                  !video?.strVedioUrl?.includes(fieldSearch?.strSearch?.getValue())
                ) {
                  return null;
                }
                return (
                  <Grid item xs="12" key={video?.id}>
                    <Paper elevation={"0"} outline={"1"}>
                      <Grid container>
                        <Grid item xs="8" container>
                          <Grid item>
                            <Typography as="body2">{video?.jsnInfo?.title?.[lang] || video?.strVedioUrl}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs="4" container sx={{ justifyContent: "flex-end" }}>
                          {/* <Grid item>
                            <Icon icon={"delete"} color="error" onClick={handlers?.delateVideoConfirmation(video)} />
                          </Grid> */}
                          <Grid item>
                            <Icon icon={"edit"} color="primary" onClick={handleOpenModale(video)} />
                          </Grid>
                          <Grid item>
                            <Icon icon={"openInNew"} color="info" onClick={() => window.open(`/${video?.strUrlCode}`, "_blank")} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography as="body3">
                            {label?.intCountView?.[lang]}: {video?.intCountView}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography as="body3">
                            {label?.intCountWatchMedia?.[lang]}: {video?.intCountWatchMedia}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo.blnOpenModal} eventClose={handleOpenModale(null)} size="xl">
        <ModalHeader>{label?.videoInfo?.[lang]}</ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs="12" container dir={"ltr"}>
              <Grid item xs="6" sm="5" md="4" lg="3" xl="2" alignContent="center">
                <Typography as="body2">{`${window?.location?.origin}/`}</Typography>
              </Grid>
              <Grid item xs="6" sm="7" md="8" lg="9" xl="10">
                <FormField objHandler={fieldVedio?.strUrlCode} dir={"ltr"} />
              </Grid>
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldVedio?.strTitle} />
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="8">
              <Typography as="caption">{label?.videoUpload?.[lang]}</Typography>
            </Grid>
            <Grid item mx-xs-auto mx-sm-auto px-1>
              {uploaderVideo?.JSX}
            </Grid>
            <Grid item mx-xs-auto mx-sm-auto px-1>
              {uploaderVideosPoster?.JSX}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm="12">
              <Accordion>
                <AccordionTab sx={{ background: "#eee" }} open={fieldVedioBefor?.blnIsViwBefor?.getValue()} key={String(fieldVedioBefor?.blnIsViwBefor?.getValue()) + "before"}>
                  <AccordionSummary>
                    <FormField objHandler={fieldVedioBefor?.blnIsViwBefor} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container px-1>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.strTitle} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.strSubtitle} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.strDscrp} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.strDscrp2} />
                      </Grid>
                      <Grid item xs="12">
                        <Paper elevation="0" outline="1">
                          <Grid container py-0 justifyContent="space-between">
                            <Grid item xs="9">
                              <Typography as="subtitle3">{label?.point?.[lang]}</Typography>
                            </Grid>
                            <Grid item justifyContent="flex-end">
                              <Button icon="add" size="sm" onClick={handleOpenPointModale("before", null, -1)} />
                            </Grid>
                          </Grid>
                          <Grid container>
                            {(stateInfo?.objPointSelectedBeforPoint || [])?.map((dscrption, inddscrption) => {
                              return (
                                <Grid item xs="12" justifyContent="center" key={inddscrption}>
                                  <Grid item xs={"12"} container py-2 px-0 spacing={1}>
                                    <Grid item xs="11">
                                      <Typography as="body2" color={App_Primary_Color} sx={{ display: "flex", alignContent: "baseline" }}>
                                        <Icon icon="circle" size="14px" color={App_Primary_Color} mt-1 mx-1 />
                                        {dscrption?.title?.[lang]}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs="1">
                                      <Icon icon="edit" color={App_Primary_Color} onClick={handleOpenPointModale("before", dscrption, inddscrption)} />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs="12" container justifyContent="center">
                                    {(dscrption?.lstPoint || [])?.map((point, indpoint) => {
                                      return (
                                        <Grid item xs="12" key={indpoint}>
                                          <Typography as="body3" sx={{ display: "flex" }}>
                                            <label style={{ color: App_Primary_Color }}>{String(indpoint + 1) + ". "}</label>
                                            {point?.[lang]}
                                          </Typography>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.caption} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.isViewMediaBtn} />
                      </Grid>
                      {!fieldVedioBefor?.isViewMediaBtn?.getValue() ? null : (
                        <Grid item xs="12">
                          <FormField objHandler={fieldVedioBefor?.mediaBtn} />
                        </Grid>
                      )}
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioBefor?.isForumBtn} />
                      </Grid>
                      {!fieldVedioBefor?.isForumBtn?.getValue() ? null : (
                        <React.Fragment>
                          <Grid item xs="12">
                            <FormField objHandler={fieldVedioBefor?.strForumBtn} />
                          </Grid>
                          <Grid item xs="12">
                            <FormField objHandler={fieldVedioBefor?.strForumKey} />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </AccordionDetails>
                </AccordionTab>
              </Accordion>
            </Grid>
            <Grid item xs="12" py-5 p-0 sx={{ display: "block" }}>
              <Line />
            </Grid>
            <Grid item xs="12">
              <Accordion>
                <AccordionTab sx={{ background: "#eee" }} open={fieldVedioAfter?.blnIsViwAfter?.getValue()} key={String(fieldVedioAfter?.blnIsViwAfter?.getValue()) + "after"}>
                  <AccordionSummary>
                    <FormField objHandler={fieldVedioAfter?.blnIsViwAfter} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container px-1>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.strTitle} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.strSubtitle} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.strDscrp} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.strDscrp2} />
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.caption} />
                      </Grid>
                      <Grid item xs="12">
                        <Paper elevation="0" outline="1">
                          <Grid container py-0 justifyContent="space-between">
                            <Grid item xs="9">
                              <Typography as="subtitle3">{label?.point?.[lang]}</Typography>
                            </Grid>
                            <Grid item justifyContent="flex-end">
                              <Button icon="add" size="sm" onClick={handleOpenPointModale("after", null, -1)} />
                            </Grid>
                          </Grid>
                          <Grid container>
                            {(stateInfo?.objPointSelectedAfterPoint || [])?.map((dscrption, inddscrption) => {
                              return (
                                <Grid item xs="12" justifyContent="center" key={inddscrption}>
                                  <Grid item xs={"12"} container py-2 px-0 spacing={1}>
                                    <Grid item xs="11">
                                      <Typography as="body2" color={App_Primary_Color} sx={{ display: "flex", alignContent: "baseline" }}>
                                        <Icon icon="circle" size="14px" color={App_Primary_Color} mt-1 mx-1 />
                                        {dscrption?.title?.[lang]}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs="1">
                                      <Icon icon="edit" color={App_Primary_Color} onClick={handleOpenPointModale("after", dscrption, inddscrption)}  />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs="12" container justifyContent="center">
                                    {(dscrption?.lstPoint || [])?.map((point, indpoint) => {
                                      return (
                                        <Grid item xs="12" key={indpoint}>
                                          <Typography as="body3" sx={{ display: "flex" }}>
                                            <label style={{ color: App_Primary_Color }}>{String(indpoint + 1) + ". "}</label>
                                            {point?.[lang]}
                                          </Typography>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.isViewMediaBtn} />
                      </Grid>
                      {!fieldVedioAfter?.isViewMediaBtn?.getValue() ? null : (
                        <Grid item xs="12">
                          <FormField objHandler={fieldVedioAfter?.mediaBtn} />
                        </Grid>
                      )}
                      <Grid item xs="12">
                        <FormField objHandler={fieldVedioAfter?.isForumBtn} />
                      </Grid>
                      {!fieldVedioAfter?.isForumBtn?.getValue() ? null : (
                        <React.Fragment>
                          <Grid item xs="12">
                            <FormField objHandler={fieldVedioAfter?.strForumBtn} />
                          </Grid>
                          <Grid item xs="12">
                            <FormField objHandler={fieldVedioAfter?.strForumKey} />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </AccordionDetails>
                </AccordionTab>
              </Accordion>
            </Grid>
            
          </Grid>
          <Line />
          <Grid container spacing={3}>
            <Grid item xs="12">
              <FormField objHandler={fieldVedio?.lstReg} />
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} py-3>
            <Grid item xs="12">
              <Button
                py-0
                px-3
                label={!stateInfo?.selectedMediaInfo ? dictionary?.shared?.buttons?.addBtn?.[lang] : dictionary?.shared?.buttons?.update?.[lang]}
                onClick={handleSubmitModal}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo?.blnOpenPointModal} eventClose={handleOpenPointModale("", null, -1)}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs="12">
              <FormField objHandler={fieldPoints?.strTitle} />
            </Grid>
            <Grid item xs="12">
              <FormField objHandler={fieldPoints?.lstPoints} />
              <Typography as="caption">{label?.helpMsgPoint?.[lang]}</Typography>
            </Grid>
          </Grid>
          <Line sx={{ width: "100%" }} />
          <Grid container>
            <Grid item>
              <Button label={dictionary?.shared?.buttons?.save?.[lang]} size="sm" onClick={handleOpenSavePointModal} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default ManageMediaView;
