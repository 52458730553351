import "./Confirmation.css";
import '../../../style/main.css'

/**
 *
 * @param {string} message:
 * @param {string} acceptLabel:
 * @param {string} cancelLabel:

 *
 * - message is a string that which you want to show at confirmation dialog.
 *
 *
 *
 * @param {function} targetFunction:
//  * * @param {function} cancelFunction:

 * - targetFunction is the no-args function perform on click at yes button.
 * - VERY IMPORTANT: targetFunction must be no-args function, and must declare it at args by reference. also please do not called it at middle of any operation, just call it at empty function.
 *
 * - EX: appConfirmation.viewConfirmation("here is my message", myFunction) //we declare the function by reference not like "myFunction()"
 *
 */
export function viewConfirmation(message, targetFunction,cancelFunction,acceptLabel,cancelLabel) {
  const confIcon = `<svg  enableBackground="new 0 0 24 24" viewBox="0 3 24 24"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M15.73,3H8.27L3,8.27v7.46L8.27,21h7.46L21,15.73V8.27L15.73,3z M19,14.9L14.9,19H9.1L5,14.9V9.1L9.1,5h5.8L19,9.1V14.9z"/><rect height="6" width="2" x="11" y="7"/><rect height="2" width="2" x="11" y="15"/></g></g></g></svg>`;
  //////////////////////////////////////// root of modal //////////////////////////////////////////////
  const modal = document.createElement("div");
  modal.classList.add("cbmis-conf-modal");

  ////////////////////////////////////// container ///////////////////////////////////////////////////
  const modalContainer = document.createElement("div");
  modalContainer.classList.add("cbmis-conf-container");
  modal.appendChild(modalContainer);

  ////////////////////////////////////// content ///////////////////////////////////////////////////
  const modalContent = document.createElement("div");
  modalContent.classList.add("cbmis-conf-content");
  modalContainer.appendChild(modalContent);
  ////////////////////////////////// message /////////////////////////////////////////////////
  const msg = document.createElement("div");
  msg.classList.add("cbmis-conf-message");

  const icon = document.createElement("div");
  icon.classList.add("cbmis-conf-message-icon");
  icon.innerHTML = confIcon;
  msg.appendChild(icon);

  const text = document.createElement("div");
  text.classList.add("cbmis-conf-message-text");
  text.innerHTML = message;
  msg.appendChild(text);

  modalContent.appendChild(msg);
  ////////////////////////////////////// divider ///////////////////////////////////////////////////
  const divider = document.createElement("div");
  divider.classList.add("cbmis-conf-divider");
  divider.innerHTML = '<hr style="height:1px;border-width:0;color:gray;background:#c9c9c9"/>';
  modalContainer.appendChild(divider);
  ////////////////////////////////////// action ///////////////////////////////////////////////////
  const modalAction = document.createElement("div");
  modalAction.classList.add("cbmis-conf-action");
  modalContainer.appendChild(modalAction);

  ////////////////////////////////// no button ////////////////////////////////////////////////
  const noButton = document.createElement("div");
  noButton.classList.add("cbmis-conf-no-button");
  noButton.innerHTML = cancelLabel|| "No";
  modalAction.appendChild(noButton);
  noButton.addEventListener("click",async () => {
    if (modal) {
      modal.remove();
      await cancelFunction();
    }
    return;
  });
  ////////////////////////////////// yes button ////////////////////////////////////////////////
  const yesButton = document.createElement("div");
  yesButton.classList.add("cbmis-conf-yes-button");
  yesButton.innerHTML = acceptLabel|| "Yes";
  modalAction.appendChild(yesButton);
  yesButton.addEventListener("click", async () => {
    modal.remove();
    await targetFunction();
  });

  ////////////////////////////////////// add to body /////////////////////////////////////////////
  document.body.appendChild(modal);
}
