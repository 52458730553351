import React, { useEffect, useRef } from "react";
import "./Accordion.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";
import { strTestProps } from "../../../helper/CbmisVariables";

const Accordion = (props) => {
  const { children, ariaControl, sx, hidden, disabled, dir, className, id,mode, ...rest } = props;
  const screenWidth = useCheckScreenSize();

  const getID = useRef(id || componentsFunction.randomName("Accordion"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: id || componentsFunction.randomName("cbmis-accordion-tabs") });

  if(props?.[strTestProps]){
    if(children){
      console.log("Accordion", "children");
    }
    if(props?.ariaControl){
      console.log("Accordion", "ariaControl");
    }
    if(props?.sx){
      console.log("Accordion", "sx",);
    }
  }
  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [children, ariaControl, hidden, disabled, dir, className, id]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-accordion-tabs");
    }
  }, [sx, screenWidth]);

  return (
    <div id={getID.current} className="cbmis-accordion-row">
      <div className="cbmis-accordion-col">
        <div
          id={styleRef.current.strSXComponentID}
          className={`cbmis-accordion-tabs ${mode==="horizontal"?"cbmis-accordion-horizontal-tabs":""} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
            disabled
          )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${
            className || ""
          }`}
          custom-attribute={JSON.stringify({ ariaControl: ariaControl ? ariaControl : `accordionSummaryInput-${getID.current}` })}
          {...getRestProps()}
        >
        {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
