import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { workSectorCat, workSectorID } from "../../../configuration/appVariables";
import { alertStyle } from "../../../util/appStyle";
import WorkSectorOption from "../../stakeholders/member/account/components/regActivityGuest/component/WorkSectorOption.view";
import React from "react";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblActivityGuest = generateQueries("tblActivityGuest");
const userApi = generateQueries("tblUser");

const labels = dictionary?.components?.public?.regActivityGuest;
export const CtrlRegNewForum = ({ appState, appDispatch, isLoading, setIsLoading }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    openModal:  ({ navigate, state, setState, fields, getNewCatInfo }) => (forumCode)=> async()=>{
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        //#region tblCategory & filter activity
        setIsLoading(true);
        await getNewCatInfo();
        const objCat = appFunctions.getCategoryCache();
        const objActivityCat = objCat?.Root?.activity?.[String(forumCode).toLowerCase()];

        if (typeof objActivityCat === "undefined") {
          setIsLoading(false);
          navigate(appRouting?.landingPage?.url);
          return;
        }

        if (objActivityCat?.subject) {
          delete objActivityCat?.subject?.id;
          delete objActivityCat?.subject?.value;
          const lstSubject = Object?.keys(objActivityCat?.subject);
          const lstCurentSubject = lstSubject?.filter((item) => {
            return objActivityCat?.subject?.[item]?.blnView?.value?.["eng"] === "true";
          });
          state.lstCurentSubject = lstCurentSubject?.map((item) => objActivityCat?.subject?.[item]);
        }
        if (state.lstCurentSubject?.length === 0 || objActivityCat?.blnActive?.value?.eng === "false") {
          Alert.viewAlert(labels?.alertEndWeek?.[lang], "info", appLangDirection?.[lang], alertStyle);
          setIsLoading(false);
          navigate(appRouting?.landingPage?.url);
          return;
        }
        if (appState?.clientInfo?.blnIsUserLoggedIn) {
          const result = await userApi(strPageInfo, appState, "aaiotAppInitUserAccount", {
            arrAttributes: objTableAttributes.tblActivityGuest.full,
            objectCondition: {
              bigUserID: appState?.userInfo?.bigUserID,
              bigSystemID: appState?.userInfo?.bigSystemID || appVariables?.aaiot_System_ID,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            setIsLoading(false);
            Alert.viewAlert(dictionary.shared.alertFetchNote.cantFindRecords?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            return;
          }

          let lstUserRegActivityTmp = Array.isArray(result?.response?.lstActivityGuestInfo) ? result?.response?.lstActivityGuestInfo : [];

          const blnIsForumReg = lstUserRegActivityTmp?.findIndex((item) => String(item?.bigActivityType) === String(objActivityCat?.id)) > -1;

          state.blnIsForumReg = blnIsForumReg;
          state.lstUserRegActivityTmp = lstUserRegActivityTmp;
        }

        state.objActivityInfo = objActivityCat;
        state.blnIsInitDone = true;
        state.blnRegOpenModal = true;

        //#endregion

        //#region setValue if User Login
        if (!appState?.clientInfo?.blnIsUserLoggedIn) {
          fields?.strUserPassword?.setValidation("required", true);
          fields?.strConfirmPassword?.setValidation("required", true);
          fields?.bigWorkSector?.setValue(appVariables?.workSectorID?.gov);
        } else {
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            if (typeof appState?.userInfo?.jsnUserInfo?.strFullName === "object") {
              fields?.strFullName?.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang]);
            } else {
              fields?.strFullName?.setValue(appState?.userInfo?.jsnUserInfo?.strFullName);
            }
          }
          fields?.strUserEmail?.setValue(appState?.userInfo?.strUserEmail);
          if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
            fields?.bigWorkSector?.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
            handler?.handelWorkSectorChange({ fields, state, setState })();
          } else {
            fields?.bigWorkSector?.setValue(appVariables?.workSectorID?.gov);
          }
          if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
            fields?.strPlaceName?.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
          }
        }
        //#endregion

        let lstCountryOption = [];
        Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstCountryOption.push({ key: value?.id, value: value?.value });
          }
        });
        fields.bigCountryOfResidenceID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
        fields.bigCountryOfResidenceID?.setValue(lstCountryOption?.[0] || "");

        setState({ ...state });
        setIsLoading(false);
        appDispatch();
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "initData-ERROR", error);
        setIsLoading(false);
      }
    },
    // openModal:
    //   ({ state, setState, fields }) =>
    //   async () => {
    //     if (!appState?.clientInfo?.blnIsUserLoggedIn) {
    //       fields?.strUserPassword?.setValidation("required", true);
    //       fields?.strConfirmPassword?.setValidation("required", true);
    //       fields?.bigWorkSector?.setValue(appVariables?.workSectorID?.gov);
    //     } else {
    //       fields?.strUserPassword?.setValidation("required", false);
    //       fields?.strConfirmPassword?.setValidation("required", false);
    //       if (appState?.userInfo?.jsnUserInfo?.strFullName) {
    //         if (typeof appState?.userInfo?.jsnUserInfo?.strFullName === "object") {
    //           fields?.strFullName?.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang]);
    //         } else {
    //           fields?.strFullName?.setValue(appState?.userInfo?.jsnUserInfo?.strFullName);
    //         }
    //       }
    //       fields?.strUserEmail?.setValue(appState?.userInfo?.strUserEmail);
    //       if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
    //         fields?.bigWorkSector?.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
    //         handler?.handelWorkSectorChange({ fields, state, setState })();
    //       } else {
    //         fields?.bigWorkSector?.setValue(appVariables?.workSectorID?.gov);
    //       }
    //       if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
    //         fields?.strPlaceName?.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
    //       }
    //     }

    //     if (appState?.clientInfo?.blnIsUserLoggedIn) {
    //       await handler?.addGuest({ state, setState, fields });
    //     } else {
    //       setState({ ...state, blnRegOpenModal: !state?.blnRegOpenModal });
    //     }
    //   },
    addGuest: async ({ state, setState, fields }) => {
      try {
        //#region Form Valid        
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }

        //#endregion
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        let bigID = appFunctions.generateID(10);
        //#region  get SubjectValue  by control type

        const getSubjectValue = () => {
          let lstValue = [];
          lstValue = state?.lstCurentSubject?.map((item) => Number(item?.id));
          return appFunctions.uniq_fast(lstValue);
        };
        //#endregion

        const result = await tblActivityGuest(strPageInfo, appState, "aaiotActivityGuestAdd", {
          objectInput: {
            //#region tblUser Information
            bigAppID: appVariables.App_ID,
            bigSystemID: appVariables.aaiot_System_ID,
            bigUserStatusID: appVariables.objUserStatus.Approved,
            strUserPassword: fields?.strUserPassword?.getValue(),
            bigUserID: bigID,
            strUserEmail: fields?.strUserEmail?.getValue(),
            bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
            bigCountryOfCitizenID: fields?.bigCountryOfResidenceID?.getValue()?.key,
            //strUserPhone: "strUserPhone",
            //strUsername: "strUsername",

            strFullName: fields?.strFullName?.getValue(),
            bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
            intUserRoleFK: appState?.userInfo?.jsnUserInfo?.intUserRoleFK || appVariables.objUserRole.Member,
            strPlaceName: fields?.strPlaceName?.getValue(),
            blnIsUserLoggedIn: appState?.clientInfo?.blnIsUserLoggedIn,
            jsnUserInfo: {
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
              bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
              intUserRoleFK: appState?.userInfo?.jsnUserInfo?.intUserRoleFK || appVariables.objUserRole.Member,
              strPlaceName: fields?.strPlaceName?.getValue() || "",
              bigCountryOfResidenceID: fields?.bigCountryOfResidenceID?.getValue()?.key,
              bigCountryOfCitizenID: fields?.bigCountryOfResidenceID?.getValue()?.key,
            },
            blnIsActivityUser: true,
            //#endregion
            ///////////////////////////////////////////////////
            //#region tblActivityGuest information
            bigActivityType: state?.objActivityInfo?.id,
            jsnGuestInfo: {
              objSubject: getSubjectValue(),
            },
            //#endregion
            ///////////////////////////////////////////////////
            //#region Statistic ??
            lstStatisticFelidIfUserNew: ["intCountJoinUser", "intCountJoinUserAll"],
            lstStatisticFelidIfGuestActivityNew: ["intCountActivityGuest", "intCountActivityGuestAll"],
            //#endregion
          },
        });

        if (!result?.blnIsRequestSuccessful) {
          if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          } else {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          }
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setState({ ...state });
        Alert.viewAlert(labels?.alertThankeFutureForum?.[lang], "success", appLangDirection?.[lang], alertStyle);
        // fields?.controller?.resetForm();
        // if (result?.response?.blnIsUserExist && !appState?.clientInfo?.blnIsUserLoggedIn) {
        //   // navigate(appRouting.Public_Login?.url, { replace: true });
        // } else {
        appState.clientInfo.blnIsUserLoggedIn = true;
        appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
        appState.userInfo = result.response?.userInfo;
        state.blnRegOpenModal = false;
        setState({ ...state, blnIsForumReg: true });
        appDispatch();
        // }
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "addGuest-ERROR", error);
        setIsLoading(false);
      }
    },

    handelWorkSectorChange:
      ({ fields, state, setState }) =>
      () => {
        try {
          //#region rest Value
          // fields.lstTargeted?.setValue("");
          //#endregion
          //#region set Option for lstTargeted field
          // let optLst = [];
          const bigWorkSectorVal = fields?.bigWorkSector?.getValue();
          // if (bigWorkSectorVal?.key === workSectorID?.gov) {
          //   optLst = [
          //     { key: workSectorCat?.leader, value: dictionary?.shared?.app?.leader, jsx: () => <WorkSectorOption type="leader" lang="arb" /> },
          //     { key: workSectorCat?.employee, value: dictionary?.shared?.app?.employee, jsx: () => <WorkSectorOption type="employee" lang="arb" /> },
          //   ];
          // } else if (bigWorkSectorVal?.key === workSectorID?.prvt) {
          //   optLst = [
          //     { key: workSectorCat?.manager, value: dictionary?.shared?.app?.manager, jsx: () => <WorkSectorOption type="manager" lang="arb" /> },
          //     { key: workSectorCat?.programer, value: dictionary?.shared?.app?.programer, jsx: () => <WorkSectorOption type="programer" lang="arb" /> },
          //     { key: workSectorCat?.student, value: dictionary?.shared?.app?.student, jsx: () => <WorkSectorOption type="student" lang="arb" /> },
          //   ];
          // }
          // fields?.lstTargeted?.setOptions(optLst, true);
          //#endregion
          //#region set Option for fields field
          if (state?.objActivityInfo?.type?.value?.eng !== "eng") {
            for (let index = 0; index < state?.lstCurentSubject.length; index++) {
              const element = state?.objActivityInfo?.subject?.[state?.lstCurentSubject[index]];

              if (
                JSON.parse(element?.bigworkSectorID?.value?.["eng"])?.includes(bigWorkSectorVal?.key) ||
                String(element?.bigworkSectorID?.value?.["eng"])?.includes(String(bigWorkSectorVal?.key))
              ) {
                // subjectLstOption.push({
                //   key: element?.id,
                //   value: element?.value,
                //   jsx: () => <SubjectOption key={element?.id} element={element} lang={lang} state={state} setState={setState} />,
                // });
              }
            }
          }
          //#endregion
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "handelWorkSectorChange-ERROR", error);
          setIsLoading(false);
        }
      },
  };

  return handler;
};
