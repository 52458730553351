import React, { useEffect, useState } from "react";
import { Container, Loader, Grid } from "@cbmisorg/material";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";
import { useApp } from "../../../../configuration/contextapi/context";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./Account.style";
import { App_info_Color } from "../../../../util/appStyle";

import accountCtrl from "./Account.controller";
import SponsorSetterModalView from "./components/sponsorSetterModal/sponsorSetterModal.view";
import TraineeSetterModalView from "./components/traineeSetterModal/traineeSetterModal.view";
import AnnouncementView from "./components/announcement/Announcement.view";
import RunningActivityView from "./components/runningActivity/RunningActivity.view";

import lineImage from "../../../../util/assets/images/Mediamodifier-Design.svg";
import ServicesView from "./components/services/Services.view";
import ShredHeader from "../../../shared/shredHeader";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import useCheckCat from "../../../../util/hooks/useCheckCat";
import useCheckSystemInfo from "../../../../util/hooks/useCheckSystemInfo";
import { modalOverFllow, scrollToElement } from "../../../../util/appFunctions";
import ResearcherSetterModalView from "./components/researcherSetterModal/researcherSetterModal.view";
import AaaiotInfoView from "./components/aaiotInfo/AaaiotInfo.view";
import "../../../public/eCertificate/assets/font.css";
import VolunteerSetterModalView from "./components/volunteerSetterModal/volunteerSetterModal.view";
import MDEASetterModalView from "./components/MDEASetterModal/MDEASetterModal.view";
const labels = dictionary?.components?.stakeholders?.shared?.account;

export default function AccountView() {
  useCheckHooksGlb();
  const { appState, appDispatch } = useApp();
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const strUserTrainingTypeCode = appState?.userInfo?.strTrainingTypeCode || appState?.clientInfo?.clientCountryCode;
  const blnNotHideSupport = (jsnSystemConfig?.blnIsPaid || jsnSystemConfig?.blnIsContact) && jsnSystemConfig?.lstContrySponserCode?.includes(strUserTrainingTypeCode);
  const blnNotHideTraining = jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstCountryTrainingCode?.includes(strUserTrainingTypeCode);
  const blnNotHideResearcher = jsnSystemConfig?.lstContryResearcherCode?.includes(strUserTrainingTypeCode) && !appState?.userInfo?.blnIsStudent;
  const blnNotHideVolunteer = strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode;  //jsnSystemConfig?.lstContryVolunteerCode?.includes(strUserTrainingTypeCode);
  const blnNotHideMDEA =   strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode; //jsnSystemConfig?.lstContryMDEACode?.includes(strUserTrainingTypeCode);
  
  const { getNewCatInfo } = useCheckCat({ appState: appState });
  const { getNewSystemInfo } = useCheckSystemInfo({ appState: appState });

  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    blnIsInitDone: false,
    lstRunningActivity: [], //contain only the activities that is ongoing + sorted by the date // structure : {strCatName//from cate, objActivity//from cate, lstActiveSubjectOrdered, dtmLastDate}
    lstUserRegActivity: [], //contain only the activities that is ongoing (main like aiweek not its subjects)
    objPartnerInfo: null, //set only when open SponsorSetterModal, you can also use getPartnerInfo to set it.
    lstServices: [],
  });
  

  const handlers = accountCtrl({ appState, appDispatch });
  const initData = handlers.initData({ getNewCatInfo, getNewSystemInfo, isLoading, setIsLoading, setState, state });
  const jsxSponsorSetter = SponsorSetterModalView({ isLoading, setIsLoading, parentState: state, setParentState: setState, classes });
  const jsxTraineeSetter = TraineeSetterModalView({ isLoading, setIsLoading, classes, parentState: state });
  const jsxResearcherSetter = ResearcherSetterModalView({ isLoading, setIsLoading, classes, parentState: state });
  const jsxVolunteerSetter = VolunteerSetterModalView({ isLoading, setIsLoading, classes, parentState: state });
  const jsxMDEASetter = MDEASetterModalView({ isLoading, setIsLoading, classes, parentState: state });

  const jsxRunningActivity = RunningActivityView({ isLoading, setIsLoading, parentState: state, setParentState: setState, classes });
  const jsxAaaiotInfoView = AaaiotInfoView({ isLoading, setIsLoading, parentState: state, setParentState: setState, classes });

  useEffect(() => {
    if (!state.blnIsInitDone) {
      initData();
      scrollToElement("#top")
    }
    return () => {
      modalOverFllow();
    };
  }, []);

  return (
    <React.Fragment>
      <Loader backdrop={isLoading} color={App_info_Color} />
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnHome={false} blnBack={true} blnLang={false} blnLogout={false} sx={{ position: "absolute" }} />
      <section className={classes?.secDark} id="top">
        <Container className={classes?.mainContainer}>
          <Grid container spacing={2} justifyContent={appState?.userInfo?.jsnUserInfo?.blnNewRegStudent? "center":"flex-start"}>
            <Grid item xs="12">
              <AnnouncementView appState={appState} />
            </Grid>
            {!jsnSystemConfig?.lstContryDetailsAccountCode?.includes(strUserTrainingTypeCode) ? null : (
              <Grid item xs="12">
                {jsxAaaiotInfoView?.lstElm}
              </Grid>
            )}
            {!state?.lstRunningActivity?.length || !jsnSystemConfig?.lstContryActivityCode?.includes(strUserTrainingTypeCode) || appState?.userInfo?.jsnUserInfo?.blnNewRegStudent? null : (
              <Grid item xs="12" sx={{ height: "fit-content" }}>
                {jsxRunningActivity?.lstElm}
              </Grid>
            )}

            {!blnNotHideTraining ? null : (
              <Grid item xs="12" sm="12">
                {jsxTraineeSetter.openEle}
              </Grid>
            )}
             {/* {!appState?.userInfo?.jsnUserInfo?.blnNewRegStudent || !jsnSystemConfig?.lstContryActivityCode?.includes(strUserTrainingTypeCode)? null : (
              <Grid item xs="12" sx={{ height: "fit-content" }}>
                {jsxRunningActivity?.lstElm}
              </Grid>
            )} */}
            {!blnNotHideResearcher || appState?.userInfo?.jsnUserInfo?.blnNewRegStudent? null : (
              <Grid item xs="12" sm="12" md={ (blnNotHideSupport) ? "6" : "12"}>
                {jsxResearcherSetter.openEle}
              </Grid>
            )}
            {!blnNotHideSupport || appState?.userInfo?.jsnUserInfo?.blnNewRegStudent? null : (
              <Grid item xs="12" sm="12" md={ blnNotHideResearcher? "6" : "12"}>
                {jsxSponsorSetter.openEle}
              </Grid>
            )}
            {!blnNotHideVolunteer || appState?.userInfo?.jsnUserInfo?.blnNewRegStudent? null : (
              <Grid item xs="12" sm="12" md={ blnNotHideMDEA?"6": "12"}>
                {jsxVolunteerSetter.openEle}
              </Grid>
            )}
            {!blnNotHideMDEA || appState?.userInfo?.jsnUserInfo?.blnNewRegStudent? null : (
              <Grid item xs="12" sm="12" md={  blnNotHideVolunteer? "6" : "12"}>
                {jsxMDEASetter.openEle}
              </Grid>
            )}
          </Grid>
          <img src={lineImage} alt="" className={classes?.imgLine} />
          <ServicesView isLoading={isLoading} setIsLoading={setIsLoading} parentState={state} classes={classes} lang={lang} />
          <span style={{ fontFamily: "'MonteCarlo', cursive", opacity: 0, height: "0px" }}>&nbsp;</span>
        </Container>
      </section>

      {jsxSponsorSetter.modal}
      {jsxSponsorSetter?.modalTranstion}
      {jsxSponsorSetter?.modalImage}
      {jsxResearcherSetter?.modal}
      {jsxTraineeSetter.modal}
      {jsxTraineeSetter.modalImage}
      {jsxTraineeSetter?.modalGovCait}
      {jsxVolunteerSetter.modal}
      {jsxMDEASetter.modal}
      {jsxRunningActivity?.modal}
      {jsxRunningActivity?.modalStudent}
      {jsxRunningActivity?.modalStudentConfirmInvitation}
    </React.Fragment>
  );
}
