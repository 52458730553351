import React from "react";
import { ParallaxScrolling } from "@cbmisorg/client-app";
import aiImg from "../../assets/images/achievements.jpg";
 
const classes = {
  parallaxScrolling: {
    minHeight: "50px !important",
    position: "relative",
  },
};

function ParallaxScrollingApp(props) {
  return (
    <React.Fragment>
      <ParallaxScrolling src={props.img || aiImg} sx={classes?.parallaxScrolling} loading="lazy">
      {props?.children}
      </ParallaxScrolling> 
    </React.Fragment>
  );
}

export default ParallaxScrollingApp;
