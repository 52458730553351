import React, { useEffect, useRef, useState } from "react";
import "./Nav.css";
import Badge from "../../dataDisplay/Badge/Badge";
import { useNavigate } from "react-router-dom";
import Avatar from "../../dataDisplay/Avatar/Avatar";
import Typography from "../../dataDisplay/Typography/Typography";
import Icon from "../../../icon/CbmisIcon";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import { setSxClassName } from "../../../helper/convertJsCss";

const Nav = (props) => {
  const {
    label,
    path,
    menu,
    id,
    badge,
    color,
    textColor,
    headItem,
    headItemPosition,
    footerItem,
    icon,
    iconColor,
    iconSize,
    sx,
    hidden,
    disabled,
    dir,
    className,
    ...rest
  } = props;

  const screenWidth = useCheckScreenSize();

  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const getID = useRef(id || componentsFunction.randomName("AppBarNav"));
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-nav") });

  const [stateInfo, setStateInfo] = useState({
    isParentGrawerShowHeader: false,
    index: 1,
    selected: path === pathName ? "cbmis-app-bar-nav-selected" : "",
  });

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-nav");
    }
  }, [sx, screenWidth]);

  const handelLinlPathGo = () => {
    if (path) {
      if (String(path)[0] === "#" || String(path)[0] === ".") {
        if (String(path).length > 1) componentsFunction.scrollToElement(path);
      } else if (path && (String(path).toLowerCase().includes(".com") || String(path).toLowerCase().includes("http"))) {
        window.open(`${path}`, "_blank", true);
      } else if (path && (String(path).toLowerCase() === "back" || String(path).toLowerCase() === "-1")) {
        navigate(-1);
      } else if (path) {
        navigate(path);
      }
    }

    var element = document.querySelector(".cbmis-app-bar-side-bar-open");
    if (element && !menu) {
      element.classList.remove("cbmis-app-bar-side-bar-open");
    }
    const selectedNav = document.querySelectorAll(".cbmis-app-bar-nav-selected");
    selectedNav.forEach((item) => {
      item.classList.remove("cbmis-app-bar-nav-selected");
    });
    stateInfo.selected = "cbmis-app-bar-nav-selected";
    setStateInfo({ ...stateInfo });
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const getHeadItemPosition = () => {
    switch (String(headItemPosition).toLowerCase()) {
      case "end":
        return "cbmis-head-Item-Position-end";
      case "start":
        return "cbmis-head-Item-Position-start";
      case "bottom":
        return "cbmis-head-Item-Position-bottom";
      case "top":
        return "cbmis-head-Item-Position-top";
      default:
        return "cbmis-head-Item-Position-start";
    }
  };

  const navContentJXS = (
    <span className={`cbmis-app-bar-nav-content ${getHeadItemPosition()} ${badge ? "cbmis-app-bar-nav-content-badge" : ""}`} {...getRestProps()}>
      <span className="cbmis-app-bar-nav-content-headItem">
        {headItem ? (
          headItem
        ) : icon ? (
          <Icon icon={icon} size={iconSize || "22px"} color={iconColor} />
        ) : stateInfo.isParentGrawerShowHeader ? (
          <Avatar color={color} className="show-herder-default-heading">
            <Typography as="caption" color={color}>
              {stateInfo.index}
            </Typography>
          </Avatar>
        ) : null}
      </span>
      <span className="cbmis-app-bar-nav-content-content">{label || props.children}</span>
      <span className="cbmis-app-bar-nav-content-footerItem">{footerItem}</span>
    </span>
  );

  useEffect(() => {
    const child = document.getElementById(getID.current);
    stateInfo.isParentGrawerShowHeader = child.closest(".cbmis-drawer-show-header") !== null;
    stateInfo.index = Array.from(child.parentElement.children).indexOf(child);
    setStateInfo({ ...stateInfo });
  }, []);

  return (
    <ul
      className={`cbmis-app-bar-nav  ${componentsFunction.CheckDisabledItem(disabled)} ${componentsFunction.CheckHiddenItem(
        hidden
      )} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${componentsFunction.CheckDirectionItem(dir)} ${className || ""}`}
      id={getID.current}
    >
      <li onClick={handelLinlPathGo} className={`${stateInfo.selected}`} key={props.key}>
        {badge ? (
          <Badge content={badge} color={color} textColor={textColor}>
            {navContentJXS}
          </Badge>
        ) : (
          navContentJXS
        )}
        {menu ? (
         <div className="cbmis-menu-continuer">
         <ul className={`cbmis-app-bar-nav-sub-menu`} id={styleRef.current.strSXComponentID}>
            {menu.map((item, index) => {
              return <React.Fragment key={index}>{item}</React.Fragment>;
            })}
          </ul>
         </div>
        ) : null}
      </li>
    </ul>
  );
};

export default Nav;
