import React from 'react'
import Uploader from '../../Uploader'
import Icon from "../../../../icon/CbmisIcon"

function UploaderImageAvatar(props) {
    const {images,setImages,gallery,camera,handelOpenCamera,maxSizeKB,maxFilesNumber,maxWidth,maxHeight,quality} = props

  return (
    <React.Fragment>
        <div className="avatar-upload">
            {
                !camera?null:(
                    <div className="avatar-edit">
                        <button onClick={handelOpenCamera} className="avatar-edit-btn">
                            <Icon icon="camera" />
                        </button>
                    </div>
                )
            }
            <div className="avatar-preview">
            <React.Fragment>
                {
                    !gallery?null:(
                        <Uploader images={images} setImages={setImages}  maxSizeKB={maxSizeKB} maxFilesNumber={maxFilesNumber} maxWidth={maxWidth} maxHeight={maxHeight} quality={quality}/>
                    )
                }
            </React.Fragment>
            {   images.length?
                        <img src={URL.createObjectURL(images[0])} alt="/" className="image-preview"/>
                    :
                        <React.Fragment>
                            <Icon icon="image" className="iconImage"/>
                        </React.Fragment>
                }
            </div>
        </div>
    </React.Fragment>
  )
}

export default UploaderImageAvatar