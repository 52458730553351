import React, { useEffect, useState } from "react";
import ShredHeader from "../../../shared/shredHeader";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { useApp } from "../../../../configuration/contextapi/context";
import {
  Button,
  Container,
  Grid,
  Line,
  Nav,
  Paper,
  Table,
  TableFooter,
  TableRow,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  ModalHeader,
  ModalBody,
  Modal,
  Badge,
} from "@cbmisorg/material";
import { FormData, FormField } from "../../../../util/formApi/FormAPI";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./mngUser.style";
import { CtrlMngUser } from "./mngUsers.ctrl";
import { Icon } from "@cbmisorg/icons";
import { getDateUSFormat } from "../../../../util/appFunctions";
import { objUserRole, workSectorID, workSectorIDLabel } from "../../../../configuration/appVariables";
import TraineeSetterModalView from "./views/traineeSetterModal/traineeSetterModal.view";
import useCheckHooksGlb from "../../../../util/hooks/useCheckHooksGlb";

const label = dictionary?.components?.stakeholders?.admin?.mngUsers;
const rowNum = 6;
function MngUsersView() {
  useCheckHooksGlb();
  const { appState, appDispatch } = useApp();
  const lang = "eng"; //appState.clientInfo.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    lstUser: [],
    lstUserRead: [],
    curentIndex: 0,
    blnOpenStudentInfo: false,
    selectedRole: "",

    blnOpenInfo: false,
    strSelectedInterviewInfoKey: "",
    objSelectedInterviewInfo: {},

    blnOpenAddStudent: false,
    objUserSelected: {},
  });

  const fieldSearch = FormData({
    strSrearh: { control: "text", blnLanguage: false },
  });
  const handlers = CtrlMngUser(appState, appDispatch);
  const getUser = handlers?.getUser({ isLoading, setIsLoading, stateInfo, setStateInfo });
  const onChangeFilterRole = handlers?.onChangeFilterRole({ stateInfo, setStateInfo });
  const openModalInfo = handlers?.openModalInfo({ stateInfo, setStateInfo });
  const handelChangeObjUser = handlers?.handelChangeObjUser({ stateInfo, setStateInfo });
  const openAnyUserModal = handlers?.openAnyUserModal({ stateInfo, setStateInfo });
  const handelConfrmationEditByBlnKey = handlers?.handelConfrmationEditByBlnKey({ isLoading, setIsLoading, stateInfo, setStateInfo });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      getUser();
    }
  }, []);

  let index = 0;
  return (
    <React.Fragment>
      <ShredHeader title={label?.title?.[lang]} blnIsLight={true} blnIsAllLight={true} lang={lang} />
      <Container py-10 my-5>
        <Grid container justifyContent="center">
          <Grid item xs="12">
            <Paper py-0>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button icon="add" label={label?.newUser?.[lang]} size="sm" className={classes?.btnAdd} disabled/>
                </Grid>
                <Grid item xs="6" sm="auto" md="3" lg="2">
                  <FormField
                    objHandler={fieldSearch?.strSrearh}
                    className={classes?.fieldSearch}
                    endIcon="search"
                    iconColor={fieldSearch?.strSrearh?.getValue() ? "primary" : "#999"}
                  />
                </Grid>
              </Grid>
              <Line sx={{ width: "100%" }} />
              <Grid container justifyContent="space-between">
                <Grid item container>
                  <Grid item>
                    <Nav
                      label="Role"
                      menu={[
                        <Nav label="Employee" onClick={onChangeFilterRole(objUserRole?.Employee)} />,
                        <Nav label="Marketer" onClick={onChangeFilterRole(objUserRole?.Marketer)} />,
                        <Nav label="Receptionist" onClick={onChangeFilterRole(objUserRole?.Receptionist)} />,
                        <Nav
                          label="Member"
                          menu={[
                            <Nav
                              label="Student"
                              menu={[
                                <Nav label="All" onClick={onChangeFilterRole("blnIsStudent")} />,
                                <Nav label="Training approval" onClick={onChangeFilterRole("blnIsTrainingApproval")} />,
                                <Nav label="Initial approval" onClick={onChangeFilterRole("blnIsInitialApproval")} />,
                                <Nav label="Reject" onClick={onChangeFilterRole("blnIsReject")} />,
                              ]}
                            />,
                            <Nav label="Sponser" onClick={onChangeFilterRole("blnIsSponser")} />,
                            <Nav label="Researcher" onClick={onChangeFilterRole("blnIsResearcher")} />,
                            <Nav label="volunteer" onClick={onChangeFilterRole("blnIsVolunteer")} />,
                            <Nav label="MDEA" onClick={onChangeFilterRole("blnIsMDEA")} />,
                          ]}
                        />,
                      ]}
                    />
                  </Grid>
                  {!stateInfo?.selectedRole ? null : (
                    <Grid item onClick={onChangeFilterRole("")} sx={{ cursor: "pointer" }} spacing={0}>
                      <Icon icon="close" color="error" py-0 />
                      <Typography as="caption" pt-2 color="error">
                        Reset
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs="6" sm="auto" container>
                  <Grid item>
                    <Icon icon="people" color="primary" />
                    <Typography as="body2">{stateInfo?.lstUser?.length}</Typography>
                  </Grid>
                  <Grid item>
                    <Nav label="Filter" headItem={<Icon icon="FilterAlt" />} disabled/>
                  </Grid>
                </Grid>
              </Grid>
              <Line sx={{ width: "100%" }} />

              <Grid container justifyContent="center">
                <Grid item xs="12">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography as="subtitle2">#</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography as="subtitle2">Full Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography as="subtitle2">Sector</Typography>
                        </TableCell>
                        <TableCell>
                          <Grid container justifyContent="center">
                            <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                              Sponser
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid container justifyContent="center">
                            <Typography as="subtitle2" sx={{ textAlign: "center !important" }} p-0 m-0>
                              Student
                            </Typography>
                            <Icon
                              icon={stateInfo?.blnOpenStudentInfo ? "arrowbackios" : "arrowforwardios"}
                              p-1
                              m-0
                              onClick={() => setStateInfo({ ...stateInfo, blnOpenStudentInfo: !stateInfo?.blnOpenStudentInfo })}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                          <Typography as="subtitle3" sx={{ textAlign: "center !important" }}>
                            JS experience
                          </Typography>
                        </TableCell>
                        <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                          <Typography as="subtitle3" sx={{ textAlign: "center !important" }}>
                            Front-end experience
                          </Typography>
                        </TableCell>
                        <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                          <Typography as="subtitle3" sx={{ textAlign: "center !important" }}>
                            Node.js experience
                          </Typography>
                        </TableCell>
                        <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                          <Typography as="subtitle3" sx={{ textAlign: "center !important" }}>
                            AI experience
                          </Typography>
                        </TableCell>
                        <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                          <Typography as="subtitle3" sx={{ textAlign: "center !important" }}>
                            Cybersecurity experience
                          </Typography>
                        </TableCell>
                        <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                          <Typography as="subtitle3" sx={{ textAlign: "center !important" }}>
                            Other information
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Grid container justifyContent="center">
                            <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                              Researcher
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid container justifyContent="center">
                            <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                              Volunteer
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid container justifyContent="center">
                            <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                              MDEA
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                            Action
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
                            Last Login
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stateInfo?.lstUser?.map((user) => {
                        if (
                          (stateInfo?.selectedRole &&
                            !user?.[stateInfo.selectedRole] &&
                            String(user?.jsnUserInfo?.intUserRoleFK) !== stateInfo.selectedRole &&
                            !user?.jsnUserInfo?.[stateInfo.selectedRole]) ||
                          (stateInfo?.selectedRole === "blnIsReject" && (user?.jsnUserInfo?.blnIsTrainingApproval === true || user?.jsnUserInfo?.blnIsInitialApproval === true)) ||
                          user?.blnIsDeleted ||
                          String(user?.jsnUserInfo?.intUserRoleFK) === String(objUserRole?.RootAdmin) ||
                          (fieldSearch?.strSrearh?.getValue() &&
                            !user?.jsnUserInfo?.strFullName?.toString()?.toLowerCase()?.includes(String(fieldSearch?.strSrearh?.getValue())?.toLowerCase()) &&
                            !user?.strUserPhone?.toString()?.toLowerCase()?.includes(String(fieldSearch?.strSrearh?.getValue())?.toLowerCase()))
                        ) {
                          return null;
                        }
                        index++;
                        return (
                          <TableRow key={user?.bigUserID}>
                            <TableCell>
                              <Typography as="body2">{index}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography as="body2">{user?.jsnUserInfo?.strFullName?.[lang] || user?.jsnUserInfo?.strFullName}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography as="body2">{workSectorIDLabel?.[user?.jsnUserInfo?.bigWorkSector]?.[lang]}</Typography>
                            </TableCell>
                            <TableCell>
                              {user?.blnIsSponsor ? (
                                <Icon icon="HowToReg" color="success" />
                              ) : (
                                <Icon icon="addCircle" color="primary" onClick={handelConfrmationEditByBlnKey("blnIsSponsor", user)} />
                              )}
                            </TableCell>
                            <TableCell>
                              {user?.blnIsStudent ? (
                                <Nav
                                  menu={[
                                    ...(user?.jsnUserInfo?.blnIsTrainingApproval
                                      ? []
                                      : [
                                          <Nav
                                            headItem={<Icon icon="NewReleases" />}
                                            label={user?.jsnUserInfo?.blnIsInitialApproval ? "Cancel the initial approval" : "Initial Approval"}
                                            onClick={handelConfrmationEditByBlnKey("blnIsInitialApproval", user, true)}
                                          />,
                                        ]),
                                    ...(user?.jsnUserInfo?.blnIsTrainingApproval
                                      ? []
                                      : [
                                          <Nav
                                            headItem={<Icon icon="Verified" />}
                                            label={"Approval"}
                                            onClick={handelConfrmationEditByBlnKey("blnIsTrainingApproval", user, true)}
                                          />,
                                        ]),
                                    ...(user?.jsnUserInfo?.blnIsInitialApproval === false && user?.jsnUserInfo?.blnIsTrainingApproval === false
                                      ? []
                                      : [<Nav headItem={<Icon icon="NoAccounts" />} label="Reject" onClick={handelConfrmationEditByBlnKey("blnIsReject", user, true)} />]),
                                  ]}
                                  p-0
                                >
                                  <Badge
                                    mt-2
                                    mode="dot"
                                    color={
                                      user?.jsnUserInfo?.blnIsTrainingApproval
                                        ? "success"
                                        : user?.jsnUserInfo?.blnIsInitialApproval
                                        ? "primary"
                                        : user?.jsnUserInfo?.blnIsTrainingApproval === false
                                        ? "error"
                                        : "warning"
                                    }
                                  >
                                    <Icon icon="HowToReg" color="success" pt-2 />
                                  </Badge>
                                </Nav>
                              ) : (
                                <Icon icon="addCircle" color="primary" onClick={handelConfrmationEditByBlnKey("blnIsStudent", user)} />
                              )}
                            </TableCell>
                            <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                              {user?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs ? (
                                <Typography as="body3">
                                  {`${user?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs || 0} year - ${user?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject || 0} project`}
                                  {!user?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject ? null : (
                                    <Icon icon="info" color="info" onClick={openModalInfo("strExpJsProject", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                  )}
                                </Typography>
                              ) : (
                                <Icon icon="close" color="error" />
                              )}
                            </TableCell>
                            <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                              {user?.jsnUserInfo?.jsnInterviewInfo?.blnExFe ? (
                                <Typography as="body3" sx={{ textAlign: "center !important" }}>
                                  {`${user?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn || 0} year`}
                                  {!user?.jsnUserInfo?.jsnInterviewInfo?.strExpFeProject ? null : (
                                    <Icon icon="info" color="info" onClick={openModalInfo("strExpFeProject", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                  )}
                                </Typography>
                              ) : (
                                <Icon icon="close" color="error" />
                              )}
                            </TableCell>
                            <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                              {user?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode ? (
                                <Typography as="body3" sx={{ textAlign: "center !important" }}>
                                  {`${user?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode || 0} year - ${user?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject || 0} project`}
                                  {!user?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject ? null : (
                                    <Icon icon="info" color="info" onClick={openModalInfo("strExpNodeProject", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                  )}
                                </Typography>
                              ) : (
                                <Icon icon="close" color="error" />
                              )}
                            </TableCell>
                            <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                              {user?.jsnUserInfo?.jsnInterviewInfo?.blnExAi ? (
                                <Typography as="body3" sx={{ textAlign: "center !important" }}>
                                  {`${user?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi || 0} year`}
                                  {!user?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject ? null : (
                                    <Icon icon="info" color="info" onClick={openModalInfo("strExpAiProject", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                  )}
                                </Typography>
                              ) : (
                                <Icon icon="close" color="error" />
                              )}
                            </TableCell>
                            <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                              {user?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS ? (
                                <Typography as="body3" sx={{ textAlign: "center !important" }}>
                                  {`${user?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS || 0} year`}
                                  {!user?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject ? null : (
                                    <Icon icon="info" color="info" onClick={openModalInfo("strExpCybSProject", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                  )}
                                </Typography>
                              ) : (
                                <Icon icon="close" color="error" />
                              )}
                            </TableCell>
                            <TableCell className={!stateInfo?.blnOpenStudentInfo ? classes?.subTabel : classes?.subTabelOpen}>
                              {user?.jsnUserInfo?.bigWorkSector === workSectorID?.gov ? (
                                <React.Fragment>
                                  <Grid container justifyContent="flex-start">
                                    <Grid item>
                                      <Typography as="body3">{dictionary?.formapi?.blnAppsNeedDev?.label?.[lang]}</Typography>
                                    </Grid>
                                    <Grid item>
                                      {user?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev ? <Icon icon="check" color="success" /> : <Icon icon="close" color="error" />}
                                    </Grid>
                                    {!user?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal ? null : (
                                      <Grid item>
                                        <Icon icon="info" color="info" onClick={openModalInfo("strExplainProposal", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                      </Grid>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Grid container justifyContent="flex-start">
                                    <Grid item>
                                      <Typography as="body3">{dictionary?.formapi?.blnlikeWorkinGivSec?.label?.[lang]}</Typography>
                                    </Grid>
                                    <Grid item>
                                      {user?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec ? <Icon icon="check" color="success" /> : <Icon icon="close" color="error" />}
                                    </Grid>
                                    {!user?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal ? null : (
                                      <Grid item>
                                        <Icon icon="info" color="info" onClick={openModalInfo("strExplainProposal", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                      </Grid>
                                    )}
                                  </Grid>
                                  <Grid container justifyContent="flex-start">
                                    <Grid item>
                                      <Typography as="body3">{dictionary?.formapi?.blnGraduationProject?.label?.[lang]}</Typography>
                                    </Grid>
                                    <Grid item>
                                      {user?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject ? <Icon icon="check" color="success" /> : <Icon icon="close" color="error" />}
                                    </Grid>
                                  </Grid>
                                  <Grid container justifyContent="flex-start">
                                    <Grid item>
                                      <Typography as="body3">{dictionary?.formapi?.blnHaveGoveIdea?.label?.[lang]}</Typography>
                                    </Grid>
                                    <Grid item>
                                      {user?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea ? <Icon icon="check" color="success" /> : <Icon icon="close" color="error" />}
                                    </Grid>
                                    {!user?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea ? null : (
                                      <Grid item>
                                        <Icon icon="info" color="info" onClick={openModalInfo("strExplainIdea", user?.jsnUserInfo?.jsnInterviewInfo)} />
                                      </Grid>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              )}
                            </TableCell>
                            <TableCell>
                              {user?.blnIsResearcher ? (
                                <Icon icon="HowToReg" color="success" />
                              ) : (
                                <Icon icon="addCircle" color="primary" onClick={handelConfrmationEditByBlnKey("blnIsResearcher", user)} />
                              )}
                            </TableCell>
                            <TableCell>
                              {user?.blnIsVolunteer ? (
                                <Icon icon="HowToReg" color="success" />
                              ) : (
                                <Icon icon="addCircle" color="primary" onClick={handelConfrmationEditByBlnKey("blnIsVolunteer", user)} />
                              )}
                            </TableCell>
                            <TableCell>
                              {user?.blnIsMDEA ? (
                                <Icon icon="HowToReg" color="success" />
                              ) : (
                                <Icon icon="addCircle" color="primary" onClick={handelConfrmationEditByBlnKey("blnIsMDEA", user)} />
                              )}
                            </TableCell>
                            <TableCell>
                              <Grid container justifyContent="center">
                                <Grid item>
                                  <Icon icon="edit" color="info" disabled/>
                                </Grid>
                                <Grid item>
                                  <Icon icon="delete" color="error" onClick={handelConfrmationEditByBlnKey("blnIsDeleted", user)} />
                                </Grid>
                                <Grid item>
                                  <Icon icon="vpnkey" color="warning" disabled/>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Typography as="body2" sx={{ textAlign: "center !important" }}>
                                {getDateUSFormat(user?.dtmLastLogin)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>{}</TableFooter>
                  </Table>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal open={stateInfo?.blnOpenInfo} eventClose={openModalInfo()}>
        <ModalHeader>
          <Typography as="subtitle2" sx={{ textAlign: "center !important" }}>
            {dictionary?.formapi?.[stateInfo?.strSelectedInterviewInfoKey]?.label?.[lang] ||
              dictionary?.components?.public?.trainingRegistration?.[stateInfo?.strSelectedInterviewInfoKey]?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justifyContent="center">
            <Grid item xs="12" justifyContent="center">
              <Typography as="body2">{stateInfo?.objSelectedInterviewInfo?.[stateInfo?.strSelectedInterviewInfoKey]}</Typography>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <TraineeSetterModalView
        blnOpen={stateInfo?.blnOpenAddStudent}
        appState={appState}
        appDispatch={appDispatch}
        lang={lang}
        objUser={stateInfo?.objUserSelected}
        eventClose={openAnyUserModal("blnOpenAddStudent", null)}
        handelParentChangeObjUser={handelChangeObjUser}
      />
    </React.Fragment>
  );
}

export default MngUsersView;
