import React, { useEffect, useRef } from 'react'
import "./Permission.css"
import usePermissions from './usePermissions'
import * as permissionsVariable from "./PermissionsVariable/PermissionsVariable"
import * as permissionsVariableStyle from "./PermissionsVariable/PermissionsVariableStyle"

import Typography from '../../material/dataDisplay/Typography/Typography'
import Grid from '../../material/layout/Grid/Grid'
import CbmisIcon from '../../icon/CbmisIcon'
import MenuItem from '../../material/navigation/Menu/MenuItem'
import * as Confirmation  from "../../material/feedback/Confirmation/Confirmation";


function PermissionControl(props) {
    const {color,lang="eng",blnBackUrl=true, 
        appPermissionState = permissionsVariable.objPermissionState,viewLst=permissionsVariable.viewLst,
        changeAppPermissionState
    }=props
    const premission = usePermissions(appPermissionState,changeAppPermissionState,lang)

    const handlePermissionEvents=(e,permissionType)=>{
        if(e.target.checked){
            switch (permissionType) {
                case permissionsVariable.objPermissionKey.cameraPermission:
                    Confirmation.viewConfirmation(permissionsVariable.permissionLangConfirmation[lang][permissionType],
                        ()=>{premission.handleCameraAllow();},
                        ()=>{premission.handleRequestDenied(permissionType)},
                        permissionsVariable.permissionLangConfirmation[lang].yes,
                        permissionsVariable.permissionLangConfirmation[lang].no
                        )

                    break;
                case permissionsVariable.objPermissionKey.locationPermission:
                    Confirmation.viewConfirmation(permissionsVariable.permissionLangConfirmation[lang][permissionType],
                        ()=>{premission.handleLocationAllow();},
                        ()=>{premission.handleRequestDenied(permissionType)},
                        permissionsVariable.permissionLangConfirmation[lang].yes,
                        permissionsVariable.permissionLangConfirmation[lang].no
                        )
                    break;
                case permissionsVariable.objPermissionKey.microphonePermission:
                    Confirmation.viewConfirmation(permissionsVariable.permissionLangConfirmation[lang][permissionType],
                        ()=>{premission.handleMicrophoneAllow();},
                        ()=>{premission.handleRequestDenied(permissionType)},
                        permissionsVariable.permissionLangConfirmation[lang].yes,
                        permissionsVariable.permissionLangConfirmation[lang].no
                    )
                    break;
                case permissionsVariable.objPermissionKey.notificationsPermission:
                    Confirmation.viewConfirmation(permissionsVariable.permissionLangConfirmation[lang][permissionType],
                        ()=>{premission.handleNotificationsAllow()},
                        ()=>{premission.handleRequestDenied(permissionType)},
                        permissionsVariable.permissionLangConfirmation[lang].yes,
                        permissionsVariable.permissionLangConfirmation[lang].no
                    )
                    break;
                default:
                    break;
            }
        }else{
            e.target.checked=false
            premission.handleRequestDenied(permissionType)
        }

    }

    useEffect(() => {
        premission.checkAllPermission()

    }, [])
    
    useEffect(() => {
    }, [appPermissionState])
    

    const arrobjPermissionStateKey = Object.keys(appPermissionState)
  return (
    <React.Fragment>
    <Grid container spacing="0" dir="rtl">
        {
        arrobjPermissionStateKey.map((item,index)=>{
            return(
                viewLst.includes(item)?
                <Grid item xs="12" key={index}>
                    <MenuItem outlined={index !== arrobjPermissionStateKey.length-1} sx={{width:permissionsVariableStyle.width100}}
                        headItem={<CbmisIcon icon={permissionsVariable.objPermissionIcon[item]} iconColor={permissionsVariableStyle.colorText}/>}
                        footerItem={
                            <React.Fragment>
                                <input type="checkbox" 
                                        id={item} 
                                        value={item}
                                        checked={appPermissionState[item].user && appPermissionState[item].system}
                                        className="cbmis-app-Permission-switch"
                                        onChange={e=>handlePermissionEvents(e,item)}
                                        />
                                <label htmlFor={item} className='cbmis-app-Permission-switch-label' style={{color:color}}></label>
                            </React.Fragment>
                        
                        }
                    >
                        <Typography px-3>
                             {permissionsVariable.objPermissionLabel[lang][item]}
                        </Typography>
                    </MenuItem>
                </Grid>
                :null
            )
        }) 
        }
    </Grid>
</React.Fragment>
  )
}

export default PermissionControl