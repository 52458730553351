 const viewInlineTextDialog = (
    strText,
    direction,
    style = { iconColorBk: null, iconColorFill: null, borderColor: null, textColor: null, textAlign: null, fontSize: null, fontSize_xs: null }
  ) => {
    try {
      if (typeof window === "undefined" || typeof document === "undefined") {
        return;
      }
      //////////////////////////////////////////// init style ///////////////////////////////////////////////////
      //#region
      if (!style) {
      }
      if (!style?.borderColor) {
        style.borderColor = "#5458f7";
      }
      if (!style?.iconColorBk) {
        style.iconColorBk = "#ddd";
      }
      if (!style?.iconColorFill) {
        style.iconColorFill = "#cd3a37";
      }
      if (!style?.textColor) {
        style.textColor = "#55585c";
      }
      if (!style?.textAlign) {
        style.textAlign = "justify";
      }
      if (!style?.fontSize_xs) {
        style.fontSize_xs = style?.fontSize ? style?.fontSize : "13px";
      }
      if (!style?.fontSize) {
        style.fontSize = "16px";
      }
      //#endregion
      //////////////////////////////////////////// create icon ///////////////////////////////////////////////////
      //#region
      const xmlns = "http://www.w3.org/2000/svg";
      const closeIcon = document.createElementNS(xmlns, "svg");
      closeIcon.setAttributeNS(null, "viewBox", "0 0 24 24");
      const path1 = document.createElementNS(xmlns, "path");
      path1.setAttributeNS(null, "d", "M0 0h24v24H0z");
      path1.setAttributeNS(null, "fill", "none");
      const path2 = document.createElementNS(xmlns, "path");
      path2.setAttributeNS(null, "d", "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z");
      closeIcon.appendChild(path1);
      closeIcon.appendChild(path2);
      closeIcon.style.cursor = "pointer";
      closeIcon.style.fill = style.iconColorFill;
      closeIcon.style.width = "1.5rem";
      closeIcon.style.borderRadius = `50%`;
      closeIcon.style.background = style.iconColorBk;
      //#endregion
      //////////////////////////////////////// root of modal //////////////////////////////////////////////
      //#region
      const modal = document.createElement("div");
  
      modal.style.position = "fixed";
      modal.style.left = "0px";
      modal.style.top = "0px";
      modal.style.bottom = "0px";
      modal.style.right = "0px";
      modal.style.width = "100%";
      modal.style.height = "100%";
      modal.style.margin = "0px";
      modal.style.padding = "0px";
      modal.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      modal.style.opacity = "1";
      modal.style.transform = "scale(1)";
      modal.style.transition = "visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s";
      modal.style.display = "flex";
      modal.style.flexDirection = "row";
      modal.style.justifyContent = "center";
      modal.style.alignSelf = "center";
      modal.style.alignItems = "center";
      modal.style.alignContent = "center";
      modal.style.flexWrap = "wrap";
      modal.style.boxSizing = "border-box";
      modal.style.zIndex = 1301;
      modal.style.direction = direction === "rtl" ? direction : "ltr";
      //#endregion
      ////////////////////////////////////// container ///////////////////////////////////////////////////
      //#region
      const modalContainer = document.createElement("div");
      modalContainer.animate(
        [
          {
            transform: "scale(0)",
            opacity: "0",
          },
          {
            transform: "scale(1)",
            opacity: "1",
          },
        ],
        {
          duration: 200,
          iterations: 1,
        }
      );
      modalContainer.style.position = "relative";
      modalContainer.style.margin = "0px";
      modalContainer.style.display = "flex";
      modalContainer.style.flexGrow = "0";
      modalContainer.style.flexBasis = `${(100 / 12) * (window.innerWidth < 600 ? 11 : 10)}%`;
      modalContainer.style.width = `${(100 / 12) * (window.innerWidth < 600 ? 11 : 10)}%`;
      modalContainer.style.flexWrap = "wrap";
      modalContainer.style.justifyContent = "space-between";
      modalContainer.style.boxSizing = "border-box";
      modalContainer.style.background = "#ffffff";
      modalContainer.style.border = `1px solid ${style.borderColor}`;
      modalContainer.style.padding = "1.25em 1.25em";
      modalContainer.style.borderRadius = "0.3em";
      modalContainer.style.boxShadow = `1px 1px 5px ${style.borderColor + "a1"}`;
  
      modal.appendChild(modalContainer);
  
      //#endregion
      ////////////////////////////////////// close icon item ///////////////////////////////////////////////////
      //#region
      const modalIcon = document.createElement("div");
      modalIcon.style.position = "absolute";
      modalIcon.style.top = "-0.75em";
      if (direction === "rtl") {
        modalIcon.style.left = "-0.75em";
      } else {
        modalIcon.style.right = "-0.75em";
      }
      modalIcon.style.margin = "0px";
      modalIcon.style.cursor = "pointer";
      modalIcon.appendChild(closeIcon);
  
      modalIcon.onmouseenter = function () {
        closeIcon.style.fill = style.iconColorBk;
        closeIcon.style.background = style.iconColorFill;
      };
  
      modalIcon.onmouseleave = function () {
        closeIcon.style.fill = style.iconColorFill;
        closeIcon.style.background = style.iconColorBk;
      };
  
      modalContainer.appendChild(modalIcon);
      modalIcon.onclick = function (event) {
        while (modal?.firstChild) {
          modal.removeChild(modal.lastChild);
        }
        modal.remove();
      };
      //#endregion
      ////////////////////////////////////// text item ///////////////////////////////////////////////////
      //#region
      const modalDetail = document.createElement("div");
      modalDetail.style.position = "relative";
      modalDetail.style.margin = "0px";
      modalDetail.style.display = "flex";
      modalDetail.style.flexGrow = "0";
      modalDetail.style.flexBasis = `${(100 / 12) * 12}%`;
      modalDetail.style.width = `${(100 / 12) * 12}%`;
      modalDetail.style.flexWrap = "wrap";
      modalDetail.style.alignSelf = "flex-start";
      modalDetail.style.alignItems = "flex-start";
      modalDetail.style.whiteSpace = "pre-line";
      modalDetail.style.overflow = "auto";
      modalDetail.style.maxHeight = "calc(100vh - 2.5em - 3em)";
      modalDetail.style.fontSize = window.innerWidth < 600 ? style.fontSize_xs : style.fontSize;
      modalDetail.style.textAlign = style.textAlign;
      modalDetail.style.color = style.textColor;
      modalDetail.innerHTML = strText;
  
      modalContainer.appendChild(modalDetail);
      //#endregion
      ////////////////////////////////////// add to body /////////////////////////////////////////////
      //#region
      document.body.appendChild(modal);
  
      modal.onclick = function (event) {
        if (event?.target === modal) {
          while (event?.target?.firstChild) {
            event.target.removeChild(event.target.lastChild);
          }
          event.target.remove();
        }
      };
  
      return {
        eventClose: () => {
          while (modal?.firstChild) {
            modal.removeChild(modal.lastChild);
          }
          modal.remove();
        },
      };
      //#endregion
    } catch (error) {
      //console.log(error)
    }
  };
  export default viewInlineTextDialog