import React, { useEffect, useRef } from "react";
import "./Tab.css";
import "../../../style/main.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import { setSxClassName } from "../../../helper/convertJsCss";
import useCheckScreenSize from "../../../hook/useCheckScreenSize";
import Button from "../../inputs/Button/Button";

function TabsHead(props) {
  const { tabsContent, navigateTotabHandler, currentTabIndex, activeColor, className, dir, hidden, disabled, sx, ...rest } = props;

  const screenWidth = useCheckScreenSize();

  const getNameInput = componentsFunction.randomName("tab-input");
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-tab") });

  useEffect(() => {}, [tabsContent, navigateTotabHandler, currentTabIndex, activeColor, className, dir, hidden, disabled, sx]);

  useEffect(() => {
    if (sx) {
      setSxClassName(styleRef, styleRef.current.strSXComponentID, sx, "cbmis-tab");
    }
  }, [sx, screenWidth]);

  useEffect(() => {
    getScroll();
  }, [tabsContent]);

  const handleTabClicked = (e, i, el) => {
    navigateTotabHandler(i);
    if (el.onChange) {
      el.onChange(e);
    }
  };

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  const handelScroll = (e) => {
    const dirRight = document.querySelector(".cbmis-body-dir-rtl") || dir === "rtl";
    const scrollStart = dirRight ? e.target.style.scrollRight : e.target.style.scrollLeft;
    const innerWidth = e.target.offsetWidth;
    const scrollWidth = e.target.scrollWidth;

    if (scrollStart + innerWidth >= scrollWidth) {
      document.querySelector(".cbmis-tab-header-nav-next").style.display = "none";
    } else {
      document.querySelector(".cbmis-tab-header-nav-next").style.display = "block";
    }
    if (scrollStart === 0) {
      document.querySelector(".cbmis-tab-header-nav-prev").style.display = "none";
    } else {
      document.querySelector(".cbmis-tab-header-nav-prev").style.display = "block";
    }
  };

  const handelNext = () => {
    const div = document.querySelector(".cbmis-tabs");
    div.scrollLeft += 200;
  };

  const handelPrev = () => {
    const div = document.querySelector(".cbmis-tabs");
    div.scrollLeft -= 200;
  };

  const getScroll = () => {
    const tabs = document.querySelector(".cbmis-tabs");
    const innerWidth = tabs.offsetWidth;
    const scrollWidth = tabs.scrollWidth;
    const dirRight = document.querySelector(".cbmis-body-dir-rtl") || dir === "rtl";
    if (dirRight) {
      if (Number(innerWidth) < Number(scrollWidth)) {
        document.querySelector(".cbmis-tab-header-nav-prev").style.display = "block";
      }
    } else {
      if (Number(innerWidth) < Number(scrollWidth)) {
        document.querySelector(".cbmis-tab-header-nav-next").style.display = "block";
      }
    }
  };

  return (
    <div
      className={`cbmis-tabs-head ${className || ""} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
        disabled
      )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)}`}
      {...getRestProps()}
    >
      <Button icon="arrowBackIos" className="cbmis-tab-header-nav-prev cbmis-tab-header-arrow" onClick={handelPrev} size="sm" p-0/>

      <div id={styleRef.current.strSXComponentID} className={`cbmis-tabs`} onScroll={handelScroll}>
        {tabsContent.map((el, i) => (
          <div className="cbmis-tab-container" key={i} style={{ width: `${100 / tabsContent.length}%` }}>
            <input
              type="radio"
              id={`cbmis-tabs-radio-input-${i}`}
              className="cbmis-tabs-radio-input"
              name={getNameInput}
              checked={parseInt(i) === parseInt(currentTabIndex)}
              onChange={(e) => handleTabClicked(e, i, el)}
            />
            <label className="cbmis-tab" htmlFor={`cbmis-tabs-radio-input-${i}`} style={{ color: activeColor}}>
              <div>
                {
                  el?.head?(
                    <div className="head-item-tab">{el?.head}</div>
                  ):""
                }
                {el.tabLabel}
              </div>
            </label>
          </div>
        ))}
      </div>

      <Button icon="arrowForwardIos" className="cbmis-tab-header-nav-next cbmis-tab-header-arrow" onClick={handelNext} size="sm" p-0/>

    </div>
  );
}

export default TabsHead;
