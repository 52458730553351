import { StyleBuilder, styleVariables, cssKeys, mainTheme, styleFunction } from "@cbmisorg/styles";

export default (objThemeInfo) => {
  const cssObject = objThemeInfo?.cssObject || mainTheme?.cssObject;
  const currentBaseSize = objThemeInfo?.currentBaseSize || mainTheme?.currentBaseSize;

  const classesName = {
    checkbox_container: `${styleVariables?.prefixDefaultClassName}_checkbox_container`,
    checkbox: `${styleVariables?.prefixDefaultClassName}_checkbox`,
    checkbox_opt_label: `${styleVariables?.prefixDefaultClassName}_checkbox_opt_label`,
    checkbox_opt: `${styleVariables?.prefixDefaultClassName}_checkbox_opt`,
    checkbox_label: `${styleVariables?.prefixDefaultClassName}_checkbox_label`,
    sx: `${styleVariables?.prefixDefaultClassName}_checkbox_sx`,
  };
  const style = StyleBuilder({
    blnRandomPrefix: false,
    blnIsDefaultStyle: true,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.checkbox_container]: {
        position: "relative",
        width: "100%",
        margin: 0,
        display: "inline-block",
      },
      [classesName?.checkbox]: {
        appearance: "none",
        WebkitAppearance: "none",
        /* remove default */
        display: "flex",
        alignSelf: "flex-start",
        position: "relative",
        borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
        cursor: "pointer",
        verticalAlign: "middle",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
        backgroundColor: cssObject?.color?.[cssKeys?.light],
        WebkitTransition: "background-position .15s cubic-bezier(.8, 0, 1, 1), -webkit-transform .25s cubic-bezier(.8, 0, 1, 1)",
        outline: "none",
        margin: 0,
        top: ".7rem",
        "&active": {
          WebkitTransform: "scale(1.5)",
          WebkitTransition: "-webkit-transform .1s cubic-bezier(0, 0, .2, 1)",
        },
        "&after": {
          content: "",
          position: "absolute",
          display: "block",
          top: "38%",
          left: "10%",
          margin: 0,
          scale: "0",
          transform: "translate(-50%, -50%)",
          transition: ".1s ease-in-out",
        },
        "&before": {
          content: "",
          position: "absolute",
          display: "block",
          top: "38%",
          left: "10%",
          margin: 0,
          scale: "0",
          transform: "translate(-50%, -50%)",
          transition: ".1s ease-in-out",
        },
      },
      [classesName?.checkbox_label]: {
        display: "flex",
        alignItems: "center",
        fontWeight: "600",
        fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
        textAlign: "start",
        width: "100%",
      },
      [classesName?.checkbox_opt]: {
        display: "flex",
        width: "inherit",
        justifyContent: "flex-start",
        alignItems: "baseline",
        // flexWrap: "wrap",
      },
      [classesName?.checkbox_opt_label]: {
        display: "inline-block",
        width: "max-content",
        margin: "0px",
      },
    },
  });

  // const styleSx = StyleBuilder({
  //   blnRandomPrefix: true,
  //   blnIsDefaultStyle: false,
  //   blnCbmisStyle: true,
  //   objStyles: {
  //     [classesName?.checkbox_container]: (props) => {
  //       return {
  //         padding: `${cssObject?.spacing?.[cssKeys?.px]} ${cssObject?.spacing?.[cssKeys?.xs]}`,
  //         display: `${String(props?.display).toLowerCase() === "inline" && !props?.blnMenuInputList ? "flex" : "block"}`,
  //         width: props?.blnMenuInputList ? "99%" : "maxContent",
  //         // flexWrap: "wrap",
  //       };
  //     },
  //     [classesName?.checkbox]: (props) => {
  //       return {
  //         minWidth: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
  //         width: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
  //         height: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
  //         opacity: props?.blnMenuInputList ? 0 : 1,
  //         "&hover": {
  //           backgroundColor: cssObject?.color?.[`${cssKeys?.primary}_1`],
  //           ...props?.sx?.["&hover"],
  //         },
  //         "&checked": {
  //           WebkitTransition: "background-position .1s .15s cubic-bezier(0, 0, .2, 1),-webkit-transform .25s cubic-bezier(0, 0, .2, 1)",
  //           backgroundImage: props?.disabled
  //             ? cssObject?.color?.[cssKeys?.disabled]
  //             : `-webkit-linear-gradient(90deg, ${styleFunction?.checkColorReturnValue(
  //                 `${props?.color || cssObject?.color?.[`${cssKeys?.primary}_6`]}`
  //               )} 0%, ${styleFunction?.checkColorReturnValue(`${props?.color || cssObject?.color?.[`${cssKeys?.primary}`]}f9`)} 65%, ${styleFunction?.checkColorReturnValue(
  //                 `${props?.color || cssObject?.color?.[`${cssKeys?.primary}_8`]}63`
  //               )} 100%)`,
  //           backgroundRepeat: "no-repeat",
  //           boxShadow: `0 0 0 0.05em ${cssObject?.color?.[cssKeys?.white]}, 0 0 0.15em 0.1em ${
  //             props?.disabled ? cssObject?.color?.[cssKeys?.disabled] : cssObject?.color?.[cssKeys?.primary]
  //           }`,
  //           ...getMode(props),
  //           ...props?.sx?.["&checked"],
  //           "&+": props?.blnMenuInputList
  //             ? {
  //                 [`.${classesName?.checkbox_opt_label}`]: {
  //                   backgroundColor: cssObject?.color?.[`${cssKeys?.primary}_1`],
  //                   color: cssObject?.color?.[cssKeys?.primary],
  //                 },
  //               }
  //             : null,
  //         },
  //       };
  //     },
  //     [classesName?.checkbox_opt]: (props) => {
  //       return {
  //         color: cssObject?.color?.[cssKeys?.bodyColor],
  //         fontSize: cssObject?.text?.size?.[cssKeys?.body3],
  //         margin: "auto 0px",
  //         // margin:String(props?.display).toLowerCase() === "inline"?  `${cssObject?.spacing?.[cssKeys?.sm]}` : `${cssObject?.spacing?.[cssKeys?.sm]} ${cssObject?.spacing?.[cssKeys?.xs]}`,
  //         justlyContent: "center",
  //         alignItem: "center",
  //         textAlign: "start",
  //         width: props?.blnMenuInputList ? "100%" : "inherit",
  //         [`.${classesName?.checkbox_opt_label}`]: {
  //           padding:
  //             String(props?.display).toLowerCase() === "inline"
  //               ? `${cssObject?.spacing?.[cssKeys?.sm]}`
  //               : `${cssObject?.spacing?.[cssKeys?.sm]} ${cssObject?.spacing?.[cssKeys?.xs]}`,
  //           fontSize: cssObject?.text?.size?.[cssKeys?.body3],
  //           ...(props?.blnMenuInputList
  //             ? {
  //                 width: "100%",
  //                 cursor: "pointer",
  //                 "&hover": {
  //                   backgroundColor: cssObject?.color?.[cssKeys?.light],
  //                 },
  //               }
  //             : null),
  //         },
  //       };
  //     },
  //     [classesName?.checkbox_label]: {
  //       fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
  //       textAlign: "start",
  //       maxWidth: "fit-content",
  //       margin: 0,
  //     },
  //     [classesName?.sx]: (props) => {
  //       return {
  //         ...props?.sx,
  //       };
  //     },
  //   },
  // });

  const styleSx = StyleBuilder({
    blnRandomPrefix: true,
    blnIsDefaultStyle: false,
    blnCbmisStyle: true,
    objStyles: {
      [classesName?.checkbox_container]: (props) => {
        return {
          padding: `${cssObject?.spacing?.[cssKeys?.px]}`,
          margin: "auto",
          display: `${String(props?.display).toLowerCase() === "inline" && !props?.blnMenuInputList ? "flex" : "block"}`,
          width: props?.blnMenuInputList ? "99%" : "maxContent",
          // flexWrap: "wrap",
        };
      },
      [classesName?.checkbox]: (props) => {
        return {
          minWidth: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
          width: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
          height: props?.blnMenuInputList ? 0 : cssObject?.size?.[cssKeys?.item_2xs],
          opacity: props?.blnMenuInputList ? 0 : 1,
          "&hover": {
            backgroundColor: cssObject?.color?.[`${cssKeys?.primary}_1`],
            ...props?.sx?.["&hover"],
          },
          "&checked": {
            WebkitTransition: "background-position .1s .15s cubic-bezier(0, 0, .2, 1),-webkit-transform .25s cubic-bezier(0, 0, .2, 1)",
            backgroundImage: props?.disabled
              ? cssObject?.color?.[cssKeys?.disabled]
              : `-webkit-linear-gradient(90deg, ${styleFunction?.checkColorReturnValue(
                  `${props?.color || cssObject?.color?.[`${cssKeys?.primary}_6`]}`
                )} 0%, ${styleFunction?.checkColorReturnValue(`${props?.color || cssObject?.color?.[`${cssKeys?.primary}`]}f9`)} 65%, ${styleFunction?.checkColorReturnValue(
                  `${props?.color || cssObject?.color?.[`${cssKeys?.primary}_8`]}63`
                )} 100%)`,
            backgroundRepeat: "no-repeat",
            boxShadow: `0 0 0 0.05em ${cssObject?.color?.[cssKeys?.white]}, 0 0 0.15em 0.1em ${
              props?.disabled ? cssObject?.color?.[cssKeys?.disabled] : cssObject?.color?.[cssKeys?.primary]
            }`,
            ...getMode(props),
            ...props?.sx?.["&checked"],
            "&+": props?.blnMenuInputList
              ? {
                  [`.${classesName?.checkbox_opt_label}`]: {
                    backgroundColor: cssObject?.color?.[`${cssKeys?.primary}_1`],
                    color: cssObject?.color?.[cssKeys?.primary],
                  },
                }
              : null,
          },
        };
      },
    },
    [classesName?.checkbox_opt]: (props) => {
      return {
        color: cssObject?.color?.[cssKeys?.currentColor],
        fontSize: cssObject?.text?.size?.[cssKeys?.body3],
        margin: "auto 0px",
        // margin:String(props?.display).toLowerCase() === "inline"?  `${cssObject?.spacing?.[cssKeys?.sm]}` : `${cssObject?.spacing?.[cssKeys?.sm]} ${cssObject?.spacing?.[cssKeys?.xs]}`,
        justlyContent: "center",
        alignItem: "center",
        textAlign: "start",
        width: props?.blnMenuInputList ? "100%" : "inherit",
        [`.${classesName?.checkbox_opt_label}`]: {
          padding:
            String(props?.display).toLowerCase() === "inline"
              ? `${cssObject?.spacing?.[cssKeys?.sm]}`
              : `${cssObject?.spacing?.[cssKeys?.sm]} ${cssObject?.spacing?.[cssKeys?.sm]}`,
          fontSize: cssObject?.text?.size?.[cssKeys?.body3],
          ...(props?.blnMenuInputList
            ? {
                width: "100%",
                cursor: "pointer",
                "&hover": {
                  backgroundColor: cssObject?.color?.[cssKeys?.light],
                },
              }
            : null),
        },
      };
    },
    [classesName?.checkbox_label]: {
      fontSize: cssObject?.text?.size?.[cssKeys?.subtitle3],
      textAlign: "start",
      maxWidth: "fit-content",
      margin: 0,
    },
    [classesName?.sx]: (props) => {
      return {
        ...props?.sx,
      };
    },
  });

  const getMode = (props) => {
    switch (String(props?.mode)?.toLowerCase()) {
      case "cross":
        return {
          "&after": {
            content: "",
            width: "75%",
            height: "20%",
            backgroundColor: cssObject?.color?.[cssKeys?.bodyColorBg],
            rotate: "45deg",
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
            transition: ".1s ease-in-out",
            transform: "rotate(180deg)",
            scale: 1,
            boxShadow: `0 0 0 0.05em ${cssObject?.color?.[cssKeys?.white]}, 0 0 0.15em 0.1em ${cssObject?.color?.[cssKeys?.primary]}`,
          },
          "&before": {
            content: "",
            width: "75%",
            height: "20%",
            backgroundColor: cssObject?.color?.[cssKeys?.bodyColorBg],
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
            rotate: "-45deg",
            transition: ".1s ease-in-out",
            transform: "rotate(180deg)",
            scale: 1,
            boxShadow: `0 0 0 0.05em ${cssObject?.color?.[cssKeys?.white]}, 0 0 0.15em 0.1em ${cssObject?.color?.[cssKeys?.primary]}`,
          },
        };
      case "indeterminate":
        return {
          "&before": {
            width: "60%",
            height: "20%",
            left: "20%",
            backgroundColor: cssObject?.color?.[cssKeys?.bodyColorBg],
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.xs,
            transition: ".1s ease-in-out",
            transform: "rotate(180deg)",
            scale: 1,
            boxShadow: `0 0 0 0.05em ${cssObject?.color?.[cssKeys?.primary]}, 0 0 0.15em 0.1em ${cssObject?.color?.[cssKeys?.white]}`,
          },
        };
      default:
        return {
          "&before": {
            width: "65%",
            height: "35%",
            top: "15%",
            borderBottom: `${cssObject?.size?.[cssKeys?.border_sm]} solid ${cssObject?.color?.[cssKeys?.bodyColorBg]}`,
            borderLeft: `${cssObject?.size?.[cssKeys?.border_sm]} solid ${cssObject?.color?.[cssKeys?.bodyColorBg]}`,
            borderRadius: cssObject?.border?.[cssKeys?.rounded]?.px,
            rotate: "135deg",
            transition: ".1s ease-in-out",
            transform: "rotate(180deg)",
            scale: 1,
          },
        };
    }
  };
  return {
    classesName,
    style,
    styleSx,
  };
};
