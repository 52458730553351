import React, { useEffect } from 'react'
import ViewCategories from './ViewCategories/ViewCategories'

function ManageCategories(props) {
  const {
    list,rootID,objFelidName,lstSelectedID,blnOpenDialog,
    blnRootHasCheckbox,blnAddRoot,blnEditRoot,blnDeleteRoot,blnSearch,blnAddUnderTheRoot,
    blnAdd, blnEdit, blnDelete,
    handleAdd,handleEdit,handleDelete,handleChecked,handleSubmit,FormComponent,handleOpenDialog,
    color,
    objLabels,objLanguage,currentLanguage
  } = props

  useEffect(() => {},[
    rootID,objFelidName,blnOpenDialog,
    blnRootHasCheckbox,blnAddRoot,blnEditRoot,blnDeleteRoot,blnSearch,
    blnAdd, blnEdit, blnDelete,
    handleAdd,handleEdit,handleDelete,handleChecked,handleSubmit,FormComponent,handleOpenDialog,
    color,
    objLabels,objLanguage,currentLanguage
  ])

  return (
      <ViewCategories
              blnIsSelect={false}

              list={list || []}
              rootID={rootID || 0}
              objFelidName={objFelidName}
              lstSelectedID = {lstSelectedID}
              blnRootHasCheckbox = {blnRootHasCheckbox}
              blnAddRoot = {blnAddRoot}
              blnAddUnderTheRoot ={blnAddUnderTheRoot}
              blnEditRoot={blnEditRoot}
              blnDeleteRoot={blnDeleteRoot}
              blnSearch = {blnSearch}

              blnOpenDialog={blnOpenDialog}

              blnAdd={blnAdd}
              blnEdit={blnEdit}
              blnDelete={blnDelete}

              handleAdd={handleAdd}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleChecked={handleChecked}
              handleSubmit={handleSubmit}
              FormComponent={FormComponent}
              handleOpenDialog={handleOpenDialog}

              color={color}

              objLabels={objLabels}
              objLanguage={objLanguage}
              currentLanguage={currentLanguage}
            />
      )


}

export default ManageCategories