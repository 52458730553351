export const labels = {
  herorTitle: {
    arb: "بادر",
    eng: "TAKE THE INITIATIVE",
  },
  title: {
    arb: "مبادرة تدريب 1000 مبرمج كويتي",
    eng: "Training Initiative for 1000 Kuwaiti Programmers",
  },
  subtitle: {
    arb: "من أجل كويت متقدمة تقنياَ",
    eng: "For a Technologically Advanced Kuwait",
  },
  btnTrainee: {
    arb: "انضم كمتدرب",
    eng: "Join as Trainee",
  },
  btnSponser: {
    arb: "بادر بالدعم",
    eng: "Start Supporting",
  },
  about: {
    title: {
      arb: "هندسة البرمجيات الذكية والاَمنة وكيفية تحقيق التمكين الرقمي ﻓﻲ دوﻟﺔ اﻟﻜﻮﻳﺖ",
      eng: "Intelligent and Secure Software Engineering and Achieving Digital Empowerment in Kuwait",
    },
    date: {
      arb: "16 فبراير 2025",
      eng: "16 Feb 2025",
    },
    titleNav: {
      arb: "عن المبادرة",
      eng: "About Initiative",
    },
    content: {
      arb: "أول وأﻛﺒﺮ ﻣﺒﺎدرة تدريب ﻟﺘﺤﻘﻴﻖ اﻟﺘﻤﻜﻴﻦ اﻟﺮﻗﻤﻲ في مختلف القطاعاﺖ. التدريب معتمد من الجهاز المركزي لتكنولوجيا المعلومات و مصمم باستخدام مجموعة متنوعة من الأساليب التدريسية الحديثة للتي تتبنى إستراتيجيتة لضمان تجربة تعليمية شاملة ومرنة تضمن تأهيل أكبر عدد من الكوادر الوطنية بما يتماشى مع ظروفهم وأوقات فراغهم. يركز هذا التدريب على توظيف أحدث لغات البرمجة والتقنيات المستخدمة عالمياَ من أجل بناء تطبيقات مبتكرة تدعم المؤسسات في تحقيق أهداف التمكين الرقمي بكفاءة. تم تصميم البرنامج التدريبي ليكون شاملاً ومناسباً لجميع المستويات، حيث لا يُشترط على المتدربين امتلاك خبرات برمجية مسبقة. كما و يركز على تزويد المشاركين بجميع المهارات التقنية اللازمة لتطوير تطبيقات ذكية وآمنة، تعمل بكفاءة وفعالية على مختلف الأجهزة والمتصفحات. ويهدف البرنامج إلى تقديم حلول تقنية متقدمة تلبي متطلبات العصر الرقمي وتسهم في تحقيق رؤية الكويت الجديدة 2035",
      eng: `The Smart and Secure Software Engineering Training Program is the first and largest initiative aimed at achieving digital empowerment across various sectors. Accredited by the Central Agency for Information Technology, the program is designed using a diverse range of modern teaching methods to ensure a comprehensive and flexible learning experience. It is tailored to accommodate participants' schedules and circumstances, enabling the training of the largest possible number of national talents. The program focuses on utilizing the latest programming languages and globally adopted technologies to develop innovative applications that help organizations efficiently achieve their digital empowerment goals. Designed to be inclusive and suitable for all levels, the program does not require prior programming experience. It also equips participants with all the technical skills needed to develop intelligent and secure applications that perform effectively and efficiently across various devices and browsers. This program aims to deliver advanced technological solutions that meet the demands of the digital age and contribute to realizing Kuwait’s New Vision 2035`,
    },
  },
  objectives: {
    titleNav: { arb: "الأهداف", eng: "Objectives" },
    title: {
      arb: "أهداف المبادرة",
      eng: "Objectives of the Initiative",
    },
    content: {
      arb: "تستهدف المبادرة موظفي القطاعي العام والخاص وطلبة الجامعات الراغبين في العمل في مجال تكنولوجيا المعلومات, وتهدف الى ما يلي:",
      eng: "This initiative targets employees from both the public and private sectors, as well as university students interested in pursuing careers in Information Technology. Its goals include:",
    },
    lst: [
      {
        title: { arb: "تمكين موظفي القطاع العام", eng: "Empowering Public Sector Employees" },
        decr: {
          arb: "تعزيز قدرات موظفي القطاع العام لتطوير مشاريع حكومية مبتكرة تسهم في تحقيق التمكين الرقمي على مستوى دولة الكويت، بما يواكب رؤية الدولة للتحول الرقمي",
          eng: "Enhance the capabilities of public sector employees to develop innovative government projects that contribute to achieving digital empowerment across Kuwait, in line with the nation's digital transformation vision",
        },
      },
      {
        title: { arb: "تطوير المهارات العملية لطلبة التخصصات التقنية", eng: "Developing Practical Skills for University Students" },
        decr: {
          arb: "يهدف التدريب إلى رفع المستوى العملي لطلبة الجامعات، مما ينعكس إيجابيًا على تحصيلهم الأكاديمي ويعزز جاهزيتهم لسوق العمل",
          eng: "The training aims to improve the practical skills of university students, positively impacting their academic performance and enhancing their readiness for the job market",
        },
      },
      {
        title: { arb: "دعم رواد الأعمال", eng: "Supporting Entrepreneurs" },
        decr: {
          arb: "مساعدة رواد الأعمال في تحويل أفكارهم الريادية إلى تطبيقات تقنية قابلة للتسويق، مما يسهم في إنشاء مشاريع مبتكرة تدعم الاقتصاد المحلي",
          eng: "Assist entrepreneurs in turning their innovative ideas into marketable tech applications, contributing to the creation of innovative projects that support the local economy",
        },
      },
      {
        title: { arb: "تعزيز مهارات موظفي القطاع الخاص", eng: "Enhancing Private Sector Employees’ Skills" },
        decr: {
          arb: "تطوير مهارات موظفي القطاع الخاص في مجالات التكنولوجيا الحديثة مثل الذكاء الاصطناعي، الأمن السيبراني، وتطوير التطبيقات، مما يساعدهم على تحسين أدائهم المهني ويسهم في تعزيز الابتكار وزيادة الإنتاجية في بيئات العمل",
          eng: "Develop the skills of private sector employees in modern technologies such as artificial intelligence, cybersecurity, and app development, helping improve their professional performance and contributing to innovation and increased productivity in the workplace",
        },
      },
      {
        title: { arb: "تأهيل الباحثين عن عمل", eng: "Preparing Job Seekers" },
        decr: {
          arb: "تزويد الباحثين عن عمل في مجال التكنولوجيا بالمهارات التقنية التي تتطلبها الشركات، ما يعزز فرصهم في التوظيف ويتيح لهم الانخراط بسلاسة في سوق العمل",
          eng: "Provide job seekers in the tech field with the technical skills required by companies, enhancing their employability and ensuring a smooth transition into the workforce",
        },
      },
      {
        title: { arb: "تمكين المرأة في مجال التكنولوجيا", eng: "Empowering Women in Technology" },
        decr: {
          arb: "تعزيز مشاركة الفتيات والسيدات في القطاع التقني وتمكينهن من المساهمة الفاعلة في تطويره من خلال توفير فرص متكافئة ودعم قدراتهن الابتكارية",
          eng: "Promote the involvement of women in the tech sector, enabling them to contribute effectively to its development by providing equal opportunities and supporting their innovative capabilities",
        },
      },
      {
        title: { arb: "تحقيق الاستدامة الرقمية ضمن رؤية الكويت 2035", eng: "Achieving Digital Sustainability within Kuwait Vision 2035" },
        decr: {
          arb: "تأتي هذه المبادرة كجزء محوري من رؤية الكويت 2035، وتهدف إلى تعزيز التطور المجتمعي والتكنولوجي بشكل مستدام، مما يضمن بناء مستقبل رقمي يلبي احتياجات الأجيال الحالية والقادمة",
          eng: "This initiative is a key part of Kuwait’s Vision 2035, aiming to foster sustainable societal and technological development, ensuring the creation of a digital future that meets the needs of both current and future generations",
        },
      },
    ],
  },
  features: {
    titleNav: { arb: "ميزات", eng: "Features" },
    title: {
      arb: "ميزات التدريب",
      eng: "Features of the Training Program",
    },
    lst: [
      {
        icon: "ModelTraining",
        title: { arb: "برنامج تدريبي شامل لبناء مهارات المستقبل", eng: "Comprehensive Training Program to Build Future Skills" },
        decr: {
          arb: "يشمل البرنامج مسارات تدريبية متكاملة تغطي هندسة وتطوير التطبيقات، المبادئ الأساسية للذكاء الاصطناعي، وأساسيات الأمن السيبراني، مما يضمن تزويد المشاركين بمهارات تقنية متقدمة تواكب احتياجات العصر الرقمي",
          eng: "he program includes integrated training tracks covering application engineering and development, the fundamentals of artificial intelligence, and cybersecurity basics, ensuring participants are equipped with advanced technical skills that meet the demands of the digital age",
        },
      },
      {
        icon: "laptop",
        title: { arb: "لا يشترط خبرات برمجية سابقة", eng: "No Prior Programming Experience Required" },
        decr: {
          arb: "يتمتع البرنامج التدريبي بتصميم متكامل حيث لا يتطلب وجود خبرات برمجية سابقة. يغطي التدريب في مراحله الأولى جميع الأساسيات الضرورية التي يحتاجها مطورو ومهندسو البرمجيات لبناء أجزاء التطبيق المختلفة، مما يتيح للمبتدئين تطوير مهاراتهم بثقة وبسرعة",
          eng: "The training program is designed to accommodate individuals without prior programming experience. The initial stages of the training cover all essential basics that software developers and engineers need to build different parts of an application, allowing beginners to develop their skills confidently and quickly",
        },
      },
      {
        icon: "OnlinePrediction",
        title: {
          arb: "تدريب مرن  مع دعم متكامل",
          eng: "Flexible Training with Integrated Support",
        },
        decr: {
          arb: "يوفر التدريب تجربة مرنة تتناسب مع جدولك واحتياجاتك الفردية، مع ضمان تقديم دعم متكامل يضمن لك تجربة تعلم شاملة، فعّالة، ومرنة",
          eng: "The training provides a flexible experience tailored to your schedule and individual needs, ensuring comprehensive support for a holistic, effective, and adaptable learning experience",
        },
      },
      {
        icon: "AttachMoney",
        title: { arb: "فرصة المشاركة في أسبوع الإبداع الكويتي", eng: "Opportunity to Participate in Kuwait Creativity Week" },
        decr: {
          arb: "عند إتمامك للتدريب، ستتاح لك فرصة مميزة للمشاركة في أسبوع الإبداع الكويتي، حيث ستتمكن من استعراض أفكارك المبتكرة والتنافس على جوائز قيّمة تصل إلى 15,000 دينار كويتي تُمنح للمراكز الثلاثة الأولى، برعاية جمعية الذكاء الاصطناعي. هذه المبادرة تُعد فرصة استثنائية لتعزيز تميزك وتسليط الضوء على قدراتك الابتكارية",
          eng: "Upon completing the training, you will have an exceptional opportunity to participate in Kuwait Innovation Week, where you can showcase your innovative ideas and compete for valuable prizes totaling 15,000 Kuwaiti Dinars, awarded to the top three winners and sponsored by the Artificial Intelligence Association. This initiative serves as a remarkable opportunity to enhance your distinction and highlight your creative potential",
        },
      },
      {
        icon: "work",
        title: { arb: "ريادة محلية بمعايير إقليمية", eng: "●	Local Leadership with Regional Standards" },
        decr: {
          arb: `تمثل هذه المبادرة الأولى من نوعها في الكويت، مؤكدة التزامها بتعزيز التنافسية المحلية مع مواكبة التجارب الإقليمية الرائدة. تستلهم المبادرة نجاحات مبادرات مشابهة في المنطقة، مثل مبادرة "تدريب مئة ألف مبرمج" في السعودية، والمبادرة المصرية الموحدة لتدريب مليون مبرمج، ومبادرة تدريب مليون مبرمج في الأردن، والمبادرة الإماراتية لتدريب مليون مبرمج، مما يعكس طموحها في بناء مجتمع تقني منافس على المستوى الإقليمي والدولي`,
          eng: `This initiative is the first of its kind in Kuwait, demonstrating a commitment to enhancing local competitiveness while aligning with leading regional initiatives. The initiative draws inspiration from successful similar programs in the region, such as Saudi Arabia's "100,000 Programmers Training" initiative, Egypt's unified program to train one million programmers, Jordan's "Million Programmers" initiative, and the UAE's program to train one million programmers. This reflects the ambition to build a competitive tech community at the regional and international levels`,
        },
      },
    ],
  },
  sections: {
    titleNav: {
      arb: "أقسام التدريب",
      eng: "Training Sections",
    },

    title: {
      arb: "أقسام التدريب",
      eng: "Training Sections",
    },
    content: {
      arb: "التدريب مقسم الى جزئين",
      eng: "The training is divided into two parts",
    },
    lst: [
      {
        id: "1",
        title: { arb: "القسم النظري", eng: "Theoretical Section" },
        decr: {
          arb: "يقدم من خلال جلسات مباشره وتفاعلية وسيتم فيه توضيح مجموعة من المفاهيم المهمة",
          eng: "It will be delivered through live interactive sessions, during which a range of important concepts will be explained.",
        },
      },
      {
        id: "2",
        title: { arb: "التطبيق العملي", eng: "Practical Application Section" },
        decr: {
          arb: "يُقدّم من خلال مجموعة من المهام المتاحة عبر منصة تدريب سوف تسمح بتقديم المهمات التدريبية للمتدربين بما يتناسب مع أوقات فراغهم ووظروفهم. توفر المواد التعليمية الفردية المخصصة لكل متدرب، بالإضافة إلى المدربين والساعات المكتبية المرنة المتاحة في أوقات متنوعة، بيئة تعليمية متكاملة و مرنة تدعم المتدرب في تحقيق أقصى استفادة من التدريب دون التأثير على أدائه الوظيفي أو التأثر في أداء باقي المتدربين",
          eng: "It is delivered through a set of tasks available on a training platform, allowing trainees to complete the training assignments at their convenience and in alignment with their schedules and circumstances. The platform provides personalized educational materials tailored to each trainee, along with flexible trainers and office hours available at various times. This creates an integrated and flexible learning environment that supports trainees in maximizing the benefits of the training without compromising their job performance or affecting the progress of other trainees",
        },
      },
    ],
    lstTopic: {
      1: [
        {
          lst: [["The Role of IT Majors in Shaping Digital Empowerment Strategy"], ["Software Development Lifecycle (SDLC) Best Practices"], ["Design Patterns for Scalable Software Solutions"], ["Digital Empowerment Requirements"], ["Artificial Intelligence App lifecycle"], ["Image & Signal Processing Algorithms"], ["Cybersecurity Fundamentals & Secure Coding"], ["Budgeting and Cost Management in Software Projects"], ["Overcoming Challenges in Enterprise Government Projects: Best Practices"], ["Entrepreneurship Mindset "]],
          title: "Software Engineering & Advanced Topics",
        },
      ],
      2: [
        {
          lst: [["Best Practices for Development Environment Setup"], ["HTML5 Structure & Semantic"], ["CSS3 Styling"], ["JavaScript Fundamentals"], ["ES6 + Features in JavaScript"]],
          title: "Coding Fundamentals",
        },
        {
          lst: [
            ["Introduction to Databases", ["Understanding Database Essentials", "Relational vs Non-Relational Databases", "Database Structure and Design", "Relational Database Management System Architecture", "Database Constraints", "SQL Queries", "Advanced SQL Techniques", "Database Security and User Management", "CRUD Operations"]],
            ["Server Application (1)", ["Introduction to Server Applications", "Creating HTTP Server", "Database Integration", "Persistence Layer and Object–relational Mapping (ORM)", "Manage Database Connection Pooling"]],
            ["Server Application (2)", ["Manage Server Environment Variables", "Fault Tolerance", "ECMA vs CommonJS", "Blocking (Synchronously) and Non-blocking (Asynchronously) I/O Operations"]],
            ["Graphql API", ["Schema-Resolver Architecture", "GraphQL Gateway", "GraphQL SDL Validation", "Custom Resolvers", "GraphiQL Testing Tool"]],
            ["RESTful API", ["HTTP Basics for REST", "RESTful Design Principles", "Request-Response Cycle and Middleware", "API Performance Optimization", "File-Based System Routing"]],
            ["Upload File-Locally", ["Uploading Files to Server Storage", "Optimizing File Uploads with Form-Data", "Streamlining API Testing with Industry-Standard Tools", "Essential Steps for Secure Uploads"]],
            ["Upload File-Cloud Service", ["Cloud Services for Scalable Growth and Innovation", "Uploading Files to the Cloud"]],
            ["Email Server", ["Integrating SMTP with Applications", "Creating Custom Email Templates with SMTP", "The Key to Real-Time Data Exchange"]],
            ["Server Strategies and Techniques (1)", ["Types of ORM Relationship Mappings", "Joins and Querying Relationships (Lazy vs Eager Loading)", "CASCADE Options for Data Integrity"]],
            ["Server Strategies and Techniques (2)", ["Introduction to Database Transaction Lifecycle", "JSON Web Token (JWT)", "Caching Techniques", "Server Scaling Strategies"]],
          ],
          title: "Database & Server-Side / Back-End",
        },
        {
          lst: [
            ["Frontend Fundamentals", ["JavaScript Library vs Framework", "Component Programming", "Introduction to JavaScript XML (JSX)", "State Management", "Mutable vs Immutable Values", "Achieving Faster Deployment with Render and Commit", "Using Snapshots for Real-Time State Preservation", "Virtual DOM and DOM Manipulation"]],
            ["Hooks and Component Lifecycle", ["Component Lifecycle Stages", "Common Techniques for Managing Lifecycle", "Optimize the Rendering of Lists and Collections of Elements", "Boosting Code Performance with Hooks", "Rules for Efficient Hook Implementation", "Component Rendering Optimization", "Nested Components Architecture"]],
            ["Cross-Component Communication", ["Managing Props for Cleaner Component Code", "Optimizing Data Flow with Props Drilling", "Flux Design Pattern", "Efficient AppState Management for Dynamic User Interfaces", "Component Scope", "Boosting App Performance with Storage API", "Secure Caching Strategies"]],
            ["Routing", ["Client-Side vs. Server-Side Routing", "Optimizing Data Flow Across Routes", "Nested Routing", "Hard Navigation vs Soft Navigation", "Reducing Rendering Time with Critical Path Optimization"]],
            ["Form Handling and Validation", ["Client-Side Validation", "Best Validation Techniques", "Cross-Site Scripting (XSS) Protection", "Field Grouping and Conditional Validation", "Localization and Internationalization", "Enhancing Data Collection with Dynamic Form Fields"]],
            ["Responsive Design", ["CSS-in-JS Style Engine", "Flexible Grid Layouts", "Responsive Breakpoints", "Viewport Meta Tag", "Cross-device Compatibility"]],
            ["API Integration and Data Fetching", ["Basics of the Fetch API", "Streaming Responses", "Integration with REST API and GraphQL", "Chaining Multiple Requests"]],
            ["ClientApp Strategies and Techniques", ["Presentational and Container Components", "Feature-Based Folder Structure", "Lazy Loading", "How to Avoid Unnecessary Renders", "Enhancing Code Maintainability with Separation of Concerns", "Encapsulating Logic", "Error Boundaries and Centralized Error Logging"]],
            ["App Manifests", ["Optimizing App Performance with Manifest Files", "Manifest Installation Criteria", "Manifest Versioning and Updates"]],
            ["User Interface Technique", ["Infinite Scrolling", "Pagination", "Virtual Scrolling", "Content Carousels", "Parallax Scrolling", "Progressive Loading", "Scrolling Snap"]],
          ],
          title: "Client-Side / Front-End",
        },
        {
          lst: [
            ["Client-Server Architecture", ["Master the Phases of Secure Network Connections", "Types of Client-Server Architecture", "Model-View-Controller Pattern (MVC)", "Model-View-ViewModel Pattern (MVVM)"]],
            ["Authentication", ["Fundamentals of Authentication", "Authentication Mechanisms", "One-time Passwords (OTPs) via SMS or Email", "Monitoring and Logging", "OAuth 2.0"]],
            ["Authorization", ["Authorization Models", "Privileged Access Management (PAM)", "Secure Routing", "CORS and Preflight Requests", "Versioning Access Policies", "App Permissions"]],
            ["Security Best Practices", ["General Attack Protection Strategies", "DDoS (Distributed Denial of Service) Protection", "Man-in-the-Middle (MitM) Attacks", "DNS Spoofing and Poisoning", "SQL Injection Protection", "Cross-Site Scripting (XSS)", "Cross-Site Request Forgery (CSRF)", "Insecure Direct Object References (IDOR)", "Trojan and Worm Protection"]],
            ["Managing Scalable Hierarchies", ["Database Modeling for Hierarchies", "Hierarchy Restructuring", "Search and Filtering in Dynamic Hierarchies"]],
            ["Service Worker", ["Service Worker Lifecycle", "Asynchronous and Parallel Execution", "Service Worker and Offline Capabilities", "Service Worker Registration"]],
            ["Push Notifications", ["VAPID (Voluntary Application Server Identification) ID", "Push Notification Subscription", "Push Notifications by Service Worker"]],
            ["Chatbot App", ["Manage Chatbot Models", "Contextual and Context-Aware Responses", "Interactive Media Responses"]],
            ["Developing Smart Apps-1", ["Optimizing Data Acquisition for Machine Learning", "Efficient Data Preprocessing Techniques"]],
            ["Developing Smart Apps-2", ["Feature Extraction for Precise Models"]],
            ["Developing Smart Apps-3", ["Building Effective Data Training Models"]],
            ["Developing Smart Apps-4", ["Classification for Better Business Intelligence"]],
            ["Hosting Solutions", ["Hosting Solutions for Complex Application Structures", "FileZilla and SFTP (SSH File Transfer Protocol)", "Virtual Host", "Boosting Security with a Static IP Address", "Advanced Techniques for Bundling Client Applications"]],                    
          ],
          title: "Fullstack - Smart & Secure Apps",
        },
         {
          lst: [
            ["How to define system stockholders and their requirements"],                                                                                                                                                                                                                                          
            ["Develop functional and non-functional requirements"],                                                                                                                                                                                                                                          
            ["Create system requirements specifications documents"],                                                                                                                                                                                                                                          
            ["Describe the essentials elements of software architectures and design patterns"],                                                                                                                                                                                                                                          
            ["Convert functionalities into running applications "],                                                                                                                                                                                                                                          
            ["Explain varies software testing types and levels"],                                                                                                                                                                                                                                          
          ],
          title: "Capstone Project",
        },
      ],
    },
    btnDetails: { arb: "تفاصيل التدريب", eng: "Training Details" },
    btnReg: { arb: "انضم للتدريب", eng: "Join the Training" },

  },
  innovationWeek: {
    titleNav: {
      arb: "إسبوع الإبداع الكويتي",
      eng: "Kuwaiti Innovation Week",
    },
    title: {
      arb: "إسبوع الإبداع الكويتي",
      eng: "Kuwaiti Innovation Week",
    },
    content: {
      arb: `إيماناً من جمعية الذكاء الاصطناعي للأشياء بدعم الإبداع وريادة الأعمال، ستنظم الجمعية اسبوعاً في نهاية التدريب بعنوان "اسبوع الإبداع الكويتي". خلال هذا الأسبوع، سيتاح لجميع المشاركين فرصة عرض تطبيقاتهم الريادية، وسيقوم أعضاء لجنة تحكيم مختارة من أهل الاختصاص باختيار أفضل ثلاثة تطبيقات وستخصص لهم الجمعية دعماً مالياً بقيمة 7 آلاف دينار للمركز الأول، 5 آلاف دينار للمركز الثاني، و3 آلاف دينار للمركز الثالث`,
      eng: `In line with its commitment to supporting creativity and entrepreneurship, the Artificial Intelligence of Things Association will organize the "Kuwaiti Innovation Week" at the end of the training. During this week, all participants will have the opportunity to showcase their innovative applications. A panel of judges, selected from industry experts, will choose the top three applications. The association will provide financial support to the winners: 7,000 Kuwaiti Dinars for the first place, 5,000 Kuwaiti Dinars for the second place, and 3,000 Kuwaiti Dinars for the third place`,
    },
    lst: [
      {
        num: 2,
        title: {
          arb: "المركز الثاني",
          eng: "Second Place",
        },
        decr: {
          arb: "5 الاف دينار",
          eng: "5,000 KD",
        },
      },
      {
        num: 1,
        title: {
          arb: "المركز الاول",
          eng: "First Place",
        },
        decr: {
          arb: "7 الاف دينار",
          eng: "7,000 KD",
        },
      },
      {
        num: 3,
        title: {
          arb: "المركز الثالث",
          eng: "Third Place",
        },
        decr: {
          arb: "3 الاف دينار",
          eng: "3,000 KD",
        },
      },
    ],
  },

  organizers: {
    titleNav: {
      arb: "الجهات المنظمة",
      eng: "Organizing Entities",
    },
    title: {
      arb: "الجهات المنظمة",
      eng: "Organizing Entities",
    },
    content: {
      arb: "التدريب معتمد من قبل الجهاز المركزي لتكنولوجيا المعلومات وجمعية الذكاء الإصطناعي للأشياء برئاسة الشيخ محمد أحمد الصباح",
      eng: "The training is certified by the Central Agency for Information Technology and the Artificial Intelligence of Things Association, chaired by Sheikh Mohammed Ahmed Al-Sabah",
    },
    lst: [
      {
        name: { arb: "الجهاز المركزي لتكنولوجيا المعلومات", eng: "Central Agency for Information Technology" },
        decr: {
          arb: "المرجع الوطني في بناء القدرات التقنية وتطوير الكفاءات لمواكبة العصر الرقمي",
          eng: "The national reference in building technical capabilities and developing competencies to keep pace with the digital age.",
        },
      },
      {
        name: { arb: "جمعية الذكاء الاصطناعي للأشياء", eng: "Artificial Intelligence of Things Association" },
        decr: {
          arb: "المؤسسة الوطنية الرائدة في تعزيز الابتكار الرقمي وتقنيات الذكاء الاصطناعي",
          eng: "The leading national organization in promoting digital innovation and artificial intelligence technologies.",
        },
      },
    ],
    contentSheikh: {
      arb: "يُعتبر الشيخ محمد أحمد الصباح من أبرز الشخصيات القيادية في مجالات التكنولوجيا والابتكار، حيث يمتلك رؤية استراتيجية تمكنه من قيادة مسارات التمكين الرقمي بكل تميز. بفضل إسهاماته الفاعلة، أصبح له دور محوري في دفع عجلة التطور الرقمي الوطني وتعزيز المبادرات الرائدة التي تواكب أحدث التحولات التقنية على مستوى العالم. لذا، يسرنا أن يكون هذا التدريب تحت رعايته وإشرافه المباشر، مما يعكس التزامه المستمر بتطوير القطاع التكنولوجي ودعمه المستدام للابتكار الرقمي",
      eng: "Sheikh Mohammed Ahmed Al-Sabah is considered one of the leading figures in the fields of technology and innovation, possessing a strategic vision that enables him to steer digital empowerment initiatives with distinction. Through his active contributions, he has played a pivotal role in accelerating national digital development and fostering pioneering initiatives that align with the latest global technological transformations. Therefore, we are honored that this training is under his direct patronage and supervision, reflecting his ongoing commitment to the advancement of the technological sector and his sustained support for digital innovation",
    },
  },
  sponsors: {
    titleNav: {
      arb: "الجهات الراعية",
      eng: "Sponsors",
    },
    title: {
      arb: "الجهات الراعية",
      eng: "Main Sponsors",
    },
    subtitle: {
      arb: "نؤمن بأن دعمكم يجسد المعنى الحقيقي للمسؤولية الاجتماعية، ويسهم بشكل مباشر في تمكين المواطن الكويتي وإعداد كوادر وطنية متميزة في مجال البرمجة، بما يتماشى مع تحقيق رؤية الكويت 2035 نحو مستقبل مزدهر ومستدام",
      eng: "We believe that your support embodies the true essence of social responsibility and directly contributes to empowering Kuwaiti citizens and preparing exceptional national talents in the field of programming, in alignment with Kuwait Vision 2035 for a prosperous and sustainable future",
    },
    lstP: [
      {
        arb: "في عصر يسير بخطى متسارعة نحو الرقمنة، أصبح التمكين الرقمي حجر الزاوية للتفوق والمنافسة على كافة الأصعدة. إن دعم هذا التدريب يمثل فرصة استراتيجية فريدة للجهات الراغبة في تعزيز مكانتها الريادية في السوق ودفع عجلة التحول الرقمي. من خلال هذا التدريب، ستتمكنون من المشاركة الفعالة في تشكيل المستقبل الرقمي، عبر تمكين المبرمجين المحليين وتزويدهم بالمهارات اللازمة لبناء تطبيقات ذكية وآمنة",
        eng: "In an era rapidly moving towards digitization, digital empowerment has become the cornerstone of excellence and competition across all sectors. Supporting this training represents a unique strategic opportunity for organizations seeking to strengthen their leadership position in the market and accelerate the digital transformation process. Through this training, you will actively participate in shaping the digital future by empowering local programmers and equipping them with the skills needed to build smart and secure applications",
      },
      {
        arb: "إن دعمكم لهذا التدريب لا يمثل مجرد استثمار في تطوير المهارات التقنية، بل هو فرصة مميزة للارتقاء بسمعتكم كقادة في مجال الابتكار والتمكين الرقمي، مما يعزز قدرتكم على استشراف المستقبل الرقمي. معًا، سنمهد الطريق لإعداد جيل من المبرمجين القادرين على قيادة التحولات التقنية، مما يجعلهم عنصرًا أساسيًا في تحقيق التقدم الرقمي على الصعيدين الوطني والدولي",
        eng: "Your support for this training is not merely an investment in developing technical skills; it is a unique opportunity to enhance your reputation as leaders in innovation and digital empowerment, strengthening your ability to anticipate the digital future. Together, we will pave the way to prepare a generation of programmers capable of leading technological transformations, making them a key element in achieving digital progress both nationally and globally",
      },
    ],
    lst: [
      {
        title: { eng: "Official Sponser", arb: "راعي رئيسي" },
        key: "main",
      },
      {
        title: { eng: "Golden Sponser", arb: "راعي ذهبي" },
        key: "golden",
      },
      {
        title: { eng: "Silver Sponser", arb: "راعي فضي" },
        key: "silver",
      },
      {
        title: { eng: "Bronze Sponser", arb: "راعي برونزي" },
        key: "bronze",
      },
    ],
    btn: { eng: "Share the success with us", arb: "شاركنا النجاح" },
  },
  copyRight: { eng: `©${new Date()?.getFullYear()} AAIOT all rights reserved`, arb: `©جميع الحقوق محفوظة لجمعية الذكاء الإصطناعي للأشياء ${new Date()?.getFullYear()}` },
};
