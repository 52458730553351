export const CbbmisUI_Version = "v.10.5.2021";

//props Name
//icon props 
export const props_icon_name ="icon"
export const props_icon_color ="iconColor"
export const props_icon_onHover_color ="hoverColor"
export const props_icon_size ="iconSize"

export const props_sm ="sm"
export const props_mode ="mode"
export const props_disabled ="disabled"
export const props_bgColor = "bgColor"
export const props_textColor = "textColor"

export const iconSizeFormapi = "24px"

export const langRtL=[
    "ar","arb","arabic",
    "persian",
    "hebrew",
    "thaana",
    "syriac",
    "mandaic",
    "samaritan",
    "aramaic",
    "kurdish",
    "kashmiri",
    "urdu"
    ]

export const strTestProps ="a852"